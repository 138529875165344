import React, { useEffect, useState, useContext } from "react"
import Chatbotcomponent from '../Chatflow/Chatbotcomponent'
import { getAlertDetailsById } from "../../services/index";
import { getalertactivitylogs } from "../../services/alertAuthService"
import ReactPlayer from 'react-player'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import { ChatFlowWebArrowIcon, BotAvatarIcon, UserAvatarIcon, ERTAvatarIcon, MosaicNoStream, AlertGenericImg1, AlertGenericImg } from '../../assets/images';
import Reactfunctionalchatbot from '../../components/ReactChatBot/Reactfunctionalchatbot.js'
import Swal from "sweetalert2";
import { frontDateConverter } from "../../utils/utils";

export default function AlertReport() {
    let match1 = useRouteMatch("/alertreport/:id") //to fetch id from url
    let alertId = match1.params['id']

    const [singleAlertDet, setSingleAlertDet] = useState(null)
    const [activitylogs, setactivitylogs] = useState(null)
    const [MessagesList, setMessagesList] = useState(null)
    const [config, setconfig] = useState(null)
    let history = useHistory()
    const [messages, setmessages] = useState([])

    const [isimage, setisimage] = useState(false)
    const [isOpenmodal, setisOpenmodal] = useState(false)
    const [snapshoturl, setsnapshoturl] = useState([])
    const [isShowChatbotComponent, setIsShowChatbotComponent] = useState(false)

    useEffect(() => {
        (async () => {
            if (alertId && alertId != null) {
                GetAlertDetails()
                //Getalertactivitylogs()
            }
        })();

    }, [alertId])

    // useEffect(async () => {
    //     if (alertId && alertId != null) {
    //         GetAlertDetails()
    //         //Getalertactivitylogs()
    //     }

    // }, [alertId])
    const GetAlertDetails = async () => {
        try {
            await getAlertDetailsById(alertId).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        setSingleAlertDet(resp.message[0])
                    }
                } else if (resp.status === "Failed") {
                    // alert(resp.message)
                    Swal.fire({ text: resp.message })
                    return;
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    const Getalertactivitylogs = async () => {
        try {
            let obj = {
                "alertId": alertId
            }
            getalertactivitylogs(obj).then((resp) => {
                if (resp.status == "Ok") {
                    setactivitylogs(resp.message.conversations)
                } else if (resp.status === "Failed") {
                    Swal.fire({ text: resp.message })
                    return;
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        if (singleAlertDet != null) {
            Getalertactivitylogs()
        }
    }, [singleAlertDet])
    useEffect(() => {
        if (activitylogs != null) {
            ConvertRasaMessages(activitylogs, singleAlertDet)
        }
    }, [activitylogs])

    useEffect(() => {
        if (config != null) {
            setIsShowChatbotComponent(true)
        }
    }, [config])


    const ConvertRasaMessages = async (convlist, alertdetails) => {
        var messageslist = []

        var index1 = 0
        convlist.map((mess, index) => {
            index1 = index1 + 1

            if (mess.data.otherProps.botMessage == "Please confirm if you want to close this alert?") {
                if (mess.data.otherProps.btnResponse != 0) {
                    var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        loading: true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)

                    var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                    index1 = index1 + 1
                    var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null)
                    var messobj = {
                        id: index1,
                        type: "user",
                        message: cmess,
                        loading: true,
                        responseTime: mess.data.otherProps.responseTime,
                        direction: "right",
                    }
                    messageslist.push(messobj)
                } else {
                    var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        loading: true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                }
            } else if (mess.data.otherProps.action == "Escalation Matrix") {
                if (singleAlertDet.emergencyContactsList == null) {
                    var bmess = <h3>No Contacts for Escalation</h3>
                } else {
                    var bmess = EscalationContactList(singleAlertDet.emergencyContactsList)
                }

                var prepostchatmess =
                    <>
                        <div className="chatFlowBoxContainer justify-content-start">
                            <div className="chatFlowChatBoxBig float-left">
                                <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                            </div>
                        </div>
                        {bmess}
                    </>
                var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    //loading:true,
                    responseTime: mess.createdOn,
                    direction: "left",
                }
                messageslist.push(messobj)
            } else if (mess.data.otherProps.action == "Please watch live stream") {
                if (singleAlertDet.associatedCamList == null) {
                    var bmess = <h3>No devices associated</h3>
                } else {
                    // var bmess = AssociatedDevicesListLiveComponent(singleAlertDet.associatedCamList)
                    var bmess = <>
                        <p>No live stream available once alert closed.</p>
                    </>
                }

                var prepostchatmess =
                    <>
                        <div className="chatFlowBoxContainer justify-content-start">
                            <div className="chatFlowChatBoxBig float-left">
                                <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                            </div>
                        </div>
                        {bmess}
                    </>
                var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    //loading:true,
                    responseTime: mess.createdOn,
                    direction: "left",
                }
                messageslist.push(messobj)
            } else if (mess.data.otherProps.action == "Request alert snapshot") {
                var prepostchatmess =
                    <>
                        <AlertSnapshotComponent />
                    </>
                var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    //loading:true,
                    responseTime: mess.createdOn,
                    direction: "left",
                }
                messageslist.push(messobj)
            }
            else if (mess.data.otherProps.botMessage == "Please attach alert evidence(E.G.: Videos, Comments, Documents, Voice notes & Photos)") {

                if (mess.data.otherProps.btnResponse != 0) {
                    var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        loading: true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)

                    var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                    index1 = index1 + 1
                    var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null)
                    var messobj = {
                        id: index1,
                        type: "user",
                        message: cmess,
                        loading: true,
                        responseTime: mess.data.otherProps.responseTime,
                        direction: "right",
                    }
                    messageslist.push(messobj)
                } else {
                    var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        loading: true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                }
            }
            else if (mess.data.otherProps.botMessage == "Please check the pre & post video link below:") {
                var bmess = ""
                if (alertdetails.alertPlayBackUrl.includes("https://")) {
                    bmess = <ReactPlayer
                        id={"video-" + alertdetails.alertId}
                        url={alertdetails.alertPlayBackUrl}
                        playing={true}
                        width="264px"
                        height="150px"
                        controls={true}
                        style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }}
                    />
                } else {
                    bmess = alertdetails.alertPlayBackUrl
                }
                var prepostchatmess =
                    <>
                        <div className="chatFlowBoxContainer">
                            <div className="chatFlowChatBoxBig">
                                <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                            </div>
                        </div>
                        <p>{bmess}</p>
                    </>
                var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    loading: true,
                    responseTime: mess.createdOn,
                    direction: "left",

                }
                messageslist.push(messobj)
            }
            else if (mess.data.otherProps.action == "Op Evidence") {
                var evitxt = mess.data.otherProps.botMessage
                var ertmess = ""
                if (evitxt.includes("https://")) {
                    if (evitxt.includes(".jpg") || evitxt.includes(".jpeg") || evitxt.includes(".png")) {
                        ertmess = <div className="chatFlowMultiImageContainer"><img className="chatFlowChatTimeIconBig" src={evitxt} /></div>
                    } else if (evitxt.includes(".mp3")) {
                        ertmess = <div className="chatFlowVoiceBoxContainer"><audio src={evitxt} controls /></div>
                    } else {
                        ertmess = <a className="evidence-link" href={evitxt} target="_blank">{evitxt}</a>
                    }
                } else {
                    ertmess = evitxt
                }
                var cmess = BotMessageComponent("user", ertmess, mess.data.otherProps.responseTime, null, null)
                var messobj = {
                    id: index1,
                    type: "user",
                    message: cmess,
                    responseTime: mess.data.otherProps.responseTime,
                    direction: "right",
                }
                messageslist.push(messobj)
            } else if (mess.data.otherProps.action == "ERT Evidence") {
                var evitxt = mess.data.otherProps.botMessage
                var ertmess = ""
                if (evitxt.includes("https://")) {
                    if (mess.data.otherProps.evidenceType == "img") {
                        ertmess = <div className="chatFlowMultiImageContainer"><img className="chatFlowChatTimeIconBig" src={evitxt} /></div>
                    } else if (mess.data.otherProps.evidenceType == "video") {
                        var ertmess = ""
                        if (evitxt.includes("https://")) {
                            ertmess = <ReactPlayer
                                id={"video-" + singleAlertDet.alertId}
                                url={evitxt}
                                playing={true}
                                width="264px"
                                height="150px"
                                controls={true}
                                style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }}
                            />
                        } else {
                            ertmess = <a className="evidence-link" href={evitxt} target="_blank">{evitxt}</a>
                        }
                    } else if (mess.data.otherProps.evidenceType == "location") {
                        var loc = mess.data.otherProps.botMessage.split("My current location : ")
                        ertmess = <><p>{"ERT current location : "}</p><a className="evidence-link" href={loc[1]} target="_blank">{loc[1]}</a></>
                    } else if (mess.data.otherProps.evidenceType == "audio") {
                        ertmess = <div className="chatFlowVoiceBoxContainer"><audio src={evitxt} controls /></div>
                    } else {
                        ertmess = <a className="evidence-link" href={evitxt} target="_blank">{evitxt}</a>
                    }
                } else {
                    ertmess = evitxt
                }
                var emess = BotMessageComponent("ert", ertmess, mess.data.otherProps.responseTime, null, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: emess,
                    responseTime: mess.data.otherProps.responseTime,
                    direction: "left",
                }
                messageslist.push(messobj)
            }
            else if (mess.data.otherProps.btnResponse == 0) {
                if (mess.data.otherProps.buttons.length > 0) {
                    if(mess.data.otherProps.botMessage != "Please acknowledge the alert"){
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            loading: true,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)
                    }
                } else {
                    if (mess.data.otherProps.botMessage == "Autoclose alert") {
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            loading: true,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)

                        index1 = index1 + 1
                        var cmess = BotMessageComponent("user", "Alert has been closed successfully.", mess.createdOn, null, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            loading: true,
                            responseTime: mess.createdOn,
                            direction: "right",
                        }
                        messageslist.push(messobj)
                    } else {
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            loading: true,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)
                    }
                }
            } else {
                var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null)
                var messobj = {
                    id: index1,
                    type: "bot",
                    message: cmess,
                    loading: true,
                    responseTime: mess.createdOn,
                    direction: "left",
                }
                messageslist.push(messobj)

                var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                if (buttonclicked) {
                    index1 = index1 + 1
                    if(mess.data.otherProps.action == "Request to select reason code" || mess.data.otherProps.action == "Request to select closure comment"){
                        var cmess = BotMessageComponent("user", mess.data.otherProps.selectedValue, mess.data.otherProps.responseTime, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            loading: true,
                            responseTime: mess.data.otherProps.responseTime,
                            direction: "right",
                        }
                    }else{
                        var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            loading: true,
                            responseTime: mess.data.otherProps.responseTime,
                            direction: "right",
                        }
                    }
                    messageslist.push(messobj)
                }
            }

        })

        const sorted = messageslist.sort(function (a, b) {
            return new Date(a.responseTime) - new Date(b.responseTime);
        });

        setmessages(sorted)

    }

    const AlertSnapshotComponent = () => (
        <>
            <div className="chatFlowBoxContainer justify-content-start">
                <div className="chatFlowChatBoxBig float-left">
                    <p className="chatFlowChatTextBig">Alert Snapshot</p>
                </div>
            </div>
            <div className='GridBgImgChat'>
                {singleAlertDet && singleAlertDet.snapshotURL ? <img src={singleAlertDet.snapshotURL}
                    onClick={() => handleChange(true, singleAlertDet.snapshotURL)}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = AlertGenericImg1;
                    }}
                    style={{ height: "15rem" }} /> : <AlertGenericImg />}
            </div>
        </>
    )

    const BotMessageComponent = (chattype, mess, chatdatetime, buttonlist) => (
        <div>
            {
                (chattype == "bot")
                    ?
                    <>
                        <div className="chatFlowBoxContainer-bot">
                            <div className="chatFlowTimeIconContainerBig">
                                <img className="chatFlowChatTimeIconBig" src={BotAvatarIcon} />
                            </div>
                            <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
                        </div>
                        {
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess}</p>
                                </div>
                            </div>
                        }
                    </>
                    :
                    (chattype == "ert")
                        ?
                        <>
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowTimeIconContainerBig">
                                    <img className="chatFlowChatTimeIconBig" src={ERTAvatarIcon} />
                                </div>
                                <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
                            </div>
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess}</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="chatFlowBoxContainer">

                                <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
                                <div className="chatFlowTimeIconContainerBig">
                                    <img className="chatFlowChatTimeIconBig" src={UserAvatarIcon} />
                                </div>
                            </div>
                            <div className="chatFlowBoxContainer">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess}</p>
                                </div>
                            </div>
                        </>

            }
        </div>
    )

    //#region Live component

    const AssociatedDevicesListLiveComponent = (lst) => {
        return <>
            {lst.length > 0 &&
                <div>
                    <AlertSourceDeviceLiveComponent />
                    <span>Associated Devices</span>
                    {lst.map(item => (
                        PlayerComponent(item.deviceId, item.cameraName, item.hlsStreamingUrl)
                    ))}
                </div>
            }
            {lst.length == 0 &&
                <>
                    <AlertSourceDeviceLiveComponent />
                    <span className="Ass_Name">Associated Devices</span>
                    <p>No devices associated</p>
                </>
            }
        </>
    }

    const AlertSourceDeviceLiveComponent = () => (
        <>
            {
                (singleAlertDet.deviceTypeID == 1)
                    ?
                    <>
                        <span>Alert Device</span>
                        {PlayerComponent(singleAlertDet.deviceId, singleAlertDet.deviceName, singleAlertDet.hlsStreamingUrl)}
                        <hr className="solid"></hr>
                    </>
                    :
                    <></>
            }
        </>

    )
    const PlayerComponent = (devid, devname, devurl) => (
        <div className="chatbot-live-camera" key={devid}>
            <p className="LocationNameText camera-name"><span>{devname}</span> </p>
            {
                devurl != null
                    ?
                    <ReactPlayer
                        id={"video-" + devid}
                        url={devurl}
                        playing={true}
                        width="264px"
                        height="150px"
                        controls={true}
                        style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }} />
                    :
                    <img src={MosaicNoStream} alt="mosaic" style={{ height: "150px", width: "264px", backgroundColor: '#D0D2D1', borderRadius: "14px" }} />
            }
        </div>
    )

    //#endregion
    const ClosePreview = () => {
        setisOpenmodal(false)
    }
    const handleChange = (isimg, snapurl) => {
        setisimage(isimg)
        setisOpenmodal(true)
        setsnapshoturl(snapurl)
    }
    return (
        <>
            {/* modal is for snapshot preview only start*/}
            <Modal
                show={isOpenmodal}
                dialogClassName={"activityUpdateModel"}
                onHide={ClosePreview}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton style={{ padding: "0 1rem", borderBottom: "0" }}></Modal.Header>
                <Modal.Body>
                    <div id="EvidenceViewContainer">
                        <div style={{ display: (isimage ? 'block' : 'none') }}>
                            <img
                                style={{ height: "500px", width: "465px" }}
                                src={snapshoturl}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal is for snapshot preview only end*/}
            <div className="MainContentMargin">
            <div className="col-lg-12 pr-0 chatFlowWebMaiContainer">
                {
                    singleAlertDet && <>
                        <div className="chatFlowWebHeaderTop d-flex align-items-center justify-content-center">
                            <div className="container-fluid m-0">
                                <div className="row">
                                    <div className="col-11  d-flex align-items-center">
                                        <img className="mr-3 float-left evidence-item" alt="backarrow icon" src={ChatFlowWebArrowIcon} onClick={() => {
                                            // history.push(`/alertdetails/${singleAlertDet.alertId}`)
                                            history.push(`/myalerts`)
                                        }} />
                                        <p className="chatFlowWebHeaderAlertText">{singleAlertDet.alertCode}</p>
                                        <div className={`chatFlowWebHeaderSeverityBox ${singleAlertDet.severity.toLowerCase()}-bg`}>
                                            <p>{singleAlertDet.severity}</p>
                                        </div>
                                        {/* <p className="chatFlowWebOperatorNameText">Ravi and You</p> */}
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="chatFlowWebHeaderBottom d-flex align-items-center justify-content-center">
                            <div className="container-fluid m-0">
                                <div className="row">
                                    <div className="col-11">
                                        <p className="chatFlowWebHeaderAddress">{singleAlertDet.site} | {singleAlertDet.actualTime} | ID: {singleAlertDet.alertId}</p>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="Chatbot">
                            {isShowChatbotComponent &&
                                <Chatbotcomponent Configurations={config} />}
                        </div>
                        <div className="alert-report-section"><Reactfunctionalchatbot messages={messages} /></div>
                    </>
                }
            </div>
            </div>
        </>

    )
}

const EscalationContactList = (lst) => {
    return <>
        {lst.length > 0 &&
            <div className="container">
                {lst.map(item => (
                    <div><p>{"L" + item.contactLevel}{" - "}{item.shortDesignation}{" - "}{item.phoneNumber}{" - "}{item.shortContactName}</p></div>
                ))}
               
            </div>
        }
        {lst.length == 0 &&
            <>
                <h3>No Contacts for Escalation</h3>
            </>}
    </>
}
