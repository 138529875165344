import React, { useState, useEffect, useContext } from 'react'
import { Link} from "react-router-dom"
import { sidebarContact, AlertTemplateBlue, AlertTemplateWhite, NumberOneBlue, NumberTwoBlue, NumberThreeBlue, NumberFourBlue, ReviewBlue, ReviewWhite, SiteTemplateLocBlue, SiteTemplateLocWhite, UsersBlue, UsersWhite, NotificationSelected, NotificationUnSelected } from '../../assets/images';
import { VERSION, IS_SITE_MANAGEMENT_TAB_HIDDEN, IS_ALERT_TEMPLATE_TAB_HIDDEN, IS_USERS_TAB_HIDDEN, IS_NOTIFICATION_SETTING_TAB_HIDDEN, IS_BRANDMASTER } from '../../config';
import { DrawerContext } from "../../Context/DrawerContext";
import alertSelected from '../../assets/images/sidebar-logo/alertSelected.svg'
import alertUnselected from '../../assets/images/sidebar-logo/alertUnselected.svg'

// let tempSiteObj = {
//     "SiteID": 0,
//     "SiteName": '',
//     "BranchID": '',
//     "SiteAddress": '',
//     "clientID": 0
// }

let tempSiteObj = {
    "SiteID": 0,
    "SiteName": '',
    "BranchID": '',
    "SiteAddress": '',
    "clientID": 0,
    "AddCustomerName":'',
    "CountryName":0,
    "StateName":0,
    "CityName":'',
    "SiteRegionName":'',
    "SitePincode":'',
    "SiteArea":'',
}

// let interfaceObj = {
//     "siteid": 0,
//     "interfaceId":0,
//     "InterfaceName": '',
//     "InterfaceType": 0,
//     "InterfaceSubType": 0,
//     "InterfaceIP": '',
//     "InterfacePort":'',
//     "InterfaceUsername":'',
//     "InterfacePassword":'',
// }

export default function ConfigSideBar({ list, onNavigate, setIsItemClick,selectedSubMenu,
    setSelectedSubMenu, onMoveNext, selectedMainMenu, selectedMenu, setSelectedMenu, setSelectedSite,setSelectedInterface, setAlertTemplateObj, setIsAlertTemplateEdit, setIsAlertTempFormEnabled, setIsEmptyFormAlert,
    setDeviceTemplateList, setDevTemClicked, setSelTempDevice, setDisplayButtons, isDarkTheme, setActivityLogShow, setInterfaceDataList
}) {
    //const [selectedMenu, setSelectedMenu] = useState('Site Template')
    
    useEffect(() => {
        if (selectedSubMenu === 'Site Configuration' || selectedSubMenu === "Manage Devices" || selectedSubMenu === "Manage Site Groups") {
            setIsItemClick(false);
        }
        if (selectedSubMenu === 'Add Alert Template' || selectedSubMenu === "Manage Workflow") {
            setIsItemClick(false);
        }
        if (selectedSubMenu === 'Add User' || selectedSubMenu === "FR Assignment" || selectedSubMenu === "Data Access Group") {
            setIsItemClick(false);
        }
    },)

    useEffect(() => {
        if (selectedMainMenu) {
            setSelectedMenu(selectedMainMenu)
        }
    }, [selectedMainMenu])
    

    const { collapsed, setCollapsed } = useContext(DrawerContext);
    const [isActive, setActive] = useState(true);
    const [isOpen, setOpen] = useState(true);
    const [singleLink, setSingleLink] = useState(true);
    const SidebarToggle =(e)=>{
      setActive(!isActive);
     
    }
    const Multiselect=(e)=>{
      setOpen(!isOpen);
      e.stopPropagation();
    }
    return (
        <div>
               <div className={collapsed ? 'sidebar-minimized' : ''}>
                    <div className="sidebar left updatedSidebar">
                        <nav className="sidebar-nav">
                            <div className="menu-list">
                                <ul id="menu-content"  className="navList borderLineList">
                                    <li 
                                style={{ display: IS_SITE_MANAGEMENT_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => { 
                                        if(selectedMenu == 'Site Management' && selectedSubMenu == 'Site Configuration'){
                                            return;
                                        }
                                        setSelectedMenu('Site Management'); onMoveNext('siteTemplate');setSelectedSubMenu('Site Configuration');setSelectedSite(tempSiteObj); 
                                        setInterfaceDataList(null)
                                    // setSelectedInterface(interfaceObj)
                                    }}
                                    className={selectedMenu == "Site Management" ? "nav-item nav-dropdown open" : 'nav-item nav-dropdown'}>
                                         <a className='nav-link'>
                                            {
                                                !isDarkTheme ?
                                                <>
                                                    <img src={selectedMenu == "Site Management" ? SiteTemplateLocWhite : SiteTemplateLocWhite} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 ${selectedMenu == "Site Management" ? "nav-label" : "nav-label"}`}>Site Management</span>
                                                </>
                                                :
                                                <>
                                                    <img src={selectedMenu == "Site Management" ? SiteTemplateLocWhite : SiteTemplateLocBlue} alt="Logo" height={17} width={17} />
                                                    <span className={`pl-3 ${selectedMenu == "Site Management" ? "nav-label" : "nav-label"}`}>Site Management</span>
                                                </>
                                            }
                                            {/* <img src={selectedMenu == "Site Management" ? SiteTemplateLocWhite : SiteTemplateLocBlue} alt="Logo" height={17} width={17} />
                                            <span className={`pl-3 nav-label`}>Site Management</span> */}
                                         </a>
                                    </li>
                                    {selectedMenu == "Site Management" &&
                                        <ul className={`nav-dropdown-items`} id="products">
                                            <li 
                                style={{ display: IS_SITE_MANAGEMENT_TAB_HIDDEN ? 'none' : 'block' }}
                                            className={`${selectedSubMenu == 'Site Configuration' ? 'active-sub-menu' : ''}`} 
                                            onClick={() => {
                                                if(selectedMenu == 'Site Management' && selectedSubMenu == 'Site Configuration'){
                                                    return;
                                                }
                                                onNavigate('Site Configuration');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Site Configuration');
                                                setSelectedSite(tempSiteObj);
                                                setInterfaceDataList(null)
                                                // setSelectedInterface(interfaceObj)
                                            }}>
                                                {/*  <img src={NumberOneBlue} alt="Logo" height={17} width={17} /> */}
                                                <span className='sidebarLabel'>Site Configuration</span>
                                            </li>
                                            {/* <li className={`${selectedSubMenu == 'Interface' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Interface');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Interface');
                                            }}>
                                                <img src={NumberTwoBlue} alt="Logo" height={17} width={17} />
                                                <span className='sidebarLabel'>Interface</span>
                                            </li> */}
                                            {/* <li className={`${selectedSubMenu == 'Manage Devices' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Manage Devices');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Manage Devices');
                                            }}>
                                                <span className='sidebarLabel'>Manage Devices</span>
                                            </li> */}
                                            <li 
                                style={{ display: IS_SITE_MANAGEMENT_TAB_HIDDEN ? 'none' : 'block' }}
                                            className={`${selectedSubMenu == 'Manage Site Groups' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Manage Site Groups');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Manage Site Groups');
                                            }}>
                                                {/* <img src={NumberThreeBlue} alt="Logo" height={17} width={17} /> */}
                                                <span className='sidebarLabel'> Manage Site Groups</span>
                                            </li>
                                        </ul>
                                    }
                                    <li 
                                style={{ display: IS_ALERT_TEMPLATE_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => { setSelectedMenu('Alert Template');onMoveNext('alertTemplate'); setSelectedSubMenu('Add Alert Template'); 
                                    let tObj = {
                                        templateId : "",
                                        templateName: "",
                                        templateClientId: "0",
                                        templateSiteId: [],
                                        templateDeviceTypeId: "0",
                                        templateDeviceSubTypeId: "0",
                                        templateDevice: [],
                                        templateAlertType: "0",
                                        scheduleType: "NA",
                                        schedulerObj : {},
                                        tempSelectedSites:[],
                                        tempSelectedDevices:[],
                                    }
                                    setAlertTemplateObj(tObj);
                                    setIsAlertTemplateEdit(true)
                                    setIsAlertTempFormEnabled(true);
                                    setIsEmptyFormAlert(true);
                                    setDeviceTemplateList([]);
                                    setDevTemClicked(false);
                                    setSelTempDevice(null);
                                    setDisplayButtons(true);
                                    setActivityLogShow(false);
                                    }}
                                        className={` ${selectedMenu == "Alert Template" ? "nav-item nav-dropdown open" : 'nav-item nav-dropdown'}`}>
                                            <a className='nav-link'>
                                                {
                                                    !isDarkTheme ?
                                                    <>
                                                        <img src={selectedMenu == "Alert Template" ? alertSelected : alertSelected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 nav-label`}>Alert Template</span>
                                                    </>
                                                    :
                                                    <>
                                                        <img src={selectedMenu == "Alert Template" ? alertSelected : alertUnselected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 nav-label`}>Alert Template</span>
                                                    </>
                                                }
                                                {/* <img src={selectedMenu == "Alert Template" ? AlertTemplateWhite : AlertTemplateBlue} alt="Logo" height={17} width={17} /> */}
                                                {/* <img src={selectedMenu == "Alert Template" ? alertSelected : alertUnselected} alt="Logo" height={17} width={17} />
                                                <span className={`pl-3 nav-label`}>Alert Template</span> */}
                                            </a>
                                       
                                    </li>
                                    {
                                    selectedMenu == "Alert Template" &&
                                    <ul className={`nav-dropdown-items`} id="products">
                                        <li className={`${selectedSubMenu == 'Add Alert Template' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('Add Alert Template');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('Add Alert Template')
                                        }}>
                                            {/* <img src={NumberOneBlue} alt="Logo" height={17} width={17} /> */}
                                            <span className='sidebarLabel'> Add Alert Template</span>
                                        </li>
                                        <li className={`${selectedSubMenu == 'Manage Workflow' ? 'active-sub-menu' : ''}`} onClick={() => {
                                            onNavigate('Manage Workflow');
                                            setIsItemClick(false);
                                            setSelectedSubMenu('Manage Workflow');
                                        }}>
                                            {/* <img src={NumberTwoBlue} alt="Logo" height={17} width={17} /> */}
                                            <span className='sidebarLabel'>Manage Workflow</span>
                                        </li>
                                    </ul> 
                                    }
                                    <li 
                                style={{ display: IS_USERS_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => { setSelectedMenu('Users');onMoveNext('users'); setSelectedSubMenu('Add User');}}
                                        className={` ${selectedMenu == "Users" ? "nav-item nav-dropdown open" : 'nav-item nav-dropdown'}`}>
                                            <a className='nav-link'>
                                                {
                                                    !isDarkTheme ?
                                                    <>
                                                        <img src={selectedMenu == "Users" ? UsersWhite : UsersWhite} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 nav-label`}>Users</span>
                                                    </>
                                                    :
                                                    <>
                                                        <img src={selectedMenu == "Users" ? UsersWhite : UsersBlue} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 nav-label`}>Users</span>
                                                    </>
                                                }
                                                {/* <img src={selectedMenu == "Users" ? UsersWhite : UsersBlue} alt="Logo" height={17} width={17} />
                                                <span className={`pl-3 nav-label`}>Users</span> */}
                                            </a>
                                    </li>
                                        {
                                        selectedMenu == "Users" &&
                                        <ul className={`nav-dropdown-items`} id="products">
                                            <li className={`${selectedSubMenu == 'Add User' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Add User');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Add User');
                                            }}>
                                                {/* <img src={NumberOneBlue} alt="Logo" height={17} width={17} /> */}
                                                <span className='sidebarLabel'> Add User</span>
                                            </li>
                                            <li className={`${selectedSubMenu == 'FR Assignment' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('FR Assignment');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('FR Assignment');
                                            }}>
                                                {/* <img src={NumberTwoBlue} alt="Logo" height={17} width={17} /> */}
                                                <span className='sidebarLabel'>FR Assignment</span>
                                            </li>
                                            {
                                            <li className={`${selectedSubMenu == 'Data Access Group' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Data Access Group');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Data Access Group');
                                            }}>
                                                {/* <img src={NumberThreeBlue} alt="Logo" height={17} width={17} /> */}
                                                <span className='sidebarLabel'>  Data Access Group</span>
                                            </li>
                                            }
                                        </ul> 
                                        }
                                    <li 
                                style={{ display: IS_NOTIFICATION_SETTING_TAB_HIDDEN ? 'none' : 'block' }}
                                    onClick={() => { setSelectedMenu('Notification Setting');onMoveNext('notification'); }}
                                        className={` ${selectedMenu == "Notification Setting" ? "nav-item nav-dropdown open noDrop navPage" : 'nav-item nav-dropdown navPage'}`}>
                                             <a className='nav-link'>
                                                {
                                                    !isDarkTheme ?
                                                    <>
                                                        <img src={selectedMenu == "Notification Setting" ? NotificationSelected : NotificationSelected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 nav-label`}>Notification Setting</span>    
                                                    </>
                                                    :
                                                    <>
                                                        <img src={selectedMenu == "Notification Setting" ? NotificationSelected : NotificationUnSelected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 nav-label`}>Notification Setting</span>
                                                    </>
                                                }
                                                {/* <img src={selectedMenu == "Notification Setting" ? NotificationSelected : NotificationUnSelected} alt="Logo" height={17} width={17} />
                                                <span className={`pl-3 nav-label`}>Notification Setting</span> */}
                                             </a>
                                    </li>

                                    {/* <li 
                                style={{ display: IS_BRANDMASTER ? 'none' : 'block' }}
                                    onClick={() => { setSelectedMenu('Brand Master');onMoveNext('brandMaster') }}
                                        className={` ${selectedMenu == "Brand Master" ? "nav-item nav-dropdown open noDrop navPage" : 'nav-item nav-dropdown navPage'}`}>
                                             <a className='nav-link'>
                                                {
                                                    !isDarkTheme ?
                                                    <>
                                                        <img src={selectedMenu == "Brand Master" ? NotificationSelected : NotificationSelected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 nav-label`}>Brand Master</span>    
                                                    </>
                                                    :
                                                    <>
                                                        <img src={selectedMenu == "Brand Master" ? NotificationSelected : NotificationUnSelected} alt="Logo" height={17} width={17} />
                                                        <span className={`pl-3 nav-label`}>Brand Master</span>
                                                    </>
                                                }
                                             </a>
                                    </li> */}

                                    {/* <li onClick={() => { setSelectedMenu('Review');onMoveNext('review'); setSelectedSubMenu('Site Template');}}
                                        className={` ${selectedMenu == "Review" ? "nav-item nav-dropdown open" : 'nav-item nav-dropdown'}`}>
                                             <a className='nav-link'>
                                                <img src={selectedMenu == "Review" ? ReviewWhite : ReviewBlue} alt="Logo" height={17} width={17} />
                                                <span className={`pl-3 nav-label`}>Review</span>
                                             </a>
                                    </li> */}
                                        {
                                        selectedMenu == "Review" &&
                                        <ul className={`nav-dropdown-items`} id="products">
                                            <li className={`${selectedSubMenu == 'Site Template' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Site Template');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Site Template');
                                            }}>
                                                {/* <img src={NumberOneBlue} alt="Logo" height={17} width={17} /> */}
                                                <span className='sidebarLabel'> Site Template</span>
                                            </li>
                                            <li className={`${selectedSubMenu == 'Devices' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Devices');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Devices');
                                            }}>
                                                {/* <img src={NumberTwoBlue} alt="Logo" height={17} width={17} /> */}
                                                <span className='sidebarLabel'> Devices</span>
                                            </li>
                                            <li className={`${selectedSubMenu == 'Alert Template' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Alert Template');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Alert Template');
                                            }}>
                                                {/* <img src={NumberThreeBlue} alt="Logo" height={17} width={17} /> */}
                                                <span className='sidebarLabel'> Alert Template</span>
                                            </li>
                                            <li className={`${selectedSubMenu == 'Users' ? 'active-sub-menu' : ''}`} onClick={() => {
                                                onNavigate('Users');
                                                setIsItemClick(false);
                                                setSelectedSubMenu('Users');
                                            }}>
                                               {/*  <img src={NumberFourBlue} alt="Logo" height={17} width={17} /> */}
                                                 <span className='sidebarLabel'> Users</span>
                                            </li>
                                        </ul> 
                                        }
                                </ul>
                            </div>
                            <label className='versionLabel'>Version: {VERSION}</label>
                        </nav>
                    </div>
                </div>
            {/* <div className="sidebar">
                <div className="nav-side-menu">
                    <i className="fa fa-bars fa-2x toggle-btn" data-toggle="collapse" data-target="#menu-content"></i>
                    <div className="menu-list">
                        <ul id="menu-content" className="menu-content out">

                            <li onClick={() => { setSelectedMenu('Site Template'); onMoveNext('siteTemplate');setSelectedSubMenu('Site Details'); }}
                                className={selectedMenu == "Site Template" ? "sidebarActive" : ''}>
                                <img src={selectedMenu == "Site Template" ? SiteTemplateLocWhite : SiteTemplateLocBlue} alt="Logo" height={17} width={17} />
                                <span className={`sidebarLabel activeText`}>Site Template</span>
                            </li>
                            {selectedMenu == "Site Template" &&
                                <ul className={`sub-menu collapse show`} id="products">
                                    <li className={`pl-4 ${selectedSubMenu == 'Site Details' ? 'active active-sub-menu' : ''}`} 
                                    onClick={() => {
                                        onNavigate('Site Details');
                                        setIsItemClick(false);
                                        setSelectedSubMenu('Site Details');
                                        setSelectedSite(tempSiteObj);
                                    }}> <img src={NumberOneBlue} alt="Logo" height={17} width={17} />
                                        <span className='sidebarLabel'> Site Configuration</span>
                                    </li>
                                    <li className={`pl-4 ${selectedSubMenu == 'Manage Devices' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                        onNavigate('Manage Devices');
                                        setIsItemClick(false);
                                        setSelectedSubMenu('Manage Devices');
                                    }}><img src={NumberTwoBlue} alt="Logo" height={17} width={17} />
                                        <span className='sidebarLabel'> Manage Devices</span>
                                    </li>
                                    <li className={`pl-4 ${selectedSubMenu == 'Manage Site Groups' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                        onNavigate('Manage Site Groups');
                                        setIsItemClick(false);
                                        setSelectedSubMenu('Manage Site Groups');
                                    }}><img src={NumberThreeBlue} alt="Logo" height={17} width={17} />
                                    <span className='sidebarLabel'> Manage Site Groups</span>
                                    </li>
                                </ul>
                            }

                            <li onClick={() => { setSelectedMenu('Alert Template');onMoveNext('alertTemplate'); setSelectedSubMenu('Alert Template'); }}
                                className={` ${selectedMenu == "Alert Template" ? "sidebarActive" : ''}`}>
                                <img src={selectedMenu == "Alert Template" ? AlertTemplateWhite : AlertTemplateBlue} alt="Logo" height={17} width={17} />
                                <span className={`pl-3  sidebarLabel activeText`}>Alert Template</span>
                            </li>
                            {
                            selectedMenu == "Alert Template" &&
                            <ul className={`sub-menu collapse show`} id="products">
                                <li className={`pl-4 ${selectedSubMenu == 'Alert Template' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                    onNavigate('Alert Template');
                                    setIsItemClick(false);
                                    setSelectedSubMenu('Alert Template')
                                }}><img src={NumberOneBlue} alt="Logo" height={17} width={17} />
                                <span className='sidebarLabel'> Add Alert Template</span>
                                </li>
                                <li className={`pl-4 ${selectedSubMenu == 'Add Workflow' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                    onNavigate('Add Workflow');
                                    setIsItemClick(false);
                                    setSelectedSubMenu('Add Workflow');
                                }}><img src={NumberTwoBlue} alt="Logo" height={17} width={17} />
                                <span className='sidebarLabel'>Manage Workflow</span>
                                </li>
                            </ul> 
                            }
                            <li onClick={() => { setSelectedMenu('Users');onMoveNext('users'); setSelectedSubMenu('Add User');}}
                                className={` ${selectedMenu == "Users" ? "sidebarActive" : ''}`}>
                                <img src={selectedMenu == "Users" ? UsersWhite : UsersBlue} alt="Logo" height={17} width={17} />
                                <span className={`pl-3  sidebarLabel activeText`}>Users</span>
                            </li>
                            {
                            selectedMenu == "Users" &&
                            <ul className={`sub-menu collapse show`} id="products">
                                <li className={`pl-4 ${selectedSubMenu == 'Add User' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                    onNavigate('Add User');
                                    setIsItemClick(false);
                                    setSelectedSubMenu('Add User');
                                }}><img src={NumberOneBlue} alt="Logo" height={17} width={17} />
                                <span className='sidebarLabel'> Add User</span>
                                </li>
                                <li className={`pl-4 ${selectedSubMenu == 'FR Assignment' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                    onNavigate('FR Assignment');
                                    setIsItemClick(false);
                                    setSelectedSubMenu('FR Assignment');
                                }}><img src={NumberTwoBlue} alt="Logo" height={17} width={17} />
                                <span className='sidebarLabel'> FR Assignment</span>
                                </li>
                                <li className={`pl-4 ${selectedSubMenu == 'Data Access Group' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                    onNavigate('Data Access Group');
                                    setIsItemClick(false);
                                    setSelectedSubMenu('Data Access Group');
                                }}><img src={NumberThreeBlue} alt="Logo" height={17} width={17} />
                                <span className='sidebarLabel'> Data Access Group</span>
                                </li>
                            </ul> 
                            }
                            <li onClick={() => { setSelectedMenu('Review');onMoveNext('review'); setSelectedSubMenu('Site Template');}}
                                className={` ${selectedMenu == "Review" ? "sidebarActive" : ''}`}>
                                <img src={selectedMenu == "Review" ? ReviewWhite : ReviewBlue} alt="Logo" height={17} width={17} />
                                <span className={`pl-3  sidebarLabel activeText`}>Review</span>
                            </li>
                            {
                            selectedMenu == "Review" &&
                            <ul className={`sub-menu collapse show`} id="products">
                                <li className={`pl-4 ${selectedSubMenu == 'Site Template' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                    onNavigate('Site Template');
                                    setIsItemClick(false);
                                    setSelectedSubMenu('Site Template');
                                }}><img src={NumberOneBlue} alt="Logo" height={17} width={17} />
                                <span className='sidebarLabel'> Site Template</span>
                                </li>
                                <li className={`pl-4 ${selectedSubMenu == 'Devices' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                    onNavigate('Devices');
                                    setIsItemClick(false);
                                    setSelectedSubMenu('Devices');
                                }}><img src={NumberTwoBlue} alt="Logo" height={17} width={17} />
                                <span className='sidebarLabel'> Devices</span>
                                </li>
                                <li className={`pl-4 ${selectedSubMenu == 'Alert Template' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                    onNavigate('Alert Template');
                                    setIsItemClick(false);
                                    setSelectedSubMenu('Alert Template');
                                }}><img src={NumberThreeBlue} alt="Logo" height={17} width={17} />
                                    <span className='sidebarLabel'> Alert Template</span>
                                </li>
                                <li className={`pl-4 ${selectedSubMenu == 'Users' ? 'active active-sub-menu' : ''}`} onClick={() => {
                                    onNavigate('Users');
                                    setIsItemClick(false);
                                    setSelectedSubMenu('Users');
                                }}><img src={NumberFourBlue} alt="Logo" height={17} width={17} /><span className='sidebarLabel'> Users</span>
                                </li>
                            </ul> 
                            }
                        </ul>
                    </div>
                </div>
                <label className='versionLabel'>Version: {VERSION}</label>
            </div> */}
        </div>
    )
}
