import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player';
import { useVideo } from '../../CustomHooks/useVideo'
import captureVideoFrame from "capture-video-frame"
import screenfull from "screenfull"

export default function ReactCustomPlayer(props) {
  const ref = useRef()
  const [state, setState] = useState({
    url: null,
    pip: false,
    playing: true,
    controls: false,
    light: false,
    volume: 0.8,
    muted: true,
    played: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    loaded: 0,
    seeking: false,
  });

  const {
    url,
    pip,
    playing,
    controls,
    light,
    volume,
    muted,
    played,
    duration,
    playbackRate,
    loop,
    loaded,
    seeking,

  } = state;
  const { count, setCount, videoStartBuffering, videoStopBuffering, videoErrorHandler, getHeaders } = useVideo();
const [capturedImage, setCapturedImage] = useState(null);
// const videoRef = useRef(null);



  const handleSeekMouseDown = () => {
    setState({ ...state, seeking: true });
  }

  const handleSeekChange = e => {
    setState({ ...state, played: parseFloat(e.target.value) });
  }

  const handleSeekMouseUp = e => {
    setState({ ...state, seeking: false });
    ref.current.seekTo(parseFloat(e.target.value))
  }

  const handleProgress = changeState => {
    // We only want to update time slider if we are not currently seeking
    if (!state.seeking) {
      setState({ ...state, ...changeState });
    }
  }

  const onSeekChange = (e) => {
  }

  const handlePlay = () => {
    setState({ ...state, playing: true });
  }
/* captureImage */
const handleCaptureFrame = (params) => {
  
  console.log("handleCaptureFrame",ref)
  console.log("Rupesh checks videoRef", ref.current.getInternalPlayer());
  const video = ref.current.getInternalPlayer();

  if (typeof video === 'string') {
    video = console.log("rupesh checks",video);
}
    const frame = captureVideoFrame(params)
    console.log('captured frame', frame)
          setCapturedImage(frame.dataUri)
          var fileUrl = frame.dataUri;
          // captureVideoFrameData(capturedImage)
          props.captureVideoFrameData(fileUrl)
  // const popupWindow = window.open('', '_blank', 'width=540,height=360');
  // popupWindow.document.open();
  // popupWindow.document.write(`
  // <html>
  // <body>
  //   <img src="${fileUrl}" alt="Screenshot" width="300" height="300" />
  //   <br />
  //   <button class="DataAccessEdit" onclick="capturedFrameDownloadClick('${fileUrl}')">
  //   <img src=${DownloadIcon} alt="download icon" />
  // </button>
  // </body>
  // <script>
  // function capturedFrameDownloadClick(imageData){
  //   var fileUrl = imageData;
  //   fetch(fileUrl)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       debugger
  //       var link = window.document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob, { type: "image" });
  //       link.download = "test.png";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     });              
  // }
  // </script>
  // </html>`);
  // popupWindow.document.close();
};
/* captureImage */
// useEffect(() => {
//   //debugger
//   console.log("rupesh checks",props?.isSnapShotBtnClick)
//   if(props?.isSnapShotBtnClick == true){
//       setTimeout(() => {
//           handleCaptureFrame(ref.current.getInternalPlayer())
//           props.setIsSnapShotBtnClick(false)  
//       }, 1000);
     
//   }
// }, [props.isSnapShotBtnClick])

useEffect(() => {
  //debugger
  console.log("handleCaptureFrame",props?.isSnapShotBtnClickPb)
  if(props?.isSnapShotBtnClickPb){
      setTimeout(() => {
        console.log("handleCaptureFrame",props?.isSnapShotBtnClickPb)
          handleCaptureFrame(ref.current.getInternalPlayer())
          props.setIsSnapShotBtnClickPb(false)  
      }, 1000);
     
  }
}, [props.isSnapShotBtnClickPb])

useEffect(() => {
  console.log("isFullScreenPlayback", props.isFullScreenPlayback, props.randomId)
  // if(props.isFullScreenPlayback != null){
  //   console.log("isFullScreenPlayback", props.isFullScreenPlayback)
  //   screenfull.toggle(props.playbackRef?.current);
  //   let c = count + 1;
  //   setCount(c);
  // }
  if(props.isFullScreenPlayback && props.randomId != 0){
    console.log("isFullScreenPlayback true", props.isFullScreenPlayback)
    screenfull.toggle(props.playbackRef?.current);
    let c = count + 1;
    setCount(c);
    // props.setRandomId(0);
  }else if(!props.isFullScreenPlayback && props.randomId != 0){
    console.log("isFullScreenPlayback false", props.isFullScreenPlayback)
    screenfull.toggle(props.playbackRef?.current);
    let c = count + 1;
    setCount(c);
    // props.setRandomId(0);
  }
}, [props.isFullScreenPlayback])

  return (
    <>
      <ReactPlayer
        // key={props.count}
        key={count}
        muted
        // id={"video1"}
        id={props.id}
        // url="http://3.108.30.150:3000/stream/533_42876910-e526-42e6-aa38-9614beae4f29/index.m3u8"
        url={props.url}
        playing={playing} //rg
        // playing={true}
        // className="player-responsive"
        className={props?.isFullScreenPlayback ? "player-responsive-pb" : "player-responsive"}
        height={props?.isFullScreenPlayback ? "100%" : "500px !important"}
        width={props?.isFullScreenPlayback ? "100%" : ""}
        // height="500px !important"
        controls={true}
        autoPlay
        // style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }}
        ref={ref}
        onSeek={e => onSeekChange(e)}
        // onPlay={handlePlay}
        onProgress={handleProgress}
        onPlay={handlePlay}
        onBuffer={(e) => videoStartBuffering(e, props.devname)}
        onBufferEnd={(e) => {
          videoStopBuffering(e, props.devname);
        }}
        onError={(e) => videoErrorHandler(e, props.devname)}
        //rg
        // onBuffer={(e) => props.videoStartBuffering(e, props.devname)}
        // onBufferEnd={(e) => props.videoStopBuffering(e, props.devname)}
        // onError={(e) => 
        //   {
        //     props.videoErrorHandler(e, props.devname)
        //   }
        //   }
        config={{
          file: {
            attributes: {
              controlsList: "noplaybackrate nodownload",
              // disablePictureInPicture: isMultiPlay ? false : true, // hide and display pip
              // controlsList: isMultiPlay ? "noplaybackrate" : "noplaybackrate nodownload",
              onContextMenu: (e) => e.preventDefault(),
            },
            tracks: [],
            forceVideo: true,
            forceAudio: true,
            // http: {
            //     headers: props.getHeaders(),
            // },
          },
        }}
      //rg ends
      />
      {/* <p className='customplayer-label'>Playing from last 2 min of alert time.</p> */}
      {/* <p className='customplayer-label'>Playing from 1 min before the alert time.</p> */}
      {/* <input
        type='range' min={0} max={0.9999} step='any'
        value={played}
        onMouseDown={handleSeekMouseDown}
        onChange={handleSeekChange}
        onMouseUp={handleSeekMouseUp}
        className="slider"
      /> */}
    </>
  )

}