import React, { useState } from "react"
import { Modal } from 'react-bootstrap'



export default function ContactStateOfficalComponent(props) {
    const [isOpenmodal, setisOpenmodal] = useState(true)

    const ClosePreview = () => {
        setisOpenmodal(false)
        props.Closemodal(false)
    }

    return(
        <Modal
            centered
            show={isOpenmodal}
            onHide={ClosePreview}
            backdrop="static"
            keyboard={false}>
            <Modal.Header closeButton style={{padding: "0 1rem",borderBottom: "0" }}>{props.Selectedcontacttitle}</Modal.Header>
            <Modal.Body>
                <div>
                    {props.Selectedcontactlist &&
                        props.Selectedcontactlist.map(contact => (
                            <div id={contact.name}>
                            <p>{contact.name}{" : "}
                            <span className="notification-link" style={{ cursor: "pointer" }}
                                onClick={() => props.MakeCall(contact.phoneNumber)}
                            >{contact.phoneNumber}</span></p>
                            </div>
                        ))
                    }
                    {!props.Selectedcontactlist &&
                    <>Contacts not found!</>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}