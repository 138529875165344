import ReactPlayer from 'react-player'
import { Row, Col, OverlayTrigger, Tooltip} from 'react-bootstrap'
import { useState, useEffect, useMemo, useRef } from 'react'
import { useHistory } from 'react-router-dom'

// import MosaicNoStream from '../../assets/images/mosaic-no-stream.png'
// import BackArrowWhite from '../../assets/images/BackArrowWhite.svg'
// import MosaicIcon from '../../assets/images/mosaic-icon-white.svg'
// import ArrowIcon from '../../assets/images/arrow.svg'
// import ToggleIcon from '../../assets/images/toggle.svg'
// import AutoPlayOff from '../../assets/images/off-toggle.svg'
// import fullscreenIcon from '../../assets/images/fullscreenIcon.svg'
// import { FullscreenIcon } from '../../assets/images'
// import FullScreen from '../../assets/images/fullscreen-white.svg'

import {MosaicNoStream, BackArrowWhite, MosaicIcon, ArrowIcon, ToggleIcon, AutoPlayOff, FullScreen, TwowaySoundOff, MosaicHooterOn, MosaicHooterOnWhite, MosaicFullscreen, MosaicExitFullscreen, MosaicHooterOff, MosaicTwowayEnd, MosaicTwowayStart, MosaicTwowayStop, MosaicSnapshot, DownloadIcon, Save, Video_Export} from '../../assets/images'

import { Getdeviceslistdata } from '../../services/configurationServices'
import Pagination from '../../components/Pagination/Pagination'
import { mixpanel } from '../../utils/MixPanelUtil'
import { useVideo } from '../../CustomHooks/useVideo'
import { THIRTY_MINUTES } from '../../navigation/CONSTANTS'
import { CLOUD_STREAMING, IS_WEBRTC, NO_STREAM_INTERVAL_TIMER } from '../../config'
import { HooterOnOffRequest, ScreenShot, TwoWayCommunicationRequest } from '../../services/alertAuthService'
import screenfull from 'screenfull'
import captureVideoFrame from "capture-video-frame"
import WebRTCPlayer from '../Cameras/WebRTCPlayer'
import { ToastContainer } from 'react-toastify'
// import Player from '../Cameras/Player'


export default function MosaicContainer() {
    const [mosaicOption, setMosaicOption] = useState(1) // option value
    const [camerasUI, setCamerasUI] = useState(null)
    const [cameraDeviceList, setCameraDeviceList] = useState([])
    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(4) // page size matched with option value 1=>4(2X2), etc
    const [isMenuVisible, setIsMenuVisible] = useState(true)
    const [isAutoPlay, setIsAutoPlay] = useState(false)
    const [intervalId, setIntervalId] = useState(null);
    const [totalPageCount, setTotalPageCount] = useState(null)
    const [intervalTime, setIntervalTime] = useState(30);
    const [time, setTime] = useState(Date.now());
    const totalPageCountRef = useRef(null);
    const playerRef = useRef(null);
    const [isFullscreen, setIsFullscreen] = useState(false);
    // const [playUrl, setPlayUrl] = useState(''); 


    const options = [
       /*  { value: "10", label: "10 sec" },
        { value: "15", label: "15 sec" },
        { value: "20", label: "20 sec" },
        { value: "25", label: "25 sec" }, */
        { value: "30", label: "30 sec" },
        { value: "35", label: "35 sec" },
        { value: "40", label: "40 sec" },
        { value: "45", label: "45 sec" },
        { value: "50", label: "50 sec" },
        { value: "55", label: "55 sec" },
        { value: "60", label: "60 sec" }
    ];

    let history = useHistory()

    const getdeviceslistdata = () => {
        try {
            Getdeviceslistdata().then((resp) => {
                // let finalCamList = resp?.filter(x => x.DeviceTypeID == 1)
                let finalCamList = resp;
                setCameraDeviceList(finalCamList)
                setCurrentPage(1)
            })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        (async () => {
            getdeviceslistdata() //device master list
        })();

    }, [])

    // useEffect(() => {
    //     const intervalId = setInterval(() => {
    //         caches.keys().then(keys => {
    //             keys.forEach(key => {
    //                 caches.delete(key);
    //             });
    //         });
    //         window.location.reload(true);
    //     }, 30000);
 
    //     return () => {
    //         clearInterval(intervalId);
    //     };
    // }, []);

    function onArrowIconClick() {
        if (isMenuVisible == true) {
            setIsMenuVisible(false)
        }
        else {
            setIsMenuVisible(true)
        }
    }

    function OnAutoPlayHandle(autoPlayStatus) {
        if (autoPlayStatus == 'on') {
            setIsAutoPlay(false)
            autoPlayStop()
        }
        else {
            setIsAutoPlay(true)
        }
    }

    // For Autoplay feature:
    const autoPlayStart = () => {
        setCurrentPage(currentPage => {
            if (currentPage === totalPageCountRef?.current) {
                return 1;
            } else {
                return currentPage + 1;
            }
        })
    }

    const autoPlayStop = () => {
        clearInterval(intervalId);
    }

    const totalNoOfCameras = cameraDeviceList != null ? cameraDeviceList.length : 0;
    useEffect(() => {
        const totalPages = Math.ceil(totalNoOfCameras / pageSize)
        setTotalPageCount(totalPages)
        totalPageCountRef.current = totalPages
    }, [cameraDeviceList, mosaicOption])

    useEffect(() => {
        if (isAutoPlay) {
            let autoPlayInterval;
            clearInterval(autoPlayInterval)
            autoPlayInterval = setInterval(autoPlayStart, intervalTime * 1000);
            setIntervalId(autoPlayInterval);
            return () => clearInterval(autoPlayInterval)
        }
    }, [isAutoPlay, intervalTime])

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(Date.now())
        }, THIRTY_MINUTES); // set the interval to 30 min
        return () => clearInterval(intervalId); // clear the interval on unmount
    }, []);

    useEffect(() => {
        if(IS_WEBRTC == 0){
            if(cameraDeviceList.length > 0){
                const firstPageIndex = (currentPage - 1) * pageSize;
                const lastPageIndex = firstPageIndex + pageSize;
                let firstList = cameraDeviceList?.slice(firstPageIndex, lastPageIndex);
                let firstIndex = lastPageIndex ;
                let secondIndex = lastPageIndex + pageSize;
                let secondList = cameraDeviceList?.slice(firstIndex, secondIndex);
                console.log("Calling API start", firstIndex, secondIndex);
                console.log("Calling API start", secondList);
                console.log("Calling API start",currentPage);
                console.log("Calling API start ", Math.ceil(cameraDeviceList.length/pageSize))
                let maxCount = Math.ceil(cameraDeviceList.length/pageSize);
                if(maxCount == currentPage){
                    let firstArr = cameraDeviceList?.slice(0, pageSize);
                    for(let i=0; i<firstArr.length; i++){
                        if(CLOUD_STREAMING == '0'){
                            let baseUrl = "http://localhost:3000"
                        let tempbaseUrl = "http://localhost:3000";
                      let rtspurl = firstArr[i].RTSPURL
                      //get cam guid
                      let camguid = firstArr[i].StreamName
                      //do post req to base url
                      let actbaseUrl = baseUrl + "/start"
                      let IsTrancoding = false
                    //   if(secondList[i].istranscdng==1)
                    if(firstArr[i].isTranscoded == 1){
                        IsTrancoding=true
                    }
                    console.log("Calling API start", camguid, i);
                      fetch(actbaseUrl, {
                        method: 'POST',
                        body: JSON.stringify({
                          uri: rtspurl,
                          alias: camguid,
                          isencode:IsTrancoding
                        }),
                        headers: {
                          'Content-type': 'application/json; charset=UTF-8',
                        },
                      }).then((res) => res.json())
                        .then((data) => {
                          baseUrl = baseUrl + data['uri']
                        //   setPlayUrl(baseUrl)
                          //item.
                        })
                        .catch((err) => {
                        });
                        }
                    }
                }
                for(let i=0; i<secondList.length; i++){
                    if(CLOUD_STREAMING == '0'){
                        let baseUrl = "http://localhost:3000"
                    let tempbaseUrl = "http://localhost:3000";
                  let rtspurl = secondList[i].RTSPURL
                  //get cam guid
                  let camguid = secondList[i].StreamName
                  //do post req to base url
                  let actbaseUrl = baseUrl + "/start"
                  let IsTrancoding = false
                //   if(secondList[i].istranscdng==1)
                if(secondList[i].isTranscoded == 1){
                    IsTrancoding=true
                }
                console.log("Calling API start", camguid, i);
                  fetch(actbaseUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                      uri: rtspurl,
                      alias: camguid,
                      isencode:IsTrancoding
                    }),
                    headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                    },
                  }).then((res) => res.json())
                    .then((data) => {
                      baseUrl = baseUrl + data['uri']
                    //   setPlayUrl(baseUrl)
                      //item.
                    })
                    .catch((err) => {
                    });
                    }
                }
            }        
        }
    }, [currentPage, pageSize, cameraDeviceList])

    //** currentTableData is used for the list to be shown on the selected page
    const currentTableData = useMemo(() => {
        // debugger
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
        let pageList = cameraDeviceList?.slice(firstPageIndex, lastPageIndex);
        setCamerasUI(pageList)
        return pageList

    }, [currentPage, cameraDeviceList, pageSize]);

    //#endregion

    const onMosaicTypeChange = (e) => {
        setMosaicOption(e.target.value)

        let val = e.target.value

        if (val == 1) {
            try {
                mixpanel.track("Clicks on 2x2")
            } catch (error) {
                console.error(error)
            }
            setPageSize(4) //* For 2X2 per page cameras will be 4
            setIntervalTime(30)
        }
        else if (val == 2) {
            try {
                mixpanel.track("Clicks on 3x3")
            } catch (error) {
                console.error(error)
            }
            setPageSize(9)//* For 3X3 per page cameras will be 9
            setIntervalTime(30)
        }
        else if (val == 3) {
            try {
                mixpanel.track("Clicks on 4x4")
            } catch (error) {
                console.error(error)
            }
            setPageSize(16)//* For 4X4 per page cameras will be 16
            setIntervalTime(30)
        }
        else if (val == 4) {
            try {
                mixpanel.track("Clicks on 5x5")
            } catch (error) {
                console.error(error)
            }
            setPageSize(25)//* For 5X5 per page cameras will be 25
            setIntervalTime(30)
        }

        setCurrentPage(1)
    }

    const onMosaicTimeIntervalChange = (e) => {
        setIntervalTime(e.target.value);
        setCurrentPage(1)
    }

    const toggleFullscreen = () => {
        const doc = window.document;
        const docEl = doc.documentElement;

        const requestFullScreen =
            docEl.requestFullscreen ||
            docEl.mozRequestFullScreen ||
            docEl.webkitRequestFullScreen ||
            docEl.msRequestFullscreen;
        const exitFullScreen =
            doc.exitFullscreen ||
            doc.mozCancelFullScreen ||
            doc.webkitExitFullscreen ||
            doc.msExitFullscreen;

        if (!isFullscreen) {
            if (requestFullScreen) {
                requestFullScreen.call(docEl);
            }
            setIsFullscreen(true);
        } else {
            if (exitFullScreen) {
                exitFullScreen.call(doc);
            }
            setIsFullscreen(false);
        }
    };

    return (
        <div className="main-mosaic">
            {isMenuVisible &&
                <div id="myNav" className="overlay">
                    <div className='inner-row'>
                        <div className="item-align">
                           {/*  <button onClick={e => history.goBack()} className='btn CameraBackBtn'>
                                <img className='back-arrow' src={BackArrowWhite} alt='back arrow' ></img>
                                <span style={{ fontSize: "12px" }}>Cameras</span>
                            </button> */}
                        </div>

                        <div className='MosaicGrid'>
                            {/* <Col lg={10} className="item-align"> */}
                                <div className="mosaic-popup item-align">
                                <img src={MosaicIcon} alt="Mosaic icon" />
                                <span className="mosaic-popup-text">
                                            Mosaic
                                </span>
                                <select className="select-ext mosaic-select" defaultValue={mosaicOption} onChange={e => onMosaicTypeChange(e)} >
                                            <option id="4" value="1">2 x 2</option>
                                            <option id="9" value="2">3 x 3</option>
                                            <option id="16" value="3">4 x 4</option>
                                            {/* <option id="25" value="4">5 x 5</option> */}
                                </select>
                                    {/* <div className="col-lg-1 pl-0">
                                        
                                    </div>
                                    <div className="col-lg-5 pl-0" >
                                        
                                    </div>
                                    <div className="col-lg-4 w-100 p-0">
                                                                        
                                    </div> */}
                                </div>
                                <div className="mosaic-popup item-align mosaic-content">
                                <span className="auto-play-text">
                                            Auto Play
                                </span>
                                {isAutoPlay ? <img src={ToggleIcon} alt="Mosaic icon" onClick={() => OnAutoPlayHandle('on')} />
                                : <img src={AutoPlayOff} alt="Mosaic icon" onClick={() => OnAutoPlayHandle('off')} />}

                                    {/* <div className="col-lg-9 pl-0" >
                                        
                                    </div>
                                    <div className="col-lg-3 pl-0">
                                        

                                    </div> */}

                                </div>
                                <div className="mosaic-popup item-align mosaic-content">
                                    <span className="auto-play-text">
                                        {/* {JSON.stringify(camerasUI.length)}  of {totalNoOfCameras} */}
                                        {`${camerasUI && JSON.stringify(camerasUI.length)} of ${totalNoOfCameras}`}
                                        {/* If data is present in the camerasUI then other condition will execute. */}
                                    </span>
                                    <Pagination
                                        currentPage={currentPage}
                                        totalCount={cameraDeviceList != null ? cameraDeviceList.length : 0}
                                        pageSize={pageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                        pageName='mosaic'
                                    />
                                    <>
                                        <select className="select-ext mosaic-select" value={intervalTime} onChange={e => onMosaicTimeIntervalChange(e)}>
                                            {options.filter(option => {
                                                if (pageSize === 4) return true;
                                                if (pageSize === 9) return option.value >= 30;
                                                if (pageSize === 16) return option.value >= 30;
                                                if (pageSize === 25) return option.value >= 30;
                                                return false;
                                            }).map(option => (
                                                <option key={option.value} value={option.value} id="mosaic-timeInterval">{option.label}</option>
                                            ))}
                                        </select>
                                    </>
                                    <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                                <Tooltip>
                                                    FullScreen
                                                </Tooltip>
                                            }
                                        >
                                    <div className=" fullscreen-icon col-lg-1 pl-0 ml-3">
                                        {/* <button onClick={toggleFullscreen}> */}
                                            {/* <img src={fullscreenIcon} alt="fullscreen icon" onClick={toggleFullscreen} /> */}
                                            <img src={FullScreen} alt="fullscreen icon" onClick={toggleFullscreen} />
                                        {/* </button> */}
                                        {/* <button onClick={toggleFullscreen}><img src={FullscreenIcon} alt="fullscreen icon" /></button> */}
                                    </div>
                                    </OverlayTrigger>
                                </div>
                        </div>
                        {/* </Col> */}

                    </div>
                </div>
            }
            <div className={isMenuVisible ? 'arrow-icon' : 'arrow-icon arrow-icon-collapse'}>
                <Row>
                    <Col><img src={ArrowIcon} onClick={() => onArrowIconClick()}></img></Col>
                </Row>
            </div>

            
          {/*   <div className="row mosaic-row wrapper"> */}
            <div className="mosaic-row">
                <>      
                    {
                        mosaicOption == 1 &&
                        <Matrix
                            cameras={camerasUI}
                            gridType="parent2x2"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                        />
                    }

                     {
                        mosaicOption == 2 &&
                        <Matrix
                            cameras={camerasUI}
                            gridType="parent3x3"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                        />
                    }

                    {
                        mosaicOption == 3 &&
                        <Matrix
                            cameras={camerasUI}
                            gridType="parent4x4"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                        />
                    }

                    {
                        mosaicOption == 4 &&
                        <Matrix
                            cameras={camerasUI}
                            gridType="parent5x5"
                            height={"100%"}
                            width={"100%"}
                            playerRef={playerRef}
                            refreshTime={time}
                            currentPage={currentPage}
                        />
                    }
                </>
            </div>

            <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
            
        </div>
    )
}

const IndividualMosaiCameras = ({ camera, gridType, height, width, playerRef, refreshTime, currentPage, item, setCount, count }) => {
    const [isMosaicHooterOn, setIsMosaicHooterOn] = useState(true);
    const [isMosaicSoundOn, setIsMosaicSoundOn] = useState(true);
    const [isMosaicFullscreenClick, setIsMosaicFullscreenClick] = useState(false);
    const videoContainerRef = useRef(null);
    const videoContainerParentRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null);
    let history = useHistory()

    //for Mosaic Hooter Implementation
    const HooterOnOffReq = async (privateIPAddress, camPort, action, panelBrand, CCTVType
        , user, password, BrandName) => {
        try {
            if (privateIPAddress) {
                HooterOnOffRequest(privateIPAddress, camPort, action, panelBrand, CCTVType
                    , user, password, BrandName).then((resp) => {
                        return resp
                    })
            }
        } catch (error) {
            console.error(error)
        }
    }
    function MosaicHooterClick(hooter, camera, isCloseBtn) {
        console.log("hooter", hooter, camera);
        var resp = "";
        if (hooter && hooter == 'on') {
            setIsMosaicHooterOn(false)
            resp = HooterOnOffReq(camera.privateIPAddress, camera.camPort, 'on', camera.panelBrand, camera.CCTVType
                , camera.camUserName, camera.camPassword, camera.BrandName)
        }
        if (hooter && hooter == 'off') {
            setIsMosaicHooterOn(true)
            resp = HooterOnOffReq(camera.privateIPAddress, camera.camPort, 'off', camera.panelBrand, camera.CCTVType
                , camera.camUserName, camera.camPassword, camera.BrandName)
        }

    }

    // for Mosaic FullScreen Implementation
    useEffect(() => {
        if (screenfull.isEnabled) {
            screenfull.on('change', () => {
                console.log("CheckFullScreen", screenfull.isFullscreen);

            })
        }
    }, [screenfull]);

    useEffect(() => {
        document.addEventListener('fullscreenchange', exitHandler);
        function exitHandler() {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                console.log("Plain JS screenfull close");
                setIsMosaicFullscreenClick(false);
                let c = count + 1;
                setCount(c);
            } else {
                console.log("Plain JS screenfull start");
            }
        }
        return () => {
            document.removeEventListener('fullscreenchange', exitHandler);
        }
    }, []);
    const handleMosaicFullScreen = () => {
        screenfull.toggle(videoContainerParentRef.current)
        console.log("handleFullScreen", screenfull.isFullscreen);
        setIsMosaicFullscreenClick(!screenfull.isFullscreen);
        let c = count + 1;
        setCount(c);
    }

    //for Mosaic Snapshot Implementation 
    const MosaicSnapShotClick = async (privateIPAddress, camPort, action, panelBrand, CCTVType, user, password, BrandName) => {
        debugger
        try {
            if (privateIPAddress && camPort
                && user && password) {
                ScreenShot(privateIPAddress, camPort, action, panelBrand, CCTVType, user, password, BrandName)
                    .then((resp) => {
                        console.log("image", resp);
                        var fileUrl = "data:image;base64," + resp.Data;

                        const popupWindow = window.open('', '_blank', 'width=540,height=360');
                        popupWindow.document.open();
                        popupWindow.document.write(`
                <html>
                <body>
                  <img src="${fileUrl}" alt="Screenshot" width="300" height="300" />
                  <br />
                  <button class="DataAccessEdit" onclick="ScreenShotDownloadClick('${resp.Data}')">
                  <img src=${DownloadIcon} alt="download icon" />
                </button>
                </body>
                <script>
                function ScreenShotDownloadClick(imageData){
                  var fileUrl = "data:image;base64," + imageData;
                  fetch(fileUrl)
                    .then(response => response.blob())
                    .then(blob => {
                      debugger
                      var link = window.document.createElement("a");
                      link.href = window.URL.createObjectURL(blob, { type: "image" });
                      link.download = "test.png";
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    });              
                }
                </script>
                </html>`);
                        popupWindow.document.close();

                        //return resp;
                    });
            }
        } catch (error) {
            console.error(error)
        }
    }
    function MosaicSnapShotOkClick(audio, camera, isCloseBtn) {
        debugger
        MosaicSnapShotClick(camera.privateIPAddress, camera.camPort, 'start', camera.panelBrand, camera.CCTVType, camera.camUserName, camera.camPassword, camera.BrandName)
    }


    /* Capture Frame */
    const handleCaptureFrame = (params) => {
    //     console.log("Rupesh Checks",playerRef)
    //     console.log("Rupesh checks", playerRef.current.getInternalPlayer());
    //     const video = playerRef.current.getInternalPlayer();
      
    //     if (typeof video === 'string') {
    //       video = console.log("rupesh checks",video);
    //   }
          const frame = captureVideoFrame(params)
          console.log('captured frame', frame)
                setCapturedImage(frame.dataUri)
                var fileUrl = frame.dataUri;
      
        // const popupWindow = window.open('', '_blank', 'width=540,height=360');
        const popupWindow = window.open('', '_blank', 'width=auto,height=auto');
        popupWindow.document.open();
        popupWindow.document.write(`
        <html>
        <head>
        <title>Snapshot</title>
        <style>
        body, html {
          margin: 0; padding: 0; background: transparent; font-family: Segoe UI;
        }
        .maincontainer {
          display: flex;
          flex-flow: column;
          height: 100%;
        }
        .snapshottitlediv {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #bdbcbc; 
          flex: 0 1 auto;
          padding: 0px 10px;
        }
        .DataAccessEdit {
          width: 32px;
          height: 32px;
          padding: 8px 7.6px 7.6px 8px;
          border-radius: 5px;
          background-color: #0b70d1;
          line-height: 10px;
          margin-left: 5px;
          border: 1px solid #0b70d1;
        }
        p {
          color: #ffffff;
          font-size: 14px;
          font-weight: 600;
          margin: 0px 0px 0px 5px;
        }
        .snapshotimagediv {
          flex: 1 1 auto;
          display: grid;
          align-items: center;
        }
        .snapshotimagediv img {
          width: 100%;
          object-fit: contain;
          box-sizing: border-box;
          display: flex;
        }
        </style>
        </head>        
        <body>
        <div class="maincontainer">
        <div class="snapshottitlediv">
        <p>${camera.CameraName} Snapshot</p>
        <button class="DataAccessEdit" data-title="Download" title="Save" onclick="capturedFrameDownloadClick('${fileUrl}')">
        <img src=${Save} alt="download icon" data-title="Save" />
        </button>
        </div>
        <div class="snapshotimagediv">
        <img src="${fileUrl}" alt="Screenshot" />
        </div>
      </div>
        </body>
        <script>
        function capturedFrameDownloadClick(imageData){
          var fileUrl = imageData;
          fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
              debugger
              var link = window.document.createElement("a");
              link.href = window.URL.createObjectURL(blob, { type: "image" });
              link.download = "test.png";
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            });              
        }
        </script>
        </html>`);
        // popupWindow.document.write(`
        // <html>
        // <body>
        //   <img src="${fileUrl}" alt="Screenshot" width="300" height="300" />
        //   <br />
        //   <button class="DataAccessEdit" onclick="capturedFrameDownloadClick('${fileUrl}')">
        //   <img src=${DownloadIcon} alt="download icon" />
        // </button>
        // </body>
        // <script>
        // function capturedFrameDownloadClick(imageData){
        //   var fileUrl = imageData;
        //   fetch(fileUrl)
        //     .then(response => response.blob())
        //     .then(blob => {
        //       debugger
        //       var link = window.document.createElement("a");
        //       link.href = window.URL.createObjectURL(blob, { type: "image" });
        //       link.download = "test.png";
        //       document.body.appendChild(link);
        //       link.click();
        //       document.body.removeChild(link);
        //     });              
        // }
        // </script>
        // </html>`);
        popupWindow.document.close();
      };
    /* Capture Frame */

    //for Mosaic Two-way Implementation 

    const TwoWayCommunicationReq = async (privateIPAddress, CommunicationPort
        , action, user, password, BrandName) => {
        try {
            if (privateIPAddress && CommunicationPort
                && user && password) {
                TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
                    , action, user, password, BrandName).then((resp) => {
                        return resp
                    })
            }else{
                console.log("rupesh checks twoway click after else",)
                TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
                  , action, user, password, BrandName).then((resp) => {
                    
                    return resp
                  })
            }
        } catch (error) {
            console.error(error)
        }
    }

    function MosaicTwoWayAudioClick(audio, camera, isCloseBtn) {
        // debugger
        var resp = "";
        if (audio && audio == 'start') {

            setIsMosaicSoundOn(false)
            TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)

            resp = TwoWayCommunicationReq(camera.privateIPAddress, camera.CommunicationPort
                , 'start', camera.camUserName, camera.camPassword, camera.BrandName
            )

        }
        if (audio && audio == 'stop') {
            setIsMosaicSoundOn(true)

            resp = TwoWayCommunicationReq(camera.privateIPAddress, camera.CommunicationPort
                , 'stop', camera.camUserName, camera.camPassword, camera.BrandName
            )
        }
    }
    useEffect(() => {
        const handlePageChange = () => {
          TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
        };
        const unlisten = history.listen(handlePageChange);
        return () => {
          unlisten();
        }
      }, [history])

    return <>
        {
            camera.RTSPURL != null ?
                <> 
                    {
                        IS_WEBRTC == 0
                        ?
                        <div
                        ref={videoContainerParentRef}
                        className='HeightChildDiv' style={{ height: height, width: width, position: "relative" }}>
                        <ReactPlayerExt
                            item={camera}
                            style={{ height: height, width: width }}
                            playerRef={playerRef}
                            time={refreshTime}
                            currentPage={currentPage}
                            videoContainerRef={videoContainerRef}
                        />
                        <div className='mosaicButtonsMainDiv' style={{ width: "100%"}}>
                            <div className={` ${gridType == "parent5x5" ? "parent5x5CameraName" : 'MosaicCameraName'}`}><p>{camera.CameraName}</p></div>
                            <div className='mosaicbuttons'>
                                {/* <button data-title='SnapShot' className='MosaicButtonDiv'>
                                    <img style={{ padding: "2px", height: "24px", }}
                                        src={MosaicSnapshot}
                                        onClick={() => MosaicSnapShotOkClick('start', camera)}
                                    />
                                </button> */}
                                <button data-title='SnapShot' className='MosaicButtonDiv'>
                                    <img style={{ padding: "2px", height: "24px", }}
                                        src={MosaicSnapshot}
                                        onClick={()=>{handleCaptureFrame(videoContainerRef.current.getInternalPlayer())}}
                                    />
                                </button>
                                {
                                    camera.ShowTwoWay
                                        ? (
                                            isMosaicSoundOn ?
                                                <button data-title='TwowayStart' className='MosaicButtonDiv'>
                                                    <img style={{ padding: "2px" }}
                                                        src={MosaicTwowayStart}
                                                        onClick={() => MosaicTwoWayAudioClick('start', camera)}
                                                    />
                                                </button>
                                                :
                                                <button data-title='TwowayStop' className='MosaicButtonDiv'>
                                                    <img style={{ padding: "2px" }}
                                                        src={MosaicTwowayStop}
                                                        onClick={() => MosaicTwoWayAudioClick('stop', camera)}
                                                    />
                                                </button>
                                        )
                                        : null
                                }
    
                                {/* {
                                    isMosaicSoundOn ?
                                        <button data-title='TwowayStart' className='MosaicButtonDiv'>
                                            <img style={{ padding: "2px" }}
                                                src={MosaicTwowayStart}
                                                onClick={() => MosaicTwoWayAudioClick('start', camera)}
                                            />
                                        </button>
                                        :
                                        <button data-title='TwowayStop' className='MosaicButtonDiv'>
                                            <img style={{ padding: "2px" }}
                                                src={MosaicTwowayStop}
                                                onClick={() => MosaicTwoWayAudioClick('stop', camera)}
                                            />
                                        </button>
                                } */}

                                {
                                    camera.ShowHooter ?
                                    (isMosaicHooterOn ?
                                        <button data-title='HooterOn' className='MosaicButtonDiv'>
                                            <img style={{ padding: "2px" }}
                                                src={MosaicHooterOn}
                                                onClick={() => MosaicHooterClick('on', camera)}
                                            />
                                        </button>
                                        :
                                        <button data-title='HooterOff' className='MosaicButtonDiv'>
                                            <img style={{ padding: "2px" }}
                                                src={MosaicHooterOff}
                                                onClick={() => MosaicHooterClick('off', camera)}
                                            />
                                        </button>) : null
                                }

                                {/* <button data-title='Fullscreen' className='MosaicButtonDiv'>
                                            <img style={{ padding: "2px" }} 
                                            src={MosaicFullscreen} 
                                            // onClick={() => ScreenShotOkClick('start', item)} 
                                            />
                                        </button>
                                        <button data-title='ExitFullscreen' className='MosaicButtonDiv'>
                                            <img style={{ padding: "2px" }} 
                                            src={MosaicExitFullscreen} 
                                            // onClick={() => ScreenShotOkClick('start', item)} 
                                            />
                                        </button> */}
                                <button
                                //data-title='Fullscreen' 
                                   data-title={isMosaicFullscreenClick ? 'Exit Fullscreen' : 'Fullscreen'}
                                    className='MosaicButtonDiv'
                                    onClick={handleMosaicFullScreen}
                                >
                                    {
                                        !isMosaicFullscreenClick ?
                                            <img style={{ padding: "2px" }}
                                                src={MosaicFullscreen}
                                            />
                                            :
                                            <img style={{ padding: "2px" }}
                                                src={MosaicExitFullscreen}
                                            />
                                    }
                                </button>
    
                                <button data-title='Video Export' className='MosaicButtonDiv'>
                              <img style={{ padding: "2px", height: "24px", }}
                                src={Video_Export} alt="video Export Button"
                                // onClick={
                                //   () => {
                                //     setPopOpen(true);
                                //     setMode("Export");
                                //   }
                                //   // videoExportOnClick(item.privateIPAddress, item.camPort, item.camUserName, item.camPassword, item.BrandName ,item.startDateTime,item.endDateTime)
                                //   }
                              />
                             </button>
    
                        </div>
                        </div>
                        </div> 
                        : 
                        // null
                        // <div className='HeightChildDiv' style={{ height: height, width: width, position: "relative" }}>
                        //     <video className="mosaic" id={camera.ParentCameraID}></video>
                        // </div>
                        <WebRTCPlayer 
                        key={camera.ParentCameraID}
                        id={camera.ParentCameraID+"1"} 
                        url={camera.RTSPURL} 
                        isEncode={camera.isTranscoded}
                        // isEncode={0} 
                        upperDivCss="HeightChildDiv"
                        videoCss="mosaic"
                        item={camera}
                        buttonCss="mosaicButtonsMainDiv"
                        noStreamDivCss="HeightChildDiv"
                        playback={true}
                        prePostDisplay={false}
                        liveButton={true}
                        exportCurrentVideoHide={true}
                        showPlaybackBtn={true}
                        />
                    }
                </>
                :
                <div className='HeightChildDiv' style={{ height: height, width: width }}>
                    <img src={MosaicNoStream} alt="mosaic" style={{ height: height, width: width, backgroundColor: '#000000' }} />
                </div>
        }
    </>
}

const Matrix = ({ cameras, gridType, height, width, playerRef, refreshTime, currentPage }) => {

    return <div className={gridType}>
        {
            cameras &&
            cameras.map(camera => (
                <IndividualMosaiCameras
                    camera={camera}
                    height={height}
                    width={width}
                    playerRef={playerRef}
                    time={refreshTime}
                    currentPage={currentPage}
                    gridType={gridType}
                />

            )
                //     return <>
                //         {
                //             camera.RTSPURL != null ?
                //                 <div className='HeightChildDiv' style={{ height: height, width: width , position: "relative"}}>
                //                     <ReactPlayerExt
                //                         item={camera}
                //                         style={{ height: height, width: width }}
                //                         playerRef={playerRef}
                //                         time={refreshTime}
                //                         currentPage={currentPage}
                //                     />
                //                     <div className='mosaicButtonsMainDiv' style={{width: "100%"}}>
                //                         <div className={` ${gridType=="parent5x5" ? "parent5x5CameraName" :'MosaicCameraName'}`}><p>{camera.CameraName}</p></div>
                //                         <div className='mosaicbuttons'>
                //                         <button data-title='SnapShot' className='MosaicButtonDiv'>
                //                             <img style={{ padding: "2px", height: "24px",  }} 
                //                             src={MosaicSnapshot} 
                //                             // onClick={() => ScreenShotOkClick('start', item)} 
                //                             />
                //                         </button>
                //                         <button data-title='TwowayStart' className='MosaicButtonDiv'>
                //                             <img style={{ padding: "2px" }} 
                //                             src={MosaicTwowayStart} 
                //                             // onClick={() => ScreenShotOkClick('start', item)} 
                //                             />
                //                         </button>
                //                         <button data-title='TwowayStop' className='MosaicButtonDiv'>
                //                             <img style={{ padding: "2px" }} 
                //                             src={MosaicTwowayStop} 
                //                             // onClick={() => ScreenShotOkClick('start', item)} 
                //                             />
                //                         </button>
                //                         {
                //                             isMosaicHooterOn ?
                //                         <button data-title='HooterOn' className='MosaicButtonDiv'>
                //                             <img style={{ padding: "2px" }} 
                //                             src={MosaicHooterOn} 
                //                              onClick={() =>  MosaicHooterClick('on', item)} 
                //                             />
                //                         </button>
                //                         :
                //                         <button data-title='HooterOff' className='MosaicButtonDiv'>
                //                             <img style={{ padding: "2px" }} 
                //                             src={MosaicHooterOff} 
                //                              onClick={() =>  MosaicHooterClick('off', item)} 
                //                             />
                //                         </button>
                // }
                //                         <button data-title='Fullscreen' className='MosaicButtonDiv'>
                //                             <img style={{ padding: "2px" }} 
                //                             src={MosaicFullscreen} 
                //                             // onClick={() => ScreenShotOkClick('start', item)} 
                //                             />
                //                         </button>
                //                         <button data-title='ExitFullscreen' className='MosaicButtonDiv'>
                //                             <img style={{ padding: "2px" }} 
                //                             src={MosaicExitFullscreen} 
                //                             // onClick={() => ScreenShotOkClick('start', item)} 
                //                             />
                //                         </button>

                //                         </div>
                //                     </div>
                //                 </div> :
                //                 <div className='HeightChildDiv' style={{ height: height, width: width }}>
                //                     <img src={MosaicNoStream} alt="mosaic" style={{ height: height, width: width, backgroundColor: '#000000' }} />
                //                 </div>
                //         }
                //     </>
            )}
    </div>
}

const ReactPlayerExt = ({ item, style, playerRef, time, currentPage, videoContainerRef }) => {

    const { count, setCount, videoStartBuffering, videoStopBuffering, videoErrorHandler, getHeaders } = useVideo();
    const [playUrl, setPlayUrl] = useState('');

    const [bufferCheckState, setBufferCheckState] = useState(false);
    const [bufferViewState, setBufferViewState] = useState(true);
    const bufferRef = useRef(bufferCheckState)

    useEffect(() => {
        const timeoutId = setTimeout(() => {
          if(!bufferRef.current){
            console.log("Page Refreshs")
            setBufferViewState(false)
          }
        }, NO_STREAM_INTERVAL_TIMER);
  
        return () => {
          clearTimeout(timeoutId);
        };
    }, []);

    useEffect(() => {
        let c = count + 1;
        setCount(c);

        //setPlayUrl(item.StreamingURL)
        //check here for mode of streaming
        if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
        {
            //   let baseUrl = "http://localhost:8080"
            let baseUrl = "http://localhost:3000"
            let tempbaseUrl = "http://localhost:3000";
            let rtspurl = item.RTSPURL
            //get cam guid
            let camguid = item.StreamName
            //do post req to base url
            let actbaseUrl = baseUrl + "/start"
            let IsTrancoding = false
            //   if(item.istranscdng==1)
            if (item.isTranscoded == 1)
                IsTrancoding = true
            fetch(actbaseUrl, {
                method: 'POST',
                body: JSON.stringify({
                    uri: rtspurl,
                    alias: camguid,
                    isencode: IsTrancoding
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }).then((res) => res.json())
                .then((data) => {
                    baseUrl = baseUrl + data['uri']
                    setPlayUrl(baseUrl)
                    //item.
                })
                .catch((err) => {
                });

            return () => {
                let stopbaseUrl = tempbaseUrl + "/stop";
                fetch(stopbaseUrl, {
                    method: "POST",
                    body: JSON.stringify({
                        alias: camguid
                    }),
                    headers: {
                        "Content-type": "application/json; charset=UTF-8",
                    }
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log("Unmounted the Mosaic", data);
                    })
                    .catch((err) => {
                    })
            }
            //url as rtspurl and alis as camguid
        } else {
            setPlayUrl(item.StreamingURL)
        }

        //get resp

        //make URL to play to react player
        // let  = baseUrl + ""//retun rul


    }, [time, currentPage]);
    // useEffect(() => {
    //     let c = count + 1;
    //     setCount(c);
    //     let baseUrl = "http://localhost:8080"
    //     let rtspurl = item.RTSPURL
    //     console.log("RTSPURL : ",rtspurl)
    //     //get cam guid
    //     let camguid = item.StreamName
    //     //do post req to base url
    //     let actbaseUrl = baseUrl + "/start"
    //     console.log("check data : ", playUrl)

    //     fetch(actbaseUrl, {
    //       method: 'POST',
    //       body: JSON.stringify({
    //         uri: rtspurl,
    //         alias: camguid
    //       }),
    //       headers: {
    //         'Content-type': 'application/json; charset=UTF-8',
    //       },
    //     })
    //       .then((res) => res.json())
    //       .then((data) => {
    //         baseUrl = baseUrl + data['uri']
    //         setPlayUrl(baseUrl)
    //         console.log("check data playurl", baseUrl)
    //         console.log("check data", data)
    //       })
    //       .catch((err) => {
    //         console.log("check data error", err.message);
    //       });
    //     // url as rtspurl and alis as camguid
    
    //     //get resp
    
    //     //make URL to play to react player
    //     // let  = baseUrl + ""//retun rul
    
    //   }, [time]);
    //   console.log(item)
    //   console.log(item.StreamName)
    //   console.log(item.StreamingURL)
    // const getHeaders = () => {
    //   return { 'Cache-Control': 'no-store' };
    // };

    // const getHeaders = () => {
    //     return { 'Cache-Control': 'no-store' };
    //   };

    return (
        bufferViewState ?
        <>
            <ReactPlayer
                ref={videoContainerRef}
                itemID={"video-" + item.StreamName}
                key={count}
                height={style.height}
                width={style.width}
                id={"video-" + item.StreamName}
                // url={item.StreamingURL}
                url={playUrl}
                playing={true}
                style={{ backgroundColor: "#000000" }}
                className="mosaic"
                controls
                autoPlay
                config={{
                    file: {
                        attributes: {
                            crossorigin: 'anonymous',
                            disablePictureInPicture: true, // hide pip
                            controlsList: 'nodownload noplaybackrate',
                            onContextMenu: (e) => e.preventDefault(),
                        },
                        tracks: [],
                        forceVideo: true,
                        forceAudio: true,
                        http: {
                            headers: getHeaders(),
                        },
                    },
                }}
                onError={(e) => videoErrorHandler(e, item.CameraName)}
                onBuffer={(e) => videoStartBuffering(e, item.CameraName)}
                onBufferEnd={(e) => videoStopBuffering(e, item.CameraName)}
            />
        </> :
            <div className="no-stream-found">
                <img
                    src={MosaicNoStream}
                    alt="Video"
                    className="w-100 image-bg mosaic-nostream-img"
                />
            </div>
    )
}