class MessageParser {
    constructor(actionProvider, state) {
      this.actionProvider = actionProvider;
      this.state = state;
    }

    
  
    parse(message) {
        var AccessToken = localStorage.getItem("AccessToken");
        var BotResponse={
            message:message,
            AccessToken:AccessToken,
            ConversationId:this.state.ConversationId,
            MessagesList:this.state.messages
        }
        this.actionProvider.setChatbotResponse(BotResponse)
        this.actionProvider.ChatboCustomMessagehandler()
    }
  }
  
  export default MessageParser;