import React from "react"

export default function ChatbotFunctionalComponent(props){

    function Focusonlastmessage(row) {
        try{
        }catch(error){}
    }

    return(
        <div className="react-functional-chatbot-main-div">
            <div className="react-functional-chatbot-inner-div">
                {
                    (props.messages.length > 0)
                    ?
                        <>
                        {/* <Row className="reasonCodeHeader"><label>Please select the reason code</label></Row>
                         <Row><button class="reasonCodeBtn">As per live viewing, Nothing suspicious in branch</button></Row>
                         <Row><button class="reasonCodeBtn">As confirmed with branch person --Name-- --ContactNumber--  --reason for panic alert--</button></Row>
                         <Row><button class="reasonCodeBtn">As confirmed with branch person --Name-- --ContactNumber-- --reason for Door Open Too Long--</button></Row>
                         <Row><button class="reasonCodeBtn">As confirmed with branch person --Name-- --ContactNumber--  --reason for panel tamper--</button></Row> */}
                        {
                            props.messages.map((item,i) => (
                                
                                <div id={"ChatMessage_"+item.id} key={item.id} style={{display:"grid",justifyContent:item.direction}}
                                onRender={item.id == props.messages.length ? Focusonlastmessage("ChatMessage_"+item.id) : null}>
                                    {item.message}
                                </div>
                            ))
                        }
                      
                        </>
                    :
                    <>{console.log("ChatMessages null")}</>
                }
            </div>
        </div>
    )
}