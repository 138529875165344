import React from 'react'
import { InfoBlue } from '../../assets/images'

export const ZeroCountAlert = ({type}) => {
    return (
        <div className="noDataAccessMsg">
                        <div>
                        <img 
                        className="informationIcon" 
                        src={InfoBlue} 
                        alt="Info"
                        />
                        </div>
                    <div style={{ width: "100%" }}>
                    <h3 className="No-Data-Access-Group">
                    0 {type} alert
                    </h3>
                    </div>
                </div>
  )
}
