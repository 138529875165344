import React, { useEffect, useState, useContext, useRef } from "react"
import { ConvertDate, ConvertDateTwo, frontDateConverter } from '../../utils/utils'
import { Row, Col, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { DeleteConversation } from '../../utils/NotificationUtil'
import { ConversationStateContext } from '../../Context/ConversationStateContext'
import ChatflowEvidenceAttachment from "./ChatflowEvidenceAttachment";
import ReactPlayer from 'react-player'
import { BotAvatarIcon, UserAvatarIcon, ERTAvatarIcon, MosaicNoStream, CemeraBGDiv, AlertGenericImg, AlertGenericImg1, CheckmarkCircle, SendIcon, MosaicSnapshot, MosaicTwowayStart, MosaicTwowayStop, MosaicHooterOn, MosaicHooterOff, MosaicFullscreen, MosaicExitFullscreen } from '../../assets/images'
import { conversationtemplatecrud, adderttochatroom, updatealert, makecall, getstreamingurl, sendemail, getRasaConversation, addrasaconversationindb } from "../../services/chatFlowService";
import {
    AddERTCount, DeleteERTCount, GetERTCount, GetAlertNotAckRecord, DeleteChatWindowOpenedTime,
    Deletecloseobject, AddChatWindowOpenedTime,
} from '../../utils/IndexedDBStorageUtil'

import { mixpanel } from '../../utils/MixPanelUtil'
import ContactStateOfficalComponent from './ContactStateOfficalComponent'
import { nearbyresource } from '../../services/chatFlowService'

import Reactfunctionalchatbot from "../../components/ReactChatBot/Reactfunctionalchatbot"
import ReactCustomPlayer from "../../components/Slider/ReactCustomPlayer"
import ChatflowReasonCode from "./ChatflowReasonCode";
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CLOUD_STREAMING, IS_WEBRTC } from '../../config'
import { useVideo } from '../../CustomHooks/useVideo'
import { THIRTY_MINUTES } from "../../navigation/CONSTANTS";
import ReactLivePlayer from "../../components/Slider/ReactLivePlayer"
import screenfull from "screenfull"
import captureVideoFrame from "capture-video-frame"
import WebRTCPlayer from "../Cameras/WebRTCPlayer"
import { attachalertevidences } from "../../services/alertAuthService"
import { ErrorToast } from "../../CustomHooks/ErrorToast"
import { SuccessToast } from "../../CustomHooks/SuccessToast"
// import Player from "../Cameras/Player"

let reasonCodeObj = {
    "btnId": 0,
    "buttonProperties": {},
    "title": ""
}

let closureCommentObj = {
    "btnId": 0,
    "buttonProperties": {},
    "title": ""
}

export default function ChatflowContainer(props) {
    const [loading, setLoading] = useState(false);
    let { isHooterOn } = props;
    const [messages, setmessages] = useState([])
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))
    //#region rasa conv
    const [uploadfiles, setuploadfiles] = useState(false);
    const [selectedReasonCode, setSelectedReasonCode] = useState(reasonCodeObj)
    const [isReasonCodeSelected, setIsReasonCodeSelected] = useState(false)
    const [reasonCodeList, setReasonCodeList] = useState([])

    const [selectedClosureComment, setSelectedClosureComment] = useState(closureCommentObj)
    const [isClosureCommentSelected, setIsClosureCommentSelected] = useState(false)
    const [closureCommentList, setClosureCommentList] = useState([])

    const [isevidence, setisevidence] = useState(false)

    const {conversationstate, setconversationState} = useContext(ConversationStateContext)
    const [showChatFlow, setShowChatFlow] = useState(true);

    //#endregion
    let isevidenceupload = false
    //const [isevidenceupload,setisevidenceupload]=useState(false)
    let MessageListState = []
    let FRUserState = 1
    const [ConversationsList, setConversationsList] = useState([])
    //const [ConversationId,setConversationId]=useState('')

    const [ChatbotList, setChatbotList] = useState([])
    let ConversationId = props.ConversationId

    let Ertescallationtime = 2
    let FRusersescallationtime = 2
    let acksnoozetime = 2
    let alertnotacktime = 5

    const [isimage, setisimage] = useState(false)
    const [isOpenmodal, setisOpenmodal] = useState(false)
    const [snapshoturl, setsnapshoturl] = useState([])
    const [countTwo, setCountTwo] = useState(0);
    const [countThree, setCountThree] = useState(0);
    const { count, setCount, videoStartBuffering, videoStopBuffering, videoErrorHandler, getHeaders } = useVideo();
    const [playUrl, setPlayUrl] = useState('');
    const [time, setTime] = useState(Date.now());
    const [url, setUrl] = useState('')
    const [playbackDate, setPlaybackDate] = useState("");
    const [playbackUrls, setPlaybackUrls] = useState([]);
    const [capturedImage, setCapturedImage] = useState(null);
    const [isSnapShotBtnClick, setIsSnapShotBtnClick] = useState(false);
    const [isSnapShotBtnClickPb, setIsSnapShotBtnClickPb] = useState(false);
    const [savedRef, setSavedRef] = useState(null);
    const [fullScreenRef, setFullScreenRef] = useState(null);
    const videoRef = useRef(null);
    const [isAlertLiveFullscreenClick, setIsAlertLiveFullscreenClick] = useState(false);
    const [isFullScreenPlayback, setIsFullScreenPlayback] = useState(false);
    const [randomId, setRandomId] = useState(0);
    const [showWebRTCPlayer, setShowWebRTCPlayer] = useState(false);
    const [webRTCUrl, setWebRTCUrl] = useState("");
    const [videoId, setVideoId] = useState("");
    const [videoItem, setVideoItem] = useState(null);
    let isFullScreenRef = useRef(false);

    const videoContainerRef = useRef(null);
    const videoContainerParentRef = useRef(null);
    const playbackRef = useRef(null);

    const captureVideoFrameData = (data) => {
        console.log("captureVideoFrameData",data)
        setCapturedImage(data);
      };

      const SnapShotBtnClick = (devid) =>{
        console.log("rupesh checks videoRef snapshot btn",devid)
        setIsSnapShotBtnClick(true)
        setSavedRef(devid);
    }

    const fullScreenPlayback = (id) => {
        console.log("isFullScreenPlayback", id)
        setIsFullScreenPlayback(id);
        setRandomId(1);
    }

    const screenshotPlayback = () => {
        setIsSnapShotBtnClickPb(true);
    }

    const fullScreenMode = (id) => {
        console.log('fullScreenMode', id);
        setIsAlertLiveFullscreenClick(!isAlertLiveFullscreenClick);
        setFullScreenRef(id);
    }

    const AttachalertevidencesParent = (e, fname) => {
        try {
            var obj = {
                "evidenceFileName": fname,
                "alertId": props.AlertDetails.alertId,
                "userId": LoggedInUser.UserID,
                "base64String": e
            }
            attachalertevidences(obj).then((evidenceRes) => {
                if (evidenceRes.status === "Ok") {
                    OnAttachedSuccess(evidenceRes)
                    props.scrollToBottom()
                    SuccessToast("Snapshot added to chat.")
                    // props.setCapturedImage(null);
                }
                else {
                    console.error('Error while attaching the evidence ', evidenceRes.message)
                    // alert(evidenceRes.message)
                    ErrorToast(evidenceRes.message);
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const OnAttachedSuccess = (evidenceRes) => {
        try {
            let LoggedInUser = JSON.parse(localStorage.getItem('user'))
            var myCurrentDatetime = new Date();
            var _datetime = ConvertDate(myCurrentDatetime)
            var rasaconvobj = {
                "evidenceType": "img",
                "text": evidenceRes.documentUrl,
                "timestamp": _datetime,
                "type": "user"
            }
            var addindbobj = {
                "assignedTo": LoggedInUser.UserID,
                "rasaConversationID": ConversationId,
                "uploadType": "file",
                "evidence": rasaconvobj,
                "conversation": null,
                "userType": "operator",
                "createdOn": _datetime
            }
            addrasaconversationindb(addindbobj).then((resp) => {
                if (resp.status === "Ok") {
                    OnEvidenceAttached(evidenceRes)
                }
                else {
                    console.error('Error while saving evidence to databse ', resp.message)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        document.addEventListener('fullscreenchange', exitHandler);
        function exitHandler() {
            if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
                console.log("Plain JS screenfull close isAlertLiveFullscreenClick",isAlertLiveFullscreenClick);
                console.log("Plain JS screenfull close isFullScreenPlayback",isFullScreenPlayback);
                console.log("Plain JS screenfull close randomId",randomId);
                    setIsAlertLiveFullscreenClick(false);
                    setIsFullScreenPlayback(false);
                    if(randomId == 0){
                        setRandomId(0);
                    }else{
                        setRandomId(1);
                    }
                // setIsMosaicFullscreenClick(false);
                let c = count + 1;
                setCount(c);
            } else {
                console.log("Plain JS screenfull start");
            }
        }
        return () => {
            document.removeEventListener('fullscreenchange', exitHandler);
        }
    }, [randomId]);

    useEffect(() => {
        console.log("AlertDetails", props.AlertDetails)
        console.log("playurl updated useEffect", playUrl );
        console.log("reset_timer_1", playUrl)
        console.log("PlaybackChanged", url)

        let user_id = localStorage.getItem('UserID');
        if(props.AlertDetails.assignedTo != null && props.AlertDetails.assignedTo != user_id){
            setShowChatFlow(false);
            return;
        }else{
            if (props.AlertDetails != null) {
                setShowChatFlow(true);
                ConversationId = ConversationId.replace('null',localStorage.getItem('UserID'));
                try {
                    var obj = {
                        "action": "get",
                        "workflowId": "",
                        "conversationId": ConversationId,
                        "convo": []
                    }
                    conversationtemplatecrud(obj).then((resp) => {
                        if (resp.status == "Ok") {
                            if (resp.message.rasaConversation == null) {
                                AddNewConversation(ConversationId, props.AlertDetails)
                            } else {
                                ChatMessagesConversion(resp.message.rasaConversation, props.AlertDetails.hlsStreamingUrl)
                            }
                        } else if (resp.status == "Failed") {
                            if (resp.message == "Conversation does not exist!") {
                                AddNewConversation(ConversationId, props.AlertDetails)
                            } else {
                                alert(resp.message)
                            }
                        } else {
                            alert(resp.message)
                        }
                    })
                } catch (error) {
                    console.error(error)
                }
            }
        }
    }, [props.ConversationId, countTwo, playUrl, countThree, url, webRTCUrl])

    useEffect(() => {
        if(isSnapShotBtnClick){
            ChatMessagesConversion(ConversationsList, props.AlertDetails.hlsStreamingUrl);
        }
    }, [isSnapShotBtnClick]);

    useEffect(() => {
        if(isSnapShotBtnClickPb){
            ChatMessagesConversion(ConversationsList, props.AlertDetails.hlsStreamingUrl);
        }
    }, [isSnapShotBtnClickPb]);

    useEffect(() => {
            ChatMessagesConversion(ConversationsList, props.AlertDetails.hlsStreamingUrl);
    }, [isAlertLiveFullscreenClick]);

    useEffect(() => {
        ChatMessagesConversion(ConversationsList, props.AlertDetails.hlsStreamingUrl);
    }, [isFullScreenPlayback]);

    // console.log("rupesh checks props",props)
    useEffect(() => {
        const intervalId = setInterval(() => {
        //   if (isMultiPlay == false) {
          setTime(Date.now())
        // }
        }, THIRTY_MINUTES); // set the interval to 30 mins
    
        return () => clearInterval(intervalId); // clear the interval on unmount
   
    }, []);

    useEffect(() => {
        let camguid;
        let pbcamguid;
        let tempbaseUrl = "http://localhost:3000"
        let rtspurl;
        function fetchResponse(){
        let c = count + 1;
        setCount(c);
        //
    
        //check here for mode of streaming
        if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
        {
          // let baseUrl = "http://localhost:8080"
          let baseUrl = "http://localhost:3000"
          rtspurl = props.AlertDetails.rtspUrl
          if(rtspurl == null || rtspurl == undefined){
            return
          }
          //get cam guid
          let temp_date = ConvertDateTwo(new Date())
          camguid = props.AlertDetails.streamName + temp_date;
          pbcamguid = props.AlertDetails.streamName;
          //do post req to base url
          let actbaseUrl = baseUrl + "/start"
          let IsTrancoding = false
          // if (item.istranscdng == 1)
          if (props.AlertDetails.isTranscoded == 1)
            IsTrancoding = true
            fetch(actbaseUrl, {
                method: 'POST',
                body: JSON.stringify({
                  uri: rtspurl,
                  alias: camguid,
                  isencode: IsTrancoding
                }),
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                },
              }).then((res) => res.json())
                .then((data) => {
                  baseUrl = baseUrl + data['uri']
                  console.log("PlayUrl : ",baseUrl)
                  setPlayUrl(baseUrl)
                    let c = count + 1;
                    setCount(c);
                    //   let cTwo = countTwo + 1;
                    //   setCountTwo(cTwo);
                    // let convList2 = ConversationsList;
                    // setConversationsList(convList2)
                    //   console.log("PlayUrl : ",setPlayUrl(baseUrl))
    
                  //item.
                })
                .catch((err) => {
                  console.log("check data error", err.message);
                });
            } else {
                console.log("HLS URLS : ",props.AlertDetails.hlsStreamingUrl)
                setPlayUrl(props.AlertDetails.hlsStreamingUrl)
            }   
        } 
        //get resp
    
        //make URL to play to react player
        // let  = baseUrl + ""//retun rul
        fetchResponse()
        return () => {
            console.log("Exiting Component", playbackDate, props.AlertDetails.alertId);
            if(rtspurl == null || rtspurl == undefined){
                return
            }
            let stopbaseUrl = tempbaseUrl + "/stop";
            fetch(stopbaseUrl, {
                method: 'POST',
                body: JSON.stringify({
                    alias: camguid,
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
                .then((res) => res.json())
                .then((data) => {
                    // console.log("Unmounted the component data",data);
                })
                .catch(err => {
                    console.log(err.message)
                });
            let playbackAlias = pbcamguid + props.AlertDetails.alertId + "_plb"
            fetch(stopbaseUrl, {
                method: 'POST',
                body: JSON.stringify({
                    alias: playbackAlias,
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
                .then((res) => res.json())
                .then((data) => {
                    // console.log("Unmounted the component data",data);
                })
                .catch(err => {
                    console.log(err.message)
                });
                console.log("playbackUrls", playbackUrls);
                if(playbackUrls.length > 0){

                
            for(let i=0; i<playbackUrls.length; i++){
                console.log("playbackUrls", playbackUrls[i]);
                fetch(stopbaseUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                        alias: playbackUrls[i],
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
                    .then((res) => res.json())
                    .then((data) => {
                        // console.log("Unmounted the component data",data);
                    })
                    .catch(err => {
                        console.log(err.message)
                    });
            }
        }
        }
    }, [time]);

    let mountRef = useRef(true);

    useEffect(() => {
            if(!mountRef.current){
        console.log("Hooter On Status", isHooterOn)
            let myCurrentDatetime = new Date();
            let _datetime = ConvertDate(myCurrentDatetime)
            let rasaconvobj = {
                "evidenceType": "comment",
                "text": `Hooter ${isHooterOn ? 'stopped' : 'started'}.`,
                "timestamp": _datetime,
                "type": "user"
            }
            addrasaconversationindb({
                "assignedTo": LoggedInUser.UserID,
                "rasaConversationID": ConversationId,
                "uploadType": "file",
                "evidence": rasaconvobj,
                "conversation": null,
                "userType": "operator",
                "createdOn": _datetime
            }).then((resp) => {
                if (resp.status === "Ok") {
                    // document.getElementById("Commenttxt:" + props.AlertID).value = ""
                    // let evidenceRes = { "documentUrl": "Hooter started" }
                    OnEvidenceAttached();
                    props.scrollToBottom();
                }
                else {
                    console.error('Error while saving evidence to databse ', resp.message)
                }
            }).catch(err => console.log(err));
        }    
        return () => { mountRef.current = false } 
    }, [isHooterOn]);

    let isUnMountingRef = useRef(false);

    useEffect(() => () => {isUnMountingRef.current = true}, []);

    useEffect(() => () => {
        if(isUnMountingRef.current){
            console.log("Calling Function", isHooterOn);
            if(!isHooterOn){
            let myCurrentDatetime = new Date();
            let _datetime = ConvertDate(myCurrentDatetime)
            let rasaconvobj = {
                "evidenceType": "comment",
                "text": `Hooter Auto Stopped.`,
                "timestamp": _datetime,
                "type": "user"
            }
            addrasaconversationindb({
                "assignedTo": LoggedInUser.UserID,
                "rasaConversationID": ConversationId,
                "uploadType": "file",
                "evidence": rasaconvobj,
                "conversation": null,
                "userType": "operator",
                "createdOn": _datetime
            }).then((resp) => {
                if (resp.status === "Ok") {
                    // document.getElementById("Commenttxt:" + props.AlertID).value = ""
                    // let evidenceRes = { "documentUrl": "Hooter started" }
                    // OnEvidenceAttached();
                    // props.scrollToBottom();
                }
                else {
                    console.error('Error while saving evidence to databse ', resp.message)
                }
            }).catch(err => console.log(err));
        }
        }
    },[isHooterOn])

    console.log("isHooterOn", isHooterOn)

// console.log("rupesh checks props",props)
    const AddNewConversation = async (conversationId, alertdata) => {
        try {

            var _datetime = ConvertDate(new Date())
            var convoData = [
                {
                    "id": "2",
                    "createdOn": _datetime,
                    "data": {
                        "label": "Alert is Displayed to an Operator",
                        "otherProps": {
                            "buttons": [],
                            "btnResponse": 0,
                            "responseTime": _datetime,
                            "nodeUserID": 1,
                            "botMessage": alertdata.alertTemplate + " " + alertdata.severity + " alert generated",
                            "selectedValue": ""
                        }
                    }
                }
            ]

            var obj = {
                "action": "add",
                "workflowId": props.AlertDetails.workflowId,
                "conversationId": conversationId,
                "convo": convoData
            }
            setConversationsList(convoData)
            GetConversationResponse(obj)
        } catch (error) {
            console.error(error)
        }
    }
    function sleep(milliseconds) {
        var start = new Date().getTime();
        for (var i = 0; i < 1e7; i++) {
            if ((new Date().getTime() - start) > milliseconds) {
                break;
            }
        }
    }
    const RetriveAlertPlayback = async (selectedcamera) => {
        console.log("RetriveAlertPlayback", selectedcamera)
        try {
            setLoading(true);
            var DeviceId = 0
            var DeviceBrandId = 0
            if (props.AlertDetails.deviceTypeID == 1) {
                DeviceId = props.AlertDetails.deviceId
                DeviceBrandId = props.AlertDetails.cameraBrandId
            } else if (props.AlertDetails.deviceTypeID == 2 || props.AlertDetails.deviceTypeID == 4) {
                DeviceId = selectedcamera.associatedCameraId
                DeviceBrandId = selectedcamera.cameraBrandId
            }

            let split1 = props.AlertDetails.actualTime.split(' ')
            console.log("rupesh checks",split1)
            console.log("rupesh checks",split1[0])

            let split2 = split1[0].split('-')

            // var newdateSplit = `${split2[2]}-${split2[1]}-${split2[0]} ${split1[1]}`
            // console.log("rupesh checks newdateSplit",newdateSplit)
            var newdateSplit = `${split2[2]}-${split2[1]}-${split2[0]} ${split1[1]}`
console.log("rupesh checks newdateSplit",newdateSplit)

            // var alerttime = new Date(props.AlertDetails.actualTime)

            var alerttime = new Date(newdateSplit)


            // let preposttime = 3600 //1800 sec 30min
            let pretime = 60;
            let posttime = 120 //1800 sec 30min
            // alerttime.setMinutes(alerttime.getMinutes() - preposttime)
            alerttime.setSeconds(alerttime.getSeconds() - pretime)
            var styy = alerttime.getFullYear()
            var stmm = alerttime.getMonth() + 1
            // var stmm = alerttime.getMonth()
            var stdd = alerttime.getDate()
            // var stdd = alerttime.getDate() + 1
            var sth = alerttime.getHours()
            var stm = alerttime.getMinutes()
            var sts = alerttime.getSeconds()

            // alerttime.setMinutes(alerttime.getMinutes() + preposttime + preposttime)
            alerttime.setSeconds(alerttime.getSeconds() + pretime + posttime)
            var etyy = alerttime.getFullYear()
            var etmm = alerttime.getMonth() + 1
            // var etmm = alerttime.getMonth() 
            var etdd = alerttime.getDate()
            // var etdd = alerttime.getDate() + 1
            var eth = alerttime.getHours()
            var etm = alerttime.getMinutes()
            var ets = alerttime.getSeconds()


            if (stmm.toString().length == 1) { stmm = "0" + stmm.toString() }
            if (stdd.toString().length == 1) { stdd = "0" + stdd.toString() }
            if (sth.toString().length == 1) { sth = "0" + sth.toString() }
            if (stm.toString().length == 1) { stm = "0" + stm.toString() }
            if (sts.toString().length == 1) { sts = "0" + sts.toString() }

            if (etmm.toString().length == 1) { etmm = "0" + etmm.toString() }
            if (etdd.toString().length == 1) { etdd = "0" + etdd.toString() }
            if (eth.toString().length == 1) { eth = "0" + eth.toString() }
            if (etm.toString().length == 1) { etm = "0" + etm.toString() }
            if (ets.toString().length == 1) { ets = "0" + ets.toString() }


            if (DeviceBrandId == 1) {
                // var startTime = styy + stmm + stdd + "T" + sth + stm + sts
                // var endTime = etyy + etmm + etdd + "T" + eth + etm + ets

                // var startTime = styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + sts
                var startTime = styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + "00"
                // var endTime = etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + ets
                var endTime = etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + "00"
            } else if (DeviceBrandId == 2) {
                // var startTime = styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + sts
                var startTime = styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + "00"
                // var endTime = etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + ets
                var endTime = etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + "00"
            }

            // var startTime=yy+mm+dd+"T"+sth+stm+sts
            // var endTime=yy+mm+dd+"T"+eth+etm+ets
            // var startTime=yy+"_"+mm+"_"+dd+"_"+sth+"_"+stm+"_"+sts
            // var endTime=yy+"_"+mm+"_"+dd+"_"+eth+"_"+etm+"_"+ets
            console.log("rupesh checks time day",stdd)
            console.log("rupesh checks time day",etdd)
            console.log("rupesh checks time",startTime)
            console.log("rupesh checks time",endTime)
            var obj = {
                "action": "StartPlaybackUrl",
                "startTime": startTime,
                "endTime": endTime,
                // "endTime": "2021_12_13_12_22_00",
                // "startTime": "2021_12_13_12_18_00",
                "deviceId": DeviceId,
                "alertId": props.AlertDetails.alertId
            }

            // This region is for demo changes done when rover is running locally ,when want to run local demo just uncomment the code bet ween this region
            //#region Demo changes
            // var roverobj={
            //     "startTime":startTime,
            //     "endTime":endTime,
            //     "deviceId":DeviceId,
            //     "alertId":props.AlertDetails.alertId
            // }
            // getplaybackurl(roverobj).then((resp1) => {
            //     console.log("Rover api playbackurl stream response => ",resp1)
            //     if(resp1){
            //         if (resp1.status == "Ok") {
            //             if( MessageListState.length > 0) { ChatMessagesConversion(MessageListState,resp1.message) }
            //             else { ChatMessagesConversion(ConversationsList,resp1.message) }
            //         }else{
            //             alert(resp1.message)
            //         }
            //     }
            // })
            // return
            //#endregion
            /* getstreamingurl(obj).then((resp) => {
                if (resp) {
                    if (resp.status == "Ok") {
                        //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                        //props.AlertDetails.hlsStreamingUrl=resp.message
                        if (MessageListState.length > 0) { ChatMessagesConversion(MessageListState, resp.message) }
                        else { ChatMessagesConversion(ConversationsList, resp.message) }
                        setLoading(false);
                    } else {
                        alert(resp.message)
                    }
                }
            }) */
            
            //#region playback in chatbot
            getstreamingurl(obj).then((res) => {
                                console.log(res);
                // console.log("Rupesh Checks Response",res);
                if(res.status === 'Ok'){
                    //
                    console.log("Rupesh Checks RTSPURL Playback check",res.rtspURL);
                    if(IS_WEBRTC == 0){

                    if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
                    {
                    // let baseUrl = "http://localhost:8080"
                    let baseUrl = "http://localhost:3000"
                    let rtspurl = res.rtspURL
                    console.log("Rupesh Checks RTSPURL : ", rtspurl)
                    //get cam guid
                    let temp_date = ConvertDateTwo(new Date())
                    setPlaybackDate(temp_date);
                    let camguid = props.AlertDetails.streamName + props.AlertDetails.alertId +"_plb" 
                    let arr = playbackUrls;
                    arr.push(camguid);
                    setPlaybackUrls(arr);
                    console.log("Rupesh Checks RTSPURL checkplayback",camguid);
                    //do post req to base url
                    let actbaseUrl = baseUrl + "/start"
                    // console.log("check data : ", playUrl)
                    let IsTrancoding = false
                    console.log("Rupesh Checks RTSPURL checkplayback",props.AlertDetails.isTranscoded);
                    if(props.AlertDetails.isTranscoded==1)
                        IsTrancoding=true
    
                    fetch(actbaseUrl, {
                        method: 'POST',
                        body: JSON.stringify({
                        uri: rtspurl,
                        alias: camguid,
                        isencode:IsTrancoding
                        }),
                        headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                        },
                    }).then((res) => res.json())
                        .then((data) => {
                        baseUrl = baseUrl + data['uri']
                        // 
                        console.log("Rupesh Checks check data playurl", baseUrl)
                        setUrl(baseUrl);
                        setLoading(false)
                        // let c = countPB + 1;
                        //     setCountPB(c);
                            let convList2 = ConversationsList;
                            setConversationsList(convList2)
                        //setPlayUrl(baseUrl)
                        //item.
                        // console.log("check data playurl", baseUrl)
                        console.log("check data", data)
                        })
                        .catch((err) => {
                        console.log("check data error", err.message);
                        });
                    //url as rtspurl and alis as camguid
                    }
                    else{
                    //
                    console.log("rupesh checks cloud Streaming 1",res.message)  
                    console.log("rupesh checks cloud Streaming 1",res.rtspURL)  
                    console.log("rupesh checks cloud Streaming 1 hlsStreamingUrl",props.AlertDetails.hlsStreamingUrl)  
                    // setUrl(res.message);
                    // setUrl(res.rtspURL);
                    setUrl(props.AlertDetails.hlsStreamingUrl);
                    let convList2 = ConversationsList;
                    setConversationsList(convList2)
                    setLoading(false)
                    }
                    }
                    else{
                        setShowWebRTCPlayer(true);
                        setWebRTCUrl(res.rtspURL);
                        setVideoId(DeviceId + "8");   
                        setVideoItem(selectedcamera); 
                    }
                }
            }).catch(err => {
                console.log(err);
                setLoading(false);
            })
            //#endregion playback in chatbotends
            sleep(1000);
            var vlcobj = {
                "action": "StartVlcStream",
                "startTime": startTime,
                "endTime": endTime,
                // "endTime": "2021_12_13_12_22_00",
                // "startTime": "2021_12_13_12_18_00",
                "deviceId": DeviceId,
                "alertId": props.AlertDetails.alertId
            }
            //commenting below part of vlc by rupesh 
           /*  getstreamingurl(vlcobj).then((resp) => {
                // if (resp.status == "Ok") {
                //     ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                //     props.AlertDetails.hlsStreamingUrl=resp.message
                //     if( MessageListState.length > 0) { ChatMessagesConversion(MessageListState,resp.message) }
                //     else { ChatMessagesConversion(ConversationsList,resp.message) }
                // }else{
                //     alert(resp.message)
                // }
                //console.log("StartVlcStream resp End---------> ")
            }) */
        } catch (error) {
            console.error(error)
        }
    }

    const GetConversationResponse = (obj, isalertacksnooze = false) => {
        try {
            var lastbtnobj = obj
            conversationtemplatecrud(obj).then((resp) => {
                if (resp.status == "Ok") {      
                    //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                    //var templist = MessageListState

                    if (lastbtnobj.convo[0].id == "2") {
                        MessageListState.push({
                            "id": "2",
                            "createdOn": ConvertDate(new Date()),
                            "data": {
                                "label": "Alert is Displayed to an Operator",
                                "otherProps": {
                                    "buttons": [],
                                    "btnResponse": 0,
                                    "responseTime": ConvertDate(new Date()),
                                    "nodeUserID": 1,
                                    "botMessage": props.AlertDetails.alertTemplate + " " + props.AlertDetails.severity + " alert generated",
                                    "selectedValue": ""
                                }
                            }
                        })
                    }

                    // // rupesh gupta
                    // if(resp.message.length > 0){
                    //     resp.message.map((item) => {
                    //         MessageListState.push(item)
                    //     })
                    //     MessageListState.pop()
                    // }

                    //AlertSnoozedOperations(templist,resp.message)
                    // setConversationsList(templist)

                    if (resp.message.length > 0) {
                        // var alertplaybackstep = resp.message.find(x => x.data.otherProps.action == "Send Pre & Post video to operator")
                        // if(alertplaybackstep){
                        //     RetriveAlertPlayback()
                        // }
                        var closestep = resp.message.find(x => x.data.otherProps.action == "Operator closes the alert" || x.data.otherProps.action == "Autoclose alert")
                        if (closestep) {
                            var obj = {
                                "action": "handleOpBotCloseMessage",
                                "userType": "operator",
                                "alertId": props.AlertDetails.alertId,
                                "step": closestep,
                                "datetime": ConvertDate(new Date()),
                                "userId": LoggedInUser.UserID
                            }
                            conversationtemplatecrud(obj).then((resp1) => {
                                if (resp1 && resp1.status == "Ok") {
                                    if (resp1.message == true) {
                                        if (isalertacksnooze == true) {
                                            var lastobj = MessageListState[MessageListState.length - 1]
                                            lastobj.data.otherProps.btnResponse = lastbtnobj.convo[0].data.otherProps.btnResponse
                                            var templist = MessageListState
                                            //AlertSnoozedOperations(templist,resp.message)
                                            setConversationsList(templist)
                                            buttonRef.current = false;
                                            ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)
                                            //var templist = MessageListState
                                            // resp.message.map((item) => {
                                            //     templist.push(item)
                                            // })

                                        } else {
                                            if (resp.message[0].data.otherProps.action == "Autoclose alert") {
                                                onUpdateAlert(3, props.AlertDetails.alertId)
                                                DeleteConversation(props.AlertDetails.alertId)
                                                Deletecloseobject(props.AlertDetails.alertId)
                                                ClearTimerList()
                                                StopAlertNotAckTimer(props.AlertDetails.alertId)
                                            }
                                            var templist = MessageListState
                                            resp.message.map((item) => {
                                                templist.push(item)
                                            })
                                            setConversationsList(templist)
                                            buttonRef.current = false;
                                            ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)
                                        }
                                        return
                                    } else {
                                        var lastobj = MessageListState[MessageListState.length - 1]
                                        lastobj.data.otherProps.btnResponse = lastbtnobj.convo[0].data.otherProps.btnResponse
                                        var templist = MessageListState

                                        //Old Code
                                        // resp.message.map((item) => {
                                        //     console.log("Inside map of resp");
                                        //     if (item.data.otherProps.action != "Operator closes the alert" && item.data.otherProps.action != "Autoclose alert") {
                                        //         templist.push(item)
                                        //     }
                                        // })
                                        // console.log(templist);
                                        // console.log(MessageListState);
                                        // setConversationsList(MessageListState)
                                        // ChatMessagesConversion(MessageListState, props.AlertDetails.hlsStreamingUrl)

                                        // New Code
                                        if (resp.message[0].data.otherProps.action == "Autoclose alert") {
                                            onUpdateAlert(3, props.AlertDetails.alertId)
                                            DeleteConversation(props.AlertDetails.alertId)
                                            Deletecloseobject(props.AlertDetails.alertId)
                                            ClearTimerList()
                                            StopAlertNotAckTimer(props.AlertDetails.alertId)
                                        }
                                                                        
                                        console.log("LastData", resp.message[0]);
                                        if(resp.message[0].data.otherProps.action != "Operator closes the alert"){
                                            resp.message.map((item) => {
                                                //if (item.data.otherProps.action == "Operator closes the alert" || item.data.otherProps.action == "Autoclose alert") {
                                                templist.push(item)
                                                //}
                                            })
                                        }
                                        setConversationsList(templist)
                                        buttonRef.current = false;
                                        ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)
                                        if(resp.message[0].data.otherProps.responseTime == ""){
                                            let c = countTwo + 1;
                                            setCountTwo(c);
                                        }
                                        return
                                    }
                                } else {
                                    alert(resp1.message)
                                }
                            })
                        } else {
                            if (isalertacksnooze == true) {
                                var lastobj = MessageListState[MessageListState.length - 1]
                                lastobj.data.otherProps.btnResponse = lastbtnobj.convo[0].data.otherProps.btnResponse
                                var templist = MessageListState
                                //AlertSnoozedOperations(templist,resp.message)
                                setConversationsList(templist)
                                buttonRef.current = false;
                                ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)
                                //var templist = MessageListState
                                // resp.message.map((item) => {
                                //     templist.push(item)
                                // })


                            } else {
                                var templist = MessageListState
                                resp.message.map((item) => {
                                    templist.push(item)
                                })
                                setConversationsList(templist)
                                var alertplaybackstep = resp.message.find(x => x.data.otherProps.action == "Send Pre & Post video to operator")
                                // if(alertplaybackstep){
                                //     RetriveAlertPlayback()
                                //     ChatMessagesConversion(templist,"Retrieving playback")
                                // }else{
                                buttonRef.current = false;
                                ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)
                                // }

                            }
                            return
                        }
                    }
                    return
                } else {
                    if (resp.message == "Next step does not exist") {
                        alert("Workflow for this alert does not exists. Can't Proceed further!!")
                    } else {
                        alert(resp.message)
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    const TimerList = (timerval) => {
        try {
            var timerlst = JSON.parse(localStorage.getItem('TimerList'))
            var lst = []
            if (timerlst) {
                lst = timerlst
                lst.push(timerval)
            } else {
                lst.push(timerval)
            }
            localStorage.setItem('TimerList', JSON.stringify(lst))
        } catch (error) {
            console.error(error)
        }
    }
    const ClearTimerList = () => {
        try {
            var timerlst = JSON.parse(localStorage.getItem('TimerList'))
            if (timerlst) {
                var lst = timerlst
                if (lst.length > 0) {
                    lst.map(item => {
                        try {
                            clearTimeout(item)
                        } catch (error) {
                            console.error(error)
                        }
                    })
                }
                localStorage.removeItem("TimerList")
            }
        } catch (error) {
            console.error(error)
        }
    }


    //#region Contact state officials hospital,police,fire

    const [ismodalOpen, setismodalOpen] = useState(false)
    const [selectedcontacttitle, setselectedcontacttitle] = useState(null)
    const [selectedcontactlist, setselectedcontactlist] = useState(null)

    const ShowSelectedContactDetails = (title, place) => {
        try {
            if (props.AlertDetails.siteLattitude != 0 || props.AlertDetails.siteLongitude != 0) {
                if (place == "demo_contact") {
                    var contactlst = [
                        {
                            "name": "Saurabh Y",
                            "phoneNumber": "9892522108",
                            "address": ""
                        },
                        {
                            "name": "Tejasvini K",
                            "phoneNumber": "9137343566",
                            "address": ""
                        },
                        {
                            "name": "Sanket R",
                            "phoneNumber": "7021408188",
                            "address": ""
                        },
                        {
                            "name": "Aniruddha N",
                            "phoneNumber": "7021408188",
                            "address": ""
                        }
                    ]
                    setselectedcontactlist(contactlst)
                    setismodalOpen(true)
                } else {
                    GetStateOfficialsContactList(place)
                }
                setselectedcontacttitle(title)
            } else {
                setselectedcontactlist(null)
                setselectedcontacttitle(title)
            }
        } catch (error) {
            console.error(error)
        }
    }
    const GetStateOfficialsContactList = async (place) => {
        try {
            var objNearByResources = {
                "SearchPlace": place,//"hospital,police,fire_station",
                "radius": 100,
                "lat": props.AlertDetails.siteLattitude,
                "long": props.AlertDetails.siteLongitude,
                "SearchType": "Top3Resources"
            }

            await nearbyresource(objNearByResources).then((data) => {
                if (data.status == "Ok")
                    setselectedcontactlist(data.message[0].resourceList)
                setismodalOpen(true)
            })
        } catch (error) {
            console.error(error)
        }
    }
    const StateOfficaialsList = () => (
        <div style={{ marginLeft: "5px" }} className="row">
            <div style={{ marginRight: "5px" }}>{ContactDetailsComponent("Police", "police")}</div>
            <div style={{ marginRight: "5px" }}>{ContactDetailsComponent("Hospital", "hospital")}</div>
            <div style={{ marginRight: "5px" }}>{ContactDetailsComponent("Fire station", "fire_station")}</div>
            {/* <div >{ContactDetailsComponent("Demo contact", "demo_contact")}</div> */}
        </div>
    )
    const ContactDetailsComponent = (title, placename) => (
        <button className="stat-officials-icon" onClick={() => ShowSelectedContactDetails(title, placename)}>{title}</button>
    )
    const MakeCall = (emergencyContact) => {
        try {
            let agentNo = emergencyContact
            let customerNo = LoggedInUser.PhoneNumber
            let obj = {
                "emeContactPhone": `+91${customerNo}`,
                "operatorPhone": `+91${agentNo}`
            }

            makecall(obj).then((resp) => {
                if (resp.status == "Ok") {
                    alert(resp.message)
                }
                else {
                    alert(resp.message)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    //#endregion

    const ChatMessagesConversion = async (convlist, hlsStreamingUrl) => {
        try {
            console.log("hlsStreamingUrl",hlsStreamingUrl)
            var messlength = convlist.length - 1
            var messageslist = []

            var index1 = 0
            var uploadevidencestate = false
            convlist.map((mess, index) => {
                index1 = index1 + 1
                if (mess.data.otherProps.action == "Contact to state official") {
                    var bmess = <StateOfficaialsList />
                    var prepostchatmess =
                        <>
                            <div className="chatFlowBoxContainer">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                                </div>
                            </div>
                            {bmess}
                        </>

                    var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        //loading:true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                } else if (mess.data.otherProps.action == "Escalation Matrix") { //implemented for zicom only. here we are just showing eme contact list
                    if (props.AlertDetails.emergencyContactsList == null) {
                        var bmess = <h3>No Contacts for Escalation</h3>
                    } else {
                        var bmess = EscalationContactList(props.AlertDetails.emergencyContactsList)
                    }

                    var prepostchatmess =
                        <>
                            <div className="chatFlowBoxContainer justify-content-start">
                                <div className="chatFlowChatBoxBig float-left">
                                    <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                                </div>
                            </div>
                            {bmess}
                        </>
                    var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        //loading:true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                } else if (mess.data.otherProps.action == "Please watch live stream") {
                    // if(props.AlertDetails.associatedCamList == null){
                    //     var bmess = <p>No devices associated</p>
                    // }else{
                    var bmess = AssociatedDevicesListLiveComponent(props.AlertDetails.associatedCamList)
                    // }

                    var prepostchatmess =
                        <>
                            <div className="chatFlowBoxContainer justify-content-start">
                                <div className="chatFlowChatBoxBig float-left">
                                    <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                                </div>
                            </div>
                            {bmess}
                        </>
                    var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        //loading:true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                } else if (mess.data.otherProps.action == "Request alert snapshot") {

                    var prepostchatmess =
                        <>
                            <AlertSnapshotComponent />
                        </>
                    var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        //loading:true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                } else if (mess.data.otherProps.botMessage == "Please acknowledge the alert") {
                    if (mess.data.otherProps.btnResponse != 0) {
                        if (mess.data.otherProps.btnResponse == 2 && messlength == index) {
                            var sec = parseInt((new Date() - new Date(mess.data.otherProps.responseTime)) / 1000)
                            var min = parseInt(sec / 60)
                            if (min > acksnoozetime) {
                                index1 = index1 + 1
                                //uploadevidencestate=false
                                //mess.data.otherProps.btnResponse = 0
                                var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                                var messobj = {
                                    id: index1,
                                    type: "bot",
                                    message: cmess,
                                    //loading:true,
                                    responseTime: mess.createdOn,
                                    direction: "left",
                                }
                                messageslist.push(messobj)
                            } else {
                                var lefttime = acksnoozetime - min

                                var alertsnoozetimer = setTimeout(() => {
                                    //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                                    var templist = convlist
                                    //snoozeresponse.data.otherProps.btnResponse=0
                                    //snoozeresponse.map((item) => {

                                    // mess.createdOn = ConvertDate(new Date())
                                    // mess.data.otherProps.btnResponse = 0
                                    const clone = JSON.parse(JSON.stringify(mess));
                                    //mess.data.otherProps.responseTime=ConvertDate(new Date())
                                    // templist.push(mess)
                                    templist.push(clone);
                                    //})

                                    let lastObj = templist[templist.length - 1];
                                    lastObj.createdOn = ConvertDate(new Date())
                                    lastObj.data.otherProps.btnResponse = 0
                                    let tempTimeList = templist;
                                    clearTimeout(alertsnoozetimer)
                                    ChatMessagesConversion(tempTimeList, props.AlertDetails.hlsStreamingUrl)
                                    // ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)

                                }, lefttime * 60 * 1000)
                                TimerList(alertsnoozetimer)
                                var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                                var messobj = {
                                    id: index1,
                                    type: "bot",
                                    message: cmess,
                                    //loading:true,
                                    responseTime: mess.createdOn,
                                    direction: "left",
                                }
                                messageslist.push(messobj)

                                var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)

                                index1 = index1 + 1
                                var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null, null)
                                var messobj = {
                                    id: index1,
                                    type: "user",
                                    message: cmess,
                                    //loading:true,
                                    responseTime: mess.data.otherProps.responseTime,
                                    direction: "right",
                                }
                                messageslist.push(messobj)
                            }

                        } else {
                            var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                            var messobj = {
                                id: index1,
                                type: "bot",
                                message: cmess,
                                responseTime: mess.createdOn,
                                direction: "left",
                            }
                            messageslist.push(messobj)

                            var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)

                            index1 = index1 + 1
                            var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null, null)
                            var messobj = {
                                id: index1,
                                type: "user",
                                message: cmess,
                                responseTime: mess.data.otherProps.responseTime,
                                direction: "right",
                            }
                            messageslist.push(messobj)
                        }
                    } else {
                        if (messlength == index) {
                            uploadevidencestate = false
                            var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                            var messobj = {
                                id: index1,
                                type: "bot",
                                message: cmess,
                                responseTime: mess.createdOn,
                                direction: "left",
                            }
                            messageslist.push(messobj)
                        }
                    }
                } else if (mess.data.otherProps.botMessage == "Please confirm if you want to close this alert?") {
                    //region new impl

                    if (mess.data.otherProps.btnResponse != 0) {
                        if (mess.data.otherProps.btnResponse == 2 && messlength == index) {
                            var sec = parseInt((new Date() - new Date(mess.data.otherProps.responseTime)) / 1000)
                            var min = parseInt(sec / 60)
                            if (min > acksnoozetime) {
                                index1 = index1 + 1
                                uploadevidencestate = false
                                mess.data.otherProps.btnResponse = 0
                                var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                                var messobj = {
                                    id: index1,
                                    type: "bot",
                                    message: cmess,
                                    //loading:true,
                                    responseTime: mess.createdOn,
                                    direction: "left",
                                }
                                messageslist.push(messobj)
                            } else {
                                var lefttime = acksnoozetime - min

                                var alertsnoozetimer = setTimeout(() => {
                                    //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                                    //var templist=convlist
                                    let temptempList = convlist;

                                    const clone = JSON.parse(JSON.stringify(mess));
                                    temptempList.push(clone);

                                    // var lastobj = convlist[convlist.length - 1]
                                    let lastobj = temptempList[temptempList.length - 1];
                                    lastobj.data.otherProps.btnResponse = 0
                                    // lastobj.data.createdOn = ConvertDate(new Date())
                                    lastobj.createdOn = ConvertDate(new Date())
                                    // var templisttimer = convlist
                                    let templisttimer = temptempList;
                                    //snoozeresponse.data.otherProps.btnResponse=0
                                    //snoozeresponse.map((item) => {
                                    // mess.createdOn = ConvertDate(new Date())
                                    // mess.data.otherProps.btnResponse=0
                                    // //mess.data.otherProps.responseTime=ConvertDate(new Date())
                                    // templist.push(mess)
                                    //})
                                    clearTimeout(alertsnoozetimer)
                                    ChatMessagesConversion(templisttimer, props.AlertDetails.hlsStreamingUrl)

                                }, lefttime * 60 * 1000)
                                TimerList(alertsnoozetimer)
                                var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                                var messobj = {
                                    id: index1,
                                    type: "bot",
                                    message: cmess,
                                    //loading:true,
                                    responseTime: mess.createdOn,
                                    direction: "left",
                                }
                                messageslist.push(messobj)

                                var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)

                                index1 = index1 + 1
                                var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null, null)
                                var messobj = {
                                    id: index1,
                                    type: "user",
                                    message: cmess,
                                    //loading:true,
                                    responseTime: mess.data.otherProps.responseTime,
                                    direction: "right",
                                }
                                messageslist.push(messobj)
                            }

                        } else if (mess.data.otherProps.btnResponse == 1 && messlength == index) {
                            var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                            var messobj = {
                                id: index1,
                                type: "bot",
                                message: cmess,
                                //loading:true,
                                responseTime: mess.createdOn,
                                direction: "left",
                            }
                            messageslist.push(messobj)

                            var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)

                            index1 = index1 + 1
                            var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null, null)
                            var messobj = {
                                id: index1,
                                type: "user",
                                message: cmess,
                                //loading:true,
                                responseTime: mess.data.otherProps.responseTime,
                                direction: "right",
                            }
                            messageslist.push(messobj)

                            if (buttonclicked.buttonProperties.responseText == "Alert has been closed successfully.") {
                                uploadevidencestate = true
                            }
                        } else {
                            var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                            var messobj = {
                                id: index1,
                                type: "bot",
                                message: cmess,
                                responseTime: mess.createdOn,
                                direction: "left",
                            }
                            messageslist.push(messobj)

                            var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)

                            index1 = index1 + 1
                            var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null, null)
                            var messobj = {
                                id: index1,
                                type: "user",
                                message: cmess,
                                responseTime: mess.data.otherProps.responseTime,
                                direction: "right",
                            }
                            messageslist.push(messobj)

                            if (buttonclicked.buttonProperties.responseText == "Alert has been closed successfully.") {
                                uploadevidencestate = true
                            }
                        }
                    } else {
                        uploadevidencestate = false
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)
                    }

                    //#endregion
                } else if (mess.data.otherProps.botMessage == "Please check the pre & post video link below:") {
                    var bmess = ""
                    // if(hlsStreamingUrl.includes("http://")){
                    //     bmess = <ReactPlayer
                    //         id={"video-" + props.AlertDetails.alertId}
                    //         url={hlsStreamingUrl}
                    //         playing={true}
                    //         width="264px"
                    //         height="150px"
                    //         controls={true}
                    //         style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }}
                    //     />
                    // }else{
                    if (hlsStreamingUrl) {
                        if (hlsStreamingUrl.includes("http://")) {
                            bmess = AssociatedDevicesList(props.AlertDetails.associatedCamList, props.AlertDetails.alertId, hlsStreamingUrl, true, false)
                        } else {
                            bmess = AssociatedDevicesList(props.AlertDetails.associatedCamList, props.AlertDetails.alertId, hlsStreamingUrl, false, false)
                        }
                    } else {
                        // bmess = AssociatedDevicesList(props.AlertDetails.associatedCamList, props.AlertDetails.alertId, hlsStreamingUrl, false, true)
                        bmess = AssociatedDevicesList(props.AlertDetails.associatedCamList, props.AlertDetails.alertId, hlsStreamingUrl, true, false)
                    }

                    //bmess = <img src={CemeraBGDiv} alt="Video" onClick={() => RetriveAlertPlayback()}/>
                    //bmess = hlsStreamingUrl
                    // }
                    var prepostchatmess =
                        <>
                            <div className="chatFlowBoxContainer">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess.data.otherProps.botMessage}</p>
                                </div>
                            </div>
                            <p>{bmess}</p>
                        </>

                    var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        //loading:true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                } else if (mess.data.otherProps.action == "Op Evidence") {
                    //setuploadfiles(false)
                    isevidenceupload = true
                    var evitxt = mess.data.otherProps.botMessage
                    var ertmess = ""
                    if (evitxt.includes("https://")) {
                        if (evitxt.includes(".jpg") || evitxt.includes(".jpeg") || evitxt.includes(".png")) {
                            ertmess = <div className="chatFlowMultiImageContainer"><img style={{cursor: 'pointer'}} className="chatFlowChatTimeIconBig" src={evitxt}
                                onClick={
                                    () => { handleChange(true, evitxt) }}
                            /></div>
                        } else if (evitxt.includes(".mp3")) {
                            ertmess = <div className="chatFlowVoiceBoxContainer"><audio src={evitxt} controls /></div>
                        } else {
                            ertmess = <a className="evidence-link" href={evitxt} target="_blank">{evitxt}</a>
                        }
                    } else {
                        ertmess = evitxt
                    }
                    var cmess = BotMessageComponent("user", ertmess, mess.data.otherProps.responseTime, null, null)
                    var messobj = {
                        id: index1,
                        type: "user",
                        message: cmess,
                        responseTime: mess.data.otherProps.responseTime,
                        direction: "right",
                    }
                    messageslist.push(messobj)
                } else if (mess.data.otherProps.action == "ERT Evidence") {
                    var evitxt = mess.data.otherProps.botMessage
                    var ertmess = ""
                    if (evitxt.includes("https://")) {
                        if (mess.data.otherProps.evidenceType == "img") {
                            ertmess = <div className="chatFlowMultiImageContainer"><img className="chatFlowChatTimeIconBig" src={evitxt}
                                onClick={
                                    () => {
                                        handleChange(true, evitxt)
                                    }
                                }
                            /></div>
                        } else if (mess.data.otherProps.evidenceType == "video") {
                            var ertmess = ""
                            if (evitxt.includes("https://")) {
                                ertmess = <ReactPlayer
                                    id={"video-" + props.AlertDetails.alertId}
                                    url={evitxt}
                                    playing={true}
                                    width="264px"
                                    height="150px"
                                    controls={true}
                                    style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }}
                                />
                            } else {
                                ertmess = <a className="evidence-link" href={evitxt} target="_blank">{evitxt}</a>
                            }
                        } else if (mess.data.otherProps.evidenceType == "location") {
                            var loc = mess.data.otherProps.botMessage.split("My current location : ")
                            ertmess = <><p>{"ERT current location : "}</p><a className="evidence-link" href={loc[1]} >{loc[1]}</a></>
                        } else if (mess.data.otherProps.evidenceType == "audio") {
                            ertmess = <div className="chatFlowVoiceBoxContainer"><audio src={evitxt} controls /></div>
                        } else {
                            ertmess = <a className="evidence-link" href={evitxt} target="_blank">{evitxt}</a>
                        }
                    } else {
                        ertmess = evitxt
                    }
                    var emess = BotMessageComponent("ert", ertmess, mess.data.otherProps.responseTime, null, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: emess,
                        responseTime: mess.data.otherProps.responseTime,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                } else if (mess.data.otherProps.botMessage == "Please attach alert evidence(E.G.: Videos, Comments, Documents, Voice notes & Photos)") {
                    //setuploadfiles(true)
                    if (mess.data.otherProps.btnResponse != 0) {
                        //setuploadfiles(false)
                        uploadevidencestate = false
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            //loading:true,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)

                        var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                        index1 = index1 + 1
                        var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            //loading:true,
                            responseTime: mess.data.otherProps.responseTime,
                            direction: "right",
                        }
                        messageslist.push(messobj)
                    } else {
                        //setuploadfiles(true)
                        uploadevidencestate = true
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            //loading:true,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)
                    }
                } else if (mess.data.otherProps.botMessage == "Please select the reason code") {
                    // alert(mess.data.otherProps.botMessage)
                    setReasonCodeList(mess)

                    var prepostchatmess = ReasonCodeComponent(mess.data.otherProps.buttons, mess.data.otherProps, convlist, hlsStreamingUrl)
                    var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null, "reasoncode")
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        //loading:true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)

                    if (mess.data.otherProps.btnResponse != 0 && mess.data.otherProps.responseTime != '') {
                        var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                        index1 = index1 + 1
                        var cmess = BotMessageComponent("user", mess.data.otherProps.selectedValue, mess.data.otherProps.responseTime, null, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            //loading:true,
                            responseTime: mess.data.otherProps.responseTime,
                            direction: "right",
                        }
                        messageslist.push(messobj)
                    }
                } else if (mess.data.otherProps.botMessage == "Please select the closure comment") {
                    setClosureCommentList(mess)
                    var prepostchatmess = ClosureCommentComponent(mess, convlist, hlsStreamingUrl)
                    var cmess = BotMessageComponent("bot", prepostchatmess, mess.createdOn, null, null, "closurecomment")
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        //loading:true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)
                    if (mess.data.otherProps.btnResponse != 0 && mess.data.otherProps.responseTime != '') {
                        var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                        index1 = index1 + 1
                        var cmess = BotMessageComponent("user", mess.data.otherProps.selectedValue, mess.data.otherProps.responseTime, null, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            //loading:true,
                            responseTime: mess.data.otherProps.responseTime,
                            direction: "right",
                        }
                        messageslist.push(messobj)
                    }

                } else if (mess.data.otherProps.btnResponse == 0) {
                    if (mess.data.otherProps.buttons.length > 0) {
                        var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, mess.data.otherProps.buttons, mess)
                        var messobj = {
                            id: index1,
                            type: "bot",
                            message: cmess,
                            //loading:true,
                            responseTime: mess.createdOn,
                            direction: "left",
                        }
                        messageslist.push(messobj)
                    }
                    else {
                        if (mess.data.otherProps.botMessage == "Autoclose alert") {
                            var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                            var messobj = {
                                id: index1,
                                type: "bot",
                                message: cmess,
                                //loading:true,
                                responseTime: mess.createdOn,
                                direction: "left",
                            }
                            messageslist.push(messobj)

                            index1 = index1 + 1
                            var cmess = BotMessageComponent("user", "Alert has been closed successfully.", mess.createdOn, null, null)
                            var messobj = {
                                id: index1,
                                type: "user",
                                message: cmess,
                                //loading:true,
                                responseTime: mess.createdOn,
                                direction: "right",
                            }
                            messageslist.push(messobj)
                        } else {
                            var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                            var messobj = {
                                id: index1,
                                type: "bot",
                                message: cmess,
                                //loading:true,
                                responseTime: mess.createdOn,
                                direction: "left",
                            }
                            messageslist.push(messobj)
                        }
                    }
                } else {
                    var cmess = BotMessageComponent("bot", mess.data.otherProps.botMessage, mess.createdOn, null, null)
                    var messobj = {
                        id: index1,
                        type: "bot",
                        message: cmess,
                        //loading:true,
                        responseTime: mess.createdOn,
                        direction: "left",
                    }
                    messageslist.push(messobj)

                    var buttonclicked = mess.data.otherProps.buttons.find(x => x.btnId == mess.data.otherProps.btnResponse)
                    if (buttonclicked) {
                        index1 = index1 + 1
                        var cmess = BotMessageComponent("user", buttonclicked.buttonProperties.responseText, mess.data.otherProps.responseTime, null, null)
                        var messobj = {
                            id: index1,
                            type: "user",
                            message: cmess,
                            //loading:true,
                            responseTime: mess.data.otherProps.responseTime,
                            direction: "right",
                        }
                        messageslist.push(messobj)
                    }
                }
            })
            if (props.AlertDetails.alertStatus == "Closed") {
                //setuploadfiles(true)
                uploadevidencestate = true
            }

            const sorted = messageslist.sort(function (a, b) {
                return new Date(a.responseTime) - new Date(b.responseTime);
            });
            Renderchatbot(sorted, convlist, uploadevidencestate)
        } catch (error) {
            console.error(error)
        }
    }

    const ReasonCodeClick = (reasoncode, convlist, hlsStreamingUrl) => {
        try {
            if (reasoncode) {
                setSelectedReasonCode(reasoncode)
                setIsReasonCodeSelected(true)
                var lastObj = convlist[convlist.length - 1]
                if (lastObj && lastObj.data.label == "Request to select reason code") {
                    lastObj.data.otherProps.btnResponse = reasoncode.btnId
                    lastObj.data.otherProps.selectedValue = reasoncode.title
                }
                ChatMessagesConversion(convlist, hlsStreamingUrl)
            }
        } catch (error) {

        }
    }

    async function ReasonCodeSendClick(e) {
        if (MessageListState.length == 0) {
            var obj = {
                "action": "get",
                "workflowId": "",
                "conversationId": ConversationId,
                "convo": []
            }
            conversationtemplatecrud(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message.rasaConversation != null) {
                        MessageListState = resp.message.rasaConversation
                        BotResponseClick(selectedReasonCode, reasonCodeList)
                    }
                }
            })
        }
        else {
            BotResponseClick(selectedReasonCode, reasonCodeList)
        }
        setIsReasonCodeSelected(false)
    }

    const Renderchatbot = (messageslist, convlist, uploadevidencestate) => {
        try {
            if (messageslist) {
                var lst = {
                    initialMessages: messageslist,
                    customComponents: {
                        header: () => <div className="chatbot-header" hidden></div>
                    },
                }
                setmessages(messageslist)
            }
            setConversationsList(convlist)
            setChatbotList(messageslist)
            setuploadfiles(uploadevidencestate)
            MessageListState = convlist
            FRUserState = FRUserState
            // props.scrollToBottom();
        } catch (error) {
            console.error(error)
        }
    }

    const SetAlertTATEvent = (existinglist, currentstep) => {
        try {
            if (existinglist.length > 0) {
                var templist = []
                existinglist.map((item) => {
                    if (item.data.otherProps.buttons.length != 0) {
                        templist.push(item)
                    }
                })
                if (templist.length > 0) {
                    if (templist[0].id == currentstep.id) {
                        mixpanel.time_event("Alert TAT")
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }
    const Setalertnotacktimeout = async (id) => {
        try {
            var res = await GetAlertNotAckRecord()
            res.onsuccess = function (event) {
                if (event.target.result != []) {
                    var existingtimer = event.target.result.find(x => x.alertId == id)
                    if (existingtimer) {
                        return
                    } else {
                        var notifytimer = setTimeout(() => {
                            var escallateimer = setTimeout(() => {
                                // alert("AlertId_" + id + " - Escallate api calling!")
                                clearTimeout(escallateimer)
                                EscallateAlert(id)
                            }, alertnotacktime * 60 * 1000)
                            TimerList(escallateimer)
                            AddChatWindowOpenedTime(id, null, escallateimer)
                            //alert("AlertId_" + id + " - Please acknowledge your alert, if you don't this alert will get reassigned.")
                            // alert("AlertId_" + id + " - User idle! , please perform operation, if you don't this alert will get reassigned.")
                            clearTimeout(notifytimer)
                        }, alertnotacktime * 60 * 1000)
                        AddChatWindowOpenedTime(id, notifytimer, null)
                        TimerList(notifytimer)
                    }
                }
            };
        } catch (error) {
            console.error(error)
        }
    }

    const EscallateAlert = async (id) => {
        try {
            var obj = {
                "action": "escalateAlert",
                "alertId": id,
                "userId": LoggedInUser.UserID,
                "datetime": ConvertDate(new Date())
            }
            await conversationtemplatecrud(obj).then((resp) => {
                if (resp.status == "Ok") {
                    // onCloseAlertChat(id)
                    DeleteChatWindowOpenedTime(id)
                    // GetAlertList()
                } else {
                    alert(resp.message)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    let buttonRef = useRef();
    const [botClick,setBotClick] = useState(false);
    console.log("anotherBotClick", buttonRef.current);
    const anotherBotClick = (e,button,Conversationdata) => {
        console.log("anotherBotClick", e, button , Conversationdata);
        console.log("anotherBotClick", buttonRef.current);
        if(buttonRef.current){
            return;
        }
        if(e.detail != 1){
            console.log("anotherBotClick", e.detail);
            return;
        }else{
        console.log("anotherBotClick", e.detail);
            buttonRef.current = true;
            BotResponseClick(button, Conversationdata)
        }
    }
  
    const BotResponseClick = async (button, Conversationdata) => {
        try {
            if (button.buttonProperties.responseText != "Alert has been closed successfully.") {
                StopAlertNotAckTimer(props.AlertDetails.alertId)
                Setalertnotacktimeout(props.AlertDetails.alertId)
            } else {
                StopAlertNotAckTimer(props.AlertDetails.alertId)
            }
            SetAlertTATEvent(MessageListState, Conversationdata)
            if (button.buttonProperties.responseText == "Evidences attached successfully") {
                if (isevidenceupload == false) {
                    alert("Kindly attach atleast one evidence, before going to next step.")
                    return
                }
            }
            if (button.buttonProperties.responseText == "Don't want to add Field Responder to the chatroom") {
                var closeobj = {
                    "action": "handleOpBotCloseMessage",
                    "userType": "ert",
                    "alertId": props.AlertDetails.alertId,
                    "step": Conversationdata,
                    "datetime": ConvertDate(new Date()),
                    "userId": LoggedInUser.UserID
                }
                await conversationtemplatecrud(closeobj).then((resp) => {
                    if (resp.status == "Ok") {
                        //GetConversationResponse(obj)
                    } else {
                        alert(resp.message)
                    }
                })
            }

            if (Conversationdata.data.label == "Request to select reason code") {
                Conversationdata.data.otherProps.selectedValue = button.title
                var lastObj = MessageListState[MessageListState.length - 1]
                if (lastObj && lastObj.data.label == "Request to select reason code") {
                    lastObj.data.otherProps.btnResponse = button.btnId
                    lastObj.data.otherProps.responseTime = ConvertDate(new Date())
                    lastObj.data.otherProps.selectedValue = button.title

                    var selButton = lastObj.data.otherProps.buttons.find(x => x.btnId == button.btnId)
                    if (selButton) {
                        selButton.buttonProperties.responseText = button.title
                    }
                }

            }
            if (Conversationdata.data.label == "Request to select closure comment") {
                Conversationdata.data.otherProps.selectedValue = button.title
                var lastObj = MessageListState[MessageListState.length - 1]
                if (lastObj && lastObj.data.label == "Request to select closure comment") {
                    lastObj.data.otherProps.btnResponse = button.btnId
                    lastObj.data.otherProps.responseTime = ConvertDate(new Date())
                    lastObj.data.otherProps.selectedValue = button.title

                    var selButton = lastObj.data.otherProps.buttons.find(x => x.btnId == button.btnId)
                    if (selButton) {
                        selButton.buttonProperties.responseText = button.title
                    }
                }
            }

            var templist = []
            //Conversationdata.createdOn=ConvertDate(new Date())
            Conversationdata.data.otherProps.btnResponse = button.btnId
            Conversationdata.data.otherProps.responseTime = ConvertDate(new Date())
            templist.push(Conversationdata)
            var obj = {
                "action": "add",
                "workflowId": props.AlertDetails.workflowId,
                "conversationId": ConversationId,
                "convo": templist
            }



            if (button.buttonProperties.responseText == "Alert has been closed successfully.") {

                //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                // var closemessobj = MessageListState.find(x => x.id == Conversationdata.id)
                let tempclosemessobj = MessageListState[MessageListState.length-1];
                //closemessobj.createdOn=ConvertDate(new Date())
                // closemessobj.data.otherProps.btnResponse = button.btnId
                // closemessobj.data.otherProps.responseTime = ConvertDate(new Date())

                tempclosemessobj.data.otherProps.btnResponse = button.btnId
                tempclosemessobj.data.otherProps.responseTime = ConvertDate(new Date())

                onUpdateAlert(3, props.AlertDetails.alertId)
                DeleteConversation(props.AlertDetails.alertId)
                Deletecloseobject(props.AlertDetails.alertId)
                ClearTimerList()
                conversationtemplatecrud(obj).then((resp) => {
                    //var lst=MessageListState
                    //lst.push(Conversationdata)
                    ChatMessagesConversion(MessageListState, props.AlertDetails.hlsStreamingUrl)

                })


                return
            } else if (button.buttonProperties.responseText == "Snoozed" || button.buttonProperties.responseText == "Snooze") {
                GetConversationResponse(obj, true)
            } else if (button.buttonProperties.responseText == "Alert acknowledged successfully") {
                try {
                    mixpanel.track("Chatflow page alert acknowledged :  AlertId:" + props.AlertDetails.alertId);
                } catch (error) {
                    console.error(error)
                }
                onUpdateAlert(2, props.AlertDetails.alertId)
                //autoclose
                // onUpdateAlert(3, props.AlertDetails.alertId)
                GetConversationResponse(obj)
                //StopAlertNotAckTimer(props.AlertDetails.alertId)
            } else if (button.buttonProperties.responseText == "Evidences attached successfully") {
                // if(isevidence == false){
                //     alert("Please attach evidence or comments!")
                // }else{
                GetConversationResponse(obj)
                //}
            } else if (button.buttonProperties.responseText == "Field Responder added to the chatroom successfully") {
                if (props.AlertDetails.frUsersList != null && props.AlertDetails.frUsersList.length != 0) {
                    //await AddingERTToChatroom(props.AlertDetails.alertId,obj)
                    await GetUserId(1, obj)
                } else {
                    const msg = 'Field responder is not configured for ' + props.AlertDetails.site + '!'
                    toast.warn(msg, {
                        position: "bottom-left",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    // alert("ERT user not added!")
                }
            } else {
                GetConversationResponse(obj)
            }

        } catch (error) {
            console.error(error)
        }
    }
    const AlertSnoozedOperations = (oldlist, snoozeresponse) => {
        try {
            //GetConversationResponse(obj,true)
            var alertsnoozetimer = setTimeout(() => {

                var templist = oldlist
                //snoozeresponse.data.otherProps.btnResponse=0
                snoozeresponse.map((item) => {
                    //item.data.otherProps.btnResponse=0
                    templist.push(item)
                })
                //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)
                clearTimeout(alertsnoozetimer)
            }, acksnoozetime * 60 * 1000)
        } catch (error) {
            console.error(error)
        }
    }



    //#region Adding ERT functions

    const EscallatingToERTPersonnel = async () => {
        try {
            var res = await GetERTCount()
            console.log("ERT Testing", res);
            res.onsuccess = function (event) {
                if (event.target.result.length > 0) {
                    var ert = event.target.result.find(x => x.alertId == props.AlertDetails.alertId)
                    if (ert) {
                        console.log("ERT Testing", ert);
                        if (ert.count == props.AlertDetails.emergencyContactsList.length) {
                            alert("AlertId: " + props.AlertDetails.alertId + " - Alert was not acknowledge by FR Users")
                            DeleteERTCount(props.AlertDetails.alertId)
                            return
                        } else {
                            var currentert = ert.count + 1
                            ContactUser(currentert)
                        }
                    } else {
                        console.log("ERT Testing", ert);
                        ContactUser(1)
                    }
                } else {
                    ContactUser(1)
                }
            };
        } catch (error) {
            console.error(error)
        }
    }
    const ContactUser = (currentcount) => {
        try {
            //var ertuser = props.AlertDetails.emergencyContactsList.find(x => x.contactLevel == currentcount)
            var ertuser = props.AlertDetails.emergencyContactsList[currentcount - 1]
            // var ertpersonnelescallateimer = setInterval(() => {
            //     clearInterval(ertpersonnelescallateimer)
            //     EscallatingToERTPersonnel()
            // }, Ertescallationtime * 60 * 1000)
            // AddERTCount(props.AlertDetails.alertId,currentcount,ertpersonnelescallateimer)

            if (ertuser.isEmail == true) {
                let mailobj = {
                    "emailId": ertuser.emailId,
                    "emailMessage": props.AlertDetails.alertId.toString()
                }
                sendemail(mailobj)
            }

            let agentNo = ertuser.phoneNumber
            let customerNo = LoggedInUser.PhoneNumber
            let obj = {
                "emeContactPhone": `+91${customerNo}`,
                "operatorPhone": `+91${agentNo}`
            }

            makecall(obj).then((resp) => {
                if (resp.status == "Ok") {
                    alert(resp.message + " to " + ertuser.contactName)
                    var ertpersonnelescallateimer = setInterval(() => {
                        clearInterval(ertpersonnelescallateimer)
                        EscallatingToERTPersonnel()
                    }, Ertescallationtime * 60 * 1000)
                    AddERTCount(props.AlertDetails.alertId, currentcount, ertpersonnelescallateimer)
                }
                else {
                    AddERTCount(props.AlertDetails.alertId, currentcount, 0)
                    alert(resp.message)
                    EscallatingToERTPersonnel()
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const AddingERTToChatroom = async (id, obj) => {
        try {
            var res = await GetERTCount()
            res.onsuccess = async function (event) {
                console.log("ERT Testing AddingERTToChatroom", event);
                if (event.target.result.length > 0) {
                    var ert = event.target.result.find(x => x.alertId == id)
                    console.log("ERT Testing AddingERTToChatroom", ert);
                    var rasaObject = {"RasaConversationID" : 'AlertID_3976'}

                    await getRasaConversation(rasaObject).then((resp) => {
                        if(resp.status == "ok"){
                            var operatorChats = JSON.parse(resp.message.replaceAll("'",'"'))
                        }

                    });

                    if (ert) {
                        console.log("ERT Testing AddingERTToChatroom", props.AlertDetails.frUsersList.length);
                        if (ert.count == props.AlertDetails.frUsersList.length) {
                            var convoobj = obj
                            console.log("ERT Testing AddingERTToChatroom", convoobj);
                            var frnotaddedobj = null//FRuseralertnotackobj(obj)
                            if (frnotaddedobj) { convoobj = frnotaddedobj }
                            // Commenting the below code for testing to uncomment it later
                            // GetConversationResponse(convoobj)

                            alert("AlertId: " + id + " - Alert was not acknowledge by FR Users")
                            DeleteERTCount(id)
                            EscallatingToERTPersonnel()
                            return
                        } else {
                            var currentert = ert.count + 1
                            //AddERTCount(id,currentert)
                            //return currentert
                            GetUserId(currentert, obj)
                        }
                    } else {
                        GetUserId(1, obj)
                    }
                } else {
                    //AddERTCount(id,1)
                    //return 1
                    GetUserId(1, obj)
                }
            };
        } catch (error) {
            console.error(error)
        }
    }
    const FRuseralertnotackobj = (obj) => {
        try {
            if (obj) {
                if (obj.convo.length > 0) {
                    if (obj.convo[0].data.otherProps.action == "Operator adds Field Responder to the chatroom") {
                        var btn = obj.convo[0].data.otherProps.buttons.find(x => x.buttonProperties.responseText == "Don't want to add Field Responder to the chatroom")
                        if (btn) {
                            obj.convo[0].data.otherProps.btnResponse = btn.btnId
                            return obj
                        }
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }
    const GetUserId = async (currentcount, obj) => {
        try {
            var l1user = props.AlertDetails.frUsersList.find(x => x.ContactLevel == currentcount)
            // var ertescallateimer = setInterval(() => {
            //     clearInterval(ertescallateimer)
            //     AddingERTToChatroom(props.AlertDetails.alertId,obj)
            // }, FRusersescallationtime * 60 * 1000)
            // AddERTCount(props.AlertDetails.alertId,currentcount,0)
            var addl1obj = {
                "ConversationId": "AlertID_" + props.AlertDetails.alertId + "_UserID_" + l1user.UserID,
                "UserID": l1user.UserID
            }
            //GetConversationResponse(obj)
            adderttochatroom(addl1obj).then((botresp) => {
                if (botresp.status == "Ok") {
                    //GetConversationResponse(obj)
                    //StopEscallationTimer()
                    
                    //Commenting below line
                    if(currentcount == 1){
                        GetConversationResponse(obj)
                    }

                    var ertobj = AddERTNewConversationObj(l1user.UserID)
                    conversationtemplatecrud(ertobj).then((resp) => {
                    })
                    alert("AlertId: " + props.AlertDetails.alertId + " - L" + currentcount + " " + botresp.message + " - UserId: " + l1user.UserID)
                    var ertescallateimer = setInterval(() => {
                        clearInterval(ertescallateimer)
                        AddingERTToChatroom(props.AlertDetails.alertId, obj)
                    }, FRusersescallationtime * 60 * 1000)
                    AddERTCount(props.AlertDetails.alertId, currentcount, ertescallateimer)
                } else if (botresp.message == "FCM Details not found" + l1user.UserID) {
                    AddERTCount(props.AlertDetails.alertId, currentcount, 0)
                    //UpdateMessageList1(null,message,false,true,false)
                    alert(botresp.message)
                    AddingERTToChatroom(props.AlertDetails.alertId, obj)
                } else {
                    alert(botresp.message)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    const AddERTNewConversationObj = (ertuid) => {
        try {
            var _datetime = ConvertDate(new Date())
            var convoData = [
                {
                    "id": "2",
                    "createdOn": _datetime,
                    "data": {
                        "label": "Alert is Displayed to an ERT",
                        "otherProps": {
                            "buttons": [],
                            "btnResponse": 0,
                            "responseTime": _datetime,
                            "nodeUserID": 2,
                            "botMessage": props.AlertDetails.alertTemplate + " " + props.AlertDetails.severity + " alert generated",
                            "selectedValue": ""
                        }
                    }
                }
            ]

            var obj = {
                "action": "add",
                "workflowId": props.AlertDetails.workflowId,
                "conversationId": "AlertID_" + props.AlertDetails.alertId + "_UserID_" + ertuid,
                "convo": convoData
            }
            return obj
        } catch (error) {
            console.error(error)
            return null
        }
    }

    //#endregion

    const StopAlertNotAckTimer = async (id) => {
        try {
            var res = await GetAlertNotAckRecord()
            res.onsuccess = function (event) {
                if (event.target.result != []) {
                    var timer = event.target.result.find(x => x.alertId == id)
                    if (timer) {
                        if (timer.notifytimer != null) { clearTimeout(timer.notifytimer) }
                        if (timer.escallatetimer != null) { clearTimeout(timer.escallatetimer) }
                        DeleteChatWindowOpenedTime(id)
                    }
                }
            };
        } catch (error) {
            console.error(error)
        }
    }


    //#region ERTMessages state

    useEffect(() => {
        console.log("UseEffect 2");
        try {
            if (ChatbotList != null) {
                if (ChatbotList.length == 0) { return }
                if (conversationstate.length > 0) {
                    if (conversationstate[0] == null) { return }
                    var alerttxt = ConversationId.split("_UserID_")[0]
                    var alertid = alerttxt.split("AlertID_")[1]
                    Object.keys(conversationstate[0]).map(function (key, value) {
                        if (key == alertid) {
                            if (conversationstate[0][key] == "Alert has been closed successfully.") {
                                ClearERTEscallationTimer()
                                var obj = {
                                    "action": "handleOpBotCloseMessage",
                                    "userType": "ert",
                                    "alertId": props.AlertDetails.alertId,
                                    "step": "Alert has been closed successfully.",
                                    "datetime": ConvertDate(new Date()),
                                    "userId": LoggedInUser.UserID
                                }

                                conversationtemplatecrud(obj).then((resp) => {
                                    if (resp.status == "Ok") {
                                        if (resp.message == true) {
                                            //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                                            AddOperatorAlertCloseMessage(props.AlertDetails.alertId, resp.step)
                                        }
                                    } else {
                                        alert(resp.message)
                                    }
                                })

                                //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                                //AddOperatorAlertCloseMessage(props.AlertDetails.alertId)


                            } else if (conversationstate[0][key] == "Alert acknowledged successfully") {
                                ClearERTEscallationTimer()
                                //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                                // var messageslist = ChatbotList
                                // if(props.AlertDetails.isAddERTStepExistInWF == true){
                                // }else{
                                var obj = {
                                    "action": "get",
                                    "workflowId": "",
                                    "conversationId": ConversationId,
                                    "convo": []
                                }
                                conversationtemplatecrud(obj).then((resp) => {
                                    if (resp.status == "Ok") {
                                        if (props.AlertDetails.isAddERTStepExistInWF == true) {
                                            var tempmesslst = resp.message.rasaConversation
                                            let data = tempmesslst.filter((value) => {
                                                return value.data.otherProps.botMessage !== "Please confirm if you want to close this alert?"
                                            })
                                            let finallist = data.filter((value) => {
                                                return value.data.otherProps.botMessage !== "Autoclose alert"
                                            })
                                            ChatMessagesConversion(finallist, props.AlertDetails.hlsStreamingUrl)
                                        } else {
                                            ChatMessagesConversion(resp.message.rasaConversation, props.AlertDetails.hlsStreamingUrl)
                                        }
                                    } else if (resp.status == "Failed") {
                                        alert(resp.message)
                                    } else {
                                        alert(resp.message)
                                    }
                                })
                                //}

                            } else {
                                ClearERTEscallationTimer()
                                //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                                var obj = {
                                    "action": "get",
                                    "workflowId": "",
                                    "conversationId": ConversationId,
                                    "convo": []
                                }
                                conversationtemplatecrud(obj).then((resp) => {
                                    if (resp.status == "Ok") {
                                        if (props.AlertDetails.isAddERTStepExistInWF == true) {
                                            var tempmesslst = resp.message.rasaConversation
                                            let data = tempmesslst.filter((value) => {
                                                return value.data.otherProps.botMessage !== "Please confirm if you want to close this alert?"
                                            })
                                            let finallist = data.filter((value) => {
                                                return value.data.otherProps.botMessage !== "Autoclose alert"
                                            })
                                            ChatMessagesConversion(finallist, props.AlertDetails.hlsStreamingUrl)
                                        } else {
                                            ChatMessagesConversion(resp.message.rasaConversation, props.AlertDetails.hlsStreamingUrl)
                                        }
                                    } else if (resp.status == "Failed") {
                                        alert(resp.message)
                                    } else {
                                        alert(resp.message)
                                    }
                                })
                                //ChatMessagesConversion(ConversationsList)
                            }
                        }
                    });
                }
            }
        } catch (error) {
            console.error(error)
        }
    }, [conversationstate])

    const AddOperatorAlertCloseMessage = async (id, closestepobj) => {
        try {
            closestepobj.createdOn = ConvertDate(new Date())
            if (closestepobj.data.otherProps.action == "Autoclose alert") {
                onUpdateAlert(3, props.AlertDetails.alertId)
                DeleteConversation(props.AlertDetails.alertId)
                Deletecloseobject(props.AlertDetails.alertId)
                ClearTimerList()
                StopAlertNotAckTimer(props.AlertDetails.alertId)

                var templist = ConversationsList
                if (closestepobj.data.otherProps.action != "ERT Evidence" || closestepobj.data.otherProps.action != "Op Evidence") {
                    var isstepexists = templist.find(x => x.id == closestepobj.id)
                    if (!isstepexists) {
                        templist.push(closestepobj)
                    }
                    ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)
                }

            } else {
                var templist = ConversationsList
                if (closestepobj.data.otherProps.action != "ERT Evidence" || closestepobj.data.otherProps.action != "Op Evidence") {
                    //var isstepexists = templist.find(x => x.id == closestepobj.id)
                    var isstepexists = templist.find(x => x.data.otherProps.action == "Operator closes the alert")
                    if (!isstepexists) {
                        //templist.push(closestepobj)
                        //setConversationsList(templist)
                        var cmess = AlertCloseBotMessageComponent(closestepobj.data.otherProps.botMessage, closestepobj.createdOn, closestepobj.data.otherProps.buttons, closestepobj)
                        var messageslist = ChatbotList
                        var messobj = {
                            id: messageslist.length + 1,
                            type: "bot",
                            message: cmess,
                            loading: true,
                            direction: "left"
                        }
                        messageslist.push(messobj)

                        var lst = {
                            initialMessages: messageslist,
                            customComponents: {
                                header: () => <div className="chatbot-header" hidden></div>
                            },
                        }
                        templist.push(closestepobj)
                        ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)
                        setConversationsList(templist)
                        MessageListState = templist
                    } else {
                        var templist = ConversationsList
                        if (closestepobj.data.otherProps.action != "ERT Evidence" || closestepobj.data.otherProps.action != "Op Evidence") {
                            var isstepexists = templist.find(x => x.id == closestepobj.id)
                            if (!isstepexists) {
                                templist.push(closestepobj)
                            }
                            ChatMessagesConversion(templist, props.AlertDetails.hlsStreamingUrl)
                        }
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const ClearERTEscallationTimer = async () => {
        try {
            var res = await GetERTCount()
            res.onsuccess = function (event) {
                if (event.target.result.length > 0) {
                    var ert = event.target.result.find(x => x.alertId == props.AlertDetails.alertId)
                    if (ert) {
                        clearInterval(ert.ertescallateimer)
                        DeleteERTCount(props.AlertDetails.alertId)
                        return
                    }
                }
            };
        } catch (error) {
            console.error(error)
        }
    }

    //#endregion

    const onUpdateAlert = async (action, alertid) => {
        try {
            var _datetime = ConvertDate(new Date())
            var obj = {
                "alertID": alertid,
                "userUniqueID": LoggedInUser.UserUniqueID,
                "alertStatusId": action,
                "alertTime": _datetime,
                "parentCameraID": props.AlertDetails.deviceId,
                "alertSource": "Web",
                "systemDeviceID": "",
                "alertSeverity": "",
                "alertUpdateKey": "AlertStatus",
            }
            return await updatealert(obj).then(res => {
                if (res.status == "Ok") {
                    console.log("AlertDetails", res, action);
                    console.log("Hootway data", props.hootWay);   
                    let tempArr = props.hootWay.map((item) => {
                        let obj;
                        if(item.alertId == alertid){
                            obj = {
                                ...item,
                                btnDisabled: false
                            }
                        }else{
                            obj = {
                                ...item
                            }
                        }
                        return obj;
                    })
                    console.log("hootWayData", tempArr);
                    props.setHootWay(tempArr);
                    // Do change the array over here for button disable.
                    if (action == 3) {
                        props.ResetAlertList()
                        mixpanel.track("Alert TAT", {
                            'ConversationId': "AlertID_" + props.AlertDetails.alertId + "_UserID_" + props.AlertDetails.assignedTo,
                            'AlertTemplate': props.AlertDetails.alertTemplate,
                            'AlertId': props.AlertDetails.alertId,
                            'Severity': props.AlertDetails.severity
                        });
                    }
                    //StopAlertAckTimer()
                    //console.log(res)
                    //alert(res.message)
                } else {
                    console.log(res)
                }
            })
        } catch (error) {
            console.error(error)
        }
        
    }
    const CloseAlertBotResponseClick = (button, Conversationdata) => {
        try {
            Conversationdata.createdOn = ConvertDate(new Date())
            Conversationdata.data.otherProps.btnResponse = button.btnId
            Conversationdata.data.otherProps.responseTime = ConvertDate(new Date())
            //ConversationsList.push(Conversationdata)
            var objtemplist = []
            objtemplist.push(Conversationdata)
            var obj = {
                "action": "add",
                "workflowId": props.AlertDetails.workflowId,
                "conversationId": ConversationId,
                "convo": objtemplist
            }
            if (button.btnId == 2) {
                GetConversationResponse(obj, true)
            } else {
                //ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+props.AlertDetails.alertId))
                onUpdateAlert(3, props.AlertDetails.alertId)
                DeleteConversation(props.AlertDetails.alertId)
                Deletecloseobject(props.AlertDetails.alertId)
                StopAlertNotAckTimer(props.AlertDetails.alertId)
                conversationtemplatecrud(obj).then((resp) => {
                    if (resp.message == "Next step does not exist") {
                        var templst = ConversationsList
                        templst.push(Conversationdata)
                        setConversationsList(templst)
                        ChatMessagesConversion(templst, props.AlertDetails.hlsStreamingUrl)
                    }
                })
                //ChatMessagesConversion(ConversationsList)
            }
        } catch (error) {
            console.error(error)
        }
    }

    //#region Chatbot message components

    const BotMessageComponent = (chattype, mess, chatdatetime, buttonlist, Conversationdata, node) => (
        <div>
            {
                (chattype == "bot")
                    ?
                    <>
                        <div className="chatFlowBoxContainer-bot">
                            <div className="chatFlowTimeIconContainerBig">
                                <img className="chatFlowChatTimeIconBig" src={BotAvatarIcon} />
                            </div>
                            <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
                        </div>
                        {
                            !buttonlist && node == undefined &&
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowChatBoxBig">
                                    <span className="chatFlowChatTextBig">{mess}</span>
                                </div>
                            </div>
                        }
                        {
                            !buttonlist && node != undefined && (node == 'reasoncode' || node == 'closurecomment') &&
                            <>{mess}</>
                        }
                        {
                            buttonlist &&
                            <div className="chatFlowConfirmationBox">
                                <div className="chatFlowConfirmationBoxTitleBig">
                                    <p>{mess}</p>
                                </div>
                                {
                                    buttonlist.map((buttons, index) => (
                                        
                                        <button
                                            className="chatFlowConfirmationBoxButtonLeft chatFlowConfirmationBoxButtonSep d-flex align-items-center justify-content-center"
                                            id={buttons.btnId} key={index} onClick={(e) => anotherBotClick(e, buttons, Conversationdata)}
                                        >
                                            <p id={buttons.payload} className="chatFlowBoxCenterTextBigText12">{buttons.title}</p>
                                        </button>
                                    ))
                                }
                            </div>
                        }
                    </>
                    :
                    (chattype == "ert")
                        ?
                        <>
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowTimeIconContainerBig">
                                    <img className="chatFlowChatTimeIconBig" src={ERTAvatarIcon} />
                                </div>
                                <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
                            </div>
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess}</p>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className="chatFlowBoxContainer">

                                <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
                                <div className="chatFlowTimeIconContainerBig">
                                    <img className="chatFlowChatTimeIconBig" src={UserAvatarIcon} />
                                </div>
                            </div>
                            <div className="chatFlowBoxContainer">
                                <div className="chatFlowChatBoxBig">
                                    <p className="chatFlowChatTextBig">{mess}</p>
                                </div>
                            </div>
                        </>

            }
        </div>
    )

    const AlertCloseBotMessageComponent = (mess, chatdatetime, buttonlist, Conversationdata) => (
        <>
            <div className="chatFlowBoxContainer-bot">
                <div className="chatFlowTimeIconContainerBig">
                    <img className="chatFlowChatTimeIconBig" src={BotAvatarIcon} />
                </div>
                <p className="chatFlowChatTimeIconTextBig">{frontDateConverter(chatdatetime)}</p>
            </div>
            {
                !buttonlist &&
                <div className="chatFlowBoxContainer-bot">
                    <div className="chatFlowChatBoxBig">
                        <span className="chatFlowChatTextBig">{mess}</span>
                    </div>
                </div>
            }
            {
                buttonlist &&
                <div className="chatFlowConfirmationBox">
                    <div className="chatFlowConfirmationBoxTitleBig">
                        <p>{mess}</p>
                    </div>
                    {
                        buttonlist.map((buttons, index) => (
                            <div
                                className="chatFlowConfirmationBoxButtonLeft chatFlowConfirmationBoxButtonSep d-flex align-items-center justify-content-center col"
                                id={buttons.btnId} key={index} onClick={() => CloseAlertBotResponseClick(buttons, Conversationdata)}
                            >
                                <p id={buttons.payload} className="chatFlowBoxCenterTextBigText12">{buttons.title}</p>
                            </div>
                        ))
                    }
                </div>
            }
        </>
    )

    //#endregion

    //#region Evidence upload operations

    const OnEvidenceAttached = (evidenceRes) => {
        console.log("Evidence attached calling get api");
        try {
            setisevidence(true)
            var obj = {
                "action": "get",
                "workflowId": "",
                "conversationId": ConversationId,
                "convo": []
            }
            conversationtemplatecrud(obj).then((resp) => {
                if (resp.status == "Ok") {
                    ChatMessagesConversion(resp.message.rasaConversation, props.AlertDetails.hlsStreamingUrl)
                } else if (resp.status == "Failed") {
                    alert(resp.message)
                } else {
                    alert(resp.message)
                }
            })
            //ChatMessagesConversion(ConversationsList)
        } catch (error) {
            console.error(error)
        }
    }

    //#endregion


    const [playbackurl, setplaybackurl] = useState(null)
    const RetriveAlertPlayback1 = (name) => {
        try {
            // var dev=associateddeviceslist.find(x => x.deviceName == name)
            // dev.playbackUrl="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
            setplaybackurl("http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4")
        } catch (error) {
            console.error(error)
        }
    }

    const AssociatedDevicesList = (lst, alertid, hlsStreamingUrl, isplayback, playbackFlag) => {
        console.log("rupesh checks",lst)
        return <>
            {lst.length > 0 &&
                <div>
                    <div className="chatbot-associated-devices-list">
                        <AlertSourceDevicePlaybackComponent />
                        <span className="Ass_Name">Associated Devices</span>
                        {lst.map(item => (
                        <>
                            <div style={{ display: "flex" }}>
                                <img className="playback-video-image" src={CemeraBGDiv} alt="Video" onClick={() => RetriveAlertPlayback(item)} />
                                <p>{item.cameraName}</p>
                            </div>
                        </>
                        ))}
                    </div>
                    {isplayback && url && IS_WEBRTC == 0 &&
                        <>
                            <p><span style={{ fontWeight: "400" }}>{props.AlertDetails.deviceName}</span>
                                <span style={{ float: "right", fontWeight: "bold" }}>Playback</span>
                            </p>
                            <div className="chatbotPlaybackCameraContainerMain">
                                <div className="chatbotPlaybackCameraContainer"
                                ref={playbackRef}
                                >
                            <ReactCustomPlayer
                                // url={hlsStreamingUrl}
                                url={url}
                                id={"video-" + alertid}
                                devname = {props.AlertDetails.deviceName}
                                // isSnapShotBtnClick = {isSnapShotBtnClick}
                                // setIsSnapShotBtnClick={setIsSnapShotBtnClick}
                                captureVideoFrameData={captureVideoFrameData}
                                isFullScreenPlayback={isFullScreenPlayback}
                                isSnapShotBtnClickPb={isSnapShotBtnClickPb}
                                setIsSnapShotBtnClickPb={setIsSnapShotBtnClickPb}
                                playbackRef={playbackRef}
                                randomId={randomId}
                                setRandomId={setRandomId}
                            />
                            <div className='mosaicButtonsMainDiv' style={{width: "100%"}}>
                                    <div className='mosaicbuttons'>
                                    <button data-title='SnapShot' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicSnapshot} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        // onClick={()=>{SnapShotBtnClick()}}
                                        onClick={screenshotPlayback}
                                        />
                                    </button>
                                    {/* <button data-title='TwowayStart' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicTwowayStart} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button>
                                    <button data-title='TwowayStop' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicTwowayStop} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button> */}

                                    {/* <button data-title='HooterOn' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicHooterOn} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button>
                                    <button data-title='HooterOff' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicHooterOff} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button> */}
                                    {
                                        !isFullScreenPlayback
                                        ?
                                        <button data-title='Fullscreen' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicFullscreen} 
                                        onClick={() => fullScreenPlayback(true)}
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button>
                                        :
                                        <button data-title='Exit Fullscreen' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicExitFullscreen} 
                                        onClick={() => fullScreenPlayback(false)}
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                        </button>

                                    }                                    
                                    </div>
                                </div>
                                </div>
                            </div>
                            <p className='customplayer-label'>Playing from 1 min before the alert time.</p>
                            {/* <ReactPlayer
                            id={"video-" + alertid}
                            url={hlsStreamingUrl}
                            playing={true}
                            width="264px"
                            height="150px"
                            controls={true}
                            style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }}
                        />                       */}
                        </>
                    }

                    {
                        showWebRTCPlayer && IS_WEBRTC == 1 &&
                        <div className="chatbotPlaybackCameraContainerMain">
                            <p><span style={{ fontWeight: "400" }}>{videoItem.cameraName}</span>
                                <span style={{ float: "right", fontWeight: "bold" }}>Playback</span>
                            </p>
                            <div className="chatbotPlaybackCameraContainer">
                              <WebRTCPlayer 
                                        key={videoItem.associatedCameraId}
                                        id={videoItem.associatedCameraId + "98"}
                                        devId={videoItem.associatedCameraId}
                                        url={webRTCUrl}
                                        isEncode={videoItem.isTranscoded}
                                        item={videoItem}
                                        actualTime={props.AlertDetails.actualTime}
                                        alertDetail={props.AlertDetails}

                                        upperDivCss="cameraHoverMainDiv"
                                        videoCss="player-responsive1"
                                        //videoCss="chatbotliveplayer"
                                        fullVideoCss="player-responsive-fullScreen"
                                        buttonCss="cameraButtonsMainDiv"
                                        noStreamDivCss="no-stream-found"
                                        
                                        playback={false}
                                        prePostDisplay={false}
                                        liveButton={true}
                                        exportCurrentVideoHide={true}
                                        showPlaybackBtn={false}
                                        hideTwoWay={true}
                                        imageToChat={AttachalertevidencesParent}
                                        showPrePostReplay={true}
                              />  
                            </div>
                        </div>
                    }
                </div>
            }
            {lst.length == 0 &&
                <>
                    <AlertSourceDevicePlaybackComponent />
                    {
                        playbackFlag ? <p>Playback not found</p> : null
                    }
                    {isplayback && url && IS_WEBRTC == 0 ?(
                    <>
                        <p><span style={{ fontWeight: "400" }}>{props.AlertDetails.deviceName}</span>
                            <span style={{ float: "right", fontWeight: "bold" }}>Playback</span>
                        </p>
                        <div className="chatbotPlaybackCameraContainerMain">
                            <div className="chatbotPlaybackCameraContainer"
                                ref={playbackRef}
                                >
                                <ReactCustomPlayer
                                    // url={hlsStreamingUrl}
                                    url={url}
                                    id={"video-" + alertid}
                                    devname={props.AlertDetails.deviceName}
                                    isFullScreenPlayback={isFullScreenPlayback}
                                    isSnapShotBtnClickPb={isSnapShotBtnClickPb}
                                    setIsSnapShotBtnClickPb={setIsSnapShotBtnClickPb}
                                    playbackRef={playbackRef}
                                    captureVideoFrameData={captureVideoFrameData}
                                    randomId={randomId}
                                    setRandomId={setRandomId}
                                />
                                <div className='mosaicButtonsMainDiv' style={{width: "100%"}}>
                                            <div className='mosaicbuttons'>
                                            <button data-title='SnapShot' className='ChatbotCameraButton'>
                                                <img style={{ padding: "2px" }} 
                                                src={MosaicSnapshot} 
                                                // onClick={() => ScreenShotOkClick('start', item)} 
                                                onClick={screenshotPlayback}
                                                />
                                            </button>
                                            {/* <button data-title='TwowayStart' className='ChatbotCameraButton'>
                                                <img style={{ padding: "2px" }} 
                                                src={MosaicTwowayStart} 
                                                // onClick={() => ScreenShotOkClick('start', item)} 
                                                />
                                            </button>
                                            <button data-title='TwowayStop' className='ChatbotCameraButton'>
                                                <img style={{ padding: "2px" }} 
                                                src={MosaicTwowayStop} 
                                                // onClick={() => ScreenShotOkClick('start', item)} 
                                                />
                                            </button> */}

                                            {/* <button data-title='HooterOn' className='ChatbotCameraButton'>
                                                <img style={{ padding: "2px" }} 
                                                src={MosaicHooterOn} 
                                                // onClick={() => ScreenShotOkClick('start', item)} 
                                                />
                                            </button>
                                            <button data-title='HooterOff' className='ChatbotCameraButton'>
                                                <img style={{ padding: "2px" }} 
                                                src={MosaicHooterOff} 
                                                // onClick={() => ScreenShotOkClick('start', item)} 
                                                />
                                            </button> */}
                                            {
                                                !isFullScreenPlayback
                                                ?
                                                <button data-title='Fullscreen' className='ChatbotCameraButton'>
                                                <img style={{ padding: "2px" }} 
                                                src={MosaicFullscreen} 
                                                onClick={() => fullScreenPlayback(true)}
                                                // onClick={() => ScreenShotOkClick('start', item)} 
                                                />
                                            </button>
                                                :
                                                <button data-title='Exit Fullscreen' className='ChatbotCameraButton'>
                                                <img style={{ padding: "2px" }} 
                                                src={MosaicExitFullscreen} 
                                                onClick={() => fullScreenPlayback(false)}
                                                // onClick={() => ScreenShotOkClick('start', item)} 
                                                />
                                                </button>
                                            }
                                            </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : null
                    }
                    {
                        showWebRTCPlayer && IS_WEBRTC == 1 
                        ?
                        <>
                        <p><span style={{ fontWeight: "400" }}>{props.AlertDetails.deviceName}</span>
                            <span style={{ float: "right", fontWeight: "bold" }}>Playback</span>
                        </p>
                        <div className="chatbotPlaybackCameraContainerMain">
                            <div className="chatbotPlaybackCameraContainer"
                                ref={playbackRef}
                                >
                                {/* <ReactCustomPlayer
                                    // url={hlsStreamingUrl}
                                    url={url}
                                    id={"video-" + alertid}
                                    devname={props.AlertDetails.deviceName}
                                    isFullScreenPlayback={isFullScreenPlayback}
                                    isSnapShotBtnClickPb={isSnapShotBtnClickPb}
                                    setIsSnapShotBtnClickPb={setIsSnapShotBtnClickPb}
                                    playbackRef={playbackRef}
                                    captureVideoFrameData={captureVideoFrameData}
                                    randomId={randomId}
                                    setRandomId={setRandomId}
                                /> */}

                                    <WebRTCPlayer 
                                        key={props.AlertDetails.deviceId}
                                        id={props.AlertDetails.deviceId + "98"}
                                        devId={props.AlertDetails.deviceId}
                                        url={webRTCUrl}
                                        isEncode={props.AlertDetails.isTranscoded}
                                        item={props.AlertDetails}
                                        actualTime={props.AlertDetails.actualTime}
                                        alertDetail={props.AlertDetails}

                                        upperDivCss="cameraHoverMainDiv"
                                        videoCss="player-responsive1"
                                        //videoCss="chatbotliveplayer"
                                        fullVideoCss="player-responsive-fullScreen"
                                        buttonCss="cameraButtonsMainDiv"
                                        noStreamDivCss="no-stream-found"

                                        playback={false}
                                        prePostDisplay={false}
                                        liveButton={true}
                                        exportCurrentVideoHide={true}
                                        showPlaybackBtn={false}
                                        hideTwoWay={true}
                                        imageToChat={AttachalertevidencesParent}
                                        showPrePostReplay={true}
                                    />  
                            </div>
                        </div>
                    </>
                        : null   
                    }
                    <span  className="Ass_Name">Associated Devices</span>
                    <p>No devices associated</p>
                </>}
        </>
    }

    useEffect(() => {
        if(screenfull.isEnabled){
          screenfull.on('change', () => {
            console.log("CheckFullScreen", screenfull.isFullscreen);
    
          })
        }
      }, [screenfull]);

      const keyDownHandler = event => {
        console.log("Key Click", event.key);
      }

      useEffect(() => {
        document.addEventListener('fullscreenchange', exitHandler);
      function exitHandler() {
          if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
              console.log("Plain JS screenfull close");
              setIsAlertLiveFullscreenClick(false);
              isFullScreenRef.current = true;
              let c = count + 1;
              setCount(c);    
          }else{
            console.log("Plain JS screenfull start");
          }
      }  
      return () => {
        document.removeEventListener('fullscreenchange', exitHandler);
      }
      }, []);

      const handleAlertLiveFullScreen = () => {
        screenfull.toggle(videoContainerParentRef.current)
        console.log("handleFullScreen", screenfull.isFullscreen);
        console.log("isAlertLiveFullscreenClick", !screenfull.isFullscreen)
        setIsAlertLiveFullscreenClick(!screenfull.isFullscreen);
        let c = count + 1;
        setCount(c);
      }

    const AlertSourceDevicePlaybackComponent = () => (
        <>
            {
                (props.AlertDetails.deviceTypeID == 1)
                    ?
                    <>
                        <span>Alert Device</span>
                        <div style={{ display: "flex", alignItems: 'flex-end' }}>
                            <img className="playback-video-image" src={CemeraBGDiv} alt="Video" onClick={() => RetriveAlertPlayback()} />
                            <p>{props.AlertDetails.deviceName}</p>
                        </div>
                        <hr className="solid"></hr>
                    </>
                    :
                    <>
                    </>
            }
        </>
    )

    const AssociatedDevicesListLiveComponent = (lst) => {
        console.log("lst", lst);
        return <>
            {lst.length > 0 &&
                <div>
                    <AlertSourceDeviceLiveComponent />
                    <span  className="Ass_Name">Associated Devices</span>
                    {lst.map(item => (
                        // PlayerComponent(item.deviceId, item.cameraName, item.hlsStreamingUrl)
                        // <PlayerComponent devid={item.deviceId} devname={item.cameraName} devurl={item.hlsStreamingUrl} item={item} associated={true} />
                        <div className="chatbot-live-camera" key={item.associatedCameraId}>
                        <p className="chatbotLiveCameraName"><span>{item.cameraName}</span> </p>
                        <div className="chatbotLiveCameraContainer">
                                <WebRTCPlayer
                                    key={item.associatedCameraId}
                                    id={item.associatedCameraId + "47"}
                                    devId={item.associatedCameraId}
                                    url={item.rtspUrl}
                                    isEncode={item.isTranscoded}
                                    actualTime={props.AlertDetails.actualTime}
                                    alertDetail={props.AlertDetails}
                                    item={item}

                                    upperDivCss="cameraHoverMainDiv"
                                    videoCss="player-responsive1"
                                    //videoCss="chatbotliveplayer"
                                    fullVideoCss="player-responsive-fullScreen"
                                    buttonCss="cameraButtonsMainDiv"
                                    noStreamDivCss="no-stream-found"

                                    playback={false}
                                    prePostDisplay={false}
                                    liveButton={true}
                                    exportCurrentVideoHide={true}
                                    showPlaybackBtn={false}
                                    hideTwoWay={true}
                                    imageToChat={AttachalertevidencesParent}
                                />
                        </div>
                      </div>
                    ))}
                </div>
            }
            {lst.length == 0 &&
                <>
                    {/* <AlertSourceDeviceLiveComponent /> */}
                    {
                (props.AlertDetails.deviceTypeID == 1)
                    ?
                    <>
                        <span>Alert Device</span>
                        {/* {PlayerComponent(props.AlertDetails.deviceId, props.AlertDetails.deviceName, props.AlertDetails.hlsStreamingUrl)} */}
                        <div className="chatbot-live-camera">
                        <p className="chatbotLiveCameraName"><span>{props.AlertDetails.deviceName}</span> </p>
                        <div className="chatbotLiveCameraContainer">
                                <WebRTCPlayer
                                    key={props.AlertDetails.deviceId}
                                    id={props.AlertDetails.deviceId + "34"}
                                    devId={props.AlertDetails.deviceId}
                                    url={props.AlertDetails.rtspUrl}
                                    isEncode={props.AlertDetails.isTranscoded}
                                    item={props.AlertDetails}
                                    actualTime={props.AlertDetails.actualTime}
                                    alertDetail={props.AlertDetails}

                                    upperDivCss="cameraHoverMainDiv"
                                    videoCss="player-responsive1"
                                    //videoCss="chatbotliveplayer"
                                    fullVideoCss="player-responsive-fullScreen"
                                    buttonCss="cameraButtonsMainDiv"
                                    noStreamDivCss="no-stream-found"

                                    playback={false}
                                    prePostDisplay={false}
                                    liveButton={true}
                                    exportCurrentVideoHide={true}
                                    showPlaybackBtn={false}
                                    hideTwoWay={true}
                                    imageToChat={AttachalertevidencesParent}
                                />
                        </div>
                        </div>                        

                        {/* <PlayerComponent devid={props.AlertDetails.deviceId} devname={props.AlertDetails.deviceName} devurl={props.AlertDetails.hlsStreamingUrl} associated={false} /> */}
                        <hr className="solid"></hr>
                    </>
                    :
                    <></>
            }
                    <span  className="Ass_Name">Associated Devices</span>
                    <p>No devices associated</p>
                </>
            }
        </>
    }

    const ReasonCodeComponent = (buttons, otherProps, convlist, hlsStreamingUrl) => {
        return <>
            <Row className="reasonCodeHeader"><label>If you wish to close this alert, please select the reason code</label></Row>
            {
                buttons &&
                buttons.map(reasoncode => {
                    return <div>
                        {otherProps.responseTime &&
                            <Row>
                                <Col lg={9} className="reasonCode-column-text">
                                    <div id={reasoncode.btnId}
                                        className={otherProps.btnResponse == reasoncode.btnId ? "reasonCodeBtn selected-reasoncode" : "reasonCodeBtn"}
                                    >{reasoncode.title}</div>
                                </Col>
                                <Col lg={3} className="reasonCode-column-checkImg"><img className={otherProps.btnResponse == reasoncode.btnId ?
                                    "checkmark-circle-reasoncode checkmarkcircle-visible" : "checkmark-circle-reasoncode checkmarkcircle-hidden"}
                                    src={CheckmarkCircle} alt="check mark with outer Circle" /></Col>
                            </Row>
                        }

                        {!otherProps.responseTime &&
                            <Row>
                                <Col lg={9} className="reasonCode-column-text">
                                    <button id={reasoncode.btnId}
                                        onClick={() => ReasonCodeClick(reasoncode, convlist, hlsStreamingUrl)}
                                        className={otherProps.btnResponse == reasoncode.btnId ? "reasonCodeBtn selected-reasoncode" : "reasonCodeBtn"}
                                    >{reasoncode.title}</button>
                                </Col>
                                <Col lg={3} className="reasonCode-column-checkImg"><img className={otherProps.btnResponse == reasoncode.btnId ?
                                    "checkmark-circle-reasoncode checkmarkcircle-visible" : "checkmark-circle-reasoncode checkmarkcircle-hidden"}
                                    src={CheckmarkCircle} alt="check mark with outer Circle" /></Col>
                            </Row>
                        }
                    </div>
                })
            }
        </>
    }

    const ClosureCommentComponent = (mess, convlist, hlsStreamingUrl) => {
        return <>
            <Row className="reasonCodeHeader"><label>If you wish to close this alert, please select the closure comment</label></Row>
            {
                mess.data.otherProps.buttons &&
                mess.data.otherProps.buttons.map(closureComment => {
                    return <div>
                        {mess.data.otherProps.responseTime &&
                            <Row>
                                <Col lg={9}>
                                    <div id={closureComment.btnId}
                                        className={mess.data.otherProps.btnResponse == closureComment.btnId ? "reasonCodeBtn selected-reasoncode" : "reasonCodeBtn"}
                                    >{closureComment.title}</div>
                                </Col>
                                <Col lg={3} ><img className={mess.data.otherProps.btnResponse == closureComment.btnId ?
                                    "checkmark-circle-reasoncode checkmarkcircle-visible" : "checkmark-circle-reasoncode checkmarkcircle-hidden"}
                                    src={CheckmarkCircle} alt="check mark with outer Circle" /></Col>
                            </Row>
                        }

                        {!mess.data.otherProps.responseTime &&
                            <Row>
                                <Col lg={9}>
                                    <button id={closureComment.btnId}
                                        onClick={() => ClosureCommentClick(closureComment, convlist, hlsStreamingUrl, mess)}
                                        className={mess.data.otherProps.btnResponse == closureComment.btnId ? "reasonCodeBtn selected-reasoncode" : "reasonCodeBtn"}
                                    >{closureComment.title}</button>
                                </Col>
                                <Col lg={3} ><img className={mess.data.otherProps.btnResponse == closureComment.btnId ?
                                    "checkmark-circle-reasoncode checkmarkcircle-visible" : "checkmark-circle-reasoncode checkmarkcircle-hidden"}
                                    src={CheckmarkCircle} alt="check mark with outer Circle" /></Col>
                            </Row>
                        }
                    </div>
                })
            }
        </>
    }

    const ClosureCommentClick = (closureComment, convlist, hlsStreamingUrl, mess) => {
        try {
            if (closureComment) {
                setSelectedClosureComment(closureComment)
                setIsClosureCommentSelected(true)
                var lastObj = convlist[convlist.length - 1]
                if (lastObj && lastObj.data.label == "Request to select closure comment") {
                    lastObj.data.otherProps.btnResponse = closureComment.btnId
                    lastObj.data.otherProps.selectedValue = closureComment.title
                }
                ChatMessagesConversion(convlist, hlsStreamingUrl)
                if (MessageListState.length == 0) {
                    var obj = {
                        "action": "get",
                        "workflowId": "",
                        "conversationId": ConversationId,
                        "convo": []
                    }
                    conversationtemplatecrud(obj).then((resp) => {
                        if (resp.status == "Ok") {
                            if (resp.message.rasaConversation != null) {
                                MessageListState = resp.message.rasaConversation
                                BotResponseClick(closureComment, mess)
                            }
                        }
                    })
                }
                else {
                    BotResponseClick(closureComment, mess)
                }
                setIsClosureCommentSelected(false)
            }
        } catch (error) {

        }
    }
    const AlertSnapshotComponent = () => (
        <>
            <div className="chatFlowBoxContainer justify-content-start">
                <div className="chatFlowChatBoxBig float-left">
                    <p className="chatFlowChatTextBig">Alert Snapshot</p>
                </div>
            </div>
            <div className='GridBgImgChat'>
                {props.AlertDetails.snapshotURL ? <img src={props.AlertDetails.snapshotURL}
                    onClick={() => handleChange(true, props.AlertDetails.snapshotURL)}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = AlertGenericImg1;
                    }}
                    style={{ height: "13rem" }} /> : <AlertGenericImg />}
            </div>
        </>
    )

    const AlertSourceDeviceLiveComponent = () => (
        <>
            {
                (props.AlertDetails.deviceTypeID == 1)
                    ?
                    <>
                        <span>Alert Device</span>
                        {/* {PlayerComponent(props.AlertDetails.deviceId, props.AlertDetails.deviceName, props.AlertDetails.hlsStreamingUrl)} */}
                        <div className="chatbot-live-camera">
                        <p className="chatbotLiveCameraName"><span>{props.AlertDetails.deviceName}</span> </p>
                        <div className="chatbotLiveCameraContainer">
                                <WebRTCPlayer
                                    key={props.AlertDetails.deviceId}
                                    id={props.AlertDetails.deviceId + "34"}
                                    devId={props.AlertDetails.deviceId}
                                    url={props.AlertDetails.rtspUrl}
                                    isEncode={props.AlertDetails.isTranscoded}
                                    item={props.AlertDetails}
                                    actualTime={props.AlertDetails.actualTime}
                                    alertDetail={props.AlertDetails}

                                    upperDivCss="cameraHoverMainDiv"
                                    videoCss="player-responsive1"
                                    //videoCss="chatbotliveplayer"
                                    fullVideoCss="player-responsive-fullScreen"
                                    buttonCss="cameraButtonsMainDiv"
                                    noStreamDivCss="no-stream-found"

                                    playback={false}
                                    prePostDisplay={false}
                                    liveButton={true}
                                    exportCurrentVideoHide={true}
                                    showPlaybackBtn={false}
                                    hideTwoWay={true}
                                    imageToChat={AttachalertevidencesParent}
                                />
                        </div>
                        </div>                        

                        {/* <PlayerComponent devid={props.AlertDetails.deviceId} devname={props.AlertDetails.deviceName} devurl={props.AlertDetails.hlsStreamingUrl} associated={false} /> */}
                        <hr className="solid"></hr>
                    </>
                    :
                    <></>
            }
        </>

    )
    const PlayerComponent = ({devid, devname, devurl, item, associated}) => {
        console.log("AFC-LIVEURL", playUrl)
        console.log("item-item", item, devid);

        const [liveUrl, setLiveUrl] = useState(playUrl);
        const videoContainerParentRefInDiv = useRef(null);
        const newVideoRef = useRef(null);

        //const indVidCam = useRef(null);

        useEffect(() => {
            setLiveUrl(playUrl)
        }, [playUrl]);

        useEffect(() => {
            if(!associated){
                return;
            }
            let camguid;
            let pbcamguid;
            let tempbaseUrl = "http://localhost:3000"    
            if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
        {
          // let baseUrl = "http://localhost:8080"
          let baseUrl = "http://localhost:3000"
          let rtspurl = item?.rtspUrl
          //get cam guid
          let temp_date = ConvertDateTwo(new Date())
          camguid = item.streamName + temp_date;
            // camguid = item.streamName;
          pbcamguid = item.streamName;
          //do post req to base url
          console.log("Panel Live Stream API Call", rtspurl)
          if(rtspurl == null || rtspurl == undefined){
            return;
          }
          let actbaseUrl = baseUrl + "/start"
          let IsTrancoding = false
          if (item.isTranscoded == 1){
            IsTrancoding = true
          }
          console.log("Panel Live Stream API Call")
            fetch(actbaseUrl, {
                method: 'POST',
                body: JSON.stringify({
                  uri: rtspurl,
                  alias: camguid,
                  isencode: IsTrancoding
                }),
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                },
              }).then((res) => res.json())
                .then((data) => {
                console.log("Panel Live Stream API Call", data);
                  baseUrl = baseUrl + data['uri']
                  console.log("PlayUrl : ",baseUrl)
                  setLiveUrl(baseUrl)
                })
                .catch((err) => {
                  console.log("check data error", err.message);
                });
            } else {
                console.log("HLS URLS : ",props.AlertDetails.hlsStreamingUrl)
                setLiveUrl(props.AlertDetails.hlsStreamingUrl)
            }   

            return () => {
                console.log("Exiting Component", );
                let stopbaseUrl = tempbaseUrl + "/stop";
                fetch(stopbaseUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                        alias: camguid,
                    }),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    }
                })
                    .then((res) => res.json())
                    .then((data) => {
                        // console.log("Unmounted the component data",data);
                    })
                    .catch(err => {
                        console.log(err.message)
                    });
                }

        }, [item]);

        const handleCapFrameTest = () => {
            const video = newVideoRef.current.getInternalPlayer();
            const frame = captureVideoFrame(video);
            let fileUrl = frame.dataUri;
            captureVideoFrameData(fileUrl);
        }

        // useEffect(() => {
        //     document.addEventListener('fullscreenchange', exitHandler);
        //     function exitHandler() {
        //         if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        //             console.log("Plain JS screenfull close", item.associatedCameraId);
        //             // setIsMosaicFullscreenClick(false);
        //             let c = count + 1;
        //             setCount(c);
        //         } else {
        //             console.log("Plain JS screenfull start");
        //         }
        //     }
        //     return () => {
        //         document.removeEventListener('fullscreenchange', exitHandler);
        //     }
        // }, []);

        return (
        <div className="chatbot-live-camera" key={devid}>
            <p className="chatbotLiveCameraName"><span>{devname}</span> </p>
            {
                playUrl != null
                    ? 
                    // <Player 
                    //     item={item}
                    //     isMultiPlay={false}
                    //     upperDivCss="chatbotLiveCameraContainer"
                    //     lowerDivCss="mosaicButtonsMainDiv"
                    //     noStreamDivCss="no-stream-found"
                    //     type="ALERTLIVE"
                    //     popOpen={false}
                    //     setPopOpen={() => {}}
                    //     setMode={false}
                    // />
                    <div 
                    // ref={videoContainerParentRef}
                    ref={videoContainerParentRefInDiv}
                    className="chatbotLiveCameraContainer">
                    <ReactLivePlayer 
                    devid={devid} streamName={props.AlertDetails.streamName} devname={devname} playUrl={liveUrl} isSnapShotBtnClick = {isSnapShotBtnClick}
                    setIsSnapShotBtnClick={setIsSnapShotBtnClick}
                    captureVideoFrameData={captureVideoFrameData}
                    //indVidCam={indVidCam}
                    savedRef={savedRef}
                    isAlertLiveFullscreenClick={isAlertLiveFullscreenClick}
                    isFullScreenRef={isFullScreenRef}
                    item={item}
                    videoContainerParentRefInDiv={videoContainerParentRefInDiv}
                    fullScreenRef={fullScreenRef}
                    setFullScreenRef={setFullScreenRef}
                    newVideoRef={newVideoRef}
                    />
                    
                    <div className='mosaicButtonsMainDiv' style={{width: "100%"}}>
                                    
                                    <div className='mosaicbuttons'>
                                    <button 
                                    data-title='SnapShot' 
                                    className='ChatbotCameraButton' onClick={handleCapFrameTest}>
                                        <img style={{ padding: "2px", height: "16px",  }} 
                                        src={MosaicSnapshot} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        //  onClick={()=>{
                                        //     if(item == undefined){
                                        //         SnapShotBtnClick(0);
                                        //     }else{
                                        //         SnapShotBtnClick(item.associatedCameraId)
                                        //     }
                                        // }}
                                        />
                                    </button>

                                    {/* <button data-title='TwowayStart' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicTwowayStart} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button>
                                    <button data-title='TwowayStop' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicTwowayStop} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button> */}
                                    {/* <button data-title='HooterOn' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicHooterOn} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button>
                                    <button data-title='HooterOff' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicHooterOff} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button> */}
                                    {/* <button data-title='Fullscreen3' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicFullscreen} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button>
                                    <button data-title='ExitFullscreen' className='ChatbotCameraButton'>
                                        <img style={{ padding: "2px" }} 
                                        src={MosaicExitFullscreen} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                    </button> */}

                                    {
                                        isAlertLiveFullscreenClick
                                            ?
                                            <button
                                                data-title='Exit Fullscreen'
                                                className='ChatbotCameraButton'
                                                //   onClick={handleAlertLiveFullScreen}
                                                onClick={() => {
                                                    if(item == undefined){
                                                        fullScreenMode(0)
                                                    }else{
                                                        fullScreenMode(item.associatedCameraId)
                                                    }
                                                }}
                                            >
                                                <img style={{ padding: "2px" }}
                                                    src={MosaicExitFullscreen}
                                                />
                                            </button>
                                            :
                                            <button
                                                data-title='Fullscreen'
                                                className='ChatbotCameraButton'
                                                //   onClick={handleAlertLiveFullScreen}
                                                onClick={() => {
                                                    if(item == undefined){
                                                        fullScreenMode(0)
                                                    }else{
                                                        fullScreenMode(item.associatedCameraId)
                                                    }
                                                }}
                                            >
                                                <img style={{ padding: "2px" }}
                                                    src={MosaicFullscreen}
                                                />
                                            </button>
                                    }
                                    {/* <button
                          data-title='Fullscreen3'
                          className='ChatbotCameraButton'
                        //   onClick={handleAlertLiveFullScreen}
                        onClick={() => fullScreenMode(item.associatedCameraId)}
                        >
                          {
                            !isAlertLiveFullscreenClick ?
                              <img style={{ padding: "2px"}}
                                src={MosaicFullscreen}
                              />
                              :
                              <img style={{ padding: "2px"}}
                                src={MosaicExitFullscreen}
                              />
                          }
                        </button> */}
                                    </div>
                    </div>
                    </div>
                    // <ReactPlayer
                    //     key={count}
                    //     id={"video-" + devid}
                    //     itemID={"video-" + props.AlertDetails.streamName}
                    //     url={playUrl}
                    //     playing={true}
                    //     width="264px"
                    //     height="150px"
                    //     controls
                    //     autoPlay
                    //     pip={true} // for picture in picture
                    //     stopOnUnmount={false} // for picture in picture
                    //     style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px" }}
                    //     onBuffer={(e) => videoStartBuffering(e, devname)}
                    //     onBufferEnd={(e) => videoStopBuffering(e, devname)}
                    //     onError={(e) => videoErrorHandler(e, devname)}
                    //     config={{
                    //         file: {
                    //             attributes: {
                    //                 // disablePictureInPicture: isMultiPlay ? false : true, // hide and display pip
                    //                 // controlsList: isMultiPlay ? "noplaybackrate" : "noplaybackrate nodownload",
                    //                 onContextMenu: (e) => e.preventDefault(),
                    //             },
                    //             tracks: [],
                    //             forceVideo: true,
                    //             forceAudio: true,
                    //             http: {
                    //                 headers: getHeaders(),
                    //             },
                    //         },
                    //     }}
                    // />
                    :
                    <img src={MosaicNoStream} alt="mosaic" style={{ height: "150px", width: "264px", backgroundColor: '#D0D2D1', borderRadius: "14px" }} />
            }
        </div>
    )
    }

    const ClosePreview = () => {
        setisOpenmodal(false)
    }
    const handleChange = (isimg, snapurl) => {
        setisimage(isimg)
        setisOpenmodal(true)
        setsnapshoturl(snapurl)
    }

    return (
        <>
            {/* modal is for snapshot preview only start*/}
            <Modal
                show={isOpenmodal}
                dialogClassName={"activityUpdateModel"}
                onHide={ClosePreview}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton style={{ padding: "0 1rem", borderBottom: "0" }}></Modal.Header>
                <Modal.Body>
                    <div id="EvidenceViewContainer">
                        <div style={{ display: (isimage ? 'block' : 'none') }}>
                            <img
                                style={{ height: "500px", width: "465px" }}
                                src={snapshoturl}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* modal is for snapshot preview only end*/}
            {ismodalOpen &&
                <ContactStateOfficalComponent
                    Selectedcontacttitle={selectedcontacttitle}
                    Selectedcontactlist={selectedcontactlist}
                    Closemodal={setismodalOpen}
                    MakeCall={MakeCall}
                />
            }
            <div>
                {
                    !showChatFlow
                        ? <>
                        <div className="react-functional-chatbot-inner-div">
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowTimeIconContainerBig">
                                    <img className="chatFlowChatTimeIconBig" src={BotAvatarIcon} />
                                </div>
                                <p className="chatFlowChatTimeIconTextBig">{props.AlertDetails.actualTime}</p>
                            </div>
                            <div className="chatFlowBoxContainer-bot">
                                <div className="chatFlowChatBoxBig">
                                    <span className="chatFlowChatTextBig">Alert has already been acknowledged by another operator.</span>
                                </div>
                            </div>
                        </div>
                        </>
                        : null
                }
                <Reactfunctionalchatbot messages={messages} />
                <div style={{ display: (uploadfiles ? 'block' : 'none') }} className="chatFlowWebChatBox1">

                    <ChatflowEvidenceAttachment
                        scrollToBottom={props.scrollToBottom}
                        OnEvidenceAttached={OnEvidenceAttached}
                        ConversationId={ConversationId}
                        AlertID={props.AlertDetails.alertId}
                        alertDetails={props.AlertDetails}
                        capturedImage = {capturedImage}
                        setCapturedImage={setCapturedImage}
                         />
                </div>
                <div className="chatFlowWebChatBox1" style={{ display: (isReasonCodeSelected ? 'block' : 'none') }}>
                    <ChatflowReasonCode
                        selectedReasonCode={selectedReasonCode}
                        ReasonCodeSendClick={ReasonCodeSendClick}
                        setSelectedReasonCode={setSelectedReasonCode}
                    />
                    

                    {/* <div className="chatFlowBoxContainer">
                        <input id={selectedReasonCode.btnId} value={selectedReasonCode.title} readOnly className="chatFlowWebChatBoxInput" type="text"
                        // placeholder="Enter comments here"
                        />
                        <a className="cursor-ptr" style={{ marginRight: "15px" }} onClick={(e) => ReasonCodeSendClick(e)}>
                            <img src={SendIcon} height={20} width={20} />
                        </a>
                    </div> */}

                </div>
            </div>
        </>
    )


}




const EscalationContactList = (lst) => {
    return <>
        {lst.length > 0 &&
            <div className="container">
                {lst.map(item => (
                    <div><p>{"L" + item.contactLevel}{" - "}{item.shortDesignation}{" - "}{item.phoneNumber}{" - "}{item.shortContactName}</p></div>
                ))}
                {/* <Table striped bordered hover className="widgtTableMobile">
                    <thead>
                        <tr>
                            <th>Contact Level</th>
                            <th>Contact Name</th>
                            <th>Phone no</th>
                            <th>Designation</th>
                        </tr>
                    </thead>
                    <tbody className="body-half-screen">
                        {
                            lst.map(item => (
                                <tr>
                                    <td>{item.contactLevel}</td>
                                    <td>{item.contactName}</td>
                                    <td>{item.phoneNumber}</td>
                                    <td>{item.designation}</td>
                                </tr>
                            ))}
                    </tbody>
                </Table> */}
            </div>
        }
        {lst.length == 0 &&
            <>
                <h3>No Contacts for Escalation</h3>
            </>}
        {/* <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        /> */}
    </>
}

