import React, { useEffect, useImperativeHandle, useState } from 'react'
import { DeleteShape, EditShape, WFIconAlert, WFBullet, Eye, Cross, DeleteQc, Search, EditQc, Toggle, ToggleOff, ToggleOn, DropArrow, SearchIcon, Filter, BackArrowDevice, WarningDelete, SearchIconWhite, RetailStore1, RetailStore2, RetailStore2White } from '../../assets/images'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { parseTimeIntoDate } from '../../utils/utils'
import Swal from 'sweetalert2'
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';
import Scheduler from './Scheduler'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css';
import { MultiSelect } from "react-multi-select-component";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Pagination from "react-bootstrap/Pagination";
import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination'
import Modal from "react-bootstrap/Modal";
import { AlertTemplateList } from './AlertTemplateList'
import {GetDataToFilter}from '../../services/configurationServices'

import ActivityLogComponent from './ActivityLogComponent'

export default function AlertTemplateStep({
    pageState,
    selectedTemplate,
    onSubmitTemplate,
    onSubmitDeviceTemplate,
    alertCodes,
    isFullTime,
    setIsFullTime,
    fromTime,
    setFromTime,
    toTime,
    setToTime,
    severities,
    selectedSeverity,
    setSelectedSeverity,
    onSelectTemplate,
    // templateObj,
    // eventTemplates,
    onDeleteTemplate,
    workflows,
    onFinish,
    onPreviewWF,
    isAlertTemplateEdit,
    setIsAlertTemplateEdit,
    alertTemplateRef,
    filteredEventTemplates,
    onSearchChange,
    devicesmasterlist,
    devicetypeid,
    setdevicetypeid,
    setSiteList,
    siteList,
    deviceList,
    setDeviceList,
    getSiteList,
    getDeviceList,
    alertTemplateList,
    GetAlertTemplateList,
    addNewClickedAlert,
    alertSaveBtnClicked,
    alertDeleteBtnClicked,
    getAlertTemplateById,
    alertTemplateObj,
    PanelZoneType,
    setIDSPanelZoneTypeID,
    setPanelZoneType,
    totalTemplateCount,
    totalDeviceCount,
    isAlertUpdated,
    setIsAlertUpdated,
    getAlertTemplateByDeviceId,
    deviceTemplateList,
    devTemClicked,
    setDevTemClicked,
    selTempDevice,
    setSelTempDevice,
    setDeviceTemplateList,
    getCustomerData,
    customerData,
    isAlertTempFormEnabled,
    isStore,
    setDisplayButtons,
    clients,
    sites,
    GetTemplateDataToFilter,
    isChecked,
    activityLogShow,
    setActivityLogShow,
    isDarkTheme,
    setAlertTemplateObj,
    setAddNewClickedAlert,
    setIsEmptyFormAlert,
    setIsAlertTempFormEnabled,
    setIsStore,
    getZonesAT,
    zoneList,
    templateDelete
   // GetAlertTemplateList
}) {
    let history = useHistory()

    const onSelectWorkflow = (id) => {
        history.push(`/workflow/${id}`)
    }
    const [issearch, setissearch] = useState(true)
    
    function ToggeleSearch() {
        try {
            setissearch(!issearch)
            document.getElementById("Search_txt").value = ""
            onSearchChange("", "Alert Template")
        } catch (error) {
            console.error(error)
        }
    }
    const [siteSelected, setSiteSelected] = useState([]);
    const[deviceSelected,setDeviceSelected]=useState([]);
    const[masterCustomerList,setmasterCustomerList]=useState([]);
    const[masterSiteList,setmasterSiteList]=useState([]);
    const[masterDeviceTypeList,setmasterDeviceTypeList]=useState([]);
    const[masterAlertTypeList,setmasterAlertTypeList]=useState([]);
    const[filtercustomerList,setFilterCustomerList]=useState([]);
    const[filterSiteList,setFilterSiteList]=useState([]);
    const[filterDeviceTypeList,setFilterDeviceTypeList]=useState([]);
    const[filterAlertTypeList, setFilterAlertTypeList]=useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [checkOne, setCheckOne] = useState(false);
    const [checkTwo, setCheckTwo] = useState(false);
    const [customerSelectAll, setCustomerSelectAll] = useState(false);
    const [siteSelectAll, setSiteSelectAll] = useState(false);
    const [deviceTypeSelectAll, setDeviceTypeSelectAll] = useState(false);
    const [alertTypeSelectAll, setAlertTypeSelectAll] = useState(false);
    const [unselectedTempFiltered, setUnSelectedTempFiltered] = useState([]);
    const [selectedTempFiltered, setSelectedTempFiltered] = useState([]);
    const[customerSearch,setCustomerSearch]=useState("");
    const[siteSearch,setSiteSearch]=useState("");
    const[deviceTypeSearch,setDeviceTypeSearch]=useState("");
    const[alertTypeSearch,setAlertTypeSearch]=useState("")
    const[filterCount,setFilterCount]=useState(0)
    const [count, setCount] = useState(0)
    return (
        <>
            <div className="col-lg-12 mt-2" style={{height: "calc(100% - 80px)"}}>
                {
                    pageState == "Add Alert Template" &&
                    <>
                        <AlertTemplateForm
                            selectedTemplate={selectedTemplate}
                            onSubmitTemplate={onSubmitTemplate}
                            onSubmitDeviceTemplate={onSubmitDeviceTemplate}
                            alertCodes={alertCodes}
                            isFullTime={isFullTime}
                            setIsFullTime={setIsFullTime}
                            fromTime={fromTime}
                            setFromTime={setFromTime}
                            toTime={toTime}
                            setToTime={setToTime}
                            severities={severities}
                            selectedSeverity={selectedSeverity}
                            setSelectedSeverity={setSelectedSeverity}
                            isAlertTemplateEdit={isAlertTemplateEdit}
                            setIsAlertTemplateEdit={setIsAlertTemplateEdit}
                            ref={alertTemplateRef}
                            devicesmasterlist={devicesmasterlist}
                            devicetypeid={devicetypeid}
                            setdevicetypeid={setdevicetypeid}
                            filteredEventTemplates={filteredEventTemplates}
                            onSelectTemplate={onSelectTemplate}
                            onDeleteTemplate={onDeleteTemplate}
                            setSiteList={setSiteList}
                            siteList={siteList}
                            deviceList={deviceList}
                            setDeviceList={setDeviceList}
                            getSiteList={ getSiteList}
                            siteSelected={siteSelected}
                            setSiteSelected={setSiteSelected}
                            getDeviceList ={getDeviceList}
                            deviceSelected={deviceSelected}
                            setDeviceSelected={setDeviceSelected}
                            alertTemplateList={alertTemplateList}
                            alertSaveBtnClicked={alertSaveBtnClicked}
                            workflows={workflows}
                            getAlertTemplateById={getAlertTemplateById}
                            alertTemplateObj={alertTemplateObj}
                            GetAlertTemplateList={GetAlertTemplateList}
                            PanelZoneType={PanelZoneType}
                            setPanelZoneType={setPanelZoneType}
                            totalTemplateCount={totalTemplateCount}
                            totalDeviceCount={totalDeviceCount}
                            isAlertUpdated={isAlertUpdated}
                            setIsAlertUpdated={setIsAlertUpdated}
                            getAlertTemplateByDeviceId={getAlertTemplateByDeviceId}
                            deviceTemplateList={deviceTemplateList}
                            devTemClicked={devTemClicked}
                            setDevTemClicked={setDevTemClicked}
                            selTempDevice={selTempDevice}
                            setSelTempDevice={setSelTempDevice}
                            setDeviceTemplateList={setDeviceTemplateList}
                            getCustomerData={getCustomerData}
                            customerData={customerData}
                            isAlertTempFormEnabled={isAlertTempFormEnabled}
                            isStore={isStore}
                            setDisplayButtons={setDisplayButtons}
                            clients={clients}
                            sites={sites}
                            filtercustomerList={filtercustomerList}
                            setFilterCustomerList={ setFilterCustomerList}
                            filterSiteList={filterSiteList}
                            setFilterSiteList={setFilterSiteList} 
                            filterDeviceTypeList={filterDeviceTypeList}
                            setFilterDeviceTypeList={setFilterDeviceTypeList}
                            filterAlertTypeList={filterAlertTypeList}
                            setFilterAlertTypeList={setFilterAlertTypeList}
                            GetTemplateDataToFilter={GetTemplateDataToFilter}
                            selectedTempFiltered={selectedTempFiltered}
                            setSelectedTempFiltered={setSelectedTempFiltered}
                            unselectedTempFiltered={unselectedTempFiltered}

                            customerSearch={customerSearch}
                            setCustomerSearch={setCustomerSearch}
                            siteSearch={siteSearch}
                            setSiteSearch={setSiteSearch}
                            deviceTypeSearch={deviceTypeSearch}
                            setDeviceTypeSearch={setDeviceTypeSearch}
                            alertTypeSearch={alertTypeSearch}
                            setAlertTypeSearch={setAlertTypeSearch}
                            masterCustomerList={masterCustomerList}
                            setmasterCustomerList={setmasterCustomerList}
                            masterSiteList={masterSiteList}
                            setmasterSiteList={setmasterSiteList}
                            masterDeviceTypeList={masterDeviceTypeList}
                            setmasterDeviceTypeList={setmasterDeviceTypeList}
                            masterAlertTypeList={masterAlertTypeList}
                            setmasterAlertTypeList={setmasterAlertTypeList}
                            customerSelectAll={customerSelectAll}
                            setCustomerSelectAll={setCustomerSelectAll}
                            siteSelectAll={siteSelectAll}
                            setSiteSelectAll={setSiteSelectAll}
                            deviceTypeSelectAll={deviceTypeSelectAll}
                            setDeviceTypeSelectAll={setDeviceTypeSelectAll}
                            alertTypeSelectAll={alertTypeSelectAll}
                            setAlertTypeSelectAll={setAlertTypeSelectAll}
                            filterCount={filterCount}
                            setFilterCount={setFilterCount}
                            activityLogShow={activityLogShow}
                            setActivityLogShow={setActivityLogShow}
                            isDarkTheme={isDarkTheme}
                            setAlertTemplateObj={setAlertTemplateObj}
                            setAddNewClickedAlert={setAddNewClickedAlert}
                            setIsEmptyFormAlert={setIsEmptyFormAlert}
                            setIsAlertTempFormEnabled={setIsAlertTempFormEnabled}
                            setIsStore={setIsStore}
                            getZonesAT={getZonesAT}
                            zoneList={zoneList}
                            templateDelete = {templateDelete}
                            />
                    </>
                }

                {
                    pageState == "Manage Workflow" &&
                    <>

                        {/* {workflows == null && <StartWorkflow history={history} />} */}

                        {
                            // workflows &&
                            <>
                                <div className="LeftAddSide">
                                    <div className='LeftAddSideHeight'>
                                    <div className="AddUser">
                                        <h1 className="MainHeaderName">1. Add Workflow</h1>
                                        <div className="wf-list">
                                            {workflows &&
                                                workflows.map(wf => {
                                                    return <div className="wf-list-item" key={wf.workflowID} onClick={() => onSelectWorkflow(wf.workflowID)}>
                                                        <div className="col-lg-1">
                                                            <img src={WFBullet} alt="workflow bullet" />
                                                        </div>
                                                        <div className="col-lg-5 template-name">
                                                            <span>{wf.workflowName}</span>
                                                        </div>
                                                        <div className="col-lg-3">
                                                            <a style={{ display: "inline-flex" }} onClick={(e) => {
                                                                e.stopPropagation()
                                                                onPreviewWF(wf.workflowID)
                                                            }}>
                                                                <span>Preview Workflow</span>
                                                            </a>
                                                        </div>
                                                        <div className="col-lg-1">
                                                            <img className="EyeIcon" alt='view icon' src={Eye} onClick={(e) => {
                                                                e.stopPropagation()
                                                                onPreviewWF(wf.workflowID)
                                                            }} />
                                                        </div>
                                                        <div className="col-lg-1">
                                                            <img className="EyeIcon" alt='edit icon' src={EditQc} />
                                                        </div>
                                                        {/* <div className="col-lg-1">
                                                            <img className="EyeIcon" src={DeleteShape} />
                                                        </div> */}
                                                    </div>
                                                })}
                                        </div>
                                        <div className='workFlow-btn-container'>
                                            <div className="AddMore alertTemplate-addWorkFlow-addMore-btn">
                                                <a href="#" className="UserAddPlus" onClick={() => history.push('/workflow/0')}>Add More +</a>
                                            </div>
                                            <div className="ButtonDiv alertTemplate-addWorkFlow-next-btn-div">
                                                <button className="AlertViewButton  mr-3" hidden>
                                                    <p>Previous</p>
                                                </button>
                                                <button className="NextMoveButton" onClick={() => onFinish('users')}>
                                                    <span className="ViewUpdate">Next</span>
                                                </button>
                                            </div>
                                        </div>

                                    </div>
                                    </div>
                                </div>

                            </>
                        }
                    </>
                }

                {
                    pageState == "Manage Workflow" ? 
                <div className="RightListSide">
                    <div className="HeaderSiteList m-2">

                        {issearch && <p className="active-all-light active-block active-text button-common-margin">Alert Template List</p>}
                        {!issearch &&
                            <input id="Search_txt" autoFocus placeholder="Search Alert Template" className="SearchViewInput1" type="text" onChange={e => onSearchChange(e.target.value, 'Alert Template')} />
                        }

                        <div className="SearchTextDivConfigTool">
                            {issearch &&
                                <img className="EyeIcon" alt='search icon' src={SearchIcon} onClick={() => ToggeleSearch()} />}
                            {!issearch && <img className="EyeIcon" alt='cancel icon' src={Cross} onClick={() => ToggeleSearch()} />}
                        </div>
                    </div>
                    {/* {!issearch &&
                        <input id="Search_txt" className="SearchViewInput" type="search" onChange={e => onSearchChange(e.target.value, 'Alert Template')} placeholder="Search" />} */}
                    <div className="SiteListMain">
                        {
                            filteredEventTemplates && (
                                filteredEventTemplates?.length > 0 ?
                                filteredEventTemplates.map(template => {
                                    return <div className={selectedTemplate?.AlertTemplateId === template.AlertTemplateId ? "OneList SelectedList" : "OneList"} key={template.AlertTemplateId} onClick={() => onSelectTemplate(template, true)}>
                                        <p className="ListofText">{template.TemplateName}
                                            <span className="SiteNameText"> - {template.DeviceType}</span>
                                        </p>
                                        <div>
                                            <img className="m-2" src={EditShape} alt='edit icon' />
                                            <img src={DeleteQc} alt='delete icon' onClick={(e) => onDeleteTemplate(e, template.AlertTemplateId)} />
                                        </div>
                                    </div>
                                }) : <div className="no-data-found"><p>No Templates Found.</p></div>
                            )
                        }
                        {
                            !filteredEventTemplates && 
                            <div class="no-report-found"><p class="noRecordFoundText">No Templates Available.</p></div>
                        }
                    </div>
                    {/* <button className="NextMoveButton m-2 float-right" onClick={() => onSelectTemplate(templateObj)}>
                        <span className="ViewUpdate">Add New Template</span>
                    </button> */}
                </div> : ""
                }
                
                {/* <ToastContainer
                    position="bottom-left"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                /> */}
            </div>
        </>
    )
}



const AlertTemplateForm = React.forwardRef((props, ref) => {

    let {
        selectedTemplate,
        onSubmitTemplate,
        onSubmitDeviceTemplate,
        alertCodes,
        isFullTime,
        setIsFullTime,
        fromTime,
        setFromTime,
        toTime,
        setToTime,
        severities,
        selectedSeverity,
        setSelectedSeverity,
        isAlertTemplateEdit,
        setIsAlertTemplateEdit,
        devicesmasterlist,
        devicetypeid,
        setdevicetypeid,
        filteredEventTemplates,
        onSelectTemplate,
        isTemplateItemSelected,
        setSiteList,
        siteList,
        deviceList,
        setDeviceList,
        getSiteList,
        setSiteSelected,
        siteSelected,
        getDeviceList,
        deviceSelected,
        setDeviceSelected,
        alertTemplateList,
        onDeleteTemplate,
        alertSaveBtnClicked,
        workflows,
        getAlertTemplateById,
        alertTemplateObj,
        GetAlertTemplateList,
        PanelZoneType,
        setPanelZoneType,
        totalTemplateCount,
        totalDeviceCount,
        isAlertUpdated,
        setIsAlertUpdated,
        getAlertTemplateByDeviceId,
        deviceTemplateList,
        devTemClicked,
        setDevTemClicked,
        selTempDevice,
        setSelTempDevice,
        setDeviceTemplateList,
        getCustomerData,
        customerData,
        isAlertTempFormEnabled,
        isStore,
        setDisplayButtons,
        clients,
        sites,
        activityLogShow,
        setActivityLogShow,
        filtercustomerList,
        setFilterCustomerList,
        filterSiteList,
        setFilterSiteList,
        filterDeviceTypeList,
        setFilterDeviceTypeList,
        filterAlertTypeList,
        setFilterAlertTypeList,
        //GetTemplateDataToFilter,
        checkOne,
        setCheckOne,
        setSelectAll,
        selectAll,
        unselectedTempFiltered,
        setUnSelectedTempFiltered,
        selectedTempFiltered,
        setSelectedTempFiltered,
        customerSearch,
        setCustomerSearch,
        siteSearch,
        setSiteSearch,
        deviceTypeSearch,
        setDeviceTypeSearch,
        alertTypeSearch,
        setAlertTypeSearch,
        masterCustomerList,
        setmasterCustomerList,
        masterSiteList,
        setmasterSiteList,
        masterDeviceTypeList,
        setmasterDeviceTypeList,
        masterAlertTypeList,
        setmasterAlertTypeList,
        customerSelectAll, 
        setCustomerSelectAll,
        siteSelectAll, 
        setSiteSelectAll,
        deviceTypeSelectAll, 
        setDeviceTypeSelectAll,
        alertTypeSelectAll, 
        setAlertTypeSelectAll,
        isChecked,
        filterCount,
        setFilterCount,
        isDarkTheme,
        setAlertTemplateObj,
        setAddNewClickedAlert,
        setIsEmptyFormAlert,
        setIsAlertTempFormEnabled,
        setIsStore,
        getZonesAT,
        zoneList,
        templateDelete
    } = { ...props }
    const [isDisabled, setIsDisabled] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [dateCustom, setDateCustom] = useState(null);
    const [selectedSiteTab, setSelectedSiteTab] = useState("no-alert");
    const [sevenDaysData, setSevenDaysData] = useState([
        {
            "id": 0,
            "day": "mon",
            "dayResource": [],
            "isEnabled": false
        },
        {
            "id": 1,
            "day": "tue",
            "dayResource": [],
            "isEnabled": false
        },
        {
            "id": 2,
            "day": "wed",
            "dayResource": [],
            "isEnabled": false
        },
        {
            "id": 3,
            "day": "thu",
            "dayResource": [],
            "isEnabled": false
        },
        {
            "id": 4,
            "day": "fri",
            "dayResource": [],
            "isEnabled": false
        },
        {
            "id": 5,
            "day": "sat",
            "dayResource": [],
            "isEnabled": false
        },
        {
            "id": 6,
            "day": "sun",
            "dayResource": [],
            "isEnabled": false
        },
    ]);
    const [customData, setCustomData] = useState([]);
    const [templateModalFilter, setTemplateModalFilter] = useState(false);
    const [deviceModalFilter, setDeviceModalFilter] = useState(false);

    useEffect(() => {
        if(templateModalFilter && filterCount == 0){
            GetTemplateDataToFilter("all","")  
            setCustomerSelectAll(false);
            setSiteSelectAll(false);
            setDeviceTypeSelectAll(false);
            setAlertTypeSelectAll(false)
            setCustomerSearch("");
            setSiteSearch("");
            setDeviceTypeSearch("");
            setAlertTypeSearch("");
        }
    }, [templateModalFilter]);

    const GetTemplateDataToFilter=(pType,pSelectedIds)=>{
             try{
                GetDataToFilter(pType,pSelectedIds).then((resp)=>{
                    if (resp != undefined) {
                        console.log("pTypecheck", resp)
                        if(resp.Status == "success"){
                            if(pType == "customer"){
                                console.log("pTypecheck",pType)
                            }else if(pType == "site"){
                                
                            }
                            else if(pType == "alert_type") {
                          
                            }else if(pType == "device_type"){
    
                            }else{
                                customerIsChecked(resp.Data.LstClient);
                                siteIsChecked(resp.Data.LstSite);
                                alertIsChecked(resp.Data.LstAlertCode);
                                deviceTypeIsChecked(resp.Data.LstDeviceType);
                            }
                         } 
                      }
                })
            } catch (error) {
                console.error(error)
            }
        }

        const customerIsChecked = (arr) => {
            const customer = arr.map((item, index) => {
                let obj = {
                    ...item,
                    isChecked: false
                }
                return obj;
            })
            console.log("pTypecheck", customer)
            setmasterCustomerList(customer);
            setFilterCustomerList(customer);
            setCustomerCount(customer.length);
        }

        const siteIsChecked =(arr)=>{
            const site= arr.map((item, index) => {
                let obj = {
                    ...item,
                    isChecked: false
                }
                return obj;
            })
            console.log("pTypecheck", site)
            setmasterSiteList(site);
            setFilterSiteList(site);
            setSiteCount(site.length)
        }

        const alertIsChecked =(arr)=>{
            const alert_type = arr.map((item, index) => {
                let obj = {
                    ...item,
                    isChecked: false
                }
                return obj;
            })
            console.log("pTypecheck", alert_type)
            setmasterAlertTypeList(alert_type);
           setFilterAlertTypeList(alert_type); 
           setalertTypeCount(alert_type.length)
        }

        const deviceTypeIsChecked =(arr)=>{
            const device_type = arr.map((item, index) => {
                let obj = {
                    ...item,
                    isChecked: false
                }
                return obj;
            })
            console.log("pTypecheck", device_type)
            setmasterDeviceTypeList(device_type);
            setFilterDeviceTypeList(device_type);
            setDeviceTypeCount(device_type.length)
        }

    console.log("severities", severities);
    const { register, handleSubmit, setValue, getValues, watch } = useForm({ ...selectedTemplate });
    useEffect(() => {
        setSelectedSeverity(selectedTemplate?.SeverityId)
        Diplayformfields(selectedTemplate.DeviceTypeId)
        //FilterAlertType(selectedTemplate.DeviceTypeId)

        setValue('TemplateName', selectedTemplate?.TemplateName)
        setValue('AlertCodeId', selectedTemplate?.AlertCodeId)
        setValue('Description', selectedTemplate?.Description)
        setValue('SeverityId', selectedTemplate?.SeverityId)
        setValue('FullTime', selectedTemplate?.FullTime)
        setValue('DeviceTypeId', selectedTemplate?.DeviceTypeId)
        setValue('ClientId', selectedTemplate?.ClientId)
        setValue('IDSPanelZoneTypeId', selectedTemplate?.IDSPanelZoneTypeId)
        setValue('SiteId', selectedTemplate.SiteId)
        setValue('DeviceId', selectedTemplate.DeviceId)
        setValue('WorkflowId', selectedTemplate.WorkflowId)
        setIsFullTime(selectedTemplate?.FullTime)

        if(selectedTemplate?.FullTime === 1){
            setIsToggleActive(true);
        }else{
            setIsToggleActive(false);
        }

        if (selectedTemplate.FromTime != null &&
            selectedTemplate.FromTime !== "" &&
            selectedTemplate.FromTime != undefined) {
            let dt = parseTimeIntoDate(selectedTemplate.FromTime);
            setFromTime(dt);
        }
        // else { setFromTime(new Date()) }

        if (selectedTemplate.ToTime != null &&
            selectedTemplate.ToTime !== "" &&
            selectedTemplate.ToTime != undefined) {
            let dt = parseTimeIntoDate(selectedTemplate.ToTime);
            setToTime(dt);
        }
        // else { setToTime(new Date()) }

        if( isTemplateItemSelected == true){
            setIsDisabled(true)
        }
    }, [selectedTemplate])

    const [gettingData, setGettingData] = useState(false);
    
    useEffect(() => {
        console.log("alertTemplateObj", alertTemplateObj)
        setValue('TemplateName', alertTemplateObj?.templateName)   
        setValue('ClientId', alertTemplateObj?.templateClientId)
        setdevicetypeid(alertTemplateObj?.templateDeviceTypeId)
        setValue('IDSPanelZoneTypeId', alertTemplateObj?.templateDeviceSubTypeId)
        setValue('DeviceTypeId', alertTemplateObj?.templateDeviceTypeId)
        setValue('AlertCodeId', alertTemplateObj?.templateAlertType)

        if(alertTemplateObj.templateClientId != "0"){
            // getSiteList(alertTemplateObj?.templateClientId)
        }else{
            setSiteSelected([]);
        }
        if(alertTemplateObj?.templateDeviceTypeId == 3){
            setissensor(true);
        }else{
            setissensor(false);
        }
        if(alertTemplateObj.tempSelectedSites.length > 0 && alertTemplateObj.templateDeviceTypeId != "0" && alertTemplateObj.templateDeviceSubTypeId){
            console.log("Call Device from useEffect",);
            // getDeviceList(alertTemplateObj?.tempSelectedSites,alertTemplateObj?.templateDeviceTypeId, alertTemplateList.templateDeviceSubTypeId)
        }else{
            setDeviceSelected([]);
        }
        if(alertTemplateObj?.scheduleType == 1){
            setSelectedItem("No Alert")
            setSelectedSiteTab("no-alert");
        }else if(alertTemplateObj?.scheduleType == 0){
            setSelectedItem("Custom")
            setSelectedSiteTab("custom");

        }else if(alertTemplateObj?.scheduleType == "NA"){
            setSelectedItem("No Alert")
            setSelectedSiteTab("no-alert");
       
        }else{
            setSelectedItem("No Alert");
            setSelectedSiteTab("no-alert");
        }
        let scheduleObj = alertTemplateObj.schedulerObj;
        console.log("PARSED", scheduleObj)
        if(scheduleObj?.custom && scheduleObj?.sevendays){
            console.log("PARSED", scheduleObj.sevendays)
            setCustomData(scheduleObj.custom);
            if(scheduleObj.sevendays.length == 0){
                setSevenDaysData([
                    {
                        "id": 0,
                        "day": "mon",
                        "dayResource": [],
                        "isEnabled": false
                    },
                    {
                        "id": 1,
                        "day": "tue",
                        "dayResource": [],
                        "isEnabled": false
                    },
                    {
                        "id": 2,
                        "day": "wed",
                        "dayResource": [],
                        "isEnabled": false
                    },
                    {
                        "id": 3,
                        "day": "thu",
                        "dayResource": [],
                        "isEnabled": false
                    },
                    {
                        "id": 4,
                        "day": "fri",
                        "dayResource": [],
                        "isEnabled": false
                    },
                    {
                        "id": 5,
                        "day": "sat",
                        "dayResource": [],
                        "isEnabled": false
                    },
                    {
                        "id": 6,
                        "day": "sun",
                        "dayResource": [],
                        "isEnabled": false
                    },
                ])
            }else{
                setSevenDaysData(scheduleObj.sevendays)
            }
            setGettingData(true);
        }else{
            console.log("PARSED", scheduleObj)
            setCustomData([]);
            setSevenDaysData([
                {
                    "id": 0,
                    "day": "mon",
                    "dayResource": [],
                    "isEnabled": false
                },
                {
                    "id": 1,
                    "day": "tue",
                    "dayResource": [],
                    "isEnabled": false
                },
                {
                    "id": 2,
                    "day": "wed",
                    "dayResource": [],
                    "isEnabled": false
                },
                {
                    "id": 3,
                    "day": "thu",
                    "dayResource": [],
                    "isEnabled": false
                },
                {
                    "id": 4,
                    "day": "fri",
                    "dayResource": [],
                    "isEnabled": false
                },
                {
                    "id": 5,
                    "day": "sat",
                    "dayResource": [],
                    "isEnabled": false
                },
                {
                    "id": 6,
                    "day": "sun",
                    "dayResource": [],
                    "isEnabled": false
                },
            ])
            setGettingData(false);
        }
        setDateCustom(null);
    }, [alertTemplateObj]);

    console.log("watch", watch('ClientId'));

    useEffect(() => {
        console.log("siteList", siteList)
        console.log("siteList", alertTemplateObj.tempSelectedSites)
        let siteTempArr =  siteList.filter(site =>alertTemplateObj?.tempSelectedSites.includes(site.value));
        setSiteSelected(siteTempArr);
    }, [siteList, alertTemplateObj])

    useEffect(() => {
        console.log("deviceTypeArr", alertTemplateObj.tempSelectedDevices);
        console.log("deviceTypeArr", deviceList);
        let deviceTypeArr = deviceList.filter(DeviceTypeId=> alertTemplateObj?.tempSelectedDevices.includes(DeviceTypeId.value));
        console.log("deviceTypeArr", deviceTypeArr);
        setDeviceSelected(deviceTypeArr);
    }, [deviceList, alertTemplateObj])

    useEffect(() => {
        if(alertTemplateObj?.templateAlertType){
            setValue('AlertCodeId', alertTemplateObj?.templateAlertType)            
        }
    },[devicetypeid, alertTemplateObj]);

    useEffect(() => {
        if(alertTemplateObj?.templateClientId){
            setValue('ClientId', alertTemplateObj?.templateClientId)
        }
    },[customerData,alertTemplateObj]);

    useEffect(() => {
        if(alertTemplateObj?.templateDeviceSubTypeId){
            setValue('IDSPanelZoneTypeId', alertTemplateObj?.templateDeviceSubTypeId)
        }
    }, [zoneList,alertTemplateObj])

useEffect(() => {
if(templateDelete){
    GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch,filterText)
}
}, [templateDelete])


    useEffect(() => {
        if(alertSaveBtnClicked){
            console.log("getValues", getValues());
            console.log("UpdateBtnClicked", selTempDevice)
            console.log("UpdateBtnClicked", deviceTemplateList);
            if(deviceTemplateList.length > 0 && selTempDevice != null){
                //Call api related to device template updation.
                onSubmitDeviceTemplate(getValues(), siteSelected, deviceSelected, selectedItem, sevenDaysData, customData);
            }else{
                onSubmitTemplate(getValues(), siteSelected, deviceSelected, selectedItem, sevenDaysData, customData);
            }
        }
    }, [alertSaveBtnClicked]);

    useEffect(() => {
        console.log("Call Device API", watch('DeviceTypeId'), siteSelected, watch('IDSPanelZoneTypeId'))
        let showAllFlag
            if(alertTemplateObj.templateId == ""){
                showAllFlag = false;
            }else{
                showAllFlag = true;
            }
        if(siteSelected.length > 0 && watch('DeviceTypeId') == 3 && watch('AlertCodeId') != 0){
            console.log("Call Device API for sensor list", );
            getDeviceList(siteSelected,watch('DeviceTypeId'),"1", watch('AlertCodeId'), showAllFlag, alertTemplateObj.tempSelectedDevices, deviceTemplateList, isStore)
        }
        if(siteSelected.length > 0 && watch('DeviceTypeId') != 0 && watch('DeviceTypeId') != 3 && watch('AlertCodeId') != 0){
            console.log("Call Device API for device list", );
            getDeviceList(siteSelected,watch('DeviceTypeId'),"1", watch('AlertCodeId'), showAllFlag, alertTemplateObj.tempSelectedDevices, deviceTemplateList, isStore)
        }
    }, [watch('DeviceTypeId'), siteSelected, watch('AlertCodeId')])

    useEffect(() => {
        let showAllFlag
            if(alertTemplateObj.templateId == ""){
                showAllFlag = false;
            }else{
                showAllFlag = true;
            }
        if(deviceSelected.length > 0 && watch('DeviceTypeId') == 3 && watch('AlertCodeId') != 0){
            console.log("getZonesAT");
            getZonesAT(deviceSelected, showAllFlag, watch('AlertCodeId'));
        }
    }, [deviceSelected, watch('DeviceTypeId'), watch('AlertCodeId')]);

    useEffect(() => {
        console.log("UseEffect call customer api");
        if(watch('AlertCodeId') != 0 && watch('DeviceTypeId') != 0){
            console.log("UseEffect api call if block");
            let showAllFlag
            if(alertTemplateObj.templateId == ""){
                showAllFlag = false;
            }else{
                showAllFlag = true;
            }
            getCustomerData(watch('DeviceTypeId'), watch('AlertCodeId'), showAllFlag)
        }
    }, [watch('AlertCodeId'), watch('DeviceTypeId')])

    useEffect(()=>{
        if(watch('ClientId') !=0 && watch('DeviceTypeId')!= 0 && watch('AlertCodeId')!=0 && watch('ClientId') != undefined && watch('DeviceTypeId') != undefined && watch('AlertCodeId') != undefined){
            if(isStore){
                let showAllFlag;
                if(alertTemplateObj.templateId == ""){
                    showAllFlag = 0;
                }else if(alertTemplateObj.templateId != ""){
                    showAllFlag = 2;
                }else{
                    showAllFlag = 1;
                }    
            getSiteList(watch('ClientId'),watch('DeviceTypeId'),watch('AlertCodeId'), showAllFlag, isStore)
            }else{
                let showAllFlag;
                if(alertTemplateObj.templateId == ""){
                    showAllFlag = 0;
                }else{
                    showAllFlag = 1;
                }    
            getSiteList(watch('ClientId'),watch('DeviceTypeId'),watch('AlertCodeId'), showAllFlag, isStore)
            }
        }
    }, [watch('DeviceTypeId'),watch('ClientId'),watch('AlertCodeId'), isStore])
    //#region alert template edit cancel impl
    function isDataChanged() {
        return getValues('TemplateName') != selectedTemplate?.TemplateName ||
            getValues('AlertCodeId') != selectedTemplate?.AlertCodeId ||
            getValues('Description') != selectedTemplate?.Description ||
            getValues('SeverityId') != selectedTemplate?.SeverityId ||
            getValues('FullTime') != selectedTemplate?.FullTime ||
            getValues('DeviceTypeId') != selectedTemplate?.DeviceTypeId ||
            getValues('ClientId') != selectedTemplate?.ClientId ||
            getValues('IDSPanelZoneTypeId') != selectedTemplate?.IDSPanelZoneTypeId ||
            getValues('SiteId') != selectedTemplate?.SiteId ||
            getValues('DeviceId') != selectedTemplate?.DeviceId ||
            getValues('WorkflowId') != selectedTemplate?.WorkflowId
           // getValues('isAlertFlag') !=selectedItem?.isAlertFlag
    }

    // useEffect(() => {
    //     console.log("isStore", isStore)
    //     if(isStore){
    //         console.log("isStore", isStore)
    //         console.log("isStore", siteList)
    //         let arr = siteList.map((item) => ({
    //             value: item.value,
    //             label: item.label,
    //             disabled: true
    //         }))
    //         console.log("isStore", arr);
    //         setSiteList(arr);
    //     }
    // }, [isStore])

    useImperativeHandle(ref, () => ({
        isDataChanged() {
            return getValues('TemplateName') != selectedTemplate?.TemplateName ||
                getValues('AlertCodeId') != selectedTemplate?.AlertCodeId ||
                getValues('Description') != selectedTemplate?.Description ||
                getValues('SeverityId') != selectedTemplate?.SeverityId ||
                getValues('FullTime') != selectedTemplate?.FullTime ||
                getValues('DeviceTypeId') != selectedTemplate?.DeviceTypeId ||
                getValues('ClientId') != selectedTemplate?.ClientId ||
                getValues('IDSPanelZoneTypeId') != selectedTemplate?.IDSPanelZoneTypeId ||
                getValues('SiteId') != selectedTemplate?.SiteId ||
                getValues('DeviceId') != selectedTemplate?.DeviceId ||
                getValues('WorkflowId') != selectedTemplate?.WorkflowId
                //getValues('isAlertFlag') !=selectedItem?.isAlertFlag
    
        }
    }))

    const onCancel = (e) => {
        e.stopPropagation()
        if (isDataChanged()) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to cancel the changes made?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#027aaa',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Leave!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setValue('TemplateName', selectedTemplate?.TemplateName)
                    setValue('AlertCodeId', selectedTemplate?.AlertCodeId)
                    setValue('Description', selectedTemplate?.Description)
                    setValue('SeverityId', selectedTemplate?.SeverityId)
                    setValue('FullTime', selectedTemplate?.FullTime)
                    setValue('DeviceTypeId', selectedTemplate?.DeviceTypeId)
                    setValue('ClientId', selectedTemplate?.ClientId)
                    setValue('IDSPanelZoneTypeId', selectedTemplate?.IDSPanelZoneTypeId)
                    setValue('SiteId', selectedTemplate.SiteId)
                    setValue('DeviceId', selectedTemplate.DeviceId)
                    setValue('WorkflowId', selectedTemplate.WorkflowId)
                    //setValue('isAlertFlag',selectedItem?.isAlertFlag)
                    
                    setIsFullTime(selectedTemplate?.FullTime)

                    setIsToggleActive(selectedTemplate?.FullTime);
                    if (selectedTemplate.FromTime != null &&
                        selectedTemplate.FromTime !== "" &&
                        selectedTemplate.FromTime != undefined) {
                        let dt = parseTimeIntoDate(selectedTemplate.FromTime);
                        setFromTime(dt);
                    } else { setFromTime(new Date()) }

                    if (selectedTemplate.ToTime != null &&
                        selectedTemplate.ToTime !== "" &&
                        selectedTemplate.ToTime != undefined) {
                        let dt = parseTimeIntoDate(selectedTemplate.ToTime);
                        setToTime(dt);
                    } else { setToTime(new Date()) }
                    setSelectedSeverity(selectedTemplate?.SeverityID)
                    setIsAlertTemplateEdit(false)
                }
            })
        } else {
            setIsAlertTemplateEdit(false)
            let dt = parseTimeIntoDate(selectedTemplate.FromTime);
            let tdt = parseTimeIntoDate(selectedTemplate.ToTime);
            setFromTime(dt);
            setToTime(tdt);
        }
    }
    //#endregion

    const [isalerttype, setisalerttype] = useState(false)
    const [isclient, setisclient] = useState(false)
    const [issensor, setissensor] = useState(false)
    const OnDeviceTypeChanges = (e) => {
        try {
            setValue('DeviceTypeId', e.target.value)
            Diplayformfields(e.target.value)
            setdevicetypeid(e.target.value)
            setValue('AlertCodeId', 0)
            setValue('ClientId', "0")
            setValue('IDSPanelZoneTypeId', "0")
            setSiteSelected([]);
            setDeviceSelected([]);
        } catch (error) {
            console.error(error)
        }
    }

    const [alerttype, setalerttype] = useState([])
    const Diplayformfields = (id) => {
        try {
            if (!devicesmasterlist) return;
            var _type = devicesmasterlist.deviceTypeList.find(x => x.deviceTypeID == id)
            if (_type) {
                if (_type.deviceType == "Camera Device") {
                    setisalerttype(true)
                    setisclient(false)
                    setissensor(false)
                } else if (_type.deviceType == "IDS Panel") {
                    setisalerttype(true)
                    setisclient(true)
                    setissensor(false)
                } else if (_type.deviceType == "Sensor") {
                    setisalerttype(false)
                    setisclient(true)
                    setissensor(true)
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const FilterAlertType = (id) => {
        try {
            if (!devicesmasterlist) return;
            if (devicesmasterlist.alertCodeList) {
                if (devicesmasterlist.alertCodeList.length > 0) {
                    var alerttypelist = devicesmasterlist.alertCodeList.filter(x => x.deviceTypeId == id)
                    if (alerttypelist) { setalerttype(alerttypelist) }
                    else { setalerttype(null) }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    const [isToggleActive, setIsToggleActive] = useState(false);
    const [nextClickOne, prevClickOne, pageCounterOne, currentPageNoOne, limitOne, totalPagesOne, setCurrentPageNoOne, setTotalPagesOne, maxPageAllowedOne, setPageCounterOne] = useDataAccessPagination(10, 1, 10, 1);
    const [nextClickTwo, prevClickTwo, pageCounterTwo, currentPageNoTwo, limitTwo, totalPagesTwo, setCurrentPageNoTwo, setTotalPagesTwo, maxPageAllowedTwo, setPageCounterTwo] = useDataAccessPagination(10, 1, 10, 1);

    useEffect(() => {
        let cOne = currentPageNoOne;
        setCurrentPageNoOne(cOne);
        setPageCounterOne(cOne);
        setTotalPagesOne(parseInt(totalTemplateCount));
    }, [totalTemplateCount]);

    useEffect(() => {
        let cTwo = currentPageNoTwo;
        setCurrentPageNoTwo(cTwo);
        setPageCounterTwo(cTwo);
        setTotalPagesTwo(parseInt(totalDeviceCount))
    }, [totalDeviceCount]);

    useEffect(() => {
        if(activeTab == "Template"){
            GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch,filterText)
        }
    }, [currentPageNoOne]);

    useEffect(() => {
        if(activeTab == "Device"){
            GetAlertTemplateList("site_list", currentPageNoTwo, deviceSearch,filterText);        
        }
    }, [currentPageNoTwo])

    useEffect(() => {
        if (isAlertUpdated) {
            if (activeTab == "Template") {
                GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch, filterText)
            }
            setIsAlertUpdated(false);
        }
    }, [isAlertUpdated])

    const ToggleAlertFullTime = (toggleStatus) => {

        if (toggleStatus == "on"){
            setIsToggleActive(false)
            setIsFullTime(false)
            setValue('FullTime',0)
        }
        else {
            setIsToggleActive(true)
            //setIsFullTime(selectedTemplate?.FullTime)
            setIsFullTime(true)
            setFromTime(parseTimeIntoDate("00:00"))
            setToTime(parseTimeIntoDate("00:00"))
            setValue('FullTime',1)
        }
    }
    const onSpecialCharacter = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isValid = new RegExp("^[A-Za-z0-9.\\-_ ]*$").test(keyValue);
        if (!isValid) {
          event.preventDefault();
          return;
        }
      }
     
    //   useEffect(()=>{
    //     if(isChecked){
    //         GetDataToFilter("customer",)

    //     }
    //   })
        
   
//For Filtercustomer
    //   useEffect(() => {
    //     let arr = filtercustomerList.filter((item) => item.isChecked).map((i) => i.Item1);
    //     console.log("filtercustomerList", arr);
    //     let clientIds = arr.join(',');
    //     console.log("filtercustomerList", clientIds);
    //     if(arr.length != 0){
    //         GetDataToFilter("customer",clientIds).then((resp)=>{
    //             if(resp.Status == "success"){
    //                 siteIsChecked(resp.Data.LstSite);
    //                 alertIsChecked(resp.Data.LstAlertCode);
    //                 deviceTypeIsChecked(resp.Data.LstDeviceType);  
    //             }
    //             console.log("filtercustomerList", resp);
    //         })
    //     }
    //   }, [filtercustomerList])

    const handleCustomerSelectAll = () => {
        const tempArr = filtercustomerList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: !customerSelectAll
            }
            return obj;
        })

        let customerOne = masterCustomerList.map((item) => {
            let objcustomer;
            if (customerSelectAll) {
                if (!item.Item2.toLowerCase().includes(customerSearch.toLowerCase())) {
                    objcustomer = {
                        ...item
                    }
                } else {
                    objcustomer = {
                        ...item,
                        isChecked: false
                    }
                }
            } else {
                if (!item.Item2.toLowerCase().includes(customerSearch.toLowerCase())) {
                    objcustomer = {
                        ...item,
                    }
                } else {
                    objcustomer = {
                        ...item,
                        isChecked: true
                    }
                }
            }
            return objcustomer;
        })

        setCustomerSelectAll(!customerSelectAll);
        setFilterCustomerList(tempArr);
        setmasterCustomerList(customerOne);


        let customerArr = customerOne.filter((item) => item.isChecked).map((i) => i.Item1);
        console.log("filtercustomerList", customerArr);
        let clientIds = customerArr.join(',');
        console.log("filtercustomerList", clientIds);
        if (customerArr.length != 0) {
            GetDataToFilter("customer", clientIds).then((resp) => {
                if (resp.Status == "success") {
                    siteIsChecked(resp.Data.LstSite);
                    setSiteSelectAll(false);
                    deviceTypeIsChecked(resp.Data.LstDeviceType);
                    setDeviceTypeSelectAll(false);
                    alertIsChecked(resp.Data.LstAlertCode);
                    setAlertTypeSelectAll(false)
                }
                console.log("filtercustomerList", resp);
            })
        }

    };

    const customerCheckHandler = (e, id) => {
        let arr = filtercustomerList.map((item) => {
            let obj;
            if (item.Item1 == id) {
                obj = {
                    ...item,
                    isChecked: !item.isChecked
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })


        let masterCustomer = masterCustomerList.map((item) => {
            let obj;
            if (item.Item1 == id) {
                obj = {
                    ...item,
                    isChecked: !item.isChecked
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })

        let customerArr = arr.filter((item) => item.isChecked).map((i) => i.Item1);
        console.log("filtercustomerList", customerArr);
        let clientIds = customerArr.join(',');
        console.log("filtercustomerList", clientIds);
        if (customerArr.length != 0) {
            GetDataToFilter("customer", clientIds).then((resp) => {
                if (resp.Status == "success") {
                    siteIsChecked(resp.Data.LstSite);
                    setSiteSelectAll(false);
                    deviceTypeIsChecked(resp.Data.LstDeviceType);
                    setDeviceTypeSelectAll(false);
                    alertIsChecked(resp.Data.LstAlertCode);
                    setAlertTypeSelectAll(false)
                }
                console.log("filtercustomerList", resp);
            })
        } else {
            GetDataToFilter("all", "").then((resp) => {
                if (resp.Status == "success") {
                    siteIsChecked(resp.Data.LstSite);
                    setSiteSelectAll(false);
                    deviceTypeIsChecked(resp.Data.LstDeviceType);
                    setDeviceTypeSelectAll(false);
                    alertIsChecked(resp.Data.LstAlertCode);
                    setAlertTypeSelectAll(false)
                }
                console.log("filtercustomerList", resp);
            })
        }

        setmasterCustomerList(masterCustomer);
        setFilterCustomerList(arr);

    } 

    //------------For FilterSite----------
    // useEffect(() => {
    //     let arr = filterSiteList.filter((item) => item.isChecked).map((i) => i.Item1);
    //     console.log("filterSiteList", arr);
    //     let siteIds = arr.join(',');
    //     console.log("filterSiteList",  siteIds);
    //     if(arr.length != 0){
    //         GetDataToFilter("site", siteIds).then((resp)=>{
    //             if(resp.Status == "success"){
    //                 deviceTypeIsChecked(resp.Data.LstDeviceType);
    //                 setDeviceTypeSelectAll(false);
    //                 alertIsChecked(resp.Data.LstAlertCode);
    //                 setAlertTypeSelectAll(false);
    //             }
    //             console.log("filterSiteList", resp);
    //         })
    //     }
    //   }, [filterSiteList])
    const handleSiteSelectAll = () => {

        const tempArr = filterSiteList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: !siteSelectAll
            }
            return obj;
        })
        let siteOne = masterSiteList.map((item) => {
            let objsite;
            if (siteSelectAll) {
                if (!item.Item2.toLowerCase().includes(siteSearch.toLowerCase())) {
                    objsite = {
                        ...item
                    }
                } else {
                    objsite = {
                        ...item,
                        isChecked: false
                    }
                }
            } else {
                if (!item.Item2.toLowerCase().includes(siteSearch.toLowerCase())) {
                    objsite = {
                        ...item
                    }
                } else {
                    objsite = {
                        ...item,
                        isChecked: true
                    }
                }
            }
            return objsite;
        })
        setSiteSelectAll(!siteSelectAll);
        setFilterSiteList(tempArr);
        setmasterSiteList(siteOne);

        let siteArr = siteOne.filter((item) => item.isChecked).map((i) => i.Item1);
        console.log("filterSiteList", siteArr);
        let siteIds = siteArr.join(',');
        console.log("filterSiteList", siteIds);
        if (siteArr.length != 0) {
            GetDataToFilter("site", siteIds).then((resp) => {
                if (resp.Status == "success") {
                    deviceTypeIsChecked(resp.Data.LstDeviceType);
                    setDeviceTypeSelectAll(false);
                    alertIsChecked(resp.Data.LstAlertCode);
                    setAlertTypeSelectAll(false);
                }
                console.log("filterSiteList", resp);
            })
        }
    };
    const siteCheckHandler = (e, id) => {
        let arr = filterSiteList.map((item) => {
            let obj;
            if (item.Item1 == id) {
                obj = {
                    ...item,
                    isChecked: !item.isChecked
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })
        let masterSite = masterSiteList.map((item) => {
            let obj;
            if (item.Item1 == id) {
                obj = {
                    ...item,
                    isChecked: !item.isChecked
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })

        let siteArr = arr.filter((item) => item.isChecked).map((i) => i.Item1);
        console.log("filterSiteList", siteArr);
        let siteIds = siteArr.join(',');
        console.log("filterSiteList", siteIds);
        if (siteArr.length != 0) {
            GetDataToFilter("site", siteIds).then((resp) => {
                if (resp.Status == "success") {
                    deviceTypeIsChecked(resp.Data.LstDeviceType);
                    setDeviceTypeSelectAll(false);
                    alertIsChecked(resp.Data.LstAlertCode);
                    setAlertTypeSelectAll(false);
                }
                console.log("filterSiteList", resp);
            })
        } else {
            let customerArr = masterCustomerList.filter((item) => item.isChecked).map((i) => i.Item1);
            console.log("filtercustomerList", customerArr);
            let clientIds = customerArr.join(',');
            console.log("filtercustomerList", clientIds);
            if (customerArr.length != 0) {
                GetDataToFilter("customer", clientIds).then((resp) => {
                    if (resp.Status == "success") {
                        // siteIsChecked(resp.Data.LstSite);
                        // setSiteSelectAll(false);
                        deviceTypeIsChecked(resp.Data.LstDeviceType);
                        setDeviceTypeSelectAll(false);
                        alertIsChecked(resp.Data.LstAlertCode);
                        setAlertTypeSelectAll(false)
                    }
                    console.log("filtercustomerList", resp);
                })
            } else {
                GetDataToFilter("all", "").then((resp) => {
                    if (resp.Status == "success") {
                        siteIsChecked(resp.Data.LstSite);
                        setSiteSelectAll(false);
                        deviceTypeIsChecked(resp.Data.LstDeviceType);
                        setDeviceTypeSelectAll(false);
                        alertIsChecked(resp.Data.LstAlertCode);
                        setAlertTypeSelectAll(false)
                    }
                    console.log("filtercustomerList", resp);
                })
            }
            // GetDataToFilter("site", siteIds).then((resp)=>{
            //     if(resp.Status == "success"){
            //         deviceTypeIsChecked(resp.Data.LstDeviceType);  
            //         setDeviceTypeSelectAll(false);
            //         alertIsChecked(resp.Data.LstAlertCode);
            //         setAlertTypeSelectAll(false);
            //     }
            //     console.log("filterSiteList", resp);
            // })
        }
        setmasterSiteList(masterSite);
        setFilterSiteList(arr);
    }
    //---------For FilterDeviceType------------
    // useEffect(() => {
    // let arr = filterDeviceTypeList.filter((item) => item.isChecked).map((i) => i.Item1);
    // console.log("filterDeviceTypeList", arr);
    // let deviceTypeIds = arr.join(',');
    // console.log("filterDeviceTypeList", deviceTypeIds);
    // if(arr.length != 0){
    //     GetDataToFilter("device_type",deviceTypeIds).then((resp)=>{
    //         if(resp.Status == "success"){
    //              alertIsChecked(resp.Data.LstAlertCode);
    //              setAlertTypeSelectAll(false);
    //         }
    //         console.log("filterDeviceTypeList", resp);
    //     })
    // }
    //   }, [filterDeviceTypeList])

    const handleDeviceTypeSelectAll = () => {

        const tempArr = filterDeviceTypeList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: !deviceTypeSelectAll
            }
            return obj;
        })
        let deviceTypeOne = masterDeviceTypeList.map((item) => {
            let objdeviceType;
            if (deviceTypeSelectAll) {
                if (!item.Item2.toLowerCase().includes(deviceTypeSearch.toLowerCase())) {
                    objdeviceType = {
                        ...item
                    }
                } else {
                    objdeviceType = {
                        ...item,
                        isChecked: false
                    }
                }
            } else {
                if (!item.Item2.toLowerCase().includes(deviceTypeSearch.toLowerCase())) {
                    objdeviceType = {
                        ...item,
                    }
                } else {
                    objdeviceType = {
                        ...item,
                        isChecked: true
                    }
                }
            }
            return objdeviceType;
        })
        setDeviceTypeSelectAll(!deviceTypeSelectAll);
        setFilterDeviceTypeList(tempArr);
        setmasterDeviceTypeList(deviceTypeOne);

        let deviceTypeArr = deviceTypeOne.filter((item) => item.isChecked).map((i) => i.Item1);
        console.log("filterDeviceTypeList", deviceTypeArr);
        let deviceTypeIds = deviceTypeArr.join(',');
        console.log("filterDeviceTypeList", deviceTypeIds);
        if (deviceTypeArr.length != 0) {
            GetDataToFilter("device_type", deviceTypeIds).then((resp) => {
                if (resp.Status == "success") {
                    alertIsChecked(resp.Data.LstAlertCode);
                    setAlertTypeSelectAll(false);
                }
                console.log("filterDeviceTypeList", resp);
            })
        }
    };
    const deviceTypeCheckHandler = (e, id) => {
        let arr = filterDeviceTypeList.map((item) => {
            let obj;
            if (item.Item1 == id) {
                obj = {
                    ...item,
                    isChecked: !item.isChecked
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })
        let masterDeviceType = masterDeviceTypeList.map((item) => {
            let obj;
            if (item.Item1 == id) {
                obj = {
                    ...item,
                    isChecked: !item.isChecked
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })
        let deviceTypeArr = arr.filter((item) => item.isChecked).map((i) => i.Item1);
        console.log("filterDeviceTypeList", deviceTypeArr);
        let deviceTypeIds = deviceTypeArr.join(',');
        console.log("filterDeviceTypeList", deviceTypeIds);
        if (deviceTypeArr.length != 0) {
            GetDataToFilter("device_type", deviceTypeIds).then((resp) => {
                if (resp.Status == "success") {
                    alertIsChecked(resp.Data.LstAlertCode);
                    setAlertTypeSelectAll(false);
                }
                console.log("filterDeviceTypeList", resp);
            })
        } else {
            let siteArr = masterSiteList.filter((item) => item.isChecked).map((i) => i.Item1);
            console.log("filterSiteList", siteArr);
            let siteIds = siteArr.join(',');
            console.log("filterSiteList", siteIds);
            if (siteArr.length != 0) {
                GetDataToFilter("site", siteIds).then((resp) => {
                    if (resp.Status == "success") {
                        deviceTypeIsChecked(resp.Data.LstDeviceType);
                        setDeviceTypeSelectAll(false);
                        alertIsChecked(resp.Data.LstAlertCode);
                        setAlertTypeSelectAll(false);
                    }
                    console.log("filterSiteList", resp);
                })
            } else {
                let customerArr = masterCustomerList.filter((item) => item.isChecked).map((i) => i.Item1);
                console.log("filtercustomerList", customerArr);
                let clientIds = customerArr.join(',');
                console.log("filtercustomerList", clientIds);
                if (customerArr.length != 0) {
                    GetDataToFilter("customer", clientIds).then((resp) => {
                        if (resp.Status == "success") {
                            // siteIsChecked(resp.Data.LstSite);
                            // setSiteSelectAll(false);
                            deviceTypeIsChecked(resp.Data.LstDeviceType);
                            setDeviceTypeSelectAll(false);
                            alertIsChecked(resp.Data.LstAlertCode);
                            setAlertTypeSelectAll(false)
                        }
                        console.log("filtercustomerList", resp);
                    })
                } else {
                    GetDataToFilter("all", "").then((resp) => {
                        if (resp.Status == "success") {
                            siteIsChecked(resp.Data.LstSite);
                            setSiteSelectAll(false);
                            deviceTypeIsChecked(resp.Data.LstDeviceType);
                            setDeviceTypeSelectAll(false);
                            alertIsChecked(resp.Data.LstAlertCode);
                            setAlertTypeSelectAll(false)
                        }
                        console.log("filtercustomerList", resp);
                    })
                }
            }

        }
        setmasterDeviceTypeList(masterDeviceType);
        setFilterDeviceTypeList(arr);
    }

    //--------For FilterAlertType----------
    // useEffect(() => {
    //     let arr = filterAlertTypeList.filter((item) => item.isChecked).map((i) => i.Item1);
    //     console.log("filterAlertTypeList", arr);
    //     let alertTypeIds = arr.join(',');
    //     console.log("filterAlertTypeList", alertTypeIds);
    //     // if(arr.length != 0){
    //     //     GetDataToFilter("alert_type",alertTypeIds).then((resp)=>{
    //     //         console.log("filterAlertTypeList", resp);
    //     //     })
    //     // }
    //   }, [filterAlertTypeList])

    const handleAlertTypeSelectAll = () => {

        const tempArr = filterAlertTypeList.map((item, index) => {
            let obj = {
                ...item,
                isChecked: !alertTypeSelectAll
            }
            return obj;
        })
        let alertTypeOne = masterAlertTypeList.map((item) => {
            let objalertType;
            if (alertTypeSelectAll) {
                if (!item.Item2.toLowerCase().includes(alertTypeSearch.toLowerCase())) {
                    objalertType = {
                        ...item
                    }
                } else {
                    objalertType = {
                        ...item,
                        isChecked: false
                    }
                }

            } else {
                if (!item.Item2.toLowerCase().includes(alertTypeSearch.toLowerCase())) {
                    objalertType = {
                        ...item,
                    }
                } else {
                    objalertType = {
                        ...item,
                        isChecked: true
                    }
                }
            }
            return objalertType;
        })
        setAlertTypeSelectAll(!alertTypeSelectAll);
        setFilterAlertTypeList(tempArr);
        setmasterAlertTypeList(alertTypeOne)
    };
    const alertTypeCheckHandler = (e, id) => {
        let arr = filterAlertTypeList.map((item) => {
            let obj;
            if (item.Item1 == id) {
                obj = {
                    ...item,
                    isChecked: !item.isChecked
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })
        let masterAlertType = masterAlertTypeList.map((item) => {
            let obj;
            if (item.Item1 == id) {
                obj = {
                    ...item,
                    isChecked: !item.isChecked
                }
            } else {
                obj = {
                    ...item
                }
            }
            return obj;
        })
        setmasterAlertTypeList(masterAlertType);
        setFilterAlertTypeList(arr);
    }
    


    const searchFilterHandler = (e,type)=>{
        let searchText = e.target.value;
        if(type == "Customer"){
            setCustomerSearch(searchText);
            let arr = masterCustomerList.filter((item)=> item.Item2.toLowerCase().includes(searchText.toLowerCase()));
            let isChecked = true;
            for(let i=0; i<arr.length; i++){
                if(arr[i].isChecked == true){
                    continue;
                }else{
                    isChecked = false;
                    break;
                }
            }
            setCustomerSelectAll(isChecked);
            setFilterCustomerList(arr);
            setCustomerCount(arr.length);
        }else if(type == "Site"){
            setSiteSearch(searchText);
            let arr = masterSiteList.filter((item)=> item.Item2.toLowerCase().includes(searchText.toLowerCase()));
            let isChecked = true;
            for(let i=0; i<arr.length; i++){
                if(arr[i].isChecked == true){
                    continue;
                }else{
                    isChecked = false;
                    break;
                }
            }
            setSiteSelectAll(isChecked);
            setFilterSiteList(arr);
            setSiteCount(arr.length);
        }else if(type == "Device Type"){
            setDeviceTypeSearch(searchText )
            let arr = masterDeviceTypeList.filter((item)=> item.Item2.toLowerCase().includes(searchText.toLowerCase()));
            let isChecked = true;
            for(let i=0; i<arr.length; i++){
                if(arr[i].isChecked == true){
                    continue;
                }else{
                    isChecked = false;
                    break;
                }
            }
            setDeviceTypeSelectAll(isChecked);
            setFilterDeviceTypeList(arr);
            setDeviceTypeCount(arr.length);
        }else if (type == "Alert Type"){
            setAlertTypeSearch(searchText)
            let arr = masterAlertTypeList.filter((item)=> item.Item2.toLowerCase().includes(searchText.toLowerCase()));
            let isChecked = true;
            for(let i=0; i<arr.length; i++){
                if(arr[i].isChecked == true){
                    continue;
                }else{
                    isChecked = false;
                    break;
                }
            }
            setAlertTypeSelectAll(isChecked);
            setFilterAlertTypeList(arr);
            setalertTypeCount(arr.length)
        }
    }

const filterClearAll = ()=>{
    GetTemplateDataToFilter("all","")  
    setCustomerSearch("")
    setSiteSearch("")
    setDeviceTypeSearch("")
    setAlertTypeSearch("")
    setCustomerSelectAll(false);
    setSiteSelectAll(false);
    setDeviceTypeSelectAll(false);
    setAlertTypeSelectAll(false);
    setfilterText("");
    const tempcustomer = masterCustomerList.map((item, index) => {
        let obj = {
            ...item,
            isChecked:false
        }
        return obj;
    })
    setmasterCustomerList(tempcustomer);
    setFilterCustomerList(tempcustomer);

    const tempsite = masterSiteList.map((item, index) => {
        let obj = {
            ...item,
            isChecked:false
        }
        return obj;
    })
    setmasterSiteList(tempsite);
    setFilterSiteList(tempsite);

    const tempdevicetype = masterDeviceTypeList.map((item, index) => {
        let obj = {
            ...item,
            isChecked:false
        }
        return obj;
    })
    setmasterDeviceTypeList(tempdevicetype)
    setFilterDeviceTypeList(tempdevicetype);

    const tempalerttype = masterAlertTypeList.map((item, index) => {
        let obj = {
            ...item,
            isChecked:false
        }
        return obj;
    })
    setmasterAlertTypeList(tempalerttype)
    setFilterAlertTypeList(tempalerttype);
    setFilterCount(0)
    if (activeTab == "Template") {
        GetAlertTemplateList("alert_template_list", 1, alertSearch, "")
    }
    else {
        GetAlertTemplateList("site_list", 1, deviceSearch, "");
    }

}


const applyAll = () => {

    let count = 0;
    let isCustomerChecked = masterCustomerList.some((item) => item.isChecked);
    let isSiteChecked = masterSiteList.some((item) => item.isChecked);
    let isDeviceTypeChecked = masterDeviceTypeList.some((item) => item.isChecked);
    let isAlertTypeChecked = masterAlertTypeList.some((item) => item.isChecked);
    if(isCustomerChecked){
        count++;
    }
    if(isSiteChecked){
        count++;
    }
    if(isDeviceTypeChecked){
        count++;
    }
    if(isAlertTypeChecked){
        count++;
    }
    console.log("checkFiltertextstring", count);
    setFilterCount(count);

    let customer = masterCustomerList.filter((item) => item.isChecked).map((i) => i.Item1);
    console.log("masterCustomerList", customer);
    let clientIds = customer.join(',');
    console.log("masterCustomerList", clientIds);

    let site = masterSiteList.filter((item) => item.isChecked).map((i) => i.Item1);
    console.log("masterSiteList", site );
    let siteIds = site.join(',');
    console.log("masterSiteList",  siteIds);

    let deviceType = masterDeviceTypeList.filter((item) => item.isChecked).map((i) => i.Item1);
    console.log("masterDeviceTypeList", deviceType);
    let deviceTypeIds = deviceType.join(',');
    console.log("masterDeviceTypeList", deviceTypeIds);

    let alertType = masterAlertTypeList.filter((item) => item.isChecked).map((i) => i.Item1);
    console.log("masterAlertTypeList", alertType);
    let alertTypeIds =alertType.join(',');
    console.log("masterAlertTypeList", alertTypeIds);

    const fileterTextstring = `customer:${clientIds};site:${siteIds};device_type:${deviceTypeIds};alert_type:${alertTypeIds}`;
    console.log("checkFiltertextstring", fileterTextstring)
    setfilterText(fileterTextstring);
    if (activeTab == "Template") {
        GetAlertTemplateList("alert_template_list", 1, alertSearch, fileterTextstring)
    }
    else {
        GetAlertTemplateList("site_list", 1, deviceSearch, fileterTextstring);
    }

    setTemplateModalFilter(false)
}

    // return <div className="LeftAddSide">
    //     <div className='LeftAddSideHeight'>
    //     <div className="AddSite alertTemplate-addUpdate-container">
    //         {selectedTemplate.AlertTemplateId == 0 && <h1 className="MainHeaderName">1. Add Template</h1>}
    //         {selectedTemplate.AlertTemplateId != 0 && <h1 className="MainHeaderName">1. Update Template</h1>}
    //         <form className="m-2 alert-template-form-container" onSubmit={handleSubmit((e) => onSubmitTemplate(e))} >
    //             <div className='alert-template-form'>
    //                 <div className="SiteTextBox">
    //                     {/* <p className="SiteNameText">Name <span style={{ color: "red" }}> *</span></p> */}
    //                     <input
    //                         placeholder='Name*'
    //                         {...register("TemplateName")}
    //                         className="SiteNameTextBox"
    //                         defaultValue={selectedTemplate.TemplateName}
    //                         disabled={!isAlertTemplateEdit} />
    //                 </div>
    //                 <div className="SiteTextBox">
    //                     {/* <p className="SiteNameText">Device Type <span style={{ color: "red" }}> *</span></p> */}
    //                     <select
    //                         {...register("DeviceTypeId")}
    //                         className="SiteNameTextBox p-2"
    //                         defaultValue={selectedTemplate.DeviceTypeId}
    //                         onChange={e => OnDeviceTypeChanges(e)}
    //                         disabled={!isAlertTemplateEdit}>
    //                         <option key="0" value="0" >Device Type*</option>

    //                         {
    //                             devicesmasterlist &&
    //                             devicesmasterlist.deviceTypeList &&
    //                             devicesmasterlist.deviceTypeList.map(devtype =>
    //                                 <option
    //                                     value={devtype.deviceTypeID}
    //                                     key={devtype.deviceTypeID}>
    //                                     {devtype.deviceType}
    //                                 </option>)
    //                         }
    //                     </select>
    //                 </div>
    //                 <div className="SiteTextBox">
    //                     {/* <p className="SiteNameText">Alert Type <span style={{ color: "red" }}> *</span></p> */}
    //                     <select
    //                         {...register("AlertCodeId")}
    //                         className="SiteNameTextBox p-2"
    //                         defaultValue={selectedTemplate.AlertCodeId}
    //                         onChange={e => {
    //                             setValue('AlertCodeId', e.target.value)
    //                         }}
    //                         disabled={!isAlertTemplateEdit}>
    //                         <option key="0" value="0" >Alert Type*</option>
    //                         {
    //                             devicesmasterlist &&
    //                             devicesmasterlist.alertCodeList &&
    //                             devicesmasterlist.alertCodeList.filter(x => x.deviceTypeId == devicetypeid).map(alertCode =>
    //                                 <option
    //                                     value={alertCode.alertCodeId}
    //                                     key={alertCode.alertCodeId}>
    //                                     {alertCode.combinedCode}
    //                                 </option>)
    //                         }
    //                     </select>
    //                 </div>
    //                 {isclient && <div className="SiteTextBox">
    //                     {/* <p className="SiteNameText">Client Name <span style={{ color: "red" }}> *</span></p> */}
    //                     <select
    //                         {...register("ClientId")}
    //                         className="SiteNameTextBox p-2"
    //                         defaultValue={selectedTemplate.alertCodeId}
    //                         onChange={e => setValue('ClientId', e.target.value)}
    //                         disabled={!isAlertTemplateEdit}>
    //                         <option key="0" value="0" >Client Name*</option>
    //                         {
    //                             devicesmasterlist &&
    //                             devicesmasterlist.clientList &&
    //                             devicesmasterlist.clientList.map(client =>
    //                                 <option
    //                                     value={client.clientId}
    //                                     key={client.clientId}>
    //                                     {client.clientName}
    //                                 </option>)
    //                         }
    //                     </select>
    //                 </div>}
    //                 {issensor && <div className="SiteTextBox">
    //                     {/* <p className="SiteNameText">Sensor Name <span style={{ color: "red" }}> *</span></p> */}
    //                     <select
    //                         {...register("IDSPanelZoneTypeId")}
    //                         className="SiteNameTextBox p-2"
    //                         defaultValue={selectedTemplate.IDSPanelZoneTypeId}
    //                         onChange={e => setValue('IDSPanelZoneTypeId', e.target.value)}
    //                         disabled={!isAlertTemplateEdit}>
    //                         <option key="0" value="0" >Sensor Name*</option>
    //                         {
    //                             devicesmasterlist &&
    //                             devicesmasterlist.zoneTypeMasterList &&
    //                             devicesmasterlist.zoneTypeMasterList.map(zone =>
    //                                 <option
    //                                     value={zone.iDSPanelZoneTypeID}
    //                                     key={zone.iDSPanelZoneTypeID}>
    //                                     {zone.zoneType}
    //                                 </option>)
    //                         }
    //                     </select>
    //                 </div>}
    //                 <div className="row col-lg-12 config-input-section mt-4">
    //                     {/*  <Row className='timeSlot col-lg-12'>
    //                         <p className="SiteNameText">Time Slot <span style={{ color: "red" }}> *</span></p>
    //                     </Row> */}
    //                     <Row className='alert-template-duration-container col-lg-12 mt-2'>
    //                         {/* <Col lg={9}> */}
    //                         {/* <label htmlFor="24hrs">
    //                                 <input
    //                                     id="24hrs"
    //                                     type="checkbox"
    //                                     label="24 hrs"
    //                                     name="FullTime"
    //                                     {...register('FullTime')}
    //                                     value={isFullTime}
    //                                     defaultChecked={isFullTime}
    //                                     onChange={(e) => setIsFullTime(e.target.checked)}
    //                                     disabled={!isAlertTemplateEdit}/>
    //                                 24 hrs
    //                             </label> */}
    //                         {/* <Col lg={4} px-0 style={{ background: "blue" }}> */}
    //                         <Col lg={4} className="px-2">
    //                             <div className="alert-template-fullDay ">
    //                                 <div className="alert-template-fullDay-textbox ">
    //                                     <span className="alert-template-fullDay-text">
    //                                         Full Day*
    //                                     </span>
    //                                     <span className="alert-template-fullDay-toggle">
    //                                         {isToggleActive ? <img src={ToggleOn} alt="Toggle icon on" onClick={() => {
    //                                             if(isAlertTemplateEdit) {
    //                                                 ToggleAlertFullTime('on')
    //                                             }
    //                                         }} />
    //                                             : <img src={ToggleOff} alt="Toggle icon off" onClick={() => {
    //                                                 if(isAlertTemplateEdit){
    //                                                     ToggleAlertFullTime('off')
    //                                                 }
    //                                             }} />}
    //                                     </span>
    //                                 </div>
    //                             </div>
    //                         </Col>
    //                         {
    //                             !isFullTime
    //                             ? <span style={{paddingTop: '0.5rem', marginLeft: '-0.5rem', marginRight: '-0.5rem', fontSize: '0.8rem'}}>OR</span>
    //                             : null
    //                         }
    //                         {/* <Col lg={4} style={{ background: "green" }}> */}

    //                         {
    //                             !isFullTime ? (<Col lg={4} className="px-0 reactDatePicker-container">
    //                             {/* <Col lg={6} style={{ background: "pink" }}> */}
    //                             <Col lg={6} className="pr-2 pl-3 alertTemplate-reactDatePicker-box1">
    //                                 <DatePicker
    //                                     className="SiteNameTextBox alertTemplate-reactDatePicker1"
    //                                     showPopperArrow={false}
    //                                     selected={fromTime}
    //                                     onChange={(date) => setFromTime(date)}
    //                                     showTimeSelect
    //                                     showTimeSelectOnly
    //                                     timeIntervals={15}
    //                                     timeCaption="Time"
    //                                     // dateFormat="HH:mm"
    //                                     // timeFormat="HH:mm"
    //                                     dateFormat="h:mm aa"
    //                                     // disabled={!isAlertTemplateEdit}
    //                                     disabled={isToggleActive}
    //                                     //readOnly={isFullTime}
    //                                     readOnly={
    //                                         !isFullTime
    //                                           ? !isAlertTemplateEdit
    //                                             ? true
    //                                             : false
    //                                           : true
    //                                     }
    //                                 />
    //                                 <DropArrow className='dropArrow-icon' />
    //                             </Col>
    //                             {/* <Col lg={6} style={{ background: "pink" }}> */}
    //                             <Col lg={6} className="pr-4 pl-0 alertTemplate-reactDatePicker-box2">
    //                                 <DatePicker
    //                                     className="SiteNameTextBox alertTemplate-reactDatePicker2"
    //                                     showPopperArrow={false}
    //                                     selected={toTime}
    //                                     onChange={(date) => setToTime(date)}
    //                                     showTimeSelect
    //                                     showTimeSelectOnly
    //                                     timeIntervals={15}
    //                                     timeCaption="Time"
    //                                     // dateFormat="HH:mm"
    //                                     // timeFormat="HH:mm"
    //                                     dateFormat="h:mm aa"
    //                                     // disabled={!isAlertTemplateEdit}
    //                                     disabled={isToggleActive}
    //                                     //readOnly={isFullTime}
    //                                     readOnly={
    //                                         !isFullTime
    //                                           ? !isAlertTemplateEdit
    //                                             ? true
    //                                             : false
    //                                           : true
    //                                     }
    //                                 />
    //                                 <DropArrow className='dropArrow-icon' />
    //                             </Col>
    //                         </Col>) : null
    //                         }
    //                         {/* <Col lg={4}>

    //                         </Col> */}

    //                         {/* <label className='SiteNameText' style={{ paddingLeft: '10px', paddingRight: '5px' }}>OR</label> */}
    //                         {/* <div className='reactDatePicker-container' style={{ display: "inline-flex", alignItems: "center" }}>

    //                             <span>
    //                                 <DatePicker
    //                                     className="m-1 SiteNameTextBox"
    //                                     showPopperArrow={false}
    //                                     selected={fromTime}
    //                                     onChange={(date) => setFromTime(date)}
    //                                     showTimeSelect
    //                                     showTimeSelectOnly
    //                                     timeIntervals={15}
    //                                     timeCaption="Time"
    //                                     // dateFormat="HH:mm"
    //                                     // timeFormat="HH:mm"
    //                                     dateFormat="h:mm aa"
    //                                     // disabled={!isAlertTemplateEdit}
    //                                     disabled={isToggleActive}
    //                                     readOnly={isFullTime}
    //                                 />


    //                                 <DropArrow style={{ color: "black", height: "7px", width: "10px" }} alt="drop arrow" className="m-1 dropArrow" />
    //                             </span>
    //                             <p className="m-1 p-1 SiteNameText">To: </p>
    //                             <span>
    //                                 <DatePicker
    //                                     className="m-1 SiteNameTextBox"
    //                                     showPopperArrow={false}
    //                                     selected={toTime}
    //                                     onChange={(date) => setToTime(date)}
    //                                     showTimeSelect
    //                                     showTimeSelectOnly
    //                                     timeIntervals={15}
    //                                     timeCaption="Time"
    //                                     // dateFormat="HH:mm"
    //                                     // timeFormat="HH:mm"
    //                                     dateFormat="h:mm aa"
    //                                     // disabled={!isAlertTemplateEdit}
    //                                     disabled={isToggleActive}
    //                                     readOnly={isFullTime}
    //                                 />
    //                                 <DropArrow style={{ color: "black" }} alt="drop arrow" className="m-1 dropArrow" />
    //                             </span>
    //                         </div> */}
    //                         {/* </Col> */}
    //                     </Row>

    //                 </div>
    //                 <div className="row config-input-section mt-2 severity-level-outer-container">
    //                     <div className="container form-group mb-3 severity-level-inner-container">
    //                         <div className="row SiteNameText" style={{ marginLeft: "10px" }}>
    //                             <div>Severity <span style={{ color: "red" }}> *</span></div>
    //                         </div>
    //                         <div className="row" style={{ marginLeft: "0px" }}>
    //                             {/* <div className="col-lg-8"> */}
    //                             <div className="col-lg-12">
    //                                 {severities &&
    //                                     severities.map(item => {
    //                                         return <div key={item.SeverityId} className="form-check-inline">
    //                                             <>
    //                                                 <input
    //                                                     style={{ left: "0", position: "relative" }}
    //                                                     type="radio"
    //                                                     name="SeverityId"
    //                                                     value={item.SeverityId}
    //                                                     checked={selectedSeverity == item.SeverityId}
    //                                                     onChange={e => {
    //                                                         setValue('SeverityId', e.currentTarget.value)
    //                                                         setSelectedSeverity(e.currentTarget.value)
    //                                                     }}
    //                                                     disabled={!isAlertTemplateEdit} />{" "}
    //                                                 <span className="ml-2">{` ${item.SeverityTitle}`}</span>
    //                                             </>
    //                                         </div>
    //                                     })}
    //                             </div>
    //                             {/* <div className="col-lg-4 align-self-end">
    //                             {
    //                                 selectedTemplate.AlertTemplateId != 0 &&
    //                                 !isAlertTemplateEdit &&
    //                                 <button type="submit" className="NextMoveButton float-end" style={{ width: "6.8rem" }} onClick={() => setIsAlertTemplateEdit(true)}>
    //                                     <span className="ViewUpdate">Edit</span>
    //                                 </button>
    //                             }
    //                             {
    //                                 isAlertTemplateEdit &&
    //                                 <>
    //                                     {selectedTemplate.AlertTemplateId != 0 &&
    //                                         <button type="submit" className="NextMoveButton float-end" style={{ width: "6.8rem" }}>
    //                                             <span className="ViewUpdate">Update</span>
    //                                         </button>}
    //                                     {selectedTemplate.AlertTemplateId == 0 &&
    //                                         <button type="submit" className="NextMoveButton float-end" style={{ width: "6.8rem" }}>
    //                                             <span className="ViewUpdate">Next</span>
    //                                         </button>
    //                                     }

    //                                     {
    //                                         selectedTemplate.AlertTemplateId != 0 &&
    //                                         <a className="btn btn-link ml-2" onClick={(e) => onCancel(e)}>Cancel</a>
    //                                     }
    //                                 </>
    //                             }
    //                         </div> */}
    //                         </div>
    //                     </div>
    //                 </div>

    //                 <div className="row col-lg-12 config-input-section mt-2 alertTemplate-addTemplate-description-container" style={{margin:"0 auto"}}>
    //                     <div className="col-lg-12 form-group p-0">
    //                         {/* <label for="alert-desc" >Description</label> */}
    //                         <textarea
    //                             style={{resize: 'none'}}
    //                             id="alert-desc"
    //                             rows="2"
    //                             className="form-control description-textbox"
    //                             placeholder="Enter Template Description"
    //                             {...register("Description")}
    //                             disabled={!isAlertTemplateEdit}>

    //                         </textarea>
    //                     </div>

    //                 </div>
    //             </div>
    //             <div className="row">
    //                 <div className="col-lg-12 align-self-end alertTemplate-updateTemplate-edit-btn-container">
    //                     {
    //                         selectedTemplate.AlertTemplateId != 0 &&
    //                         !isAlertTemplateEdit &&
    //                         <button type="submit" className="NextMoveButton float-end template-btn alertTemplate-updateTemplate-edit-btn" onClick={() => setIsAlertTemplateEdit(true)}>
    //                             <span className="ViewUpdate">Edit</span>
    //                         </button>
    //                     }
    //                     {
    //                         isAlertTemplateEdit &&
    //                         <>
    //                             {selectedTemplate.AlertTemplateId != 0 &&
    //                                 <button type="submit" className="NextMoveButton float-end template-btn alertTemplate-updateTemplate-update-btn" >
    //                                     <span className="ViewUpdate">Update</span>
    //                                 </button>}
    //                             {selectedTemplate.AlertTemplateId == 0 &&
    //                                 <button type="submit" className="NextMoveButton float-end template-btn">
    //                                     <span className="ViewUpdate">Next</span>
    //                                 </button>
    //                             }

    //                             {
    //                                 selectedTemplate.AlertTemplateId != 0 &&
    //                                 <a className="btn btn-link ml-2" onClick={(e) => onCancel(e)}>Cancel</a>
    //                             }
    //                         </>
    //                     }
    //                 </div>
    //             </div>
    //         </form>
    //         <ToastContainer
    //             position="bottom-left"
    //             autoClose={5000}
    //             hideProgressBar={false}
    //             newestOnTop={false}
    //             closeOnClick
    //             rtl={false}
    //             pauseOnFocusLoss
    //             draggable
    //             pauseOnHover
    //             theme="light"
    //         />
    //     </div>

    //     </div>
    // </div>

    const PaginationComponentOne = (currentPageNo) => {
        let numbers = [];
        if (maxPageAllowedOne - 2 != 0 && (currentPageNo === maxPageAllowedOne || currentPageNo === (maxPageAllowedOne - 1) || currentPageNo === (maxPageAllowedOne - 2))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowedOne - 2}
                    active={maxPageAllowedOne - 2 == currentPageNo}
                    onClick={() => setCurrentPageNoOne(maxPageAllowedOne - 2)}
                >
                    {maxPageAllowedOne - 2}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowedOne - 1}
                    active={maxPageAllowedOne - 1 == currentPageNo}
                    onClick={() => setCurrentPageNoOne(maxPageAllowedOne - 1)}
                >
                    {maxPageAllowedOne - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowedOne}
                    active={maxPageAllowedOne == currentPageNo}
                    onClick={() => setCurrentPageNoOne(maxPageAllowedOne)}
                >
                    {maxPageAllowedOne}
                </Pagination.Item>)
        }
        else if (maxPageAllowedOne - 1 != 0 && (currentPageNo === maxPageAllowedOne || currentPageNo === (maxPageAllowedOne - 1))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowedOne - 1}
                    active={maxPageAllowedOne - 1 == currentPageNo}
                    onClick={() => setCurrentPageNoOne(maxPageAllowedOne - 1)}
                >
                    {maxPageAllowedOne - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowedOne}
                    active={maxPageAllowedOne == currentPageNo}
                    onClick={() => setCurrentPageNoOne(maxPageAllowedOne)}
                >
                    {maxPageAllowedOne}
                </Pagination.Item>)
        }
        else {
            for (let i = currentPageNo; i < currentPageNo + 3; i++) {
                if (i <= maxPageAllowedOne) {
                    numbers.push(
                        <Pagination.Item
                            key={i}
                            active={i == currentPageNo}
                            onClick={() => setCurrentPageNoOne(i)}
                        >
                            {i}
                        </Pagination.Item>
                    )
                }
            }
        }
        return numbers;
    }

    const PaginationComponentTwo = (currentPageNo) => {
        let numbers = [];
        if(maxPageAllowedTwo - 2 != 0  && (currentPageNo === maxPageAllowedTwo || currentPageNo === (maxPageAllowedTwo - 1) || currentPageNo === (maxPageAllowedTwo - 2)))
        {
          numbers.push(
          <Pagination.Item
            key={maxPageAllowedTwo - 2}
            active={maxPageAllowedTwo - 2 == currentPageNo}
            onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo - 2)}
          >
            {maxPageAllowedTwo - 2}
          </Pagination.Item>)
          numbers.push(
            <Pagination.Item
              key={maxPageAllowedTwo - 1}
              active={maxPageAllowedTwo - 1 == currentPageNo}
              onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo - 1)}
            >
              {maxPageAllowedTwo - 1}
            </Pagination.Item>) 
          numbers.push(
            <Pagination.Item
              key={maxPageAllowedTwo}
              active={maxPageAllowedTwo == currentPageNo}
              onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo)}
            >
        {maxPageAllowedTwo}
        </Pagination.Item>)
        }
        else if(maxPageAllowedTwo - 1 != 0 && (currentPageNo === maxPageAllowedTwo || currentPageNo === (maxPageAllowedTwo - 1))){
          numbers.push(
            <Pagination.Item
              key={maxPageAllowedTwo - 1}
              active={maxPageAllowedTwo - 1 == currentPageNo}
              onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo - 1)}
            >
              {maxPageAllowedTwo - 1}
            </Pagination.Item>)
            numbers.push(
              <Pagination.Item
                key={maxPageAllowedTwo}
                active={maxPageAllowedTwo == currentPageNo}
                onClick={() => setCurrentPageNoTwo(maxPageAllowedTwo)}
              >
                {maxPageAllowedTwo}
              </Pagination.Item>) 
        }
        else
        {
          for(let i=currentPageNo; i<currentPageNo + 3; i++) {
            if(i <= maxPageAllowedTwo){
              numbers.push(
                <Pagination.Item
                  key={i}
                  active={i == currentPageNo}
                  onClick={() => setCurrentPageNoTwo(i)}
                >
                  {i}
                </Pagination.Item>
              )
            }
          }  
        }
        return numbers;
    }

    const [alertSearch, setAlertSearch] = useState("");
    const [deviceSearch, setDeviceSearch] = useState("");
    const [activeTab, setActiveTab] = useState("Template")
    const [timer, setTimer] = useState(null);
    const [alertTimer, setAlertTimer] = useState(null);
    const [filterText, setfilterText] = useState("");
    const [customerCount, setCustomerCount] = useState(0);
    const [siteCount, setSiteCount] = useState(0);
    const [deviceTypeCount, setDeviceTypeCount] = useState(0);
    const [alertTypeCount, setalertTypeCount] = useState(0);

    useEffect(() => {
        if(activeTab == "Template"){
            clearTimeout(timer);
            const newTimer = setTimeout(() => {
                console.log("filterTextfilterText", filterText)
                GetAlertTemplateList("alert_template_list", 1, alertSearch,filterText) 
                setCurrentPageNoOne(1)
            }, 500)
            setTimer(newTimer);
        }
    }, [alertSearch])

    useEffect(() => {
        if(activeTab == "Device"){
            clearTimeout(alertTimer);
            const newTimer = setTimeout(() => {
                GetAlertTemplateList("site_list", 1, deviceSearch,filterText);
                setCurrentPageNoTwo(1);
            }, 500)
            setAlertTimer(newTimer);
        }
    }, [deviceSearch]);

    const templateSelected = (id) => {
        if(id == alertTemplateObj.templateId){
            return;
        }
        if(alertTemplateObj.templateId != "" && isAlertTempFormEnabled){
            Swal.fire({
                title: 'Operation in Progress.',
                text: "You have started the operation for a template, do you want to discard any changes and continue to the other template.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#027aaa',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok!',
                allowOutsideClick: false
              }).then((result) => {
                if(result.isConfirmed){
                    getAlertTemplateById(id); 
                    setDeviceTemplateList([]);
                    setSelTempDevice(null)
                    setDisplayButtons(true);
                    setActivityLogShow(false);
                }
              })
        }else{
            getAlertTemplateById(id); 
            setDeviceTemplateList([]);
            setSelTempDevice(null)
            setDisplayButtons(true);
            setActivityLogShow(false);
        }
    }
    const deviceTempSelected = (Id, template) => {
        console.log("checkselTempDevice", selTempDevice)

        if (Id == selTempDevice?.Id) {
            console.log("checkselTempDevice", selTempDevice)
            return;
        }
        if (alertTemplateObj.templateId !== "" && isAlertTempFormEnabled) {
            Swal.fire({
                title: 'Operation in Progress.',
                text: "You have started the operation for a device, do you want to discard any changes and continue to the other device.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#027aaa',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok!',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    //getAlertTemplateById(Id); 
                    getAlertTemplateByDeviceId(Id);
                    setDeviceTemplateList([]);
                    setSelTempDevice(Id);
                    setDisplayButtons(true);
                    setActivityLogShow(false);
                    setSelTempDevice(template)
                }
            });
        } else {
            getAlertTemplateByDeviceId(Id);
            setDeviceTemplateList([]);
            setSelTempDevice(null);
            setDisplayButtons(true);
            setActivityLogShow(false);
            setSelTempDevice(template)
        }
    }
return (
    <>
      {/* <div className="col-lg-12 mt-2"> */}
        <div className="SiteMainContainer">
          <div className="SiteLeftContainer">

            <div>
                <Tabs
                defaultActiveKey="Template"
                id="uncontrolled-tab-example"
                className="AlertTemplateTab"
                onSelect={(k) => {
                    console.log("ActiveTab", k)
                    if(activeTab == k){
                        console.log("ActiveTab", k)
                        return;
                    }
                    if(k == "Template"){
                        GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch,"")
                        setfilterText("");
                        setDevTemClicked(false);
                        setDeviceTemplateList([]);
                        let newAlertTemplateObj = {
                            templateId : "",
                            templateName: "",
                            templateClientId: "0",
                            templateSiteId: [],
                            templateDeviceTypeId: "0",
                            templateDeviceSubTypeId: "0",
                            templateDevice: [],
                            templateAlertType: "0",
                            scheduleType: "NA",
                            schedulerObj : {},
                            tempSelectedSites:[],
                            tempSelectedDevices:[],
                            // templateAlertCodeId:"",
                            // templateDescription:"",
                            // templateCreatedOn:"",
                            // templateCreatedBy:"",
                            // templateModifiedOn:"",
                            // templateModifiedBy:"",
                            // LstDeviceAlertTemplateConfig:[],
                        
                        }
                        setAlertTemplateObj(newAlertTemplateObj)
                        setDisplayButtons(true);
                        setAddNewClickedAlert(true);
                        setIsEmptyFormAlert(true);
                        setIsAlertTempFormEnabled(true);
                        setIsAlertTemplateEdit(true)
                        setIsStore(false);
                        setActivityLogShow(false);
                        if(activeTab == "Device"){
                            setFilterCount(0);
                        }
                    }else{
                        GetAlertTemplateList("site_list", currentPageNoTwo, deviceSearch,"", true);
                        setfilterText("");
                        if(activeTab == "Template"){
                            setFilterCount(0);
                        }
                    }
                    setActiveTab(k);
                }}
                >
                    <Tab eventKey="Template" title="Template List">
                        <div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div className="m-2 HeaderSiteList withFilterBox">
                                    <input
                                        id="Search_txt"
                                        className="SearchViewInput1"
                                        type="text"
                                        placeholder="Search"
                                        // autoFocus
                                        value={alertSearch}
                                        onChange={(e) => setAlertSearch(e.target.value)}
                                    />
                                    <div className="SearchTextDivConfigTool">
                                        {
                                            isDarkTheme ?
                                            <img
                                            className="EyeIcon"
                                            src={SearchIcon}
                                            alt="search icon"
                                            onClick={() => {
                                                GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch,filterText)
                                            }}
                                            /> :
                                            <img
                                            className="EyeIcon"
                                            src={SearchIconWhite}
                                            alt="search icon" height="15"
                                            onClick={() => {
                                                GetAlertTemplateList("alert_template_list", currentPageNoOne, alertSearch,filterText)
                                            }}
                                            />

                                        }
                                    </div>
                                </div>
                                <div>
                                <button className="AlertFilter"
                                // style={{display: "none"}}
                                            onClick={() => {setTemplateModalFilter(true)}}
                                            >
                                                                    <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={
                                                                <Tooltip>
                                                                    filter
                                                                </Tooltip>
                                                                }
                                                            >
                                                                <>
                                            <div className="badge badge-pill badge-danger" style={{top: "2px", right: "4px", minWidth: "25px"}}>
                                                <p className='filteractivetext'>{filterCount}</p>
                                            </div>
                                            <img src={Filter} style={{height: "15px", float: "left"}} className="camera-icon-height" alt='Alert filter icon'/>
                                                                </>
                                            </OverlayTrigger>
                                            </button>
                                </div>
                                <Popup
                                        className='popupDiv'
                                        // position="bottom center"
                                        position={["bottom center", "top center", "right center"]}
                                        //   keepTooltipInside=".camera-list"
                                        // closeOnDocumentClick={false}        
                                        // closeOnDocumentClick={true} 
                                        trigger={
                                            <button className="DataAccessEdit"
                                            style={{height: "37px", width: "37px", display: "none"}} 
                                            // onClick={e => { onSingleDevPlaybackHandleClick(item) }}
                                            >
                                                                    <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={
                                                                <Tooltip>
                                                                    filter
                                                                </Tooltip>
                                                                }
                                                            >
                                                                <>
                                            <div className="badge badge-pill badge-danger ml-1 mt-1" style={{top: "3.4rem"}}>
                                                <p className='filteractivetext'>88</p>
                                            </div>
                                            <img src={Filter} style={{height: "15px"}} className="camera-icon-height" alt='playback white icon'/>
                                                                </>
                                            </OverlayTrigger>
                                            </button>
                                        }
                                        >
                                        <div className='popupDiv filtermaindiv'>
                                            <div className='filterpopup p-0'>
                                                <h6>Filter by:</h6>
                                                <button>
                                                    <img src={Cross} alt='cancel button' />
                                                </button>
                                            </div>
                                                <div className='filterinnermaindiv'>
                                                    <div className="NotificationTextBox">
                                                            <p className="SiteNameText">
                                                            Severity  
                                                            </p>
                                                            <div className="filterseverity">
                                                                {severities &&
                                                                    severities.map(item => {
                                                                        return <div key={item.SeverityId} className="form-check-inline">
                                                                            <>
                                                                                <input
                                                                                    style={{ left: "0", position: "relative" }}
                                                                                    type="checkbox"
                                                                                    // name="SeverityId"
                                                                                    // value={item.SeverityId}
                                                                                    // checked={selectedSeverity == item.SeverityId}
                                                                                    // onChange={e => {
                                                                                    //     setValue('SeverityId', e.currentTarget.value)
                                                                                    //     setSelectedSeverity(e.currentTarget.value)
                                                                                    // }}
                                                                                    disabled={!isAlertTemplateEdit} />{" "}
                                                                                <span className="ml-2">{` ${item.SeverityTitle}`}</span>
                                                                            </>
                                                                        </div>
                                                                })}
                                                            </div>
                                                    </div>
                                                </div>

                                            <div style={{textAlign: 'center'}}>
                                                <div className='DataAccessEdit' style={{width: "100px"}}>
                                                    <span className='ViewUpdate'>Apply</span>
                                                </div>
                                                {/* <div className='DataAccessEdit' style={{width: "100px"}}>
                                                    <span className='ViewUpdate'>Cancel</span>
                                                </div> */}
                                            </div>
                                        </div>
                                </Popup>

                                <Modal
                                    size='lg'
                                    show={templateModalFilter}
                                    onHide={() => setTemplateModalFilter(false)}
                                    className='filterModalMainclass'
                                    backdrop="static"
                                >
                                <Modal.Header closeButton style={{borderBottom: "none"}}>
                                    <Modal.Title className='pb-0'>
                                    {/* Filter Template List */}
                                    {
                                        <span>Filter {activeTab} List</span>
                                    }
                                        {/* <p className="ModelHeaderName">
                                        Filter Template List:
                                        </p> */}
                                    </Modal.Title>
                                </Modal.Header>   

                                <Modal.Body>
                                    <div className=''>
                                                <div className=''>
                                                    <div className="grid-container filterContainer grid-container--fit mb-0 ">
                                                        <div className="NotificationTextBox mt-0">
                                                            <p className="SiteNameText mb-0">
                                                            Customer Name
                                                            </p>
                                                            {/* <select
                                                            className="MessageTextBox1"
                                                            placeholder="Select Device type"
                                                            {...register("ClientId")}
                                                            defaultValue={selectedTemplate.ClientId}
                                                            disabled={!isAlertTemplateEdit}
                                                            >
                                                            <option key="0" disabled={false} value="0">
                                                                Select Customer
                                                            </option>
                                                            {
                                                                // customerData.map((item) => (
                                                                //     <option 
                                                                //         value={item.CustomerId}
                                                                //         key={item.CustomerId}
                                                                //     >
                                                                //         {item.CustomerName}
                                                                //     </option>
                                                                // ))
                                                                clients?.map((val) => (
                                                                    <option value={val.clientID} key={val.clientName}>
                                                                      {val.clientName}
                                                                    </option>
                                                                  ))
                                                            }    
                                                            </select> */}

                                                        <div className='filterinnerboxdiv'>
                                                            <div>
                                                                <div className="SelectionListWithSelectAll">
                                                                    <input
                                                                        className="SearchViewInput1"
                                                                        type="search"
                                                                        placeholder="Search"
                                                                        value={customerSearch}
                                                                        onChange={(e) => searchFilterHandler(e, "Customer")}
                                                                    />
                                                                    {
                                                                        isDarkTheme ?
                                                                        <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                                                        <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                                                    }
                                                                </div>
                                                            </div>
                                                                {
                                                                    customerCount > 0
                                                                        ?
                                                                        <div className='dataAccessListItem mt-2'
                                                                        // onClick={handleCustomerSelectAll}
                                                                        >
                                                                            <input type="checkbox"
                                                                                checked={customerSelectAll}
                                                                                onChange={handleCustomerSelectAll}
                                                                            />
                                                                            <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                                                                onClick={handleCustomerSelectAll}>
                                                                                Select all <span>{customerCount}</span>
                                                                            </p>
                                                                        </div>
                                                                        : null
                                                                }
                                                            <div className='HgtFilterBoxList'>
                                                                
                                                                    {
                                                                        filtercustomerList.map((item) => (
                                                                            <div key={item.Item1} className="dataAccessListItem"
                                                                            // onClick={(e) => customerCheckHandler(e, item.Item1)}
                                                                            >
                                                                                <input
                                                                                    checked={item.isChecked}
                                                                                    type="checkbox"
                                                                                    onChange={(e) => customerCheckHandler(e, item.Item1)}
                                                                                />
                                                                                <p className="AllTextwithValue"
                                                                                    onClick={(e) => customerCheckHandler(e, item.Item1)}>
                                                                                    {item.Item2}</p>
                                                                            </div>
                                                                        ))
                                                                    }
                                                            </div>
                                                        </div>


                                                        </div>
                                                        <div className="NotificationTextBox mt-0">
                                                            <p className="SiteNameText mb-0">
                                                            Site
                                                            </p>
                                                            
                                                            {/* <MultiSelect
                                                            // className="MessageTextBox1"
                                                            //placeholder="Select Site"
                                                            //   disabled={!isNotifFormEnabled}
                                                            // options={siteList}
                                                            //   options={siteListArray}
                                                            // value={siteSelected}
                                                            // onChange={setSiteSelected}
                                                            // disabled={!isAlertTemplateEdit}
                                                            //   labelledBy={"Select"}
                                                            // overrideStrings={{
                                                            //      selectSomeItems: `${siteList[siteList.length - 1].value}`
                                                            //     selectSomeItems: "Select site",
                                                            //     allItemsAreSelected: "All sites are selected"
                                                            // }}
                                                            // ArrowRenderer={() => 
                                                            // <DropArrow />
                                                            // }
                                                            /> */}

                                                        <div className='filterinnerboxdiv'>
                                                            <div>
                                                                <div className="SelectionListWithSelectAll">
                                                                    <input
                                                                        className="SearchViewInput1"
                                                                        type="search"
                                                                        placeholder="Search"
                                                                        value={siteSearch}
                                                                        onChange={(e) => searchFilterHandler (e, "Site")}
                                                                    />
                                                                    {
                                                                        isDarkTheme ?
                                                                        <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                                                        <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                                                    }
                                                                </div>
                                                            </div>
                                                                {
                                                                    siteCount > 0
                                                                        ?
                                                                        <div className='dataAccessListItem mt-2'
                                                                        // onClick={handleSiteSelectAll}
                                                                        >
                                                                            <input type="checkbox"
                                                                                checked={siteSelectAll}
                                                                                onChange={handleSiteSelectAll}
                                                                            />
                                                                            <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                                                                onClick={handleSiteSelectAll}>
                                                                                Select all <span>{siteCount}</span>
                                                                            </p>
                                                                        </div>
                                                                        : null
                                                                }
                                                            <div className='HgtFilterBoxList'>
                                                                
                                                                    {
                                                                        filterSiteList.map((item) => (
                                                                            <div key={item.Item1} className="dataAccessListItem"
                                                                            // onClick={(e) => siteCheckHandler(e, item.Item1)} 
                                                                            >
                                                                                <input checked={item.isChecked}
                                                                                    type="checkbox"
                                                                                    onChange={(e) => siteCheckHandler(e, item.Item1)}
                                                                                />
                                                                                <p className="AllTextwithValue"
                                                                                    onClick={(e) => siteCheckHandler(e, item.Item1)}>
                                                                                    {item.Item2}</p>
                                                                            </div>
                                                                        ))
                                                                    }
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid-container filterContainer grid-container--fit mb-0">
                                                        <div className="NotificationTextBox">
                                                            <p className="SiteNameText mb-0">
                                                            Device Type 
                                                            </p>
                                                            {/* <select
                                                            className="MessageTextBox1"
                                                            placeholder="Select Device type"
                                                            {...register("DeviceTypeId")}
                                                            defaultValue={selectedTemplate.DeviceTypeId}
                                                            onChange={e => {
                                                                OnDeviceTypeChanges(e)
                                                            }}
                                                            disabled={!isAlertTemplateEdit}
                                                            >
                                                            <option key="0" disabled={false} value="0">
                                                                Select Device type
                                                            </option>
                                                            {
                                                                devicesmasterlist &&
                                                                devicesmasterlist.deviceTypeList &&
                                                                devicesmasterlist.deviceTypeList.map(devtype =>
                                                                    <option
                                                                        value={devtype.deviceTypeID}
                                                                        key={devtype.deviceTypeID}>
                                                                        {devtype.deviceType}
                                                                    </option>)
                                                            }
                                                            </select> */}

                                                            <div className='filterinnerboxdiv'>
                                                                <div>
                                                                    <div className="SelectionListWithSelectAll">
                                                                        <input
                                                                            className="SearchViewInput1"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                            value={deviceTypeSearch}
                                                                            onChange={(e) => searchFilterHandler (e, "Device Type")}
                                                                        />
                                                                        {
                                                                            isDarkTheme ?
                                                                            <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                                                            <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    deviceTypeCount > 0
                                                                        ?
                                                                        <div className='dataAccessListItem mt-2'
                                                                        //  onClick={handleDeviceTypeSelectAll}
                                                                        >
                                                                            <input type="checkbox"
                                                                                checked={deviceTypeSelectAll}
                                                                                onChange={handleDeviceTypeSelectAll}
                                                                            />
                                                                            <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                                                                onClick={handleDeviceTypeSelectAll}>
                                                                                Select all <span>{deviceTypeCount}</span>
                                                                            </p>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div className='HgtFilterBoxList'>

                                                                    {
                                                                        filterDeviceTypeList.map((item) => (
                                                                            <div key={item.Item1} className="dataAccessListItem"
                                                                            // onClick={(e) => deviceTypeCheckHandler(e, item.Item1)} 
                                                                            >
                                                                                <input checked={item.isChecked}
                                                                                    type="checkbox"
                                                                                    onChange={(e) => deviceTypeCheckHandler(e, item.Item1)}
                                                                                />
                                                                                <p className="AllTextwithValue"
                                                                                    onClick={(e) => deviceTypeCheckHandler(e, item.Item1)}>
                                                                                    {item.Item2}</p>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="NotificationTextBox">
                                                            <p className="SiteNameText mb-0">
                                                                Alert Type 
                                                            </p>
                                                            {/* <select
                                                                className="MessageTextBox1"
                                                                placeholder="Select Device type"
                                                                {...register("AlertCodeId")}
                                                                defaultValue={selectedTemplate.AlertCodeId}
                                                                onChange={e => {setValue('AlertCodeId', e.target.value)}}
                                                                disabled={!isAlertTemplateEdit}
                                                                >
                                                                <option key="0" disabled={false} value="0">
                                                                    Select alert type
                                                                </option>
                                                                {
                                                                    devicesmasterlist &&
                                                                    devicesmasterlist.alertCodeList &&
                                                                    devicesmasterlist.alertCodeList.filter(x => x.deviceTypeId == devicetypeid).map(alertCode =>
                                                                        <option
                                                                            value={alertCode.alertCodeId}
                                                                            key={alertCode.alertCodeId}>
                                                                            {alertCode.combinedCode}
                                                                        </option>)
                                                                    }
                                                                </select> */}

                                                            <div className='filterinnerboxdiv'>
                                                                <div>
                                                                    <div className="SelectionListWithSelectAll">
                                                                        <input
                                                                            className="SearchViewInput1"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                            value={alertTypeSearch}
                                                                            onChange={(e) => searchFilterHandler (e, "Alert Type")}
                                                                        />
                                                                        {
                                                                            isDarkTheme ?
                                                                            <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} /> :
                                                                            <img src={SearchIconWhite} height="15" alt='search icon' style={{ cursor: 'pointer' }} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {
                                                                    alertTypeCount > 0
                                                                        ?
                                                                        <div className='dataAccessListItem mt-2'
                                                                        // onClick={handleAlertTypeSelectAll}
                                                                        >
                                                                            <input type="checkbox"
                                                                                checked={alertTypeSelectAll}
                                                                                onChange={handleAlertTypeSelectAll}
                                                                            />
                                                                            <p className="AllTextwithValue mb-1" style={{ fontWeight: "500" }}
                                                                                onClick={handleAlertTypeSelectAll}>
                                                                                Select all <span>{alertTypeCount}</span>
                                                                            </p>
                                                                        </div>
                                                                        : null
                                                                }
                                                                <div className='HgtFilterBoxList'>
                                                                    
                                                                    {
                                                                        filterAlertTypeList.map((item) => (
                                                                            <div key={item.Item1} className="dataAccessListItem"
                                                                            //  onClick={(e) =>  alertTypeCheckHandler(e, item.Item1)}
                                                                            >
                                                                                <input checked={item.isChecked} type="checkbox"
                                                                                    onChange={(e) => alertTypeCheckHandler(e, item.Item1)}
                                                                                />
                                                                                <p className="AllTextwithValue"
                                                                                    onClick={(e) => alertTypeCheckHandler(e, item.Item1)}>
                                                                                    {item.Item2}</p>
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div style={{textAlign: 'center', display: "flex", justifyContent: "center"}} className='filterbuttonsdiv'>
                                                                        <button className='DataAccessEdit' style={{width: "100px"}} onClick={applyAll}>
                                                                            <span className='ViewUpdate'>Apply</span>
                                                                        </button>
                                                                        
                                                                        <button className='DataAccessEdit' style={{width: "100px"}} onClick={filterClearAll}>
                                                                            <span className='ViewUpdate'>Reset</span>
                                                                            
                                                                        </button>
                                                </div>
                                    </div>
                                </Modal.Body>
                                </Modal>
                            </div>
                            <div className="AlertTemplateListDiv">
                            {/* {
                                filteredEventTemplates && (
                                    filteredEventTemplates?.length > 0 ?
                                    filteredEventTemplates.map((template) => {
                                    return (
                                        <div
                                        className={selectedTemplate?.AlertTemplateId === template.AlertTemplateId ? "OneList SelectedList" : "OneList"}
                                        key={template.AlertTemplateId}
                                        onClick={() => {
                                            // onSelectTemplate(template, true)
                                            getAlertTemplateById(81);
                                        }}
                                        >
                                        <p className="ListofText">
                                            {template.TemplateName}
                                            <span className="SiteNameText"> - {template.DeviceType}</span>
                                        </p>
                                        <div>
                                                <img className="m-2" src={EditShape} alt='edit icon' />
                                                <img src={DeleteQc} alt='delete icon' onClick={(e) => onDeleteTemplate(e, template.AlertTemplateId)} />
                                            </div>
                                        </div>
                                    );
                                    }) : <div className="no-data-found"><p>No Templates Found.</p></div>
                                )
                                }
                                {!filteredEventTemplates && 
                                <div className="no-data-found">No Templates Available.</div>
                                } */}
                                    {
                                        alertTemplateList && (
                                            alertTemplateList?.length > 0 ?
                                                alertTemplateList.map((template) => {
                                                    console.log("alertTemplateList", alertTemplateList);
                                                    return (
                                                        <div
                                                            className={alertTemplateObj?.templateId == template.Id ? "OneList SelectedList" : "OneList"}
                                                            key={template.Id}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                //Correct One
                                                                templateSelected(template.Id);
                                                            }}
                                                        //onClick={() => onSelectTemplate(template, true)}
                                                        >
                                                            <p className="ListofText">
                                                                {template.Name}
                                                                <span className="SiteNameText"> - {template.Tag}</span>
                                                                {/* <span className="SiteNameText"> - {template.Description}</span> */}
                                                            </p>
                                                            <div>
                                                                {/* <img src={RetailStore1} alt='retailstore icon' style={{height: "15px"}} /> */}
                                                               
                                                                {

                                                                    template.IsMobileAlert == "True" ?
                                                                    (
                                                                        <OverlayTrigger
                                              placement='bottom'
                                              overlay={
                                                <Tooltip>
                                                {template.SiteMode}
                                                </Tooltip>
                                              }
                                            >
                                                {
                                                    isDarkTheme ?
                                                    <img src={RetailStore2} alt='retailstore icon' style={{ height: "15px" }} />
                                                    :
                                                    <img src={RetailStore2White} alt='retailstore icon' style={{ height: "15px" }} />
                                                }
                                            </OverlayTrigger> 
                                                                    )
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                }) : <div className="no-data-found"><p>No Templates Found.</p></div>
                                        )
                                    }
                                {!alertTemplateList && 
                                <div className="no-data-found">No Templates Available.</div>
                                }
                            </div>

                            {/* Paginations */}
                            {
                                totalPagesOne === 0 || totalPagesOne <= limitOne
                                ? null
                                : <div className="PaginationDiv mb-2 mt-2 mr-2">
                                <h5 className='PageNumbers'>
                                    {
                                        totalPagesOne == 0 ? 0
                                            :
                                            (currentPageNoOne - 1) * parseInt(limitOne) + parseInt(1)} - {(currentPageNoOne * limitOne) > (totalPagesOne)
                                                ? (totalPagesOne)
                                                : currentPageNoOne * limitOne}
                                </h5>
                                <span>of</span>
                                <h5 className="PageNumbers">{totalPagesOne}</h5>
                                <Pagination>
                                    <Pagination.Prev onClick={prevClickOne} disabled={currentPageNoOne == 1}/>
                                        {
                                            PaginationComponentOne(currentPageNoOne)
                                        }
                                    {/* <Pagination.Item active>1</Pagination.Item> */}
                                    <Pagination.Next onClick={nextClickOne} disabled={currentPageNoOne == maxPageAllowedOne}/>
                                </Pagination>
                            </div>
                            }

                        </div>
                    </Tab>

                    <Tab eventKey="Device" title="Device List">
                    {/* <Tab eventKey="Device" >                     */}
                        <div>
                            <div style={{display: "flex", alignItems: "center"}}>
                                <div className="m-2 HeaderSiteList" style={{width: "85%"}}>
                                    <input
                                        id="Search_txt"
                                        className="SearchViewInput1"
                                        type="text"
                                        placeholder="Search"
                                        // autoFocus
                                        value={deviceSearch}
                                        onChange={(e) => setDeviceSearch(e.target.value)}
                                    />
                                    <div className="SearchTextDivConfigTool">
                                        {
                                            isDarkTheme ?
                                            <img
                                            className="EyeIcon"
                                            src={SearchIcon}
                                            alt="search icon"
                                            onClick={() => {
                                                GetAlertTemplateList("site_list", currentPageNoTwo, deviceSearch,filterText);
                                            }}
                                            /> :
                                            <img
                                            className="EyeIcon"
                                            src={SearchIconWhite}
                                            alt="search icon" height="15"
                                            onClick={() => {
                                                GetAlertTemplateList("site_list", currentPageNoTwo, deviceSearch,filterText);
                                            }}
                                            />

                                        }
                                    </div>
                                </div>
                                <div>
                                <button className="AlertFilter"
                                // style={{display: "none"}}
                                            // onClick={() => {setDeviceModalFilter(true)}}
                                            onClick={() => {setTemplateModalFilter(true)}}
                                            >
                                                                    <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={
                                                                <Tooltip>
                                                                    filter
                                                                </Tooltip>
                                                                }
                                                            >
                                                                <>
                                            <div className="badge badge-pill badge-danger" style={{top: "2px", right: "4px", minWidth: "25px"}}>
                                                <p className='filteractivetext'>{filterCount}</p>
                                            </div>
                                            <img src={Filter} style={{height: "15px", float: "left"}} className="camera-icon-height" alt='Alert filter icon'/>
                                                                </>
                                            </OverlayTrigger>
                                            </button>
                                </div>


                                <Popup 
                                        className='popupDiv'
                                        // position="bottom center"
                                        position={["bottom center", "top center", "right center"]}
                                        //   keepTooltipInside=".camera-list"
                                        // closeOnDocumentClick={false}        
                                        // closeOnDocumentClick={true} 
                                        trigger={
                                            <button className="DataAccessEdit"
                                            style={{height: "37px", width: "37px", display: "none"}}
                                            >
                                                                    <OverlayTrigger
                                                                placement='bottom'
                                                                overlay={
                                                                <Tooltip>
                                                                    filter
                                                                </Tooltip>
                                                                }
                                                            >
                                                                <>
                                            <div className="badge badge-pill badge-danger ml-1 mt-1" style={{top: "3.4rem"}}>
                                                <p className='filteractivetext'>88</p>
                                            </div>
                                            <img src={Filter} style={{height: "15px"}} className="camera-icon-height" alt='playback white icon'/>
                                                                </>
                                            </OverlayTrigger>
                                            </button>
                                        }
                                        >
                                        <div className='popupDiv filtermaindiv'>
                                            <div className='filterpopup p-0'>
                                                <h6>Filter by:</h6>
                                                <button>
                                                    <img src={Cross} alt='cancel button' />
                                                </button>
                                            </div>
                                                <div className='filterinnermaindiv'>
                                                    <div className="NotificationTextBox">
                                                            <p className="SiteNameText">
                                                            Severity  
                                                            </p>
                                                            <div className="filterseverity">
                                                                {severities &&
                                                                    severities.map(item => {
                                                                        return <div key={item.SeverityId} className="form-check-inline">
                                                                            <>
                                                                                <input
                                                                                    style={{ left: "0", position: "relative" }}
                                                                                    type="checkbox"
                                                                                    // name="SeverityId"
                                                                                    // value={item.SeverityId}
                                                                                    // checked={selectedSeverity == item.SeverityId}
                                                                                    // onChange={e => {
                                                                                    //     setValue('SeverityId', e.currentTarget.value)
                                                                                    //     setSelectedSeverity(e.currentTarget.value)
                                                                                    // }}
                                                                                    disabled={!isAlertTemplateEdit} />{" "}
                                                                                <span className="ml-2">{` ${item.SeverityTitle}`}</span>
                                                                            </>
                                                                        </div>
                                                                })}
                                                            </div>
                                                    </div>
                                                </div>
                                            <div style={{textAlign: 'center'}}>
                                                <div className='DataAccessEdit' style={{width: "100px"}}>
                                                    <span className='ViewUpdate'>Apply</span>
                                                </div>
                                            </div>
                                        </div>
                                </Popup>

                                <Modal
                                    size='lg'
                                    show={deviceModalFilter}
                                    onHide={() => setDeviceModalFilter(false)}
                                    className='filterModalMainclass'
                                    backdrop="static"
                                >
                                <Modal.Header closeButton style={{borderBottom: "none"}}>
                                    <Modal.Title className='pb-0'>
                                    Filter Device List
                                        {/* <p className="ModelHeaderName">
                                        Filter Template List:
                                        </p> */}
                                    </Modal.Title>
                                </Modal.Header>   

                                <Modal.Body>
                                    <div className=''>
                                                <div className=''>
                                                    <div className="grid-container filterContainer grid-container--fit mb-0 ">
                                                        <div className="NotificationTextBox mt-0">
                                                            <p className="SiteNameText mb-0" style={{color: "#212529"}}>
                                                            Customer Name
                                                            </p>
                                                            {/* <select
                                                            className="MessageTextBox1"
                                                            placeholder="Select Device type"
                                                            {...register("ClientId")}
                                                            defaultValue={selectedTemplate.ClientId}
                                                            disabled={!isAlertTemplateEdit}
                                                            >
                                                            <option key="0" disabled={false} value="0">
                                                                Select Customer
                                                            </option>
                                                            {
                                                                // customerData.map((item) => (
                                                                //     <option 
                                                                //         value={item.CustomerId}
                                                                //         key={item.CustomerId}
                                                                //     >
                                                                //         {item.CustomerName}
                                                                //     </option>
                                                                // ))
                                                                clients?.map((val) => (
                                                                    <option value={val.clientID} key={val.clientName}>
                                                                      {val.clientName}
                                                                    </option>
                                                                  ))
                                                            }    
                                                            </select> */}

                                                        <div className='filterinnerboxdiv'>
                                                            <div>
                                                                <div className="SelectionListWithSelectAll">
                                                                    <input
                                                                        className="SearchViewInput1"
                                                                        type="search"
                                                                        placeholder="Search"
                                                                    />
                                                                    <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} />
                                                                </div>
                                                            </div>
                                                                <div className='dataAccessListItem'>
                                                                    <input type="checkbox" />
                                                                    <label className="AllTextwithValue" style={{fontWeight: "500"}}>
                                                                        Select all <span>{10}</span>
                                                                    </label>
                                                                </div>
                                                            <div className='HgtFilterBoxList'>
                                                                <div className="dataAccessListItem">
                                                                    <input type="checkbox" id='checkbox_id' />
                                                                    <label for='checkbox_id' className="AllTextwithValue" style={{marginBottom: "10px"}}>Alam</label>
                                                                </div>
                                                                <div className="dataAccessListItem">
                                                                    <input type="checkbox" id='checkbox_id' />
                                                                    <label for='checkbox_id' className="AllTextwithValue" style={{marginBottom: "10px"}} >Alam</label>
                                                                </div>
                                                                <div className="dataAccessListItem">
                                                                    <input type="checkbox" id='checkbox_id' />
                                                                    <label for='checkbox_id' className="AllTextwithValue" style={{marginBottom: "10px"}} >Alam</label>
                                                                </div>
                                                                <div className="dataAccessListItem">
                                                                    <input type="checkbox" id='checkbox_id' />
                                                                    <label for='checkbox_id' className="AllTextwithValue" style={{marginBottom: "10px"}} >Alam</label>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        </div>
                                                        <div className="NotificationTextBox mt-0">
                                                            <p className="SiteNameText mb-0" style={{color: "#212529"}}>
                                                            Site
                                                            </p>
                                                            
                                                            {/* <MultiSelect
                                                            // className="MessageTextBox1"
                                                            //placeholder="Select Site"
                                                            //   disabled={!isNotifFormEnabled}
                                                            // options={siteList}
                                                            //   options={siteListArray}
                                                            // value={siteSelected}
                                                            // onChange={setSiteSelected}
                                                            // disabled={!isAlertTemplateEdit}
                                                            //   labelledBy={"Select"}
                                                            // overrideStrings={{
                                                            //      selectSomeItems: `${siteList[siteList.length - 1].value}`
                                                            //     selectSomeItems: "Select site",
                                                            //     allItemsAreSelected: "All sites are selected"
                                                            // }}
                                                            // ArrowRenderer={() => 
                                                            // <DropArrow />
                                                            // }
                                                            /> */}

                                                        <div className='filterinnerboxdiv'>
                                                            <div>
                                                                <div className="SelectionListWithSelectAll">
                                                                    <input
                                                                        className="SearchViewInput1"
                                                                        type="search"
                                                                        placeholder="Search"
                                                                    />
                                                                    <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} />
                                                                </div>
                                                            </div>
                                                            <div className='HgtFilterBoxList'>
                                                                <div className='dataAccessListItem'>
                                                                    <input type="checkbox" />
                                                                    <p className="AllTextwithValue" style={{fontWeight: "500"}}>
                                                                        Select all <span>{10}</span>
                                                                    </p>
                                                                </div>
                                                                <div className="dataAccessListItem">
                                                                    <input type="checkbox" />
                                                                    <p className="AllTextwithValue">Alam</p>
                                                                </div>
                                                                <div className="dataAccessListItem">
                                                                    <input type="checkbox" />
                                                                    <p className="AllTextwithValue">Alam</p>
                                                                </div>
                                                                <div className="dataAccessListItem">
                                                                    <input type="checkbox" />
                                                                    <p className="AllTextwithValue">Alam</p>
                                                                </div>
                                                                <div className="dataAccessListItem">
                                                                    <input type="checkbox" />
                                                                    <p className="AllTextwithValue">Alam</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="grid-container filterContainer grid-container--fit mb-0">
                                                        <div className="NotificationTextBox">
                                                            <p className="SiteNameText mb-0" style={{color: "#212529"}}>
                                                            Device Type 
                                                            </p>
                                                            {/* <select
                                                            className="MessageTextBox1"
                                                            placeholder="Select Device type"
                                                            {...register("DeviceTypeId")}
                                                            defaultValue={selectedTemplate.DeviceTypeId}
                                                            onChange={e => {
                                                                OnDeviceTypeChanges(e)
                                                            }}
                                                            disabled={!isAlertTemplateEdit}
                                                            >
                                                            <option key="0" disabled={false} value="0">
                                                                Select Device type
                                                            </option>
                                                            {
                                                                devicesmasterlist &&
                                                                devicesmasterlist.deviceTypeList &&
                                                                devicesmasterlist.deviceTypeList.map(devtype =>
                                                                    <option
                                                                        value={devtype.deviceTypeID}
                                                                        key={devtype.deviceTypeID}>
                                                                        {devtype.deviceType}
                                                                    </option>)
                                                            }
                                                            </select> */}

                                                            <div className='filterinnerboxdiv'>
                                                                <div>
                                                                    <div className="SelectionListWithSelectAll">
                                                                        <input
                                                                            className="SearchViewInput1"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                        />
                                                                        <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} />
                                                                    </div>
                                                                </div>
                                                                <div className='HgtFilterBoxList'>
                                                                    <div className='dataAccessListItem'>
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue" style={{fontWeight: "500"}}>
                                                                            Select all <span>{10}</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="dataAccessListItem">
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue">Alam</p>
                                                                    </div>
                                                                    <div className="dataAccessListItem">
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue">Alam</p>
                                                                    </div>
                                                                    <div className="dataAccessListItem">
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue">Alam</p>
                                                                    </div>
                                                                    <div className="dataAccessListItem">
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue">Alam</p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="NotificationTextBox">
                                                            <p className="SiteNameText mb-0" style={{color: "#212529"}}>
                                                                Alert Type 
                                                            </p>
                                                            {/* <select
                                                                className="MessageTextBox1"
                                                                placeholder="Select Device type"
                                                                {...register("AlertCodeId")}
                                                                defaultValue={selectedTemplate.AlertCodeId}
                                                                onChange={e => {setValue('AlertCodeId', e.target.value)}}
                                                                disabled={!isAlertTemplateEdit}
                                                                >
                                                                <option key="0" disabled={false} value="0">
                                                                    Select alert type
                                                                </option>
                                                                {
                                                                    devicesmasterlist &&
                                                                    devicesmasterlist.alertCodeList &&
                                                                    devicesmasterlist.alertCodeList.filter(x => x.deviceTypeId == devicetypeid).map(alertCode =>
                                                                        <option
                                                                            value={alertCode.alertCodeId}
                                                                            key={alertCode.alertCodeId}>
                                                                            {alertCode.combinedCode}
                                                                        </option>)
                                                                    }
                                                                </select> */}

                                                            <div className='filterinnerboxdiv'>
                                                                <div>
                                                                    <div className="SelectionListWithSelectAll">
                                                                        <input
                                                                            className="SearchViewInput1"
                                                                            type="search"
                                                                            placeholder="Search"
                                                                        />
                                                                        <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} />
                                                                    </div>
                                                                </div>
                                                                <div className='HgtFilterBoxList'>
                                                                    <div className='dataAccessListItem'>
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue" style={{fontWeight: "500"}}>
                                                                            Select all <span>{10}</span>
                                                                        </p>
                                                                    </div>
                                                                    <div className="dataAccessListItem">
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue">Alam</p>
                                                                    </div>
                                                                    <div className="dataAccessListItem">
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue">Alam</p>
                                                                    </div>
                                                                    <div className="dataAccessListItem">
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue">Alam</p>
                                                                    </div>
                                                                    <div className="dataAccessListItem">
                                                                        <input type="checkbox" />
                                                                        <p className="AllTextwithValue">Alam</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                </div>
                                                <div style={{textAlign: 'center', display: "flex", justifyContent: "center"}} className='filterbuttonsdiv'>
                                                                        <div className='DataAccessEdit' style={{width: "100px"}}>
                                                                            <span className='ViewUpdate'>Apply</span>
                                                                        </div>
                                                                        <div className='DataAccessEdit' style={{width: "100px"}}>
                                                                            <span className='ViewUpdate'>Reset</span>
                                                                        </div>
                                                </div>
                                    </div>
                                </Modal.Body>
                                </Modal>
                            </div>
                                <div className="AlertTemplateListDiv">
                                    {
                                        alertTemplateList && (
                                            alertTemplateList?.length > 0 ?
                                                alertTemplateList.map((template) => {
                                                    console.log("alertTemplateList", alertTemplateList);
                                                    return (
                                                        <div
                                                            className={selTempDevice?.Id === template.Id ? "OneList SelectedList" : "OneList"}
                                                            key={template.Id}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                deviceTempSelected(template.Id, template);
                                                                //getAlertTemplateByDeviceId(template.Id);
                                                                //deviceTempSelected(template.Id)
                                                                // setSelTempDevice(template);
                                                            }}
                                                        >
                                                            <p className="ListofText">
                                                                {template.Name}
                                                                <span className="SiteNameText"> - {template.Tag}</span>
                                                            </p>
                                                        </div>
                                                    );
                                                }) : 
                                                <div>
                                                    <div className="no-data-found"><p>No Devices Found.</p></div>
                                                </div>
                                        )
                                    }
                                {!alertTemplateList && 
                                <div className="no-data-found">No Devices Available.</div>
                                }
                            </div>
                            {
                                totalPagesTwo === 0 || totalPagesTwo <= limitTwo
                                ? null
                                :
                                <div className="PaginationDiv mb-2 mt-2 mr-2">
                                                <h5 className='PageNumbers'>
                                                    {
                                                        totalPagesTwo == 0 ? 0
                                                            :
                                                            (currentPageNoTwo - 1) * parseInt(limitTwo) + parseInt(1)} - {(currentPageNoTwo * limitTwo) > (totalPagesTwo)
                                                                ? (totalPagesTwo)
                                                                : currentPageNoTwo * limitTwo}
                                                </h5>
                                                <span>of</span>
                                                <h5 className='PageNumbers'>{totalPagesTwo}</h5>                                <Pagination>
                                    <Pagination.Prev onClick={prevClickTwo} disabled={currentPageNoTwo == 1}/>
                                    {
                                            PaginationComponentTwo(currentPageNoTwo)
                                    }
                                    <Pagination.Next onClick={nextClickTwo} disabled={currentPageNoTwo == maxPageAllowedTwo}/>
                                </Pagination>
                            </div>
                            }

                        </div>
                    </Tab>
                </Tabs>
            </div>
          </div>
          <div className="SiteRightContainer">
            <div style={{display: devTemClicked || activityLogShow ? "none" : "block"}}>           
                <form style={{height: "auto"}}>
                <div className='ml50 mt20' style={{float: "left", display: deviceTemplateList.length > 0 ? "block" : "none"}}>
                    <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                        Go to back template list
                        </Tooltip>
                    }
                    >
                    <button className="DataAccessEdit"
                        style={{float: "left"}}
                        onClick={(e) => {
                        e.preventDefault();
                        setDevTemClicked(true)
                        setDisplayButtons(false);
                        }} >
                        <img src={BackArrowDevice} alt="back arrow icon" className="EditedIcon" />
                    </button>
                    </OverlayTrigger>
                    <p className='templatedevicename'>{selTempDevice?.Name}</p>
                </div>
                        <div className="AlertActivity-form TemplateHgtDiv" style={{ padding: "0px 50px 0px 50px", width: "100%" }}>
                            <div className="NotificationTextBox">
                                <p className="SiteNameText">
                                    Template Name*
                                </p>
                                <input
                                    className="MessageTextBox"
                                    placeholder="Enter alert template name here"
                                    {...register("TemplateName")}
                                    defaultValue={selectedTemplate.TemplateName}
                                    // value={alertTemplateObj.templateName}
                                    // disabled={isStore || !isAlertTemplateEdit}
                                    onKeyPress={onSpecialCharacter}
                                    disabled={deviceTemplateList.length > 0 || isStore || !isAlertTemplateEdit}
                                //disabled={setIsDisabled}
                                />
                            </div>

                            <div className="NotificationTextBox">
                                <p className="SiteNameText">
                                    Device Type*
                                </p>
                                <select
                                    className="MessageTextBox1"
                                    placeholder="Select Device type"
                                    {...register("DeviceTypeId")}
                                    defaultValue={selectedTemplate.DeviceTypeId}
                                    onChange={e => {
                                        OnDeviceTypeChanges(e)
                                    }}
                                    disabled={deviceTemplateList.length > 0 || isStore || !isAlertTemplateEdit}
                                >
                                    <option key="0" disabled={false} value="0">
                                        Select Device type
                                    </option>
                                    {
                                        devicesmasterlist &&
                                        devicesmasterlist.deviceTypeList &&
                                        devicesmasterlist.deviceTypeList.map(devtype =>
                                            <option
                                                value={devtype.deviceTypeID}
                                                key={devtype.deviceTypeID}>
                                                {devtype.deviceType}
                                            </option>)
                                    }
                                </select>
                            </div>

                            <div className="NotificationTextBox mb-0">
                                <p className="SiteNameText">
                                    Alert Type*
                                </p>
                                <select
                                    className="MessageTextBox1"
                                    placeholder="Select Device type"
                                    {...register("AlertCodeId")}
                                    defaultValue={selectedTemplate.AlertCodeId}
                                    onChange={e => { setValue('AlertCodeId', e.target.value) }}
                                    disabled={deviceTemplateList.length > 0 || isStore || !isAlertTemplateEdit}
                                >
                                    <option key="0" disabled={false} value="0">
                                        Select alert type
                                    </option>
                                    {
                                        devicesmasterlist &&
                                        devicesmasterlist.alertCodeList &&
                                        devicesmasterlist.alertCodeList.filter(x => x.deviceTypeId == devicetypeid).map(alertCode =>
                                            <option
                                                value={alertCode.alertCodeId}
                                                key={alertCode.alertCodeId}>
                                                {alertCode.combinedCode}
                                            </option>)
                                    }
                                </select>
                                {/* <input
                        className="MessageTextBox"
                        placeholder="Enter notification name here"
                        value={notificationName}
                        onChange={(e) => setNotificationName(e.target.value)}
                        disabled={!isNotifFormEnabled}
                    /> */}
                            </div>

                            <div className="NotificationTextBox mb-0">
                                <p className="SiteNameText">
                                    Customer Name*
                                </p>
                                <select
                                    className="MessageTextBox1"
                                    placeholder="Select Device type"
                                    {...register("ClientId")}
                                    defaultValue={selectedTemplate.ClientId}
                                    onChange={e => {
                                        setValue('ClientId', e.target.value)
                                        setDeviceList([])
                                    }}
                                    disabled={deviceTemplateList.length > 0 || isStore || !isAlertTemplateEdit}
                                //disabled={setIsDisabled}
                                >
                                    <option key="0" disabled={false} value="0">
                                        Select Customer
                                    </option>
                                    {/* {
                            devicesmasterlist &&
                            devicesmasterlist.clientList &&
                            devicesmasterlist.clientList.map(client =>
                                <option
                                    value={client.clientId}
                                    key={client.clientId}>
                                    {client.clientName}
                                </option>)
                            } */}
                                    {
                                        customerData.map((item) => (
                                            <option
                                                value={item.CustomerId}
                                                key={item.CustomerId}
                                            >
                                                {item.CustomerName}
                                            </option>
                                        ))
                                    }
                                </select>
                            </div>

                    {/* <span className="Error_P">Enter valid Name</span> */}
                    <div className="grid-container Notificationgrid-container grid-container--fit mb-0">
                                <div className="NotificationTextBox">
                                    <p className="SiteNameText">
                                        Site*
                                    </p>
                                    {/*   <select
                        className="MessageTextBox1"
                        placeholder="Select Site"
                        disabled={!isAlertTemplateEdit}
                        {...register("SiteId")}
                        defaultValue={selectedTemplate.SiteId}
                        onChange={e => setValue('SiteId', e.target.value)}

                        >
                        <option key="0" disabled={false} value="">
                            
                            Select Site
                        </option>
                        <option></option>
                        </select> */}

                                    <MultiSelect
                                        // className="MessageTextBox1"
                                        //placeholder="Select Site"
                                        //   disabled={!isNotifFormEnabled}
                                        options={siteList}
                                        //   options={siteListArray}
                                        value={siteSelected}
                                        onChange={setSiteSelected}
                                        disabled={!isAlertTemplateEdit}
                                        //   labelledBy={"Select"}
                                        overrideStrings={{
                                            //   // selectSomeItems: `${siteList[siteList.length - 1].value}`
                                            selectSomeItems: "Select site",
                                            allItemsAreSelected: "All sites are selected"
                                        }}
                                        ArrowRenderer={() =>
                                            <DropArrow />
                                        }
                                    />

                                </div>

                                <div className="NotificationTextBox">
                                    <p className="SiteNameText">
                                        Device*
                                    </p>
                    {/*  <select
                        className="MessageTextBox1"
                        placeholder="Select Device type"
                        {...register("DeviceId")}
                        defaultValue={selectedTemplate.DeviceId}
                        onChange={e => setValue('DeviceId', e.target.value)}
                        disabled={!isAlertTemplateEdit}
                        >
                        <option key="0" disabled={false} value="">
                            Select Device
                        </option>
                        <option></option>
                        </select> */}

                        <MultiSelect
                        // className="MessageTextBox1"
                            placeholder="Select Device"
                            //   disabled={!isNotifFormEnabled}
                            options={deviceList}
                            value={deviceSelected}
                            disabled={!isAlertTemplateEdit}
                            onChange={setDeviceSelected}
                            //   labelledBy={"Select"}
                            overrideStrings={{
                                //   // selectSomeItems: `${siteList[siteList.length - 1].value}`
                                selectSomeItems: "Select Device",
                                allItemsAreSelected: "All devices are selected"
                            }}
                            ArrowRenderer={() => 
                            <DropArrow />
                            }
                        />
                        
                    </div>

                                {issensor &&
                                    <div className="NotificationTextBox">
                                        <p className="SiteNameText">
                                            Sensor*
                                        </p>
                                        <select
                                            className="MessageTextBox1"
                                            placeholder="Select Sensor"
                                            {...register("IDSPanelZoneTypeId")}
                                            defaultValue={selectedTemplate.IDSPanelZoneTypeId}
                                            onChange={e => {
                                                setValue('IDSPanelZoneTypeId', e.target.value)
                                            }}
                                            disabled={isStore || !isAlertTemplateEdit}
                                        >
                                            <option key="0" disabled={false} value="0">
                                                Select Sensor
                                            </option>
                                            {/* {
                                                devicesmasterlist &&
                                                devicesmasterlist.alertTempZoneTypeList &&
                                                devicesmasterlist.alertTempZoneTypeList.map(zone =>
                                                    <option
                                                        value={zone.iDSPanelZoneTypeID}
                                                        key={zone.iDSPanelZoneTypeID}>
                                                        {zone.zoneType}
                                                    </option>)
                                            } */}
                                            {
                                                zoneList.map(zone => 
                                                    <option
                                                    value={zone.iDSPanelZoneTypeID}
                                                    key={zone.iDSPanelZoneTypeID}>
                                                        {zone.zoneType}
                                                    </option>    
                                                )
                                            }
                                        </select>

                                    </div>
                                }
                          
                    </div>

                   

                    {/* <div className="NotificationTextBox">
                    <p className="SiteNameText">
                        Workflow 
                    </p>
                    <select
                        className="MessageTextBox1"
                        placeholder="Select Device type"
                        {...register("WorkflowId")}
                        defaultValue={selectedTemplate.WorkflowId}
                        onChange={e => {setValue('WorkflowId', e.target.value)}}
                        disabled={!isAlertTemplateEdit}
                        >
                        <option key="0" disabled={false} value="0">
                            Select workflow
                        </option>
                        {
                            workflows.map((item) => (
                                <option
                                    value={item.workflowID}
                                    key={item.workflowID}
                                >
                                    {item.workflowName}
                                </option>
                            ))
                        }
                        {/* {
                            devicesmasterlist &&
                            devicesmasterlist.alertCodeList &&
                            devicesmasterlist.alertCodeList.filter(x => x.deviceTypeId == devicetypeid).map(alertCode =>
                                <option
                                    value={alertCode.alertCodeId}
                                    key={alertCode.alertCodeId}>
                                    {alertCode.combinedCode}
                                </option>)
                            } 
                    </select>
                    </div> 

                {/*  <div className="grid-container Notificationgrid-container grid-container--fit mb-0">
                        <div className="NotificationTextBox">
                        <p className="SiteNameText">
                        Severity  
                        </p>
                            <div className="MessageTextBox1" style={{padding: "10px"}}>
                                {severities &&
                                    severities.map(item => {
                                        return <div key={item.SeverityId} className="form-check-inline">
                                            <>
                                                <input
                                                    style={{ left: "0", position: "relative" }}
                                                    type="radio"
                                                    name="SeverityId"
                                                    value={item.SeverityId}
                                                    checked={selectedSeverity == item.SeverityId}
                                                    onChange={e => {
                                                        setValue('SeverityId', e.currentTarget.value)
                                                        setSelectedSeverity(e.currentTarget.value)
                                                    }}
                                                    disabled={!isAlertTemplateEdit} />{" "}
                                                <span className="ml-2">{` ${item.SeverityTitle}`}</span>
                                            </>
                                        </div>
                                })}
                            </div>
                        </div>
                    </div>
                    */}
                    
                    <Scheduler 
                        isAlertTemplateEdit={isAlertTemplateEdit}
                        selectedItem={selectedItem}
                        setSelectedItem={setSelectedItem}
                        workflows={workflows}
                        severities={severities}
                        customData={customData}
                        setCustomData={setCustomData}
                        sevenDaysData={sevenDaysData}
                        setSevenDaysData={setSevenDaysData}
                        gettingData={gettingData}
                        setGettingData={setGettingData}
                        selectedSiteTab={selectedSiteTab}
                        setSelectedSiteTab={setSelectedSiteTab}
                        dateCustom={dateCustom}
                        setDateCustom={setDateCustom}
                        activityLogShow={activityLogShow}
                        setActivityLogShow={setActivityLogShow}
                        alertTemplateObj={alertTemplateObj}
                        isStore={isStore}
                    />
                    
                </div>
                </form>
            </div>
            {
                deviceTemplateList.length > 0 && devTemClicked && !activityLogShow
                ? <AlertTemplateList list={deviceTemplateList} getAlertTemplateById={getAlertTemplateById} setDisplayButtons={setDisplayButtons} isDarkTheme={isDarkTheme} />
                : null
            }    
            {
                activityLogShow
                ?
                <ActivityLogComponent 
                    isAlertTemplateEdit={isAlertTemplateEdit}
                    sevenDaysData={sevenDaysData}
                    setSevenDaysData={setSevenDaysData}
                    workflows={workflows}
                    severities={severities}
                    gettingData={gettingData}
                    setGettingData={setGettingData}
                    selTempDevice={selTempDevice}
                    activityLogShow={activityLogShow}
                    setActivityLogShow={setActivityLogShow}
                    alertTemplateObj={alertTemplateObj}
                    // selectedItem={selectedItem}
                    // setSelectedItem={setSelectedItem}
                    // customData={customData}
                    // setCustomData={setCustomData}
                    // selectedSiteTab={selectedSiteTab}
                    // setSelectedSiteTab={setSelectedSiteTab}
                    // dateCustom={dateCustom}
                    // setDateCustom={setDateCustom}
                    // setActivityLogShow={setActivityLogShow}
                    // alertTemplateObj={alertTemplateObj}
                />
                : null
            }        
          </div>          
          {/* <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          /> */}
        </div>
      {/* </div> */}
    </>
  );
})

// })

const StartWorkflow = ({
    history
}) => {
    return (
        <div className="LeftAddSide">
            <div className="AddSite">
                <h1 className="MainHeaderName">2.  Workflow</h1>
                <div>
                    <div className="col wf-icon">
                        <img src={WFIconAlert} alt="workflow" />
                    </div>

                    <button className="start-wf-btn" onClick={() => history.push('/workflow/0')}>Start Workflow Builder</button>
                </div>
            </div>
        </div>)
}