import React, { useEffect, useState,useContext, useRef, Suspense, lazy } from 'react'
import { getAlertList } from "../../services/index";
import Sidebar from "../../components/Sidebar/Sidebar";
import Topbar from "../../components//Topbar/Topbar";
import { Switch, useLocation , useHistory, useRouteMatch,Redirect } from 'react-router-dom';
import PrivateRoute from '../../navigation/Auth/PrivateRoute';
import {
    ALERTS, DASHBOARD, CONFIGURATION, ALLALERTS, MYALERTS, ALERTDETAILS, ALLNOTIFICATIONS,
    WORKFLOW, ROOT, REPORTS, ALERTACTIVITYREPORT, ALERTARMDISARMREPORT, PROFILE, CAMERAS, MOSAIC,
    CHATFLOW, ALERTREPORT, PLAYER, CAMERA_SINGLE_PLAYBACK, HEALTHMONITORING, DISPLAYSITE, NOTIFICATON, EVENTACTIVITYREPORT, PAGE, DOWNLOAD, HOMEPAGE
} from '../../navigation/CONSTANTS';
import AlertsContainer from '../Alerts/AlertsContainer'
import AllAlerts from '../Alerts/AllAlerts'
import MyAlerts from '../Alerts/MyAlerts'
import AlertDetails from '../Alerts/AlertDetails'
import DashboardContainer from '../Dashboard/DashboardContainer'
import ConfigurationContainer from '../Configuration/ConfigurationContainer'
import AllNotificationContainer from '../Notification/Allnotificationspage'
// import './rootcontainer.css'
import WorkflowContainer from '../Workflow/WorkflowContainer';
import AlertReportsContainer from '../Reports/AlertReportsContainer'
import AlertActivityReport from '../Reports/AlertActivityReport';
import AlertArmDisarmReport from '../Reports/AlertArmDisarmReport';
import CameraViewPage from '../Cameras/CameraViewPage'
import ProfileContainer from '../Profile/ProfileContainer';
import { GetUserProfileInfo, GetUserProfileInfoDotNet } from '../../services/userManagementservice';
import ChatflowPageContainer from '../Chatflow/ChatflowPageContainer'
import AlertReportContainer from '../Alerts/AlertReportContainer'
import ChatbotContainer from '../ChatbotWidget/ChatbotContainer';
import { useAuth } from '../../navigation/Auth/ProvideAuth';
import { GetAlertDetails } from '../../utils/ChatbotUtil'
import { NoAccessPage } from '../../navigation/NoAccess';

import { CheckIndexedDBSupported , AddAlertRecord , DeleteAlertRecord , GetAllAlertRecord , GetAlertNotAckRecord ,
     DeleteChatWindowOpenedTime , AddChatWindowOpenedTime } from '../../utils/IndexedDBStorageUtil'
import { conversationtemplatecrud } from "../../services/chatFlowService";
import { ConvertDate } from '../../utils/utils'
import Player from '../Player/Player';
import ReactDOM from 'react-dom'
import Swal from 'sweetalert2'
import { useProfile } from '../../CustomHooks/useProfile';
import SinglePlayback from '../Cameras/SinglePlayback';
import DrawerProvider, { DrawerContext } from '../../Context/DrawerContext';
import {ALERT_INTERVAL_TIMER, ASSOC_DIRECT_OPEN, HIDE_STUFF} from "../../config"
import HealthMonitoring from '../HealthMonitoring/HealthMonitoring';
import HealthDisplaySite from '../HealthMonitoring/HealthDisplaySite';
import { EventActivityReport } from '../Reports/EventActivityReport';
import { LogoutDotNet } from '../../services/userAuthServices';
import CustomUrlPage from '../Dashboard/CustomUrlPage';
import DownloadHistory from '../DownloadHistory/DownloadHistory';
import ChatbotAssociateCamera from '../ChatbotWidget/ChatbotAssociateCamera';
// import NotificationContainer from '../NotificationMenu/NotificationContainer';

export default function RootContainer() {

    const [alertList, setAlertList] = useState(null)
    const [todaysAlertList, setTodaysAlertList] = useState(null)
    const [rootLoading, setRootLoading] = useState(false);
    const [alertcounts,setalertcounts]=useState({
        "TotalOpen": 0,
        "Extreme": 0,
        "High": 0,
        "Moderate": 0,
        "Low": 0,
        "TotalClosed": 0,
        "ExtremeClosed": 0,
        "HighClosed": 0,
        "ModerateClosed": 0,
        "LowClosed": 0,
        "TodaysAlerts":0
        })

    // const [profileData, setProfileData] = useState(null)
    const [selectedTab, setSelectedTab] = useState('dashboard')
    const [selectedSubMenu, setSelectedSubMenu] = useState(null)
    const [isSuperUser, setIsSuperUser] = useState(false);
    const {profile, getprofiledata } = useProfile();
    const [uniqueId, setUniqueId] = useState('');
    const [isDarkTheme, setIsDarkTheme] = useState(true);
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))

    let currentUser = useAuth()

    let location = useLocation()
    const { path } = useRouteMatch();

    let history = useHistory()
    const [cctvTypeName, setCctvTypeName] = useState('');
    const [camUserName, setCamUserName] = useState('');
    const [camPassword, setCamPassword] = useState('');
    const [brandName, setBrandName] = useState('');
    const [hootWay, setHootWay] = useState([]);
    // const [hootWay, setHootWay] = useState([], () => {
    //     const localData = localStorage.getItem('hootWayData');
    //     return localData ? JSON.parse(localData) : [];
    // });

    const [openButton1, setOpenButton1] = useState(false);
    const [activeCam, setActiveCam] = useState(null);
    const [alertDetail, setAlertDetail] = useState([]);

    useEffect(() => {
        let arr = JSON.parse(localStorage.getItem('hootWayData'));
        if(arr == null){
            setHootWay([]);
        }else{
            setHootWay(arr);
        }
    }, []);

    let hootRef = useRef(true);
    useEffect(() => {
        if(!hootRef.current){
            let arr = hootWay;
            localStorage.setItem('hootWayData', JSON.stringify(arr));    
        }
        return () => {
            hootRef.current = false;
        }
    },[hootWay]);

    const [alertSearch, setAlertSearch] = useState(false);
    const [activeSeverity, setActiveSeverity] = useState("all");

    // }

    // useEffect(() => {
    //     if(localStorage.getItem('sessionExpired') == "true"){
    //         LogoutDotNet().then((res) => {
    //             localStorage.removeItem('authToken');
    //             localStorage.removeItem('authCode');
    //             localStorage.removeItem('user');
    //             localStorage.removeItem('userPassword');
    //             localStorage.removeItem('userID');
    //             localStorage.removeItem('UserID');
    //             localStorage.removeItem('sessionExpired');
    //             sessionStorage.removeItem('Page_Refresh_State');
    //             history.push('/login');
    //         })   
    //     }
    // }, []);

    useEffect(() => {
        setUniqueId(LoggedInUser?.UserUniqueID);
        setIsSuperUser(LoggedInUser?.UserRoleID == 1 || LoggedInUser?.UserRoleID == 2)
    },[LoggedInUser]);

    // useEffect(async () => {
    //     await getprofiledata()
    //     if (location.pathname === "/allalerts") {
    //         await Getopenedchats()
    //     }
    //     if (LoggedInUser && LoggedInUser.UserRoleID == 3) {
    //     await Getnotackalertslst()
    //     }
        
    // }, [])

    useEffect(() => {
        (async () => {
            await getprofiledata()
            // if (location.pathname === "/allalerts") {
                await Getopenedchats()
            // }
            if (LoggedInUser && LoggedInUser.UserRoleID == 3) {
            await Getnotackalertslst()
            }
         })();
       
         }, [])
    //For dashboard alert docker and todays alert count
    useEffect( () => {
        const interval = setInterval(async () => {
            if (isSuperUser && uniqueId) { //tej 17022022 alert docker get refreshed after every 1 min if the current logged in user is root or admin
                // if (location.pathname === "/dashboard" || location.pathname === '/') {
                    await GetAlertCount()
                    await GetTodaysAlertList()
                // }  
            }
            else{
                // await 
                await GetAlertCount()
                await GetTodaysAlertList()
            }
        }, ALERT_INTERVAL_TIMER);
        return () => {
            clearInterval(interval);
        } 
      }, [uniqueId]);


     //For All alert docker 
    useEffect(() => {
        const interval = setInterval(async () => {
            if (isSuperUser) { //tej 17022022 alert docker get refreshed after every 1 min if the current logged in user is root or admin
                if (location && location.pathname && (location.pathname === "/allalerts") && !alertSearch) {
                    await GetAlertList()
                }
            }else{
                await GetAlertList();
            }
        }, ALERT_INTERVAL_TIMER);
        return () => {
            clearInterval(interval);
        }    
    }, [uniqueId, location, alertSearch, activeSeverity]);

    useEffect(() => {
        // setRootLoading(true);
        GetTodaysAlertList();
        GetAlertCount();
    }, [])

    // useEffect(() => {
    //  if(LoggedInUser && LoggedInUser.UserUniqueID){
    //     <Redirect from="/login" to="/dashboard" />
    // }
    // }, [])
    
    const [childWindow, setChildWindow] = useState(null);
    const [counter, setCounter] = useState(0);
    
    useEffect(() => {
        if(sessionStorage.getItem('mosaicWindowKey') == "mosaicWindow"){
            let interval = setInterval(() => {
                if(childWindow?.closed){
                    sessionStorage.removeItem('mosaicWindowKey');
                }
            }, 2000);
    
            return () => {
                clearInterval(interval);
            }
    
        }
    }, [childWindow, sessionStorage.getItem('mosaicWindowKey')]);


    useEffect(() => {
        // Listen for the beforeunload event in the parent window
        const beforeUnloadListener = () => {
          if (childWindow) {
            sessionStorage.removeItem("mosaicWindowKey")
            childWindow.close();
          }
        };
    
        window.addEventListener('beforeunload', beforeUnloadListener);
        return () => {
            // Cleanup: Remove the event listener when the parent component unmounts
            window.removeEventListener('beforeunload', beforeUnloadListener);
          };

        }, [childWindow]);
   
        /* useEffect(() => {
            // Function to check for userID in local storage
            const checkUserIDInLocalStorage = () => {
              const UserID = localStorage.getItem('UserID');
              if (!UserID) {
                setIsLoggedIn(false);
              }
            };
        
            // Run the check on the specified timer interval (e.g., every 10 seconds)
            const timerInterval = 15000; // 10 seconds in milliseconds
            const timer = setInterval(checkUserIDInLocalStorage, timerInterval);
        
            // Initial check when the component mounts
            checkUserIDInLocalStorage();
        
            // Cleanup the interval when the component unmounts
            return () => {
              clearInterval(timer);
            };
          }, []); */

       /*  useEffect(()=>{
            function closeChildWindow(){
                const UserID = localStorage.getItem('UserID');
                localStorage.removeItem("mosaicWindowKey")
                if (childWindow) {
                    childWindow.close();
                  }
            }
        if(isLoggedIn === false){
            closeChildWindow()
        }
        },[setChildWindow, isLoggedIn, childWindow]) */

        useEffect(() => {
            let intervalId
            if(childWindow){
                 intervalId = setInterval(() => {
                  setCounter(prevCounter => prevCounter + 1);
                  if(sessionStorage.getItem('mosaicWindowKey') == null || sessionStorage.getItem('mosaicWindowKey') === undefined || sessionStorage.getItem('mosaicWindowKey') === ''){
                    childWindow.close();
                }
                }, 100); // 100 milliseconds (0.1 seconds)
            }
        
            return () => {
              clearInterval(intervalId); // Clean up the interval on unmount
            }
          }, [childWindow]); 

    const GetAlertCount = async () => {
        try{
            
            var obj={
                "action":"alertCount",
                "userUniqueId":LoggedInUser?.UserUniqueID,
                "authCode":localStorage.getItem('authCode')
            }
            await getAlertList(obj).then((resp) => {
                if (resp && resp.status == "Ok") {
                    if (resp.message) {
                        setalertcounts(resp.message[0])
                        // setRootLoading(false);
                    }
                }else{
                    // alert(resp.message)
                    if(resp.message == "Unauthorized"){
                        // sessionStorage.removeItem('mosaicWindowKey')
                        if(localStorage.getItem('sessionExpired') == "true"){
                            localStorage.removeItem('sessionExpired');
                            sessionStorage.removeItem('mosaicWindowKey')
                            return;
                        }else{
                        localStorage.setItem('sessionExpired', true)
                        sessionStorage.removeItem('mosaicWindowKey')
                        Swal.fire({
                            text: 'Logged in from another machine, this session is expired you will be logged out from here.',
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            timer: 5100,
                            timerProgressBar: true  
                        }).then((result) => {
                            if(result.isConfirmed){
                                LogoutDotNet().then((res) => {
                                    history.push('/login');
                                    localStorage.removeItem('authToken');
                                    localStorage.removeItem('authCode');
                                    localStorage.removeItem('user');
                                    localStorage.removeItem('userPassword');
                                    localStorage.removeItem('userID');
                                    localStorage.removeItem('hootWayData');
                                    localStorage.removeItem('UserID');
                                    localStorage.removeItem('sessionExpired');
                                    sessionStorage.removeItem('Page_Refresh_State');
                                    sessionStorage.removeItem('mosaicWindowKey')
                                    childWindow.close();
                                })
                            }
                        }).catch(err => console.log("catcherr", err))
                        setTimeout(() => {
                            Swal.clickConfirm();
                            // let authCode = localStorage.getItem('authCode');
                            // if(authCode != null){
                            //     LogoutDotNet().then((res) => {
                            //     history.push('/login');
                            //     localStorage.removeItem('authToken');
                            //     localStorage.removeItem('authCode');
                            //     localStorage.removeItem('user');
                            //     localStorage.removeItem('userPassword');
                            //     localStorage.removeItem('hootWayData');
                            //     localStorage.removeItem('userID');
                            //     localStorage.removeItem('UserID');
                            //     localStorage.removeItem('sessionExpired');
                            //     sessionStorage.removeItem('Page_Refresh_State');
                            //     childWindow.close();
                            // })
                            // }else{
                            //     history.push('/login');
                            //     localStorage.removeItem('authToken');
                            //     localStorage.removeItem('authCode');
                            //     localStorage.removeItem('user');
                            //     localStorage.removeItem('userPassword');
                            //     localStorage.removeItem('userID');
                            //     localStorage.removeItem('hootWayData');
                            //     localStorage.removeItem('UserID');
                            //     localStorage.removeItem('sessionExpired');
                            //     sessionStorage.removeItem('Page_Refresh_State');
                            //     childWindow.close();                               
                            // }
                        }, 5000);
                        }
                    }
                }
            })
        }catch(error){
            console.error(error)
            // setRootLoading(false);
        }
    }

    const GetAlertList = async () => {
        try{
            var obj={
                "action":"getAlertList",
                "severity":activeSeverity,
                "userUniqueId":LoggedInUser?.UserUniqueID,
                "startIndex":0,
                "numberOfElements":50,
                "authCode":localStorage.getItem('authCode')
            }
            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        setAlertList(resp.message)
                        // setRootLoading(false);
                    }
                }
            })
        }catch(error){
            console.error(error)
            // setRootLoading(false);
        }
    }

    const GetTodaysAlertList = async () => {
        setRootLoading(true);
        try{
            var obj={
                "action":"getTodaysAlertsList",
                "userUniqueId":LoggedInUser?.UserUniqueID,
                "startIndex":0,
                "numberOfElements":50,
                "authCode":localStorage.getItem('authCode')
            }
            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        setTodaysAlertList(resp.message)
                        setRootLoading(false);
                    }
                }
            })
        }catch(error){
            console.error(error)
            setRootLoading(false);
        }
    }

    let alertnotacktime = 2
    const EscallateAlert = async (id) => {
        try{
            var obj={
                "action": "escalateAlert",
                "alertId": id,
                "userId": LoggedInUser.UserID,
                "datetime": ConvertDate(new Date())
            }
            await conversationtemplatecrud(obj).then((resp) => {
                if (resp.status == "Ok") {
                    onCloseAlertChat(id)
                    DeleteChatWindowOpenedTime(id)
                    GetAlertList()
                }else{
                    alert(resp.message)
                }
            })
        }catch(error){
            console.error(error)
        }
    }
    const Getopenedchats = async () => {
        try{
            var res = await GetAllAlertRecord()
            res.onsuccess = function(event) {
                if(event.target.result.length > 0 ){
                    let arr = event.target.result.map((item) => {
                        let obj = {
                            ...item,
                            isOpen: false
                        }
                        return obj;
                    })
                    setChatHeads(arr)
                    // setChatHeads(event.target.result)
                }
            };
        }catch(error){
            console.error(error)
        }
    }
    const Getnotackalertslst = async () => {
        try{
            var res = await GetAlertNotAckRecord()
            res.onsuccess = function(event) {
                if(event.target.result != []){
                    event.target.result.map((item) => {
                        var sec = parseInt((new Date() - new Date(item.alertWindowOpenTime))/1000)
                        var min = parseInt(sec/60 )
                        if(min > alertnotacktime){
                            var fulltime = alertnotacktime + alertnotacktime
                            if(min > fulltime){
                                //alert("AlertId_"+item.alertId+" - Escallate api calling!")
                                EscallateAlert(item.alertId)
                            }else{
                                var lefttime = fulltime - min
                                var escallateimer = setTimeout(() => {
                                    //alert("AlertId_"+item.alertId+" - Escallate api calling!")
                                    clearTimeout(escallateimer)
                                    //DeleteChatWindowOpenedTime(item.alertId)
                                    EscallateAlert(item.alertId)
                                }, lefttime * 60 * 1000)
                                //AddEscallationTimerList(item.alertId,null,escallateimer)
                                AddChatWindowOpenedTime(item.alertId,null,escallateimer)
                            }
                        }else{
                            var lefttime = alertnotacktime - min
                            var notifytimer = setTimeout(() => {
                                var escallateimer = setTimeout(() => {
                                    //alert("AlertId_"+item.alertId+" - Escallate api calling!")
                                    
                                    clearTimeout(escallateimer)
                                    EscallateAlert(item.alertId)
                                    //DeleteChatWindowOpenedTime(item.alertId)
                                }, alertnotacktime * 60 * 1000)
                                //AddEscallationTimerList(item.alertId,null,escallateimer)
                                AddChatWindowOpenedTime(item.alertId,null,escallateimer)
                                alert("AlertId_"+item.alertId+" - Please acknowledge your alert, if you don't this alert will get reassigned.")
                                clearTimeout(notifytimer)
                            }, lefttime * 60 * 1000)
                            //AddEscallationTimerList(item.alertId,notifytimer,null)
                            AddChatWindowOpenedTime(item.alertId,notifytimer,null)
                        }
                    })
                }
            };
        }catch(error){
            console.error(error)
        }
    }
    
    const Setalertnotacktimeout = (id) => {
        try{
            var notifytimer = setTimeout(() => {
                var escallateimer = setTimeout(() => {
                    //alert("AlertId_"+id+" - Escallate api calling!")
                    
                    clearTimeout(escallateimer)
                    EscallateAlert(id)
                    //DeleteChatWindowOpenedTime(id)
                }, alertnotacktime * 60 * 1000)
                AddChatWindowOpenedTime(id,null,escallateimer)
                alert("AlertId_"+id+" - Please acknowledge your alert, if you don't this alert will get reassigned.")
                clearTimeout(notifytimer)
            }, alertnotacktime * 60 * 1000)
            //AddEscallationTimerList(id,notifytimer,null)
            AddChatWindowOpenedTime(id,notifytimer,null)
            
        }catch(error){
            console.error(error)
        }
    }

    //#region Chatbot
    const [chatHeads, setChatHeads] = useState([])
    const [token, setToken] = useState(null)

    function Openchatroom(){
        try{
            // if(singleAlertDet){
            //     if(singleAlertDet.workflowId != null){
            //         history.push(`/chatflow/${singleAlertDet.alertId}`)
            //     }else{
            //         Swal.fire( {text: "Workflow not configured for "+singleAlertDet.alertTemplate } )
            //     }
            // }
        }catch(error){
            console.error(error)
        }
    }

    async function onAddAlertChat(id) {
        try {
            let res = chatHeads.find(chat => chat.alertId == id)
            console.error({ res })
            if (!res) {
                return await GetAlertDetails(id).then((resp) => {
                    if(resp){
                        if(ASSOC_DIRECT_OPEN){
                            setAlertDetail(resp);
                        }
                        setCctvTypeName(resp.cctvtype)
                        setCamUserName(resp.camUserName)
                        setCamPassword(resp.camPassword)
                        setBrandName(resp.brandName)
                        if(resp.workflowId != null){
                            setChatHeads(prev => [...prev, resp])
                            AddAlertRecord(resp)
                            let isItDisabled = true;
                            let user_id = localStorage.getItem('UserID');
                            if(resp.assignedTo != null && resp.assignedTo == user_id){
                                isItDisabled = false
                            }
        
                            let tempObj = {
                                alertId: id,
                                twoWayStatus: true,
                                hooterStatus: true,
                                btnDisabled: isItDisabled,
                                deviceId: resp.deviceId
                            }
                            setHootWay([...hootWay, tempObj]);
                            return true
                        }else{
                            Swal.fire( {text: "Workflow not configured for "+resp.alertTemplate } )
                            return false
                        }
                    }
                })
            } else {
                return false
            }
        } catch (error) {
            console.error({ error })
            return false
        }
    }

    const onCloseAlertChat = (id) => {
        try{
            let arr = hootWay;
            let tempArr = arr.filter((item) => item.alertId != id.alertId);
            setHootWay(tempArr);
            // setHootWay((currentArr) => currentArr.filter((item) => item.alertId != id));
            ReactDOM.unmountComponentAtNode(document.getElementById("Chatbotcomponentdiv:AlertID-"+id.alertId))
        }catch(error){
            console.error(error)
        }
        let data = chatHeads.filter((value) => {
            return value.alertId !== id.alertId
        })
        setChatHeads(data)
        DeleteAlertRecord(id.alertId)
    }

    const onMaximizeAlertChat = (id) => {
        let data = chatHeads.filter((value) => {
            return value.alertId !== id
        })
        setChatHeads(data)
        DeleteAlertRecord(id)
        history.push(`/chatflow/${id}`)
    }

    const onChatOpen = (alertId) => {
        let arr = chatHeads.map((item) => {
            let obj;
            if(alertId == item.alertId){
                obj = {
                    ...item,
                    isOpen: !item.isOpen
                }
            }else{
                obj = {
                    ...item
                }
            }
            return obj;
        })
        setChatHeads(arr);
    }

    //#endregion

    function renderSwitch(param, onAddAlert, onCloseAlertChat) {
        return <Switch>
             <PrivateRoute path={PLAYER}>
                <Player/>
            </PrivateRoute>
            <PrivateRoute path={CONFIGURATION}>
                {isSuperUser ? <ConfigurationContainer isDarkTheme={isDarkTheme} /> : <NoAccessPage />}
            </PrivateRoute>
            <PrivateRoute path={WORKFLOW}>
                {isSuperUser ? <WorkflowContainer /> : <NoAccessPage />}
            </PrivateRoute>
            <PrivateRoute path={ROOT}>
                <MainContent
                    isSuperUser={isSuperUser}
                    alerts={alertList}
                    onAddAlertChat={onAddAlert}
                    ResetAlertList={GetAlertList} 
                    selectedTab={selectedTab} 
                    setSelectedTab={setSelectedTab}
                    selectedSubMenu={selectedSubMenu}
                    setSelectedSubMenu={setSelectedSubMenu}
                    alertcounts={alertcounts}
                    rootLoading={rootLoading}
                    ResetAlertCounts={GetAlertCount}
                    todaysAlertList = {todaysAlertList}
                    uniqueId={uniqueId}
                    cctvTypeName={cctvTypeName}
                    camUserName = {camUserName}
                    camPassword = {camPassword}
                    brandName = {brandName}
                    hootWay={hootWay}
                    setHootWay={setHootWay}
                    alertSearch={alertSearch}
                    setAlertSearch={setAlertSearch}
                    activeSeverity={activeSeverity}
                    setActiveSeverity={setActiveSeverity}
                    childWindow={childWindow}
                    setChildWindow= {setChildWindow}
                    isDarkTheme={isDarkTheme}
                    setIsDarkTheme={setIsDarkTheme}
                    setAlertDetail={setAlertDetail}
                    setOpenButton1={setOpenButton1}
                    openButton1={openButton1}
                    activeCam={activeCam}
                    setActiveCam={setActiveCam}
                    />
            </PrivateRoute>
            {/* <PrivateRoute path={CAMERAS}>
                <CameraViewPage />
            </PrivateRoute> */}
            <PrivateRoute path={CAMERA_SINGLE_PLAYBACK}>
                <SinglePlayback />
            </PrivateRoute>
            {/* <PrivateRoute path={DASHBOARD}>
                <DashboardContainer alertList={alertList}
                todaysAlertList = {todaysAlertList}  />
            </PrivateRoute> */}
        </Switch>
    }

    return (
        <>
         <DrawerProvider>
         <div className="container-fluid p-0">
                <Topbar profile={profile} isDarkTheme={isDarkTheme} setIsDarkTheme={setIsDarkTheme}  childWindow={childWindow}
                    setChildWindow= {setChildWindow}/>
                {/* All Config Routes here */}
                <main className="mainDiv">
                    {
                        renderSwitch(
                            location.pathname, 
                            onAddAlertChat)
                    }
                </main>
            </div>
            {
                currentUser &&
                <ChatbotContainer
                    chatHeads={chatHeads}
                    onCloseAlertChat={onCloseAlertChat}
                    onMaximizeAlertChat={onMaximizeAlertChat}
                    ResetAlertList={GetAlertList}
                    onChatOpen={onChatOpen}
                    cctvTypeName = {cctvTypeName}
                    camUserName = {camUserName}
                    camPassword = {camPassword}
                    brandName = {brandName}
                    hootWay={hootWay}
                    setHootWay={setHootWay}
                    openButton1={openButton1}
                    setOpenButton1={setOpenButton1}
                    setAlertDetail={setAlertDetail}
                    activeCam={activeCam}
                    setActiveCam={setActiveCam}
                    />
            }

            {openButton1 && <ChatbotAssociateCamera openButton1={openButton1} setOpenButton1={setOpenButton1} activeCam={activeCam} setActiveCam={setActiveCam} alertDetail={alertDetail} />}
         </DrawerProvider>
            
        </>
    )
}


const MainContent = ({
    alerts,
    onAddAlertChat,
    ResetAlertList,
    selectedTab,
    setSelectedTab,
    selectedSubMenu, 
    setSelectedSubMenu,
    alertcounts,
    rootLoading,
    ResetAlertCounts,
    todaysAlertList,
    isSuperUser,
    uniqueId,
    cctvTypeName,
    camUserName,
    camPassword,
    brandName,
    hootWay,
    setHootWay,
    alertSearch,
    setAlertSearch,
    activeSeverity,
    setActiveSeverity,
    childWindow,
    setChildWindow,
    isDarkTheme,
    setIsDarkTheme,
    setAlertDetail,
    setOpenButton1,
    openButton1,
    activeCam,
    setActiveCam
}) => {
    const{ collapsed, setCollapsed} = useContext(DrawerContext);
    // const [isDarkTheme, setIsDarkTheme] = useState(true);
    return <>
        <Sidebar
            isSuperUser={isSuperUser}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            selectedSubMenu={selectedSubMenu} 
            setSelectedSubMenu={setSelectedSubMenu} 
            isDarkTheme={isDarkTheme} />
        <div 
        className={!collapsed ? "mainContent" : "collapseMainContent" }
        /* className="mainContent" */
        >
            <Switch>
                {/* List all private/auth routes here */}
                <PrivateRoute path={ALERTS}>
                    <AlertsContainer onAddAlertChat={onAddAlertChat} setSelectedTab={setSelectedTab} uniqueId={uniqueId} alertSearch={alertSearch} setAlertSearch={setAlertSearch} activeSeverity={activeSeverity} setActiveSeverity={setActiveSeverity} />
                </PrivateRoute>
                <PrivateRoute path={ALLALERTS}>
                    <AllAlerts alertList={alerts} onAddAlertChat={onAddAlertChat} setSelectedTab={setSelectedTab} alertcounts={alertcounts} ResetAlertCounts={ResetAlertCounts} alertSearch={alertSearch} setAlertSearch={setAlertSearch} activeSeverity={activeSeverity} setActiveSeverity={setActiveSeverity}/>
                </PrivateRoute>
                <PrivateRoute path={MYALERTS}>
                    <MyAlerts alertList={alerts} onAddAlertChat={onAddAlertChat} setSelectedTab={setSelectedTab} alertcounts={alertcounts} alertSearch={alertSearch} setAlertSearch={setAlertSearch} activeSeverity={activeSeverity} setActiveSeverity={setActiveSeverity}/>
                </PrivateRoute>
                <PrivateRoute path={ALERTDETAILS}>
                    <AlertDetails setSelectedTab={setSelectedTab} onAddAlertChat={onAddAlertChat} hootWay={hootWay} setHootWay={setHootWay} setSelectedSubMenu={setSelectedSubMenu} />
                </PrivateRoute>
                <PrivateRoute path={DASHBOARD}>
                    <DashboardContainer alertList={alerts} setSelectedTab={setSelectedTab} alertcounts={alertcounts} todaysAlertList={todaysAlertList} rootLoading={rootLoading} onAddAlertChat={onAddAlertChat} isDarkTheme={isDarkTheme} />
                </PrivateRoute>
                <PrivateRoute path={HOMEPAGE}>
                    <DashboardContainer alertList={alerts} setSelectedTab={setSelectedTab} alertcounts={alertcounts} todaysAlertList={todaysAlertList} rootLoading={rootLoading} onAddAlertChat={onAddAlertChat} isDarkTheme={isDarkTheme} />
                </PrivateRoute>
                {/* <PrivateRoute path={CONFIGURATION}>
                    <ConfigurationContainer />
                </PrivateRoute> */}
                <PrivateRoute path={ALLNOTIFICATIONS}>
                    <AllNotificationContainer />
                </PrivateRoute>

                {/* Reports S tej */}
                <PrivateRoute path={REPORTS}>
                    <AlertReportsContainer isDarkTheme={isDarkTheme} />
                </PrivateRoute>
                {/* <PrivateRoute path={ALERTACTIVITYREPORT}>
                    <AlertActivityReport />
                </PrivateRoute> */}
                <PrivateRoute path={ALERTARMDISARMREPORT}>
                    <AlertArmDisarmReport />
                </PrivateRoute>
                <PrivateRoute path={EVENTACTIVITYREPORT}>
                    <EventActivityReport isDarkTheme={isDarkTheme} />
                </PrivateRoute>

                {/* Reports E tej*/}

                {/* Camera S tej*/}
                <PrivateRoute path={CAMERAS}>
                    <CameraViewPage childWindow={childWindow}
                    setChildWindow={setChildWindow}/>
                </PrivateRoute>

                <PrivateRoute path={CAMERA_SINGLE_PLAYBACK}>
                    <SinglePlayback 
                        setChildWindow={setChildWindow}
                    />
                </PrivateRoute>
                {/* Camera E */}

                <PrivateRoute path={PROFILE}>
                    <ProfileContainer isDarkTheme={isDarkTheme} />
                </PrivateRoute>
                {
                    !HIDE_STUFF
                    ?
                    <PrivateRoute path={DOWNLOAD}>
                    <DownloadHistory />
                    </PrivateRoute>
                    : null
                }    

                {/* dashboard S */}
                {/* <PrivateRoute path={DASHBOARD}>
                    <DashboardContainer />
                </PrivateRoute> */}
                {/* dashboard E*/}
                <PrivateRoute path={CHATFLOW}>
                    <ChatflowPageContainer ResetAlertList={ResetAlertList} 
                    cctvTypeName = {cctvTypeName}
                    camUserName = {camUserName}
                    camPassword = {camPassword}
                    brandName = {brandName}
                    hootWay = {hootWay}
                    setHootWay = {setHootWay}
                    setAlertDetail={setAlertDetail}
                    setOpenButton1={setOpenButton1}
                    openButton1={openButton1}
                    activeCam={activeCam}
                    setActiveCam={setActiveCam}
                    />
                </PrivateRoute>
                <PrivateRoute path={ALERTREPORT}>
                    <AlertReportContainer />
                </PrivateRoute>
                <PrivateRoute path={HEALTHMONITORING} exact={true}>
                    <HealthMonitoring 
                        isDarkTheme={isDarkTheme}
                    />
                </PrivateRoute> 
                <PrivateRoute path={DISPLAYSITE} exact={true}>
                    <HealthDisplaySite  />
                </PrivateRoute>
                {/* <PrivateRoute path={NOTIFICATON}>
                    <NotificationContainer />
                </PrivateRoute> */}
                <PrivateRoute path={PAGE}>
                    <CustomUrlPage />
                </PrivateRoute>
            </Switch>
        </div>
    </>
}