import React, { useEffect, useState } from 'react'

const HealthModalList = (props) => {
    let {status, item } = props;
    const [dateString, setDateString] = useState("");
    const [timeString, setTimeString] = useState("");

    useEffect(() => {
      setDateString(item?.StatusDate?.split(" ")[0])
      setTimeString(item?.StatusDate?.split(" ")[1])
    }, []);

  return (
    <tr>
    {
      status === "Online" || status === "Offline"
      ? <td className={status === "Online" ? "online-color" : "offline-color" }>{status}</td>
      : <td className={status === "Normal" ? "online-color" : "offline-color" }>{status}</td>
    }
    <td>{dateString}</td>
    <td>{item.StatusTime}</td>
    </tr>
  )
}

export default HealthModalList