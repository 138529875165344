import React from "react"
import SendIcon from '../../assets/images/send.svg'

export default function ChatflowReasonCode({
    // buttons,
    // ReasonCodeClick,
    // selectedReasonCode,
    // setSelectedReasonCode,
    // isReasonCodeSelected,
    // setIsReasonCodeSelected

    selectedReasonCode,
    ReasonCodeSendClick,
    setSelectedReasonCode
    // MessageListState

}) {
    const handleChange = event => {
        let reasonCodeObj = {
            "btnId":selectedReasonCode.btnId,
            "buttonProperties": selectedReasonCode.buttonProperties,
            "title": event.target.value
        }
        setSelectedReasonCode(reasonCodeObj);
      };
    return(
        <>
            {/* <div className="chatFlowWebChatBox1" style={{ display: (isReasonCodeSelected ? 'block' : 'none') }}> */}
                <div className="chatFlowBoxContainer">
                    <input id={selectedReasonCode.btnId} className="chatFlowWebChatBoxInput" type="text" value={selectedReasonCode.title}
                    onChange={handleChange}
                    // value={selectedReasonCode.title} 
                    />
                    <a className="cursor-ptr" style={{ marginRight: "15px" }} onClick={(e) => ReasonCodeSendClick(e)}>
                        <img src={SendIcon} alt= "Send Icon" height={20} width={20} />
                    </a>
                </div>
            {/* </div> */}
        {/* <Row className="reasonCodeHeader"><label>Please select the reason code</label></Row>
        {
           buttons &&
            buttons.map(reasoncode => {
                // mess.data.otherProps.buttons.map((reasoncode, index) => (
                return <div>
                    <Row><span id={reasoncode.btnId}
                     onClick={() => {
                        ReasonCodeClick(reasoncode)
                        // setSelectedReasonCode(reasoncode)
                        // setIsReasonCodeSelected(true)
                    }}
                    //  onClick={(e) => ReasonCodeClick(reasoncode)} 
                    className={isReasonCodeSelected &&  selectedReasonCode?.btnId == reasoncode.btnId? "reasonCodeBtn selected-reasoncode": "reasonCodeBtn"}
                        // className={`reasonCodeBtn ${selectedReasonCode && reasoncode.btnId === selectedReasonCode?.btnId && "selected-reasoncode"}`}
                    >{reasoncode.title}</span></Row>
                </div>
                //)
            })
        } */}
    </>
    )
}