import React, { useEffect, useState } from 'react'
import { ReactComponent as LoginImg } from '../../../assets/images/login-img.svg'
import { ReactComponent as ErrorIcon } from '../../../assets/images/error.svg'
import { useHistory, useLocation } from 'react-router-dom'
import { useAuth } from '../ProvideAuth'
import { loginCF, loginDotNet, updateFCMDetails } from '../../../services/userAuthServices'
import '../../../app.css'
import { IsEmailValid } from '../../../utils/validations'
import { mixpanel } from '../../../utils/MixPanelUtil'
import { DASHBOARD, HOMEPAGE } from '../../CONSTANTS'
import { ConvertDate } from '../../../utils/utils'
import { Eye, LoginImage, EyeMask, MonitoringHubLogo } from '../../../assets/images'
import { SuccessToast } from '../../../CustomHooks/SuccessToast'
import { ErrorToast } from '../../../CustomHooks/ErrorToast'
import { async } from 'q'
import { ToastContainer } from 'react-toastify'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'

export const Login = ({ match }) => {
    //* * Form Variables
    const [email, setEmail] = useState('') // to remove in the end
    const [password, setPassword] = useState('')// to remove in the end
    const [passwordShown, setPasswordShown] = useState(false);
    const [greetingMessage, setGreetingMessage] = useState('Hello')
    const [error, setError] = useState(null)
    const [commonError, setCommonError] = useState(null)
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isNotValidPassword, setIsNotValidPassword] = useState(false); 
    let history = useHistory();
    let location = useLocation();
    let auth = useAuth();

    let { from } = location.state || { from: { pathname: HOMEPAGE } };
     //const handlePasswordChange = (event) => {
    //     let tempValue = event.target.value[event.target.value.length - 1];
    //     console.log("Space is not Allow!", tempValue);
    //     if (tempValue == " "){ 
    //          ErrorToast('Space is not Allow!'); 
    //          return;
    //     }
    //     const newPassword = event.target.value.replace(" ","");
    //     setPassword(newPassword);
    //     setIsButtonDisabled(
    //       !(
    //         newPassword.length > 0 &&
    //         isCapitalCase(newPassword) &&
    //         isSpecialCharacter(newPassword) &&
    //         isNumberInclude(newPassword) &&
    //         isMinCharacter(newPassword) &&
    //         isMaxCharacter(newPassword)
    //       )
    //     );
    //     setIsNotValidPassword(!(
    //         newPassword.length > 0 &&
    //         isCapitalCase(newPassword) &&
    //          isSpecialCharacter(newPassword) &&
    //          isNumberInclude(newPassword) &&
    //         isMinCharacter(newPassword) &&
    //         isMaxCharacter(newPassword)
    //       ))
    //   };
    
    //   const isCapitalCase = (pass) => {
    //     const pattern = /[A-Z]/;
    //     return pattern.test(pass)
    //   }
    //   const isSpecialCharacter = (pass) => {
    //     const pattern = /[\W_]/;
    //     return pattern.test(pass)
    //   };
    
    //   const isNumberInclude = (pass) => {
    //     const pattern = /\d/;
    //     return pattern.test(pass)
    //   }
    //   const isMinCharacter = (pass) => {
    //     return pass.length >= 8;
    //   };
    //   const isMaxCharacter = (pass) => {
    //     return pass.length <= 15 && pass.length >= 1;
    //   };
    
    
    
    //   const popover = (
    //     <Popover id="popover-basic" style={{ width: "200px" }}>
    //       <Popover.Content>
    //         <p className={isCapitalCase(password) ? "popoverP Valid" : "popoverP Invalid"}>
    //           <i className="fa fa-check-circle-o" aria-hidden="true"></i>One capital case
    //         </p>
    
    //         <p className={isSpecialCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
    //           <i className="fa fa-check-circle-o" aria-hidden="true"></i>One special character
    //         </p>
    
    //         <p className={isNumberInclude(password) ? "popoverP Valid" : "popoverP Invalid"}>
    //           <i className="fa fa-check-circle-o" aria-hidden="true"></i>One number
    //         </p>
    
    //         <p className={isMinCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
    //           <i className="fa fa-check-circle-o" aria-hidden="true"></i>Minimum 8 Character
    //         </p>
    
    //         <p className={isMaxCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
    //           <i className="fa fa-check-circle-o" aria-hidden="true"></i>Maximum 15 Character
    //         </p>
    //       </Popover.Content>
    //     </Popover>
    //   );

    const onForgotPassword = () => {
        // if (email.trim() === '') {
        //     setCommonError(null)
        //     setError('Please enter email')
        //     return
        // }

        // if (!IsEmailValid(email)) {
        //     setCommonError(null)
        //     setError('Please enter valid Email Id.')
        //     return
        // }

        history.push(`/forgetpassword/${JSON.stringify(email.trim())}`)
    }
    const SetEvents = (user) => {
        try {
            var obj = { "UserRoleID": user.UserRoleID }
            mixpanel.people.set(JSON.stringify(obj));

            mixpanel.identify(user.UserID.toString());
            mixpanel.track("Clicks on Sign In")
        } catch (error) {
            console.error(error)
        }
    }
    const login = async () => {
        if (email.trim() === '' && password.trim() === '') {
            setError(null)
            setCommonError(null)
            setCommonError('Please enter email and password')
            return
        }

        if (email.trim() === '') {
            setCommonError(null)
            setError('Please enter email')
            return
        }

        if (!IsEmailValid(email)) {
            setCommonError(null)
            setError('Please enter valid Email Id.')
            return
        }

        if (password.trim() === '') {
            setCommonError(null)
            setError('Please enter password')
            return
        }

        let userId;
        let token;
        let myCurrentDatetime;
        // await auth.signin(email, password)
        //     .then(async (res) => {
        //         console.log("Firebase Login", res);
        //         token = await auth.getFCMToken()
        //         myCurrentDatetime = new Date();
        //         console.log("Firebase Login token", token);
        //         let datetime = ConvertDate(myCurrentDatetime)
        //         console.log("Firebase Login Datetime", datetime);
        //         auth.signout();
        //     }).catch((err) => {
        //         if (err.code == "auth/wrong-password") {
        //             setCommonError(null)
        //             //setError("Please enter the correct password.")
        //             return;
        //         }
        //         else if (err.code == "auth/user-not-found") {
        //             setError(null)
        //             //setCommonError("Invalid email or password. Please try again.")
        //             return;
        //         }
        //     });

        const savedTheme = localStorage.getItem('theme');
        if (savedTheme == undefined) {
            localStorage.setItem('theme', 'lightTheme');
        }
        loginDotNet(email, password)
            .then(async (resp) => {
                if (resp.Status === "ok") {
                    console.log("Login Response", resp);
                    localStorage.setItem('authToken', resp.Message.split("##")[0]);
                    localStorage.setItem('authCode', resp.Message.split("##")[1]);
                    localStorage.setItem('UserID', resp.Message.split("##")[2]);
                    localStorage.setItem('userPassword', password);
                    sessionStorage.removeItem('Page_Refresh_State');
                    userId = parseInt(resp.Message.split("##")[2]);
                    // localStorage.setItem('UserID', 533);
                    history.replace(from);

                    token = await auth.getFCMToken()
                    console.log("Firebase Login token", token);
                    myCurrentDatetime = new Date();
                    let datetime = ConvertDate(myCurrentDatetime)
                    console.log("Firebase Login Datetime", datetime);

                    /* -------------------- Block for hitting FCM Details API ------------------- */
                    console.log("Firebase Login", userId, token, myCurrentDatetime);
                    updateFCMDetails(userId, token, myCurrentDatetime).then((res) => {
                        if (res.status === "Ok") {
                            console.log("Firebase Login", res.message);
                        } else {
                            console.log("Firebase Login", res.message);
                        }
                    })
                } else if (resp.Status === "failed") {
                    setCommonError(null)
                    if (resp.Message === "email not verified") {
                         history.push(`/verification/${JSON.stringify(email.trim())}`)
                        //   setError("Email not verified! Please verify email, link is sent to registered email id.")
                        
                    } else {
                        setError(resp.Message);
                    }
                }
            })
            .catch((err) => {
                // setCommonError("Invalid email or password. Please try again.")
                setCommonError("Unable to Login.")
                return;
            });


            // await auth.signin(email, password).then(async (res) => {
            //     if (res.emailVerified == false) {
            //         res.sendEmailVerification()
            //         auth.signout()
            //         setCommonError(null)
            //         setError("Email not verified! Please verify email, link is sent to registered email id.")
            //         return
            //     }
            //     let token = await auth.getFCMToken()
            //     let myCurrentDatetime = new Date();
            //     let datetime = ConvertDate(myCurrentDatetime)
            //     await loginCF(email, token, datetime).then((user) => {
            //         if (user.status === "Ok") {
            //             SetEvents(user)
            //             localStorage.setItem('user', JSON.stringify(user))
            //             localStorage.setItem("UserID", user.UserID);
            //             history.replace(from)
            //         } else {
            //             setCommonError(null)
            //             setError(user.message)
            //         }
            //     }).catch((err) => {
            //         console.log(err);
            //     })
            // }).catch((err) => {
            //     if (err.code == "auth/wrong-password") {
            //         setCommonError(null)
            //         setError("Please enter the correct password.")
            //         return
            //     }
            //     else if (err.code == "auth/user-not-found") {
            //         setError(null)
            //         setCommonError("Invalid email or password. Please try again.")
            //         return
            //     }
            // });
    };

    useEffect(() => {
        // auth.user != null && history.push(DASHBOARD) 
        getGreetingTime()
    }, [])

    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
        if (loggedInUser && window.location.pathname === '/login') {
          history.push(HOMEPAGE);
        }
      }, [loggedInUser, history]);

    /**
     * *
     * Refer for the method logic
     * http://jsfiddle.net/DerekL/we8Ty/
     */
    const getGreetingTime = () => {
        try {
            let data = [
                [0, 11, "Good morning"],
                [12, 16, "Good afternoon"],
                [17, 24, "Good evening"]
            ]
            let hr = new Date().getHours();

            for (var i = 0; i < data.length; i++) {
                if (hr >= data[i][0] && hr <= data[i][1]) {
                    setGreetingMessage(data[i][2])
                    break;
                }
            }
        } catch (error) {
            setGreetingMessage('Hello')
            console.error(error)
        }

    }
    return (
        <>
            <div className="container-fluid forgot-pass-container" style={{ backgroundColor: "#fff" }}>
                <div className="row forgot-pass-row">
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ backgroundColor: "#F6F8FC" }}>
                        <div className="imageWhiteBg">
                            <MonitoringHubLogo height={"70px"} />
                            {/* <img src={logo} className="mt-4" /> */}
                        </div>
                        <div className="BigImage">
                            {/* <LoginImg height={'28rem'}
                                className="loginPage-img"
                            /> */}
                            <img src={LoginImage} />
                        </div>
                    </div>
                    <div className="col-lg-6 ContentForm col-md-6 col-sm-12 col-xs-12 login-col-2" style={{ backgroundColor: "#fff" }}>
                        <div className="WhiteFormSignIn">
                            <div className="Content">
                                <p className="GM_msg">{greetingMessage} !<br />
                                    Hope you are having a good day</p>
                                <div className="row" hidden>
                                    <div className="col-md-4 user-type-main">
                                        <input type="radio" name="user-type"></input><label>Operator</label>
                                        <p className="user-type-desc">Account owner that perform tasks</p>
                                    </div>
                                    <div className="col-md-4 user-type-main">
                                        <input type="radio" name="user-type"></input><label>Admin</label>
                                        <p className="user-type-desc">Account owner that perform tasks</p>
                                    </div>
                                    <div className="col-md-4 user-type-main">
                                        <input type="radio" name="user-type"></input><label>Root</label>
                                        <p className="user-type-desc">Account owner that perform tasks</p>
                                    </div>
                                </div>
                                {/* <p className="Error_P">
                                    {commonError &&
                                        <>
                                            <ErrorIcon />
                                            <span>{commonError}</span>
                                        </>
                                    }
                                </p>
                                <p className="LabelText">Email ID </p>
                                <input
                                    type="email"
                                    placeholder="name@example.com"
                                    className="EnterID"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)} />

                                <p className="LabelText">Password</p>
                                <div className="BgInputPassword">
                                    <input
                                        type={passwordShown ? "text" : "password"}
                                        placeholder="abc@123"
                                        className="PasswordBg pl-2"
                                        value={password}
                                        onChange={e => setPassword(e.target.value)} />
                                    {
                                        !passwordShown ?
                                            <img
                                                className="EyeIcon"
                                                src={Eye}
                                                onClick={() => setPasswordShown(!passwordShown)} /> :
                                            <EyeMask
                                                height={18}
                                                width={18}
                                                onClick={() => setPasswordShown(!passwordShown)} />
                                    }
                                </div>
                                <p className="Error_P">
                                    {
                                        error &&
                                        <>
                                            <ErrorIcon />
                                            <span>{error}</span>
                                        </>
                                    }
                                </p> */}

                                <div className="inputMainContainer w25dot75rem mt35">
                                    <div className="inputTypeLabelContainer">
                                        <label for="email">Email ID</label>
                                        <input
                                            className="inputType"
                                            id="email"
                                            type="email"
                                            placeholder="Ex. info@intellve.com"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onKeyUp={(e) => { if (e.code === 'Enter') login() }}
                                        />
                                    </div>
                                </div>

                                <div className="inputMainContainer w25dot75rem mt30">
                                    <div className="inputTypeLabelContainer">
                                        <label for="password">Password</label>
                                        {/* <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}> */}
                                        <input
                                            className="inputTypePassword"
                                            id="password"
                                            type={passwordShown ? "text" : "password"}
                                            placeholder="Ex. abc@123"
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            //onChange={handlePasswordChange}
                                            // onChange={(e) => handlePasswordChange(e)}
                                            onKeyUp={(e) => { if (e.code === 'Enter') login() }}
                                          />
                                          {/* </OverlayTrigger> */}

                                        <div className="EyeIconDiv">
                                            {!passwordShown ? (
                                                <img
                                                    className="EyeIcon"
                                                    src={Eye}
                                                    onClick={() => setPasswordShown(!passwordShown)}
                                                />
                                            ) : (
                                                <EyeMask
                                                    height={18}
                                                    width={18}
                                                    onClick={() => setPasswordShown(!passwordShown)}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div style={{ height: "10px" }}>
                                    <p className="Error_P">
                                        {error && (
                                            <>
                                                {/* <ErrorIcon /> */}
                                                <span>{error}</span>

                                           </>
                                        )}

                                        {commonError && (
                                            <>
                                                {/* <ErrorIcon /> */}
                                                <span>{commonError}</span>
                                            </>
                                        )}
                                    </p>
                                </div>

                                {/* <a className="LinkForget" onClick={onForgotPassword}>
                                    <p className="ForgotPassword">Forgot Password?</p>
                                </a> */}
                                <p className="ForgotPassword"
                                    onClick={onForgotPassword}
                                >
                                    Forgot Password?
                                </p>
                                <button className="ResetPasswordButton w25dot75rem" onClick={login}>
                                    <span className="Manage-Configuration Sign-In">Sign In</span>
                                </button>

                                <p className="Intellve_customer">
                                    By continuing, you agree to the Intellve customer{" "}
                                    <a href=""> agreement </a> and the{" "}
                                    <a href=""> privacy notice</a>.
                                </p>
                                {/* <p className="Intellve_customer">
                                    By continuing you agree to the Intellve customer agreement or other agreement for Intellve service and Privacy Notice.
                                </p> */}
                                <p className="Account" hidden>Don't have an account yet?
                                    <span className="Sign-up"><a href="/signup">Sign up</a></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
            </div>
        </>
    )
}
