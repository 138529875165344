import React, { useState, useEffect } from "react";
// import "../../pages/NotificationMenu/NotificationContainer.css";
import { getSiteList, getDeviceTypeList } from "../../services/configurationServices";
import { DropArrow, SearchIcon, SearchIconWhite } from "../../assets/images";
import {
  useAccordionToggle,
} from "react-bootstrap";
import { Mention, MentionsInput } from "react-mentions";
import mentionsInputStyles from "./mentionsInputStyles";
import mentionStyles from "./mentionStyles";
import RegisteredUsers from "./RegisteredUsers";
import CustomContact from "./CustomContact";
import { MultiSelect } from "react-multi-select-component";
import { addNotifs, deleteNotifs, getMentionsList, getNotifById, getNotifs, updateNotifs } from "../../services/notifService";
import { WarningToast } from "../../CustomHooks/WarningToast";
import { ToastContainer } from "react-toastify";
import { SuccessToast } from "../../CustomHooks/SuccessToast";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import Swal from 'sweetalert2';

const REALTIME = "REALTIME";
const DAILY = "DAILY";
const HOURLY = "HOURLY";
const WEEKLY = "WEEKLY";
const MONTHLY = "MONTHLY";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionToggle(eventKey, () =>
    console.log("totally custom!")
  );

  return (
    <button
      type="button"
      style={{ backgroundColor: "pink" }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const NotificationContainer = ({addNewClicked, setAddNewClicked, isNotifFormEnabled, setIsNotifFormEnabled, notifSaveBtnClicked, notifDeleteBtnClicked, setNotifSaveBtnClicked, setNotifDeleteBtnClicked, setIsEmptyForm, isDarkTheme }) => {
  
  const [selectedSiteTab, setSelectedSiteTab] = useState("realTime");
  const [notifList, setNotifList] = useState([]);
  const [mainNotifList, setMainNotifList] = useState([]);
  const [notifSearch, setNotifSearch] = useState("");
  const [notificationName, setNotificationName] = useState("");
  const [emergencyContact, setEmergencyContact] = useState(false);
  const [customContact, setCustomContact] = useState([{
    Id: 0,
    Name: "",
    Designation: "",
    ContactNumber: "",
    ContactEmail: ""
  }]);
  const [mentionList, setMentionList] = useState([]);
  
  //Email Notification Template State Values Start
  const [realTimeEnabled, setRealTimeEnabled] = useState(false);
  const [realTimeSubject, setRealTimeSubject] = useState("");
  const [mentionRealTimeSubject, setMentionRealTimeSubject] = useState("");
  const [realTimeMessage, setRealTimeMessage] = useState("");
  const [mentionRealTimeMessage, setMentionRealTimeMessage] = useState("");

  const [hourlyEnabled, setHourlyEnabled] = useState(false);
  const [hourlySubject, setHourlySubject] = useState("");
  const [mentionHourlySubject, setMentionHourlySubject] = useState("");
  const [hourlyMessage, setHourlyMessage] = useState("");
  const [mentionHourlyMessage, setMentionHourlyMessage] = useState("");

  const [dailyEnabled, setDailyEnabled] = useState(false);
  const [dailySubject, setDailySubject] = useState("");
  const [mentionDailySubject, setMentionDailySubject] = useState("");
  const [dailyMessage, setDailyMessage] = useState("");
  const [mentionDailyMessage, setMentionDailyMessage] = useState("");

  const [weeklyEnabled, setWeeklyEnabled] = useState(false);
  const [weeklySubject, setWeeklySubject] = useState("");
  const [mentionWeeklySubject, setMentionWeeklySubject] = useState("");
  const [weeklyMessage, setWeeklyMessage] = useState("");
  const [mentionWeeklyMessage, setMentionWeeklyMessage] = useState("");

  const [monthlyEnabled, setMonthlyEnabled] = useState(false);
  const [monthlySubject, setMonthlySubject] = useState("");
  const [mentionMonthlySubject, setMentionMonthlySubject] = useState("");
  const [monthlyMessage, setMonthlyMessage] = useState("");
  const [mentionMonthlyMessage, setMentionMonthlyMessage] = useState("");
  //Email Notification Template State Values End

  const [userList, setUserList] = useState([]);
  const [selectedUsersList, setSelectedUsersList] = useState([]);
  const [selectedRegUsers, setSelectedRegUsers] = useState([]);
  const [siteListString, setSiteListString] = useState("");
  const [mainCustomerList, setMainCustomerList] = useState([{
    Id: 0,
    Name: "",
    Designation: "",
    ContactNumber: "",
    ContactEmail: ""
  }]);
  const [count, setCount] = useState(0);
  const [enableAdd, setEnableAdd] = useState(false);
  // const [clientListArray, setClientListArray] = useState([]);
  // const [clientSelected, setClientSelected] = useState([]);
  const [siteSelected, setSiteSelected] = useState([]);
  const [deviceTypeSelected, setDeviceTypeSelected] = useState([]);
  const [eventCodeSelected, setEventCodeSelected] = useState([]);
  // const [totalSelected, setTotalSelected] = useState("Selected Sites"); //Will use in future for counts
  const [siteListData, setSiteListData] = useState([]);
  // const [clients, setClients] = useState([])
  const [deviceTypeListData, setDeviceTypeListData] = useState([]);
  const [selectedNotifObj, setSelectedNotifObj] = useState(null);
  const [selectedAllRegUsers, setSelectedAllRegUsers] = useState(false);

  const eventCodeListArray = [
    { label: "Online", value: "1" },
    { label: "Offline", value: "2" },
  ];

  const tempeventCodeListArray = [
    { label: "Camera Block", value: "1" },
    { label: "Camera Tamper", value: "2" },
    { label: "Camera View Change", value: "3" },
  ];

  useEffect(() => {
    getNotificationList();
  }, []);

  useEffect(() => {
    let temp = siteSelected.map((item) => item.value).join(",");
    let oldString = siteListString;
    let oldArr = siteListString.split(",");
    let newArr = temp.split(",");
    if(oldArr.length > newArr.length){
      let diff = oldArr.filter(e => !newArr.includes(e));
      let anotherTempArr = selectedUsersList;
      for(let i=0; i<diff.length; i++){
        anotherTempArr = anotherTempArr.filter((item) => item.Item2 != diff[i]);
      }
      setSelectedUsersList(anotherTempArr);
    }
    setSiteListString(temp);
  }, [siteSelected])

  const finalObj = () => {
    let obj = {
      "NotificationConfigurationID": 0,
      "NotificationtemplateName": notificationName,
      "NotificationConfigurationDetail": {
        "LstSites": [

        ],
        "LstDeviecType": [

        ],
        "LstEventCode": [

        ],
        "NotificationTemplateEmail": {
          "Subject": "",
          "Body": "",
          "Type": "",
        },
        "EmergencyContact": "",
        "LstUserSite": [],
        "LstCustomContact": []
      }
    }
  }

  useEffect(() => {
    if(notifSaveBtnClicked){
      setNotifSaveBtnClicked(false);
      //Call Function for adding
      addNotification();
    }
  }, [notifSaveBtnClicked]);

  useEffect(() => {
    if(!isNotifFormEnabled && selectedNotifObj === null){
      clearInputFields();
    }
    if(!isNotifFormEnabled && selectedNotifObj !== null){
      // getNotificationById(selectedNotifObj);
    }
  }, [isNotifFormEnabled]);

  useEffect(() => {
    if(notifDeleteBtnClicked){
      //Call Function to delete the Noti
      deleteNotification();
    }
  }, [notifDeleteBtnClicked]);

  const deleteNotification = () => {
    if(selectedNotifObj == null){
      WarningToast("Kindly select a notification to delete it.")
      return;
    }
    try {
      Swal.fire({
        title: 'Are you sure?',
        //text: "Deleting this data access group will delete all the associations related to it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!'
      }).then((result) => {
        if(result.isConfirmed){
          let updateObj = {
            NotificationConfigurationID: selectedNotifObj.NotificationConfigurationID, 
            NotificationtemplateName: notificationName,
            NotificationConfigurationDetail: {
              LstSites: [""],
              LstDeviecType: [""],
              LstEventCode: [""],
              LstNotificationTemplateEmail: [{
                Subject: "",
                Body: "",
                Type: "RealTime",
                IsEnabled: realTimeEnabled.toString()
              },
              {
                Subject: "",
                Body: "",
                Type: "Hourly",
                IsEnabled: hourlyEnabled.toString()
              },
              {
                Subject: "",
                Body: "",
                Type: "Daily",
                IsEnabled: dailyEnabled.toString()
              },
              {
                Subject: "",
                Body: "",
                Type: "Weekly",
                IsEnabled: weeklyEnabled.toString()
              },
              {
                Subject: "",
                Body: "",
                Type: "Monthly",
                IsEnabled: monthlyEnabled.toString()
              }],
              EmergencyContact: "",
              LstUserSite: [{
                Item1: "",
                Item2: ""
              }],
              LstCustomContact: [{
                Id: "",
                Name: "",
                Designation: "",
                ContactNumber: "",
                ContactEmail: ""
              }]
            }
          }
          deleteNotifs(updateObj)
            .then(res => {
              if(res.Status == "success"){
                SuccessToast(res.Message);
                getNotificationList();
                setNotifDeleteBtnClicked(false); 
                clearInputFields();
                setIsNotifFormEnabled(true);
                setIsEmptyForm(true);
              }
            }).catch(err => console.log(err))
        }else{
          setNotifDeleteBtnClicked(false); 
        }
      })
    } catch (error) {
      console.log(error);
    }
  }

  function cloneObject(obj) {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    var temp = obj.constructor(); // give temp the original obj's constructor
    for (var key in obj) {
        temp[key] = cloneObject(obj[key]);
    }
    return temp;
  }
  const addNotification = () => {
    // let tempCustomContact = mainCustomerList;
    // let tempCustomContact = cloneObject(mainCustomerList);
    // tempCustomContact.forEach((item) => { delete item.id })
    // console.log("addNotification", mainCustomerList);
    // for(let i=0; i < tempCustomContact.length; i++){
    //   delete tempCustomContact[i].id;
    // }
    // tempCustomContact.forEach(item => {
    //   delete item['id'];
    // })
    if(notificationName == "")
    { 
      ErrorToast('Kindly enter a name for the template');
      setNotifSaveBtnClicked(false);
     return;  
    }
    // if(clientSelected.length == 0){
    //   ErrorToast('Kindly select some customers'); 
    //   setNotifSaveBtnClicked(false);
    //   return; 
    // }
    // let tempClients = clientSelected.map((item) => item.value);
    // console.log("addNotification", tempClients);
    if(siteSelected.length === 0) { 
      ErrorToast('Kindly select some sites'); 
      setNotifSaveBtnClicked(false);
      return;
    }
    let tempSites = siteSelected.map((item) => item.value);

    if(deviceTypeSelected.length === 0) { 
      ErrorToast('Kindly select a type of device'); 
      setNotifSaveBtnClicked(false);
      return; 
    }
    let tempDeviceTypes = deviceTypeSelected.map((item) => item.value);

    if(eventCodeSelected.length === 0) { 
      ErrorToast('Kindly select a type of event'); 
      setNotifSaveBtnClicked(false);
      return; 
    }
    let tempEventType = eventCodeSelected.map((item) => item.value);

    if(realTimeEnabled && (mentionRealTimeSubject === "" ||  mentionRealTimeMessage === "")){
      ErrorToast("Kindly fill the real time email notification template");
      return;
    }

    if(mentionRealTimeSubject === "" ||  mentionRealTimeMessage === ""){
      ErrorToast("Kindly fill the real time email notification template");
      return;
    }

    if(hourlyEnabled && (mentionHourlySubject === "" ||  mentionHourlyMessage === "")){
      ErrorToast("Kindly fill the hourly email notification template");
      return;
    }

    if(dailyEnabled && (mentionDailySubject === "" ||  mentionDailyMessage === "")){
      ErrorToast("Kindly fill the daily email notification template");
      return;
    }

    if(weeklyEnabled && (mentionWeeklySubject === "" ||  mentionWeeklyMessage === "")){
      ErrorToast("Kindly fill the weekly email notification template");
      return;
    }

    if(monthlyEnabled && (mentionMonthlySubject === "" ||  mentionMonthlyMessage === "")){
      ErrorToast("Kindly fill the monthly email notification template");
      return;
    }
  
    setEnableAdd(false);
    let lastForm = mainCustomerList[mainCustomerList.length - 1];
    let contactNameEntered = lastForm?.Name;
    let contactEmailEntered = lastForm?.ContactEmail;
    let contactNumberEntered = lastForm?.ContactNumber;
    const emailPattern = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;
    
    if (contactNameEntered && contactEmailEntered && contactNumberEntered) {
      if (enableAdd) {
        WarningToast("Please complete the contact details to add another");
      } else {
        if (emailPattern.test(contactEmailEntered)) {
          // Valid email logic
        } else {
          ErrorToast("Please enter email id properly");
          return;
        }
        if (contactNumberEntered.length !== 10) {
          ErrorToast("Please enter contact number properly");
          return;
        }
      }
    }
    
    let isEmptyFields = false;
    if(mainCustomerList.length === 1){
      for(let x=0; x<mainCustomerList.length; x++){
        if((mainCustomerList[x].Name == "" || mainCustomerList[x].ContactNumber == "" || mainCustomerList[x].ContactEmail == "")  
        && !(mainCustomerList[x].Name == "" && mainCustomerList[x].ContactNumber == "" && mainCustomerList[x].ContactEmail == "" && mainCustomerList[x].Designation == ""))
        {
          ErrorToast('Kindly complete the custom contact details.');
          return;
        } 
      }  
    }else{
      for(let x=0; x<mainCustomerList.length; x++){
        if((mainCustomerList[x].Name == "" || mainCustomerList[x].ContactNumber == "" || mainCustomerList[x].ContactEmail == ""))
        {
          ErrorToast('Kindly complete the custom contact details.');
          return;
        } 
      }  
    }

    let tempEmergencyContact;
    if(emergencyContact){
      tempEmergencyContact = "yes"
    }else{
      tempEmergencyContact = "no"
    }

    let tempCustomContact = mainCustomerList.map((item) => {
      let obj = {
        ...item,
        Id: item.Id.toString(),
      }
      return obj;
    });

    // if(enableAdd){
    //   WarningToast("Please complete the custom contact details")
    //   return;
    // }
    if(selectedNotifObj === null){
      // Add Part
      let obj = {
        NotificationtemplateName: notificationName.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
        NotificationConfigurationDetail: {
          LstSites: tempSites,
          LstDeviecType: tempDeviceTypes,
          LstEventCode: tempEventType,
          // NotificationTemplateEmail: {
          //   Subject: "Test Subject",
          //   Body: "Test Body",
          //   Type: "Test Type",
          // },
          LstNotificationTemplateEmail: [{
            Subject: mentionRealTimeSubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionRealTimeMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "RealTime",
            IsEnabled: realTimeEnabled.toString()
          },
          {
            Subject: mentionHourlySubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionHourlyMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Hourly",
            IsEnabled: hourlyEnabled.toString()
          },
          {
            Subject: mentionDailySubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionDailyMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Daily",
            IsEnabled: dailyEnabled.toString()
          },
          {
            Subject: mentionWeeklySubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionWeeklyMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Weekly",
            IsEnabled: weeklyEnabled.toString()
          },
          {
            Subject: mentionMonthlySubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionMonthlyMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Monthly",
            IsEnabled: monthlyEnabled.toString()
          }],
          // EmergencyContact: emergencyContact,
          // EmergencyContact: emergencyContact,
          EmergencyContact: tempEmergencyContact,
          // LstUserSite: [
          //   {
          //     Item1: '1',//User unique id
          //     Item2: '1'// site id
          //   }
          // ],
          LstUserSite: selectedUsersList,
          LstCustomContact: tempCustomContact
        //   LstCustomContact: [{
        //     Name: "Cust 1",
        //     Designation: "Designation 1",
        //     ContactNumber: "87954621013",
        //     ContactEmail: "abc@abc.abc"
        // }]
        }
      }
      addNotifs(obj)
        .then(res => {
          if(res.Status == "success"){
            SuccessToast(res.Message);
            getNotificationList();
            clearInputFields();
            // setIsNotifFormEnabled(false);
            setNotifSaveBtnClicked(false);      
          }else{
            ErrorToast(res.Message);
          }
        }).catch(err => console.log(err));
    }else{
      // Update Part
      let updateObj = {
        NotificationConfigurationID: selectedNotifObj.NotificationConfigurationID, 
        NotificationtemplateName: notificationName.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
        NotificationConfigurationDetail: {
          LstSites: tempSites,
          LstDeviecType: tempDeviceTypes,
          LstEventCode: tempEventType,
          // NotificationTemplateEmail: {
          //   Subject: "Test Subject",
          //   Body: "Test Body",
          //   Type: "Test Type",
          // },
          LstNotificationTemplateEmail: [{
            Subject: mentionRealTimeSubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionRealTimeMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "RealTime",
            IsEnabled: realTimeEnabled.toString()
          },
          {
            Subject: mentionHourlySubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionHourlyMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Hourly",
            IsEnabled: hourlyEnabled.toString()
          },
          {
            Subject: mentionDailySubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionDailyMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Daily",
            IsEnabled: dailyEnabled.toString()
          },
          {
            Subject: mentionWeeklySubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionWeeklyMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Weekly",
            IsEnabled: weeklyEnabled.toString()
          },
          {
            Subject: mentionMonthlySubject.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Body: mentionMonthlyMessage.replaceAll("\\","\\\\").replaceAll("'", `''`).replaceAll('"', '\\"'),
            Type: "Monthly",
            IsEnabled: monthlyEnabled.toString()
          }],
          EmergencyContact: tempEmergencyContact,
          // LstUserSite: [
          //   {
          //     Item1: '1',
          //     Item2: '1'
          //   }
          // ],
          LstUserSite: selectedUsersList,
          LstCustomContact: tempCustomContact
        //   LstCustomContact: [{
        //     Name: "Cust 1",
        //     Designation: "Designation 1",
        //     ContactNumber: "87954621013",
        //     ContactEmail: "abc@abc.abc"
        // }]
        }
      }
      updateNotifs(updateObj)
        .then(res => {
          if(res.Status == "success"){
            SuccessToast(res.Message);
            getNotificationList();
            setIsNotifFormEnabled(false);
            setNotifSaveBtnClicked(false);      
          }else{
            ErrorToast(res.Message);
          }
        }).catch(err => console.log(err));
    }
  }

  const getNotificationList = () => {
    getNotifs()
      .then(res => {
        if(res.Status == "success"){
          setNotifList(res.Data);
          setMainNotifList(res.Data);
        }else{

        }
      })
      .catch(err => console.log(err));
  }

  const getSiteListFn = () => {
    try {
      getSiteList().then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            setSiteListData(resp.Data)
          } else {
            setSiteListData([])
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

//   const getClients = () => {
//     try {
//         var obj = {
//             "action": "getClients"
//         }
//         Addsitedetails(obj).then((resp) => {
//             if (resp != undefined) {
//                 if (resp.status == "Ok") {
//                     console.log("ClientNames", resp.message);
//                     const temp = resp.message.map((item) => ({
//                       value: item.clientID.toString(),
//                       label: item.clientName,
//                     })) 
//                     console.log("clientListArray", clientListArray); 
//                     setClients(resp.message)
//                     setClientListArray(temp);
//                 } else {
//                     setClients(null)
//                     // setSitestoBind(null) //10112021
//                 }
//             }
//         })
//     } catch (error) {
//         console.error(error)
//     }
// }

//   useEffect(() => {
//     getClients();
//   }, []);

  const getDeviceTypeListFn = () => {
    try {
      getDeviceTypeList().then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            setDeviceTypeListData(resp.Data)
          } else {
            setDeviceTypeListData([])
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

const siteListArray = siteListData.map((site) => ({
  value: site.SiteId,
  label: site.SiteName,
}));

const deviceTypeListArray = deviceTypeListData.map((device) => ({
  value: device.DeviceTypeId,
  label: device.DeviceType,
}));

  useEffect(() => {
    getMentionsList()
      .then((res) => {
        if(res.Status == "success"){
          setMentionList(res.Data);
        }
      })
      .catch(err => console.log(err));
  }, []);

  useEffect(() => {
    if(addNewClicked){
      //Empty all the fields(
      clearInputFields();
    }
  }, [addNewClicked]);

  const clearInputFields = () => {
    setNotificationName("");
    setSiteSelected([]);
    // setClientSelected([]);
    setSiteListString("");
    setDeviceTypeSelected([]);
    setEventCodeSelected([]);
    setRealTimeEnabled(false);
    setRealTimeSubject("");      
    setMentionRealTimeSubject("");
    setRealTimeMessage("");
    setMentionRealTimeMessage("");
    setHourlyEnabled(false);
    setHourlySubject("");
    setMentionHourlySubject("");
    setHourlyMessage("");      
    setMentionHourlyMessage("");
    setDailyEnabled(false);
    setDailySubject("");
    setMentionDailySubject("");
    setDailyMessage("");
    setMentionDailyMessage("");
    setWeeklyEnabled(false);
    setWeeklySubject("");
    setMentionWeeklySubject("");
    setWeeklyMessage("");
    setMentionWeeklyMessage("");
    setMonthlyEnabled(false);
    setMonthlySubject("");
    setMentionMonthlySubject("");
    setMonthlyMessage("");
    setMentionMonthlyMessage("");
    setEmergencyContact(false);
    setSelectedUsersList([]);
    setUserList([]);
    setCustomContact([{
      Id: 1,
      Name: "",
      Designation: "",
      ContactNumber: "",
      ContactEmail: ""
    }]);
    setMainCustomerList([{
      Id: 1,
      Name: "",
      Designation: "",
      ContactNumber: "",
      ContactEmail: ""
    }])
    setAddNewClicked(false);
    setSelectedNotifObj(null);
  }

  useEffect(() => {
    getSiteListFn();
    getDeviceTypeListFn();
  }, [])

  const subjectHandler = (e, type, newValue, newPlainTextValue) => {
    let tempString = newPlainTextValue;
    switch (type) {
      case REALTIME:
        setRealTimeSubject(e.target.value);
        setMentionRealTimeSubject(tempString);
        break;
      case HOURLY:
        setHourlySubject(e.target.value);
        setMentionHourlySubject(tempString)
        break;
      case DAILY:
        setDailySubject(e.target.value);
        setMentionDailySubject(tempString)
        break;
      case WEEKLY:
        setWeeklySubject(e.target.value);
        setMentionWeeklySubject(tempString)
        break;
      case MONTHLY:
        setMonthlySubject(e.target.value);
        setMentionMonthlySubject(tempString)
        break;
      default:
        break;
    }
  }

  const messageHandler = (e, type,newValue, newPlainTextValue) => {
    let tempString = newPlainTextValue;
    switch (type) {
      case REALTIME:
        setRealTimeMessage(e.target.value);
        // setMentionRealTimeMessage(tempString.replaceAll("{", "[").replaceAll("}", "]"));
        setMentionRealTimeMessage(tempString);
        break;
      case HOURLY:
        setHourlyMessage(e.target.value);
        setMentionHourlyMessage(tempString)
        break;
      case DAILY:
        setDailyMessage(e.target.value);
        setMentionDailyMessage(tempString)
        break;
      case WEEKLY:
        setWeeklyMessage(e.target.value);
        setMentionWeeklyMessage(tempString)
        break;
      case MONTHLY:
        setMonthlyMessage(e.target.value);
        setMentionMonthlyMessage(tempString)
        break;
      default:
        break;
    }    
  }

  const notifSelected = (item) => {
    if(item.NotificationConfigurationID == selectedNotifObj?.NotificationConfigurationID){
      return;
    }
    if(selectedNotifObj != null && isNotifFormEnabled){
      Swal.fire({
        title: 'Operation in Progress.',
        text: "You have started the operation for a notification, do you want to discard any changes and continue to the other notification.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#027aaa',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok!',
        allowOutsideClick: false
      }).then((result) => {
        if(result.isConfirmed){
            getNotificationById(item);
            // setIsNotifFormEnabled(false);
            // setIsNotifFormEnabled(false);
            // setIsEmptyForm(false);
        }
      })
    }else{
      getNotificationById(item);
      setIsNotifFormEnabled(false);
      setIsEmptyForm(false);
    }
  }

  const getNotificationById = (item) => {
    getNotifById(item.NotificationConfigurationID)
            .then((res) => {
              if(res.Status == "success"){
                let item = res.Data;
                setSelectedNotifObj(res.Data);
                setNotificationName(item.NotificationtemplateName);
                let siteTempArr = siteListArray.filter(site => item.NotificationConfigurationDetail.LstSites.includes(site.value));
                setSiteSelected(siteTempArr);
                let deviceTypeArr = deviceTypeListArray.filter(device => item.NotificationConfigurationDetail.LstDeviecType.includes(device.value))
                setDeviceTypeSelected(deviceTypeArr);
                let eventCodeArr = eventCodeListArray.filter((event) => item.NotificationConfigurationDetail.LstEventCode.includes(event.value));
                setEventCodeSelected(eventCodeArr);
                //Block to set value to email alert template.
                //1.RealTime
                let realTimeArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "RealTime");
                if(realTimeArr.length > 0){
                  setRealTimeSubject(realTimeArr[0]?.Subject);
                  setMentionRealTimeSubject(realTimeArr[0]?.Subject);
                  setRealTimeMessage(realTimeArr[0]?.Body);
                  setMentionRealTimeMessage(realTimeArr[0]?.Body);
                  setRealTimeEnabled(String(realTimeArr[0]?.IsEnabled) == "true");  
                }else{
                  setRealTimeSubject("");
                  setMentionRealTimeSubject("");
                  setRealTimeMessage("");
                  setMentionRealTimeMessage("");
                  setRealTimeEnabled(false);              
                }
      
                //2.Hourly
                let hourlyArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "Hourly");
                if(hourlyArr.length > 0){
                  setHourlySubject(hourlyArr[0].Subject);
                  setMentionHourlySubject(hourlyArr[0].Subject);
                  setHourlyMessage(hourlyArr[0].Body);
                  setMentionHourlyMessage(hourlyArr[0].Body);
                  setHourlyEnabled(String(hourlyArr[0].IsEnabled) == "true");  
                }else{
                  setHourlySubject("");
                  setMentionHourlySubject("");
                  setHourlyMessage("");
                  setMentionHourlyMessage("");
                  setHourlyEnabled(false);  
                }
      
                //3.Daily
                let dailyArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "Daily");
                if(dailyArr.length > 0){
                  setDailySubject(dailyArr[0].Subject);
                  setMentionDailySubject(dailyArr[0].Subject);
                  setDailyMessage(dailyArr[0].Body);
                  setMentionDailyMessage(dailyArr[0].Body);
                  setDailyEnabled(String(dailyArr[0].IsEnabled) == "true");  
                }else{
                  setDailySubject("");
                  setMentionDailySubject("");
                  setDailyMessage("");
                  setMentionDailyMessage("");
                  setDailyEnabled(false);  
                }
      
                //4.Weekly
                let weeklyArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "Weekly");
                if(weeklyArr.length > 0){
                  setWeeklySubject(weeklyArr[0].Subject);
                  setMentionWeeklySubject(weeklyArr[0].Subject);
                  setWeeklyMessage(weeklyArr[0].Body);
                  setMentionWeeklyMessage(weeklyArr[0].Body);
                  setWeeklyEnabled(String(weeklyArr[0].IsEnabled) == "true");  
                }else{  
                  setWeeklySubject("");
                  setMentionWeeklySubject("");
                  setWeeklyMessage("");
                  setMentionWeeklyMessage("");
                  setWeeklyEnabled(false);  
                }
      
                //5.Monthly
                let monthlyArr = item.NotificationConfigurationDetail.LstNotificationTemplateEmail.filter((item) => item.Type == "Monthly");
                if(monthlyArr.length > 0){
                  setMonthlySubject(monthlyArr[0].Subject);
                  setMentionMonthlySubject(monthlyArr[0].Subject);
                  setMonthlyMessage(monthlyArr[0].Body);
                  setMentionMonthlyMessage(monthlyArr[0].Body);
                  setMonthlyEnabled(String(monthlyArr[0].IsEnabled) == "true");  
                }else{
                  setMonthlySubject("");
                  setMentionMonthlySubject("");
                  setMonthlyMessage("");
                  setMentionMonthlyMessage("");
                  setMonthlyEnabled(false);  
                }
      
                // For Registered Users
                // setCustomContact(item.NotificationConfigurationDetail.LstCustomContact)
                if (item.NotificationConfigurationDetail.LstCustomContact.length == 0) {
                  let arr = [{
                    Id: 0,
                    Name: "",
                    Designation: "",
                    ContactNumber: "",
                    ContactEmail: ""
                  }];
                  setCount(0);
                  setMainCustomerList(arr);
                  setCustomContact(arr);
                } else {
                  let initCount = item.NotificationConfigurationDetail.LstCustomContact[item.NotificationConfigurationDetail.LstCustomContact.length - 1].Id;
                  setCount(initCount);
                  setMainCustomerList(item.NotificationConfigurationDetail.LstCustomContact);
                  setCustomContact(item.NotificationConfigurationDetail.LstCustomContact)
                }
                // setSelectedRegUsers(item.NotificationConfigurationDetail.LstUserSite);
                let getEmergencyContact = false;
                if(item.NotificationConfigurationDetail.EmergencyContact == "yes"){
                  getEmergencyContact = true;
                }
                setEmergencyContact(getEmergencyContact);
                setSelectedUsersList(item.NotificationConfigurationDetail.LstUserSite);
                setIsNotifFormEnabled(false);
                // setIsNotifFormEnabled(true);
              }
            })
            .catch(err => {
              console.log(err);
            })
  }

  const onAddClick = (e) => {
    console.log("Button Clicked", enableAdd);
    e.preventDefault();
    let lastForm = mainCustomerList[mainCustomerList.length - 1];
    console.log("Button Clicked", lastForm);
    let contactNameEntered = lastForm?.Name;
    let contactEmailEntered = lastForm?.ContactEmail;
    let contactNumberEntered = lastForm?.ContactNumber;
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+\.com$/i;
    const emailPattern = /^((?!\.)[\w\-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gm;
    // if (emailPattern.test(contactEmailEntered)) {
    // } else {
    //   ErrorToast("Please enter email id properly")
    //   return;
    // }

    if (enableAdd) {
      WarningToast("Please complete the contact details to add another")
      return;
    } else {
      console.log("Button Clicked", count, contactEmailEntered, contactNumberEntered);
      if(contactEmailEntered != undefined && contactNumberEntered != undefined){
        if (emailPattern.test(contactEmailEntered)) { } else {
          ErrorToast("Please enter email id properly")
          return;
        }
        if (contactNumberEntered.length != 10) {
          ErrorToast("Please enter contact number properly")
          return;
        }
      }
    }
    console.log("Button Clicked", count);
    // let arr = customContact;
    // arr.push(<div className="grid-container Notificationgrid-container grid-container--fit">A</div>);
    // arr.push(customContactDiv);
    // console.log("Button Clicked", arr);
    let c = count + 1;
    let tempObj = {
        // Id: parseInt(mainCustomerList.length+1),
        Id: c,
        Name: "",
        Designation: "",
        ContactNumber: "",
        ContactEmail: ""
    }
    let tempArr = mainCustomerList
    tempArr.push(tempObj);
    setMainCustomerList(tempArr);
    setCustomContact(tempArr);
    // setCustomContact(arr);
    setCount(c)
  }
  console.log("Delete Testing", count, customContact);

  const onDeleteClick = (e, id) => {
    e.preventDefault();
    console.log("Delete Testing", id);
    console.log("Delete Testing", customContact);
    let temp = customContact.filter(item => item.Id == id);
    if(temp[0].Name === "" || temp[0].Designation === "" || temp[0].ContactNumber === "" || temp[0].ContactEmail === ""){
      setEnableAdd(false);
    }
    let arr = mainCustomerList.filter(item => item.Id != id);
    setMainCustomerList(arr);
    let arrTemp = customContact.filter(item => item.Id != id)
    console.log("Delete Testing", arrTemp);
    setCustomContact(arrTemp);
  }

  const searchNotifList = (e) => {
    setNotifSearch(e.target.value);
    let tempArr = mainNotifList.filter(x => x.NotificationtemplateName.toLowerCase().includes(e.target.value.toLowerCase()))
    setNotifList(tempArr);
  }

  return (
    <>
      <div className="col-lg-12 mt-2">
        <div className="SiteMainContainer">
          <div className="SiteLeftContainer">
            <div className="m-2 HeaderSiteList">
              <input
                id="Search_txt"
                className="SearchViewInput1"
                // ref={focusSearchDiv}
                type="text"
                // onChange={(e) =>
                //   onSearchChange(e.target.value, selectedSiteTypeList)
                // }
                placeholder="Search"
                // autoFocus
                value={notifSearch}
                onChange={(e) => searchNotifList(e)}
              />
              <div className="SearchTextDivConfigTool">
                {
                  isDarkTheme ?
                  <img
                    className="EyeIcon"
                    src={SearchIcon}
                    alt="search icon"
                    // onClick={() => ToggeleSearch()}
                  /> :
                  <img
                  className="EyeIcon"
                  src={SearchIconWhite} height="15"
                  alt="search icon"
                  // onClick={() => ToggeleSearch()}
                />

                }
                {/* {!issearch && (
                    <img
                      className="EyeIcon"
                      src={Cross}
                      onClick={() => ToggeleSearch()}
                    />
                  )} */}
              </div>
            </div>
            <div className="NotificationList">
              {
                  notifList && (
                    notifList?.length > 0 ?
                    notifList.map((item) => {
                      return (
                        <div
                          className={selectedNotifObj?.NotificationConfigurationID === item.NotificationConfigurationID ? "OneList SelectedList" : "OneList"}
                          key={item.NotificationConfigurationID}
                          onClick={(e) => {
                            e.stopPropagation();
                            notifSelected(item);
                          }}
                        >
                          <p className="ListofText">
                            {item.NotificationtemplateName}
                            {/* <span className="SiteNameText"> - {item.siteName}</span> */}
                          </p>
                        </div>
                      );
                    }) : <div className="no-data-found"><p>No Notification Found.</p></div>
                  )
                  }
                {!notifList && (
                  <div className="no-data-found">No Notification List Available.</div>
                )}
            </div>
          </div>
          <div className="SiteRightContainer">
            <form>
              <div className="Notification-form">
                <div className="NotificationTextBox">
                  <p className="SiteNameText">
                    Name 
                  </p>
                  <input
                    className="MessageTextBox"
                    placeholder="Enter notification name here"
                    value={notificationName}
                    onChange={(e) => setNotificationName(e.target.value)}
                    disabled={!isNotifFormEnabled}
                  />
                </div>
                {/* <span className="Error_P">Enter valid Name</span> */}
                <div className="grid-container Notificationgrid-container grid-container--fit">
                {/* <div className="NotificationTextBox">
                    <p className="SiteNameText">
                      Customer Name
                    </p>

                    <MultiSelect
                      disabled={!isNotifFormEnabled}
                      options={clientListArray}
                      value={clientSelected}
                      onChange={setClientSelected}
                      labelledBy={"Select"}
                      overrideStrings={{
                        selectSomeItems: "Select customer name",
                        allItemsAreSelected: "All customer names are selected"
                    }}
                    ArrowRenderer={() => 
                      <DropArrow />
                    }
                    />

                  </div> */}
                  <div className="NotificationTextBox">
                    <p className="SiteNameText">
                      Site
                    </p>
                    {/* <select className="MessageTextBox1" placeholder="Select Site" disabled={!isNotifFormEnabled}>
                      <option key="0" disabled={false} value="">
                        Select Site
                      </option>
                      <option></option>
                    </select> */}

                    <MultiSelect
                      // className="MessageTextBox1"
                      // placeholder="Select Site"
                      disabled={!isNotifFormEnabled}
                      // options={siteList}
                      options={siteListArray}
                      value={siteSelected}
                      onChange={setSiteSelected}
                      labelledBy={"Select"}
                      overrideStrings={{
                        //   // selectSomeItems: `${siteList[siteList.length - 1].value}`
                        selectSomeItems: "Select site",
                        allItemsAreSelected: "All sites are selected"
                    }}
                    ArrowRenderer={() => 
                      <DropArrow />
                    }
                    />

                  </div>
                  <div className="NotificationTextBox">
                    <p className="SiteNameText">
                      Device Type 
                    </p>
                    {/* <select
                      className="MessageTextBox1"
                      placeholder="Select Device type"
                      disabled={!isNotifFormEnabled}
                    >
                      <option key="0" disabled={false} value="">
                        Select Device type
                      </option>
                      <option></option>
                    </select> */}

                    <MultiSelect
                      //  className="MessageTextBox1"
                      // placeholder="Select Device type"
                      disabled={!isNotifFormEnabled}
                      // options={deviceTypeList}
                      options={deviceTypeListArray}
                      value={deviceTypeSelected}
                      onChange={setDeviceTypeSelected}
                      labelledBy={"Select"}
                      overrideStrings={{
                        //   // selectSomeItems: `${siteList[siteList.length - 1].value}`
                        selectSomeItems: "Select device type",
                        allItemsAreSelected: "All devices are selected"
                    }}
                    ArrowRenderer={() => 
                      <DropArrow />
                    }
                    />
                  </div>
                  <div className="NotificationTextBox">
                    <p className="SiteNameText">
                      Event Code 
                    </p>
                    {/* <select
                      className="MessageTextBox1"
                      placeholder="Select event code"
                      disabled={!isNotifFormEnabled}
                    >
                      <option key="0" disabled={false} value="">
                        Select event code
                      </option>
                      <option></option>
                    </select> */}
                    <MultiSelect
                    //  className="MessageTextBox1"
                    //  placeholder="Select event code"
                      disabled={!isNotifFormEnabled}
                      // options={eventCodeList}
                      options={eventCodeListArray}
                      value={eventCodeSelected}
                      onChange={setEventCodeSelected}
                      labelledBy={"Select"}
                      overrideStrings={{
                        //   // selectSomeItems: `${siteList[siteList.length - 1].value}`
                        selectSomeItems: "Select event code type",
                        allItemsAreSelected: "All event codes are selected"
                    }}
                    ArrowRenderer={() => 
                      <DropArrow />
                    }
                    />
                  </div>
                </div>
                {/* <div className="SiteNameText">
                  <input className="checkbox_custom" type="checkbox" checked={webEnabled} onChange={(e) => setWebEnabled(!webEnabled)} disabled={!isNotifFormEnabled} />
                  Web Notification Template<br></br>
                  <MentionsInput
                    // className="NotificationMsgBox"
                    rows={50}
                    style={mentionsInputStyles} 
                    disabled={!isNotifFormEnabled}
                    placeholder="Enter message details here"
                    // rows={5}
                    value={webMessage}
                    onChange={(e) => webMessageChanged(e)}
                  >
                      <Mention data={users} style={mentionStyles} /> 
                  </MentionsInput>   
                </div>
                <div className="SiteNameText">
                  <input className="checkbox_custom" type="checkbox" checked={mobileEnabled} onChange={(e) => setMobileEnabled(!mobileEnabled)} disabled={!isNotifFormEnabled} />
                  Mobile Notification Template<br></br>
                  <MentionsInput
                    style={mentionsInputStyles}
                    placeholder="Enter message details here"
                    value={mobileMessage}
                    disabled={!isNotifFormEnabled}
                    onChange={(e) => mobileMessageChanged(e)}
                  >
                    <Mention data={users} style={mentionStyles} />
                  </MentionsInput>
                </div> */}
                {/* <div className="SiteNameText">
                  <input className="checkbox_custom" type="checkbox" checked={emailEnabled} onChange={(e) => setEmailEnabled(!emailEnabled)} disabled={!isNotifFormEnabled} />
                  Email Notification Template<br></br>
                  <MentionsInput
                    style={mentionsInputStyles}
                    placeholder="Enter message details here"
                    value={emailMessage}
                    disabled={!isNotifFormEnabled}
                    onChange={(e) => emailMessageChanged(e)}
                  >
                    <Mention data={users} style={mentionStyles} />
                  </MentionsInput>
                </div> */}

                <p className="SiteNameText">Email Notification Template</p>
                <div className="NotificationEmailMsgBox">
                  <div className="EmailMsgBoxHeading">
                    <ul className="nav nav-pills">
                      <li className="EmailNotificationNavItem" disabled={!isNotifFormEnabled}>
                        <a className={
                            selectedSiteTab == "realTime"
                              ? "nav-link active bottom-three-sites"
                              : "inactive-text-dashboard bottom-three-sites"
                          }
                          onClick={() => setSelectedSiteTab("realTime")}
                        >
                        <div className="Notificationcheckwithlabel">
                          <input checked={realTimeEnabled}
                           onChange={(e) => setRealTimeEnabled(!realTimeEnabled)} 
                           type="checkbox" 
                           disabled={!isNotifFormEnabled} style={{marginRight: "3px"}} />
                          <label style={{ margin: "0px"}}>Real Time</label> 
                        </div>
                        </a>
                        {/* </p> */}
                      </li>
                      {/* <li className="EmailNotificationNavItem">
                        <a
                          className={
                            selectedSiteTab == "hourly"
                              ? "nav-link active bottom-three-sites"
                              : "inactive-text-dashboard bottom-three-sites"
                          }
                          onClick={() => setSelectedSiteTab("hourly")}
                        >
                        <div className="Notificationcheckwithlabel">
                          <input checked={hourlyEnabled} onChange={(e) => setHourlyEnabled(!hourlyEnabled)} type="checkbox" disabled={!isNotifFormEnabled} style={{marginRight: "3px"}} /> 
                          <label style={{margin: "0px"}}>Hourly</label>
                        </div>
                        </a>
                      </li>
                      <li className="EmailNotificationNavItem">
                        <a
                          className={
                            selectedSiteTab == "daily"
                              ? "nav-link active bottom-three-sites"
                              : "inactive-text-dashboard bottom-three-sites"
                          }
                          onClick={() => setSelectedSiteTab("daily")}
                        >
                        <div className="Notificationcheckwithlabel">
                          <input checked={dailyEnabled} onChange={(e) => setDailyEnabled(!dailyEnabled)} type="checkbox" disabled={!isNotifFormEnabled} style={{marginRight: "3px"}} /> 
                          <label style={{margin: "0px"}}>Daily</label>
                        </div>
                        </a>
                      </li>
                      <li className="EmailNotificationNavItem">
                        <a
                          className={
                            selectedSiteTab == "weekly"
                              ? "nav-link active bottom-three-sites"
                              : "inactive-text-dashboard bottom-three-sites"
                          }
                          onClick={() => setSelectedSiteTab("weekly")}
                        >
                        <div className="Notificationcheckwithlabel">
                          <input checked={weeklyEnabled} onChange={(e) => setWeeklyEnabled(!weeklyEnabled)} type="checkbox" disabled={!isNotifFormEnabled} style={{marginRight: "3px"}} /> 
                          <label style={{margin: "0px"}}>Weekly</label>
                        </div>
                        </a>
                      </li>
                      <li className="EmailNotificationNavItem">
                        <a
                          className={
                            selectedSiteTab == "monthly"
                              ? "nav-link active bottom-three-sites"
                              : "inactive-text-dashboard bottom-three-sites"
                          }
                          onClick={() => setSelectedSiteTab("monthly")}
                        >
                        <div className="Notificationcheckwithlabel">
                          <input checked={monthlyEnabled} onChange={(e) => setMonthlyEnabled(!monthlyEnabled)} type="checkbox" disabled={!isNotifFormEnabled} style={{marginRight: "3px"}} /> 
                          <label style={{margin: "0px"}}>Monthly</label>
                        </div>
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="tab-content">
                    {selectedSiteTab && selectedSiteTab == "realTime" && (
                      <>
                        <div className="startDiv">
                          {/* <input
                            className="EmailMsgSubject"
                            type="text"
                            placeholder="Enter Subject here"
                            disabled={!isNotifFormEnabled}
                          /> */}
                          <MentionsInput
                            style={mentionsInputStyles} 
                            singleLine
                            placeholder="Enter Subject here"
                            disabled={!isNotifFormEnabled}
                            value={realTimeSubject}
                            onChange={(e, newValue, newPlainTextValue) => subjectHandler(e, REALTIME, newValue, newPlainTextValue)}
                          >
                            <Mention data={mentionList} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                        <div className="EmailDivideLine"></div>
                        <div className="mentionDiv">
                          <MentionsInput
                          style={mentionsInputStyles}
                          placeholder="Enter message details here"
                          rows={5}
                          disabled={!isNotifFormEnabled}
                          value={realTimeMessage}
                          onChange={(e,newValue, newPlainTextValue) => messageHandler(e, REALTIME,newValue, newPlainTextValue)}
                          >
                            <Mention data={mentionList} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                          {/* <textarea
                            className="EmailMsgDetail"
                            placeholder="Enter message details here"
                            rows={5}
                            style={{ resize: "none" }}
                            disabled={!isNotifFormEnabled}
                          ></textarea> */}
                        </div>
                      </>
                    )}
                    {/* {selectedSiteTab && selectedSiteTab == "hourly" && (
                      <>
                        <div className="startDiv">
                        <MentionsInput
                            style={mentionsInputStyles} 
                            singleLine
                            placeholder="Enter Subject here"
                            disabled={!isNotifFormEnabled}
                            value={hourlySubject}
                            onChange={(e, newValue, newPlainTextValue) => subjectHandler(e, HOURLY, newValue, newPlainTextValue)}
                          >
                            <Mention data={[]} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                        <div className="EmailDivideLine"></div>
                        <div>
                          <MentionsInput
                          style={mentionsInputStyles}
                          placeholder="Enter message details here"
                          rows={5}
                          disabled={!isNotifFormEnabled}
                          value={hourlyMessage}
                          onChange={(e,newValue, newPlainTextValue) => messageHandler(e, HOURLY,newValue, newPlainTextValue)}
                          >
                            <Mention data={[]} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                      </>
                    )}
                    {selectedSiteTab && selectedSiteTab == "daily" && (
                      <>
                        <div className="startDiv">
                        <MentionsInput
                            style={mentionsInputStyles} 
                            singleLine
                            placeholder="Enter Subject here"
                            disabled={!isNotifFormEnabled}
                            value={dailySubject}
                            onChange={(e, newValue, newPlainTextValue) => subjectHandler(e, DAILY, newValue, newPlainTextValue)}
                          >
                            <Mention data={[]} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                        <div className="EmailDivideLine"></div>
                        <div>
                          <MentionsInput
                          style={mentionsInputStyles}
                          placeholder="Enter message details here"
                          rows={5}
                          disabled={!isNotifFormEnabled}
                          value={dailyMessage}
                          onChange={(e,newValue, newPlainTextValue) => messageHandler(e, DAILY,newValue, newPlainTextValue)}
                          >
                            <Mention data={[]} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                      </>
                    )}
                    {selectedSiteTab && selectedSiteTab == "weekly" && (
                      <>
                        <div className="startDiv">
                        <MentionsInput
                            style={mentionsInputStyles} 
                            singleLine
                            placeholder="Enter Subject here"
                            disabled={!isNotifFormEnabled}
                            value={weeklySubject}
                            onChange={(e,  newValue, newPlainTextValue) => subjectHandler(e, WEEKLY, newValue, newPlainTextValue)}
                          >
                            <Mention data={[]} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                        <div className="EmailDivideLine"></div>
                        <div>
                          <MentionsInput
                          style={mentionsInputStyles}
                          placeholder="Enter message details here"
                          rows={5}
                          disabled={!isNotifFormEnabled}
                          value={weeklyMessage}
                          onChange={(e,newValue, newPlainTextValue) => messageHandler(e, WEEKLY,newValue, newPlainTextValue)}
                          >
                            <Mention data={[]} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                      </>
                    )}
                    {selectedSiteTab && selectedSiteTab == "monthly" && (
                      <>
                        <div className="startDiv">
                        <MentionsInput
                            style={mentionsInputStyles} 
                            singleLine
                            placeholder="Enter Subject here"
                            disabled={!isNotifFormEnabled}
                            value={monthlySubject}
                            onChange={(e,newValue, newPlainTextValue) => subjectHandler(e, MONTHLY, newValue, newPlainTextValue)}
                          >
                            <Mention data={[]} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>
                        </div>
                        <div className="EmailDivideLine"></div>
                        <div>
                          <MentionsInput
                          style={mentionsInputStyles}
                          placeholder="Enter message details here"
                          rows={5}
                          disabled={!isNotifFormEnabled}
                          value={monthlyMessage}
                          onChange={(e,newValue, newPlainTextValue) => messageHandler(e, MONTHLY,newValue, newPlainTextValue)}
                          >
                            <Mention data={[]} markup="{__display__}" style={mentionStyles} />
                          </MentionsInput>                    
                          </div>
                      </>
                    )} */}
                  </div>
                </div>

                {/* <div className="SiteNameText spocCheckbox">
                <div style={{display: "flex", alignItems: "center"}}>
                  <input checked={emergencyContact} onChange={(e) => setEmergencyContact(!emergencyContact)} type="checkbox" className="checkbox_custom" disabled={!isNotifFormEnabled} />
                  <label style={{margin: "0px"}}>Emergency Contacts</label> <br></br>
                </div>
                </div> */}
                <RegisteredUsers selectedUsersList={selectedUsersList} setSelectedUsersList={setSelectedUsersList} isNotifFormEnabled={isNotifFormEnabled} userList={userList} setUserList={setUserList} siteListString={siteListString} selectedAllRegUsers={selectedAllRegUsers} setSelectedAllRegUsers={setSelectedAllRegUsers} isDarkTheme={isDarkTheme} />
                <CustomContact addNewClicked={addNewClicked} customContact={customContact} setCustomContact={setCustomContact} onAddClick={onAddClick} isNotifFormEnabled={isNotifFormEnabled} count={count} mainCustomerList={mainCustomerList} onDeleteClick={onDeleteClick} setEnableAdd={setEnableAdd} setMainCustomerList={setMainCustomerList} isDarkTheme={isDarkTheme} />
              </div>
            </form>
          </div>
          {/* <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          /> */}
        </div>
      </div>
    </>
  );
};

export default NotificationContainer;
