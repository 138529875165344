import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { Login } from './Auth/Login/Login'
import Signup from './Auth/Signup/Signup'
import Forgetpassword from './Auth/Forgetpassword/Forgetpassword'
import { SIGNOUT, ROOT, SIGNUP, FORGETPASSWORD, MOSAIC, RESETPASSWORD, VERIFICATION, AUDITLOG} from './../navigation/CONSTANTS';
import PrivateRoute from './Auth/PrivateRoute';
import { NotFound } from './NotFound';
import { useAuth } from './Auth/ProvideAuth';
import RootContainer from '../pages/Root/RootContainer';
import MosaicContainer from '../pages/Mosaic/MosaicContainer'
import { ResetPassword } from './Auth/ResetPassword/ResetPassword'
import Verification from './Auth/Verification/Verification'
import AuditLog from '../pages/Configuration/AuditLog'
import WebRTCPlayer from '../pages/Cameras/WebRTCPlayer'

export const RouterConfig = ({ match }) => {
    let auth = useAuth()
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    return (
        <Switch>
            {/* List all public routes here */}
            <Route path="/login">
                <Login />
            </Route>
            <Route path="/webrtc">
                <>
                    <WebRTCPlayer id="102" url="rtsp://admin:Bisleri%40123@123.63.20.234:554/Streaming/Channels/1002" isEncode={0} upperDivCss="cameraHoverMainDiv"
                        videoCss="player-responsive1" />
                    <WebRTCPlayer id="103" url="rtsp://admin:keesara%40123@103.148.206.180:554/Streaming/Channels/102" isEncode={0} upperDivCss="cameraHoverMainDiv"
                        videoCss="player-responsive1" />
                    <WebRTCPlayer id="104" url="rtsp://admin:keesara%40123@103.148.206.180:554/Streaming/Channels/202" isEncode={0} upperDivCss="cameraHoverMainDiv"
                        videoCss="player-responsive1" />
                    <WebRTCPlayer id="105" url="rtsp://admin:keesara%40123@103.148.206.180:554/Streaming/Channels/1002" isEncode={0} upperDivCss="cameraHoverMainDiv"
                        videoCss="player-responsive1" />
                <WebRTCPlayer id="106" url="rtsp://admin:Bisleri@123@123.63.20.234:554/Streaming/Channels/2502" isEncode={0} />
                <WebRTCPlayer id="107" url="rtsp://admin:bisleri1@14.142.134.154:554/Streaming/Channels/202" isEncode={0} />

                {/* <WebRTCPlayer id="103" url="rtsp://admin:abc%401234@192.168.0.66:554/cam/realmonitor?channel=1&subtype=1" isEncode={0} /> */}
                </>
            </Route>
            <Route path={SIGNUP}>
                <Signup />
            </Route>
            <Route path={FORGETPASSWORD}>
                <Forgetpassword />
                {/* <ResetPassword /> */}
            </Route>
            <Route path={VERIFICATION}>
                <Verification />
            </Route>
            <Route path={RESETPASSWORD}>
                {/* <Forgetpassword /> */}
                <ResetPassword />
            </Route>

            <Route path={AUDITLOG}>
                <AuditLog />
            </Route>

            <Route path={SIGNOUT} component={() => auth.signout()} />

            {/* List all private/auth routes here */}
            <PrivateRoute path={MOSAIC}>
                <MosaicContainer />
            </PrivateRoute>
           
            <PrivateRoute path={ROOT}>
                <RootContainer />
            </PrivateRoute>

            {/* List a generic 404-Not Found route here */}
            <Route path="*">
                <NotFound />
            </Route>

        </Switch>
    )
}
