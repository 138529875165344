import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { useVideo } from '../../CustomHooks/useVideo';
import captureVideoFrame from "capture-video-frame"
import { DownloadIcon } from '../../assets/images';
import screenfull from "screenfull"

const ReactLivePlayer = ({ item, devid, playUrl, devname, streamName, captureVideoFrameData, isSnapShotBtnClick,setIsSnapShotBtnClick, savedRef, isAlertLiveFullscreenClick, videoContainerParentRefInDiv, fullScreenRef, setFullScreenRef, newVideoRef }) => {
    const { count, setCount, videoStartBuffering, videoStopBuffering, videoErrorHandler, getHeaders } = useVideo();
    const [capturedImage, setCapturedImage] = useState(null);
    // const [isSnapShotBtnClick, setIsSnapShotBtnClick] = useState(false);
    const videoRef = useRef(null);

    /* captureImage */
const handleCaptureFrame = (params) => {
    console.log("Rupesh Checks",videoRef)
    // console.log("Rupesh checks videoRef", videoRef.current.getInternalPlayer());
    const video = videoRef.current.getInternalPlayer();
  
//     if (typeof video === 'string') {
//       video = console.log("rupesh checks",video);
//   }
      const frame = captureVideoFrame(video)
      console.log('captured frame', frame)
            setCapturedImage(frame.dataUri)
            var fileUrl = frame.dataUri;
            // captureVideoFrameData(capturedImage)
            captureVideoFrameData(fileUrl)
    // const popupWindow = window.open('', '_blank', 'width=540,height=360');
    // popupWindow.document.open();
    // popupWindow.document.write(`
    // <html>
    // <body>
    //   <img src="${fileUrl}" alt="Screenshot" width="300" height="300" />
    //   <br />
    //   <button class="DataAccessEdit" onclick="capturedFrameDownloadClick('${fileUrl}')">
    //   <img src=${DownloadIcon} alt="download icon" />
    // </button>
    // </body>
    // <script>
    // function capturedFrameDownloadClick(imageData){
    //   var fileUrl = imageData;
    //   fetch(fileUrl)
    //     .then(response => response.blob())
    //     .then(blob => {
    //       debugger
    //       var link = window.document.createElement("a");
    //       link.href = window.URL.createObjectURL(blob, { type: "image" });
    //       link.download = "test.png";
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     });              
    // }
    // </script>
    // </html>`);
    // popupWindow.document.close();
  };
  /* captureImage */

  useEffect(() => {
    //debugger
    console.log("savedRef",savedRef)
    console.log("savedRef",item);
    console.log("savedRef",isSnapShotBtnClick);
    if(isSnapShotBtnClick && savedRef == item?.associatedCameraId){
        setTimeout(() => {
            console.log("savedRef generating snapshot",videoRef.current.getInternalPlayer());
            handleCaptureFrame(videoRef.current.getInternalPlayer())
            setIsSnapShotBtnClick(false)  
        }, 2000);  
    }else if(isSnapShotBtnClick && savedRef == 0){
        setTimeout(() => {
            console.log("savedRef generating snapshot",videoRef.current.getInternalPlayer());
            handleCaptureFrame(videoRef.current.getInternalPlayer())
            setIsSnapShotBtnClick(false)  
        }, 2000);
    }
  }, [isSnapShotBtnClick])

    useEffect(() => {
        console.log("isAlertLiveFullscreenClick", isAlertLiveFullscreenClick)
        console.log("isAlertLiveFullscreenClick", fullScreenRef)
        console.log("isAlertLiveFullscreenClick", videoContainerParentRefInDiv)
        console.log("isAlertLiveFullscreenClick", item)
        if(fullScreenRef == 0){
            if(isAlertLiveFullscreenClick){
                console.log("isAlertLiveFullscreenClick full screen", item)
                screenfull.toggle(videoContainerParentRefInDiv?.current)
                let c = count + 1;
                setCount(c);
                setFullScreenRef(null);
            }else if(!isAlertLiveFullscreenClick && fullScreenRef != null){
                console.log("isAlertLiveFullscreenClick small screen", item)
                screenfull.toggle(videoContainerParentRefInDiv?.current)
                let c = count + 1;
                setCount(c);
                setFullScreenRef(null);
            }
        }else{
            if(isAlertLiveFullscreenClick && fullScreenRef == item?.associatedCameraId){
                console.log("isAlertLiveFullscreenClick full screen", item)
                screenfull.toggle(videoContainerParentRefInDiv?.current)
                let c = count + 1;
                setCount(c);
                setFullScreenRef(null);
            }else if(!isAlertLiveFullscreenClick && (fullScreenRef != null && fullScreenRef == item?.associatedCameraId)){
                console.log("isAlertLiveFullscreenClick small screen", item)
                screenfull.toggle(videoContainerParentRefInDiv?.current)
                let c = count + 1;
                setCount(c);
                setFullScreenRef(null);
            }
        }
    },[isAlertLiveFullscreenClick]);

    // useEffect(() => {
    //     document.addEventListener('fullscreenchange', exitHandler);
    //     function exitHandler() {
    //         if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
    //             console.log("Plain JS screenfull close");
    //             // setIsMosaicFullscreenClick(false);
    //             let c = count + 1;
    //             setCount(c);
    //         } else {
    //             console.log("Plain JS screenfull start");
    //         }
    //     }
    //     return () => {
    //         document.removeEventListener('fullscreenchange', exitHandler);
    //     }
    // }, []);
  
    return (
        <ReactPlayer
            // ref={videoRef}
            ref={newVideoRef}
            key={count}
            id={"video-" + devid}
            itemID={"video-" + streamName}
            url={playUrl}
            playing={true}
            className="chatbotliveplayer"
            width={isAlertLiveFullscreenClick ? "100%" : "264px"}
            height={isAlertLiveFullscreenClick ? "100%" : "150px"}
            controls={true}
            autoPlay
            pip={true} // for picture in picture
            stopOnUnmount={false} // for picture in picture
            style={{ backgroundColor: "rgb(189,188,188)", borderRadius: "14px", position: "relative" }}
            onBuffer={(e) => videoStartBuffering(e, devname)}
            onBufferEnd={(e) => videoStopBuffering(e, devname)}
            onError={(e) => videoErrorHandler(e, devname)}
            config={{
                file: {
                    attributes: {
                        controlsList: "noplaybackrate nodownload",
                        // disablePictureInPicture: isMultiPlay ? false : true, // hide and display pip
                        // controlsList: isMultiPlay ? "noplaybackrate" : "noplaybackrate nodownload",
                        crossorigin: 'anonymous',
                        onContextMenu: (e) => e.preventDefault(),
                    },
                    tracks: [],
                    forceVideo: true,
                    forceAudio: true,
                    http: {
                        headers: getHeaders(),
                    },
                },
            }}
        />
    )
}

export default ReactLivePlayer