import React, { useState } from "react"
import { EmailIcon, Password1Icon, EyeMask, Eye, EmailIconWhite, Password1IconWhite } from '../../assets/images'
import Avatar from 'react-avatar';
import { OverlayTrigger } from 'react-bootstrap'
import Tooltip from 'react-bootstrap/Tooltip'
import { changePassword, updateFirebaseUser } from "../../services/userAuthServices";
import { SuccessToast } from "../../CustomHooks/SuccessToast";
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from 'react-toastify';
import Popover from 'react-bootstrap/Popover';


export default function UserProfileComponent(props) {

    const [newPassword, setNewPassword] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isNotValidPassword, setIsNotValidPassword] = useState(false);
    const changePasswordHandler = async () => {
        if (newPassword === "") {
            ErrorToast("Please enter the new password");
            return;
        }

        var userObj = JSON.parse(localStorage.getItem('user'));
        updateFirebaseUser(userObj.Email, newPassword)
            .then((res) => {
                // await changePassword(props.ProfileData.userId, localStorage.getItem('userPassword') ,newPassword)
                changePassword(props.ProfileData.UserID, localStorage.getItem('userPassword'), newPassword)
                    .then((res) => {
                        if (res.Status == "success") {
                            localStorage.setItem('userPassword', newPassword);
                            SuccessToast(res.Message)
                            setNewPassword("");
                        } else {
                            ErrorToast(res.Message);
                            setNewPassword("");
                        }
                    })
            })
            .catch(function (error) {
                console.error(error);
            });
    }
    const handlePasswordChange = (event) => {
        let tempValue = event.target.value[event.target.value.length - 1];
        if (tempValue == " "){ 
             ErrorToast('Space is not Allow!'); 
             return;
        }
        const newPassword = event.target.value.replace(" ","");
        setNewPassword(newPassword);
        setIsButtonDisabled(
          !(
            newPassword.length > 0 &&
            isCapitalCase(newPassword) &&
            isSpecialCharacter(newPassword) &&
            isNumberInclude(newPassword) &&
            isMinCharacter(newPassword) &&
            isMaxCharacter(newPassword)
          )
        );
        setIsNotValidPassword(!(
          newPassword.length > 0 &&
          isCapitalCase(newPassword) &&
           isSpecialCharacter(newPassword) &&
           isNumberInclude(newPassword) &&
          isMinCharacter(newPassword) &&
          isMaxCharacter(newPassword)
        ))
      };
    
      const isCapitalCase = (pass) => {
        const pattern = /[A-Z]/;
        return pattern.test(pass)
      }
      const isSpecialCharacter = (pass) => {
        const pattern = /[\W_]/;
        return pattern.test(pass)
      };
    
      const isNumberInclude = (pass) => {
        const pattern = /\d/;
        return pattern.test(pass)
      }
      const isMinCharacter = (pass) => {
        return pass.length >= 8;
      };
      const isMaxCharacter = (pass) => {
        return pass.length <= 15 && pass.length >= 1;
      };
    
    
    
      const popover = (
        <Popover id="popover-basic" style={{ width: "200px" }}>
          <Popover.Content>
            <p className={isCapitalCase(newPassword) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>One capital case
            </p>
    
            <p className={isSpecialCharacter(newPassword) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>One special character
            </p>
    
            <p className={isNumberInclude(newPassword) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>One number
            </p>
    
            <p className={isMinCharacter(newPassword) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>Minimum 8 Character
            </p>
    
            <p className={isMaxCharacter(newPassword) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>Maximum 15 Character
            </p>
          </Popover.Content>
        </Popover>
      );

    return (
        <div className="UserDetailsView">
            <div className="AdminBG"></div>
            <div className="RoundShapeDiv">
                <div className="ProfilePicture">
                    <Avatar
                        name={props?.ProfileData?.FullName}
                        round={true}
                        size={125}
                        style={{ margin: "0 auto" }}
                    />
                    {/* <img src={EditButtonIcon} style={{ marginRight: "-10px" }} /> */}
                </div>
            </div>
            <p className="ProfileName">{props?.ProfileData?.FullName}</p>
            <p className="ProfileSubName">{props.UserRole}</p>
            <div style={{ marginTop: "30px", margin: "0 auto" }}>
                <div className="DataBorder">
                    <p className="AboutText">About</p>
                </div>
                {/* <div className="DataBorder">
                    <div className="imageWidth">
                        <img src={AccountIcon} />
                    </div>            
                    <p className="AboutTextDetails">Account ID:
                        <span className="DetailsAboutHead">{props.ProfileData.UserID}</span>
                    </p>
                </div> */}
                <div className="DataBorder">
                    <div className="imageWidth">
                      {
                        props.isDarkTheme ?
                        <img src={EmailIcon} alt="email icon" />
                        :
                        <img src={EmailIconWhite} alt="email icon" />
                      }
                    </div>
                    <OverlayTrigger
                        placement='bottom'
                        overlay={<Tooltip>{props.ProfileData?.Email || props.ProfileData?.email}</Tooltip>}>
                        <p className="AboutTextDetails  camera-name">Email Id:<br></br>
                            <span className="DetailsAboutHead">{props.ProfileData?.Email || props.ProfileData?.email}</span>
                        </p>
                    </OverlayTrigger>

                </div>
                <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
                <div className="DataBorder">
                    <div className="imageWidth">
                      {
                        props.isDarkTheme ?
                        <img src={Password1Icon} alt="passwordlock icon" />
                        :
                        <img src={Password1IconWhite} alt="passwordlock icon" />
                      }
                    </div>

                    <p className="AboutTextDetails"><span className="mr-2">Password:</span>
                <input className="DetailsAboutHead" value={newPassword}
                  onChange={(e) => handlePasswordChange(e)}
                  type="password" style={{ border: "1px solid #464646" }} />

                    </p>
                </div>
                </OverlayTrigger>
                {/* <div className="DataBorder changePassword">
                          <button 
                          onClick={changePasswordHandler}
                          disabled={isButtonDisabled}
                          >
                              Change Password 
                          </button>
                      </div> */}
                      <div className="ChangeButton">
                      <button className="DataAccessEdit" style={{ width: "auto" }}
                        onClick={changePasswordHandler}
                        disabled={isButtonDisabled}
                      >
                        <span className="ViewUpdate">Change Password</span>
                      </button>
                      </div>
                  {/* <div className="DataBorder">
                      <div className="imageWidth">
                          <img src={GroupIcon} />
                      </div>

                      <p className="AboutTextDetails">Group No.
                          <span className="DetailsAboutHead">07</span>
                      </p>
                  </div> */}

            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}