import React,{useState} from 'react';

const initialState = []

export const ConversationStateContext = React.createContext();

const ConversationState = ({children}) => {
    const [state, setState] = useState(initialState)
    const [isOperatorWidgetClicked, setIsOperatorWidgetClicked] = useState(false)
    const [isNotificationAlertIdClicked, setIsNotificationAlertIdClicked] = useState(false)
    
    return (
        // <ConversationStateContext.Provider value={[state,setState]}>{children}</ConversationStateContext.Provider>
        <ConversationStateContext.Provider value={{ state, setState, isOperatorWidgetClicked, setIsOperatorWidgetClicked, isNotificationAlertIdClicked, setIsNotificationAlertIdClicked }}>{children}</ConversationStateContext.Provider>
    );
}

export default ConversationState;