
 export const registerServiceWorker = () => {

    if ('serviceWorker' in navigator) {
        navigator.serviceWorker
        .register('./firebase-messaging-sw.js',{
           // scope:'/'
        })
        .then(function (registration) {
            // console.log('Registration successful, scope is:', registration.scope);
           
            // console.log({ registration })
        }).catch(function (err) {
            console.error('Service worker registration failed, error:');
        });
    }
}