import React, { useState, useEffect } from 'react'
import TimerWidget from '../../UI/TimerWidget'

const TimerCard = ({ getDataFromChild, dateFromParent, setBlankDate }) => {

 const [date, setDate] = useState(dateFromParent);
 const [dateString, setDateString] = useState('');
    useEffect(() => {
        let str = `${date.getHours()}_${date.getMinutes()}_${date.getSeconds()}`
        setDateString(str);
        setBlankDate(str);
    }, []);

 const handleTimerCB = (data, type) => {
    let arr = dateString.split("_");
    let temp = data.toString().length;
    let correctDigit = "";
    if(temp === 1){
        correctDigit = "0" + data.toString();
    }else{
        correctDigit = data.toString();
    }

    if(type === "HOUR"){
        arr[0] = correctDigit;
    }else if(type === "MIN"){
        arr[1] = correctDigit;
    }else{
        arr[2] = correctDigit;
    }
    setDateString(arr.join("_")); 
    getDataFromChild(arr.join("_"));
 }

  return (
    <div className='popup_body' style={{gap: "0.3rem"}}>
        <TimerWidget maxCount={23} handleTimerCB={handleTimerCB} type="HOUR"
        min="00"
        max="23"
        maxLength="2" 
        defaultValue={date.getHours()}
        // defaultValue={0}
        />
        :
        <TimerWidget maxCount={59} handleTimerCB={handleTimerCB} type="MIN" 
        min="00"
        max="59"
        maxLength="2"
        defaultValue={date.getMinutes()}
        // defaultValue={0}
        />
        :
        <TimerWidget maxCount={59} handleTimerCB={handleTimerCB} type="SEC" 
        min="00"
        max="59"
        maxLength="2"
        defaultValue={date.getSeconds()}
        // defaultValue={0}
        />
    </div>
  )
}

export default TimerCard