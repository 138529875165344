import { useState, useEffect } from 'react';
import { Getuserslist } from '../services/userManagementservice';

export const useUsers = () => {
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    const[users, setUsers] = useState([]);
    
    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        try {
            var obj = {
                "action": "getUserList",
                "userId": loggedInUser?.UserID
            }
            await Getuserslist(obj).then(async (resp) => {
                console.log("Check List", resp);
                let templist = []
                if (resp.status == "Ok") {
                    templist = resp.message
                    setUsers(templist)
                } else if(resp.Status === "success"){
                    setUsers(resp.Data);
                } 
            })
        } catch (error) {
            console.error(error)
        }
    }

    return { users, getUsers, setUsers };
}