import { BASE_URL } from './../config/index'

export const getrasaauthtoken = async (obj) => {

    let url = `${BASE_URL}getrasaauthtoken`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const adderttochatroom = async (obj) => {

    let url = `${BASE_URL}adderttochatroom`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const getertmessages = async (obj) => {

    let url = `${BASE_URL}getertmessages`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const addrasaconversationindb = async (obj) => {

    let url = `${BASE_URL}addrasaconversationindb`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const conversationtemplatecrud = async (obj) => {

    let url = `${BASE_URL}conversationtemplatecrud`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const updatealert = async (obj) => {

    let url = `${BASE_URL}updatealert`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const addalertrequest = async (obj) => {

    let url = `${BASE_URL}addalertrequest`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const nearbyresource = async (obj) => {

    let url = `${BASE_URL}nearbyresource`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const makecall = async (obj) => {

    let url = `${BASE_URL}makecall`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const getstreamingurl = async (obj) => {

    let url = `${BASE_URL}getroverstreamingurl`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}


export const sendemail = async (obj) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    };
  
    const url = BASE_URL + "sendemail";
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        console.error("sendemail response: ",error);
      });
  }


  export const getRasaConversation = async (obj) => {
    let url = `${BASE_URL}getRasaConversation`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}