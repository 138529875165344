import React from "react";
import { useEffect } from "react";
import ReactPlayer from "react-player";
import { Rnd } from "react-rnd";
import {
    CancelWhiteIcon,
  MosaicSnapshot,
  MosaicTwowayStart,
  MosaicTwowayStop,
  Video_Export,
} from "../../assets/images";
import Player from "../Cameras/Player";
import WebRTCPlayer from "../Cameras/WebRTCPlayer";

const ChatbotAssociateCamera = ({ openButton1, setOpenButton1, alertDetail }) => {
//   useEffect(() => {
//     if (openButton1) {
//       // setTimeout(() => {
//       //     let c = count + 1;
//       //     setCount(c);
//       // }, 1000);

//       const panes = document.querySelectorAll(".pane");
//       let z = 1;

//       panes.forEach((pane) => {
//         const title = pane.querySelector(".title");
//         const corner = pane.querySelector(".corner");

//         pane.addEventListener("mousedown", () => {
//           z = z + 1;
//           pane.style.zIndex = z;
//         });

//         title.addEventListener("mousedown", (event) => {
//           console.log("titledrag", event);
//           pane.classList.add("is-dragging");

//           let l = pane.offsetLeft;
//           let t = pane.offsetTop;

//           let startX = event.pageX;
//           let startY = event.pageY;

//           const drag = (event) => {
//             event.preventDefault();

//             pane.style.left = l + (event.pageX - startX) + "px";
//             pane.style.top = t + (event.pageY - startY) + "px";
//           };

//           const mouseup = () => {
//             pane.classList.remove("is-dragging");

//             document.removeEventListener("mousemove", drag);
//             document.removeEventListener("mouseup", mouseup);
//           };

//           document.addEventListener("mousemove", drag);
//           document.addEventListener("mouseup", mouseup);
//         });

//         corner.addEventListener("mousedown", (event) => {
//           console.log("event", event);
//           let w = pane.clientWidth;
//           let h = pane.clientHeight;

//           let startX = event.pageX;
//           let startY = event.pageY;

//           const drag = (event) => {
//             event.preventDefault();

//             pane.style.width = w + (event.pageX - startX) + "px";
//             pane.style.height = h + (event.pageY - startY) + "px";
//           };

//           const mouseup = () => {
//             document.removeEventListener("mousemove", drag);
//             document.removeEventListener("mouseup", mouseup);
//           };

//           document.addEventListener("mousemove", drag);
//           document.addEventListener("mouseup", mouseup);
//         });
//       });
//     }
//   }, [openButton1]);

//   const enableDragging = () => {};

console.log("alertDetailalertDetail", alertDetail)

  return (
    <>
      {/* <div
        className="pane"
        style={{ left: "calc(50vw - 400px)", top: "calc(50vh - 280px)" }}
      >
        <div className="title" onMouseDown={enableDragging}>
          <h2>Doodle #1</h2>
          <button
            onClick={() => {
              setOpenButton1(false);
            }}
          >
            Cancel
          </button>
        </div>

        <div className="content">
          <div className="mosaic-row">
            <div className="AssociatemainDiv">
              <div className="HeightChildDiv">
                <ReactPlayer
                  height={"100%"}
                  width={"100%"}
                  url="https://www.youtube.com/watch?v=jNgP6d9HraI"
                  playing={true}
                  style={{ backgroundColor: "#000000" }}
                  className="mosaic"
                  //   controls
                  autoPlay
                  config={{
                    file: {
                      attributes: {
                        crossorigin: "anonymous",
                        disablePictureInPicture: true, // hide pip
                        controlsList: "nodownload noplaybackrate",
                        onContextMenu: (e) => e.preventDefault(),
                      },
                      tracks: [],
                      forceVideo: true,
                      forceAudio: true,
                      http: {
                        // headers: getHeaders(),
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="corner"></div>
      </div> */}

      {/* ----- react-rnd librabry code ----- */}
      {openButton1 && (
          <Rnd
            default={{
              x: 650,
              y: 205,
              width: 600,
             height: 400,
            }}
            minWidth={650}
            minHeight={400}
            // maxHeight={1200}
            // maxWidth={1500}
            bounds="window"
            dragHandleClassName={"title"}
            enableResizing={true}
          >
            <div
              className="box"
              style={{ margin: 0, height: "100%", width: "100%" }}
            >
              <div
                className="title"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h2 className="mb-0">Alert ID: {alertDetail.alertId}</h2>
                <button className="LinkButtonView" onClick={() => setOpenButton1(false)}><img src={CancelWhiteIcon} /></button>
              </div>
              <div className="Associate-row">
                <div 
                className={alertDetail?.associatedCamList?.length == 3 || alertDetail?.associatedCamList?.length == 4 
                  ? "resize2x2" 
                  : alertDetail?.associatedCamList?.length == 2 
                    ? "resizeTwoView"
                    : "resizeOneView"
                }
                // className="resizeOneView"
                // className="resizeTwoView"
                // className="resize2x2"
                >
                {/* <div className="media">
                    <ReactPlayer
                    height={"100%"}
                    width={"100%"}
                    url="https://www.youtube.com/watch?v=jNgP6d9HraI"
                    muted
                    playing={true}
                    //   style={{ backgroundColor: "#000000" }}
                    className="mosaic"
                    />
                </div>
                <div className="media">
                    <ReactPlayer
                    height={"100%"}
                    width={"100%"}
                    url="https://www.youtube.com/watch?v=jNgP6d9HraI"
                    playing={true}
                    muted
                    //   style={{ backgroundColor: "#000000" }}
                    className="mosaic"
                    />
                </div> */}
                
                {
                  alertDetail?.associatedCamList?.length == 0 
                  ?
                    <>
                      {/* <Player 
                      item={alertDetail}
                      isMultiPlay={false}
                      // upperDivCss="cameraHoverMainDiv"  
                      upperDivCss="HeightChildDiv"  
                       lowerDivCss="cameraButtonsMainDiv"
                      //lowerDivCss="mosaicButtonsMainDiv"
                      // noStreamDivCss="no-stream-found"
                      noStreamDivCss="HeightChildDiv"
                      type="CAMERA"
                      popOpen={false}
                      setPopOpen={() => {}}
                      setMode={() => {}}
                      actualTime={alertDetail.actualTime}
                      /> */}

                    <WebRTCPlayer
                      key={alertDetail.deviceId}
                      id={alertDetail.deviceId+"34"}
                      devId={alertDetail.deviceId}
                      url={alertDetail.rtspUrl}
                      isEncode={alertDetail.isTranscoded}
                      // isEncode={0}
                      item={alertDetail}
                      upperDivCss="HeightChildDiv"
                      // videoCss="player-responsive1"
                      videoCss="mosaic"
                      fullVideoCss="player-responsive-fullScreen"
                      buttonCss="cameraButtonsMainDiv"
                      actualTime={alertDetail.actualTime}
                      alertDetail={alertDetail}
                      playback={true}
                      prePostDisplay={true}
                      liveButton={true}
                      noStreamDivCss="HeightChildDiv"
                      exportCurrentVideoHide={true}
                      showPlaybackBtn={true}
                    />
                    </>
                  
                  :
                  alertDetail?.associatedCamList?.map((item) => (
                    <>
                    {/* <Player
                      item={item}
                      isMultiPlay={false}
                      //upperDivCss="cameraHoverMainDiv"  
                      upperDivCss="HeightChildDiv"
                      lowerDivCss="cameraButtonsMainDiv"
                      //lowerDivCss="mosaicButtonsMainDiv"
                      //noStreamDivCss="no-stream-found"
                      noStreamDivCss="HeightChildDiv"
                      type="CAMERA"
                      popOpen={false}
                      setPopOpen={() => {}}
                      setMode={() => {}}
                      actualTime={alertDetail.actualTime}
                    /> */}

                      {/* <WebRTCPlayer 
                        key={item.associatedCameraId}
                        id={item.associatedCameraId}
                        url={item.rtspUrl}
                      // isEncode={item.isTranscoded}
                      isEncode={0}
                      item={item}
                      upperDivCss="cameraHoverMainDiv"
                      videoCss="player-responsive1"
                      fullVideoCss="player-responsive-fullScreen"
                      buttonCss="cameraButtonsMainDiv"
                      actualTime={alertDetail.actualTime}
                      alertDetail={alertDetail}
                      playback={true}
                      prePostDisplay={true}
                      liveButton={true}
                      /> */}
                   
                      <WebRTCPlayer 
                        key={item.associatedCameraId}
                        id={item.associatedCameraId+"47"}
                        devId={item.associatedCameraId}
                        url={item.rtspUrl}
                      isEncode={item.isTranscoded}
                      // isEncode={0}
                      item={item}
                      upperDivCss="AssoHieghtChildDiv"
                      videoCss="mosaic"
                      fullVideoCss="player-responsive-fullScreen"
                      buttonCss="cameraButtonsMainDiv"
                      noStreamDivCss="AssoHieghtChildDiv"
                      actualTime={alertDetail.actualTime}
                      alertDetail={alertDetail}
                      playback={true}
                      prePostDisplay={true}
                      liveButton={true}
                      exportCurrentVideoHide={true}
                      showPlaybackBtn={true}
                      />
                    </>
                    // <div className="media">
                    // <ReactPlayer
                    // height={"100%"}
                    // width={"100%"}
                    // url="https://www.youtube.com/watch?v=jNgP6d9HraI"
                    // playing={true}
                    // muted
                    // //   style={{ backgroundColor: "#000000" }}
                    // className="mosaic"
                    // />
                    // </div>
                  ))
                }
                </div>
              </div>
            </div>
          </Rnd>
        )}
    </>
  );
};

export default ChatbotAssociateCamera;
