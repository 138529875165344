import { auth, messaging } from '../../services/firebase'
import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'


export function useProvideAuth() {

    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState(null);

    let history = useHistory()

    // signin method: It can either return a promise or execute a callback function.
    // You can prefer to keep this in userServices.js

    //** Firebase auth example code (ref: https://usehooks.com/useAuth/)
    const signin = (email, password) => {
        return auth
            .signInWithEmailAndPassword(email, password)
            .then(response => {
                setUser(response.user);
                return response.user;
            });
    };

    const signout = async () => {
        return  auth.signOut().then((result) => {
            // setUser(null)
           history?.push('/login')
        }).catch((error) => {
            var errorMessage = error.message;
        });
    };

    const signup = (email, password) =>{
        return auth.createUserWithEmailAndPassword(email, password).then((result) => {

            if (result.credential) {
                // This gives you a Google Access Token.
                var token = result.credential.accessToken;
            }
            var user = result.user;
            user.sendEmailVerification();
        })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
            });
    }
    function passwordReset(email) {
        return auth.sendPasswordResetEmail(email)
    }
    const getFCMToken = async () => {
        

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
       return await messaging.getToken().catch((error) => {
            console.error({ error })
        })
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            console.log("userValue", user)
            setUser(user)

            if (user == null)
                // localStorage.setItem('user', null)
                console.log("userValue checks", user)
            setLoading(false)
        })
        return unsubscribe
    }, [])

    return {
        user,
        signin,
        signout,
        signup,
        loading,
        getFCMToken,
        passwordReset
    };
}