import React, { useState, useRef, useEffect } from 'react'
import ReactPlayer from 'react-player'
import { MosaicNoStream, PlayBackWhite, SiteIcon, BranchIcon, HooterOff, HooterOn, TwowaySoundOff, TwowaySoundOn, DownloadIcon, MosaicSnapshot, MosaicTwowayStart, MosaicTwowayStop, MosaicHooterOn, MosaicHooterOff, MosaicFullscreen, MosaicExitFullscreen, Video_Export } from '../../assets/images'
import { useVideo } from '../../CustomHooks/useVideo'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css';
import "rsuite/dist/rsuite.min.css";
import 'react-calendar/dist/Calendar.css';
import { useHistory } from 'react-router-dom'
import { CAMERA_SINGLE_PLAYBACK } from '../../navigation/CONSTANTS'
import TimerWidget from '../../UI/TimerWidget'
import TimerCard from './TimerCard'
import { ConvertDate } from '../../utils/utils'
import { addMinutes, subMinutes, differenceInMinutes } from 'date-fns'
import PopupCard from './PopupCard'
import { CLOUD_STREAMING, NO_STREAM_INTERVAL_TIMER, IS_WEBRTC } from '../../config'
import { toast } from 'react-toastify';
import { Modal, ModalBody, Tooltip } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { WarningToast } from '../../CustomHooks/WarningToast';
import { SuccessToast } from '../../CustomHooks/SuccessToast';
import { HooterOnOffClick, HooterOnOffRequest, ScreenShot, TwoWayCommunicationRequest, VideoExport } from '../../services/alertAuthService'
import screenfull from 'screenfull'
import captureVideoFrame from "capture-video-frame"
import { Save } from '../../assets/images'
import WebRTCPlayer from './WebRTCPlayer'
import { addDownload } from '../../services/downloadService'
// import Player from './Player'

const CameraCard = ({ item,
  chat,
  time,
  cctvTypeName,
  isMultiPlay,
  isCheckBoxVisible,
  onSingleDevPlaybackHandleClick,
  isChecked,
  onCheckBoxOnClick,
  exportStartTime,
  exportEndTime
}) => {
  const [isHooterOn, setIsHooterOn] = useState(true);
  const [isCameraCardHooterOn, setIsCameraCardHooterOn] = useState(false);
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [isFullscreenClick, setIsFullscreenClick] = useState(false);
  const videoContainerRef = useRef(null);
  const videoContainerParentRef = useRef(null);
  const [countDiv, setCountDiv] = useState(0);
  //const [isImage,setImage]=useState("data:image/jpeg;charset=utf\-8;base64");
  //const [isImage, setImage] = useState("data:image/jpeg;base64,");

  const [blankDate, setBlankDate] = useState("");
  const [blankDateTwo,setBlankDateTwo]=useState("");
  const { count, setCount, videoStartBuffering, videoStopBuffering, videoErrorHandler, getHeaders } = useVideo();
  const [playUrl, setPlayUrl] = useState('');
  const [capturedImage, setCapturedImage] = useState(null);
  // let playUrl = '';
  useEffect(() => {
    let c = count + 1;
    setCount(c);
    //

    if(IS_WEBRTC == 0){
      if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
      {
        // let baseUrl = "http://localhost:8080"
        let baseUrl = "http://localhost:3000"
        let tempbaseUrl = "http://localhost:3000"
        let rtspurl = item.RTSPURL
        //get cam guid
        let camguid = item.StreamName
        //do post req to base url
        let actbaseUrl = baseUrl + "/start"
        let IsTrancoding = false
        // if (item.istranscdng == 1)
        if (item.isTranscoded == 1)
          IsTrancoding = true
        fetch(actbaseUrl, {
          method: 'POST',
          body: JSON.stringify({
            uri: rtspurl,
            alias: camguid,
            isencode: IsTrancoding
          }),
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }).then((res) => res.json())
          .then((data) => {
            baseUrl = baseUrl + data['uri']
            setPlayUrl(baseUrl)
            //item.
          })
          .catch((err) => {
            console.log("check data error", err.message);
          });
        return () => {
          let stopbaseUrl = tempbaseUrl + "/stop";
          let isPresent = sessionStorage.getItem('mosaicWindowKey')
          if (isPresent == "mosaicWindow") {
            return;
          }
          fetch(stopbaseUrl, {
            method: 'POST',
            body: JSON.stringify({
              alias: camguid,
            }),
            headers: {
              'Content-type': 'application/json; charset=UTF-8',
            }
          })
            .then((res) => res.json())
            .then((data) => {
              // console.log("Unmounted the component data",data);
            })
            .catch(err => {
              console.log(err.message)
            });
        }
        //url as rtspurl and alis as camguid
      } else {
        setPlayUrl(item.StreamingURL)
      }
    }else{
      let rtspurl = item.RTSPURL
      let camguid = item.StreamName
      let isTranscode = item.isTranscoded


    }

    //check here for mode of streaming
    
    //get resp

    //make URL to play to react player
    // let  = baseUrl + ""//retun rul

  }, [time]);
  // const getHeaders = () => {
  //   return { 'Cache-Control': 'no-store' };
  // };
  const [displayModal, setDisplayModal] = useState(false);
  const [startString, setStartString] = useState("");;
  const [bufferCheckState, setBufferCheckState] = useState(false);
  const [buffering, setBuffering] = useState(false);
  const [bufferViewState, setBufferViewState] = useState(true);
  const bufferRef = useRef(bufferCheckState)
  // const [value, onChange] = useState(new Date());
  const [date, setDate] = useState(subMinutes(new Date(), 15));
  const[dateTwo,setdateTwo]=useState(new Date());
  const [dateString, setDateString] = useState("");
  const [dateStringTwo,setDateStringTwo]=useState("");
  const [timeString, setTimeString] = useState("");
  const [timeStringTwo,setTimeStringTwo]=useState("");
  const[timeStringMain,setTimeStringMain]=useState("+15 min");
  const[timeStringMainTwo,setTimeStringMainTwo]=useState("+15 min");
  const[differenceTime,setDifferenceTime]=useState(15);
  const[differenceTimeTwo,setDifferenceTimeTwo]=useState(15);
  const[popOpen, setPopOpen] = useState(false);
  const[mode,setMode]=useState("");
  const closeModal = () => setPopOpen(false);
  let history = useHistory()
  const [videoData, setVideoData] = useState(null);

  const playerRef = useRef(null);
  const videoBuffering = (e, cameraName) => {
    setBuffering(true);
  }
  const finishedBuffering = (e, cameraName) => {
    setBuffering(false);
  }
  const ReactPlayerHandleOnError = (event, cameraName) => {
    if (!buffering) {
      let c = count + 1;
      setCount(c);
    }
    // window.location.reload(true)
  }
  // const getHeaders = () => {
  //   return { 'Cache-Control': 'no-store' };
  // };
  const dateChange = (v, e) => {
    let d = ConvertDate(v);
    let temp = d.slice(0, 10).replace(/-/g, '_');
    setDateString(temp);
    setDateStringTwo(temp);
    setDate(v);
    setdateTwo(v);
  }

  const dateChangeVideoExport = (v, e) => {
    let d = ConvertDate(v);
    let temp = d.slice(0, 10).replace(/-/g, '-');
    setDateString(temp);
    setDate(v);
  }
  const getDataFromChildTwo = (data) => {
    let timeStringMainTwo = "";

    if (data === "") {
      let blankArr = blankDateTwo.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMainTwo = blankStr;
    } else {
      timeStringMainTwo = data;
    }
    let temp = dateStringTwo + '_' + timeStringMainTwo;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let currentTime = new Date();
    const difference = differenceInMinutes(currentTime, SD);
    if (difference < 15 && difference >= 0) {
      if(mode == "Playback"){
        WarningToast("Selected Time is less than 15 min !")
      }

      timeStringMainTwo = difference + " min ";
      setDifferenceTimeTwo(parseInt(difference))
      setTimeStringMainTwo(timeStringMain);
    } else {
      setDifferenceTimeTwo(15);
      setTimeStringMainTwo("15 min");
    }

    setTimeStringTwo(data);
  }

  const getDataFromChild = (data) => {
    let timeStringMain = "";
  
    if(data === ""){
      let blankArr = blankDate.split("_");
      for(let i=0; i<blankArr.length; i++){
        if(blankArr[i].length == 1){
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    }else{
      timeStringMain = data;
    }
    let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let currentTime = new Date();
    const difference = differenceInMinutes(currentTime, SD);
    if (difference < 15 && difference >= 0) {
      if(mode == "Playback"){
      WarningToast("Selected Time is less than 15 min !")
      }
      timeStringMain = difference + " min ";
      setDifferenceTime(parseInt(difference))
      setTimeStringMain(timeStringMain);
    } else {
      setDifferenceTime(15);
      setTimeStringMain("15 min");
    }

    setTimeString(data);
  }

  useEffect(() => {
    // let d = new Date();
    let d = subMinutes(new Date(), 15);
    let temp = ConvertDate(d);
    let temp_date = temp.slice(0, 10).replace(/-/g, '_');
    setDateString(temp_date);
  }, []);

  useEffect(() => {
    // let d = new Date();
    let d = new Date();
    let temp = ConvertDate(d);
    let temp_date = temp.slice(0, 10).replace(/-/g, '_');
    setDateStringTwo(temp_date);
  }, []);

  const showErrorToast = (msg) => {
    toast.error(msg, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
};

  const onSubmitBtn = () => {
    let timeStringMain = "";
    if (timeString === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = timeString;
    }
    let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);

    let ED = addMinutes(SD, differenceTime);
    let toDateTime = ConvertDate(ED);

    let currentTime = new Date();

    // let fifteenMinutesAgo = (currentTime, -15);
    // if (SD >= fifteenMinutesAgo) {
    //   // Show a toast message
    //   showErrorToast('The time should be selected 15 minutes backwards from the current time!');
    // }
    //currentTime =addMinutes(currentTime, 15)
    if (differenceTime == 0) {
      showErrorToast('The selected time is  greater from the current time!')
      return;
    }
    //const difference = differenceInMinutes(currentTime, SD);
    if (SD > currentTime && differenceTime > 0) {
      showErrorToast('The selected time is  greater from the current time!')
      return;
    } else {
      let tempState = {
        startTime: temp,
        endTime: toDateTime.replace(/[ :-]/g, '_'),
        deviceId: item.ParentCameraID,
        cameraName: item.CameraName,
        siteName: item.SiteName,
        dateTimeOne: SD,
        dateTimeTwo: ED,
        streamName: item.StreamName,
        isTranscoded: item.isTranscoded
      }

      history.push({
        pathname: CAMERA_SINGLE_PLAYBACK,
        state: {
          startTime: temp,
          endTime: toDateTime.replace(/[ :-]/g, '_'),
          deviceId: item.ParentCameraID,
          cameraName: item.CameraName,
          siteName: item.SiteName,
          dateTimeOne: SD,
          dateTimeTwo: ED,
          streamName: item.StreamName,
          isTranscoded: item.isTranscoded,
          item: item
        }
      });
    }
  }

  const onExportBtn = () => {
    let timeStringMain = "";
    if (timeString === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = timeString;
    }
    let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let startD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
    let SD = formatDate(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

    let timeStringMainTwo = "";
    if (timeStringTwo === "") {
      let blankArr = blankDateTwo.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMainTwo = blankStr;
    } else {
      timeStringMainTwo = timeStringTwo;
    }
    let tempTwo = dateStringTwo + '_' + timeStringMainTwo;
    let arrTwo = tempTwo.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    //console.log("Date", ED); 
    let endD = new Date(arrTwo[0], arrTwo[1] - 1, arrTwo[2], arrTwo[3], arrTwo[4], arrTwo[5])
    let ED = formatDate(new Date(arrTwo[0], arrTwo[1] - 1, arrTwo[2], arrTwo[3], arrTwo[4], arrTwo[5]));
    let currentTime = new Date();

    let diff = differenceInMinutes(endD, startD);
    let current = new Date();

    if (startD > current) {
      showErrorToast('Invalid timings, start date time is greater than current time.')
      return;
    }

    if (endD > current) {
      showErrorToast('Invalid timings, end date time is greater than current time.')
      return;
    }

    if (diff > 15) {
      showErrorToast('Max limit for downloading of video is 15 mins')
      return;
    } else if (diff < 0) {
      showErrorToast('Invalid timings')
      return;
    } else if (diff == 15 && SD.slice(-2) != ED.slice(-2)) {
      showErrorToast('Max limit for downloading of video is 15 mins')
      return;
    }
 if (differenceTimeTwo == 0) {
      showErrorToast('The selected time is greater from the current time!')
      return;
    }
    //const difference = differenceInMinutes(currentTime, SD);
    if (SD > currentTime && differenceTimeTwo > 0) {
      showErrorToast('The selected time is greater from the current time!')
      return;
    } else {
      // Here will need to call the API to initiate download for the video with the parameters of its start time and end time.
      let user = JSON.parse(localStorage.getItem('user'))
      let currentDate = formatDate(new Date());
      let obj = {
        VideoExportLogID: "1",
        RequestedBy: user.UserUniqueID,
        RequestedByName: user.FullName,
        ExportRequestedOn: currentDate,
        DeviceID: item.ParentCameraID.toString(),
        DeviceName: item.CameraName,
        ExportStatus: "0",
        ExportStatusDateTime: "",
        ExportStatusLog: "",
        DownloadLink: "",
        DownloadStatus: "",
        DownloadDateTime: "",
        DeleteFlag: "0",
        ExportFromDateTime: SD,
        ExportToDateTime: ED
      }
      addDownload(obj)
        .then((res) => {
          if (res.Status == "success") {
            SuccessToast(`The download request has been raised successfully. `)
          }
        }).catch(err => console.log(err));
      // videoExportOnClick(item.privateIPAddress, item.httpPort
      //   , item.camUserName, item.camPassword, item.BrandName, SD, ED)
 }
  }


  let startDateTime,endDateTime;
  const onSubmitBtnVideoExport = () => {

    //     startTime = "2023-11-13 00:00:00";
    // endTime = "2023-11-13 00:05:00";
    let timeStringMain = "";
    if (timeString === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join(":");
      timeStringMain = blankStr;
      startTime = blankStr;
      console.log("rupesh checks blankdate", startTime)
    } else {
      timeStringMain = timeString;
    }

     startDateTime = dateString.replaceAll("_", "-")  + ' ' + timeStringMain.replaceAll("_", ":") ;
    let temp = dateString.replaceAll("-", "_") + '_' + timeStringMain.replaceAll(":", "_");
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);

    let ED = addMinutes(SD, differenceTime);
    let toDateTime = ConvertDate(ED);
     endDateTime = toDateTime;
    let currentTime = new Date();

    if (differenceTime == 0) {
      showErrorToast('The selected time is  greater from the current time!')
      return;
    }
    if (SD > currentTime && differenceTime > 0) {
      showErrorToast('The selected time is  greater from the current time!')
      return;
    } else {
      

      videoExportOnClick(item.privateIPAddress, item.camPort
        , item.camUserName, item.camPassword, item.BrandName,item.startDateTime,item.endDateTime)
    }
  }


  const HooterOnOffReq = async (privateIPAddress, camPort, action, panelBrand, CCTVType
    , user, password, BrandName) => {
    try {
      if (privateIPAddress) {
        HooterOnOffRequest(privateIPAddress, camPort, action, panelBrand, CCTVType
          , user, password, BrandName).then((resp) => {
            return resp
          })
      }
    } catch (error) {
      console.error(error)
    }
  }
  function HooterClick(hooter, chat, isCloseBtn) {
    var resp = "";
    if (hooter && hooter == 'on') {
      setIsHooterOn(false)
      resp = HooterOnOffReq(chat.privateIPAddress, chat.camPort, 'on', chat.panelBrand, chat.CCTVType
        , chat.camUserName, chat.camPassword, chat.BrandName)
    }
    if (hooter && hooter == 'off') {
      setIsHooterOn(true)
      resp = HooterOnOffReq(chat.privateIPAddress, chat.camPort, 'off', chat.panelBrand, chat.CCTVType
        , chat.camUserName, chat.camPassword, chat.BrandName)
    }

  }

  const HooterOnOffClickReq =  (pDeviceId,pMode) => {
    HooterOnOffClick(pDeviceId,pMode)
    .then((res) => {
      if(res.Status == "success"){
        if(pMode == 'on'){
          setIsCameraCardHooterOn(true)
        }else{
          setIsCameraCardHooterOn(false)
        }
      }
    }).catch(err => console.log(err));
  }


  const TwoWayCommunicationReq = async (privateIPAddress, CommunicationPort
    , action, user, password, BrandName) => {
    try {
      if (privateIPAddress && CommunicationPort
        && user && password) {        
        TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
          , action, user, password, BrandName).then((resp) => {
            return resp
          })
      }else{
        TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
          , action, user, password, BrandName).then((resp) => {
            
            return resp
          })
      }
    } catch (error) {
      console.error(error)
    }
  }

  function TwoWayAudioClick(audio, chat, isCloseBtn) {
    var resp = "";
    var resp1 = "";
    if (audio && audio == 'start') {

      setIsSoundOn(false)
      // 
      // TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
      resp1 = TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
      // 
      resp = TwoWayCommunicationReq(chat.privateIPAddress, chat.CommunicationPort
        , 'start', chat.camUserName, chat.camPassword, chat.BrandName
       )
    }
    if (audio && audio == 'stop') {
      setIsSoundOn(true)

      resp = TwoWayCommunicationReq(chat.privateIPAddress, chat.CommunicationPort
        , 'stop', chat.camUserName, chat.camPassword, chat.BrandName
      )
    }
  }

  useEffect(() => {
    const handlePageChange = () => {
      TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
    };
    const unlisten = history.listen(handlePageChange);
    return () => {
      unlisten();
    }
  }, [history])

  useEffect(() => {
    if(screenfull.isEnabled){
      screenfull.on('change', () => {

      })
    }
  }, [screenfull]);

  const keyDownHandler = event => {
    console.log("Key Click", event.key);
  }


  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler);
  function exitHandler() {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
          setIsFullscreenClick(false);
          let c = count + 1;
          setCount(c);    
      }else{
        console.log("Plain JS screenfull start");
      }
  }  
  return () => {
    document.removeEventListener('fullscreenchange', exitHandler);
  }
  }, [isFullscreenClick]);


  const handleFullScreen = () => {
    screenfull.toggle(videoContainerParentRef.current)
    setIsFullscreenClick(!screenfull.isFullscreen);
    let c = count + 1;
    setCount(c);
    //debugger
    // if (!isFullscreenClick) {
    //   videoContainerRef.current.requestFullscreen();
    //   setIsFullscreenClick(true);
    // } else {
    //   document.exitFullscreen();
    //   setIsFullscreenClick(false);
    // }
  //   if(screenfull.isEnabled){
  //     screenfull.request(videoContainerRef.current.wrapper);
  // }
  };

  // const handleFullScreenTwo = () => {
  //   console.log("handleFullScreenTwo", isFullscreenClick)
  //   console.log("handleFullScreenTwo", videoContainerParentRef.current)
  //     screenfull.toggle(videoContainerParentRef.current)
  //     setIsFullscreenClick(true);   
  //     let c = count + 1;
  //     setCount(c);
  // }

  // function ScreenShotDownloadClick(imageData) {
  //   console.log("ScreenShotDownloadClick", ScreenShotDownloadClick)
  //   var fileUrl = "data:image;base64," + imageData;
  //   fetch(fileUrl)
  //     .then(response => response.blob())
  //     .then(blob => {
  //       var link = window.document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob, { type: "image" });
  //       link.download = "test.png";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     });
  // }

  const ScreenShotClick = async (privateIPAddress, camPort, action, panelBrand, CCTVType, user, password, BrandName) => {
    try {
      if (privateIPAddress && camPort
        && user && password) {
        ScreenShot(privateIPAddress, camPort, action, panelBrand, CCTVType, user, password, BrandName)
          .then((resp) => {
            var fileUrl = "data:image;base64," + resp.Data;

            const popupWindow = window.open('', '_blank', 'width=540,height=360');
            popupWindow.document.open();
            popupWindow.document.write(`
            <html>
            <body>
              <img src="${fileUrl}" alt="Screenshot" width="300" height="300" />
              <br />
              <button class="DataAccessEdit" onclick="ScreenShotDownloadClick('${resp.Data}')">
              <img src=${DownloadIcon} alt="download icon" />
            </button>
            </body>
            <script>
            function ScreenShotDownloadClick(imageData){
              var fileUrl = "data:image;base64," + imageData;
              fetch(fileUrl)
                .then(response => response.blob())
                .then(blob => {
                  var link = window.document.createElement("a");
                  link.href = window.URL.createObjectURL(blob, { type: "image" });
                  link.download = "test.png";
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });              
            }
            </script>
            </html>`);
            popupWindow.document.close();

            //return resp;
          });
      }
    } catch (error) {
      console.error(error)
    }
  }

  function ScreenShotOkClick(audio, chat, isCloseBtn) {
    ScreenShotClick(chat.privateIPAddress, chat.camPort, 'start', chat.panelBrand, chat.CCTVType, chat.camUserName, chat.camPassword, chat.BrandName)
  }

/* captureImage */
const handleCaptureFrame = (params) => {
  const video = videoContainerRef.current.getInternalPlayer();

  if (typeof video === 'string') {
    video = console.log("rupesh checks",video);
}
    const frame = captureVideoFrame(params)
          setCapturedImage(frame.dataUri)
          var fileUrl = frame.dataUri;

  // const popupWindow = window.open('', '_blank', 'width=540,height=360');
  const popupWindow = window.open('', '_blank', 'width=auto, height=auto');
  popupWindow.document.open();
  popupWindow.document.write(`
  <html>
  <head>
  <title>Snapshot</title>
  
  <style>
  body, html {
    margin: 0; padding: 0; background: transparent; font-family: Segoe UI;
  }
  .maincontainer {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  .snapshottitlediv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #bdbcbc; 
    flex: 0 1 auto;
    padding: 0px 10px;
  }
  .DataAccessEdit {
    width: 32px;
    height: 32px;
    padding: 8px 7.6px 7.6px 8px;
    border-radius: 5px;
    background-color: #0b70d1;
    line-height: 10px;
    margin-left: 5px;
    border: 1px solid #0b70d1;
  }
  p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 0px 5px;
  }
  .snapshotimagediv {
    flex: 1 1 auto;
    display: grid;
    align-items: center;
  }
  .snapshotimagediv img {
    width: 100%;
    object-fit: contain;
    box-sizing: border-box;
    display: flex;
  }
  </style>
  </head>
  <body>
  <div class="maincontainer">
    <div class="snapshottitlediv">
    <p>${item.CameraName} Snapshot</p>
    <button class="DataAccessEdit" data-title="Download" title="Save" onclick="capturedFrameDownloadClick('${fileUrl}')">
    <img src=${Save} alt="download icon" data-title="Save" />

    </button>
    </div>
    <div class="snapshotimagediv">
    <img src="${fileUrl}" alt="Screenshot" />
    </div>
  </div>
  </body>
  <script>
  function capturedFrameDownloadClick(imageData){
    var fileUrl = imageData;
    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => {
        var link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob, { type: "image" });
        link.download = "test.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });              
  }
  </script>
  </html>`);
  popupWindow.document.close();
};
/* captureImage */

  useEffect(() => {
      const timeoutId = setTimeout(() => {
        if(!bufferRef.current){
          setBufferViewState(false)
        }
      }, NO_STREAM_INTERVAL_TIMER);

      return () => {
        clearTimeout(timeoutId);
      };
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (bufferViewState == false) {
        setBufferViewState(true)
      }
    }, 60000 * 2);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [bufferViewState]);

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const currentDateTime = new Date();
const twoMinutesLater = new Date(currentDateTime.getTime() + 2 * 60000);
let startTime, endTime;
// startTime = formatDate(currentDateTime);
// endTime = formatDate(twoMinutesLater);
// startTime = "2023-10-31 18:25:00";
// endTime = "2023-10-31 18:26:00";
startTime = "2023-11-13 00:00:00";
endTime = "2023-11-13 00:05:00";

  //rg
  /* const videoExportOnClick = async (privateIPAddress, camPort
    , user, password, BrandName) => {    
      debugger  
      console.log("rupesh checks item",item)
    try {          
        if (privateIPAddress && camPort
          && user && password) {
            VideoExport(BrandName, privateIPAddress, camPort, user, password, startTime, endTime).then((resp) => {
              console.log("rupesh checks response",resp)
              
              var videoUrl = resp.Data;
            })
        }            
    } catch (error) {
        console.error(error)
    }
  } */
  const videoExportOnClick = async (privateIPAddress, httpPort, user, password, BrandName,startDateTime, endDateTime) => {
    //Response Data:  https://localhost:6001//VideoTempFiles/video_2023_11_13 00_00_00_2023_11_13 00_05_00.mp4
    // https://localhost:6001//VideoTempFiles/video_2023_11_13 00_00_00_2023_11_13 00_05_00.mp4
  //debugger;
  try {
    if (privateIPAddress && httpPort && user && password) {
      const resp = await VideoExport(BrandName, privateIPAddress, httpPort, user, password, startDateTime, endDateTime);

      // Handle video download
      var videoUrl = resp.Data;
      downloadVideo(videoUrl);
    }
  } catch (error) {
    console.error(error);
  }
};



const downloadVideo = (videoUrl) => {
  //debugger
  // var videoUrl = "data:video/mp4;base64," + videoUrl;
  fetch(videoUrl)
  .then(response => {
    return response.blob();
  })
  .then(blob => {
    var link = window.document.createElement("a");
    link.href = window.URL.createObjectURL(blob, { type: "video/mp4" });
    link.download = "downloaded_video.mp4";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  })
  .catch(error => {
    console.error("Error downloading video:", error);
  });

};


// useEffect(() => {
//   const dataTitleElement = document.querySelector('[data-title]');
//   console.log("dataTitleElement", dataTitleElement);
//   if (isFullscreenClick) {
//     dataTitleElement.style.top = '0';
//     dataTitleElement.style.bottom = 'unset';
//   } else {
//       dataTitleElement.style.bottom = '0';
//       dataTitleElement.style.top = 'unset';
//   }
// }, [isFullscreenClick])

  return (
    <div className="CameraDiv1 CameraGridNewUI"
      key={item.StreamName}
    >
      {
        IS_WEBRTC == 0
        ?
        <>
        {
          item.RTSPURL ?//item.StreamingURL ?
            (
              bufferViewState ?
                <>
                  <div
                    ref={videoContainerParentRef}
                    className='cameraHoverMainDiv'
                  >
                    <ReactPlayer
                      key={count}
                      ref={videoContainerRef}
                      // key={item.ParentCameraID}
                      // muted
                      itemID={"video-" + item.StreamName}
                      url={isMultiPlay ? item.playbackURL : playUrl}//item.StreamingURL
                      playing={true}
                      style={{ borderRadius: "7px 7px 0px 0px", width: "100%", height: "100%" }}
                      controls
                      autoPlay
                      pip={true} // for picture in picture
                      stopOnUnmount={false} // for picture in picture
                      // className="player-responsive1"
                      // className={
                      //   isMultiPlay ? "multiplaybackplayer"
                      //     : isFullscreenClick ? "player-responsive-fullScreen"
                      //       : "player-responsive1"}
                      className={
                        isFullscreenClick && isMultiPlay ? "player-responsive-fullScreen"
                         : !isFullscreenClick && isMultiPlay ? "multiplaybackplayer"
                          : isFullscreenClick && !isMultiPlay ? "player-responsive-fullScreen"
                            : "player-responsive1"}      
                      // onError={(e)=>videoErrorHandler(e, item.CameraName)}
                      onBuffer={(e) => videoStartBuffering(e, item.CameraName)}
                      onBufferEnd={(e) => {
                        videoStopBuffering(e, item.CameraName);
                        // setBufferCheckState(true)
                        bufferRef.current = true
                      }}
                      onError={(e) => videoErrorHandler(e, item.CameraName)}
                      width='320px'
                      height='180px'
                      config={{
                        file: {
                          attributes: {
                            crossorigin: 'anonymous',
                            disablePictureInPicture: isMultiPlay ? false : true, // hide and display pip
                            controlsList: isMultiPlay ? "noplaybackrate" : "noplaybackrate nodownload",
                            onContextMenu: (e) => e.preventDefault(),
                          },
                          tracks: [],
                          forceVideo: true,
                          forceAudio: true,
                          http: {
                            headers: getHeaders(),
                          },
                        },
                      }}
                    />
                    {/*   <div className='liveTagR'><p>R</p></div>
          <div className='liveTagL'><p>L</p></div> */}
                    <div className='cameraButtonsMainDiv' style={{ width: "100%" }}>
                      <div className='mosaicbuttons'>
                       {/*  <button data-title='SnapShot' className='MosaicButtonDiv'>
                          <img style={{ padding: "2px", height: "24px", }}
                            src={MosaicSnapshot}
                            onClick={() => ScreenShotOkClick('start', item)}
                          />
                        </button> */}
                        {
                          !isFullscreenClick && !isMultiPlay
                          ?
                          <button 
                          // className="PlayBackBtnDiv"
                          className="MosaicButtonDiv"
                          onClick={e => { 
                            setPopOpen(true);
                            setMode("Playback");
                            // onSingleDevPlaybackHandleClick(item) 
                          }}>
                                                    <OverlayTrigger
                                              placement='bottom'
                                              overlay={
                                                <Tooltip>
                                                  Playback
                                                </Tooltip>
                                              }
                                            >
                            <img src={PlayBackWhite} className="camera-icon-height" alt='playback white icon' style={{ padding: "2px", height: "24px", }}/>
                            </OverlayTrigger>
                          </button>
                          : null
                        }

                        <button data-title='SnapShot' className='MosaicButtonDiv'>
                          <img style={{ padding: "2px", height: "24px", }}
                            src={MosaicSnapshot}
                            onClick={
                              ()=>{ 
                              handleCaptureFrame(videoContainerRef.current.getInternalPlayer())
                              if(isFullscreenClick){
                                setIsFullscreenClick(false);
                                let c = count + 1;
                                setCount(c);                          
                              }
                              }
                            }
                          />
                        </button>
                        {
                          item.ShowTwoWay
                            ? (
                              isSoundOn ?
                                <button data-title='TwowayStart' className='MosaicButtonDiv'>
                                  <img style={{ padding: "2px" }}
                                    src={MosaicTwowayStart}
                                    onClick={() => TwoWayAudioClick('start', item)}
                                  />
                                </button>
                                :
                                <button data-title='TwowayStop' className='MosaicButtonDiv'>
                                  <img style={{ padding: "2px" }}
                                    src={MosaicTwowayStop}
                                    onClick={() => TwoWayAudioClick('stop', item)}
                                  />
                                </button>
                            )
                            : null
                        }
                        {/* {
                          isSoundOn ?
                            <button data-title='TwowayStart' className='MosaicButtonDiv'>
                              <img style={{ padding: "2px" }}
                                src={MosaicTwowayStart}
                                onClick={() => TwoWayAudioClick('start', item)}
                              />
                            </button>
                            :
                            <button data-title='TwowayStop' className='MosaicButtonDiv'>
                              <img style={{ padding: "2px" }}
                                src={MosaicTwowayStop}
                                onClick={() => TwoWayAudioClick('stop', item)}
                              />
                            </button>
                        } */}

                            {
                              item.ShowHooter ?
                                (!isCameraCardHooterOn ?
                                  <button data-title='HooterOn' className='MosaicButtonDiv'>
                                    <img style={{ padding: "2px" }}
                                      src={MosaicHooterOn}
                                      //onClick={() => HooterClick('on', item)}
                                      onClick={() => {
                                        HooterOnOffClickReq(item.ParentCameraID,'on')
                                        //setIsCameraCardHooterOn(true);
                                      }}
                                    />
                                  </button>
                                  :
                                  <button data-title='HooterOff' className='MosaicButtonDiv'>
                                    <img style={{ padding: "2px" }}
                                      src={MosaicHooterOff}
                                      //onClick={() => HooterClick('off', item)}
                                      onClick={() => {
                                        HooterOnOffClickReq(item.ParentCameraID,'off')
                                        //setIsCameraCardHooterOn(false);
                                      }}

                                    />
                                  </button>) : null
                            }

                            {/* {
                                      isFullscreenClick ?
                                    <button data-title='Fullscreen' className='MosaicButtonDiv'>
                                        <img style={{ padding: "2px" }}
                                        src={MosaicFullscreen}
                                        onClick={() => handleFullScreen(false)}
                                        />
                                    </button>
                                    :
                                    <button data-title='ExitFullscreen' className='MosaicButtonDiv'>
                                        <img style={{ padding: "2px" }}
                                        src={MosaicExitFullscreen}
                                        onClick={() => handleFullScreen(true)}
                                        />
                                    </button>
                                    } */}

                            <button
                              //data-title='Fullscreen' 
                              data-title={isFullscreenClick ? 'ExitFullscreen' : 'Fullscreen'}
                              className='MosaicButtonDiv'
                              onClick={handleFullScreen}
                            >
                              {
                                !isFullscreenClick ?
                                  <img style={{ padding: "2px" }}
                                    src={MosaicFullscreen}
                                  />
                                  :
                                  <img style={{ padding: "2px" }}
                                    src={MosaicExitFullscreen}
                                  />
                              }
                              {/* <span className='Button_module_buttonChildren'>
                            <span className='Tooltip_module_tooltip' style={{left: "calc(50% + 0px)"}}> 
                              Fullscreen
                            </span>
                          </span> */}
                            </button>
                        
                        {/* {
                          isFullscreenClick ?
                        <button
                          className='MosaicButtonDiv'
                          onClick={handleFullScreen}
                        >
                        <OverlayTrigger
                                              placement='bottom'
                                              overlay={
                                                <Tooltip>
                                                  FullScreen
                                                </Tooltip>
                                              }
                                            >
                                              <img style={{ padding: "2px" }}
                                                src={MosaicFullscreen}
                                              />
                                            </OverlayTrigger>
                        </button>
                          :
                        <button
                          className='MosaicButtonDiv'
                          onClick={handleFullScreen}
                        >
                        <OverlayTrigger
                                              placement='top'
                                              overlay={
                                                <Tooltip>
                                                  ExitFullscreen
                                                </Tooltip>
                                              }
                                            >
                                              <img style={{ padding: "2px" }}
                                                src={MosaicExitFullscreen}
                                              />
                                            </OverlayTrigger>
                        </button>
                        } */}

                        <button data-title='Video Export' className='MosaicButtonDiv'>
                          <img style={{ padding: "2px", height: "24px", }}
                            src={Video_Export} alt="video Export Button"
                            onClick={
                              () => {
                                setPopOpen(true);
                                setMode("Export");
                              }
                              // videoExportOnClick(item.privateIPAddress, item.camPort, item.camUserName, item.camPassword, item.BrandName ,item.startDateTime,item.endDateTime)
                              }
                          />
                         </button>  
                      </div>
                      <div>
                        <Modal
                          // size='lg'
                          show={popOpen}
                          onHide={() => setPopOpen(false)}
                          className={mode == "Playback" ? 'singleplaybackexportModal' : 'ExportModal'}
                          backdrop="static"
                        >
                          <Modal.Header closeButton>
                            {
                              mode =="Playback" ?
                            <Modal.Title>
                              <p className="ModelHeaderName">{item.CameraName} - Playback</p>
                            </Modal.Title>
                            :
                             <Modal.Title>
                              <p className="ModelHeaderName">{item.CameraName} - Export</p>
                            </Modal.Title>
  } 
                          </Modal.Header>
                          <Modal.Body>
                        <PopupCard item={item} dateChange={dateChange} date={date} getDataFromChild={getDataFromChild} onSubmitBtn={mode == "Playback" ?  onSubmitBtn : onExportBtn} setBlankDate={setBlankDate} timeStringMain={timeStringMain} mode={mode} setBlankDateTwo={setBlankDateTwo}  getDataFromChildTwo={ getDataFromChildTwo} timeStringMainTwo={timeStringMainTwo} dateTwo={dateTwo}/>
                          </Modal.Body>
                        </Modal>  
                      </div>
                      <div>
                        
                      {/* <Popup
                      open={popOpen}
                      className='popupDiv'
                      // position="bottom center"
                      position={["top center", "right center",]}
                      keepTooltipInside=".camera-list"
                      // closeOnDocumentClick={false}        
                      // closeOnDocumentClick={true} 
                      closeOnDocumentClick 
                      onClose={closeModal}
                    >
                      <PopupCard item={item} dateChange={dateChange} date={date} getDataFromChild={getDataFromChild} onSubmitBtn={onSubmitBtn} setBlankDate={setBlankDate} timeStringMain={timeStringMain} />
                        </Popup> */}
                      </div>
                    </div>
                  </div>
                </>
                :
                <div className="no-stream-found">
                  <img
                    src={MosaicNoStream}
                    alt="Video"
                    className="w-100 image-bg mosaic-nostream-img"
                  />
                </div>
              // <>

              //   <Player
              //     item={item}
              //     isMultiPlay={isMultiPlay}
              //     upperDivCss="cameraHoverMainDiv"
              //     lowerDivCss="cameraButtonsMainDiv"
              //     noStreamDivCss="no-stream-found"
              //     type="CAMERA"
              //     popOpen={popOpen}
              //     setPopOpen={setPopOpen}
              //     setMode={setMode}
              //   />

              //   <div>
              //     <Modal
              //       // size='lg'
              //       show={popOpen}
              //       onHide={() => setPopOpen(false)}
              //       className='singleplaybackexportModal'
              //       backdrop="static"
              //     >
              //       <Modal.Header closeButton>
              //         {
              //           mode == "Playback" ?
              //             <Modal.Title>
              //               <p className="ModelHeaderName">{item.CameraName} Playback</p>
              //             </Modal.Title>
              //             :
              //             <Modal.Title>
              //               <p className="ModelHeaderName">{item.CameraName} Export</p>
              //             </Modal.Title>
              //         }
              //       </Modal.Header>
              //       <Modal.Body>
              //         <PopupCard item={item} dateChange={dateChange} date={date} getDataFromChild={getDataFromChild} onSubmitBtn={mode == "Playback" ?  onSubmitBtn : onExportBtn} setBlankDate={setBlankDate} timeStringMain={timeStringMain} mode={mode} setBlankDateTwo={setBlankDateTwo}  getDataFromChildTwo={ getDataFromChildTwo} timeStringMainTwo={timeStringMainTwo} dateTwo={dateTwo}/>
              //       </Modal.Body>
              //     </Modal>
              //   </div>
              // </>
                

            )
            :
            <div className="no-stream-found">
              <img
                src={MosaicNoStream}
                alt="Video"
                className="w-100 image-bg mosaic-nostream-img"
              />
            </div>
        }

      </>
          : 
          <WebRTCPlayer
            key={`${item.ParentCameraID}_${isMultiPlay}`}
            id={isMultiPlay ? item.ParentCameraID+"4" : item.ParentCameraID}
            url={isMultiPlay ? item.playbackURL : item.RTSPURL}
            isEncode={item.isTranscoded}
            // isSinglePlayback={isMultiPlay ? true : false}
            //isEncode={0}
            item={item}
            upperDivCss="cameraHoverMainDiv"
            videoCss="player-responsive1"
            fullVideoCss="player-responsive-fullScreen"
            buttonCss="cameraButtonsMainDiv"
            noStreamDivCss="no-stream-found"
            playback={false}
            prePostDisplay={false}
            liveButton={true}
            exportCurrentVideoHide={isMultiPlay ? false : true}
            exportStartTime={exportStartTime}
            exportEndTime={exportEndTime}        
            isMultiPlay={isMultiPlay}    
            showPlaybackBtn={isMultiPlay ? false : true}
          />

      }

      <div className="p-2 ForResponsiveWidth">
        <div className='LabelDivWithButton'>
          <p className="CameraNameTextValue">{item.CameraName}</p>
          <div>
            {
              isCheckBoxVisible ?
                <div>
                  <input
                    className="camera-checkbox"
                    type="checkbox"
                    id={item.ParentCameraID}
                    defaultChecked={isChecked ? item.isChecked ? true : false : false}
                    onClick={e => { onCheckBoxOnClick(e) }}
                  />
                </div>
                : !isCheckBoxVisible && isMultiPlay
                  ? null
                  : <div style={{ display: "flex" }}>
                    {/* <button data-title='Video Export' className='PlayBackBtnDiv'>
                      <img style={{ padding: "2px" }} src={TwowaySoundOff} alt="video Export Button"
                        onClick={
                          () => videoExportOnClick(item.privateIPAddress, item.camPort
                            , item.camUserName, item.camPassword, item.BrandName)}
                      />
                    </button>   */}

                     {/* <Popup
                      className='popupDiv'
                      // position="bottom center"
                      position={["top center", "right center",]}
                      keepTooltipInside=".camera-list"
                      // closeOnDocumentClick={false}        
                      // closeOnDocumentClick={true} 
                      trigger={
                        <button className="PlayBackBtnDiv"
                        // onClick={e => { onSingleDevPlaybackHandleClick(item) }}
                        >
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Tooltip>
                                Video Export
                              </Tooltip>
                            }
                          >
                            <img src={PlayBackWhite} className="camera-icon-height" alt='playback white icon' />
                          </OverlayTrigger>
                        </button>
                      }
                    >
                      <PopupCard item={item} dateChange={dateChangeVideoExport} date={date} getDataFromChild={getDataFromChild} onSubmitBtn={onSubmitBtnVideoExport} setBlankDate={setBlankDate} timeStringMain={timeStringMain} />
                    </Popup> */}
                   
                    {/* <button data-title='ScreenShotClick' className='PlayBackBtnDiv'><img style={{ padding: "2px" }} src={TwowaySoundOff} onClick={() => ScreenShotOkClick('start', item)} /></button>
                    {isSoundOn &&
                      <button data-title='Start two way communication' className='PlayBackBtnDiv'><img style={{ padding: "2px" }} src={TwowaySoundOff} onClick={() => TwoWayAudioClick('start', item)} /></button>
                    }
                    {!isSoundOn &&
                      <button data-title='Stop two way communication' className='PlayBackBtnDiv'><img style={{ padding: "2px" }} src={TwowaySoundOn} onClick={() => TwoWayAudioClick('stop', item)} /></button>}
                    {isHooterOn &&
                      <button data-title='Activate Hooter' className='PlayBackBtnDiv'>
                        <img style={{ padding: "2px" }} src={HooterOff}
                          //onClick={} 
                          onClick={() => HooterClick('on', item)}
                        />

                      </button>}
                    {!isHooterOn &&
                      <button data-title='Deactivate Hooter' className='PlayBackBtnDiv'>
                        <img style={{ padding: "2px" }} src={HooterOn}
                          onClick={() => HooterClick('off', item)}
                        />
                      </button>} */} 
                    {/* <Popup
                      className='popupDiv'
                      // position="bottom center"
                      position={["top center", "right center",]}
                      keepTooltipInside=".camera-list"
                      // closeOnDocumentClick={false}        
                      // closeOnDocumentClick={true} 
                      trigger={
                        <button className="PlayBackBtnDiv"
                        // onClick={e => { onSingleDevPlaybackHandleClick(item) }}
                        >
                                                  <OverlayTrigger
                                            placement='bottom'
                                            overlay={
                                              <Tooltip>
                                                Playback
                                              </Tooltip>
                                            }
                                          >
                          <img src={PlayBackWhite} className="camera-icon-height" alt='playback white icon'/>
                          </OverlayTrigger>
                        </button>
                      }
                    >
                      <PopupCard item={item} dateChange={dateChange} date={date} getDataFromChild={getDataFromChild} onSubmitBtn={onSubmitBtn} setBlankDate={setBlankDate} timeStringMain={timeStringMain} />
                    </Popup> */}
                    {/* <button className="PlayBackBtnDiv" onClick={e => { onSingleDevPlaybackHandleClick(item) }}>
                  <img src={PlayBackWhite} className="camera-icon-height" /></button>     */}
                  </div>
            }
          </div>
        </div>
        <div className='CameraDetailsDiv'>
          <div>
            <img src={SiteIcon} alt='Site' />
            <span className='CameraDetailsText ml-1'>{item.SiteName}</span>
          </div>
          <div>
            <img src={BranchIcon} alt='Location' />
            <span className='CameraDetailsText'>{item.Location}</span>{" "}
          </div>
        </div>
      </div>
      {/* <Row className="m-0 p-2">  

        <Col lg={10} className="p-0 ForResponsiveWidth">
          <p className="CameraNameText1 camera-name cam-name">{item.CameraName}</p>
        <p className="LocationNameText camera-name location-name">
          <span>{item.SiteName}</span>{" "}
        </p>
        </Col>
        {isCheckBoxVisible ?
        <Col className="col-lg-2 text-right p-0">
          <input
            className="camera-checkbox"
            type="checkbox"
            id={item.ParentCameraID}
            defaultChecked={isChecked ? item.isChecked ? true : false : false}
            onClick={e => { onCheckBoxOnClick(e) }}
          />
        </Col>
        :
        <Col className="col-lg-2 text-right p-0">

            <Popup 
              // className='popupDiv'
              // position="bottom center"
              position={["top center", "right center", ]} 
              keepTooltipInside=".camera-list"
              // closeOnDocumentClick={false}        
              // closeOnDocumentClick={true}      
              trigger={<button className="playbackbtn"
              // onClick={e => { onSingleDevPlaybackHandleClick(item) }}
              >
               <img src={Playbackimage} className="camera-icon-height" />
                <span>PlaybackIcon</span>
                </button>
                }
              >
                <PopupCard item={item} dateChange={dateChange} date={date} getDataFromChild={getDataFromChild} onSubmitBtn={onSubmitBtn} />                
            </Popup>
        </Col>
        } 
      </Row> */}
    </div>
  )
}

export default React.memo(CameraCard)