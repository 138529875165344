// A wrapper for <Route> that redirects to the login

import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "./ProvideAuth";

// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
    let { user } = useAuth()
    let authToken = localStorage.getItem("authToken");
    
    return (
        <>
            <Route
                    {...rest}
                    render={props => {
                    return authToken ? <>{children}</> : <Redirect to="/login" />
                    }}>
                    </Route>

            {/* <Route
                {...rest}
                render={({ location }) =>
                    (auth.user &&
                        JSON.parse(localStorage.getItem('user')) != null) ? (
                            <>
                                {children}
                                <br />
                            </>
                        ) : (
                            <Redirect
                                to={{
                                    pathname: "/login",
                                    state: { from: location },
                                }}
                                render={<></>}
                            />
                        )
                }
            /> */}
        </>

    );
}
export default PrivateRoute;