import React, { useState, useContext, useEffect, useRef } from 'react'
import { ReactComponent as BellIcon } from '../../assets/images/Bell.svg'
import { mixpanel } from '../../utils/MixPanelUtil'
import { Context } from '../../Context/AlertContext'
import { getAlertDetailsById } from "../../services/index"
import { messaging } from '../../services/firebase'
//import { useToasts } from 'react-toast-notifications';
import { useLocation, useHistory } from 'react-router-dom'
import { getAll, getertmessage, updateStatus } from "../../utils/NotificationUtil"
import { ConversationStateContext } from '../../Context/ConversationStateContext'
import { Drawer } from 'rsuite';
import NotificationList from './NotificationList';
import '../../pages/Notification/NotificationLightTheme.scss'
import '../../pages/Notification/NotificationDarkTheme.scss'

let tempArr = [
    {
        key: "Notif-10",
        value: {
            message: "Alert ID - 4846\nDevice name - Dahua IP Cam\nDevice Location - Intellve 102\nDate & Time - 2023-10-12 10:06:06\n",
            status: "read",
            time: "2023-10-12 10:06:12",
            title: "Camera block Alert",
            severity: "2"
        }
    },
    {
        key: "Notif-11",
        value: {
            message: "Alert ID - 4846\nDevice name - Dahua IP Cam\nDevice Location - Intellve 102\nDate & Time - 2023-10-12 10:06:06\n",
            status: "read",
            time: "2023-10-12 10:06:12",
            title: "Camera block Alert",
            severity: "3"
        }
    },
    {
        key: "Notif-12",
        value: {
            message: "Alert ID - 4846\nDevice name - Dahua IP Cam\nDevice Location - Intellve 102\nDate & Time - 2023-10-12 10:06:06\n",
            status: "unread",
            time: "2023-10-12 10:06:12",
            title: "Camera block Alert",
            severity: "4"
        }
    },
    {
        key: "Notif-12",
        value: {
            message: "Alert ID - 4846\nDevice name - Dahua IP Cam\nDevice Location - Intellve 102\nDate & Time - 2023-10-12 10:06:06\n",
            status: "unread",
            time: "2023-10-12 10:06:12",
            title: "Camera block Alert",
            severity: "1"
        }
    },
    {
        key: "Notif-13",
        value: {
            message: "Alert ID - 4846\nDevice name - Dahua IP Cam\nDevice Location - Intellve 102\nDate & Time - 2023-10-12 10:06:06 online\n",
            status: "unread",
            time: "2023-10-12 10:06:12",
            title: "Camera block Alert",
            // severity: "1"
        }
    },
    {
        key: "Notif-14",
        value: {
            message: "Alert ID - 4846\nDevice name - Dahua IP Cam\nDevice Location - Intellve 102\nDate & Time - 2023-10-12 10:06:06 Faulty\n",
            status: "unread",
            time: "2023-10-12 10:06:12",
            title: "Camera block Alert",
            // severity: "1"
        }
    }
]

export default function NotificationIcon({ 
    iconref, 
    isMenuOpen, 
    setIsMenuOpen,
    settabopen
 }) {

    const onBellIconClick=()=>{
        try {
            mixpanel.track("Clicks on Notification Pop Up")
        } catch (error) {
            console.error(error)
        }
        setIsMenuOpen(oldState => !oldState);
        setOpen(true);
    }

    const [open, setOpen] = useState(false);

    const history = useHistory()
    //const { addToast } = useToasts();
    const [notifications, setNotifications] = useState([])
    
    const location = useLocation();
    const [alertState, setAlertState] = useContext(Context) // to refresh alert list
    // let LoggedInUser = JSON.parse(localStorage.getItem('user'))

    const { conversationstate, setconversationState } = useContext(ConversationStateContext)

    const mapNots = (snapshot) => {
        let temp = snapshot.val();
        let newNots = [];
        for (let notification in temp) {
            newNots.push({
                key: notification,
                value: temp[notification]
            });
        }
        setNotifications(prev => prev = newNots);
    }
    useEffect(() => {

        let timer = setTimeout(() => {
            let LoggedInUser = JSON.parse(localStorage.getItem('user'));
        try {
            getAll(LoggedInUser?.UserUniqueID).on('value', mapNots);
            getertmessage().on('value', ertmess)

            //mixpanel.track("Clicks on Notification Pop Up")
        } catch (error) {
            console.error(error)
        }
        }, 1000);
        return () => {
            try {
                let LoggedInUser = JSON.parse(localStorage.getItem('user'));
                getAll(LoggedInUser?.UserUniqueID).off('value', mapNots);
                getertmessage().off('value', ertmess);
                clearTimeout(timer);
            } catch (error) {
                console.error(error)
            }
        }

    }, [])

    const ertmess = (snapshot) => {
        try {
            let temp = snapshot.val();
            //if(temp != "Notification"){
            setconversationState([temp])
            //}
        } catch (error) {
            console.error(error)
        }
    }

    const sortedNots = notifications.sort((a, b) => {
        return Date.parse(b.value.time) - Date.parse(a.value.time)
    })

    const count = sortedNots.reduce((counter, obj) => obj.value.status === 'unread' ? counter += 1 : counter, 0); // 6 

    messaging.onMessage((payload) => {
        if (payload != undefined && payload != null) {
            if (payload?.data?.body != undefined) {
                var IsAlertNotification = payload.data.body.toLowerCase().includes("alert")
                if (IsAlertNotification == true) {
                    refreshAlertList(payload.data.body)
                }
            }

        }
    })

    //refresh alert list on new notification arrival (only alert notifications)
    function refreshAlertList(notificationmessage) { //tejasvini old app->17-03-2021 new app->07-10-2021    
        if (location != undefined && location != null) {
            if (location.pathname != undefined && location.pathname != null) {
                if (location.pathname === "/allalerts") {
                    var alertid=notificationmessage.split("AlertID: ")
                    const data = getAlertDetailsById(alertid[1])
                    if (data != undefined) {
                        data.then(resp => {
                            if (resp != undefined) {                       
                                if (resp.status == "Ok") {
                                    try{
                                        if(alertState){
                                            if(resp.message.length > 0){
                                                var newalert= alertState.find(x => x.alertId == resp.message[0].alertId)
                                                if(newalert){ return }
                                                var newalertobj=NewAlertObject(resp.message[0])
                                                setAlertState([newalertobj,...alertState])
                                            }
                                        }
                                    }catch(error){
                                        console.error(error)
                                    }                                    
                                }else{
                                    console.log(resp.message)
                                }
                            }
                        })
                    }
                }
            }
        }
    }

    const NewAlertObject = (alertobj) => {
        try{
            var alertobj = {
                "alertId": alertobj.alertId,
                "alertSource": alertobj.alertSource,
                "reqActualTime": alertobj.actualTime,
                "severity": alertobj.severity,
                "actualTime": alertobj.actualTime,
                "closedBy": alertobj.closedBy,
                "closedOn": alertobj.closedOn,
                "userName": alertobj.userName,
                "branchId": alertobj.branchId,
                "workflowName": alertobj.workflowName,
                "status": alertobj.status,
                "alertType": alertobj.alertType,
                "receivedTime": alertobj.receivedTime,
                "modifiedOn": alertobj.modifiedOn,
                "metadata": alertobj.metadata,
                "siteName": alertobj.site,
                "alertCode": alertobj.alertCode,
                "deviceId": alertobj.deviceId,
                "assignedTo": alertobj.assignedTo,
                "zoneType": alertobj.zoneType,
                "deviceTypeID": alertobj.deviceTypeID,
                "cameraSource":alertobj.cameraSource
            }
            return alertobj
        } catch (error) {
            console.error("NewAlertObject Error: ",error)
            return null
        }
    }

    const NavigatetoAlertDetailsPage = (mess, key) => {
        try {
            updateStatus(key, 'read')
            settabopen(false)
            var alertid = mess.split("AlertID: ")[1]
            history.push(`/alertdetails/${alertid}`)
        } catch (error) {
            console.error(error)
        }
    }

    /**
     * * To resolve the defect - 133
     * * User will be able to read the notification after clicking on it
     * 
     */
    // useEffect(() => {
    //     if (count > 0 && isMenuOpen) {
    //         sortedNots.map(item=> {
    //             console.error({ item })
    //             updateStatus(item.key, 'unread')
    //         })
    //     }
    // }, [isMenuOpen])

    const markAsRead = (key) => {
        updateStatus(key, 'read');
    }

    return (
        <div className="topNotification" ref={iconref}>

        {/*     <a onClick={() => setOpen(true)}>Open</a> */}

            <a onClick={() => onBellIconClick()}>
                {count != 0 && <span className="badge badge-pill badge-danger ml-1 mt-1">{count}</span>}
                <BellIcon style={{ cursor: "pointer" }} height={20}  className='BellIconStyle'>
                </BellIcon>
            </a>
          {/*   <NotificationContainer
                show={isMenuOpen}
                settabopen={setIsMenuOpen}
                count={count}
                sortedNots={sortedNots}
                NavigatetoAlertDetailsPage={NavigatetoAlertDetailsPage}
                history={history}/> */}


            <Drawer open={open} onClose={() => setOpen(false)} size='xs' className='NotificationDrawer'>                        
                <Drawer.Header>
                    <Drawer.Title>
                        <div className='Not_Header'>
                            <p>Notification<span>({sortedNots.length})</span> </p>
                            <p style={{color:"#0b70d1",textDecoration:"underline"}}>{count} unread</p>
                        </div>
                    
                    </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>   
                    <div className='NotificationListHgtDiv'>
                        {/* <div className='NotificationListItem'>
                            <div className='ItemOnlineStatus'>
                                <div className='AlertImgBg' style={{backgroundColor : "#B5EACC"}}>                                    
                                    <img className='AlertImgIcon' src={NotificationAlertGreen} alt="Notification Alert Green"/>
                                </div>
                                <div className='CameraStatusNotifyDiv'>
                                    <div className='NotificationTextDiv'>
                                        <p className='CameraStatusNameText'>Camera Name online</p>
                                        <OverlayTrigger placement='left'  overlay={ <Tooltip>Mark as read</Tooltip>}>
                                            <button style={{background:"transparent"}}>
                                                <div className='MarkStatusDiv'></div>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    <div>
                                        <p className='CameraStatusDetailsP'>Camera name from site Intellve Ghatkopar status is offline. Camera name from site Intellve Ghatkopar status is offline.
                                        Camera name from site Intellve Ghatkopar status is offline. Camera name from site Intellve Ghatkopar status is offline.
                                        </p>                                        
                                        <p className='DateTimeText'>17-07-2023  22:27:14</p>
                                    </div>                                    
                                </div>
                            </div>
                        </div>


                        <div className='NotificationListItem'>
                            <div className='ItemOnlineStatus'>
                                <div className='AlertImgBg' style={{backgroundColor : "#FEE5C7"}}>                                    
                                    <img className='AlertImgIcon' src={NotificationAlertOrange} alt="Notification Alert Orange"/>
                                </div>
                                <div className='CameraStatusNotifyDiv'>
                                    <div className='NotificationTextDiv'>
                                        <p className='CameraStatusNameText'>Camera Name online</p>
                                        <OverlayTrigger placement='left'  overlay={ <Tooltip>Mark as read</Tooltip>}>
                                            <button style={{background:"transparent"}}>
                                                <div className='MarkStatusDiv'></div>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    <div>
                                        <p className='CameraStatusDetailsP'>Camera name from site Intellve Ghatkopar status is offline.</p>                                        
                                        <p className='DateTimeText'>17-07-2023  22:27:14</p>
                                    </div>                                    
                                </div>
                            </div>
                        </div>

                        <div className='NotificationListItem'>
                            <div className='ItemOnlineStatus'>
                                <div className='AlertImgBg' style={{backgroundColor : "#FFE3E0"}}>                                    
                                    <img className='AlertImgIcon' src={NotificationAlertRed} alt="Notification Alert Red"/>
                                </div>
                                <div className='CameraStatusNotifyDiv'>
                                    <div className='NotificationTextDiv'>
                                        <p className='CameraStatusNameText'>Camera Name online</p>
                                        <OverlayTrigger placement='left'  overlay={ <Tooltip>Mark as read</Tooltip>}>
                                            <button style={{background:"transparent"}}>
                                                <div className='MarkStatusDiv'></div>
                                            </button>
                                        </OverlayTrigger>
                                    </div>
                                    <div>
                                        <p className='CameraStatusDetailsP'>Camera name from site Intellve Ghatkopar status is offline.</p>                                        
                                        <p className='DateTimeText'>17-07-2023  22:27:14</p>
                                    </div>                                    
                                </div>
                            </div>
                        </div> */}

                        {
                            sortedNots.map((item) => (
                                <NotificationList item={item} key={item.key} markAsRead={markAsRead} />
                            ))
                        }
                               {/*  const [showMore, setShowMore] = useState(false);
                                const text = `When I read something on websites and saw a show more/less button, 
                                I thought it was a good feature because it makes everything on the website look more clean. So I would like to share an easy way to insert a show
                                Now the button works and will expand and contract. The next step is to change the buttons wording to Show more/Show less. 
                                By adding a condition in the button as follow`
                                
                                <p className='CameraStatusDetailsP'>
                                    {showMore ? text : `${text.substring(0, 55)}`}
                                    <button 
                                        style={{background:"transparent", color: "#397FF4", textDecoration: "underline"}}
                                        onClick={() => setShowMore(!showMore)}>
                                            {showMore ? "Less" : "...More"}
                                    </button>
                                </p>  */}     
                    
                     {/*    <div className='NotificationListItem'>
                            <div className='ItemOnlineStatus'>
                            <img className='AlertImgIcon' src={NotificationAlertRed} alt="Notification Alert Red"/>
                                <div style={{width:"95%"}}>
                                    <div className='NotificationTextDiv'>
                                        <p className='CameraStatusNameText'>Camera Name offline</p>
                                        <p className='DateTimeText'>17-07-2023  22:27:14</p>
                                    </div>
                                    <div>
                                        <p className='CameraStatusDetailsP'>Camera name from site Intellve Ghatkopar status is offline.</p>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                        <div className='NotificationListItem'>
                            <div className='ItemOnlineStatus'>
                            <img className='AlertImgIcon' src={NotificationAlertOrange} alt="Notification Alert Red"/>
                                <div style={{width:"95%"}}>
                                    <div className='NotificationTextDiv'>
                                        <p className='CameraStatusNameText'>HDD-name faulty</p>
                                        <p className='DateTimeText'>17-07-2023   22:27:14</p>
                                    </div>
                                    <div>
                                        <p className='CameraStatusDetailsP'>Camera-name from site Intellve-Ghatkopar status is offline.</p>
                                    </div>                                    
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Drawer.Body>
            </Drawer>
        </div>
    )
}
