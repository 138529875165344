import React, { memo } from 'react'
import { Handle } from 'react-flow-renderer';

export default memo(({ data}) => {
    // console.log({data})
    // const isValidConnection = (connection) => {
    //     console.log({ connection })
    //     return true
    // }
    // console.log('decision node', { data })

    return (
        <div className="default-node" >
            <Handle type="target"
                position="top"
                className="handle"
                // isValidConnection={isValidConnection}
            />
            <div>{data.label}</div>
            <Handle type="source"
                position="bottom"
                className="handle"
                // isValidConnection={isValidConnection} 
                />
        </div>
    );
})

// export default memo(DefaultNode)