import { BASE_URL,BASE_CORE_URL } from './../config/index'
import DigestClient from "digest-fetch"


//With load balancing - Root - all alerts, admin - only children alerts, operator - only assigned alerts
export const getAlertList = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj
            //userUniqueId: userUniqueId
        ),
    };

    const url = BASE_URL + "getalertlist ";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("Get alert list Error", { error });
        });
}

export const getAlertDetailsById = async (alertId) => {
    // Simple POST request with a JSON body using fetch
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))
    console.log("LoggedInUser", LoggedInUser)
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            alertId: alertId,
            uniqueId: LoggedInUser.UserUniqueID || LoggedInUser.userUniqueID
        }),
    };

    const url = BASE_URL + "getalertdetailsbyalertid ";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            // console.log("Rupesh Checks", data.message[0].cctvtype)
            return data;
        })
        .catch(function (error) {
            console.error("Get alert list Error", { error });
        });
}

export const attachalertevidences = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "attachalertevidences ";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("attachalertevidences Error", { error });
        });
}

export const attachreferencealerts = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "attachreferencealerts";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("attachreferencealerts Error", { error });
        });
}

export const deletealertevidences = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "deletealertevidences";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("deletealertevidences Error", { error });
        });
}

export const createalertcomment = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "createalertcomment";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("createalertcomment Error", { error });
        });
}

export const getalertactivitylogs = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    const url = BASE_URL + "getalertactivitylogs";
    // return await fetch(url,reqoption).then((resp) => resp.json()).then(function (data){console.log(data)})
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            console.log("api response",data)
            return data;
        })
        .catch(function (error) {
            console.error("getalertactivitylogs Error", { error });
        });
}

// export const TwoWayCommunationRequest = async (obj) => {
//     // Simple POST request with a JSON body using fetch
//     const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify(obj),
//     };

//     const url = "" + "TwoWayCommunationRequest";
//     return await fetch(url, requestOptions)
//         .then((resp) => resp.json())
//         .then(function (data) {
//             console.log("api response",data)
//             return data;
//         })
//         .catch(function (error) {
//             console.error("TwoWayCommunationRequest Error", { error });
//         });
// }


export const HooterOnOffRequest = async (privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName) => {
    // const requestOptions = {
    //     method: "GET",
    //     mode: 'no-cors',
    //     headers: { "Content-Type": "application/json" },
    // };

    let url = null
    //Process name is diff according to panel brand e.g.: smartI, HikVision
    // debugger;
    if (cctvTypeNameProps == null) {
        console.log("HooterClick", privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName);
        if (panelBrand) {
            console.log("HooterClick", privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName);
            if (panelBrand.trim().toLowerCase() === 'smarti') {
                console.log("if panel brand smarti", panelBrand.trim().toLowerCase())
                // if (action == 'on') {
                //     url = "http://localhost:4530/"+processName+"/ActivateSmartiHooter?strIp=" + privateIpAddress+"&strPort="+port; 
                // }
                // else if (action == 'off') {
                //     url = "http://localhost:4530/"+processName+"/DeActivateSmartiHooter?strIp=" + privateIpAddress+"&strPort="+port; 
                // }  
                if (action == 'on') {
                    url = "http://localhost:4530/HikVisionCommandProcessService/ActivateSmartiHooter?strIp=" + privateIpAddress + "&strPort=5000";
                    console.log("utility - on url", url)
                }
                else if (action == 'off') {
                    url = "http://localhost:4530/HikVisionCommandProcessService/DeActivateSmartiHooter?strIp=" + privateIpAddress + "&strPort=5000";
                    console.log("utility - off url", url)
                }
            }
            else if (panelBrand.trim().toLowerCase() === 'hikvision') {

            }
            else if (panelBrand.trim().toLowerCase() === 'securico') {

            }
            else if (panelBrand.trim().toLowerCase() === 'vignaharta') {

            }
            else if (panelBrand.trim().toLowerCase() === 'rax') {

            }

        }
        console.log("HooterClick", privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName);
        // if (action == 'on') {
        //     // url = "http://localhost:4530/HikVisionCommandProcessService/ActivateSmartiHooter?strIp=115.242.36.169&strPort=5000";  //public ip
        //     url = "http://localhost:4530/HikVisionCommandProcessService/ActivateSmartiHooter?strIp=192.168.0.202&strPort=5000"; //private ip
        // }
        // else if (action == 'off') {
        //     // url="http://localhost:4530/HikVisionCommandProcessService/DeActivateSmartiHooter?strIp=115.242.36.169&strPort=5000"//public ip
        //     url="http://localhost:4530/HikVisionCommandProcessService/DeActivateSmartiHooter?strIp=192.168.0.202&strPort=5000" //private ip
        // } 
    } else if (cctvTypeNameProps == 'Camera') {
        if (action == 'on') {
            
            if (brandName == "CP Plus" || brandName == "dahua") {

                url = `http://${privateIpAddress}/cgi-bin/configManager.cgi?action=setConfig&AlarmOut[0].Mode=1&AlarmOut[0].Name=noname`
                console.log("utility - on url", url)
            }
        }
        else if (action == 'off') {

             url = `http://${privateIpAddress}/cgi-bin/configManager.cgi?action=setConfig&AlarmOut[0].Mode=0&AlarmOut[0].Name=noname`

            console.log("utility - off url", url)
        }
    }
    console.log("HooterClick", privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName);
    // if (url) {
        let obj =  {
            "strBrandName": brandName,          
            "strIp": privateIpAddress,        
            "strPort": port,
            "strUserName": user,
            "strPassword": password,          
            "strFunction": "hooter",          
            "strCommand": action,          
            "strParamOne": "",          
            "strParamTwo": "",          
            "strParamThree": "",          
            "strParamFour": "",          
            "strParamFive": ""          
          }
          const requestOptions = {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken'),         
            },
            body: JSON.stringify(obj),
        };
    
        const urlData = BASE_CORE_URL + "ProcessCmdCameras/ProcessCmdCameras";
        return await fetch(urlData, requestOptions)
            .then((resp) => resp.json())
            .then(function (data) {
                console.log("api response",data)
                return data;
            })
            .catch(function (error) {
                console.error("ProcessCmdCameras Error", { error });
            });        
    // }
}

//hooter api 
export const HooterOnOffClick = async (pDeviceId, pMode)  => {
    const requestOptions = {
        method: "GET",
        headers: { 
          "Content-Type": "application/json",
          "authcode": localStorage.getItem('authCode'),
          "Authorization": "Bearer " + localStorage.getItem('authToken')
       },
      }; 
      let url = BASE_CORE_URL + "ProcessCmdCameras/HooterControl?pDeviceId="+pDeviceId+"&pMode="+pMode;
     // console.log("CheckAlert",url);
      return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
          return data;
        })
        .catch(function (error) {
          console.error("HooterControl() Exception: ", error);
        }); 
}

export const TwoWayCommunicationRequest = async (privateIpAddress, communicationPort, action, user, password, brandName) => {
   // debugger
    console.log("Rupesh Checks TwoWayCommunicationRequest : ",privateIpAddress, communicationPort, action, user, password, brandName)
    const requestOptions = {
      method: "GET",
      mode: 'no-cors',
      headers: { "Content-Type": "application/json" },
    };
    
    let url = null
    if(brandName != 0 ){
        if (brandName.includes(" ")) {
            brandName = brandName.replace(/\s/g, "")
        }
    }
    //Process name is diff according to nvr/dvr brand e.g.: smartI, HikVision
    if (action == 'start') {
        // url = "http://localhost:4530/TwoWayCommandProcessService/StartTwoWay?strIp=" + privateIpAddress + "&strPort=" + port + "&strUser=" + user + "&strPass=" + password + "&strCMD=StartTwoWay";

        // http://localhost:3005/192.168.0.174/37777/admin/admin@123/cpplus/twowaystart
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/twowaystart`

    }
    else if (action == 'stop') {
        // url = "http://localhost:4530/TwoWayCommandProcessService/StopTwoWay"

        // http://localhost:3005/192.168.0.174/37777/admin/admin@123/cpplus/twowaystop
        console.log("Rupesh Checks TwoWayCommunicationRequest inside else if : ",privateIpAddress, communicationPort, action, user, password, brandName)
        url = `http://localhost:3005/${privateIpAddress}/${communicationPort}/${user}/${password}/${brandName}/twowaystop`
    }
    /*if (brandName) {

        if (brandName.trim().toLowerCase() === 'smarti') {

        }
        else if (brandName.trim().toLowerCase() === 'hikvision') {
            if (action == 'start') {
                url = "http://localhost:4530/TwoWayCommandProcessService/StartTwoWay?strIp=" + privateIpAddress + "&strPort=" + 25001 + "&strUser=" + user + "&strPass=" + password + "&strCMD=StartTwoWay";
            }
            else if (action == 'stop') {
                url = "http://localhost:4530/TwoWayCommandProcessService/StopTwoWay"
            }
        }
        else if (brandName.trim().toLowerCase() === 'securico') {

        }
        else if (brandName.trim().toLowerCase() === 'vignaharta') {

        }
        else if (brandName.trim().toLowerCase() === 'rax') {

        }
      
    }*/

    // if (action == 'start') {
    //     url = "http://localhost:4530/HikVisionCommandProcessService/StartHikTwoWay?strIp=192.168.0.238&strPort=8000&strUser=admin&strPass=123456&strCMD=StartTwoWay"; 
    // }
    // else if (action == 'stop') {
    //     url="http://localhost:4530/HikVisionCommandProcessService/StopHikTwoWay"
    // } 

    if (url) {
        console.log("Rupesh Checks TwoWayCommunicationRequest inside stop",url)
        return await fetch(url, requestOptions)
        .then((resp) => {
          return resp.json();
        })
        .then(function (data) {
          return data;
        });
       
    } 
       
}   
export const ScreenShot = async (privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName) => {
    
 //debugger;
    console.log("ScreenShotClick", privateIpAddress, port, action, panelBrand, cctvTypeNameProps, user, password, brandName);
    // if (url) {
        let obj =  {
            "strBrandName": brandName,          
            "strIp": privateIpAddress,        
            "strPort": port,
            "strUserName": user,
            "strPassword": password,          
            "strFunction": "screenshot",          
            "strCommand": action,          
            "strParamOne": "",          
            "strParamTwo": "",          
            "strParamThree": "",          
            "strParamFour": "",          
            "strParamFive": ""          
          }
          const requestOptions = {
            method: "POST",
            headers: { 
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken'),        
            },
            body: JSON.stringify(obj),
        };
    
        const urlData = BASE_CORE_URL + "ProcessCmdCameras/ProcessCmdCameras";
        return await fetch(urlData, requestOptions)
            .then((resp) => resp.json())
            .then(function (data) {
                console.log("api response",data)
                return data;
            })
            // .then(async (resp) => {
            //     if (resp.ok) {
            //         const data = await resp.blob(); 
            //         return data;
            //     } else {
            //         throw new Error("Network response was not ok");
            //     }
            // })
            .catch(function (error) {
                console.error("ProcessCmdCameras Error", { error });
            });        
    // }
}


export const FilterAlert = async (obj) => {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };
    const url = BASE_URL + "filteralerts ";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error("FilterAlert Error", { error });
        });
}


export const VideoExport = async (brandName,privateIpAddress,httpPort,user,password,startTime,endTime) => {
    //debugger
    //     console.log("VideoExport", privateIpAddress, HttpPort, user, password, brandName);
    //    console.log("Rupesh Checks ",brandName,privateIpAddress,port,user,password,startTime,endTime);
    //    // if (url) {
           let obj =  {
               "strBrandName": brandName,          
               "strIp": privateIpAddress,        
               "strPort": ""+httpPort,
               "strUserName": user,
               "strPassword": password,          
               "strFunction": "videoexport",          
               "strCommand": "string",          
               "strParamOne": startTime,          
               "strParamTwo": endTime,          
               "strParamThree": "",          
               "strParamFour": "",          
               "strParamFive": ""          
             }
             console.log("videoExportOnClick",obj)
             const requestOptions = {
               method: "POST",
               headers: { 
                "Content-Type": "application/json",
                "authcode": localStorage.getItem('authCode'),
                "Authorization": "Bearer " + localStorage.getItem('authToken'),        
             },
               body: JSON.stringify(obj),
           };
           const urlData = BASE_CORE_URL + "ProcessCmdCameras/ProcessCmdCameras";
           return await fetch(urlData, requestOptions)
               .then((resp) => resp.json())
               .then(function (data) {
                   console.log("api response",data)
                   return data;
               })
               .catch(function (error) {
                   console.error("ProcessCmdCameras Error", { error });
               });        
       // }
   }
//RG