import React, { useState, useEffect, useRef } from 'react'
// import CopyIcon from '../../assets/images/copy.png'
import SaveIcon from '../../assets/images/save-icon.svg'
// import BackIcon from '../../assets/images/LeftArrow.png'
import Path from '../../assets/images/Path.png'
import { ConfigureIcon, DelIcon, ImportIcon, CopyIcon, BackArrow } from '../../assets/images'
import { getWFMasterList, loadWorkflows, workFlowOperations } from '../../services/workflowService'
import DefaultNode from './DecisisonNode'
import ReactFlow, {
    ReactFlowProvider,
    ReactFlowProps,
    removeElements,
    updateEdge,
    addEdge,
    MiniMap,
    Controls,
    getOutgoers,
} from 'react-flow-renderer';
import { initialElements } from './InitialElements'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { GetEventTemplateList } from '../../services/configurationServices'
import { Form, OverlayTrigger, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { ConvertDate } from '../../utils/utils'
import { useHistory } from 'react-router-dom'
// import Swal from 'sweetalert2'
import { ConvertDateToUTC } from '../../utils/utils'
import { mixpanel } from '../../utils/MixPanelUtil'
import Tooltip from 'react-bootstrap/Tooltip'
import { toast, ToastContainer } from 'react-toastify';
import { toPng } from 'html-to-image'
import jsPDF from 'jspdf'

const nodeTypes = {
    default: DefaultNode
};
const snapGrid = [20, 20];
let userid = localStorage.getItem('UserID')

let defaultWf = {
    eventTemplateID: 0,
    metadata: {
        elements: [...initialElements("Mod", "Moderate")]
    },
    workflowID: 0,
    workflowMetadataID: 0,
    workflowName: ""
}
const downloadImage = (dataUrl, file_name) => {
    /* ------------------------- JPG/PNG Download Block ------------------------- */
    // let file_n = file_name + '.png'
    // const a = document.createElement('a');
    // a.setAttribute('download', file_n);
    // a.setAttribute('href', dataUrl);
    // a.click();

    /* --------------------------- PDF Download Block --------------------------- */
    const doc = new jsPDF('p', 'pt', 'a4');
    let width = doc.internal.pageSize.getWidth()
    let height = doc.internal.pageSize.getHeight()
    doc.addImage(dataUrl, null, 0, 0, width, height, null, 'FAST');
    doc.save(`${file_name}.pdf`);
}
export default function WorkflowContainer() {
    let history = useHistory()

    let { id } = useParams()

    //#region state variables
    const [wfID, setWfID] = useState(id)

    const [mastersList, setMastersList] = useState(null)
    const [actions, setActions] = useState(null)
    const reactFlowWrapper = useRef(null);
    const [elements, setElements] = useState();
    const [reactFlowInstance, setReactFlowInstance] = useState(null);
    const [selectedNode, setSelectedNode] = useState(null)
    const [isOpen, setIsOpen] = useState(false)
    const [workflows, setWorkflows] = useState(null)
    const [selectedWf, setSelectedWf] = useState(null)
    const [eventTemplates, setEventTemplates] = useState(null)
    const [templatesUI, setTemplatesUI] = useState(null)
    const [selectedTemplateUI, setSelectedTemplateUI] = useState(null)
    const [isActDisabled, setIsActDisabled] = useState(true)
    const [workflowName, setWorkflowName] = useState('')
    const [isClickedForSelect, setIsClickedForSelect] = useState(false);
    const [isClicked2, setIsClicked2] = useState(false);
    const [inputValue1, setInputValue1] = useState("");
    const [selectValue1, setSelectValue1] = useState("");
    //#endregion

    //#region API consumption
    const getMasterList = async () => {
        await getWFMasterList().then((msList) => {
            if (msList.status == "Ok") {
                setMastersList(msList.message)
                setActions(msList.message.nodeMasterList)
            }
        })
    }

    const getWorkflows = async (wfId) => {
         await loadWorkflows(userid).then((resp) => {
            //if (resp && resp.status == "Ok") {
            if (resp) {
                //setWorkflows(resp.message)               
                if (resp.message != null) {
                    if (wfId != 0) {
                        setWorkflows(resp.message)
                        let wf = resp.message.find(x => x.workflowID == wfId)
                        if (wf != undefined) {
                            setSelectedWf(wf)
                        }
                    } else {
                        setSelectedWf(defaultWf)
                        setElements(defaultWf.metadata.elements)
                    }
                }
            }
        })
    }

    const getEventTemplatesList = async () => {
        await GetEventTemplateList().then((resp) => {
            if (resp && resp.length > 0) {
                setEventTemplates(resp)
                let data = []
                resp.map(item => data.push({ value: item.AlertTemplateId, label: `${item.TemplateName}-${item.SeverityTitle}`, severityID: item.SeverityID, severityTitle: item.SeverityTitle, templateName: item.TemplateName }))
                setTemplatesUI(data)
            }
        })
    }
    //#endregion

    //#region side effects

    // useEffect(async () => {
    //     getMasterList()
    //     await getWorkflows(id)
    //     await getEventTemplatesList()
    // }, [])

    useEffect(() => {
        (async () => {
            userid = localStorage.getItem('UserID')
            getMasterList()
             await getWorkflows(id)
             await getEventTemplatesList()
        })();

    }, [])
    //#endregion

    //#region Side bar logic
    const onActionDrag = (event, nodeType) => {

        event.dataTransfer.setData('application/reactflow', nodeType);
        event.dataTransfer.setData('stepdata', event.target.innerText);
        event.dataTransfer.setData('stepId', event.target.id);
        event.dataTransfer.effectAllowed = 'move';
    };
    //#endregion


    //#region Workflow methods
    const onLoad = (_reactFlowInstance) => {
        setReactFlowInstance(_reactFlowInstance);
        _reactFlowInstance.fitView()
    };

    const getId = () => {
        if (elements != null) {
            let maxValueOfY = elements.filter(o => {
                if (o.type != "smoothstep") {
                    return parseInt(o.id)
                }
            }).map(els => parseInt(els.id))

            let matrix = Math.max(...maxValueOfY)
            return `${++matrix}`
        }
    };

    const onDrop = (event) => {
        event.preventDefault();
        const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');
        const stepdata = event.dataTransfer.getData('stepdata');
        const nodeId = event.dataTransfer.getData('stepId');

        try {
            if (stepdata == "Autoclose alert") {
                var closeobj = elements.find(x => x.data.label == "Operator closes the alert")
                if (closeobj) { return }
            } else if (stepdata == "Operator closes the alert") {
                var closeobj = elements.find(x => x.data.label == "Autoclose alert")
                if (closeobj) { return }
            }
        } catch (error) {
            console.error(error)
        }

        const position = reactFlowInstance.project({
            x: event.clientX - reactFlowBounds.left,
            y: event.clientY - reactFlowBounds.top,
        });

        const newNode = {
            id: getId(),
            type,
            position,
            data: {
                content: <>{type}</>,
                label: stepdata,
                nodeId: nodeId,
                otherProps: {
                    action: stepdata,
                    actionPerformedBy: 4
                }
            }
        };
        console.warn({ newNode })
        setSelectedNode(newNode)
        setIsOpen(true)

        // setElements((es) => es.concat(newNode));
    };

    const onDragOver = (event) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'move';
    };

    const onConnect = (params) => {

        let isAlreadyPresent = elements.filter(item => item.type == "smoothstep").find(element => {
            return element.source == params.source
        })
        if (isAlreadyPresent == undefined) {
            setElements((els) => addEdge({
                ...params,
                type: 'smoothstep',
                arrowHeadType: 'arrowclosed'
            }, els));
        }
    }

    const onEdgeUpdate = (oldEdge, newConnection) =>
        setElements((els) => updateEdge(oldEdge, newConnection, els));

    const onElementsRemove = (elementsToRemove) => {
        try {
            let data = elementsToRemove.find(item =>
                item.type == "default" ||
                item.type == "input" ||
                item.type == "smoothstep")

            if (data.id == 1 || data.id == 2 || data.id == "e1-2") return

            setElements((els) => removeElements(elementsToRemove, els));
        } catch (error) {
            console.error({ error })
        }
    }

    const handleClose = (nodeToAdd) => {
        setElements((es) => es.concat(nodeToAdd));
        setIsOpen(false)
    }

    const onSelectElement = (event, element) => {

        if (!(element.data.label === "Alert Generated" || element.data.label === "Alert is Displayed to an Operator")) {

            setSelectedNode(element)
        }

        setIsOpen(true)
    }
    //#endregion

    //#region Set Workflow
    useEffect(() => {
        if (templatesUI != null && selectedWf) {
            let temp = templatesUI.find(x => x.value == selectedWf.AlertTemplateId)

            setSelectedTemplateUI(temp)
        }
    }, [templatesUI])

    useEffect(() => {
        if (selectedWf != null) {
            setElements(selectedWf.metadata.elements)
            setWorkflowName(selectedWf.workflowName)
        }
    }, [selectedWf])
    //#endregion

    //#region Sidebar logic
    const [selectedUser, setSelectedUser] = useState(null)
    const [userActions, setUserActions] = useState([])

    useEffect(() => {
        if (actions && selectedUser != null) {
            let data = actions.filter(x => x.nodeUserID == selectedUser)
            setUserActions(data)
        }
    }, [selectedUser])
    //#endregion

    //#region Configure action form
    const onSaveFormData = (nodeToAdd) => {
        const updatedItems =
            elements.map(el => el)

        var foundIndex = updatedItems.findIndex(x => x.id == nodeToAdd.id);

        if (foundIndex != -1) {
            updatedItems[foundIndex] = nodeToAdd;
            setElements(updatedItems);
        } else {
            setElements((es) => es.concat(nodeToAdd));
        }

        setIsOpen(false)
        setSelectedNode(null)
    }

    const onBackButtonPress = () => {

    }
    //#endregion


    //#region save and delete the workflow
    const [arrangedData, setArrangedData] = useState([])
    const intervalId = useRef()

    const onArrange = (elements) => {
        try {
            let arrangedData = []
            let data = elements.filter(element => element.type == "default" || element.type == "input")

            if (data.length == 0) return;

            let element = data[0];
            for (let i = 0; i < data.length; i++) {
                // console.log({ element }) // For reference
                let outnode = getOutgoers(element, elements)
                // console.log({ outnode }) // For reference
                outnode.map((value, index, array) => {
                    let found = arrangedData.find(item => item.id == value.id)
                    // console.log({ element }, { found }, { value }) // For reference
                    if (found == undefined) {
                        element = value
                        arrangedData.push(value)
                    }
                })
            }

            let edges = elements.filter(element => element.type == "smoothstep")
            // console.log({ edges })// For reference

            let arrangedElements = [{
                id: '1',
                type: 'input',
                data: {
                    content: <>{'input'}</>,
                    label: 'Alert Generated'
                },
                position: { x: 250, y: 5 }
            },
            ...arrangedData,
            ...edges]

            setArrangedData(arrangedElements)

            return arrangedElements
        }
        catch (e) {
            console.error({ e })
        }
    }

    const fetchPayload = () => {
        if (reactFlowInstance) {
            const flow = reactFlowInstance.toObject();
            console.log("workflowsave",flow)
            let flowData = onArrange(flow.elements)
            console.log("workflowsave",flowData)

            //set initial workflow's elements intent
            // let templateName = selectedTemplateUI.templateName
            let templateName = "Alert"
            console.log("workflowsave",templateName)
            // let templateSeverity = selectedTemplateUI.severityTitle
            let templateSeverity = "Severity"
           // console.log("workflowsave",)

            let data = {}
            if (flowData) {
                let finalFlowData = flowData.map((value, index, array) => {
                    if (value.id == 2 && value.type == "default") {
                        value.data.otherProps.intentName = `${templateName.toLowerCase().replaceAll(' ', '_')}_${templateSeverity.toLowerCase()}_alertgenerated`
                        value.data.otherProps.intentText = `${templateName} ${templateSeverity}`
                        return value
                    } else {
                        return value
                    }
                })

                console.error({ finalFlowData })

                flow.elements = finalFlowData

                if (selectedWf != null) {
                    if (selectedWf.workflowID != 0) {
                        data = {
                            action: "updateWF",
                            workflowID: selectedWf.workflowID,
                            workflowName: workflowName,
                            // alertTemplateName: selectedTemplateUI.templateName,
                            alertTemplateName: "Alert",
                            // alertTemplateID: selectedTemplateUI.value,
                            alertTemplateID: "0",
                            modifiedOn: ConvertDate(new Date()),
                            modifiedBy: userid,
                            metadata: {
                                ...flow,
                                // severity: selectedTemplateUI.severityTitle
                                severity: "Severity"
                            }

                        }
                    } else {
                        data = {
                            action: "create",
                            workflowName: workflowName,
                            // alertTemplateName: selectedTemplateUI.templateName",
                            alertTemplateName: "Alert",
                            // alertTemplateID: selectedTemplateUI.value",
                            alertTemplateID: "0",
                            createdOn: ConvertDate(new Date()),
                            createdBy: userid,
                            metadata: {
                                ...flow,
                                // severity: selectedTemplateUI.severityTitle"
                                severity: "Severity"
                            }
                        }
                    }
                } else {
                    data = {
                        action: "create",
                        workflowName: workflowName,
                        // alertTemplateName: selectedTemplateUI.label",
                        alertTemplateName: "Alert",
                        // alertTemplateID: selectedTemplateUI.value",
                        alertTemplateID: "0",
                        createdOn: ConvertDate(new Date()),
                        createdBy: userid,
                        metadata: {
                            ...flow,
                            // severity: selectedTemplateUI.severityTitle"
                            severity: "Severity"
                        }
                    }
                }

            }
            return data;

        }
    }

    const showErrorToast = (msg) => {
        toast.error(msg, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };

    const showSuccessToast = (msg) => {
        toast.success(msg, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    };


//The logic for Saving is change alert template is no loger set in workflow table 24-11-2023
    const onSaveWorkflow = async () => {
        // if (!selectedTemplateUI) {
        //     showErrorToast('Please select alert template!')
        //     return
        // }
        if (window.confirm("Do you want to save the workflow?")) {
            let obj = fetchPayload()
            console.log("workflowsave", obj)
            if (obj.metadata.elements == undefined) { showErrorToast('Workflow cannot be empty!'); return }
            if (obj.workflowName.trim() == "") { showErrorToast('Please enter workflow name!'); return }
            //if (!selectedTemplateUI) { showErrorToast('Please select alert template!'); return }
            if (obj.action == "create") {
                try {
                    var createdtime = ConvertDateToUTC(new Date())
                    mixpanel.track("Clicks on Add Workflow Templates", {
                        "alertTemplateName": obj.alertTemplateName,
                        "WorkflowName": obj.workflowName,
                        "CreatedOn": createdtime
                    })
                } catch (error) {
                    console.error(error)
                }
            }
            await workFlowOperations(obj).then((resp) => {
                if (resp.status == null) {
                    showErrorToast('Error occurred while saving the workflow!')
                    return
                }

                if (resp.status == "Ok") {
                    showSuccessToast(resp.message)
                    let wfId = resp.workflowID
                    //refresh the list
                    getWorkflows(wfId)
                    history.push(`/workflow/${wfId}`)
                }
                else {
                    showErrorToast(resp.message)
                }
            })
        }
    }

    const activateWorkflow = () => {
        let obj = {
            action: "activateLatestModel"
        }

        return workFlowOperations(obj)
    }

    const deleteWorkflow = async () => {
        if (id == 0) return

        let obj = {
            "action": "deleteWF",
            "workflowID": id
        }

        if (window.confirm("Are you sure? You want to delete the workflow?")) {
            await workFlowOperations(obj).then((resp) => {
                if (resp.status == "Ok") {
                    //start timer to activate
                    showSuccessToast(resp.message)
                    //startTrainingModel(true)
                    //refresh the list
                    getWorkflows(0)
                }
            })
        }
    }

    //#endregion


    //#region On Duplicate Workflow
    const onDuplicateWorkflow = () => {
        let duplicateObj = {
            eventTemplateID: 0,
            metadata: selectedWf.metadata,
            workflowID: 0,
            workflowMetadataID: 0,
            workflowName: ""
        }

        setSelectedWf(duplicateObj)

        setIsClickedForSelect(true);
        setIsClicked2(true);

    }

    // Events for Input and React Select
    const handleSelectChange = (e) => {
        setIsClickedForSelect(false)
    };

    const handleInputChange = (e) => {
        if (isClicked2) {
            e.target.style.border = "";
            setIsClicked2(false)
        }
    };

    // React Select Library customStyles Function
    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            borderColor: isClickedForSelect ? "#FAAB20" : provided.borderColor
        })
    };

    //#endregion

    //#region Import
    // const onImport = () => {
    //     history.push('/configuration', { from: "Workflow Page" })
    // }

    const onImport = () => {
        document.querySelector('.react-flow__controls-fitview').click(
            setTimeout(() => {
                toPng(document.querySelector('.react-flow'), {
                    filter: (node) => {
                        if (node?.classList?.contains('react-flow__minimap') || node?.classList?.contains('react-flow__controls')) {
                            return false
                        }
                        return true
                    }
                }).then(function (dataUrl) {
                    downloadImage(dataUrl, workflowName)
                })
                // }).then(downloadImage)
            }, 100)
        )
    }
    //#endregion
    const isMacOS = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
    // const isMacOS = /Mac|iPod|iPhone|iPad/.test(navigator.platform);

    return (
        <>
            <div className="mainContentWithoutSB">
                <div className="row col-lg-12" style={{ margin: "0 auto", padding: "0px" }}>
                    <div className="col-lg-9 pt-2 FlowCharts">
                        <div className="row workflowinputrow">

                            <a onClick={() => history.push('/configuration', { from: "Workflow Page" })}>
                                <img src={BackArrow} style={{ paddingTop: "4px" }} alt="" />
                            </a>

                            {/* <div className="col pl-3 Configure">
                                <Select
                                    id="selectValue1"
                                    isMulti={false}
                                    name="colors"
                                    options={templatesUI}
                                    value={selectedTemplateUI}
                                    styles={customStyles}
                                    isDisabled={selectedWf?.workflowID}
                                    onChange={e => {
                                        setSelectedTemplateUI(e)
                                        handleSelectChange(e)
                                    }}
                                    placeholder="Select Alert Template" />
                            </div> */}

                            <input
                                id="inputValue1"
                                placeholder="Please Enter Workflow Name"
                                type="text"
                                className="col wf-name mt-0"
                                value={workflowName}
                                style={{ border: isClicked2 ? "1px solid #FAAB20" : "" }}
                                onChange={(e) => {
                                    e.preventDefault()
                                    if (selectedWf != null)
                                        setWorkflowName(e.target.value)
                                    handleInputChange(e)
                                }} />

                            <button className="NextMoveButton addWorkFlow-saveWorkFlow-btn" onClick={() => onSaveWorkflow()}>
                                <span className="ViewUpdate">
                                    {selectedWf?.workflowID === 0 ? "Save Workflow" : "Update Workflow"}
                                </span>
                            </button>

                            {/* <button className="col save-wf" onClick={() => onSaveWorkflow()}>
                                <img src={SaveIcon} alt="" />
                                <span className="m-1 pl-2">Save Workflow</span>
                            </button>

                            <button className="col save-wf" onClick={() => onImport()}>
                                <img src={ImportIcon} alt="" />
                                <span className="m-1 pl-2">Open</span>
                            </button> */}

                            {/* <button className="col save-wf m-0" onClick={onActivateWorkflow} disabled={isActDisabled}>
                                <span className="m-1">Activate Workflow</span>
                            </button> */}

                            {/* <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>Duplicate Workflow</Tooltip>}>
                                <button className="del" onClick={onDuplicateWorkflow} >
                                    <img src={CopyIcon} style={{ height: "15px" }} />
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>Delete Workflow</Tooltip>}>
                                <button className="del" onClick={deleteWorkflow} >
                                    <Tooltip title="Delete Workflow" />
                                    <img src={DelIcon} />
                                </button>
                            </OverlayTrigger> */}

                        </div>
                        <>
                            <div className="dndflow">
                                <ReactFlowProvider>
                                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                                        <ReactFlow
                                            elements={elements}
                                            onConnect={onConnect}
                                            onElementsRemove={onElementsRemove}
                                            onLoad={onLoad}
                                            onDrop={onDrop}
                                            onDragOver={onDragOver}
                                            onNodeDoubleClick={onSelectElement}
                                            elementsSelectable={true}
                                            // onConnect={onConnect}
                                            onEdgeUpdate={onEdgeUpdate}
                                            // onElementsRemove={onElementsRemove}
                                            nodeTypes={nodeTypes}
                                            // onNodeDoubleClick={onSelectElement}
                                            deleteKeyCode={
                                                isMacOS? 'Backspace' : 'Delete'
                                              }
                                        >
                                            <Controls />
                                        </ReactFlow>
                                    </div>
                                </ReactFlowProvider>
                            </div>
                        </>
                    </div>
                    <div className="col-lg-3 Configure">

                        {/* <div>
                            <Select
                                isMulti={false}
                                name="colors"
                                options={templatesUI}
                                value={selectedTemplateUI}
                                onChange={e => setSelectedTemplateUI(e)}
                                placeholder="Select Alert Template" />
                        </div> */}

                        <div style={{ padding: "inherit" }}>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 300, hide: 400 }}
                                overlay={<Tooltip>Download Workflow</Tooltip>}>
                                <button className="del" onClick={onImport} >
                                    <img src={ImportIcon} style={{ height: "15px" }} />
                                </button>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>Duplicate Workflow</Tooltip>}>
                                <button className="del" onClick={onDuplicateWorkflow} >
                                    <img src={CopyIcon} style={{ height: "16px" }} />
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 250, hide: 400 }}
                                overlay={<Tooltip>Delete Workflow</Tooltip>}>
                                <button className="del" onClick={deleteWorkflow} >
                                    {/* <Tooltip title="Delete Workflow" /> */}
                                    <img src={DelIcon} />
                                </button>
                            </OverlayTrigger>
                        </div>
                        <div className="ConfigureContent">
                            <div>
                                <img src={ConfigureIcon} />
                                <p className="TextConfigure">Configure</p>
                            </div>
                            {
                                selectedNode == null &&
                                <ActionsList
                                    actions={userActions}
                                    onDrag={onActionDrag}
                                    setSelectedUser={setSelectedUser}
                                    selectedUser={selectedUser} />
                            }
                            {
                                selectedNode &&
                                <>
                                    <ConfigureAction
                                        setSelectedNode={setSelectedNode}
                                        selectedNode={selectedNode}
                                        // onSave={onSaveFormData}
                                        masters={mastersList}
                                        onSave={onSaveFormData}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className="col-lg-3 MobileView" style={{ display: "none" }}>
                        <div className="MobileContent" style={{ padding: "10px", overflowY: "scroll" }}>
                            <pre>{elements && JSON.stringify(elements, null, 2)}</pre>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            /> */}
        </>
    )
}

const FlowNode = (props) =>
    <div
        className="dndnode"
        id={props.id}
        onDragStart={(event) => props.drag(event, 'default')}
        draggable>
        {props.children}
    </div>


const ConfigureAction = ({
    setSelectedNode,
    selectedNode,
    masters,
    onSave
}) => {
    const [responseOptions, setResponseOptions] = useState([])

    const [displayAction, setDisplayAction] = useState("0")

    const { register, handleSubmit, reset, getValues, setValue, control } = useForm({
        defaultValues: { ...selectedNode.data.otherProps }
    });

    const onSubmit = data => {
        data.displayActionAs = displayAction
        let nodeData = masters.nodeMasterList.find(node =>
            node.templateNodesMstID == selectedNode.data.nodeId)

        //for Buttons
        let buttons = []
        //#region unused
        if (displayAction == 3) {
            responseOptions.map(option => {
                let buttonFound = nodeData.rasaData.buttons.find(button => button.title == option.title)
                if (buttonFound != undefined) {

                    let button = {
                        ...buttonFound
                    }
                    buttons.push(button)
                }
            })
        }

        data.buttons = buttons

        selectedNode.data.otherProps = {
            ...selectedNode.data.otherProps,
            ...data,
            btnResponse: nodeData.rasaData.btnResponse,
            responseTime: nodeData.rasaData.responseTime,
            nodeUserID: nodeData.nodeUserID,
            botMessage: nodeData.rasaData.botMessage
        }
        onSave(selectedNode)
    }

    const handleDisplayActionChange = (e) => {
        setDisplayAction(e)
        if (e == 3) getResponseActions()
    }

    const getResponseActions = () => {
        let nodeData = masters.nodeMasterList.find(node =>
            node.templateNodesMstID == selectedNode.data.nodeId)

        if (nodeData.rasaData == null) {
            console.warn("node's rasaData is null")
            return
        }

        setResponseOptions(nodeData.rasaData.buttons)
    }

    useEffect(() => {
        if (selectedNode != null) {
            console.warn('selectedNode.data.nodeId', selectedNode.data)
            let nodeData = masters.nodeMasterList.find(node =>
                node.templateNodesMstID == selectedNode.data.nodeId)

            setValue('action', nodeData.data)
            if (nodeData.rasaData != null) {
                if (nodeData.rasaData.buttons.length == 0) {
                    setResponseOptions([])
                    setDisplayAction("2")
                    setValue('displayActionAs', "2")
                }
                else {
                    getResponseActions()
                    setDisplayAction("3")
                    setValue('displayActionAs', "3")
                }
            } else {
                setResponseOptions([])
                setDisplayAction("2")
                setValue('displayActionAs', '2')
            }
        }
    }, [selectedNode])

    return <>
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="OperationAccordionBgDiv">
                <div id="accordion" className="accordion">
                    <div className="mb-0">
                        <div className="collapsed AccordionActionCardHeader" data-toggle="collapse" href="#collapseOne">
                            <a onClick={() => setSelectedNode(null)}>
                                <img src={BackArrow} alt="" />
                            </a>
                            <p className="ActionText pl-2">Action Related to Operation</p>
                        </div>
                        <div id="collapseOne" className="show" data-parent="#accordion">
                            <p className="SelectActionP">Action</p>
                            <Form.Control
                                className="col-md-12 workflow-action-input-box"
                                // type="text"
                                name="action"
                                {...register}
                                defaultValue={selectedNode.data.otherProps.action}
                                onChange={e => setValue('action', e.target.value)}
                                disabled
                                as="textarea"
                            />
                            <div hidden>
                                <p className="SelectActionP mt-1"> Display Action As</p>
                                <Form.Check
                                    style={{ left: "0", position: "relative" }}
                                    // name="displayActionAs"
                                    label={"Info message"}
                                    name="displayActionAs"
                                    {...register("displayActionAs")}
                                    type="radio"
                                    value="2"
                                    // checked={selectedNode.data.otherProps.displayActionAs == 2}
                                    onChange={e => handleDisplayActionChange(e.target.value)}
                                    disabled />{""}
                                <Form.Check
                                    style={{ left: "0", position: "relative" }}
                                    name="displayActionAs"
                                    {...register("displayActionAs")}
                                    label={"Decision to make"}
                                    type="radio"
                                    value="3"
                                    // checked={selectedNode.data.otherProps.displayActionAs == 3}
                                    onChange={e => handleDisplayActionChange(e.target.value)}
                                    disabled />
                            </div>
                            {
                                displayAction == 3 &&
                                <div hidden>
                                    <p className="SelectActionP">Responses for Question</p>

                                    {responseOptions &&
                                        responseOptions.length != 0 &&
                                        <div
                                            as={Row}
                                            className="col-md-6 inline mr-0">
                                            {
                                                responseOptions.map((option) => {
                                                    return <div className="col m-1">
                                                        <Form.Check
                                                            name="responseOptions"
                                                            {...register}
                                                            type="checkbox"
                                                            label={option.title}
                                                            value={option.title}
                                                            checked={true}
                                                            disabled />
                                                    </div>
                                                })
                                            }
                                        </div>}
                                </div>
                            }
                            <div className="CheckboxDiv" hidden>
                                <div>
                                    <input id="Mandatory" className="Custom_checkbox_Square" name="Mandatory" type="checkbox" checked="" />
                                    <label htmlFor="Mandatory" className="Custom_checkbox_Square_label">Mandatory</label>
                                </div>
                                <div className="ml-5">
                                    <input id="Floating_Step" className="Custom_checkbox_Square" name="Floating_Step" type="checkbox" />
                                    <label htmlFor="Floating_Step" className="Custom_checkbox_Square_label">Floating Step</label>
                                </div>
                            </div>
                            <hr hidden />
                            <div hidden>
                                <p className="SelectActionP" hidden>Select Contact Level</p>
                                <select className="form-control Select_Action">
                                    <option>Cognybot</option>
                                    <option>Cognybot1</option>
                                </select>
                                <p className="SelectActionP mt-4 mb-1" hidden>Response for Question</p>
                                <div>
                                    <input id="L1" className="checkbox-custom" name="L1" type="checkbox" />
                                    <label htmlFor="L1" className="checkbox-custom-label">L1</label>
                                </div>
                                <div>
                                    <input id="L2" className="checkbox-custom" name="L2" type="checkbox" />
                                    <label htmlFor="L2" className="checkbox-custom-label">L1- Alternate 1</label>
                                </div>
                                <div>
                                    <input id="L3" className="checkbox-custom" name="L3" type="checkbox" />
                                    <label htmlFor="L3" className="checkbox-custom-label">L1- Alternate 2</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="ActionTimeDiv" hidden>
                <div>
                    <input id="Action" className="Custom_checkbox_Square" name="Action" type="checkbox" />
                    <label htmlFor="Action" className="Custom_checkbox_Square_label">
                        <span className="ActionTimeText">Action to be Completed Within</span>
                    </label>
                </div>
                <div className="MinsText">
                    <div className="NumberView">
                        <input type="text" className="SiteNameTextBox" value="5" />
                    </div>
                    <div className="NumberView">
                        <select className="form-control Select_Action">
                            <option>Mins</option>
                            <option>Mins1</option>
                        </select>
                    </div>
                    <p className="IfNotText">If not,</p>
                </div>
            </div>
            <div className="EscalationAccordionBgDiv" hidden>
                <div id="accordion" className="accordion">
                    <div className="mb-0">
                        <div className="collapsed AccordionActionCardHeader" data-toggle="collapse" href="#collapseTwo">
                            <a>
                                <p className="ActionText">Escalation Matrix</p>
                            </a>
                        </div>
                        <div id="collapseTwo" className="show" data-parent="#accordion" >
                            <p className="SelectActionP">Select Contact</p>
                            <select className="form-control Select_Action mb-3">
                                <option>L1 - Alternate 1</option>
                                <option>L1 - Alternate 2</option>
                            </select>

                            <p className="SelectActionP">Mode of Communication</p>
                            <select className="form-control Select_Action">
                                <option>Call</option>
                                <option>Call1</option>
                            </select>

                            <div>
                                <label htmlFor="Action" className="Custom_checkbox_Square_label mt-3">
                                    <span className="ActionTimeText1">
                                        Action to be Completed Within
                                    </span>
                                </label>
                            </div>
                            <div className="MinsText">
                                <div className="NumberView">
                                    <input type="text" className="SiteNameTextBox" value="2" />
                                </div>
                                <div className="NumberView">
                                    <select className="form-control Select_Action">
                                        <option>Mins</option>
                                        <option>Mins1</option>
                                    </select>
                                </div>
                            </div>
                            <hr />
                            <p className="SelectActionP">Select Contact</p>
                            <select className="form-control Select_Action mb-3">
                                <option>L1 - Alternate 2</option>
                                <option>L1 - Alternate 3</option>
                            </select>

                            <p className="SelectActionP">Mode of Communication</p>
                            <select className="form-control Select_Action">
                                <option>Call</option>
                                <option>Call1</option>
                            </select>

                            <div className="AddMore">
                                <a href="#" className="UserAddPlus">Add More +</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="AddMore workflowPage">
                <button className="NextMoveButton">
                    <span className="ViewUpdate">Save</span>
                </button>
            </div>
            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </form>


    </>
}


const ActionsList = ({ actions, onDrag, selectedUser, setSelectedUser }) => {
    return <div className="AccordionActionBgDiv">
        <div id="accordion" className="accordion">
            <div className="mb-0 wf-card">
                <div className="collapsed AccordionActionCardHeader" data-toggle="collapse" href="#collapseOne">
                    {/* <a className=""> */}
                    <p className="ActionText">Action Related to</p>
                    {/* </a> */}
                </div>

                <div id="collapseOne" className="collapse show" data-parent="#accordion" >
                    {
                        selectedUser == null &&
                        <>
                            <div className="BgDiv user-type" onClick={() => setSelectedUser(1)}>
                                <p>Operator</p>
                            </div>
                            <div className="BgDiv user-type" onClick={() => setSelectedUser(2)}>
                                <p>Field Responder</p>
                            </div>
                        </>
                    }
                    {
                        selectedUser &&
                        <a onClick={() => setSelectedUser(null)}> <img src={BackArrow} alt="" /></a>
                    }
                    {
                        selectedUser &&
                        actions &&
                        actions.map(item => {
                            return <div
                                className="BgDiv"
                                key={item.templateNodesMstID}
                                onDragStart={(event) => onDrag(event, 'default')}
                                draggable
                                id={item.templateNodesMstID}>
                                <p>{item.data}</p>
                            </div>
                        })}
                </div>
            </div>
        </div>
    </div>
}