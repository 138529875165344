import React, { useEffect, useState, useContext } from 'react'
import { GetDashboardDetails, SystemHealthDevicesCountDash} from "../../services/dashboardService"
import { ConvertDate, frontDateConverter } from '../../utils/utils'
import { EditQc, DeleteQc, LocationHead, QuickConfig, locationicon, ProfileIcon, ClosedAlertIcon, WFIcon, ErrorIcon, CameraIconDashboard, UserIcon, SearchIcon, SystemHealth, CameraStatus, AlertWhiteIcon, SiteWhiteIcon, CameraWhiteIcon } from '../../assets/images'
import { useHistory } from 'react-router-dom'
import { GetEventTemplateList, DeleteEventTemplate } from '../../services/configurationServices'
import Swal from 'sweetalert2'
import { mixpanel } from '../../utils/MixPanelUtil'
import { DAYS_IN_WEEK } from '../../utils/CONSTANTS'
import { CheckForPrivilege } from '../../pages/Profile/PrivilegeExtension'
import InfiniteScroll from "react-infinite-scroll-component"
import { getAlertList } from "../../services/alertAuthService";
import { ConversationStateContext } from '../../Context/ConversationStateContext'
import { Button } from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import Pagination from "react-bootstrap/Pagination";
import { CLIENT_COMPONENET_LINK, IS_HEALTH_MONITORING_TAB_HIDDEN, IS_ALERTS_TAB_HIDDEN, IS_SITE_MANAGEMENT_TAB_HIDDEN, IS_CAMERAS_TAB_HIDDEN } from '../../config'
import Skeleton from "react-loading-skeleton";

//charts---
import { Chart as ChartJS, ArcElement, Tooltip, Legend , LineElement, LinearScale, PointElement, TimeScale, ScatterController} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Doughnut } from 'react-chartjs-2';
import HealthPercent from '../HealthMonitoring/HealthPercent'
import { HEALTHMONITORING } from '../../navigation/CONSTANTS'
import { LogoutDotNet } from '../../services/userAuthServices'
ChartJS.register(ArcElement, Tooltip, Legend, TimeScale, LineElement, LinearScale, PointElement, ScatterController);


// const fs = require('fs');

let loggedinuser = JSON.parse(localStorage.getItem('user'))

export default function DashboardContainer(props) {
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [dashboardData, setDashboardData] = useState(null)
    const [eventTemplates, setEventTemplates] = useState(null)
    const [isSuperUser, setIsSuperUser] = useState(null);
    let history = useHistory()
    const [selectedSiteTab, setSelectedSiteTab] = useState('topThreeSites')
    const [displayModal, setDisplayModal] = useState(false);
    const [systemHealthDevicesCount,setSystemHealthDevicesCount]=useState(0);
    const [systemHealthDevicesOfflineCount, setSystemHealthDevicesOfflineCount] = useState(0);
    const [systemHealthDevicesOnlineCount,setSystemHealthDevicesOnlineCount]=useState(0);
    const [onlinePercent, setOnlinePercent] = useState(0);
    const [darkPieGraph, setDarkPieGraph] = useState(props.isDarkTheme);
    const [loading, setLoading] = useState(false);
    const [dashboardLoading, setDashboardLoading] = useState(false);
    useEffect(() => {
        let tempUser = JSON.parse(localStorage.getItem('user'))
        if (tempUser) {
            let check = tempUser?.UserRoleID == 1 || tempUser?.UserRoleID == 2 || tempUser?.roleId == 1 || tempUser?.roleId == 2;
            setIsSuperUser(check);
            setLoggedInUser(tempUser);
            
            SystemHealthDevicesCount();
        }
        // isSuperUser = tempUser?.UserRoleID == 1 || tempUser?.UserRoleID == 2 || tempUser?.roleId == 1 || tempUser?.roleId == 2
        // setLoggedInUser(JSON.parse(localStorage.getItem('user')));
        // isSuperUser = loggedInUser?.UserRoleID == 1 || loggedInUser?.UserRoleID == 2 || loggedInUser?.roleId == 1 || loggedInUser?.roleId == 2
    }, [localStorage.getItem('user')]);

    useEffect(() => {
        // SystemHealthDevicesCount();
      },[])

    const [alert] = useState(CheckForPrivilege("ALERT"))
    const [camera] = useState(CheckForPrivilege("CAMERALIST_VIEW"))

    const [todaysAlertList, setTodaysAlertList] = useState([])

    const { setIsOperatorWidgetClicked } = useContext(ConversationStateContext)

    //#region useeffect region S
    useEffect(() => {
        GetUserDashboardDetails()
        getEventTemplatesList()
        // FilterAlertList()
        try {
            mixpanel.track("Clicks on Dashboard")
        } catch (error) {
            console.error(error)
        }
    }, [loggedInUser])

    useEffect(() => {
        (async () => {

            if (props.todaysAlertList && props.todaysAlertList.length > 0) {
                setTodaysAlertList(props.todaysAlertList)
            }

        })();

    }, [props.todaysAlertList])
    // useEffect(async () => {

    //     // if (props.alertList && props.alertList.length > 0) {
    //     //     setTodaysAlertList(props.alertList)
    //     // }
    //     if (props.todaysAlertList && props.todaysAlertList.length > 0) {
    //         setTodaysAlertList(props.todaysAlertList)
    //     }

    // }, [props.todaysAlertList])

    //#region useeffect region E


    //#region function region start
    const GetUserDashboardDetails = async () => {
        setDashboardLoading(true);
        try {
            let obj = {
                "action": "dashboardDetails",
                "userId": loggedInUser?.UserID,
                "todaysdate": ConvertDate(new Date())
            }
            GetDashboardDetails(obj).then((resp) => {
                if (resp.status == "Ok") {
                    setDashboardData(resp.message)
                    setDashboardLoading(false);
                }
            })
        } catch (error) {
            console.error(error)
            setDashboardLoading(false);
        }
    }

    //get alert template/event template list
    const getEventTemplatesList = () => {
        GetEventTemplateList().then((resp) => {
            if (resp && resp.length > 0) {
                setEventTemplates(resp)
            }
        })
    }

    //Delete alert template/event template
    const onDeleteTemplate = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you really want to delete this template?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes!',
            allowOutsideClick: false
        }).then((result) => {
            if (result.isConfirmed) {
                DeleteEventTemplate(id).then((resp) => {
                    if (resp.status == "Ok") {
                        getEventTemplatesList()
                        Swal.fire(
                            'Deleted!',
                            "Alert template deleted successfully.",
                            'success'
                        )
                    }
                    else {
                        Swal.fire(
                            'Erroe!',
                            resp.message,
                            'failed'
                        )
                    }
                })

            }
        })

        // if (window.confirm("Do you want to delete the template")) {
        //     DeleteEventTemplate(id).then((resp) => {
        //         if (resp.status == "Ok") {
        //             getEventTemplatesList()
        //             alert(resp.message)
        //         }
        //         else {
        //             alert(resp.message)
        //         }
        //     })
        // }
    }
    //#endregion function region End

    //#region infinite scroll S
    const fetchMoreData = () => {
        try {
            var startindex = 50
            if (parseInt(todaysAlertList.length) >= parseInt(props.alertcounts['TodaysAlerts'])) { return }
            SortingList(todaysAlertList, startindex)
        } catch (error) {
            console.error(error)
        }
    }

    const SortingList = async (templist, index) => {
        try {
            var currentindex = index
            if (templist.length < currentindex) {
                currentindex = templist.length - 1
            } else {
                currentindex = templist.length
            }
            // var obj={
            //     "action":"getAlertList",
            //     "severity":"all",
            //     "userUniqueId":loggedInUser.UserUniqueID,
            //     "startIndex":currentindex,
            //     "numberOfElements":index
            // }

            var obj = {
                "action": "getTodaysAlertsList",
                "userUniqueId": loggedInUser?.UserUniqueID || loggedInUser.userUniqueID,
                "startIndex": currentindex,
                "numberOfElements": index,
                "authCode":localStorage.getItem('authCode')
            }

            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        var newList = (todaysAlertList.concat(resp.message))
                        setTodaysAlertList(newList)
                        // SetNewList(templist.concat(resp.message),tab)
                        // setselectedtablist(templist.concat(resp.message))
                    }
                }
            })
        } catch (error) {
            console.error(error)
        }
    }
    //#region infinite scroll E

    let DT = <Days date={new Date()} />

    const [show, setShow] = useState(false);

    useEffect(() => {
        props.setSelectedTab('dashboard')
    }, [])

    const runRovarUtilUrl = (url) => {
        const requestOptions = { method: "GET" };

        fetch('http://localhost:3000/list', requestOptions)
        .then((resp) => {return resp.json();})
        .then(function (data) {
            // window.open(url, "_blank", "noreferrer")
          return data;
        }).catch(function (error) {
            
            setDisplayModal(true);
            console.error("runRovarUtilUrl () Exception: ", error);
        });

        // if ()
        //window.open(url, "_blank", "noreferrer")
        // fetch(url)
    }


    // const checkFileExistence = () => {
    //     let obj = window.open("rovarrutil://", "_blank", "noreferrer")

        // const filePath = "C://Program Files (x86)//Intellve Stream Engine//rtsp-stream.exe";
        // if (window.fs.existsSync(filePath)) {
        //     runRovarUtilUrl("rovarrutil://")
        //     alert("client component installed")
        // }
        // else {
        //     alert("client component not installed")
        // }


        // const fileReader = new FileReader();
        // fileReader.onload = () => {
        //     runRovarUtilUrl("rovarrutil://")
        //     alert("client component installed")
        // };
        // fileReader.onerror = () => {
        //     alert("client component not installed")
        // };
        // fileReader.readAsDataURL(filePath) 

    
    const SystemHealthDevicesCount = () => {
    setLoading(true);
    let tempUser = JSON.parse(localStorage.getItem('user'));
    let userUniqueID = tempUser.UserUniqueID
    try {
        SystemHealthDevicesCountDash(userUniqueID).then((resp) => {
        if (resp != undefined) {
          if(resp.message == "Unauthorized"){
            LogoutDotNet().then((res) => {
                localStorage.removeItem('authToken');
                localStorage.removeItem('authCode');
                localStorage.removeItem('user');
                localStorage.removeItem('userPassword');
                localStorage.removeItem('userID');
                localStorage.removeItem('hootWayData');
                localStorage.removeItem('sessionExpired');
                localStorage.removeItem('UserID');
                sessionStorage.removeItem('Page_Refresh_State');
                history.push('/login');
            })
            /* Swal.fire({
                text: 'Logged in from another machine, this session is expired you will be logged out from here.',
                confirmButtonText: 'Ok',
                allowOutsideClick: false
            }).then((result) => {
                if(result.isConfirmed){
                    LogoutDotNet().then((res) => {
                        localStorage.removeItem('authToken');
                        localStorage.removeItem('authCode');
                        localStorage.removeItem('user');
                        localStorage.removeItem('userPassword');
                        localStorage.removeItem('userID');
                        sessionStorage.removeItem('Page_Refresh_State');
                        history.push('/login');
                    })
                }
            }) */
            return;
          }  
          if (resp.Status == "success") {
              let totalSystemHealthOffline = resp.Data.filter(item => item.Description == "System Health" && item.Status == "0")
              setSystemHealthDevicesOfflineCount(totalSystemHealthOffline[0]?.Count || 0)
              let offlineCount = totalSystemHealthOffline[0]?.Count || 0;
              let totalSystemHealthOnline = resp.Data.filter(item => item.Description == "System Health" && item.Status == "1")
              setSystemHealthDevicesOnlineCount(totalSystemHealthOnline[0]?.Count || 0)
              let onlineCount = totalSystemHealthOnline[0]?.Count || 0
              let total = parseInt(onlineCount) + parseInt(offlineCount)
              let percent = (onlineCount / total) * 100;  
              if(isNaN(percent)){
                setOnlinePercent(0);
              }else{
                setOnlinePercent(percent.toFixed(0));
              }
            //   setOnlinePercent(percent.toFixed(0));
            setLoading(false);

          } else {
            setSystemHealthDevicesCount(null)
            setLoading(false);
          }
        }
      })
    } catch (error) {
      console.error(error)

    }
  }


/* Chart data start here */
  const data = {
    labels: ['Online','Offline'],
    datasets: [
        {
            label: 'Values',
            data: [systemHealthDevicesOnlineCount,systemHealthDevicesOfflineCount],
            borderRadius: 0,
            backgroundColor: ['#1ab243', '#dddddd'],
            borderWidth: 0,
            //hoverOffset: 4
        }
    ],
    
}
const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {       
        legend: {
            display: false,
        },
        tooltip: {
            enabled: false
        }
    },
    cutout: "85%", 
  
}
const plugins = [
    {
    beforeDraw: function(chart) {
        var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         ctx.font ="bolder 25px sans-serif";
         ctx.textBaseline = "middle";
         var text = `${onlinePercent}%`,
         textX = Math.round((width - ctx.measureText(text).width) / 2),
         textY = height / 2;
         ctx.fillStyle = darkPieGraph ? '' : '#BABABA';
         ctx.fillText(text, textX, textY);
         ctx.save();
    } ,
 },

]
/* Chart data end here */

useEffect(() => {
    let c = onlinePercent;
    setOnlinePercent(c)
    let d = props.isDarkTheme;
    setDarkPieGraph(d);
}, [props]);

 return (
        <div className='MainContentMargin'>
            <div  style={{display:"none"}}>
                <div className="row col-lg-12" style={{ margin: "0 auto", padding: "0px" }}>
                    <div className="col-lg-12 pr-0 dashboardHeadingInnerDiv">
                        <div className="dashboardheadingMain">
                            <div className="dashboardheading">
                                <p>Welcome to MonitoringHub <span>{loggedInUser && " " + (loggedInUser.UserName || loggedInUser.FullName)}</span></p>
                            </div>
                            {/* <div className="CalenderFilter">
                                <input className="SearchViewInput" type="datetime-local" />
                            </div>
                            <div className="locationDropdown">

                            </div> */}
                            {/* <div className="clearFilter">
                                <p>Clear Filter <img src="../Images/clear.png" alt="" /> </p>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className='dashboardScroll'>

                    <div className=" mt-2 dashboard-alerts-widget-div" style={{ margin: "0 auto", padding: "0px", display: "flex" }}>

                        {/* Dashboard Kpi new ui Start */}
                        {/* <div className="col-lg-3 padL padR dashboard-alerts-widget-box">
                        <div className="topKpiCard AlertKPIBG" 
                            // onClick={(e) => { history.push(`/allalerts`) }}
                            >
                            <div className="AlertsCount">
                                <div style={{padding: "10px"}}>
                                <div className='KpiUpperDiv'>
                                    <div style={{ display: "flex"}}>
                                        <div className="dashboard-card-image"><img src={ErrorIcon} alt="" /></div>
                                        <div style={{ paddingLeft: "8px"}}>
                                            <p className="DashboardKpiText">Total Alert(s)</p>
                                            <p className="DashboardKpiNumber">{props && props.alertcounts['TodaysAlerts']}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <img 
                                            src={EditQc} 
                                            alt='' 
                                            onClick={() => {}}
                                        />
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", paddingTop: "8px"}}>
                                    <div>
                                        <p className="kpiTextValue">Open Alert(s)</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                    <div>
                                        <p className="kpiTextValue">ACK Alert(s)</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                    <div>
                                        <p className="kpiTextValue">Closed Alert(s)</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 padL padR dashboard-alerts-widget-box">
                        <div className="topKpiCard SeverityKpiBG" 
                            // onClick={(e) => { history.push(`/allalerts`) }}
                            >
                            <div className="AlertsCount">
                                <div style={{padding: "10px"}}>
                                <div className='KpiUpperDiv'>
                                    <div style={{ display: "flex"}}>
                                        <div className="dashboard-card-image"><img src={ErrorIcon} alt="" /></div>
                                        <div style={{ paddingLeft: "8px"}}>
                                            <p className="DashboardKpiText">Severity</p>
                                            <p className="DashboardKpiNumber"></p>
                                        </div>
                                    </div>
                                    <div>
                                        <img 
                                            src={EditQc} 
                                            alt='' 
                                            onClick={() => {}}
                                        />
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", paddingTop: "8px"}}>
                                    <div>
                                        <p className="kpiTextValue">Extreme</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                    <div>
                                        <p className="kpiTextValue">High</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                    <div>
                                        <p className="kpiTextValue">Medium</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                    <div>
                                        <p className="kpiTextValue">Low</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 padL padR dashboard-alerts-widget-box">
                        <div className="topKpiCard SitesKpiBG" 
                            // onClick={(e) => { history.push(`/configuration?initialTemplate=0`) }}
                            >
                            <div className="AlertsCount">
                                <div style={{padding: "10px"}}>
                                <div className='KpiUpperDiv'>
                                    <div style={{ display: "flex"}}>
                                        <div className="dashboard-card-image"><img src={locationicon} alt="" /></div>
                                        <div style={{ paddingLeft: "8px"}}>
                                            <p className="DashboardKpiText">Site(s)</p>
                                            <p className="DashboardKpiNumber">{dashboardData && dashboardData.totalSites}</p>
                                        </div>
                                    </div>
                                    <div>
                                        <img 
                                            src={EditQc} 
                                            alt='' 
                                            onClick={() => {}}
                                        />
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", paddingTop: "8px"}}>
                                    <div>
                                        <p className="kpiTextValue">Camera(s)</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                    <div>
                                        <p className="kpiTextValue">DVR/NVR(s)</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                    <div>
                                        <p className="kpiTextValue">HDD(s)</p>
                                        <p className="KpiNumberValue">432</p>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 padL padR dashboard-alerts-widget-box">
                        <div className="topKpiCard SiteKPIBG">
                        <div className="AlertsCount">
                                <div style={{padding: "10px"}}>
                                <div style={{display: "flex", justifyContent: "space-between"}}>
                                    <div style={{ display: "flex"}}>
                                        <div className="dashboard-card-image"><img src={ErrorIcon} alt="" /></div>
                                        <div style={{ paddingLeft: "7px"}}>
                                            <p className="DashboardKpiText">Status</p>
                                            <p className="DashboardKpiNumber">5432</p>
                                        </div>
                                    </div>
                                    <div>
                                        <img 
                                            src={EditQc} 
                                            alt='' 
                                            onClick={() => setShow(!show)}
                                        />
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "space-between", paddingTop: "8px"}}>
                                    <div>
                                        <p className="kpiTextValue">Camera(s)</p>
                                        <div style={{ display: "flex"}}>
                                            <div style={{ display: "flex", alignItems: "center",marginRight: "12px"}}>
                                                <div className='StatusInActive'></div>
                                                <span>321</span>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center"}}>
                                                <div className='StatusActive'></div>
                                                <span>123</span>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="kpiTextValue">DVR/NVR(s)</p>
                                        <div style={{ display: "flex"}}>
                                            <div style={{ display: "flex", alignItems: "center",marginRight: "12px"}}>
                                                <div className='StatusInActive'></div>
                                                <span>321</span>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center"}}>
                                                <div className='StatusActive'></div>
                                                <span>321</span>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="kpiTextValue">HDD(s)</p>
                                        <div style={{ display: "flex"}}>
                                            <div style={{ display: "flex", alignItems: "center",marginRight: "12px"}}>
                                                <div className='StatusInActive'></div>
                                                <span>text</span>
                                            </div>
                                            <div style={{ display: "flex", alignItems: "center"}}>
                                                <div className='StatusActive'></div>
                                                <span>text</span>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                        {/* Dashboard Kpi new ui End */}

                
                    <div style={{ width: "100%" }}>
                        <div className=" grid-container grid-container--fit">
                            {/* {
                                isSuperUser &&
                            <div className="topKpiCard OperatorKPIBG" onClick={(e) => {
                                history.push(`/configuration?initialTemplate=users`)
                                setIsOperatorWidgetClicked(true);
                            }}>
                                <div className="AlertsCount">
                                    <div className='dashboard-card-content'>
                                        <div className="dashboard-card-image">
                                            <img className='dashboard-icon' src={UserIcon} alt="" />
                                        </div>
                                        {isSuperUser &&
                                            <a className="cursor-ptr" onClick={(e) => { history.push(`/configuration?initialTemplate=users`) }}>
                                                <div className='dashbord-card-txt'>
                                                    <div><p className="NumberText">{dashboardData && dashboardData.totalOperators}</p>{' '}</div>
                                                    <div><p className="TextValue">Operators</p><br /></div>
                                                </div>
                                            </a>
                                        }
                                        {!isSuperUser &&
                                            <div className='dashbord-card-txt'>
                                                <div><p className="NumberText">{dashboardData && dashboardData.totalOperators}</p>{' '}</div>
                                                <div><p className="TextValue">Operators</p><br /></div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                            } */}
                        </div>

                        {/* <div className="col-lg-3 padL padR dashboard-alerts-widget-box">
                            <div className="topKpiCard AlertKPIBG" onClick={(e) => { history.push(`/allalerts`) }}>
                                <div className="AlertsCount">
                                    <div className='dashboard-card-content'>
                                        <div className="dashboard-card-image">
                                            <img className='dashboard-icon' src={ErrorIcon} alt="" />
                                        </div>
                                        {alert === false &&
                                            <div className='dashbord-card-txt'>
                                                <div><p className="NumberText">{props && props.alertcounts['TodaysAlerts']}</p>{' '}</div>
                                                <div><p className="TextValue">Alerts</p><br /></div>
                                            </div>}
                                        {alert === true &&
                                            <div className='dashbord-card-txt'>
                                                <div><p className="NumberText">{props && props.alertcounts['TodaysAlerts']}</p>{' '}</div>
                                                <div><p className="TextValue">Alerts</p><br /></div>
                                            </div>
                                        }

                                        {props && props.alertcounts && props.alertcounts['TodaysAlerts'] !== undefined ? (
                                            <div className='dashbord-card-txt'>
                                                <div>
                                                    <p className="NumberText">{props.alertcounts['TodaysAlerts']}</p>{' '}
                                                </div>
                                                <div>
                                                    <p className="TextValue">Alerts</p>
                                                    <br />
                                                </div>
                                            </div>
                                        ) : (

                                            <div className='dashbord-card-txt'>
                                                <div>
                                                    <p className="NumberText">{0}</p>{' '}
                                                </div>
                                                <div>
                                                    <p className="TextValue">Alerts</p>
                                                    <br />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 padL padR dashboard-alerts-widget-box">
                            <div className="topKpiCard SiteKPIBG" onClick={(e) => { history.push(`/configuration?initialTemplate=0`) }}>
                                <div className="AlertsCount">
                                    <div className='dashboard-card-content'>
                                        <div className="dashboard-card-image">
                                            <img className='dashboard-icon' src={locationicon} alt="" />
                                        </div>
                                        {isSuperUser &&
                                            <a className="cursor-ptr" onClick={(e) => { history.push(`/configuration?initialTemplate=0`) }}>
                                                <div className='dashbord-card-txt'>
                                                    <div><p className="NumberText">{dashboardData && dashboardData.totalSites}</p>{' '}</div>
                                                    <div><p className="TextValue">Sites</p><br /></div>
                                                </div>
                                            </a>
                                        }
                                        {!isSuperUser &&
                                            <div className='dashbord-card-txt'>
                                                <div><p className="NumberText">{dashboardData && dashboardData.totalSites}</p>{' '}</div>
                                                <div><p className="TextValue">Sites</p><br /></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 padL padR dashboard-alerts-widget-box">
                            <div className="topKpiCard CameraKPIBG"
                                // role = "link"
                                onClick={(e) => {
                                    history.push(`/cameras/true`)
                                    runRovarUtilUrl("rovarrutil://")
                                    // checkFileExistence();
                                }
                                }>
                                <div className="AlertsCount">
                                    <div className='dashboard-card-content'>
                                        <div className="dashboard-card-image">
                                            <img className='dashboard-icon' src={CameraIconDashboard} alt="" />
                                        </div>
                                        {!camera &&
                                            <div className='dashbord-card-txt'>
                                                <div><p className="NumberText">{dashboardData && dashboardData.totalCameras}</p>{' '}</div>
                                                <div><p className="TextValue">Cameras</p><br /></div>
                                            </div>}
                                        {camera &&
                                            <div className='dashbord-card-txt'>
                                                <div><p className="NumberText">{dashboardData && dashboardData.totalCameras}</p>{' '}</div>
                                                <div><p className="TextValue">Cameras</p><br /></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 padL padR dashboard-alerts-widget-box">
                            <div className="topKpiCard OperatorKPIBG" style={{display: 'none'}} onClick={(e) => {
                                history.push(`/configuration?initialTemplate=users`)
                                setIsOperatorWidgetClicked(true)
                            }}>
                                <div className="AlertsCount">
                                    <div className='dashboard-card-content'>
                                        <div className="dashboard-card-image">
                                            <img className='dashboard-icon' src={UserIcon} alt="" />
                                        </div>
                                        {isSuperUser &&
                                            <a className="cursor-ptr" onClick={(e) => { history.push(`/configuration?initialTemplate=users`) }}>
                                                <div className='dashbord-card-txt'>
                                                    <div><p className="NumberText">{dashboardData && dashboardData.totalOperators}</p>{' '}</div>
                                                    <div><p className="TextValue">Operators</p><br /></div>
                                                </div>
                                            </a>
                                        }
                                        {!isSuperUser &&
                                            <div className='dashbord-card-txt'>
                                                <div><p className="NumberText">{dashboardData && dashboardData.totalOperators}</p>{' '}</div>
                                                <div><p className="TextValue">Operators</p><br /></div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="grid-container grid-container--fit">
                        
                            {/* <div className="col-lg-3 padL padR dashboard-alerts-widget-box">
                                <div className="bottomKpiCard ">
                                    <div className="kpi2">
                                        <div className="smallKpiText">
                                            <p>{dashboardData && dashboardData.mostlyContactedFR && dashboardData.mostlyContactedFR.firstName}</p>
                                            <p>Mostly contacted FR</p>
                                        </div>
                                        <div className="ovalDiv">
                                            <img src={ProfileIcon} alt="" className="alertkpiIcon" />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        </div>
                        {/* <div className="col-lg-3 padL pr-0 dashboard-alerts-widget-box">
                        <div className="bottomKpiCard ">
                            <div className="kpi2">
                                <div className="smallKpiText">
                                    <p>{dashboardData && dashboardData.totalOperators}</p>
                                    <p>Operators</p>
                                </div>
                                <div className="ovalDiv">
                                    <img src={ProfileIcon} alt="" className="alertkpiIcon ovalDiv-closedAlerts" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    </div>
                    <div className="row alert-location-quickConfig-container" style={{ margin: "0 auto", padding: "0px" }}>
                    
                        <div className="col-lg-3 padL padR location-and-quickConfigContainer">
                        
                        </div>
                    </div>
                </div>
            </div>
        
        {/* New UI started here*/}
            <div className="row col-lg-12" style={{ margin: "0 auto", padding: "0px" }}>
                <div className="col-lg-12 pr-0 dashboardHeadingInnerDiv">
                    <div className="dashboardheadingMain">
                        <div className="dashboardheading">
                            <p>Welcome to MonitoringHub <span>{loggedInUser && " " + (loggedInUser.UserName || loggedInUser.FullName)}</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='dashboardScroll'>
                <div className="RowClass">
                    <div className="grid-container grid-container--fit">
                        <div 
                        className='systemHealth' 
                        style={{
                            cursor: 'pointer',
                            display: IS_HEALTH_MONITORING_TAB_HIDDEN ? 'none' : 'block'
                        }} onClick={(e)=>{history.push('/healthmonitoring')}}>
                            <div className='CardBoxContent'>
                                <div className="CardBoxImage" style={{backgroundColor:"#1e93c2"}}>
                                    <img className='dashboard-icon' src={SystemHealth} alt="System health"/>
                                </div>
                                <div className='CardBoxText' style={{display: "flex", justifyContent:"space-between"}}>
                                    
                                    <div>
                                        <h4>System Health</h4>
                                        <div className="StatusText" style={{marginTop:"35px"}}>
                                            <div className="StatusValueDiv" style={{width:"auto"}}>
                                                {
                                                    loading
                                                    ? (
                                                        <>
                                                        <Skeleton width={70} className="statusValueText" />
                                                        <Skeleton width={50} className="statusValueP" />
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <p className="statusValueP">{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount))}</p>
                                                            <p className="statusValueText">Total Devices</p>
                                                        </> 
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div style={{width:"55%"}}>
                                        {/* Chart comes here*/} 
                                            <div style={{width: "100%", height:"160px",display:"flex", alignItems:"center", justifyContent:"flex-end"}}>
                                                {/* {
                                                    loading && !onlinePercent
                                                    ? null
                                                    : <HealthPercent systemHealthDevicesOnlineCount={systemHealthDevicesOnlineCount} systemHealthDevicesOfflineCount={systemHealthDevicesOfflineCount} onlinePercent={onlinePercent} />
                                                } */}
                                                <Doughnut key={`${onlinePercent}_${darkPieGraph}`} data={data} options={options}  plugins={plugins}/>
                                            </div>
                                    </div>
                                    
                                </div>                                
                            </div>
                        </div>

                        <div 
                        className='KPI_Margin'>
                            <div 
                            // style={{ display: IS_ALERTS_TAB_HIDDEN ? 'none' : 'block' }}
                            style={{ pointerEvents: IS_ALERTS_TAB_HIDDEN ? 'none' : 'auto' }}
                            className="topKpiCard" onClick={(e) => { history.push(`/allalerts`) }}>
                                <div className="AlertsCount">
                                    <div className='dashboard-card-content'>
                                        <div className="dashboard-card-image" style={{backgroundColor : "#DA8012"}}>
                                            <img className='dashboard-icon' src={AlertWhiteIcon} alt="alert white icon" />
                                        </div>
                                        {alert === false &&
                                            <div className='dashbord-card-txt'>
                                                <div>
                                                    {/* {
                                                        props.rootLoading
                                                        ? <Skeleton className="NumberText" width={30} />
                                                        : <p className="NumberText">{props && props.alertcounts['TodaysAlerts'] || 0}</p>
                                                    } */}
                                                     <p className="NumberText">{props && props.alertcounts['TodaysAlerts'] || 0}</p>
                                                    {/* <p>No value</p> */}
                                                </div>
                                                <div>
                                                    {/* {
                                                        props.rootLoading
                                                        ? <Skeleton className="TextValue" width={50} />
                                                        : <p className="TextValue">Today's Alerts</p>
                                                    } */}
                                                    <p className="TextValue">Today's Alerts</p>
                                                    <br />
                                                </div>
                                            </div>}
                                        {/* {alert === true &&
                                            <div className='dashbord-card-txt'>
                                                <div><p className="NumberText">{props && props.alertcounts['TodaysAlerts']}</p>{' '}</div>
                                                <div><p className="TextValue">Alerts</p><br /></div>
                                            </div>
                                        } */}

                                        {props && props.alertcounts && props.alertcounts['TodaysAlerts'] !== undefined ? (
                                            <div className='dashbord-card-txt'>
                                                <div>
                                                    {/* {
                                                        props.rootLoading
                                                        ? <Skeleton className="NumberText" width={30} />
                                                        : <p className="NumberText">{props.alertcounts['TodaysAlerts'] || 0}</p>
                                                    } */}
                                                     <p className="NumberText">{props.alertcounts['TodaysAlerts'] || 0}</p>
                                                    {/* <p>No value</p> */}
                                                </div>
                                                <div>
                                                    {/* {
                                                        props.rootLoading
                                                        ? <Skeleton className="TextValue" width={50} />
                                                        : <p className="TextValue">Today's Alerts</p>
                                                    } */}
                                                    <p className="TextValue">Today's Alerts</p>
                                                </div>
                                            </div>
                                        ) : (

                                        <div className='dashbord-card-txt'>
                                            <div>
                                                <p className="NumberText">{0}</p>{' '}
                                               {/*  <p>No value</p> */}
                                            </div>
                                            <div>
                                                <p className="TextValue">Today's Alerts</p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                </div>
                            </div>

                            <div className="dashboard-alerts-widget-box">
                                <div className="bottomKpiCard" 
                                    // onClick={(e) => { history.push(`/configuration?initialTemplate=0`) }}
                                    >
                                    <div className="kpi2">
                                        <div className="smallKpiText">
                                            <p>{dashboardData && dashboardData.alertsMostlyGenerated != null && dashboardData.alertsMostlyGenerated + " Severity"}</p>
                                            <p>Alerts mostly generated</p>
                                            {/* <p className="NumberText">{dashboardData && dashboardData.totalSites}</p>
                                            <p>Total Sites</p> */}
                                        </div>
                                        <div className="ovalDiv">
                                            <img src={ErrorIcon} alt="alert error icon" className="alertkpiIcon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div 
                        className='KPI_Margin'>
                            <div 
                            // style={{ display: IS_SITE_MANAGEMENT_TAB_HIDDEN ? 'none' : 'block' }}
                            style={{ pointerEvents: IS_SITE_MANAGEMENT_TAB_HIDDEN ? 'none' : 'auto' }}
                            className="topKpiCard" 
                            onClick={(e) => { history.push(`/configuration?initialTemplate=0`) }}
                            // onClick={(e) => { history.push(`/allalerts`) }}
                            >
                                <div className="AlertsCount">
                                    <div className='dashboard-card-content'>
                                        <div className="dashboard-card-image" style={{backgroundColor:"#6459D6"}}>
                                            <img className='dashboard-icon' src={SiteWhiteIcon} alt="site white icon" />
                                        </div>
                                        {isSuperUser &&
                                            <a className="cursor-ptr" onClick={(e) => { history.push(`/configuration?initialTemplate=0`) }}>
                                                <div className='dashbord-card-txt'>
                                                    <div>
                                                    {
                                                        dashboardLoading
                                                        ? <Skeleton width={30} className="NumberText"/>
                                                        : 
                                                        <p className="NumberText">{dashboardData && dashboardData.totalSites}</p>
                                                        // <p className="NumberText">{props && props.alertcounts['TodaysOpenAlert'] || 0}</p>
                                                    }
                                                        {/* <p className="NumberText">{dashboardData && dashboardData.totalSites}</p> */}
                                                        {/*  <p>No value</p> */}
                                                        {' '}
                                                    </div>
                                                    <div>
                                                    {
                                                        dashboardLoading
                                                        ? <Skeleton width={50} className="TextValue"/>
                                                        : 
                                                        <p className="TextValue">Sites</p>
                                                        // <p className="TextValue">Today's Open Alerts</p>
                                                    }
                                                        <br />
                                                    </div>
                                                </div>
                                            </a>
                                        }
                                        {!isSuperUser &&
                                            <div className='dashbord-card-txt'>
                                                <div>
                                                    {
                                                        dashboardLoading
                                                        ? <Skeleton width={30} className="NumberText"/>
                                                        : 
                                                        <p className="NumberText">{dashboardData && dashboardData.totalSites}</p>
                                                        // <p className="NumberText">{props && props.alertcounts['TodaysOpenAlert'] || 0}</p>
                                                    }
                                                    {/*  <p>No value</p> */}
                                                    {' '}
                                                </div>
                                                <div>
                                                    {
                                                        dashboardLoading
                                                        ? <Skeleton width={50} className="TextValue"/>
                                                        : 
                                                        <p className="TextValue">Sites</p>
                                                        // <p className="TextValue">Today's Open Alerts</p>
                                                    }
                                                    <br />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-alerts-widget-box">
                                <div className="bottomKpiCard" 
                                // onClick={(e) => {
                                //     history.push(`/cameras/true`)
                                //     // runRovarUtilUrl("rovarrutil://")
                                //     // checkFileExistence();
                                // }}
                                >
                                    <div className="kpi2">
                                        <div className="smallKpiText">
                                            <p>{dashboardData && dashboardData.mostUsedWF}</p>
                                            <p>Most used workflow template</p>
                                            {/* <p className="NumberText">{dashboardData && dashboardData.totalCameras}</p>
                                            <p>Total Cameras</p> */}
                                        </div>
                                        <div className="ovalDiv">
                                            <img src={WFIcon} alt="workflow icon" className="alertkpiIcon" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                          

                        <div 
                        className='KPI_Margin'>
                            <div 
                            // style={{ display: IS_CAMERAS_TAB_HIDDEN ? 'none' : 'block' }}
                            style={{ pointerEvents: IS_CAMERAS_TAB_HIDDEN ? 'none' : 'auto' }}
                            className="topKpiCard"
                                //role = "link"
                                onClick={(e) => {
                                    history.push(`/cameras/true`)
                                    // runRovarUtilUrl("rovarrutil://")
                                    // checkFileExistence();
                                }
                                }
                                // onClick={(e) => { history.push(`/myalerts`) }}
                                >
                                <div className="AlertsCount">
                                    <div className='dashboard-card-content'>
                                        <div className="dashboard-card-image" style={{backgroundColor : "#27AD93"}}>
                                            <img className='dashboard-icon' src={CameraWhiteIcon} alt="" />
                                        </div>
                                        {!camera &&
                                            <div className='dashbord-card-txt'>
                                                <div>
                                                    {
                                                        dashboardLoading
                                                        ? <Skeleton width={30} className="NumberText"/>
                                                        : 
                                                        <p className="NumberText">{dashboardData && dashboardData.totalCameras}</p>
                                                        // <p className="NumberText">{dashboardData && dashboardData.closedAlerts}</p>
                                                    }
                                                    
                                                    {/*  <p>No value</p> */}
                                                </div>
                                                <div>
                                                    {
                                                        dashboardLoading
                                                        ? <Skeleton width={50} className="TextValue"/>
                                                        : 
                                                        <p className="TextValue">Cameras</p>
                                                        // <p className="TextValue">Today's Closed Alerts</p>
                                                    }
                                                    <br />
                                                </div>
                                            </div>}
                                        {camera &&
                                            <div className='dashbord-card-txt'>
                                                <div>
                                                    {
                                                        dashboardLoading
                                                        ? <Skeleton width={30} className="NumberText"/>
                                                        : 
                                                        <p className="NumberText">{dashboardData && dashboardData.totalCameras}</p>
                                                        // <p className="NumberText">{dashboardData && dashboardData.closedAlerts}</p>
                                                    }
                                                    {' '}
                                                    {/* <p>No value</p> */}
                                                </div>
                                                <div>{
                                                        dashboardLoading
                                                        ? <Skeleton width={50} className="TextValue"/>
                                                        : 
                                                        <p className="TextValue">Cameras</p>
                                                        // <p className="TextValue">Today's Closed Alerts</p>
                                                    }
                                                    <br />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-alerts-widget-box">
                                <div className="bottomKpiCard" 
                                    // onClick={(e)=>{history.push('/healthmonitoring')}}
                                    >
                                    <div className="kpi2">
                                        <div className="smallKpiText">
                                            <p>{dashboardData && dashboardData.closedAlerts}</p>
                                            <p>Today's closed alerts</p>
                                            {/* <p>{dashboardData && dashboardData.closedAlerts}</p>
                                            <p>Longest Offline Device Duration</p>   */}
                                        </div>
                                        <div className="ovalDiv">
                                            <img src={ClosedAlertIcon} alt="closed alert icon" className="alertkpiIcon ovalDiv-closedAlerts" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="RowClass">
                    <div className="WrapperTable">
                        <div className="alertTable KPI_Margin">
                                <div className="alertTableList">
                                    <p>
                                        {/* <img src="../Images/Error.png" alt="" /> */}
                                        Today's Alert(s)</p>
                                    {/* {alert &&
                                        <button className="viewAllListBtn" onClick={() => history.push('/allalerts')}>View All {props.alertcounts['TodaysOpenAlert']} Open Alerts</button>} */}
                                    {alert && props && props.alertcounts && props.alertcounts['TodaysOpenAlert'] !== undefined && (
                                        // <button className="viewAllListBtn" onClick={() => history.push('/allalerts')}>View All {props.alertcounts['TodaysOpenAlert']} Open Alerts</button>
                                        <button className="viewAllListBtn" onClick={() => history.push('/allalerts')}><p>View All Open Alerts</p></button>
                                    )}
                                </div>
                                <table className="table sticky-table text-center removeClickStyle">
                                        <thead>
                                            <tr>
                                                {/* <td>Username</td>
                                                <td>Alert ID</td>
                                                <td>Alert Code</td>
                                                <td>Site</td>
                                                <td>Severity</td>
                                                <td>Device ID</td>
                                                <td>Time</td>
                                                <td>Workflow</td>
                                                <td>Status</td>
                                                {<td>Action</td>} */}
                                                <td>ID</td>
                                                <td>Customer</td>
                                                <td>Site</td>
                                                <td>Device</td>
                                                <td>ACK By</td>
                                                <td>ACK On</td>
                                                <td>Closed On</td>
                                                <td>Status</td>
                                                <td>Severity</td>
                                                <td>Type</td>                                              
                                            </tr>
                                        </thead>
                                        <tbody className="tbody-dashboard">
                                            {
                                                props.rootLoading
                                                ? 
                                                (
                                                    <tr>
                                                      {
                                                        <>
                                                          <td>
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                          <td>
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                          <td>
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                          <td>
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                          <td className="online-color">
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                          <td>
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                          <td>
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                          <td>
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                          <td>
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                          <td>
                                                            <Skeleton className="width70pct" />
                                                          </td>
                                                        </>
                                                      }
                                                    </tr>
                                                  )
                                                :
                                                (
                                                    <>
                                                    <div id="scrollableDiv">
                                                        <InfiniteScroll
                                                            dataLength={todaysAlertList && todaysAlertList.length}
                                                            next={fetchMoreData}
                                                            hasMore={true}
                                                            scrollableTarget="scrollableDiv"
                                                        >
                                                            {
                                                                    todaysAlertList?.length > 0 
                                                                    ?
                                                                    todaysAlertList.filter(x => x.actualTime.split(' ')[0] == ConvertDate(new Date()).split(' ')[0]).map((item, index) =>
                                                                        <AlertTable
                                                                            key={item.alertId}
                                                                            id={item.alertId}
                                                                            props={item}
                                                                            onAddAlertChat={props.onAddAlertChat}
                                                                            />
                                                                    ) 
                                                                    : <div className='noRecordFound'><p className='noRecordFoundText'>No Record Found.</p></div>
                                                            }
                                                        </InfiniteScroll>
                                                    </div>
                                                    {
                                                        !todaysAlertList && (
                                                            <div className='noRecordFound'><p className='noRecordFoundText'>No Record Available.</p></div>
                                                        )
                                                    }
                                                    </>
                                                )  
                                                  
                                            }
                                        </tbody>
                                </table>
                                
                        </div>

                        <div className='pr-2'>
                            <div className="dashboard-topLocations-widget KPI_Margin">
                                    <div className="kpi-card topLocations-widget">
                                        <div className="section-head">
                                            {/*  <img src={LocationHead} alt="location-head" /> */}
                                            <span className="location-alerts">Location Generated Alerts</span>
                                        </div>
                                        <div>
                                            <ul className="nav nav-pills tabs-list">
                                                <li className="nav-item">
                                                    <a className={selectedSiteTab == "topThreeSites" ? "nav-link active top-three-sites" : "inactive-text-dashboard top-three-sites"}
                                                        onClick={() => setSelectedSiteTab('topThreeSites')}>
                                                        {/* <p className='selected-location-tab-text'> */}
                                                        Top 3 sites</a>
                                                    {/* </p> */}
                                                </li>
                                                <li className="nav-item">
                                                    <a className={selectedSiteTab == "bottomThreeSites" ? "nav-link active bottom-three-sites" : "inactive-text-dashboard bottom-three-sites"}
                                                        onClick={() => setSelectedSiteTab('bottomThreeSites')}>
                                                        {/* <p className='selected-location-tab-text'> */}
                                                        Bottom 3 sites</a>
                                                    {/* </p> */}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="tab-content">
                                            {selectedSiteTab && selectedSiteTab == "topThreeSites" && 
                                                (<div className="tab-pane container active template-list pl-0 pr-0" id="top-3">
                                                    {(dashboardData &&
                                                        dashboardData.topThreeAlertsGeneratedSites && dashboardData.topThreeAlertsGeneratedSites.length > 0 ? 
                                                        dashboardData.topThreeAlertsGeneratedSites.map((item, index) =>
                                                            <LocationList key={item.siteId}
                                                                id={item.siteId}
                                                                sites={item} />
                                                        ) : (
                                                            <div className='noRecordFound'><p className='noRecordFoundText' style={{left: "35%"}}>No Record Available.</p></div>
                                                        )
                                                    )}
                                                </div>) 
                                            }
                                            {selectedSiteTab && selectedSiteTab == "bottomThreeSites" &&
                                                (<div className="tab-pane container active template-list pl-0 pr-0" id="bottom-3">
                                                    {(dashboardData &&
                                                        dashboardData.bottomThreeAlertsGeneratedSites && dashboardData.bottomThreeAlertsGeneratedSites.length > 0 ?
                                                        dashboardData.bottomThreeAlertsGeneratedSites.map((item, index) =>
                                                            <LocationList key={item.siteId}
                                                                id={item.siteId}
                                                                sites={item} />
                                                        ) : (
                                                            <div className='noRecordFound'><p className='noRecordFoundText'style={{left: "35%"}}>No Record Available.</p></div>
                                                        )
                                                    )}
                                                </div>) 
                                            }

                                        </div>
                                    </div>
                            </div>

                            <div className='mt-3'>
                            {
                                isSuperUser &&
                                <div className="dashboard-quick-config-widget KPI_Margin">
                                    <div className="kpi-card quick-config-widget">
                                        <div className="section-head quick">
                                            {/*  <img src={QuickConfig} alt="quick-config" className='configimg' /> */}
                                            {/* <QuickConfig height={17} width={17} /> */}
                                            <span className="location-alerts quick-config-widget">Quick Configurations</span>
                                        </div>
                                        <div className='dashboard-quickconfig-alerttemplate-outer'>
                                            <div className='dashboard-quickconfig-alerttemplate-inner'>
                                                <p className='dashboard-quickconfig-alerttemplate-text'>Alert Template</p>
                                            </div>
                                        </div>

                                        <div className="tab-content">
                                            <div className="tab-pane container active pl-0 pr-0 template-list mt-1 QuickConfigHeight" id="alerts" style={{height:"175px"}}>
                                                {(
                                                    eventTemplates &&
                                                    eventTemplates?.map((item, index) =>
                                                        <EventTemplateList
                                                            key={item.AlertTemplateId}
                                                            id={item.AlertTemplateId}
                                                            template={item}
                                                            onDeleteTemplate={onDeleteTemplate}
                                                            history={history} />
                                                    )
                                                )}
                                                {
                                                    !eventTemplates && 
                                                    <div className='noRecordFound'><p className='noRecordFoundText'style={{left: "35%"}}>No Record Available.</p></div>
                                                }
                                            </div>
                                        </div>
                                        {/* Removing It */}
                                        {/* <div className='manage-config-align' style={{ paddingRight: "15px" }}>
                                            <button className="manage-config" onClick={() => {
                                                AddMixpanelEvent("Manage")
                                                history.push('/configuration')
                                            }}>
                                                <span>Manage Configurations</span>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={() => setShow(false)} centered dialogClassName='StatusModel'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className='StatusHeaderDiv'>
                            <p className='ModelHeaderName'>Device Status</p>
                            <Button variant='primary'>Export</Button>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='StatusTableDiv'>
                            <Table striped hover border={0}>
                                <thead>
                                    <tr>
                                        <th>
                                            <p>Device Name(s)</p>
                                            <div className="SearchTextDiv StatusSearchText">
                                                <input className="SearchViewInput" type="text" placeholder='Search' />
                                                <img className="EyeIcon" alt="Search-icon" src={SearchIcon} />
                                            </div>
                                        </th>
                                        <th>
                                            <p>Device Type(s)</p>
                                            <div className="SearchTextDiv StatusSearchText">
                                                <input className="SearchViewInput" type="text" placeholder='Search' />
                                                <img className="EyeIcon" alt="Search-icon" src={SearchIcon} />
                                            </div>
                                        </th>
                                        <th>
                                            <p>Current Status(s)</p>
                                            <div className="SearchTextDiv StatusSearchText">
                                                <input className="SearchViewInput" type="text" placeholder='Search' />
                                                <img className="EyeIcon" alt="Search-icon" src={SearchIcon} />
                                            </div>
                                        </th>
                                        <th>
                                            <p>Last Updated Time</p>
                                            <div className="SearchTextDiv StatusSearchText">
                                                <input className="SearchViewInput" type="text" placeholder='Search' />
                                                <img className="EyeIcon" alt="Search-icon" src={SearchIcon} />
                                            </div>
                                        </th>
                                        <th>
                                            <p>IP Address(s)</p>
                                            <div className="SearchTextDiv StatusSearchText">
                                                <input className="SearchViewInput" type="text" placeholder='Search' />
                                                <img className="EyeIcon" alt="Search-icon" src={SearchIcon} />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr>
                                        <td>Device name will come here</td>
                                        <td>DVR</td>
                                        <td><p>Online</p></td>
                                        <td><span className='DateTimeTableSpan'>21 May 2023</span><span>17:05:50</span></td>
                                        <td>192.168.00.00</td>
                                    </tr>
                                    <tr>
                                        <td>Device name will come here</td>
                                        <td>DVR</td>
                                        <td><p>Online</p></td>
                                        <td><span className='DateTimeTableSpan'>21 May 2023</span><span>17:05:50</span></td>
                                        <td>192.168.00.00</td>
                                    </tr>
                                    <tr>
                                        <td>Device name will come here</td>
                                        <td>DVR</td>
                                        <td><p>Online</p></td>
                                        <td><span className='DateTimeTableSpan'>21 May 2023</span><span>17:05:50</span></td>
                                        <td>192.168.00.00</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                        <div className="selectDeviceDiv">
                            <div className="PaginationDiv">
                                <h5 className='PageNumbers'>1 - 100</h5>
                                <span>of</span>
                                <h5 className='PageNumbers'>25550</h5>
                                <Pagination>
                                    <Pagination.First />
                                    <Pagination.Item>{1}</Pagination.Item>
                                    <Pagination.Item>{2}</Pagination.Item>
                                    <Pagination.Item active>{3}</Pagination.Item>
                                    <Pagination.Item>{4}</Pagination.Item>
                                    <Pagination.Last />
                                </Pagination>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}
const AddMixpanelEvent = (action, templatename) => {
    try {
        try {
            if (action == "Manage") {
                mixpanel.track("Clicks on Quick Configuration " + action)
            } else {
                mixpanel.track("Clicks on Quick Configuration " + action, { "AlertTemplateName": templatename })
            }
        } catch (error) {
            console.error(error)
        }
    } catch (error) {
        console.error(error)
    }
}

const AlertTable = ({ props, onAddAlertChat }) => {
    let history = useHistory()
    return (
        <tr onClick={(e) => {
            if(e.detail != 1){
                return;
            }else{
                onAddAlertChat(props.alertId).then((res) => {
                })      
            }
            // history.push(`/alertdetails/${props.alertId}`)
        }}
        style={{cursor: 'pointer'}}
        >
            {/* <td>{props.userName}</td>
            <td>{props.alertId}</td>
            <td>{props.alertCode}</td>
            <td>{props.siteName}</td>
            <td className={`ReportTableAlertSeverity ${props.severity.toLowerCase()}`}>{props.severity}</td>
            <td>{props.deviceId}</td>
            <td>{frontDateConverter(props.actualTime)}</td>
            <td>{props.workflowName}</td>
            {props.status && props.status == "Open" &&
                <td><span className="StatusBtn StatusBtn_open">{props.status}</span></td>
            }
            {props.status && props.status == "Closed" &&
                <td><span className="StatusBtn StatusBtn_closed">{props.status}</span></td>
            }
            {props.status && props.status == "Acknowledged" &&
                <td><span className="StatusBtn StatusBtn_ack">{props.status}</span></td>
            }

            {<td>{props.closedOn}</td>}
            {<td><img src={EditQc} alt="" /><img src="../Images/delete.png" alt="" /></td>} */}

            {<td>{props.alertId}</td>}
            {<td>{props.customerName}</td>}
            {<td>{props.siteName}</td>}
            {<td>{props.deviceName}</td>}
            {<td>{props.ackbyName ? props.ackbyName : "--"}</td>}
            {<td>{props.ackOn  ? props.ackOn : "--"}</td>}
            {<td>{props.closedOn ? props.closedOn : "--"}</td>}
            {/* {<td>{props.status}</td>} */}

            {props.status && props.status == "Open" &&
                <td><span className="StatusBtn StatusBtn_open">{props.status}</span></td>
            }
            {props.status && props.status == "Closed" &&
                <td><span className="StatusBtn StatusBtn_closed">{props.status}</span></td>
            }
            
             {props.severity && props.severity == "Extreme" &&
                <td><span className="StatusBtn extreme_colour">{props.severity}</span></td>
            }
            {props.severity && props.severity == "High" &&
                <td><span className="StatusBtn high_colour">{props.severity}</span></td>
            }
            {props.severity && props.severity == "Moderate" &&
                <td><span className="StatusBtn medium_colour">{props.severity}</span></td>
            }
            {props.severity && props.severity == "Low" &&
                <td><span className="StatusBtn low_colour">{props.severity}</span></td>
            }
             {<td>{props.alertType}</td>}
        </tr>
        
    )
}

const LocationList = ({ sites }) => {
    return <>
        <div className="location-list-item template-list-item">
            <span className="template-name dashboard_configuration_templateName">{sites.siteName}</span>
        </div>
    </>
}

const EventTemplateList = ({ template, onDeleteTemplate, history }) => {
    return <>
        <div className="location-list-item template-list-item pointerItem"
            onClick={() => history.push({
             pathname: '/configuration',
             state: {
                isAlertTemplate: true,
                params: template.AlertTemplateId
             }
            })}
            // onClick={() => history.push(`/configuration?alerttemplateid=${template.AlertTemplateId}`)}
        >
            <span className="template-name dashboard_configuration_templateName">{template.TemplateName}</span>
            <span className="qc-actions">
                <img src={EditQc} id={template.AlertTemplateId} alt="edit-qc" onClick={() => {
                    AddMixpanelEvent("Edit", template.TemplateName)
                }} />
                <img src={DeleteQc} id={template.AlertTemplateId} onClick={(e) => {
                    AddMixpanelEvent("Delete", template.TemplateName)
                    onDeleteTemplate(template.AlertTemplateId)
                    e.stopPropagation();
                }}
                    alt="delete-qc" />
            </span>
        </div>
    </>
}


const Days = ({ date }) => {
    return (
        <div className="DayCount">
            {
                DAYS_IN_WEEK.map((value, index, array) => {
                    return <span key={value.id} className={`DayText ${value.id == date.getDay() ? 'today' : ''}`}>{value.initialLetter}</span>
                })
            }
            {/* */}

        </div>
    )
}