import { BASE_CORE_URL } from "../config"

export const getRegisteredUsers = async (siteIds, pageNumber, searchText) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }

    let url = BASE_CORE_URL + "NotificationConfiguration/GetUserBySite?siteIds="+siteIds+"&pageNumber="+pageNumber+"&searchText="+searchText;

    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("getRegisteredUsers", data);
            return data;
        }) 
        .catch(err => console.log(err));
}

export const getMentionsList = async () => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }

    let url = BASE_CORE_URL + "NotificationConfiguration/GetNotificationPlaceMasterHolder";

    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("getMentionsList",data);
            return data;
        })
        .catch(err => console.log(err));
}

export const getNotifs = async () => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "NotificationConfiguration/Get";

    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("getNotifs",data);
            return data;
        })
        .catch(err => console.log(err));
}

export const addNotifs = async (obj) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
        body: JSON.stringify(obj)
    }
    let url = BASE_CORE_URL + "NotificationConfiguration/Add";

    return await fetch(url, requestOptions)
    .then(res => res.json())
    .then(data => {
        console.log("getMentionsList",data);
        return data;
    })
    .catch(err => console.log(err))
}

export const updateNotifs = async (obj) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
        body: JSON.stringify(obj)
    }
    let url = BASE_CORE_URL + "NotificationConfiguration/Update";

    return await fetch(url, requestOptions)
    .then(res => res.json())
    .then(data => {
        console.log("getMentionsList",data);
        return data;
    })
    .catch(err => console.log(err))
}

export const getNotifById = async (id) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "NotificationConfiguration/GetById?id="+id; 
    
    return await fetch(url, requestOptions)
    .then(res => res.json())
    .then(data => {
        console.log("getNotifById",data);
        return data;
    })
    .catch(err => console.log(err))

}

export const deleteNotifs = async (obj) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
        body: JSON.stringify(obj)
    }
    let url = BASE_CORE_URL + "NotificationConfiguration/Delete";

    return await fetch(url, requestOptions)
    .then(res => res.json())
    .then(data => {
        console.log("deleteNotifs",data);
        return data;
    })
    .catch(err => console.log(err))
   
}