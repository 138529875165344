import React, { useEffect, useState } from "react"
import { ScreenShot, attachalertevidences } from "../../services/alertAuthService"
import { addrasaconversationindb } from "../../services/chatFlowService";
import { ConvertDate } from '../../utils/utils'
import useRecorder from '../Alerts/UseRecorder'
import { ErrorToast } from "../../CustomHooks/ErrorToast";
import { SendIcon, MikeIcon, AttachEvidenceChatIcon, DownloadIcon, MosaicSnapshot } from '../../assets/images';
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function ChatflowEvidenceAttachment(props,item,chat) {
    // console.log("rupesh checks rupesh",props)
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))

    const [alertremark, setalertremark] = useState("")

    const [isrecording, setisrecording] = useState(false)
    const [isFileSelected, setisFileSelected] = useState(false)
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFilename, setSelectedFilename] = useState("");

    const [evidencename, setevidencename] = useState('')
    const [evidencetype, setevidencetype] = useState('')
    const [evidenceurl, setevidenceurl] = useState([])
    const [evidencedocs, setevidencedocs] = useState([])
    const [isOpenmodal, setisOpenmodal] = useState(false)
    const [isimage, setisimage] = useState(false)
    const [isdoc, setisdoc] = useState(false)


    let [audio, audioURL, isRecording, audioSize, startRecording, stopRecording] = useRecorder();

    //#region Attch evidence methods

    const ChangeEvidenceFileHandler = (event) => {
        try {
            const fileSelector = document.createElement('input');
            fileSelector.setAttribute('type', 'file');
            fileSelector.setAttribute('accept', '.pdf, .docx, .xlsx, .txt, .png, .jpg, .jpeg, .mp4, .mov, .avi, .wmv');
            //fileSelector.setAttribute('multiple', 'multiple');
            fileSelector.addEventListener("change", handleChange);
            fileSelector.click();
        } catch (error) {
            console.error(error)
        }
    };

    const handleChange = (e) => {
        const files = e.target.files;
        if (files && files[0]) {
            const file = files[0];
            const fileType = file.type.split('/')[0];
            const allowedFileTypes = ['image', 'application', 'text', 'video'];
            if (allowedFileTypes.includes(fileType)) {
                if (file.size <= 5242880) {
                    setSelectedFile(file);
                    setSelectedFilename(file.name);
                    setisFileSelected(true);
                    onAddFileCallback(file);
                } else {
                    console.log('File size should be less than 5MB.');
                }
            } else {
                console.log('Please Select Documents Or Images For Evidence.');
            }
        }
    }

    const onAddFileCallback = (files) => {
        try {
            let newfile = files[files.length == 0 ? 0 : files.length - 1]
            let reader = new FileReader()
            reader.readAsDataURL(files);
            reader.onload = (e) => {
                Attachalertevidences(e.target.result, files.name)
                //return attachEvidence(e, selectedFile.name)
            }
        } catch (error) {
            console.error(error)
        }
    }

    //#endregion


    //#region Voice notes

    useEffect(() => {
        if (audio != null) {
            onAttachOperatorResponse(audio)
            setisrecording(false)
        }
    }, [audio])

    const onAttachOperatorResponse = (data) => {
        var reader = new FileReader();
        reader.onload = function () {
            Attachalertevidences(reader.result, `OperatorResponse_${props.AlertID}_${new Date().getTime()}.mp3`)
        };
        reader.readAsDataURL(data);
    }

    useEffect(() => {
        if (isrecording) startRecording()
        else stopRecording()
    }, [isrecording])

    //#endregion


    const Attachalertevidences = (e, fname) => {
        try {
            var obj = {
                "evidenceFileName": fname,
                "alertId": props.AlertID,
                "userId": LoggedInUser.UserID,
                "base64String": e
            }
            attachalertevidences(obj).then((evidenceRes) => {
                if (evidenceRes.status === "Ok") {
                    setisFileSelected(false)
                    OnAttachedSuccess(evidenceRes)
                    props.scrollToBottom()
                    props.setCapturedImage(null);
                }
                else {
                    console.error('Error while attaching the evidence ', evidenceRes.message)
                    // alert(evidenceRes.message)
                    ErrorToast(evidenceRes.message);
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    const OnAttachedSuccess = (evidenceRes) => {
        try {
            var myCurrentDatetime = new Date();
            var _datetime = ConvertDate(myCurrentDatetime)
            var rasaconvobj = {
                "evidenceType": "img",
                "text": evidenceRes.documentUrl,
                "timestamp": _datetime,
                "type": "user"
            }
            var addindbobj = {
                "assignedTo": LoggedInUser.UserID,
                "rasaConversationID": props.ConversationId,
                "uploadType": "file",
                "evidence": rasaconvobj,
                "conversation": null,
                "userType": "operator",
                "createdOn": _datetime
            }
            addrasaconversationindb(addindbobj).then((resp) => {
                if (resp.status === "Ok") {
                    props.OnEvidenceAttached(evidenceRes)
                }
                else {
                    console.error('Error while saving evidence to databse ', resp.message)
                }
            })
        } catch (error) {
            console.error(error)
        }
    }

    async function AddComments() {
        try {
            var remark = alertremark.trim()
            if (remark == "") {
                alert("Enter remark!")
                return
            }
            var myCurrentDatetime = new Date();
            var _datetime = ConvertDate(myCurrentDatetime)
            var rasaconvobj = {
                "evidenceType": "comment",
                "text": "Comment :" + remark,
                "timestamp": _datetime,
                "type": "user"
            }
            addrasaconversationindb({
                "assignedTo": LoggedInUser.UserID,
                "rasaConversationID": props.ConversationId,
                "uploadType": "file",
                "evidence": rasaconvobj,
                "conversation": null,
                "userType": "operator",
                "createdOn": _datetime
            }).then((resp) => {
                if (resp.status === "Ok") {
                    document.getElementById("Commenttxt:" + props.AlertID).value = ""
                    setalertremark("");
                    var evidenceRes = { "documentUrl": "Comment :" + remark }
                    props.OnEvidenceAttached(evidenceRes)
                    props.scrollToBottom();
                }
                else {
                    console.error('Error while saving evidence to databse ', resp.message)
                }
            })
        } catch (error) {
            console.error("CinemaView", { error })
        }
    }

    
    
      const ScreenShotClick = async (privateIPAddress, port, action, panelBrand, cctvtype, camUserName, camPassword, brandName) => {
             try {
               if (privateIPAddress && port && camUserName && camPassword) {
                 ScreenShot(privateIPAddress, port, action, panelBrand, cctvtype, camUserName, camPassword, brandName)
                   .then((resp) => {
                     console.log("image", resp);
                     if(resp.Data == ""){
                        ErrorToast("Failed to take the snapshot");
                        return;
                     }
                     if(resp){
                        let fileData = "data:image/png;base64," + resp.Data;
                        let fname = brandName + "_" + Date.now() + ".png";
                        Attachalertevidences(fileData, fname);
                     }
                     //return resp;
                   });
               }
             } catch (error) {
               console.error(error)
             }
 
           }

           const ScreenShotOkClick = (audio,chat) => {
            console.log("ScreenShotOkClick", chat);
            //Correct One
            ScreenShotClick(chat?.privateIpAddress, chat?.port, 'start',chat?.panelBrand,chat?.cctvtype, chat?.camUserName,chat?.camPassword, chat?.brandName)
            //Testing One
            //Hikvision
            // ScreenShotClick("192.168.0.44", "554", 'start',null,"Camera", "admin","Admin@123", "Hikvision")
            //CP Plus
            // ScreenShotClick("192.168.0.64", "554", 'start',null,"Camera", "admin","2o2oImaging", "CP Plus")
            //Dahua
            // ScreenShotClick("192.168.0.174", "554", 'start',null,"Camera", "admin","Admin@12345", "Dahua")
            // ScreenShotClick("192.168.0.174", "554", 'start',null,"Camera", "admin","Abc@12345", "Dahua")
          }
           
          useEffect(() => {
            if(props.capturedImage){
                let fileData = props.capturedImage;
                let fname = props.brandName + "_" + Date.now() + ".png";
                Attachalertevidences(fileData, fname);
            }
          
            return () => {
            //   second
            }
          }, [props.capturedImage])
          
    return (
        <div className="chatFlowBoxContainer" style={{justifyContent: "flex-start"}}>
            <input onKeyDown={(e) => {
                if(e.key === 'Enter'){
                    AddComments()   
                }
            }} id={"Commenttxt:" + props.AlertID} className="chatFlowWebChatBoxInput" type="text" placeholder="Enter comments here" onChange={e => setalertremark(e.target.value)} />
            
            {/* <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip>
                        ScreenShotClick
                    </Tooltip>
                }
            >
            <button className='PlayBackBtnDiv' style={{marginRight: "10px"}}><img  height={20} width={20} src={MosaicSnapshot} onClick={() => ScreenShotOkClick('start', props.alertDetails)} /></button>
            </OverlayTrigger> */}
            
            <a className="cursor-ptr" onClick={() => AddComments()}>
                <img src={SendIcon} alt="send icon" height={20} width={20} />
            </a>
            <button className="LinkButtonView1 cursor-ptr" type="file" onClick={ChangeEvidenceFileHandler}>
                <img src={AttachEvidenceChatIcon} alt="attach icon" />
            </button>
            <div className="chatFlowWebChatVoiceButton cursor-ptr" onClick={() => setisrecording(!isrecording)}>
                {/* <div className={isrecording && "Blink"}> */}
                <img src={MikeIcon} alt="mike icon" className={isrecording && "Blink"} />
                {/* </div> */}
            </div>

        </div>
    )
}