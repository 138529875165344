import React from 'react'

const DataAccessListItem = ({item, selectItemHandler, type, isItDisabled}) => {
  return (
    <div key={item.Id} className="dataAccessListItem">
        <input type="checkbox" disabled={isItDisabled} id={item.Id} value={item.Name} name={item.Name} checked={item.isItSelected} onChange={() => selectItemHandler(item.Id, type)} />
        <p className="AllTextwithValue" onClick={() => selectItemHandler(item.Id, type)}>
            {item.Name}
        </p>
    </div>
  )
}

export default DataAccessListItem