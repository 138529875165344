import React,{ useEffect, useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { ReportIcon, DownloadIcon, DropArrow, monitoringHubLogo_dataURL } from '../../assets/images';
import XLSX from 'xlsx'
import Swal from 'sweetalert2'
import NoReportFound from './NoReportFound'
import { Row, Col, Dropdown} from 'react-bootstrap'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { CSVLink } from 'react-csv';

export default function AlertActivityReport(props) {
  
  const [disableDownload, setDisableDownload] = useState(false);
  useEffect(() => {
    console.log("propsData", props);
    if (props.list === null) {
      setDisableDownload(true);
    }else{
      setDisableDownload(false);
    } 
  }, [props.list]);
  const csvData = [
    [
      'Alert ID','Site Name','Device Type','Alert Type','Severity','Generated at','Acknowledge at','Operator','Workflow Associated','Alert Closed at','Resolution time',
    ],
    // ...props.list.map((item) => [
    //   item.alertId,item.siteName,item.deviceTypeID && item.deviceTypeID === 1 ? item.alertSource : item.zoneName, item.alertSource, item.severityTitle,item.actualTime,item.ackOn, item.operatorName,item.workflowName,item.closedOn,
    //   item.timeToCloseAlert === 'NA' ? 'NA' : item.timeToCloseAlert,
    // ]),
    ...props.list.map((item) => [
      item.alertId,item.siteName,item.alertSource,item.alertType,item.severityTitle,item.actualTime,item.ackOn, item.operatorName,item.workflowName,item.closedOn,
      item.timeToCloseAlert === 'NA' ? 'NA' : item.timeToCloseAlert,
    ]),
  ];
  
    const onDownloadReportCSV = (e) => {
        e.stopPropagation();
        if ( props.list == null || props.list.length == 0) {
            Swal.fire({ text: 'No records available to download.'})
            return;
        }
        const csvContent = csvData.map((row) => row.join(',')).join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        const csvURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = csvURL;
        link.setAttribute('download', 'Alert_Activity_Report.csv');
        document.body.appendChild(link);
        link.click();

        window.URL.revokeObjectURL(csvURL);
        document.body.removeChild(link);
        
        
        // let addUpdateTable = document.getElementById('alert-activity-report')

        // var wb = XLSX.utils.table_to_book(addUpdateTable, { sheet: "Alert activity report" });
        // var ws_name = "Alert Activity Report"
        // var ws_Error = XLSX.utils.table_to_sheet(addUpdateTable)
        // return XLSX.writeFile(wb, 'Alert Activity Report.xlsx');
        // 
        
    };

  const onDownloadReportPDF = () => {
   
    if (props.list === null || props.list.length == 0) {
      Swal.fire({ text: 'No records available to download.' });
      return;
    }
    var doc = new jsPDF("p", "pt", "a4");
    doc.autoTable({
      html: "#alert-activity-report",
      startY: 50,
      styles: {
        fontSize: 5,
        cellWidth: "wrap",
      },
      headStyles: {
        fillColor: "5083BD",
      },
      didDrawPage: function (data) {
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.addImage(monitoringHubLogo_dataURL, 'JPEG', 35, 5, 75, 40);
        doc.text("Alert Activity Report", 210, 30);
      },
      margin: { top: 60 }
    });
    doc.save("Alert-Activity-Report.pdf");
  };

    return (
      <>
        <div className="ReportAccordionMainContainer">
          <Accordion defaultActiveKey="1" style={{paddingBottom: "10px"}}>
            {/* <div className="ReportAccordionMainContainer"> */}
              <div className="ReportAccordionHeader">
                {/* <Accordion.Toggle eventKey="1" className="ReportAccordionHeader"> */}
                {/* Header Section */}
                <>
                  <>
                    {/* <div className="report-title-head"> */}
                    <div>
                      <Row>
                        <Col lg={10} className = "AlertActivityReportheading">
                          <div className="ReportAccordianTitleIcon">
                            <img src={ReportIcon} alt="Report Icon" />
                          </div>
                          <div className="ReportAccordianTitleText">
                            <h5
                              className="mb-0"
                              style={{ marginLeft: "1rem" }}
                            >
                              {props.title} {`(${props?.list?.length})`}
                            </h5>
                            <p className="ReportSubTitleText">
                              This Report Displays List of Closed Alerts
                            </p>
                          </div>
                        </Col>
                        { 
                        disableDownload
                        ? null
                        :
                        <Col lg={2} className = "ReportDownloadLink">
                         <a className="ReportRightOptions">
                            <Dropdown
                              
                             className="dropdown profileDpDwn w-0">
                              <Dropdown.Toggle
                              
                                as={ProfileDropDown}
                                id="dropdown-custom-components"
                              >
                                <img src={DownloadIcon} alt='download icon' />
                                Download
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  eventKey="1"
                                  onClick={onDownloadReportCSV}
                                 // disabled={disableDownload}
                                 disabled={disableDownload}
                                >
                                  CSV
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item
                                  eventKey="2"
                                  onClick={onDownloadReportPDF}
                                  disabled={disableDownload}
                                  //disabled={disableDownload}
                                  
                                >
                                  PDF
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </a>
                        </Col> 
                        }
                      </Row>
                    </div>
                  </>
                  <div className="col-2 align-items-center justify-content-end">
                    {/* <div className="ReportRightOptions">
                                    <img src="../Images/Share.png" />
                                    <p>Share</p>
                                </div>
                                <div className="ReportRightOptions">
                                    <img src="../Images/Download.png" />
                                    <p>Download</p>
                                </div> */}
                    {/* <div className="ReportAccordianArrow">
                                    <img className=" drop-arrow" src="../Images/drop-arrow.svg" alt="" />
                                </div> */}
                  </div>
                </>
              </div>
              {/* </Accordion.Toggle> */}
              {/* <Accordion.Collapse eventKey="1"> */}
              {/* Table Section */}
              <>
                {props && props.list && (
                  <div className="card-body p-0">
                    {props.list.length > 0 && (
                      <table
                        className="table table-text-ext table-fixed report-table"
                        id="alert-activity-report"
                      >
                        <thead className="ReportTableHeader ReportTableHeadReport">
                          <tr>
                            <th scope="col">Alert ID</th>
                            <th scope="col">Site Name</th>
                            <th scope="col">Device Type</th>
                            {/* <th scope="col">Alert Source</th> */}

                            <th scope="col">Alert Type</th>
                            <th scope="col">Severity</th>
                            <th scope="col">Generated at</th>
                            <th scope="col">Acknowledge at</th>
                            <th scope="col">Operator</th>
                            <th scope="col">Workflow Associated</th>
                            <th scope="col">Alert Closed at</th>
                            <th scope="col">Resolution time</th>
                          </tr>
                        </thead>
                        <tbody>
                          {props.list &&
                            props.list.map((item, index) => (
                              <ActivityReportTable
                                key={item.alertId}
                                id={item.alertId}
                                props={item}
                              />
                            ))}
                        </tbody>
                      </table>
                    )}
                    {props.list.length == 0 && <NoReportFound />}
                  </div>
                )}
              </>
              {/* </Accordion.Collapse> */}
            {/* </div> */}
          </Accordion>
        </div>
      </>
    );

}

const ActivityReportTable = ({ props }) => {
    return <>
        <tr>
            <td>{props.alertId}</td>
            <td className="ReportTableFontBold">{props.siteName}</td>
            <td>{props.alertSource}</td>
            {/* <td>{props.deviceTypeID && props.deviceTypeID == 1?
            props.alertSource && props.alertSource:props.zoneName && props.zoneName}</td> */}

            <td>{props.alertType}</td>
            <td className={`ReportTableAlertSeverity ${props.severityTitle.toLowerCase()}`}>{props.severityTitle}</td>
            <td>{props.actualTime}</td>
            <td>{props.ackOn}</td>
            <td>{props.operatorName}</td>
            <td className="ReportTableFontBold">{props.workflowName}</td>
            <td>{props.closedOn}</td>
            <td className="ReportTableFontBold">{props.timeToCloseAlert && props.timeToCloseAlert=='NA'?'NA': props.timeToCloseAlert} </td>
        </tr>
    </>
}

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const ProfileDropDown = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        className="profileDpDwn profile-name"
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        {/* &#x25bc; */}
        <DropArrow style={{ color: "black" }} alt="drop arrow"  className="m-2"/>
    </span>
));