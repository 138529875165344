import { BASE_URL, MULTI_PLAYBACK_FLASK_URL } from './../config/index'

// export async function deleteCamDevicePermanently(StreamName, parentCameraID) {
    
//     // Simple POST request with a JSON body using fetch
//     // let user = JSON.parse(localStorage.getItem('user'));
//     // let useruniqueid = user.UserUniqueID
    
//     if (StreamName != null) {
//       // var myCurrentDatetime = new Date();
//       const requestOptions = {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({
//           streamName: StreamName,
//           parentCameraId:parentCameraID,
//           // uniqueuser_id: useruniqueid,
//           // camera_name: camera_nameRef,
//           // ComponentType: "Web",
//           // SystemDeviceID: "",
//           // FcmToken: token,
//           // DateTime: myCurrentDatetime,
//         }),
//       };
//       const url = BASE_URL + "deletecameradevpermanentely";
//       return await fetch(url, requestOptions)
//         .then((resp) => resp.json())
//         .then(function (data) {
//           console.log(data);
//           return data;
//         })
//         .catch(function (error) {
//           console.log(error);
//         });
//     } else {
//       return undefined;
//     }
//   }

  export async function checkalertassociated(devid) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
  
    let url = BASE_URL + "checkalertassociated?DeviceID=" + devid;
    return await fetch(url, requestOptions)
      .then((resp) => {
        return resp.json();
      })
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        console.error("checkalertassociated () Exception: ", error);
      });
  }

  export async function getSyncReplayURL(StreamName, StartTime, EndTime) {
    try {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          StreamName: StreamName,
          StartTime: StartTime,
          EndTime: EndTime,
        }),
      };
      const url = BASE_URL + "getsyncreplayurl";
      return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
          return data;
        })
        .catch(function (error) {
          console.error(error);
        });
    } catch (e) {
      console.log(e);
    }
  }




export const getMultipleDevicePlaybackDetails = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };
  let url = MULTI_PLAYBACK_FLASK_URL + "getPlaybackURLList";
  return await fetch(url, requestOptions).then((response) =>
    response.json()).then(
      function (data) {
        return data;
      }).catch(function (error) {
        console.error("deviceconfigcrud () Exception: ", error);
        if (error.response) {
          console.log(error.response);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
            }

export const stopStream = (alias) => {
  let url = "http://localhost:3000/stop";
  let requestOptions = {
    method: "POST",
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({
      alias: alias
    })
  }
  fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => console.log(err));
}