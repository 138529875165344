import React, {useState} from 'react'
import { useHistory } from 'react-router-dom'
// import cognyfloLogo1 from '../../assets/images/cognyfloLogo1.png'
import { AlertGenericImg, AlertGenericImg1 } from '../../assets/images'
import { Modal } from 'react-bootstrap'
import { ZeroCountAlert } from './ZeroCountAlert'
import InfiniteScroll from 'react-infinite-scroll-component'

export default function AlertListView({
    severity,
    extremeAlertLst,
    highAlertLst,
    moderateAlertLst,
    lowAlertsLst,
    allAlerts,
    onAddAlertChat,
    resetInfiniteScroll,
    selectedtablist,
    fetchMoreData,
    handleScroll
}) {
    const [isimage, setisimage] = useState(false)
    const [isOpenmodal, setisOpenmodal] = useState(false)
    const [snapshoturl, setsnapshoturl] = useState([])

    const ClosePreview = () => {
        setisOpenmodal(false)
    }
    const handleChange = (isimg, snapurl) => {
        setisimage(isimg)
        setisOpenmodal(true)
        setsnapshoturl(snapurl)
    }

    return (
        <>
        <Modal
                show={isOpenmodal}
                dialogClassName={"activityUpdateModel"}
                onHide={ClosePreview}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton style={{ padding: "0 1rem", borderBottom: "0" }}></Modal.Header>
                <Modal.Body>
                    <div id="EvidenceViewContainer">
                        <div style={{ display: (isimage ? 'block' : 'none') }}>
                            <img
                                style={{ height: "500px", width: "465px" }}
                                src={snapshoturl}
                                alt='sanpshot image'
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <div className="col-lg-12 pr-0">
                <div className="MainListDiv">
                    <div className="newListofAlert">
                        <div className="ListMain" style={{height: "55px"}}>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Code
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    ID
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Site
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                            <p className="OperateBy">
                                    Customer
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                            <p className="OperateBy">
                                    Device
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                            <p className="OperateBy">
                                    ACK By
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Date & Time
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Status
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Severity
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Type
                                </p>
                            </div>
                            <div className="headerOperateByDiv">
                                <p className="OperateBy">
                                    Actions
                                </p>
                            </div>
                            <div className="ChartsButtonDiv">
                                {/* <button className="AlertViewButton">
                                    <p>View Alert</p>
                                </button> */}
                            </div>
                        </div>
                    </div>
                    <div id="scrollableDivOne" className="infinite-scroll-div-list" onScroll={handleScroll}>
                    <InfiniteScroll
                        key={resetInfiniteScroll}
                        dataLength={selectedtablist.length}
                        next={fetchMoreData}
                        hasMore={true}
                        // loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDivOne"
                    >
                    <div className="scrollbar" id="style-3">
                    
                        {
                            severity == 'extreme' &&
                            extremeAlertLst &&
                            extremeAlertLst.map((item, index) =>
                                <AlertList 
                                    key={item.alertId} 
                                    id={item.alertId} 
                                    alert={item} 
                                    onAddAlertChat={onAddAlertChat}
                                    handleChange={handleChange}/>
                            )
                        }

                        {
                            severity == 'high' &&
                            highAlertLst &&
                            highAlertLst.map((item, index) =>
                                <AlertList 
                                    key={item.alertId} 
                                    id={item.alertId} 
                                    alert={item} 
                                    onAddAlertChat={onAddAlertChat}
                                    handleChange={handleChange}/>
                            )
                        }

                        {
                            severity == 'moderate' &&
                            moderateAlertLst &&
                            moderateAlertLst.map((item, index) =>
                                <AlertList 
                                    key={item.alertId} 
                                    id={item.alertId} 
                                    alert={item}
                                    onAddAlertChat={onAddAlertChat}
                                    handleChange={handleChange} />
                            )
                        }

                        {
                            severity == 'low' &&
                            lowAlertsLst &&
                            lowAlertsLst.map((item, index) =>
                                <AlertList 
                                    key={item.alertId} 
                                    id={item.alertId} 
                                    alert={item} 
                                    onAddAlertChat={onAddAlertChat}
                                    handleChange={handleChange}/>
                            )
                        }
                        {
                            severity == 'all' &&
                            allAlerts &&
                            allAlerts.map((item, index) =>
                                <AlertList
                                    key={item.alertId}
                                    id={item.alertId}
                                    alert={item}
                                    onAddAlertChat={onAddAlertChat} 
                                    handleChange={handleChange}/>
                            )
                        }
                    </div>
                    <div>
                    {
                        severity == "extreme" && extremeAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "high" && highAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "moderate" && moderateAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "low" && lowAlertsLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "all" && allAlerts.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    </div>
                    </InfiniteScroll>
                    </div>
                </div>
            </div>
        </>
    )


}
const AlertList = ({ id, alert, onAddAlertChat,handleChange }) => {
    let history = useHistory()
    return <div className="ListofAlerts">
        <div className="ListMain">
            <div style={{width: "11%"}}>
                {alert.snapshotURL ?  <img 
                src={alert.snapshotURL} 
                className='AlertSnapshotDbImg'
                alt='snapshot image'
                // onClick={()=>handleChange(true,alert.snapshotURL)}
            onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=AlertGenericImg1;
            }} />: <AlertGenericImg className='AlertSnapshotgenericImg' 
            />}
            </div>
            {/* <img src={cognyfloLogo1} /> */}
            <div className="SituationTitleDiv">

                <p className="OperateBy">
                    {/* ID<br /> */}
                    <span className="OperateName">
                    {id}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                {/* <p className="OperateBy">
                    Severity<br />
                    <span className={`ReportTableAlertSeverity ${alert.severity?.toLowerCase()}`}>
                        {alert.severity}
                    </span>
                </p> */}
                  <p className="OperateBy">
                    {/* Site<br /> */}
                    <span className="OperateName">
                    {alert.siteName}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                {/* <p className="OperateBy">
                    Alert Source<br /> */}
                    {/* {alert.deviceTypeID && alert.deviceTypeID == 1?
                        <span className="OperateName">{alert.alertSource && alert.alertSource}</span> :
                        <span className="OperateName">{alert.zoneType && alert.zoneType}</span>} */}
                    {/* <span className="OperateName">
                        {alert.alertSource}
                    </span>
                </p> */}
               <p className="OperateBy">
                    {/* Customer<br /> */}
                    <span className="OperateName">
                    {alert.customerName}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
            <p className="OperateBy">
                    {/* Device<br /> */}
                    <span className="OperateName">
                    {alert.deviceName}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
            <p className="OperateBy">
                    {/* ACK By<br /> */}
                    <span className="OperateName">
                    {alert.ackbyName? alert.ackbyName : "--"}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                <p className="OperateBy">
                    {/* Date & Time<br /> */}
                    <span className="OperateName">
                        {alert.reqActualTime}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                {/* <p className="OperateBy">
                    Branch<br />
                    <span className="OperateName">
                        {alert.siteName}
                    </span>
                </p> */}
                 <p className="OperateBy">
                    {/* Status<br /> */}
                    <span className="OperateName">
                        {alert.status}
                    </span>
                </p>
            </div>
            <div className="OperateByDiv">
                <p className="OperateBy">
                    {/* Severity<br /> */}
                    {/* <span className="OperateName">
                        {alert.severity && alert.severity == "Extreme" &&
                            <span className="StatusBtn extreme_colour">{alert.severity}</span>
                        }
                        {alert.severity && alert.severity == "High" &&
                            <span className="StatusBtn high_colour">{alert.severity}</span>
                        }
                        {alert.severity && alert.severity == "Moderate" &&
                            <span className="StatusBtn medium_colour">{alert.severity}</span>
                        }
                        {alert.severity && alert.severity == "Low" &&
                            <span className="StatusBtn low_colour">{alert.severity}</span>
                        }
                    </span> */}
                    <span className="NewOperateName">
                        {alert.severity && alert.severity == "Extreme" &&
                            <p className="StatusBtn extreme_colour">{alert.severity}</p>
                        }
                        {alert.severity && alert.severity == "High" &&
                            <p className="StatusBtn high_colour">{alert.severity}</p>
                        }
                        {alert.severity && alert.severity == "Moderate" &&
                            <p className="StatusBtn medium_colour">{alert.severity}</p>
                        }
                        {alert.severity && alert.severity == "Low" &&
                            <p className="StatusBtn low_colour">{alert.severity}</p>
                        }
                    </span>
                </p>

            </div>
            <div className="OperateByDiv">
                <p className="OperateBy">
                    {/* Type<br /> */}
                    <span className="OperateName">
                        {alert.alertType}
                    </span>
                </p>
            </div>
            <div className="ChartsButtonDiv">
                {/* <button className="AlertViewButton">
                    <p>View Alert</p>
                </button> */}
                <button className="AlertViewButton m-1" onClick={(e) => { history.push(`/alertdetails/${id}`) }}><p>View Alert</p></button>
                {
                    (alert.closedBy != 0)
                    ?
                    <button className="SelectionAlertViewButton m-1" onClick={() => { history.push(`/alertreport/${id}`) }}>
                        <p>Report</p>
                    </button>
                    :
                    <button className="SelectionAlertViewButton m-1" onClick={() => { onAddAlertChat(id) }}>
                        <p>Chatflow</p>
                    </button>
                }
            </div>
        </div>
    </div>
}