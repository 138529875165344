import React from 'react'
import { Doughnut } from 'react-chartjs-2';

const HealthPercent = ({ systemHealthDevicesOnlineCount, systemHealthDevicesOfflineCount, onlinePercent, isDarkTheme }) => {
    const data = {
        labels: ['Online', 'Offline'],
        datasets: [
            {
                label: 'Values',
                data: [systemHealthDevicesOnlineCount, systemHealthDevicesOfflineCount],
                borderRadius: 0,
                backgroundColor: ['#1ab243', '#dddddd'],
                borderWidth: 0,
                //hoverOffset: 4
            }
        ],
    }
    const options = {
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false
            }
        },
        cutout: "83%",
    }
    let plugins = [{
        beforeDraw: function (chart) {
            var width = chart.width,
                height = chart.height,
                ctx = chart.ctx;
            ctx.restore();
            ctx.font = "bolder 28px sans-serif";       
            ctx.textBaseline = "middle";
            var text = `${onlinePercent}%`,
                textX = Math.round((width - ctx.measureText(text).width) / 2),
                textY = height / 2;
            ctx.fillStyle = isDarkTheme ? '' : '#BABABA';
            ctx.fillText(text, textX, textY);
            ctx.save();
        }
    }]

    return (
        <Doughnut key={`${onlinePercent}_${isDarkTheme}`} data={data} options={options} plugins={plugins} />
    )
}

export default HealthPercent