import React from "react"
import Avatar from "react-avatar"

export default function UserListComponent(props) {
    return (
        <div className="ActivityView">
            <div className="HeaderSiteList">
                <button className="SettingsButton">
                    <span className="SiteButtonText">Settings</span>
                </button>
            </div>
            <div className="AlertUserTabs">
                <p className="SelectedAlertTabs">Users ({props.Userscount})</p>
            </div>
            <div className="MainUserList" >
                {
                    props.UsersList &&
                    props.UsersList.length > 0 &&
                    (props.UsersList.map((item) => (
                        <div className="UserViewDiv" key={item.UserID }>
                            <Avatar
                                name={item.FullName}
                                // name={`${item?.FirstName || item?.fullName} ${item.LastName}`}
                                round={true}
                                size={25}
                            // style={{ margin: "0 auto" }}
                            />
                            <p className="UserNumberText">{item.FirstName || item.FullName} - <span style={{color:"#c1c4c4"}}>{item.RoleName || item.roleName}</span></p>
                        </div>
                    )))
                }
            </div>
        </div>
    )
}