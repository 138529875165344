import React, { useState, useEffect } from 'react'
import { getAlertActivityReport, getAlertArmDisarmReport } from '../../services/reportService'
import AlertActivityReport from './AlertActivityReport'
import AlertArmDisarmReport from './AlertArmDisarmReport'
import { Search, NewSearchIcon } from '../../assets/images'
import DatePicker from "react-datepicker"
import { frontDateConverter, onlyDateConverter } from '../../utils/utils'

export default function AlertReportsContainer() {
    let userid = localStorage.getItem('UserID')

    const [alertActivityReport, setAlertActivityReport] = useState(null)
    const [filteredActivityReportLst, setFilteredActivityReportLst] = useState([])
    const [alertArmDisarmReport, setAlertArmDisarmReport] = useState(null)
    const [filteredArmDisarmReportLst, setFilteredArmDisarmReportLst] = useState(null)
    const [reportCategory, setReportCategory] = useState(1)
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    //#region Consume api's S
    const getAlertActivityRepo = async (aReportObj) => {
        await getAlertActivityReport(aReportObj).then((acList) => {
            if (acList && acList.status == "Ok") {
                setAlertActivityReport(acList.message)
                setFilteredActivityReportLst(acList.message)
            }
        })
    }

    const getAlertArmDisarmRepo = async (adReportObj) => {
        await getAlertArmDisarmReport(adReportObj).then((adList) => {
            if (adList && adList.status == "Ok") {
                setAlertArmDisarmReport(adList.message)
                setFilteredArmDisarmReportLst(adList.message)
            }
        })
    }
    //#endregion Consume Api's E

    //#region use effects

    // useEffect(async () => {
    //     let aReportObj = {
    //         "action": "alertActivityReport",
    //         "userId": userid
    //     }
    //     await getAlertActivityRepo(aReportObj)

    //     let adReportObj = {
    //         "action": "armDisarmReport",
    //         "userId": userid
    //     }
    //     await getAlertArmDisarmRepo(adReportObj)
    // }, [])

    useEffect(() => {
        console.log("Reports Testing",userid);
        (async () => {
            let aReportObj = {
                "action": "alertActivityReport",
                "userId": userid
            }
            await getAlertActivityRepo(aReportObj)

            let adReportObj = {
                "action": "armDisarmReport",
                "userId": userid
            }
            await getAlertArmDisarmRepo(adReportObj)
        })();

    }, [])
    //#endregion

    //#region methods S

    function onSearchChange(e) {
        var searchText = e.target.value
        if (searchText) {
            let lowerCaseSearchText = searchText.toLowerCase();
            if (reportCategory == 1) {
                //alert activity report
                var filteredLstBySearch = alertActivityReport.filter(x =>
                    (x.operatorName || "").toLowerCase().includes(lowerCaseSearchText) ||
                    (x.siteName || "").toLowerCase().includes(lowerCaseSearchText) ||
                    (x.severityTitle || "").toLowerCase().includes(lowerCaseSearchText) ||
                    (x.alertType || "").toLowerCase().includes(lowerCaseSearchText) ||
                    (x.alertId || "").toLowerCase().includes(lowerCaseSearchText))
                setFilteredActivityReportLst(filteredLstBySearch)
            }
            else if (reportCategory == 2) { //Alert arm disarm report
                var filteredLstBySearch = alertArmDisarmReport.filter(x => x.panelName.toLowerCase().includes(lowerCaseSearchText) ||
                    x.siteName.toLowerCase().includes(lowerCaseSearchText) ||
                    x.alertType.toLowerCase().includes(lowerCaseSearchText) ||
                    x.branch.toLowerCase().includes(lowerCaseSearchText)
                )
                setFilteredActivityReportLst(filteredLstBySearch)
            }
        }
        else {
            setDefaultList()
        }
    }

    function handleDatepickerChange(e) {
        var fromDate = null
        var toDate = null
        if (e) {
            if (e[0]) {
                fromDate = onlyDateConverter(getDateToFilter(e[0]));
                    if (e[1]) {
                    toDate = onlyDateConverter(getDateToFilter(e[1]));
                }
            }
        }
 
        if (fromDate && toDate) {
            if (reportCategory == 1) { //alert activity report
                var newaList = alertActivityReport.filter(x => x.actualTime.split(' ')[0] >= fromDate && x.actualTime.split(' ')[0] <= toDate)
                setFilteredActivityReportLst(newaList)
            }
            else if (reportCategory == 2) { //Alert arm disarm report
                var newadList = alertArmDisarmReport.filter(x => x.actualTime.split(' ')[0] >= fromDate && x.actualTime.split(' ')[0] <= toDate)
                setFilteredArmDisarmReportLst(newadList)
            }
        }
        else {
            setDefaultList()
        }
    }

    function setDefaultList() {
        if (reportCategory == 1) {
            setFilteredActivityReportLst(alertActivityReport)
        }
        else if (reportCategory == 2) { //Alert arm disarm report
            setFilteredArmDisarmReportLst(alertArmDisarmReport)
        }
    }

    //Tried with db to get required date format. having issue in lambda. Datepicker and db date time formats are diff, so need to convert in req format
    function getDateToFilter(date) {
        var day = ('0' + date.getDate()).slice(-2)
        var month = date.getMonth() + 1
        var year = date.getFullYear()
        var dateToReturn = year + '-' + ('0' + month).slice(-2) + '-' + day
        return dateToReturn
    }
    
    //#region methods E

    return (
        <div className='MainContentMargin'>
            <div className="row col-lg-12" style={{ margin: "0 auto", padding: "0px" }}>
                <div className="col-lg-12 dashboardHeadingInnerDiv">
                    <div className="NameHeading1">
                        <p>Reports &gt; Alert Report</p>
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-end reports-dateRange-and-searchBox"> */}
                    <div className="reports-dateRange-and-searchBox">

                        {/* <div className="SearchTextDiv mt-0 ReportSearchBox reportSearchDiv">
                            <img className="EyeIcon" src={Search} alt='search icon' />
                            <input className="SearchViewInput placeholderAlert" type="search" onChange={e => onSearchChange(e)} placeholder="Alert Id, Site Name, Operator Name, Severity." />
                        </div> */}

                        {/* <div className="inputSearchContainer w25dot75rem"> */}
                            <div className="inputSearchLabelContainer">
                                        <input
                                        className="inputTypeSearch"
                                        type="search" onChange={e => onSearchChange(e)} 
                                        placeholder="Alert Id, Site Name, Operator Name, Severity."
                                        />

                                        <div className="SearchIcon changeColor">
                                        <NewSearchIcon
                                        //     onClick={() => {
                                        //     if (!isSearch)
                                        //         ErrorToast('Please enter the name of the camera, location, or site.')
                                        // }}
                                    />
                                </div>
                            </div>
                        {/* </div> */}

                        <div className="CalenderDiv mt-0">
                            <DatePicker
                                placeholderText="Date range"
                                format="dd-MM-yyyy"
                                className="SearchViewInput cursor-ptr"
                                selectsRange={true}
                                startDate={startDate}
                                endDate={endDate}
                                onChange={(update) => {

                                    handleDatepickerChange(update)
                                    setDateRange(update);
                                }}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }}
                                isClearable={true}
                                maxDate={new Date()} 
                                showDisabledMonthNavigation 
                             
                            />
                        </div>

                    </div>
                </div>
                <div className="col-lg-12 report-container-height AlertActivityReportTable">
                    {reportCategory && reportCategory == 1 &&
                        <AlertActivityReport
                            title="Alert Activity Report"
                            list={filteredActivityReportLst}
                        />}
                    {reportCategory && reportCategory == 2 &&
                        <AlertArmDisarmReport
                            title="Alert Arm/Disarm Report"
                            list={filteredArmDisarmReportLst}
                        />}
                </div>
            </div>
        </div>
    )

}


