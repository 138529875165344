import React, { useEffect, useState } from "react"
// import ReactDOM from 'react-dom'
import UserListComponent from "./UserListComponent"
import UserProfileDataComponent from "./UserProfileComponent"
import { mixpanel } from '../../utils/MixPanelUtil'
import { useUsers } from "../../CustomHooks/useUsers"
import { useProfile } from "../../CustomHooks/useProfile"

export default function ProfileContainer({isDarkTheme}) {
    let LoggedInUser = JSON.parse(localStorage.getItem('user'))
    let initialvalue = {
        "FirstName": "",
        "Email": "",
        "UserID": "",
        "RoleName": ""
    }
    const [profileData, setProfileData] = useState(initialvalue)
    const [showComponent, setShowComponent] = useState(false);
    const [userRole, setUserRole] = useState("")
    const { users } = useUsers();
    const { profile, getprofiledata } = useProfile();
    useEffect(() => {
        try {
            mixpanel.time_event("Session of My Account Page")
        } catch (error) {
            console.error(error)
        }

        return () => {
            mixpanel.track("Session of My Account Page")
        }
    }, [])

    useEffect(() => {
        // getUsers()
        // getprofiledata()
        setProfileData(profile);
    }, [profile])

    useEffect(() => {
        setShowComponent(true)
    }, [users])

    useEffect(() => {
        // var role = ""
        if (profileData?.UserRoleID == 1 || profileData?.roleId == 1) {
            setUserRole("Root")
        } else if (profileData?.UserRoleID == 2 || profileData?.roleId == 2) {
            setUserRole("Admin")
        } else if (profileData?.UserRoleID == 3 || profileData?.roleId == 3) {
            setUserRole("Operator")
        }
        setShowComponent(true);
    }, [profileData])

    return (
        <>


            <div className="row" style={{ margin: "0 auto" }}>
                <div className="col-lg-12 pr-0 mt-2 mb-2">
                    <div className="NameHeading1">
                        <p>
                            Profile
                        </p>
                    </div>
                </div>
                <div className="row col-lg-12 pl-4 pr-4" style={{ margin: "0 auto" }}>
                    <div className="MainUserDetailsView" id="Userprofilediv">
                        {showComponent && <UserProfileDataComponent ProfileData={profileData} UserRole={userRole} isDarkTheme={isDarkTheme} />}
                    </div>
                    <div className="MainUserActivity" id="Userlistdiv">
                    {showComponent && <UserListComponent UsersList={users} Userscount={users.length} />}
                    </div>
                </div>
            </div>
        </>
    )
}