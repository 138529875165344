import React from 'react'
import { Modal } from 'react-bootstrap'
import ReactFlow, { ReactFlowProvider } from 'react-flow-renderer'

export default function WFPreview({
    show,
    onClose,
    workflowName,
    elements,
    nodesDraggable
}) {
    return (
        <Modal size="xl" show={show} onHide={onClose} backdrop="static" className='ViewWorkFlowModel'>
            <Modal.Body>
                <ReactFlowProvider>
                    <Modal.Header closeButton style={{ borderBottom: "none" }}>{workflowName}</Modal.Header>
                    <ReactFlow
                        className='workflowPreview'
                        elements={elements}
                        nodesDraggable={nodesDraggable}>
                    </ReactFlow>
                </ReactFlowProvider>
            </Modal.Body>
        </Modal>
    )
}
