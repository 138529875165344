import React from 'react'
import { Col, Form, Row} from 'react-bootstrap'
import { useForm } from 'react-hook-form'
// import './Signup.css'

export default function Step1Form({ initialValues, OnSubmit }) {
    let { register, handleSubmit } = useForm({
        defaultValues: initialValues
    });

    const onSubmit = data => {
        OnSubmit(data)
    };

    return (
        <>
            <div className="signUpForm">
                <Form >
                    <Row>
                        <Col>
                            <div className="form-group">
                                <label htmlFor="first-name">First Name</label>
                                <input
                                    id="first-name"
                                    type="text"
                                    className="form-control formField"
                                    {...register("FirstName", { required: true })}
                                    defaultValue={initialValues.FirstName}
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="form-group">
                            <label htmlFor="last-name">Last Name</label>
                                <input
                                    id="last-name"
                                    type="text"
                                    className="form-control formField"
                                    {...register("LastName", { required: true })}
                                    defaultValue={initialValues.LastName}
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="form-group">
                        <label htmlFor="phone">Enter phone number</label>
                        <input
                            id="phone"
                            type="Number"
                            className="form-control formField"
                            {...register("Phone1")}
                            defaultValue={initialValues.Phone1} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="postalcode">Postal code</label>
                        <input
                            id="postalcode"
                            type="Number"
                            className="form-control formField"
                            {...register("Zipcode")}
                            defaultValue={initialValues.Zipcode} />
                    </div>

                    <div className="form-group">
                        <label htmlFor="city">City</label>
                        <input
                            id="city"
                            type="text"
                            className="form-control formField"
                            {...register("City")}
                            defaultValue={initialValues.City}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                            id="address"
                            type="text"
                            className="form-control formField"
                            {...register("Address")}
                            defaultValue={initialValues.Address}
                        />
                    </div>
                </Form>

                <button onClick={handleSubmit(onSubmit)} className="signUpBtn" >Continue Step 1 of 3</button>
            </div>
        </>
    )
}