import React, { useState, useEffect, useRef } from 'react'
import { ArrowDown, ArrowUp, AuditLog, DeleteWhite, DropArrow, InfoOrange, SearchIcon, WarningDelete } from "../../assets/images";
import { Accordion,Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DatePicker, DateRangePicker } from "rsuite";
import AlertTemplateMessage from './AlertTemplateMessage';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import CustomScheduler from './CustomScheduler';
import { ConvertDate, onlyDateConverter } from '../../utils/utils';
import { WarningToast } from '../../CustomHooks/WarningToast';
import { eachDayOfInterval } from "date-fns";
import { MultiSelect } from "react-multi-select-component";
import ActivityCustomScheduler from './ActivityCustomScheduler';

const Scheduler = ({isAlertTemplateEdit, selectedItem, setSelectedItem, workflows, severities, customData, setCustomData, sevenDaysData, setSevenDaysData, gettingData, setGettingData ,selectedSiteTab,setSelectedSiteTab,dateCustom, setDateCustom,activityLogShow, setActivityLogShow, alertTemplateObj, isStore }) => {
    console.log("isStoreisStore", isStore)
    const [activeDay, setActiveDay] = useState("Monday");
    const [customOpen, setCustomeOpen] = useState(false);
    const [RTShow, setRTShow] = useState(false);
    const [RepeatDate, setRepeatDate] = useState(false);
    const [DLShow, setDLShow] = useState(false);
    const [tempCount, setTempCount] = useState(0);
    const [dateToDel, setDateToDel] = useState(null);
    const [displayModalDelete, setDisplayModalDelete] = useState(false);
    const [customMultiOption, setCustomMultiOption] = useState([]);
    const [customMultiOptionFrom, setCustomMultiOptionFrom] = useState([]);
    const [customMultiOptionTo, setCustomMultiOptionTo] = useState([]);
    const [customMultiSelected, setCustomMultiSelected] = useState([]);
    const [repFromDay, setRepFromDay] = useState("")
    const [repFromCustom, setRepFromCustom] = useState("");
    const [customToCheck, setCustomToCheck] = useState(false);
    const [selectCount, setSelectCount] = useState(0);
    const [fromDateSearch, setFromDateSearch] = useState("");
    const [endDateSearch, setEndDateSearch] = useState("");
    const handleClose = () => setRTShow(false);
    let sevenDaysRef = useRef([
        {
            "id": 0,
            "day": "mon",
            "dayResource": [],
            "isEnabled": true
        },
        {
            "day": "tue",
            "dayResource": [],
            "isEnabled": true
        },
        {
            "day": "wed",
            "dayResource": [],
            "isEnabled": true
        },
        {
            "day": "thu",
            "dayResource": [],
            "isEnabled": true
        },
        {
            "day": "fri",
            "dayResource": [],
            "isEnabled": true
        },
        {
            "day": "sat",
            "dayResource": [],
            "isEnabled": true
        },
        {
            "day": "sun",
            "dayResource": [],
            "isEnabled": true
        },
    ]);
    const handleRTShow = () => {
        setRTShow(true);  
    }
    const [days, setDays] = useState([
        {id: 1, label: 'Monday', short: 'Mon', checked: false},
        {id: 2, label: 'Tuesday', short: 'Tue', checked: false},
        {id: 3, label: 'Wednesday', short: 'Wed', checked: false},
        {id: 4, label: 'Thursday', short: 'Thu', checked: false},
        {id: 5, label: 'Friday', short: 'Fri', checked: false},
        {id: 6, label: 'Saturday', short: 'Sat', checked: false},
        {id: 7, label: 'Sunday', short: 'Sun', checked: false},
    ]);

    const [repDays, setRepDays] = useState([
        {id: 0, label: 'Monday', short: 'Mon', checked: false},
        {id: 1, label: 'Tuesday', short: 'Tue', checked: false},
        {id: 2, label: 'Wednesday', short: 'Wed', checked: false},
        {id: 3, label: 'Thursday', short: 'Thu', checked: false},
        {id: 4, label: 'Friday', short: 'Fri', checked: false},
        {id: 5, label: 'Saturday', short: 'Sat', checked: false},
        {id: 6, label: 'Sunday', short: 'Sun', checked: false},
    ]);

    const [repCustomDays, setRepCustomDays] = useState([]);

    useEffect(() => {
        console.log("repFromDay", repFromDay)
    }, [repFromDay]);

    useEffect(() => {
        console.log("repFromCustom", repFromCustom)
    }, [repFromCustom]);

    const selectedSchedule = (e) => {
        e.stopPropagation();
        setSelectedItem(e.target.value);
    }

    const searchHandler = (e, type) => {
        let searchText = e.target.value;
        if(type == "FROM"){
            setFromDateSearch(searchText);
            let arr = customMultiOption.filter((item) => item.label.toLowerCase().includes(searchText.toLowerCase()));
            console.log("searchHandler", arr);
            setCustomMultiOptionFrom(arr);
        }else{
            setEndDateSearch(searchText);
            let arr = customMultiOption.filter((item) => item.label.toLowerCase().includes(searchText.toLowerCase()) && item.label != repFromCustom);
            console.log("searchHandler", arr);
            setCustomMultiOptionTo(arr);
            let isChecked = true;
            for(let i=0; i<arr.length; i++){
                if(arr[i].tochecked == true){
                    continue;
                }else if(arr[i].tochecked == false && arr[i].label == repFromCustom){
                    continue;
                }else{
                    isChecked = false;
                    break;
                }
            }
            setCustomToCheck(isChecked);
            setSelectCount(arr.length);
        }
    }

    useEffect(() => {
        console.log("customMultiOption", customMultiOption)
    }, [customMultiOption])

    useEffect(() => {
        console.log("customMultiSelected useEffect")
        let arr = customData.map((item) => ({
            id: item.id,
            value: item.id,
            label: item.day,
            short: item.day,
            fromchecked: false,
            tochecked: false
        }))
        console.log("customMultiSelected useEffect", arr)
        setCustomMultiOption(arr);
        setCustomMultiOptionFrom(arr);
        setCustomMultiOptionTo(arr);
        setSelectCount(arr.length - 1);
    }, [tempCount, customData]);

    const selectDays = (e, id) => {
        let arr = repDays;
        let temp = arr.map((item) => {
            let obj;
            if (item.id == id) {
                if (item.checked) {
                    obj = {
                        ...item,
                        checked: false
                    }
                } else {
                    obj = {
                        ...item,
                        checked: true
                    }
                }
            } else {
                obj = {
                    ...item,
                }
            }
            return obj;
        })
        setRepDays(temp);
    }

    const selectCustomDays = (e, label) => {
        let arr = customMultiOption;
        let temp = arr.map((item) => {
            let obj;
            if (item.label == label) {
                if (item.tochecked) {
                    obj = {
                        ...item,
                        tochecked: false
                    }
                } else {
                    obj = {
                        ...item,
                        tochecked: true
                    }
                }
            } else {
                obj = {
                    ...item,
                }
            }
            return obj;
        })

        let tempArr = customMultiOptionTo;
        let tempTwo = tempArr.map((item) => {
            let obj;
            if (item.label == label) {
                if (item.tochecked) {
                    obj = {
                        ...item,
                        tochecked: false
                    }
                } else {
                    obj = {
                        ...item,
                        tochecked: true
                    }
                }
            } else {
                obj = {
                    ...item,
                }
            }
            return obj;
        })
        console.log("setCustomMultiOption", temp)
        setCustomMultiOption(temp);
        setCustomMultiOptionTo(tempTwo);
    }

    const selectCustomDaysFrom = (e, label) => {
        // e.stopPropagation();
        let arr = customMultiOption;
        let isChecked;
        let temp = arr.map((item) => {
            let obj;
            if (item.label == label) {
                if (item.fromchecked) {
                    obj = {
                        ...item,
                        fromchecked: false,
                        tochecked: false
                    }
                    isChecked = false;
                } else {
                    obj = {
                        ...item,
                        fromchecked: true,
                        tochecked: false
                    }
                    isChecked = true;
                }
            } else {
                obj = {
                    ...item,
                    tochecked: false
                }
            }
            return obj;
        })

        let arrTwo = customMultiOptionFrom;
        let isCheckedTwo;
        let tempTwo = arrTwo.map((item) => {
            let obj;
            if (item.label == label) {
                if (item.fromchecked) {
                    obj = {
                        ...item,
                        fromchecked: false
                    }
                    isCheckedTwo = false;
                } else {
                    obj = {
                        ...item,
                        fromchecked: true
                    }
                    isCheckedTwo = true;
                }
            } else {
                obj = {
                    ...item,
                }
            }
            return obj;
        })
        console.log("setCustomMultiOption", label)
        let tempLabel = ""
        let arrThree = [];
        if(isChecked){
            tempLabel = label;
            setRepFromCustom(label);
            let arrThree = customMultiOption.filter((item) => item.label != tempLabel);
            console.log("setCustomMultiOption", arrThree)
            setCustomMultiOptionTo(arrThree)
        }else{
            tempLabel = ""
            setRepFromCustom("");
            let arrThree = customMultiOption.filter((item) => item.label != tempLabel).map((i) => {
                return {
                    ...i,
                    tochecked: false
                }
            });
            console.log("setCustomMultiOption", arrThree)
            setCustomToCheck(false);
            setCustomMultiOptionTo(arrThree)
        }
        setCustomMultiOption(temp);
        setCustomMultiOptionFrom(tempTwo)

        if(isChecked){
            setRepFromCustom(label);
        }else{
            setRepFromCustom("");
        }
    }

    const changeCustomToCheck = (e) => {
        let sample = customMultiOption.map((item) => {
            let obj;
            if(customToCheck){
                if(item.label == repFromCustom || !item.label.toLowerCase().includes(endDateSearch.toLowerCase())){
                    obj = {
                        ...item
                    }
                }else{
                    obj = {
                        ...item,
                        tochecked: false
                    }
                }
            }else{
                if(item.label == repFromCustom || !item.label.toLowerCase().includes(endDateSearch.toLowerCase())){
                    obj = {
                        ...item,
                    }
                }else{
                    obj = {
                        ...item,
                        tochecked: true
                    }
                }
            }
            return obj;
        })

        let sampleOne = customMultiOptionTo.map((item) => {
            let obj;
            if(customToCheck){
                if(item.label == repFromCustom){
                    obj = {
                        ...item
                    }
                }else{
                    obj = {
                        ...item,
                        tochecked: false
                    }
                }
            }else{
                if(item.label == repFromCustom){
                    obj = {
                        ...item,
                    }
                }else{
                    obj = {
                        ...item,
                        tochecked: true
                    }
                }
            }
            return obj;
        })
        setCustomToCheck(!customToCheck);
        setCustomMultiOption(sample);
        setCustomMultiOptionTo(sampleOne);
    }

    const replicateDayTimings = (e) => {
        e.preventDefault();
        if (repFromDay == "") {
            WarningToast("Please select a from day");
            return;
        }
        console.log("replicateDayTimings");
        let pasteIds = repDays.filter((item) => item.checked).map((i) => i.id);
        console.log("replicateDayTimings", pasteIds);
        if (pasteIds.length == 0) {
            WarningToast("Please select a day to which you want to replicate the schedule");
            return;
        }
        copyScheduleRepeatMultiple(repFromDay, pasteIds);
    }

    const replicateCustomTimings = (e) => {
        e.preventDefault();
        if (repFromCustom == "") {
            WarningToast("Please select a from date");
            return;
        }
        console.log("replicateCustomTimings", customMultiOption);
        let pasteIds = customMultiOption.filter((item) => item.tochecked).map((i) => i.label);
        console.log("replicateCustomTimings", pasteIds);
        if (pasteIds.length == 0) {
            WarningToast("Please select a date to which you want to replicate the schedule");
            return;
        }
        copyScheduleCustomMultiple(repFromCustom, pasteIds);
    }

    const changeDay = (e, id) => {
        let arr = days;
        let temp = arr.map((item) => {
            let obj;
            if(item.id == id){
                if(item.checked){
                    obj = {
                        ...item, 
                        checked: false
                    }
                }else{
                    obj = {
                        ...item, 
                        checked: true
                    }
                }
            }else{
                obj = {
                    ...item,
                }
            }
            return obj;
        })
        setDays(temp);
    }

    const enableDay = (e, id) => {
        let arr = sevenDaysData;
        arr[id].isEnabled = !arr[id].isEnabled;
        let c = tempCount + 1;
        setTempCount(c);
        setSevenDaysData(arr);
    }

    const enableCustomDay = (e, day) => {
        let arr = customData;
        let tempArr = arr.map((item) => {
            let obj;
            if(item.day == day){
                obj = {
                    ...item,
                    isEnabled: !item.isEnabled
                }
            }else{
                obj = {
                    ...item
                }
            }
            return obj;
        })
        let c = tempCount + 1;
        setTempCount(c);
        setCustomData(tempArr);
    }

    const customDateAdded = (e) => {
        if (e == null) {
            setDateCustom(null);
            return;
        }
        let con = ConvertDate(new Date(e));
        console.log("SELECTEDDATE", con);
        console.log("SELECTEDDATE", onlyDateConverter(con.split(" ")[0]))
        let properDate = onlyDateConverter(con.split(" ")[0])
        let arr = customData;
        if (customData.some((item) => item.day == properDate)) {
            WarningToast("Date already present!!")
            return;
        }
        let index = customData.length;
        let obj = {
            "id": index,
            "day": properDate,
            "dayResource": [],
            "isEnabled": false
        }
        arr.push(obj);
        // setDateCustom(null);
        setDateCustom(e);
        let c = tempCount + 1;
        setTempCount(c);
        console.log("REVERSE data object", arr);
        setCustomData(arr);
    }

    const getDatesInRange = (startDate, endDate) =>  {
        return eachDayOfInterval({
            start: new Date(startDate),
            end: new Date(endDate)
        })
    } 

    const customRangeDateAdded = (e) => {
        console.log("customRangeDateAdded", e)
        let arr = getDatesInRange(e[0], e[1]);
        console.log("customRangeDateAdded", arr)
        let tempArr = customData;
        for(let i=0; i<arr.length; i++){
            let con = ConvertDate(new Date(arr[i]));
            let d = con.split(" ")[0];
            let properDate = onlyDateConverter(con.split(" ")[0])
            if(customData.some((item) => item.day == properDate)){
                WarningToast("One of the date is already present")
                return;    
            }
            let index = tempArr.length;
            let obj = {
                "id": index,
                "day" : properDate,
                "dayResource": [],
                "isEnabled": false
            }
            tempArr.push(obj);        
        }
        let c = tempCount + 1;
        setTempCount(c);
        setCustomData(tempArr);
    }

    const copyScheduleRepeatSingle = (copyId, pasteId) => {
        let copyArr = sevenDaysData.filter((item) => item.id == copyId);
        const modifiedArr = sevenDaysData.map(item => {
            if(item.id == pasteId){
                return { ...item, dayResource: copyArr[0].dayResource }
            }
            return item;
        })
        setSevenDaysData(modifiedArr);
    }

    const copyScheduleCustomSingle = (copyId, pasteId) => {
        let copyArr = sevenDaysData.filter((item) => item.id == copyId);
        const modifiedArr = customData.map(item => {
            if(item.id == pasteId){
                return { ...item, dayResource: copyArr[0].dayResource }
            }
            return item;
        })
        setCustomData(modifiedArr);
    }

    const copyScheduleRepeatMultiple = (copyId, pasteIds) => {
        let copyArr = sevenDaysData.filter((item) => item.id == copyId);
        console.log("replicateDayTimings", copyArr);
        if(copyArr[0].dayResource.length == 0){
            WarningToast('The schedule from which you are replicating is empty.')
            return;
        }
        let modifiedArr = sevenDaysData;
        for(let i = 0; i < pasteIds.length; i++){
            modifiedArr = modifiedArr.map(item => {
                if(item.id == pasteIds[i]){
                    return { ...item, dayResource: copyArr[0].dayResource }
                }
                return item;
            })
        }
        console.log("replicateDayTimings", modifiedArr);
        setSevenDaysData(modifiedArr);
        setRTShow(false);
        setRepFromDay("");
        setRepDays([
            {id: 0, label: 'Monday', short: 'Mon', checked: false},
            {id: 1, label: 'Tuesday', short: 'Tue', checked: false},
            {id: 2, label: 'Wednesday', short: 'Wed', checked: false},
            {id: 3, label: 'Thursday', short: 'Thu', checked: false},
            {id: 4, label: 'Friday', short: 'Fri', checked: false},
            {id: 5, label: 'Saturday', short: 'Sat', checked: false},
            {id: 6, label: 'Sunday', short: 'Sun', checked: false},
        ])
    }

    const copyScheduleCustomMultiple = (copyId, pasteIds) => {
        let copyArr = customData.filter((item) => item.day == copyId);
        if(copyArr[0].dayResource.length == 0){
            WarningToast('The schedule from which you are replicating is empty.')
            return;
        }
        let modifiedArr = customData;
        for(let i = 0; i < pasteIds.length; i++){
            modifiedArr = modifiedArr.map(item => {
                if(item.day == pasteIds[i]){
                    return { ...item, dayResource: copyArr[0].dayResource }
                }
                return item;
            })
        }
        setCustomData(modifiedArr);
        setRepeatDate(false);
        setRepFromCustom("");
        let sample = customMultiOption.map((item) => {
            let obj = {
                ...item,
                tochecked: false,
                fromchecked: false
            }
            return obj
        });
        setCustomMultiOption(sample);
        setCustomMultiOptionFrom(sample);
        setCustomMultiOptionTo(sample);
        setCustomToCheck(false);
        setFromDateSearch("");
        setEndDateSearch("");
    }

    const onSpecialChar = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const isValid = new RegExp("^[0-9.\\- ]*$").test(keyValue);
        if (!isValid) {
          event.preventDefault();
          return;
        }
    }

    const resetRepeatSchedule = (e) => {
        e.preventDefault();
        setSevenDaysData([
            {
                "id": 0,
                "day": "mon",
                "dayResource": [],
                "isEnabled": false
            },
            {
                "id": 1,
                "day": "tue",
                "dayResource": [],
                "isEnabled": false
            },
            {
                "id": 2,
                "day": "wed",
                "dayResource": [],
                "isEnabled": false
            },
            {
                "id": 3,
                "day": "thu",
                "dayResource": [],
                "isEnabled": false
            },
            {
                "id": 4,
                "day": "fri",
                "dayResource": [],
                "isEnabled": false
            },
            {
                "id": 5,
                "day": "sat",
                "dayResource": [],
                "isEnabled": false
            },
            {
                "id": 6,
                "day": "sun",
                "dayResource": [],
                "isEnabled": false
            },
        ])
    }

    const resetCustomSchedule = (e) => {
        e.preventDefault();
        setDateCustom(null);
        setCustomData([]);
    }

    const resetBothSchedule = () => {
        resetCustomSchedule();
        resetRepeatSchedule();
    }

    const removeCustomDate = (e, id) => {
        e.preventDefault();
        let arr = customData.filter((item) => item.id != id);
        setCustomData(arr);
        setDisplayModalDelete(false);
    }

    const removeCustomDates = (e) => {
        e.preventDefault();
        if(customMultiSelected.length == 0){
            WarningToast('Please select a date that you want to delete.')
            return;
        }
        let tempArr = customMultiSelected.map((item) => item.value);
        let arr = customData.filter((item) => !tempArr.includes(item.id));
        setCustomData(arr);
        setDLShow(false);
        setCustomMultiSelected([]);
    }

    return (
        <div className="grid-container Notificationgrid-container grid-container--fit">
            <div 
            className="NotificationTextBox"
            >
                <p
                    className="SiteNameText"
                >
                    Scheduler*
                </p>
                <div className="NotificationEmailMsgBox" style={{ height: "auto", minHeight: "190px" }}>
                    <div className="EmailMsgBoxHeading" style={{display: "flex", justifyContent: "space-between"}}>
                        <ul className="nav nav-pills">
                            <li className="EmailNotificationNavItem">
                                <div 
                                className={
                                    selectedSiteTab == "no-alert"
                                        ? "nav-link active bottom-three-sites"
                                        : "inactive-text-dashboard bottom-three-sites"
                                }
                                onClick={(e) => {
                                    console.log("Upper Div Called", e.target.type);
                                    if(e.target.type == "radio"){
                                        return;
                                    }
                                    setSelectedSiteTab("no-alert")
                                }
                                }
                                >
                                    <div className="Notificationcheckwithlabel">
                                        <input
                                            checked={selectedItem === "No Alert"}
                                            onChange={selectedSchedule} 
                                            value="No Alert"
                                            name="schedule_type"
                                            type="radio"
                                            disabled={!isAlertTemplateEdit} 
                                            style={{ left: "0px", position: 'relative' }}
                                        />
                                        <label style={{ margin: "0px" }}>No Alert</label>
                                    </div>
                                </div>
                            </li>
                            <li className="EmailNotificationNavItem" style={{display:"none"}}>
                                <div 
                                className={
                                    selectedSiteTab == "24*7"
                                        ? "nav-link active bottom-three-sites"
                                        : "inactive-text-dashboard bottom-three-sites"
                                }
                                onClick={(e) => {
                                    console.log("Upper Div Called", e.target.type);
                                    if(e.target.type == "radio"){
                                        return;
                                    }
                                    setSelectedSiteTab("24*7")
                                }}
                                >
                                    <div className="Notificationcheckwithlabel">
                                        <input
                                            // checked={realTimeEnabled}
                                            // onChange={(e) => setRealTimeEnabled(!realTimeEnabled)} 
                                            checked={selectedItem === "24*7"}
                                            onChange={selectedSchedule}
                                            value="24*7"
                                            name="schedule_type"
                                            type="radio"
                                            disabled={!isAlertTemplateEdit} 
                                            style={{ left: "0px", position: 'relative' }}
                                        />
                                        <label style={{ margin: "0px" }}>24*7</label>
                                    </div>
                                </div>
                            </li>
                            <li className="EmailNotificationNavItem">
                                <div 
                                className={
                                    selectedSiteTab == "custom"
                                        ? "nav-link active bottom-three-sites"
                                        : "inactive-text-dashboard bottom-three-sites"
                                }
                                onClick={(e) => {
                                    console.log("Upper Div Called", e.target.type);
                                    if(e.target.type == "radio"){
                                        return;
                                    }
                                    setSelectedSiteTab("custom")
                                }}
                                >
                                    <div className="Notificationcheckwithlabel">
                                        <input
                                            // checked={realTimeEnabled}
                                            // onChange={(e) => setRealTimeEnabled(!realTimeEnabled)}
                                            checked={selectedItem === "Custom"} 
                                            onChange={selectedSchedule}
                                            value="Custom"
                                            name="schedule_type"
                                            type="radio"
                                            disabled={!isAlertTemplateEdit} 
                                            style={{ left: "0px", position: 'relative' }}
                                        />
                                        <label style={{ margin: "0px" }}>Custom</label>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        {
                            alertTemplateObj.templateId == ""
                            ?
                            null
                            :
                            <div>
                            <OverlayTrigger
                                placement='bottom'
                                overlay={<Tooltip>Schedular Log</Tooltip>}
                            >
                            <button className="DataAccessEdit"
                                                                    // disabled={!isAlertTemplateEdit}
                                                                    //     onClick={(e) => {
                                                                    //         e.preventDefault();
                                                                    //         setDLShow(true)
                                                                    //     }}
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setActivityLogShow(true);
                                                                    }}
                                                                    >
                                                                        <img src={AuditLog} className="EditedIcon" style={{ height: "16px" }} alt='Audit log white icon' />
                                                                    </button>
                            </OverlayTrigger>
                            </div>
                        }

                    </div>
                    <div className="tab-content" style={{padding: "10px"}}>
                        {selectedSiteTab && selectedSiteTab == "no-alert" && (
                            <>
                                    <AlertTemplateMessage message={"No Alert will be generated"}/>
                            </>
                        )}
                        {selectedSiteTab && selectedSiteTab == "24*7" && (
                            <>
                                <AlertTemplateMessage message={"Alerts will be generated 24x7"}/>
                            </>
                        )}
                        {selectedSiteTab && selectedSiteTab == "custom" && (
                            <>
                                <div className="startDiv" style={{ padding: "10px" }}>

                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Accordion.Toggle
                                                as={Card.Header}
                                                eventKey="1" className='NotificationCardAccordion'
                                                onClick={() => setCustomeOpen(!customOpen)}
                                            >
                                                <p>Repeat</p>
                                                {
                                                    !customOpen ?
                                                        <img src={ArrowDown} alt="dropdown-arrow" /> :
                                                        <img src={ArrowUp} alt='up arrow' />
                                                }
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body style={{ padding: "10px" }}>
                                                    <div className="EmailMsgBoxHeading" style={{ borderBottom: "none" , display:"none"}}>
                                                        <ul className="nav nav-pills">
                                                            {
                                                                days.map((item) => (
                                                                    <li className="EmailNotificationNavItem">
                                                                    <a className={
                                                                        activeDay == item.label
                                                                            ? "nav-link active bottom-three-sites"
                                                                            : "inactive-text-dashboard bottom-three-sites"
                                                                    }
                                                                        onClick={() => setActiveDay(item.label)}
                                                                    >
                                                                        <div className="Notificationcheckwithlabel">
                                                                            <input
                                                                                checked={item.checked}
                                                                                onChange={(e) => changeDay(e, item.id)} 
                                                                                type="checkbox"
                                                                                disabled={!isAlertTemplateEdit} 
                                                                                style={{ marginRight: "3px" }}
                                                                            />
                                                                            <label style={{ margin: "0px" }}>{item.short}</label>
                                                                        </div>
                                                                    </a>
                                                                </li>
                                                                ))
                                                            }
                                                            {/* <li className="EmailNotificationNavItem">
                                                                <a className={
                                                                    activeDay == "monday"
                                                                        ? "nav-link active bottom-three-sites"
                                                                        : "inactive-text-dashboard bottom-three-sites"
                                                                }
                                                                    onClick={() => setActiveDay("monday")}
                                                                >
                                                                    <div className="Notificationcheckwithlabel">
                                                                        <input
                                                                            // checked={realTimeEnabled}
                                                                            // onChange={(e) => setRealTimeEnabled(!realTimeEnabled)} 
                                                                            type="checkbox"
                                                                            // disabled={!isNotifFormEnabled} 
                                                                            style={{ marginRight: "3px" }}
                                                                        />
                                                                        <label style={{ margin: "0px" }}>Mon</label>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li className="EmailNotificationNavItem">
                                                                <a className={
                                                                    activeDay == "tuesday"
                                                                        ? "nav-link active bottom-three-sites"
                                                                        : "inactive-text-dashboard bottom-three-sites"
                                                                }
                                                                    onClick={() => setActiveDay("tuesday")}
                                                                >
                                                                    <div className="Notificationcheckwithlabel">
                                                                        <input
                                                                            // checked={realTimeEnabled}
                                                                            // onChange={(e) => setRealTimeEnabled(!realTimeEnabled)} 
                                                                            type="checkbox"
                                                                            // disabled={!isNotifFormEnabled} 
                                                                            style={{ marginRight: "3px" }}
                                                                        />
                                                                        <label style={{ margin: "0px" }}>Tue</label>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li className="EmailNotificationNavItem">
                                                                <a className={
                                                                    activeDay == "wednesday"
                                                                        ? "nav-link active bottom-three-sites"
                                                                        : "inactive-text-dashboard bottom-three-sites"
                                                                }
                                                                    onClick={() => setActiveDay("wednesday")}
                                                                >
                                                                    <div className="Notificationcheckwithlabel">
                                                                        <input
                                                                            // checked={realTimeEnabled}
                                                                            // onChange={(e) => setRealTimeEnabled(!realTimeEnabled)} 
                                                                            type="checkbox"
                                                                            // disabled={!isNotifFormEnabled} 
                                                                            style={{ marginRight: "3px" }}
                                                                        />
                                                                        <label style={{ margin: "0px" }}>Web</label>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li className="EmailNotificationNavItem">
                                                                <a className={
                                                                    activeDay == "thursday"
                                                                        ? "nav-link active bottom-three-sites"
                                                                        : "inactive-text-dashboard bottom-three-sites"
                                                                }
                                                                    onClick={() => setActiveDay("thursday")}
                                                                >
                                                                    <div className="Notificationcheckwithlabel">
                                                                        <input
                                                                            // checked={realTimeEnabled}
                                                                            // onChange={(e) => setRealTimeEnabled(!realTimeEnabled)} 
                                                                            type="checkbox"
                                                                            // disabled={!isNotifFormEnabled} 
                                                                            style={{ marginRight: "3px" }}
                                                                        />
                                                                        <label style={{ margin: "0px" }}>Thu</label>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li className="EmailNotificationNavItem">
                                                                <a className={
                                                                    activeDay == "friday"
                                                                        ? "nav-link active bottom-three-sites"
                                                                        : "inactive-text-dashboard bottom-three-sites"
                                                                }
                                                                    onClick={() => setActiveDay("friday")}
                                                                >
                                                                    <div className="Notificationcheckwithlabel">
                                                                        <input
                                                                            // checked={realTimeEnabled}
                                                                            // onChange={(e) => setRealTimeEnabled(!realTimeEnabled)} 
                                                                            type="checkbox"
                                                                            // disabled={!isNotifFormEnabled} 
                                                                            style={{ marginRight: "3px" }}
                                                                        />
                                                                        <label style={{ margin: "0px" }}>Fri</label>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li className="EmailNotificationNavItem">
                                                                <a className={
                                                                    activeDay == "saturday"
                                                                        ? "nav-link active bottom-three-sites"
                                                                        : "inactive-text-dashboard bottom-three-sites"
                                                                }
                                                                    onClick={() => setActiveDay("saturday")}
                                                                >
                                                                    <div className="Notificationcheckwithlabel">
                                                                        <input
                                                                            // checked={realTimeEnabled}
                                                                            // onChange={(e) => setRealTimeEnabled(!realTimeEnabled)} 
                                                                            type="checkbox"
                                                                            // disabled={!isNotifFormEnabled} 
                                                                            style={{ marginRight: "3px" }}
                                                                        />
                                                                        <label style={{ margin: "0px" }}>Sat</label>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                            <li className="EmailNotificationNavItem">
                                                                <a className={
                                                                    activeDay == "sunday"
                                                                        ? "nav-link active bottom-three-sites"
                                                                        : "inactive-text-dashboard bottom-three-sites"
                                                                }
                                                                    onClick={() => setActiveDay("sunday")}
                                                                >
                                                                    <div className="Notificationcheckwithlabel">
                                                                        <input
                                                                            // checked={realTimeEnabled}
                                                                            // onChange={(e) => setRealTimeEnabled(!realTimeEnabled)} 
                                                                            type="checkbox"
                                                                            // disabled={!isNotifFormEnabled} 
                                                                            style={{ marginRight: "3px" }}
                                                                        />
                                                                        <label style={{ margin: "0px" }}>Sun</label>
                                                                    </div>
                                                                </a>
                                                            </li> */}
                                                        </ul>
                                                    </div>
                                                    <div className="HgtCheckBoxList" style={{ paddingRight: "5px", height: "auto", minHeight: "70px" , display:"none"}}>
                                                        <div className="tab-content" >
                                                            {activeDay && activeDay == "Monday" && (
                                                                <>
                                                                    <p>Monday</p>
                                                                </>
                                                            )}
                                                            {activeDay && activeDay == "Tuesday" && (
                                                                <>
                                                                    <p>Tuesday</p>
                                                                </>
                                                            )}
                                                            {activeDay && activeDay == "Wednesday" && (
                                                                <>
                                                                    <p>wednesday</p>
                                                                </>
                                                            )}
                                                            {activeDay && activeDay == "Thursday" && (
                                                                <>
                                                                    <p>thursday</p>
                                                                </>
                                                            )}
                                                            {activeDay && activeDay == "Friday" && (
                                                                <>
                                                                    <p>friday</p>
                                                                </>
                                                            )}
                                                            {activeDay && activeDay == "Saturday" && (
                                                                <>
                                                                    <p>saturday</p>
                                                                </>
                                                            )}
                                                            {activeDay && activeDay == "Sunday" && (
                                                                <>
                                                                    <p>sunday</p>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div style={{ marginBottom: "10px", display: "flex", justifyContent: "flex-end", alignItems: "center"}}>
                                                            <div>
                                                                {
                                                                    !isStore
                                                                    ?
                                                                    <button className="DataAccessEdit buttonDisable" disabled={!isAlertTemplateEdit} style={{ width: "100px" }} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setRTShow(true)
                                                                    }}>
                                                                        <span className="ViewUpdate ml-2">Repeat</span>
                                                                    </button>
                                                                    : null
                                                                }

                                                                {/* <button className="DataAccessEdit">
                                                                    <img src={DeleteWhite} onClick={(e) => {
                                                                        e.preventDefault();
                                                                        setDLShow(true)
                                                                    }} className="EditedIcon" style={{ height: "16px" }} alt='delete white icon' />
                                                                </button> */}
                                                            </div>
                                                        </div>
                                                    {/* scheduler New UI started here */}
                                                    <div className="HgtCheckBoxList" style={{paddingRight: "5px", minHeight: "70px", marginTop:"0px"}}>
                                             

                                                        {/* Mon start here */}
                                                        <div className='DaySliderBox'>
                                                            <div style={{display: "flex"}}>
                                                                <div style={{width: "4rem"}}>
                                                                    <div className='togglecheckbox'>
                                                                        <label>
                                                                        <input type="checkbox" disabled={!isAlertTemplateEdit || isStore} checked={sevenDaysData[0]?.isEnabled} onChange={(e) => {enableDay(e, 0)}} style={{ marginRight: "3px" }} />
                                                                        <span />
                                                                        </label>
                                                                    </div>
                                                                    <label style={{ margin: "0px" }}>Mon</label>
                                                                </div>
                                                                <CustomScheduler isAlertTemplateEdit={isAlertTemplateEdit} sevenDaysRef={sevenDaysRef} resource="mon" sevenDaysData={sevenDaysData} setSevenDaysData={setSevenDaysData} workflows={workflows} severities={severities} gettingData={gettingData} setGettingData={setGettingData} isStore={isStore} />
                                                            </div>
                                                        </div>
                                                        {/* Mon ends here */}

                                                        {/* Tue start here */}
                                                        <div className='DaySliderBox'>
                                                            <div style={{display: "flex"}}>
                                                            <div style={{width: "4rem"}}>
                                                            <div className='togglecheckbox'>
                                                                <label>
                                                                <input type="checkbox" disabled={!isAlertTemplateEdit || isStore} checked={sevenDaysData[1]?.isEnabled} onChange={(e) => {enableDay(e, 1)}} style={{ marginRight: "3px" }}/>
                                                                 <span />   
                                                                </label>
                                                            </div>
                                                                <label style={{ margin: "0px" }}>Tue</label>
                                                            </div>
                                                            {
                                                                <CustomScheduler isAlertTemplateEdit={isAlertTemplateEdit} sevenDaysRef={sevenDaysRef} resource="tue" sevenDaysData={sevenDaysData} setSevenDaysData={setSevenDaysData} workflows={workflows} severities={severities} gettingData={gettingData} setGettingData={setGettingData} isStore={isStore} />
                                                            }
                                                            </div>
                                                        </div>
                                                         {/* Tue ends here */}

                                                        {/* Wed start here */}
                                                        <div className='DaySliderBox'>
                                                            <div style={{display: "flex"}}>
                                                                <div style={{width: "4rem"}}>
                                                                    <div className='togglecheckbox'>
                                                                        <label>
                                                                        <input type="checkbox" disabled={!isAlertTemplateEdit || isStore} checked={sevenDaysData[2]?.isEnabled} onChange={(e) => {enableDay(e, 2)}} style={{ marginRight: "3px" }}/>
                                                                        <span />
                                                                        </label>
                                                                    </div>
                                                                    <label style={{ margin: "0px" }}>Wed</label>
                                                                </div>
                                                                <CustomScheduler isAlertTemplateEdit={isAlertTemplateEdit} resource="wed" sevenDaysData={sevenDaysData} setSevenDaysData={setSevenDaysData}  workflows={workflows} severities={severities} gettingData={gettingData} setGettingData={setGettingData} isStore={isStore} />
                                                            </div>
                                                        </div>
                                                         {/* Wed ends here */}

                                                        {/* Thu start here */}
                                                        <div className='DaySliderBox'>
                                                            <div style={{display: "flex"}}>
                                                            <div style={{width: "4rem"}}>
                                                                <div className='togglecheckbox'>
                                                                    <label>
                                                                    <input type="checkbox" disabled={!isAlertTemplateEdit || isStore} checked={sevenDaysData[3]?.isEnabled} onChange={(e) => {enableDay(e, 3)}} style={{ marginRight: "3px" }}/>
                                                                    <span />
                                                                    </label>
                                                                </div>
                                                                <label style={{ margin: "0px" }}>Thu</label>
                                                            </div>
                                                            <CustomScheduler isAlertTemplateEdit={isAlertTemplateEdit} resource="thu" sevenDaysData={sevenDaysData} setSevenDaysData={setSevenDaysData} workflows={workflows} severities={severities} gettingData={gettingData} setGettingData={setGettingData} isStore={isStore} />
                                                            </div>
                                                        </div>
                                                         {/* Thu ends here */}

                                                        {/* Fri start here */}
                                                        <div className='DaySliderBox'>
                                                            <div style={{display: "flex"}}>
                                                            <div style={{width: "4rem"}}>
                                                                <div className='togglecheckbox'>
                                                                    <label>
                                                                    <input type="checkbox" disabled={!isAlertTemplateEdit || isStore} checked={sevenDaysData[4]?.isEnabled} onChange={(e) => {enableDay(e, 4)}} style={{ marginRight: "3px" }}/>
                                                                    <span />
                                                                    </label>
                                                                </div>
                                                                <label style={{ margin: "0px" }}>Fri</label>
                                                            </div>
                                                            <CustomScheduler isAlertTemplateEdit={isAlertTemplateEdit} resource="fri" sevenDaysData={sevenDaysData} setSevenDaysData={setSevenDaysData} workflows={workflows} severities={severities} gettingData={gettingData} setGettingData={setGettingData} isStore={isStore} />
                                                            </div>
                                                        </div>
                                                        {/* Fri ends here */}

                                                        {/* Sat start here */}
                                                        <div className='DaySliderBox'>
                                                            <div style={{display: "flex"}}>
                                                            <div style={{width: "4rem"}}>
                                                                <div className='togglecheckbox'>
                                                                    <label>
                                                                    <input type="checkbox" disabled={!isAlertTemplateEdit || isStore} checked={sevenDaysData[5]?.isEnabled} onChange={(e) => {enableDay(e, 5)}} style={{ marginRight: "3px" }}/>
                                                                    <span />
                                                                    </label>
                                                                </div>
                                                                <label style={{ margin: "0px" }}>Sat</label>
                                                            </div>
                                                            <CustomScheduler isAlertTemplateEdit={isAlertTemplateEdit} resource="sat" sevenDaysData={sevenDaysData} setSevenDaysData={setSevenDaysData} workflows={workflows} severities={severities} gettingData={gettingData} setGettingData={setGettingData} isStore={isStore} />
                                                            </div>
                                                        </div>
                                                        {/* Sat ends here */}

                                                        {/* Sun start here */}
                                                        <div className='DaySliderBox'>
                                                            <div style={{display: "flex"}}>
                                                            <div style={{width: "4rem"}}>
                                                                <div className='togglecheckbox'>
                                                                    <label>
                                                                    <input type="checkbox" disabled={!isAlertTemplateEdit || isStore} checked={sevenDaysData[6]?.isEnabled} onChange={(e) => {enableDay(e, 6)}} style={{ marginRight: "3px" }}/>
                                                                    <span />
                                                                    </label>
                                                                </div>
                                                                <label style={{ margin: "0px" }}>Sun</label>
                                                            </div>
                                                            <CustomScheduler isAlertTemplateEdit={isAlertTemplateEdit} resource="sun" sevenDaysData={sevenDaysData} setSevenDaysData={setSevenDaysData} workflows={workflows} severities={severities} gettingData={gettingData} setGettingData={setGettingData} isStore={isStore} />
                                                            </div>
                                                        </div>
                                                        {/* Sun ends here */}

                                                    </div>
                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>

                                <div className="startDiv" style={{ padding: "10px" }}>

                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Accordion.Toggle
                                                as={Card.Header}
                                                eventKey="1" className='NotificationCardAccordion'
                                                onClick={() => setCustomeOpen(!customOpen)}
                                            >
                                                <p>Once on a date</p>
                                                {
                                                    !customOpen ?
                                                        <img src={ArrowDown} alt="dropdown-arrow" /> :
                                                        <img src={ArrowUp} alt='up arrow' />
                                                }
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1">
                                                <Card.Body style={{ padding: "10px" }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between" }}>

                                                    </div>
                                                    <div className="HgtCheckBoxList" style={{ paddingRight: "5px", height: "auto", minHeight: "70px" }}>
                                                    <div>
                                                        <div style={{ marginBottom: "20px", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                            {/* <div>
                                                                <p>Select and add a new date</p>
                                                            </div> */}

                                                        {/* <DatePicker disabled={!isAlertTemplateEdit} placement="autoVerticalStart" onChange={(e) => customDateAdded(e)} value={dateCustom} placeholder="Select and add a new date" style={{ width: 250 }} format='dd-MM-yyyy' disabledDate={(currentDate) => currentDate && currentDate <= new Date()} /> */}
                                                        <DatePicker 
                                                        disabled={!isAlertTemplateEdit || isStore} 
                                                        placement="autoVerticalStart" 
                                                        onChange={(e) => customDateAdded(e)} 
                                                        value={dateCustom} 
                                                        placeholder="Select and add a new date" 
                                                        style={{ width: 250 }} 
                                                        format='dd-MM-yyyy' 
                                                        disabledDate={(currentDate) => currentDate && currentDate < new Date().setHours(0, 0, 0, 0)}
                                                        onFocus={e => e.target.blur()} />

                                                        <div 
                                                        style={{display: "flex"}}
                                                        >
                                                            {
                                                                customData.length > 1 && !isStore
                                                                ? 
                                                                <button className="DataAccessEdit buttonDisable" 
                                                                disabled={!isAlertTemplateEdit}
                                                            style={{ width: "100px" }} onClick={(e) => {
                                                                e.preventDefault();
                                                                setRepeatDate(true)
                                                            }}>
                                                                {/* <img src={DeleteWhite} onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setRTShow(true)
                                                                }} className="EditedIcon" style={{ height: "16px" }} alt='delete white icon' /> */}
                                                                <span className="ViewUpdate ml-2">Repeat</span>
                                                            </button>
                                                                : null
                                                            }
                                                            {
                                                                customData.length > 0 && !isStore
                                                                ?
                                                                <OverlayTrigger
                                                                    placement='bottom'
                                                                    overlay={<Tooltip>Delete</Tooltip>}
                                                                >
                                                                    <button className="DataAccessEdit buttonDisable"
                                                                    disabled={!isAlertTemplateEdit}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            setDLShow(true)
                                                                        }}
                                                                    >
                                                                        <img src={DeleteWhite} className="EditedIcon" style={{ height: "16px" }} alt='delete white icon' />
                                                                    </button>
                                                                </OverlayTrigger>
                                                                    : null
                                                            }
                                                        </div>
                                                    </div>
                                                        </div>
                                                    {/* <div className="noDataAccessMsg  mx-0" style={{background: "#FEF6F1 0% 0% no-repeat padding-box", border: "1px solid #FFDFC9"}}>
                                                        <div>
                                                            <img 
                                                            className="informationIcon" 
                                                            src={InfoOrange} 
                                                            alt="Info"
                                                            />
                                                        </div>
                                                        <div style={{ width: "100%" }}>
                                                            <h3 className="No-Data-Access-Group">
                                                            No date schedule record
                                                            </h3>
                                                        </div>
                                                    </div> */}
                                                    {
                                                        customData.length > 0
                                                        ? <>
                                                            {
                                                                customData?.map((item) => (
                                                                    <div className='DaySliderBox' key={item.day}>
                                                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                                                            <div>
                                                                                <div className='togglecheckbox'>
                                                                                    <label>
                                                                                    <input disabled={!isAlertTemplateEdit || isStore} type="checkbox" checked={item.isEnabled} onChange={(e) => {enableCustomDay(e, item.day)}} style={{ marginRight: "3px" }} />
                                                                                    <span />
                                                                                    </label>
                                                                                </div>
                                                                                <label style={{ margin: "0px" }}>{item.day}</label>
                                                                            </div>
                                                                            <CustomScheduler isAlertTemplateEdit={isAlertTemplateEdit} resource={item.day} sevenDaysData={customData} setSevenDaysData={setCustomData}  workflows={workflows} severities={severities} gettingData={gettingData} setGettingData={setGettingData} isStore={isStore} />
                                                                        </div>
                                                                        {/* <div className='hoverDelete'>
                                                                            <button className="DataAccessEdit">
                                                                                <img src={DeleteWhite} onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    setDisplayModalDelete(true)
                                                                                    setDateToDel(item);
                                                                                }} className="EditedIcon" style={{ height: "16px" }} alt='delete white icon' />
                                                                            </button>
                                                                        </div> */}
                                                                    </div>  
                                                                ))
                                                            }
                                                        </>
                                                        :  <AlertTemplateMessage message={"No date schedule record"} />
                                                    }
                                                    </div>

                                                </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </div>

                                {/* Modal popup for add replicate day of scheduler */}
                                <Modal 
                                    show={RTShow} 
                                    onHide={handleClose} 
                                    size="lg" 
                                    className='replicateModal'
                                    backdrop="static"
                                    // centered
                                    >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Repeat Day Schedule</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <div>
                                                {/* <p className='mb-0 RepTimeLabel'>Replicate From:</p>
                                                <h6>Monday</h6> */}
                                                <div className="NotificationTextBox">
                                                    <p className="SiteNameText mb-0">
                                                    Select Day From:
                                                    </p>
                                                    <select
                                                    className="MessageTextBox1"
                                                    placeholder="Select Device type"
                                                    onChange={(e) => {
                                                        setRepFromDay(e.target.value);
                                                    }}
                                                    >
                                                    <option key="0" disabled={false} value="">
                                                        Select day
                                                    </option>
                                                    {
                                                        repDays.map((item) => (
                                                            <option key={item.label} disabled={false} value={item.id}>
                                                            {item.label}
                                                            </option>
                                                        ))
                                                    }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className='CheckBoxRepTime' style={{width: "100%"}}>
                                                <p className='mb-0 RepTimeLabel'>Replicate To:</p>
                                                <div>
                                                    {
                                                        repDays.map((item) => (
                                                            <div className='CheckboxDayWidthDiv' key={item.id}>
                                                                <input
                                                                type="checkbox" id={item.id}
                                                                disabled={repFromDay == "" || item.id == repFromDay} 
                                                                onChange={(e) => selectDays(e, item.id)} 
                                                                value={item.checked} 
                                                                style={{ marginRight: "3px" }} />
                                                                <label for={item.id} style={{ margin: "0px" }}>{item.short}</label>
                                                            </div>
                                                        ))
                                                    }
                                                    {/* <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Mon</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Tue</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Wed</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Thu</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Fri</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Sat</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Sun</label>
                                                    </div> */}
                                                </div>
                                            </div>                            
                                        </Form>

                                        <button className="DataAccessEdit mt-3 ml-0"  style={{ width: "100%", height: "40px"}} onClick={replicateDayTimings}>
                                            <span className="ViewUpdate ml-1" style={{fontSize: "0.975rem"}}>Replicate Timing</span>
                                        </button>
                                    </Modal.Body>
                                </Modal>
                                 {/* Modal popup for add replicate timing of scheduler */}

                                {/* Modal popup for date of scheduler  */}
                                 <Modal 
                                    show={RepeatDate} 
                                    onHide={() => {
                                        setRepFromCustom("");
                                        let sample = customMultiOption.map((item) => {
                                            let obj = {
                                                ...item,
                                                tochecked: false,
                                                fromchecked: false
                                            }
                                            return obj
                                        });
                                        setCustomMultiOption(sample);
                                        setCustomMultiOptionFrom(sample);
                                        setCustomMultiOptionTo(sample);
                                        setCustomToCheck(false);
                                        setFromDateSearch("");
                                        setEndDateSearch("");
                                        setRepeatDate(false)
                                    }} 
                                    size="lg" 
                                    className='replicateDateModal'
                                    backdrop="static"
                                    >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Repeat Date Schedule</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form>
                                            <div>
                                                {/* <p className='mb-0 RepTimeLabel'>Replicate From:</p>
                                                <h6>Monday</h6> */}
                                                {/* <div className="NotificationTextBox">
                                                    <p className="SiteNameText mb-0">
                                                    Select date from:
                                                    </p>
                                                    <select
                                                    className="MessageTextBox1"
                                                    onChange={e => {
                                                        setRepFromCustom(e.target.value);
                                                    }}
                                                    >
                                                    <option key="0" disabled={false} value="">
                                                        Select date
                                                    </option>
                                                    {
                                                        customData.map((item) => (
                                                            <option key="0" disabled={false} value={item.day}>
                                                                {item.day}
                                                            </option>
                                                        ))
                                                    }
                                                    </select>
                                                </div> */}

                                                    <div className="grid-container filterContainer grid-container--fit mb-0">
                                                        <div className="NotificationTextBox mt-0">
                                                            <p className="SiteNameText mb-0">
                                                            Repeat From
                                                            </p>

                                                            <div className='filterinnerboxdiv'>
                                                                <div>
                                                                    <div className="SelectionListWithSelectAll">
                                                                        <input
                                                                            className="SearchViewInput1"
                                                                            type="search"
                                                                            placeholder="search"
                                                                            value={fromDateSearch}
                                                                            onKeyPress={onSpecialChar}
                                                                            onChange={(e) => searchHandler(e, "FROM")}
                                                                        />
                                                                        <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} />
                                                                    </div>
                                                                </div>
                                                                <div className='HgtFilterBoxList' style={{height: "24vh"}}>
                                                                {
                                                                    customMultiOptionFrom.map((item) => (
                                                                        <div key={item.id} className="dataAccessListItem" disabled={repFromCustom !== "" && repFromCustom !== item.label}
                                                                        >
                                                                            <input type="checkbox" disabled={repFromCustom !== "" && repFromCustom !== item.label} checked={item.fromchecked}
                                                                                onChange={(e) => selectCustomDaysFrom(e, item.label)}
                                                                            />
                                                                            <p className="AllTextwithValue"
                                                                                onClick={(e) => {
                                                                                    if (repFromCustom !== "" && repFromCustom !== item.label) {
                                                                                        return;
                                                                                    } else {
                                                                                        selectCustomDaysFrom(e, item.label)
                                                                                    }
                                                                                }} >
                                                                                {item.short}</p>
                                                                        </div>
                                                                    ))
                                                                }
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div className="NotificationTextBox mt-0">
                                                            <p className="SiteNameText mb-0">
                                                                Repeat To 
                                                            </p>

                                                            <div className='filterinnerboxdiv'>
                                                                <div>
                                                                    <div className="SelectionListWithSelectAll">
                                                                        <input
                                                                            className="SearchViewInput1"
                                                                            type="search"
                                                                            placeholder="search"
                                                                            value={endDateSearch}
                                                                            onKeyPress={onSpecialChar}
                                                                            onChange={(e) => searchHandler(e, "TO")}
                                                                        />
                                                                        <img src={SearchIcon} alt='search icon' style={{ cursor: 'pointer' }} />
                                                                    </div>
                                                                </div>
                                                                <div className='HgtFilterBoxList' style={{height: "24vh"}}>
                                                                {
                                                                    selectCount > 0
                                                                        ?
                                                                        <div className='dataAccessListItem'>
                                                                            <input type="checkbox" checked={customToCheck} onChange={changeCustomToCheck} disabled={repFromCustom == ""} />
                                                                            <p className="AllTextwithValue"
                                                                                onClick={changeCustomToCheck}>
                                                                                Select all <span>{parseInt(selectCount)}</span>
                                                                            </p>
                                                                        </div>
                                                                        : null
                                                                }

                                                                {
                                                                    customMultiOptionTo.map((item) => {
                                                                        console.log("item.tochecked", item.tochecked)
                                                                        return (
                                                                            <div key={item.id} className="dataAccessListItem"

                                                                            // disabled={repFromCustom == "" || item.label == repFromCustom}
                                                                            >
                                                                                <input type="checkbox" checked={item.tochecked} disabled={repFromCustom == "" || item.label == repFromCustom}
                                                                                    onChange={(e) => selectCustomDays(e, item.label)}
                                                                                />
                                                                                <p className="AllTextwithValue"
                                                                                    onClick={(e) => {
                                                                                        if (repFromCustom == "" || item.label == repFromCustom) {
                                                                                            return;
                                                                                        } else {
                                                                                            selectCustomDays(e, item.label)
                                                                                        }
                                                                                    }}>
                                                                                    {item.short}</p>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                                </div>
                                                            </div>
                                                        </div> 
                                                    </div>
                                            </div>
                                        </Form>

                                        <button className="DataAccessEdit mt-3 ml-0" style={{ width: "100%", height: "40px"}} onClick={replicateCustomTimings}>
                                            <span className="ViewUpdate ml-1" style={{fontSize: "0.975rem"}}>Replicate Timing</span>
                                        </button>
                                    </Modal.Body>
                                </Modal>
                                
                                {/* Modal popup for delete of scheduler          */}
                                 <Modal 
                                    show={DLShow} 
                                    onHide={() => {
                                        setDLShow(false)
                                        setCustomMultiSelected([]);
                                    }} 
                                    size="lg" 
                                    className='deleteModalClass'
                                    backdrop="static"
                                    centered
                                    >
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete date schedule</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* <Form>
                                            <div className="NotificationTextBox">
                                                            <p className="SiteNameText mb-0">
                                                            Select
                                                            </p>
                                                            <MultiSelect
                                                            overrideStrings={{
                                                                selectSomeItems: "Select date",
                                                                allItemsAreSelected: "All dates are selected"
                                                            }}
                                                            options={[]}
                                                            value={[]}
                                                            ArrowRenderer={() => <DropArrow />}
                                                            />
                                            </div>
                                            <div>
                                                <p className='mb-0 RepTimeLabel'>Delete From:</p>
                                                <h6>Monday</h6>
                                            </div>
                                            <div className='CheckBoxRepTime'>
                                                <p className='mb-0 RepTimeLabel'>Delete To:</p>
                                                <div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Mon</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Tue</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Wed</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Thu</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Fri</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Sat</label>
                                                    </div>
                                                    <div className='CheckboxDayWidthDiv'>
                                                        <input type="checkbox" style={{ marginRight: "3px" }}/>
                                                        <label style={{ margin: "0px" }}>Sun</label>
                                                    </div>
                                                </div>
                                            </div>                            
                                        </Form> */}

                                        <div className="NotificationTextBox">
                                                            <p className="SiteNameText mb-0">
                                                            Select date to delete:
                                                            </p>
                                                            <MultiSelect
                                                            overrideStrings={{
                                                                selectSomeItems: "Select date",
                                                                allItemsAreSelected: "All dates are selected"
                                                            }}
                                                            options={customMultiOption}
                                                            value={customMultiSelected}
                                                            onChange={setCustomMultiSelected}
                                                            ArrowRenderer={() => <DropArrow />}
                                                            />
                                            </div>

                                        <button className="DataAccessEdit mt-4"  style={{ width: "100%", height: "40px"}} disabled={customData.length === 0} onClick={removeCustomDates}>
                                            <span className="ViewUpdate ml-1"  style={{fontSize: "0.975rem"}}>Delete Timing</span>
                                        </button>
                                    </Modal.Body>
                                </Modal>
                                 
                                 {/* Modal popup for delete of scheduler */}
                                 <Modal
                                    size='lg'
                                    show={displayModalDelete}
                                    onHide={() => setDisplayModalDelete(false)}
                                    className='deleteModalMainClass'
                                    backdrop="static"
                                    // centered
                                > 
                                <Modal.Body>
                                    <div className='ModalBodyDiv'>
                                    <div style={{padding: "37px"}}>
                                    <div style={{display: "flex", alignItems: "center", marginBottom: "1rem"}}>
                                        <img 
                                        className="informationIcon infoorangeicon" 
                                        src={WarningDelete} 
                                        alt="Info"
                                        />
                                        <div style={{ width: "100%" }}>
                                            <h3 className="No-Data-Access-Group">
                                            Delete Date Schedule!
                                            </h3>
                                        </div>
                                    </div>
                                    <p className='DeleteText mb-4'>
                                        Are you sure you want to delete the schedule for {dateToDel?.day}
                                    </p>
                                    
                                                <div style={{ textAlign: 'center', display: "flex", justifyContent: "space-between" }}>
                                                    <button 
                                                    onClick={(e) => removeCustomDate(e, dateToDel?.id)}
                                                    className='DataAccessEdit ml-0' style={{ width: "45%" }}>
                                                        <span className='ViewUpdate'>Yes</span>
                                                    </button>
                                                    <button onClick={(e) => {
                                                        e.preventDefault();
                                                        setDisplayModalDelete(false);
                                                        setDateToDel(null);
                                                    }} className='DataAccessEdit ml-0' style={{ width: "45%" }}>
                                                        <span className='ViewUpdate'>No</span>
                                                    </button>
                                                </div>
                                    </div>
                                    
                                    </div>
                                </Modal.Body>
                                </Modal>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Scheduler