import React, { useState, useRef, useEffect, memo } from 'react'
import { DeleteShape, EditShape, SampleDownload, Cross, DeleteQc, SearchIcon, EditQc, AddFilter, EditFilter, DeleteFilter, AddSelected, RemoveSelected, SearchIconWhite } from '../../assets/images'
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Pagination from "react-bootstrap/Pagination";
import { addEntity, getMasterData, removeEntity } from '../../services/dataAccessService';
import './DataAccessControls.css';
import DataAccessListItem from './DataAccessListItem';
import { SuccessToast } from '../../CustomHooks/SuccessToast';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { WarningToast } from '../../CustomHooks/WarningToast';
import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const AVAILABLE = "AVAILABLE";
const SELECTED = "SELECTED";

const DataAccessControls = ({ editMode, isEditCustomer, setIsEditCustomer, groupName, selectedNode, nodes, disableAdd, tempParent, stats, sessionStart, tempCount, getDataAccessDetails, adminNodeSelected, setDisableInputs, isDarkTheme }) => {
    const ref = useRef();
    let clist = [
        {
            id: 0,
            name: "Customer(s)",
            selected: true,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "customer"
        },
        {
            id: 1,
            name: "Country(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "country"
        },
        {
            id: 2,
            name: "State(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "state"
        },
        {
            id: 3,
            name: "City(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "city"
        },
        {
            id: 4,
            name: "Region(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "region"
        },
        {
            id: 5,
            name: "Pin code(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "pincode"
        },
        {
            id: 6,
            name: "Area(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "area"
        },
        {
            id: 7,
            name: "Site(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "site"
        },
        {
            id: 8,
            name: "Branch ID(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "branchid"
        },
        {
            id: 9,
            name: "Device Type(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "devicetype"
        },
        {
            id: 10,
            name: "Device(s)",
            selected: false,
            menuOpen: false,
            selectedCount: 0,
            totalCount: 0,
            identifier: "device"
        },
    ]

    //Start of state block
    const [customerList, setCustomerList] = useState(clist);
    const [selectedEntity, setSelectedEntity] = useState({
        id: 0,
        name: "Customer(s)",
        selected: true,
        menuOpen: false,
        selectedCount: 0,
        totalCount: 0
    });
    const [selected, setSelected] = useState([]);
    const [unselected, setUnSelected] = useState([]);
    const [unselectedTemp, setUnSelectedTemp] = useState([]);
    const [unselectedTempFiltered, setUnSelectedTempFiltered] = useState([]);
    const [selectedTemp, setSelectedTemp] = useState([]);
    const [selectedTempFiltered, setSelectedTempFiltered] = useState([]);
    const [isLeftItemSelected, setIsLeftItemSelected] = useState(false);
    const [isRightItemSelected, setIsRightItemSelected] = useState(false);

    const [searchTextOne, setSearchTextOne] = useState("");
    const [searchTextTwo, setSearchTextTwo] = useState("");
    const [searchTextThree, setSearchTextThree] = useState("");
    const [searchTextFour, setSearchTextFour] = useState("");
    const [isLeftSearch, setIsLeftSearch] = useState(false);
    const [isRightSearch, setIsRightSearch] = useState(false);

    const [checkOne, setCheckOne] = useState(false);
    const [checkTwo, setCheckTwo] = useState(false);
    const [topLeftCheck, setTopLeftCheck] = useState(false);
    const [topRightCheck, setTopRightCheck] = useState(false);
    const [midLeftCheck, setMidLeftCheck] = useState(false);
    const [midRightCheck, setMidRightCheck] = useState(false);

    const [finalChildId, setFinalChildId] = useState(0);
    const [finalParentId, setFinalParentId] = useState(0);
    const [finalEntity, setFinalEntity] = useState("");

    const [sampleCount, setSampleCount] = useState(0);

    const [nextClickOne, prevClickOne, pageCounterOne, currentPageNoOne, limitOne, totalPagesOne, setCurrentPageNoOne, setTotalPagesOne, maxPageAllowedOne, setPageCounterOne] = useDataAccessPagination(10, 1, 10, 1);
    const [nextClickTwo, prevClickTwo, pageCounterTwo, currentPageNoTwo, limitTwo, totalPagesTwo, setCurrentPageNoTwo, setTotalPagesTwo, maxPageAllowedTwo, setPageCounterTwo] = useDataAccessPagination(10, 1, 10, 1);

    // End of state block

    // Start of useEffect block

    useEffect(() => {
        setCustomerList(clist);
    }, [tempCount]);


    useEffect(() => {
        setIsLeftItemSelected(false);
        setIsRightItemSelected(false);
        setCheckOne(false);
        setCheckTwo(false);
        setTopLeftCheck(false);
        setTopRightCheck(false);
        setMidLeftCheck(false);
        setMidRightCheck(false);
        setIsLeftSearch(false);
        setIsRightSearch(false);
    }, [selectedEntity]);

    useEffect(() => {
        let data = [];
        for (let i = 0; i < customerList.length; i++) {
            let obj = customerList[i];
            for (let j = 0; j < stats.length; j++) {
                if (stats[j].EntityDataType == obj.identifier) {
                    obj.selectedCount = stats[j].SelectedCount;
                    obj.totalCount = stats[j].TotalCount
                }
            }
            data.push(obj);
        }
        let s = sampleCount + 1;
        setSampleCount(s);
    }, [stats]);

    useEffect(() => {
        setCustomerList(clist);
    }, [editMode]);

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (ref.current && !ref.current.contains(e.target)) {
                let arr = customerList.map((c) => {
                    c.menuOpen = false;
                    return c;
                })
                setCustomerList(arr);
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [customerList])

    useEffect(() => {
        if (searchTextTwo === "") {
            setUnSelectedTempFiltered(unselectedTemp);
        }
    }, [searchTextTwo])

    useEffect(() => {
        if (searchTextFour === "") {
            setSelectedTempFiltered(selectedTemp);
        }
    }, [searchTextFour])


    // useEffect(() => {
    //     if(searchTextOne == "" && selectedNode != null){
    //         let obj = {
    //             entity: finalEntity,
    //             fileName: groupName + "_" + localStorage.getItem('authCode'),
    //             parentId: finalParentId,
    //             childId: finalChildId,
    //             pageNumber: "1",
    //             pageType: "available",
    //             searchText: searchTextOne
    //         }
    //         getMasterFunc(obj, AVAILABLE);    
    //     }
    // }, [searchTextOne]);

    // useEffect(() => {
    //     if(searchTextThree == "" && selectedNode != null){
    //         let obj = {
    //             entity: finalEntity,
    //             fileName: groupName + "_" + localStorage.getItem('authCode'),
    //             parentId: finalParentId,
    //             childId: finalChildId,
    //             pageNumber: "1",
    //             pageType: "selected",
    //             searchText: searchTextThree
    //         }
    //         getMasterFunc(obj, SELECTED);
    //     }
    // }, [searchTextThree]);

    // useEffect(() => {
    //     if(searchTextOne == "" && selectedNode != null){
    //         let obj = {
    //             entity: finalEntity,
    //             fileName: groupName + "_" + localStorage.getItem('authCode'),
    //             parentId: finalParentId,
    //             childId: finalChildId,
    //             pageNumber: "1",
    //             pageType: "all",
    //             searchText: searchTextOne
    //         }
    //         getMasterFunc(obj, AVAILABLE);
    //     }
    // }, [searchTextOne])

    useEffect(() => {
        if (selectedNode != null) {
            let obj = {
                entity: finalEntity,
                fileName: groupName + "_" + localStorage.getItem('authCode'),
                parentId: finalParentId,
                childId: finalChildId,
                pageNumber: currentPageNoOne,
                pageType: "available",
                // searchText: searchTextOne
                searchText: isLeftSearch ? searchTextOne : ""
            }
            getMasterFunc(obj, AVAILABLE, true);
        }
    }, [currentPageNoOne])

    useEffect(() => {
        if (selectedNode != null) {
            let obj = {
                entity: finalEntity,
                fileName: groupName + "_" + localStorage.getItem('authCode'),
                parentId: finalParentId,
                childId: finalChildId,
                pageNumber: currentPageNoTwo,
                pageType: "selected",
                // searchText: searchTextThree
                searchText: isRightSearch ? searchTextThree : ""
            }
            getMasterFunc(obj, SELECTED, true);
        }
    }, [currentPageNoTwo])

    // End of useEffect

    // Start of Function/Handlers.

    // 1.Modal Related. 

    const openTheMenu = (id) => {
        if (id == customerList.length - 1) {
            return;
        }
        let arr = customerList.map((c) => {
            if (c.id == id && c.menuOpen == false) {
                c.menuOpen = true;
                return c;
            } else {
                c.menuOpen = false;
                return c;
            }
        })
        setCustomerList(arr);
    }

    const closeTheMenu = (id, selectedId) => {
        let arr = customerList.map((c) => {
            if (c.id == id) {
                c.menuOpen = false;
            }
            if (c.id == selectedId) {
                c.selected = true;
            }
            return c;
        })
        setCustomerList(arr);
    }

    const deleteMenu = (id) => {
        let arr = customerList.map((c) => {
            if (c.id == id && c.id != 0) {
                c.selected = false;
            }
            return c;
        })
        setCustomerList(arr);
    }

    // 2. Controls Related.

    const selectEntityHandler = (id) => {
        let arr = customerList.filter(c => c.id == id);
        setSelectedEntity(arr[0]);
        setSearchTextOne("");
        setSearchTextTwo("");
        setSearchTextThree("");
        setSearchTextFour("");
        setCheckOne(false);
        setCheckTwo(false);
        setTopLeftCheck(false);
        setTopRightCheck(false);
        setMidLeftCheck(false);
        setMidRightCheck(false);
        setIsLeftItemSelected(false);
        setIsLeftSearch(false);
        setIsRightSearch(false);
        setIsRightItemSelected(false);
        setIsEditCustomer(!isEditCustomer);
        let selectedString = arr[0].name.substring(0, arr[0].name.length - 3).toLowerCase();
        setFinalEntity(selectedString);
        let parentIdsArr = nodes.map((item) => item.source);
        let isParent = parentIdsArr.includes(selectedNode.id);
        let tempParentId = 0
        let tempChildId = 0
        let sourceId = 0;

        if (selectedNode.id == 1) {
            tempChildId = -1
            tempParentId = 0
        } else {
            let tempNodes = nodes.filter((item) => item.id == selectedNode.id);
            tempParentId = tempNodes[0].parentId == 1 ? 0 : tempNodes[0].parentId
            tempChildId = selectedNode.id

            if (disableAdd) {
                tempChildId = -1
                if (isParent) {
                    let tempNodes = nodes.filter((item) => item.id == selectedNode.id);
                    tempParentId = tempNodes[0].parentId == 1 ? 0 : tempNodes[0].parentId
                    tempChildId = selectedNode.id
                } else {
                    for (let x = nodes.length - 1; x > 0; x--) {
                        if (nodes[x].type == "edge") {
                            sourceId = nodes[x].source;
                            tempParentId = sourceId;
                            break;
                        }
                    }
                }
            }
        }

        if (sourceId == 1) {
            tempParentId = 0
        }
        // else{
        //     tempParentId = sourceId;
        // }

        if (selectedNode.id == 1) {
            tempChildId = -1
            tempParentId = 0
        }
        setFinalChildId(tempChildId);
        setFinalParentId(tempParentId);

        let obj = {
            entity: selectedString,
            fileName: groupName + "_" + localStorage.getItem('authCode'),
            parentId: tempParentId,
            childId: tempChildId,
            pageNumber: "1",
            pageType: "all"
        }
        getMasterData(obj)
            .then(res => {
                if (res.Data) {
                    setSelected(res.Data.LstSelected);
                    setUnSelected(res.Data.LstAvailable);
                    let tempArr = res.Data.LstAvailable.map((item) => {
                        let obj = {
                            ...item,
                            isItSelected: false,
                        }
                        return obj;
                    })
                    let tempArrTwo = res.Data.LstSelected.map((item) => {
                        let obj = {
                            ...item,
                            isItSelected: false
                        }
                        return obj
                    })
                    setUnSelectedTemp(tempArr);
                    setUnSelectedTempFiltered(tempArr);
                    setSelectedTemp(tempArrTwo);
                    setSelectedTempFiltered(tempArrTwo);
                    // setTotalPagesOne(arr[0].totalCount - arr[0].selectedCount)
                    // setTotalPagesTwo(arr[0].selectedCount);
                    setTotalPagesOne(parseInt(res.Data.TotalLengthAvailable));
                    setTotalPagesTwo(parseInt(res.Data.TotalLengthSelected));
                    setCurrentPageNoOne(1);
                    setCurrentPageNoTwo(1);
                    setPageCounterOne(1);
                    setPageCounterTwo(1);
                }
            })
    }

    const selectAllHandler = (e, type) => {
        if (type === "ONE") {
            let temp = checkOne;
            setCheckOne(!checkOne);
            const tempArr = unselectedTempFiltered.map((item, index) => {
                let obj = {
                    ...item,
                    isItSelected: !checkOne
                }
                return obj;
            })
            if (temp) {
                setIsLeftItemSelected(false);
            } else {
                setIsLeftItemSelected(true);
            }
            setUnSelectedTemp(tempArr);
            setUnSelectedTempFiltered(tempArr);
        } else {
            let temp = checkTwo;
            setCheckTwo(!checkTwo);
            const tempArr = selectedTempFiltered.map((item, index) => {
                let obj = {
                    ...item,
                    isItSelected: !checkTwo
                }
                return obj
            })
            if (temp) {
                setIsRightItemSelected(false);
            } else {
                setIsRightItemSelected(true);
            }
            setSelectedTemp(tempArr);
            setSelectedTempFiltered(tempArr);
        }
    }

    const selectItemHandler = (id, type) => {
        if (type === "ONE") {
            const tempArr = unselectedTempFiltered.map((item, index) => {
                let obj;
                if (item.Id == id) {
                    obj = {
                        ...item,
                        isItSelected: !item.isItSelected
                    }
                } else {
                    obj = {
                        ...item,
                    }
                }
                return obj;
            })
            let isTruePresent = tempArr.some((item) => item.isItSelected === true);
            setIsLeftItemSelected(isTruePresent);
            setUnSelectedTemp(tempArr);
            setUnSelectedTempFiltered(tempArr);
        } else {
            const tempArr = selectedTempFiltered.map((item, index) => {
                let obj;
                if (item.Id == id) {
                    obj = {
                        ...item,
                        isItSelected: !item.isItSelected
                    }
                } else {
                    obj = {
                        ...item
                    }
                }
                return obj;
            })
            let isTruePresent = tempArr.some((item) => item.isItSelected === true);
            setIsRightItemSelected(isTruePresent);
            setSelectedTemp(tempArr);
            setSelectedTempFiltered(tempArr);
        }
    }

    // 3. Add and Remove Controls.
    const addItemsToRight = () => {
        if (unselectedTempFiltered.length == 0) {
            WarningToast("No Items left to add.");
            return;
        }
        let tempArrLeft = unselectedTempFiltered.filter((item, index) => {
            return item.isItSelected == false
        })

        let tempArrRight = unselectedTempFiltered.filter((item, index) => {
            return item.isItSelected == true
        }).map((i) => {
            i.isItSelected = false;
            return i;
        })
        if (tempArrRight.length == 0) {
            WarningToast("No items selected to add.");
            return;
        }

        let str = arrToString(tempArrRight);
        let selectedEnt = selectedEntity.name.substring(0, selectedEntity.name.length - 3).toLowerCase();
        let { id, parentId } = getCorrectIds(finalChildId, finalParentId);
        let obj = {
            data: {
                name: selectedEnt,
                ids: str,
                entityDataType: 'add',
                selectAll: topLeftCheck,
                filterText: searchTextOne,
                selectAllFilter: midLeftCheck
            },
            fileName: groupName + "_" + localStorage.getItem('authCode'),
            parentId: parentId,
            childId: id
        }
        addEntity(obj).then(res => {
            if (res.Status == "success") {
                SuccessToast("Items added successfully and records updated");
                let fname = groupName + "_" + localStorage.getItem('authCode');
                let obj = {
                    entity: finalEntity,
                    fileName: groupName + "_" + localStorage.getItem('authCode'),
                    parentId: finalParentId,
                    childId: finalChildId,
                    pageNumber: "1",
                    pageType: "all"
                }
                setIsLeftItemSelected(false);
                setDisableInputs(true);
                if (checkOne) {
                    setCheckOne(false);
                }
                getMasterData(obj)
                    .then(res => {
                        if (res.Data) {
                            setSelected(res.Data.LstSelected);
                            setUnSelected(res.Data.LstAvailable);
                            let tempArr = res.Data.LstAvailable.map((item) => {
                                let obj = {
                                    ...item,
                                    isItSelected: false,
                                }
                                return obj;
                            })
                            let tempArrTwo = res.Data.LstSelected.map((item) => {
                                let obj = {
                                    ...item,
                                    isItSelected: false
                                }
                                return obj
                            })
                            setUnSelectedTemp(tempArr);
                            setUnSelectedTempFiltered(tempArr);
                            setSelectedTemp(tempArrTwo);
                            setSelectedTempFiltered(tempArrTwo);
                            setTotalPagesOne(parseInt(res.Data.TotalLengthAvailable));
                            setTotalPagesTwo(parseInt(res.Data.TotalLengthSelected));
                            setCurrentPageNoOne(1);
                            setCurrentPageNoTwo(1);
                            setPageCounterOne(1);
                            setPageCounterTwo(1);
                            setCheckOne(false);
                            setMidLeftCheck(false);
                            setTopLeftCheck(false);
                            setSearchTextOne("");
                            setSearchTextTwo("");
                            getDataAccessDetails(finalChildId, finalParentId, fname, 1, "", "");
                        }
                    })
            } else {
                WarningToast(res.Message)
                setIsLeftItemSelected(false);
                setTopLeftCheck(false);
                setMidLeftCheck(false);
                setCheckOne(false);
                setDisableInputs(true);
                return;
            }
        }).catch(err => {
            console.log(err);
            return;
        })
    }

    const addItemsToLeft = () => {
        if (selectedTempFiltered.length == 0) {
            WarningToast("No Items left to remove.");
            return;
        }

        let tempArrRight = selectedTempFiltered.filter((item, index) => {
            return item.isItSelected == false;
        })

        let tempArrLeft =
            selectedTempFiltered
                .filter((item) => item.isItSelected == true)
                .map((i) => {
                    i.isItSelected = false
                    return i;
                })

        if (tempArrLeft.length == 0) {
            WarningToast("No items selected to remove.");
            return;
        }

        //Remove API Start
        let str = arrToString(tempArrLeft);
        let selectedEnt = selectedEntity.name.substring(0, selectedEntity.name.length - 3).toLowerCase();
        let { id, parentId } = getCorrectIds(finalChildId, finalParentId);
        let obj = {
            data: {
                name: selectedEnt,
                ids: str,
                entityDataType: 'remove',
                selectAll: topRightCheck,
                filterText: searchTextThree,
                selectAllFilter: midRightCheck
            },
            fileName: groupName + "_" + localStorage.getItem('authCode'),
            parentId: parentId,
            childId: id
        }
        removeEntity(obj).then(res => {
            if (res.Status == "success") {
                SuccessToast("Items removed successfully and records updated.");
                let fname = groupName + "_" + localStorage.getItem('authCode');
                let obj = {
                    entity: finalEntity,
                    fileName: groupName + "_" + localStorage.getItem('authCode'),
                    parentId: finalParentId,
                    childId: finalChildId,
                    pageNumber: "1",
                    pageType: "all"
                }
                setIsRightItemSelected(false);
                setDisableInputs(true);
                if (checkTwo) {
                    setCheckTwo(false);
                }
                getMasterData(obj)
                    .then(res => {
                        if (res.Data) {
                            setSelected(res.Data.LstSelected);
                            setUnSelected(res.Data.LstAvailable);
                            let tempArr = res.Data.LstAvailable.map((item) => {
                                let obj = {
                                    ...item,
                                    isItSelected: false,
                                }
                                return obj;
                            })
                            let tempArrTwo = res.Data.LstSelected.map((item) => {
                                let obj = {
                                    ...item,
                                    isItSelected: false
                                }
                                return obj
                            })
                            setUnSelectedTemp(tempArr);
                            setUnSelectedTempFiltered(tempArr);
                            setSelectedTemp(tempArrTwo);
                            setSelectedTempFiltered(tempArrTwo);
                            setTotalPagesOne(parseInt(res.Data.TotalLengthAvailable));
                            setTotalPagesTwo(parseInt(res.Data.TotalLengthSelected));
                            setCurrentPageNoOne(1);
                            setCurrentPageNoTwo(1);
                            setPageCounterOne(1);
                            setPageCounterTwo(1);
                            setCheckTwo(false);
                            setTopRightCheck(false);
                            setMidRightCheck(false);
                            setSearchTextThree("");
                            setSearchTextFour("");
                            getDataAccessDetails(finalChildId, finalParentId, fname, 1, "", "");
                        }
                    })
            } else {
                WarningToast(res.Message)
                setIsRightItemSelected(false);
                setTopRightCheck(false);
                setMidRightCheck(false);
                setCheckTwo(false);
                setDisableInputs(true);
                return;
            }
        }).catch(err => {
            console.log(err);
            return;
        })
    }

    //4. Helper Functions

    const getCorrectIds = (id, parentId) => {
        if (id == 1) {
            id = -1;
            parentId = 0;
        }
        if (tempParent == 1) {
            parentId = 0
            id = -1
        }
        if (disableAdd) {
            id = -1;
        }
        return { id, parentId }
    }

    const sortOrder = (a, b) => {
        return a.Id < b.Id ? -1 : (a.Id > b.Id ? 1 : 0);
    }

    const arrToString = (arr) => {
        return arr.map(item => item.Id).join(",");
    }

    //5. Search Related Controls

    const searchHandlerOne = (e) => {
        setSearchTextOne(e.target.value);
        if (e.target.value == "" && selectedNode != null) {
            let obj = {
                entity: finalEntity,
                fileName: groupName + "_" + localStorage.getItem('authCode'),
                parentId: finalParentId,
                childId: finalChildId,
                pageNumber: "1",
                pageType: "available",
                searchText: e.target.value
            }
            getMasterFunc(obj, AVAILABLE);    
            setIsLeftSearch(false);
        }
    }

    const searchHandlerThree = (e) => {
        setSearchTextThree(e.target.value);
        if (e.target.value == "" && selectedNode != null) {
            let obj = {
                entity: finalEntity,
                fileName: groupName + "_" + localStorage.getItem('authCode'),
                parentId: finalParentId,
                childId: finalChildId,
                pageNumber: "1",
                pageType: "selected",
                searchText: e.target.value
            }
            getMasterFunc(obj, SELECTED);
            setIsRightSearch(false);
        }
    }

    const searchHandlerTwo = () => {
        if (searchTextTwo == "") {
            WarningToast("Kindly enter something.")
        }
        let searchElement = searchTextTwo;
        const data = unselectedTemp.filter((item) => item.Name.toLowerCase().includes(searchElement.toLowerCase()));
        setUnSelectedTempFiltered(data);
    }

    const searchHandlerFour = () => {
        if (searchTextFour == "") {
            WarningToast("Kindly enter something.")
        }
        let searchElement = searchTextFour;
        const data = selectedTemp.filter((item) => item.Name.toLowerCase().includes(searchElement.toLowerCase()));
        setSelectedTempFiltered(data);
    }

    const unSelectedGetMasterData = () => {
        setIsLeftSearch(true);
        if (searchTextOne == "") {
            WarningToast("Kindly enter something.")
            return;
        }
        let obj = {
            entity: finalEntity,
            fileName: groupName + "_" + localStorage.getItem('authCode'),
            parentId: finalParentId,
            childId: finalChildId,
            pageNumber: "1",
            pageType: "available",
            searchText: searchTextOne
        }
        getMasterFunc(obj, AVAILABLE);
        let fname = groupName + "_" + localStorage.getItem('authCode');
        // getDataAccessDetails(finalChildId, finalParentId, fname, 1, "", "");
    }

    const selectedGetMasterData = () => {
        setIsRightSearch(true);
        if (searchTextThree == "") {
            WarningToast("Kindly enter something.")
            return;
        }
        let obj = {
            entity: finalEntity,
            fileName: groupName + "_" + localStorage.getItem('authCode'),
            parentId: finalParentId,
            childId: finalChildId,
            pageNumber: "1",
            pageType: "selected",
            searchText: searchTextThree
        }
        getMasterFunc(obj, SELECTED);
    }

    const getMasterFunc = (obj, type, isItPagination) => {
        getMasterData(obj).then((res) => {
            if(res.Data){
                setSelected(res.Data.LstSelected);
                setUnSelected(res.Data.LstAvailable);
                let tempArr = res.Data.LstAvailable.map((item) => {
                    let obj = {
                        ...item,
                        isItSelected: false,
                    }
                    return obj;
                })
                let tempArrTwo = res.Data.LstSelected.map((item) => {
                    let obj = {
                        ...item,
                        isItSelected: false
                    }
                    return obj
                })
                if(type == SELECTED){
                    setSelectedTemp(tempArrTwo);
                    setSelectedTempFiltered(tempArrTwo);
                    setTotalPagesTwo(parseInt(res.Data.TotalLengthSelected));
                    if(!isItPagination){
                        setCurrentPageNoTwo(1);
                    }
                }else if(type == AVAILABLE){
                    setUnSelectedTemp(tempArr);
                    setUnSelectedTempFiltered(tempArr);
                    setTotalPagesOne(parseInt(res.Data.TotalLengthAvailable));
                    if(!isItPagination){
                        setCurrentPageNoOne(1);
                    }
                    // let fname = groupName + "_" + localStorage.getItem('authCode');
                    // getDataAccessDetails(finalChildId, finalParentId, fname, 1, "", "");   
                }
                else {
                    setUnSelectedTemp(tempArr);
                    setUnSelectedTempFiltered(tempArr);
                    setSelectedTemp(tempArrTwo);
                    setSelectedTempFiltered(tempArrTwo);
                    setTotalPagesOne(parseInt(res.Data.TotalLengthAvailable));
                    setTotalPagesTwo(parseInt(res.Data.TotalLengthSelected));
                }
            }
        })
    }

    // 6. Pagination Related Control.

    const PaginationComponentOne = (next) => {
        let numbers = [];
        let max = next * 3;
        let start = max - 2;
        for (let i = start; i <= max; i++) {
            if (i <= maxPageAllowedOne) {
                numbers.push(
                    <Pagination.Item
                        key={i}
                        active={i == currentPageNoOne}
                        disabled={topLeftCheck || midLeftCheck}
                        onClick={() => {
                            if (isLeftItemSelected && editMode && !adminNodeSelected) {
                                WarningToast("Kindly add the selected items before proceeding.")
                                return;
                            }
                            setCurrentPageNoOne(i)
                        }}
                    >
                        {i}
                    </Pagination.Item>
                )
            } else {
                // numbers.push(
                //     <Pagination.Item
                //       key={i}
                //       active={i == currentPageNoOne}
                //       onClick={() => setCurrentPageNoOne(i)}
                //       disabled={true}
                //     >
                //       {i}
                //     </Pagination.Item>
                // )
            }
        }

        return numbers;
    }

    const TempPaginationComponentOne = (currentPageNoOne) => {
        let numbers = [];
        if(maxPageAllowedOne - 2 != 0 && (currentPageNoOne === maxPageAllowedOne || currentPageNoOne === (maxPageAllowedOne - 1) || currentPageNoOne === (maxPageAllowedOne - 2)))
        {
            numbers.push(
            <Pagination.Item
              key={maxPageAllowedOne - 2}
              active={maxPageAllowedOne - 2 == currentPageNoOne}
              disabled={topLeftCheck || midLeftCheck}
              onClick={() => {
                  if (isLeftItemSelected && editMode && !adminNodeSelected) {
                      WarningToast("Kindly add the selected items before proceeding.")
                      return;
                  }
                  setCurrentPageNoOne(maxPageAllowedOne - 2)
              }}
            >
              {maxPageAllowedOne - 2}
            </Pagination.Item>)
            numbers.push(
              <Pagination.Item
                key={maxPageAllowedOne - 1}
                active={maxPageAllowedOne - 1 == currentPageNoOne}
                disabled={topLeftCheck || midLeftCheck}
                onClick={() => {
                    if (isLeftItemSelected && editMode && !adminNodeSelected) {
                        WarningToast("Kindly add the selected items before proceeding.")
                        return;
                    }
                    setCurrentPageNoOne(maxPageAllowedOne - 1)
                }}
              >
                {maxPageAllowedOne - 1}
              </Pagination.Item>) 
            numbers.push(
              <Pagination.Item
                key={maxPageAllowedOne}
                active={maxPageAllowedOne == currentPageNoOne}
                disabled={topLeftCheck || midLeftCheck}
                onClick={() => {
                    if (isLeftItemSelected && editMode && !adminNodeSelected) {
                        WarningToast("Kindly add the selected items before proceeding.")
                        return;
                    }
                    setCurrentPageNoOne(maxPageAllowedOne)
                }}
              >
          {maxPageAllowedOne}
          </Pagination.Item>)
          }
        else if(maxPageAllowedOne - 1 != 0 && (currentPageNoOne === maxPageAllowedOne || currentPageNoOne === (maxPageAllowedOne - 1))){
            numbers.push(
              <Pagination.Item
                key={maxPageAllowedOne - 1}
                active={maxPageAllowedOne - 1 == currentPageNoOne}
                disabled={topLeftCheck || midLeftCheck}
                onClick={() => {
                    if (isLeftItemSelected && editMode && !adminNodeSelected) {
                        WarningToast("Kindly add the selected items before proceeding.")
                        return;
                    }
                    setCurrentPageNoOne(maxPageAllowedOne - 1)
                }}
              >
                {maxPageAllowedOne - 1}
              </Pagination.Item>)
              numbers.push(
                <Pagination.Item
                  key={maxPageAllowedOne}
                  active={maxPageAllowedOne == currentPageNoOne}
                  disabled={topLeftCheck || midLeftCheck}
                  onClick={() => {
                      if (isLeftItemSelected && editMode && !adminNodeSelected) {
                          WarningToast("Kindly add the selected items before proceeding.")
                          return;
                      }
                      setCurrentPageNoOne(maxPageAllowedOne)
                  }}
                >
                  {maxPageAllowedOne}
                </Pagination.Item>) 
          }
        else
          {
            for(let i=currentPageNoOne; i<currentPageNoOne + 3; i++) {
              if(i <= maxPageAllowedOne){
                numbers.push(
                  <Pagination.Item
                    key={i}
                    active={i == currentPageNoOne}
                    disabled={topLeftCheck || midLeftCheck}
                        onClick={() => {
                            if (isLeftItemSelected && editMode && !adminNodeSelected) {
                                WarningToast("Kindly add the selected items before proceeding.")
                                return;
                            }
                            setCurrentPageNoOne(i)
                        }}
                  >
                    {i}
                  </Pagination.Item>
                )
              }
            }  
          }
          return numbers;
    }

    const PaginationComponentTwo = (next) => {
        let numbers = [];
        // If 1,2,3 => 1
        // If 4,5,6 => 2
        // If 7,8,9 => 3
        let max = next * 3;
        let start = max - 2;
        for (let i = start; i <= max; i++) {
            if (i <= maxPageAllowedTwo) {
                numbers.push(
                    <Pagination.Item
                        key={i}
                        active={i == currentPageNoTwo}
                        disabled={topRightCheck || midRightCheck}
                        onClick={() => {
                            if (isRightItemSelected && editMode && !adminNodeSelected) {
                                WarningToast("Kindly remove the selected items before proceeding.")
                                return;
                            }
                            setCurrentPageNoTwo(i)
                        }}
                    >
                        {i}
                    </Pagination.Item>
                )
            } else {
                // numbers.push(
                //     <Pagination.Item
                //       key={i}
                //       active={i == currentPageNoTwo}
                //       onClick={() => setCurrentPageNoTwo(i)}
                //       disabled={true}
                //     >
                //       {i}
                //     </Pagination.Item>
                // )
            }
        }

        return numbers;
    }

    const TempPaginationComponentTwo = (currentPageNoTwo) => {
        let numbers = [];
        if(maxPageAllowedTwo - 2 != 0 && (currentPageNoTwo === maxPageAllowedTwo || currentPageNoTwo === (maxPageAllowedTwo - 1) || currentPageNoTwo === (maxPageAllowedTwo - 2)))
        {
            numbers.push(
            <Pagination.Item
              key={maxPageAllowedTwo - 2}
              active={maxPageAllowedTwo - 2 == currentPageNoTwo}
              disabled={topRightCheck || midRightCheck}
              onClick={() => {
                if (isRightItemSelected && editMode && !adminNodeSelected) {
                      WarningToast("Kindly remove the selected items before proceeding.")
                      return;
                }
                setCurrentPageNoTwo(maxPageAllowedTwo - 2)
              }}
            >
              {maxPageAllowedTwo - 2}
            </Pagination.Item>)
            numbers.push(
              <Pagination.Item
                key={maxPageAllowedTwo - 1}
                active={maxPageAllowedTwo - 1 == currentPageNoTwo}
                disabled={topRightCheck || midRightCheck}
                onClick={() => {
                    if(isRightItemSelected && editMode && !adminNodeSelected) {
                        WarningToast("Kindly remove the selected items before proceeding.")
                        return;
                    }
                    setCurrentPageNoTwo(maxPageAllowedTwo - 1)
                }}
              >
                {maxPageAllowedTwo - 1}
              </Pagination.Item>) 
            numbers.push(
              <Pagination.Item
                key={maxPageAllowedTwo}
                active={maxPageAllowedTwo == currentPageNoTwo}
                disabled={topRightCheck || midRightCheck}
                onClick={() => {
                    if (isRightItemSelected && editMode && !adminNodeSelected) {
                        WarningToast("Kindly remove the selected items before proceeding.")
                        return;
                    }
                    setCurrentPageNoTwo(maxPageAllowedTwo)
                }}
              >
          {maxPageAllowedTwo}
          </Pagination.Item>)
          }
        else if(maxPageAllowedTwo - 1 != 0 && (currentPageNoTwo === maxPageAllowedTwo || currentPageNoTwo === (maxPageAllowedTwo - 1))){
            numbers.push(
              <Pagination.Item
                key={maxPageAllowedTwo - 1}
                active={maxPageAllowedTwo - 1 == currentPageNoTwo}
                disabled={topRightCheck || midRightCheck}
                onClick={() => {
                    if (isRightItemSelected && editMode && !adminNodeSelected) {
                        WarningToast("Kindly remove the selected items before proceeding.")
                        return;
                    }
                    setCurrentPageNoTwo(maxPageAllowedTwo - 1)
                }}    
              >
                {maxPageAllowedTwo - 1}
              </Pagination.Item>)
              numbers.push(
                <Pagination.Item
                  key={maxPageAllowedTwo}
                  active={maxPageAllowedTwo == currentPageNoTwo}
                  disabled={topRightCheck || midRightCheck}
                  onClick={() => {
                    if (isRightItemSelected && editMode && !adminNodeSelected) {
                          WarningToast("Kindly remove the selected items before proceeding.")
                          return;
                    }
                    setCurrentPageNoTwo(maxPageAllowedTwo)
                  }}
                >
                  {maxPageAllowedTwo}
                </Pagination.Item>) 
          }
        else
          {
            for(let i=currentPageNoTwo; i<currentPageNoTwo + 3; i++) {
              if(i <= maxPageAllowedTwo){
                numbers.push(
                  <Pagination.Item
                    key={i}
                    active={i == currentPageNoTwo}
                    disabled={topRightCheck || midRightCheck}
                    onClick={() => {
                    if (isRightItemSelected && editMode && !adminNodeSelected) {
                        WarningToast("Kindly remove the selected items before proceeding.")
                        return;
                    }
                    setCurrentPageNoTwo(i)
                    }}
                  >
                    {i}
                  </Pagination.Item>
                )
              }
            }  
          }
          return numbers;     
    }

    return (
        <div style={{ display: "inline-block" }} ref={ref}>
            <span style={{ display: 'none' }}>{sampleCount}</span>
            {
                customerList.map((c) => (
                    c.selected === true ?
                        <div style={{ position: "relative", float: "left" }}>
                            <div className="customerInfoDiv">
                                {/* <div className="CustomerInfoText"> */}
                                <div className="NodeTitleDescr">
                                    <p className='NodeHeading'>{c.name}</p>
                                    <p className='NodeDescreption'>
                                        Selected <span className='selectedCount'>{c.selectedCount}/{c.totalCount}</span>{" "}
                                    </p>
                                </div>
                                <div className="customerIconDiv">
                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip>
                                                Add filter
                                            </Tooltip>
                                        }
                                    >

                                        <img
                                            src={AddFilter}
                                            alt="Addfilter"
                                            onClick={() => openTheMenu(c.id)}
                                            style={c.id == 10 ? { visibility: 'hidden' } : { visibility: 'block' }}
                                        // onClick={() => setIsMenuOpen(!isMenuOpen)}
                                        />
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip>
                                                Edit filter
                                            </Tooltip>
                                        }
                                    >
                                        <img
                                            src={EditFilter}
                                            alt="Editfilter"
                                            onClick={() => selectEntityHandler(c.id)}
                                        // onClick={() => setIsEditCustomer(!isEditCustomer)}
                                        />
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement='bottom'
                                        overlay={
                                            <Tooltip>
                                                Remove filter
                                            </Tooltip>
                                        }
                                    >
                                    <img src={DeleteFilter}
                                        alt="Delete"
                                        style={c.id == 0 ? { visibility: 'hidden' } : { visibility: 'block' }}
                                        onClick={() => deleteMenu(c.id)}
                                    />
                                    </OverlayTrigger>
                                </div>
                            </div>

                            {c.menuOpen && (
                                <div className="customerFilter">
                                    <ul>
                                        {
                                            customerList.filter((element) => element.id > c.id).map((list) => (
                                                <li
                                                    className="customerFilterList"
                                                    onClick={() => closeTheMenu(c.id, list.id)}
                                                    // onClick={() => setIsMenuOpen(false)}
                                                    key={list.id}
                                                >
                                                    {list.name}
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            )}
                            <Modal
                                show={isEditCustomer}
                                onHide={() => setIsEditCustomer(false)}
                                centered
                                dialogClassName="selectionModel"
                                backdrop="static"   
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        <p className="ModelHeaderName">
                                            Select {selectedEntity.name}
                                            <span className="countNumber">
                                                Count : <span>{selectedEntity.totalCount}</span>
                                            </span>
                                        </p>
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div>
                                        <div className="SelectDiv">
                                            <div className="TwoSelectionDiv">
                                                <div className="SelectAllSearchBarDiv">
                                                    <div className="HeaderwithSelectAll">
                                                        <p className="HeaderTextLabel">Unselected {selectedEntity.name}</p>
                                                        <div>
                                                            <input type="checkbox" checked={topLeftCheck} onChange={() => {
                                                                setTopLeftCheck(!topLeftCheck)
                                                                selectAllHandler("e", "ONE")
                                                            }} />
                                                            <p className="AllTextwithValue"
                                                             onClick={() => {
                                                                setTopLeftCheck(!topLeftCheck)
                                                                selectAllHandler("e", "ONE")
                                                            }} >
                                                                Select all <span>{selectedEntity.totalCount - selectedEntity.selectedCount}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="SelectionListWithSelectAll">
                                                            <input
                                                                className="SearchViewInput1"
                                                                type="search"
                                                                placeholder={`Search unselected ${selectedEntity.identifier}`}
                                                                value={searchTextOne}
                                                                onChange={(e) => searchHandlerOne(e)}
                                                                disabled={topLeftCheck}
                                                            />
                                                            {
                                                                isDarkTheme ?
                                                                <img src={SearchIcon} alt='search icon' onClick={unSelectedGetMasterData} style={{ cursor: 'pointer' }} /> :
                                                                <img src={SearchIconWhite} height="15" alt='search icon' onClick={unSelectedGetMasterData} style={{ cursor: 'pointer' }} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="MainResultDiv">
                                                    <div className="HeaderwithSelectAll">
                                                        <p className="HeaderTextLabel">Search Result</p>
                                                        <div>
                                                            <input type="checkbox"
                                                                checked={midLeftCheck}
                                                                disabled={topLeftCheck}
                                                                onChange={() => {
                                                                    setMidLeftCheck(!midLeftCheck)
                                                                    selectAllHandler('e', 'ONE')
                                                                }}
                                                            // checked={checkOne} onChange={(e) => selectAllHandler(e, "ONE")}
                                                            />
                                                            <p className="AllTextwithValue" onClick={() => {
                                                                    setMidLeftCheck(!midLeftCheck)
                                                                    selectAllHandler('e', 'ONE')
                                                                }}>
                                                                Select all <span>{totalPagesOne}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="SelectionListWithSelectAll">
                                                            <input
                                                                className="SearchViewInput1"
                                                                type="search"
                                                                placeholder={`Search unselected ${selectedEntity.identifier}`}
                                                                value={searchTextTwo}
                                                                disabled={topLeftCheck || midLeftCheck}
                                                                onChange={(e) => setSearchTextTwo(e.target.value)}
                                                            />
                                                            {
                                                                isDarkTheme ?
                                                                <img src={SearchIcon} alt="Search" onClick={searchHandlerTwo} style={{ cursor: 'pointer' }} /> :
                                                                <img src={SearchIconWhite} height="15" alt="Search" onClick={searchHandlerTwo} style={{ cursor: 'pointer' }} />

                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="HgtCheckBoxList">
                                                        <div>
                                                            <div className='dataAccessListItem'>
                                                                <input type="checkbox"
                                                                    disabled={topLeftCheck || midLeftCheck}
                                                                    checked={checkOne} onChange={(e) => selectAllHandler(e, "ONE")} />
                                                                <p className="AllTextwithValue" onClick={(e) => selectAllHandler(e, "ONE")}>
                                                                    Select all <span>{unselectedTempFiltered.length}</span>
                                                                </p>
                                                            </div>
                                                            {
                                                                unselectedTempFiltered.map((item) => (
                                                                    <DataAccessListItem isItDisabled={topLeftCheck || midLeftCheck} item={item} key={item.Id} selectItemHandler={selectItemHandler} type="ONE" />
                                                                ))
                                                            }
                                                        </div>
                                                    </div>

                                                    {
                                                        totalPagesOne === 0 || (totalPagesOne) <= limitOne
                                                        ? null
                                                        :   
                                                    <div className="PaginationDiv" style={{marginBottom: "5px", marginRight: "-6px"}}>
                                                        <h5 className='PageNumbers'>
                                                            {
                                                            totalPagesOne === 0 ? 0
                                                            :
                                                            (currentPageNoOne-1) * parseInt(limitOne) + parseInt(1)} - {(currentPageNoOne * limitOne) > (totalPagesOne) 
                                                            ? (totalPagesOne) 
                                                            : currentPageNoOne * limitOne}
                                                        </h5>
                                                          <span>of</span>
                                                          <h5 className='PageNumbers'>{totalPagesOne}</h5>
                                                          <Pagination>
                                                            <Pagination.Prev onClick={() => {
                                                                if(isLeftItemSelected && editMode && !adminNodeSelected){
                                                                    WarningToast("Kindly add the selected items before proceeding.")
                                                                    return;
                                                                }
                                                                prevClickOne();
                                                            }} disabled={topLeftCheck || midLeftCheck || currentPageNoOne == 1} />
                                                            {/* <Pagination.Ellipsis />                           */}
                                                              {
                                                                // PaginationComponentOne(pageCounterOne)
                                                                TempPaginationComponentOne(currentPageNoOne)
                                                              }             
                                                            {/* <Pagination.Ellipsis />                           */}
                                                            <Pagination.Next
                                                             onClick={() => {
                                                                if(isLeftItemSelected && editMode && !adminNodeSelected){
                                                                    WarningToast("Kindly add the selected items before proceeding.")
                                                                    return;
                                                                }
                                                                nextClickOne();
                                                                }} 
                                                                disabled={topLeftCheck || midLeftCheck || currentPageNoOne == maxPageAllowedOne} />
                                                          </Pagination>
                                                    </div>
                                                    }   
                                                </div>
                                            </div>

                                            <div className="AddRemoveBtnDiv">
                                                <div className='text-center'>
                                                    <Button variant="primary" className="ApplyBtn" onClick={addItemsToRight} disabled={adminNodeSelected || !editMode}>
                                                        Add <img src={AddSelected} alt='add plus icon' />
                                                    </Button>
                                                    <Button variant="primary" className="ApplyBtn" onClick={addItemsToLeft} disabled={adminNodeSelected || !editMode}>
                                                        <img src={RemoveSelected} alt='remove arrow' />  Remove
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className="TwoSelectionDiv">
                                                <div className="SelectAllSearchBarDiv">
                                                    <div className="HeaderwithSelectAll">
                                                        <p className="HeaderTextLabel">Selected {selectedEntity.name}</p>
                                                        <div>
                                                            <input type="checkbox" checked={topRightCheck} onChange={() => {
                                                                setTopRightCheck(!topRightCheck)
                                                                selectAllHandler("e", "TWO")

                                                            }} />
                                                            <p className="AllTextwithValue" onClick={() => {
                                                                setTopRightCheck(!topRightCheck)
                                                                selectAllHandler("e", "TWO")}}>
                                                                Select all <span>{selectedEntity.selectedCount}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="SelectionListWithSelectAll">
                                                            <input
                                                                className="SearchViewInput1"
                                                                type="search"
                                                                placeholder={`Search selected ${selectedEntity.identifier}`}
                                                                value={searchTextThree}
                                                                disabled={topRightCheck}
                                                                onChange={(e) => searchHandlerThree(e)}
                                                            // onChange={(e) => setSearchTextThree(e.target.value)}
                                                            />
                                                            {
                                                                isDarkTheme ?
                                                                <img src={SearchIcon} alt="Search" onClick={selectedGetMasterData} style={{ cursor: 'pointer' }} /> :
                                                                <img src={SearchIconWhite} height="15" alt="Search" onClick={selectedGetMasterData} style={{ cursor: 'pointer' }} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="MainResultDiv">
                                                    <div className="HeaderwithSelectAll">
                                                        <p className="HeaderTextLabel">Search Result</p>
                                                        <div>
                                                            <input type="checkbox"
                                                                checked={midRightCheck}
                                                                disabled={topRightCheck}
                                                                onChange={() => {
                                                                    setMidRightCheck(!midRightCheck)
                                                                    selectAllHandler("e", "TWO")
                                                                }}
                                                            // checked={checkTwo} onChange={(e) => selectAllHandler(e, "TWO")} 
                                                            />
                                                            <p className="AllTextwithValue"  onClick={() => {
                                                                    setMidRightCheck(!midRightCheck)
                                                                    selectAllHandler("e", "TWO")
                                                                }}>
                                                                Select all <span>{totalPagesTwo}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="SelectionListWithSelectAll">
                                                            <input
                                                                className="SearchViewInput1"
                                                                type="search"
                                                                disabled={topRightCheck || midRightCheck}
                                                                placeholder={`Search selected ${selectedEntity.identifier}`}
                                                                value={searchTextFour}
                                                                onChange={(e) => setSearchTextFour(e.target.value)}
                                                            />
                                                            {
                                                                isDarkTheme ?
                                                                <img src={SearchIcon} onClick={searchHandlerFour} alt="Search" style={{ cursor: 'pointer' }} /> :
                                                                <img src={SearchIconWhite} height="15" onClick={searchHandlerFour} alt="Search" style={{ cursor: 'pointer' }} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="HgtCheckBoxList">
                                                        <div>
                                                            <div className='dataAccessListItem'>
                                                                <input type="checkbox" disabled={topRightCheck || midRightCheck} checked={checkTwo} onChange={(e) => selectAllHandler(e, "TWO")} />
                                                                <p className="AllTextwithValue" onClick={(e) => selectAllHandler(e, "TWO")}>
                                                                    Select all <span>{selectedTempFiltered.length}</span>
                                                                </p>
                                                            </div>

                                                            {
                                                                selectedTempFiltered.map((item) => (
                                                                    <DataAccessListItem isItDisabled={topRightCheck || midRightCheck} item={item} key={item.Id} selectItemHandler={selectItemHandler} type="TWO" />
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                    {/* </div>
                                                    </div> */}
                                                    {
                                                        totalPagesTwo === 0 || totalPagesTwo <= limitTwo
                                                        ? null
                                                        :
                                                    <div className="PaginationDiv" style={{marginBottom: "5px", marginRight: "-6px"}}>
                                                        <h5 className='PageNumbers'>
                                                            {
                                                            totalPagesTwo == 0 ? 0
                                                            :    
                                                            (currentPageNoTwo-1)  * parseInt(limitTwo) + parseInt(1)} - {(currentPageNoTwo * limitTwo) > (totalPagesTwo) 
                                                            ? (totalPagesTwo) 
                                                            : currentPageNoTwo * limitTwo }
                                                        </h5>
                                                        <span>of</span>
                                                        <h5 className='PageNumbers'>{totalPagesTwo}</h5>
                                                        <Pagination>
                                                        <Pagination.Prev onClick={() => {
                                                            if(isRightItemSelected && editMode && !adminNodeSelected){
                                                                WarningToast("Kindly remove the selected items before proceeding.")
                                                                return;
                                                            }
                                                            prevClickTwo();
                                                        }} disabled={topRightCheck || midRightCheck || currentPageNoTwo == 1} />
                                                            {
                                                            //   PaginationComponentTwo(pageCounterTwo)
                                                            TempPaginationComponentTwo(currentPageNoTwo)
                                                            }                                     
                                                        <Pagination.Next onClick={() => {
                                                            if(isRightItemSelected && editMode && !adminNodeSelected){
                                                                WarningToast("Kindly remove the selected items before proceeding.")
                                                                return;
                                                            }
                                                            nextClickTwo();
                                                        }} disabled={topRightCheck || midRightCheck || currentPageNoTwo == maxPageAllowedTwo} />
                                                        </Pagination>
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ButtonDiv">
                                            {/* <Button variant="primary" className="ApplyBtn" onClick={() => {
                                                setIsEditCustomer(false)
                                                // let fname = groupName + "_" + localStorage.getItem('authCode');
                                                // getDataAccessDetails(finalChildId, finalParentId, fname, 1, "", "");
                                            }}
                                            disabled={adminNodeSelected} 
                                            >
                                                Apply
                                            </Button> */}
                                        </div>
                                    </div>
                                </Modal.Body>
                            </Modal>

                        </div>
                        :
                        null
                )
                )
            }
        </div>
    )
}

export default DataAccessControls;