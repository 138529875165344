import { BASE_CORE_URL, BASE_URL } from './../config/index'


export const getAlertActivityReport = async (obj) => {

    let url = `${BASE_URL}alertreport`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const getAlertArmDisarmReport = async (obj) => {

    let url = `${BASE_URL}alertreport`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const getStatusChangeReport = async (uniqueId, startDate, endDate, pageNumber, searchText) => {
    const requestOptions = {
        method: 'GET',
        headers: { 
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };
    let url = BASE_CORE_URL + "Report/GetReport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" +searchText;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("getStatusChangeReport", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

export const getExportStatus = async (uniqueId, startDate, endDate, pageNumber, searchText) => {
    const requestOptions = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "Report/GetReportExport?userUniqueId=" + uniqueId + "&fromDateTime=" + startDate + "&toDateTime=" + endDate + "&pageNumber=" + pageNumber + "&searchText=" +searchText;
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("getStatusChangeReport", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })        

}
