import React, { useState } from 'react'
import Swal from 'sweetalert2'
import { TickIcon, CancelIcon, EditQc, DeleteQc } from '../../assets/images'

export default function InlineEditItem({
    name,
    selectedItem,
    id,
    selectedItemId,
    onClick,
    onSave,
    onDelete
}) {
    const [isEdit, setIsEdit] = useState(false)
    const [text, setText] = useState(name)

    const onCancel = () => {
        if (text !== name) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to cancel the changes made?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#027aaa',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Leave!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setIsEdit(false)
                    setText(name)
                }
            })
        } else {
            setIsEdit(false)
        }
    }

    const onSaveGroup = () => {
        if (text.trim() == "") {
            Swal.fire({ text: "Please enter group name." })
            return
        }

        onSave(text, id).then((res) => {
            console.error({ res })
            if (res.status == "Ok") {
                setIsEdit(false)
            }
        })
    }
    return (
        <div>
            {
                !isEdit ?
                    <>
                        <div
                            className={`cameraList user-list-item  ${selectedItem && id == selectedItemId && "selected-user"}`}
                            key={id}
                            onClick={onClick} >
                            <p className="mb-0 pl-1">{text}</p>
                            <div className="float-right">
                                <img
                                    height={12}
                                    width={12}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setIsEdit(true)
                                    }}
                                    src={EditQc}
                                    className="m-1">
                                </img>
                                <img
                                    height={12}
                                    width={12}
                                    src={DeleteQc}
                                    className="m-1"
                                    title="Delete"
                                    onClick={(e) => onDelete(e, selectedItem)} />
                            </div>
                        </div>
                    </> :
                    <>
                        <div className="custom-input cameraList">
                            <input
                                placeholder="Enter name"
                                type="text"
                                value={text}
                                maxLength="40"
                                onChange={(e) => setText(e.target.value)}
                                onKeyUp={
                                    (e) => {
                                        if (e.code === 'Enter')
                                            onSaveGroup()
                                    }
                                }
                                className="inline-list-textbox" />
                            <div className="float-right">
                                <img
                                    height={14}
                                    width={12}
                                    onClick={() => onSaveGroup()}
                                    src={TickIcon}
                                    className="m-1 cursor-ptr">
                                </img>
                                <img
                                    height={10}
                                    width={12}
                                    onClick={() => onCancel()}
                                    src={CancelIcon}
                                    className="m-1 cursor-ptr">
                                </img>
                            </div>
                        </div>
                    </>
            }
        </div>
    )
}

