import React,{useState} from 'react';

// const initialState = []

export const DrawerContext = React.createContext();

const DrawerProvider = ({children}) => {
    // const [openDrawer, setOpenDrawer] = useState(false)
    const [collapsed, setCollapsed] = useState(false);
    
    return(
        <DrawerContext.Provider value={{collapsed,setCollapsed}}>{children}</DrawerContext.Provider>
    );
}

export default DrawerProvider;