import React from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { ReportIcon, DownloadIcon} from '../../assets/images';
import XLSX from 'xlsx'
import Swal from 'sweetalert2'
import NoReportFound from './NoReportFound'
import { Row, Col } from 'react-bootstrap'

export default function AlertArmDisarmReport(props) {
    const onDownloadReport = (e) => {
        e.stopPropagation();
        if (props.length == 0) {
            Swal.fire({ text: 'No records available to download.' })
            return
        }
        let addUpdateTable = document.getElementById('arm-disarm-activity-report')

        var wb = XLSX.utils.table_to_book(addUpdateTable, { sheet: "Added-Updated Records" });
        var ws_name = "Alert Activity"
        var ws_Error = XLSX.utils.table_to_sheet(addUpdateTable)
        return XLSX.writeFile(wb, 'Alert-Activity.xlsx');
    }
    return (
        <>
            <div className="ReportAccordionMainContainer">
                <Accordion defaultActiveKey="1">
                    <div className="ReportAccordionMainContainer">
                        <Accordion.Toggle eventKey="1" className="ReportAccordionHeader">
                            {/* Header Section */}
                            <>
                                <>
                                    {/* <div className="report-title-head"> */}
                                    <div>
                                        <Row>
                                            <Col lg={10}>
                                                <div className="ReportAccordianTitleIcon">
                                                    <img src={ReportIcon} alt="Report Icon" />
                                                </div>
                                                <div className="ReportAccordianTitleText">
                                                    <h5 className="mb-0">
                                                        {props.title}
                                                    </h5>
                                                    <p className="ReportSubTitleText">This Report Displays List of Closed Alerts</p>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <a className="ReportRightOptions" onClick={onDownloadReport}>
                                                    <img src={DownloadIcon} alt='download icon'/>
                                                    <p>Download</p>
                                                </a>
                                            </Col>
                                        </Row>
                                    </div>
                                </>
                                <div className="col-2 align-items-center justify-content-end">
                                    {/* <div className="ReportRightOptions">
                                    <img src="../Images/Share.png" />
                                    <p>Share</p>
                                </div>
                                <div className="ReportRightOptions">
                                    <img src="../Images/Download.png" />
                                    <p>Download</p>
                                </div> */}
                                </div>
                            </>
                        </Accordion.Toggle>
                        {/* <Accordion.Collapse eventKey="1"> */}
                        {/* Table Section */}
                        <>
                            {(props && props.list &&
                                <div className="card-body p-0">
                                    {(props.list.length > 0 &&
                                        <table className="table table-text-ext table-fixed report-table" id="alert-activity-report">
                                            <thead className="ReportTableHeader">
                                                <tr>
                                                    <th scope="col">Alert Title</th>
                                                    <th scope="col">Branch</th>
                                                    <th scope="col">Panel Name</th>
                                                    <th scope="col">Site Name</th>
                                                    <th scope="col">Source</th>
                                                    <th scope="col">Generated at</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(
                                                    props.list &&
                                                    props.list.map((item, index) =>
                                                        <ArmDisarmReportTable
                                                            key={item.alertId}
                                                            id={item.alertId}
                                                            props={item} />
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    )}
                                    {
                                        props.list.length == 0 &&
                                        <NoReportFound />
                                    }
                                </div>
                            )}
                        </>
                        {/* </Accordion.Collapse> */}
                    </div>
                </Accordion>
            </div>
        </>
    )
}

const ArmDisarmReportTable = ({ props }) => {
    return <>
        <tr>
            <td>{props.alertType}</td>
            <td>{props.branch}</td>
            <td>{props.panelName}</td>
            <td className="ReportTableFontBold">{props.siteName}</td>
            <td>{props.source}</td>
            <td>{props.actualTime}</td>
        </tr>
    </>
}