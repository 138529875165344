import React, { useEffect, useState, useRef } from 'react';
import { BackToLive, MosaicExitFullscreen, MosaicFullscreen, MosaicHooterOff, MosaicHooterOn, MosaicNoStream, MosaicSnapshot, MosaicTwowayStart, MosaicTwowayStop, Pause, Play, PlayBack, PlayBackWhite, PrePost, Rewind, Save, Video_Export } from '../../assets/images';
import { Modal } from 'react-bootstrap'
import PopupCard from './PopupCard';
import screenfull from 'screenfull'
import { ConvertDate } from '../../utils/utils';
import { addMinutes, subMinutes, differenceInMinutes } from 'date-fns'
import { WarningToast } from '../../CustomHooks/WarningToast';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { HooterOnOffClick, TwoWayCommunicationRequest, VideoExport } from '../../services/alertAuthService';
import { CAMERA_SINGLE_PLAYBACK } from '../../navigation/CONSTANTS';
import { useHistory } from 'react-router-dom'
import { getstreamingurl } from '../../services/chatFlowService';
import { addDownload } from '../../services/downloadService';
import { SuccessToast } from '../../CustomHooks/SuccessToast';
import { HIDE_STUFF } from '../../config';
import { usePageVisibility } from '../../CustomHooks/usePageVisibility';

const WAIT_TIME = 20000;
const RETRY_TIME = 60000;

const WebRTCPlayer = ({ id, url, isEncode, upperDivCss, videoCss, item, fullVideoCss, buttonCss, 
  actualTime, alertDetail, playback, prePostDisplay, liveButton, isSinglePlayback, noStreamDivCss, 
  exportCurrentVideoHide, videoExportDownload, exportStartTime, exportEndTime, isMultiPlay, differenceSecondsMain, secondsDiff, showPlaybackBtn, devId, hideTwoWay, imageToChat, showPrePostReplay }) => {
  let connect_attempts = 0;
  let peer_connection;
  let send_channel;
  let ws_conn;
  let peer_id
  let ws_port = '8444';
  // let ws_server = 'localhost'
  let ws_server = '127.0.0.1'
  let local_stream_promise;
  let msg;
  let sdp;
  let textarea;
  let setError;
  let scaleFactor = 0.25;
  let snapshots = [];

  // var rtc_configuration = {
  //     iceServers: [{ urls: "stun:stun.services.mozilla.com" },
  //     { urls: "stun:stun.l.google.com:19302" }]
  // };
  var rtc_configuration = { iceServers: [] };
  var default_constraints = { video: false, audio: true };

  let history = useHistory()
  const [played, setPlayed] = useState(0);
  const [videoId, setVideoId] = useState(id);
  const [liveId, setLiveId] = useState(id);
  const [videoUrl, setVideoUrl] = useState(url);
  const [liveUrl, setLiveUrl] = useState(url);
  const [liveMode, setLiveMode] = useState(true);
  const [playbackMode, setPlaybackMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [tempEvent, setTempEvent] = useState(null);
  const [popOpen, setPopOpen] = useState(false);
  const [snapModal, setSnapModal] = useState(false);
  const [mode, setMode] = useState("");
  const [isSoundOn, setIsSoundOn] = useState(true);
  const [isFullscreenClick, setIsFullscreenClick] = useState(false);
  const videoContainerParentRef = useRef(null);
  const videoContainerRef = useRef(null);
  const [date, setDate] = useState(subMinutes(new Date(), 15));
  const [dateString, setDateString] = useState("");
  const [dateStringTwo, setDateStringTwo] = useState("");
  const [timeString, setTimeString] = useState("");
  const [timeStringTwo, setTimeStringTwo] = useState("");
  const [timeStringMain, setTimeStringMain] = useState("+15 min");
  const [timeStringMainTwo, setTimeStringMainTwo] = useState("+15 min");
  const [differenceTime, setDifferenceTime] = useState(15);
  const [differenceTimeTwo, setDifferenceTimeTwo] = useState(15);
  const [blankDate, setBlankDate] = useState("");
  const [blankDateTwo, setBlankDateTwo] = useState("");
  const [dateTwo, setdateTwo] = useState(new Date());
  const [isAllinOneHooterOn, setIsAllinOneHooterOn] = useState(false);
  const [streamFound, setStreamFound] = useState(true);
  const [helperState, setHelperState] = useState(false);
  const [reconnectState, setReconnectState] = useState(false);
  const [webRTCexportStartTime, setWebRTCExportStartTime] = useState(exportStartTime);
  const [webRTCexportEndTime, setWebRTCExportEndTime] = useState(exportEndTime)
  const [webRTCExportCurrentVideoHide, setWebRTCExportCurrentVideoHide] = useState(exportCurrentVideoHide);
  const [runningTime, setRunningTime] = useState("00:00:00");
  const [totalTime, setTotalTime] = useState("00:15:00")
  const [webSocketState, setWebSocketState] = useState(null);
  const [timer, setTimer] = useState(0);  
  const [isPaused, setIsPaused] = useState(false);
  const [isOver, setIsOver] = useState(false);
  const [differenceInSeconds, setDifferenceInSeconds] = useState(900);
  const [timerInt, setTimerInt] = useState(null)
  const [pauseTimer, setPausedTimer] = useState(null);

  const isVisible = usePageVisibility()

  useEffect(() => {
    if(isSinglePlayback && webSocketState != null){
        if(isVisible){
          webSocketState.send("Play")
          setIsPaused(false)
        }else{
          webSocketState.send("Pause");
          setIsPaused(true)
        }
    }
  }, [isVisible]);

  useEffect(() => {
    if(isSinglePlayback){
      if(isPaused){
        clearTimeout(pauseTimer);
        console.log("Paused and now time also started");
        let pausedTimer = setTimeout(() => {
          console.log("Paused for more then 1min 30 sec");
          setIsOver(true);
        }, 90000);
        setPausedTimer(pausedTimer);
      
        // return () => {
        //   clearTimeout(pausedTimer)
        // }
      }else{
        console.log("Paused removed and now playing");
        clearTimeout(pauseTimer);
      }
    }
  }, [isPaused])

    console.log("isTranscoded", isEncode);
    
  useEffect(() => {
    webSocketServerConnect();

    return () => {
      ws_conn.close();
      resetVideo();
      if (peer_connection) {
        peer_connection.close();
        peer_connection = null;
      }
    }
  }, [videoUrl])

  useEffect(() => {
    let min = secondsDiff / 60;
    let str;
    if(min < 10){
      str = `00:0${min}:00`;
    }else{
      str = `00:${min}:00`;
    }
    setTotalTime(str);
  }, [secondsDiff]);

  const goBackToLive = () => {
    setVideoId(liveId);
    setVideoUrl(liveUrl);
    setLiveMode(true);
    setPlaybackMode(false);
    setWebRTCExportCurrentVideoHide(true);
  }

  const restartPrePost = () => {
    let randomNum = Math.floor(Math.random() * 100);    
    reConnectionTwo(videoId+""+randomNum);
  }

  const webSocketServerConnect = () => {
    setLoading(true);
    console.log("webSocketServerConnect", new Date());
    connect_attempts++;
    if (connect_attempts > 3) {
      // setError("Too many connection attempts, aborting. Refresh page to try again");
      return;
    }
    // Fetch the peer id to use
    peer_id = videoId;

    let ws_url = 'ws://' + ws_server + ':' + ws_port

    ws_conn = new WebSocket(ws_url);
    setWebSocketState(ws_conn);
    // ws_conn = new WebSocket(ws_url, {
    //     protocolVersion: 8,
    //     origin: ws_url,
    //     rejectUnauthorized: true
    // });


    // ws_conn = new WebSocket();
    // ws_conn.connect(ws_url, null, null, null, {rejectUnauthorized: false});

    /* When connected, immediately register with the server */
    console.log("ChatWebRTC", videoUrl, peer_id, isEncode);
    ws_conn.addEventListener('open', (event) => {
      ws_conn.send('HELLO ' + peer_id + " " + videoUrl + ' ' + isEncode + ' 0');
    });
    ws_conn.addEventListener('error', onServerError);
    ws_conn.addEventListener('message', onServerMessage);
    ws_conn.addEventListener('close', onServerClose);
  }

  const webSocketServerConnectTwo = (localId) => {
    setLoading(true);
    console.log("webSocketServerConnect", new Date());
    connect_attempts++;
    if (connect_attempts > 3) {
      // setError("Too many connection attempts, aborting. Refresh page to try again");
      return;
    }
    // Fetch the peer localId to use
    peer_id = localId;

    let ws_url = 'ws://' + ws_server + ':' + ws_port

    ws_conn = new WebSocket(ws_url);
    setWebSocketState(ws_conn);
    // ws_conn = new WebSocket(ws_url, {
    //     protocolVersion: 8,
    //     origin: ws_url,
    //     rejectUnauthorized: true
    // });


    // ws_conn = new WebSocket();
    // ws_conn.connect(ws_url, null, null, null, {rejectUnauthorized: false});

    /* When connected, immediately register with the server */
    ws_conn.addEventListener('open', (event) => {
      ws_conn.send('HELLO ' + peer_id + " " + videoUrl + ' ' + isEncode + ' 0');
    });
    ws_conn.addEventListener('error', onServerError);
    ws_conn.addEventListener('message', onServerMessage);
    ws_conn.addEventListener('close', onServerClose);
  }

  const onServerError = (event) => {
  }

  const onServerMessage = (event) => {
    //debugger
    switch (event.data) {
      case "HELLO":
        ws_conn.send('SESSION ' + videoId);
        return;
      case "SESSION_OK":
        return;
      default:
        if (event.data.startsWith("ERROR")) {
          handleIncomingError(event.data);
          return;
        }
        if (event.data.startsWith("OFFER_REQUEST")) {
          //debugger
          // The peer wants us to set up and then send an offer
          if (!peer_connection) {
            peer_connection = new RTCPeerConnection(rtc_configuration);
            send_channel = peer_connection.createDataChannel('label', null);
            send_channel.onopen = handleDataChannelOpen;
            send_channel.onmessage = handleDataChannelMessageReceived;
            send_channel.onerror = handleDataChannelError;
            send_channel.onclose = handleDataChannelClose;
            peer_connection.ondatachannel = onDataChannel;
            peer_connection.ontrack = onRemoteTrack;
            local_stream_promise = getLocalStream().then((stream) => {
              //debugger
              peer_connection?.addStream(stream);
              return stream;
            }).catch(setError)
          }
          // createCall(null).then(generateOffer);

        }
        else {
          // Handle incoming JSON SDP and ICE messages
          try {
            msg = JSON.parse(event.data);
          } catch (e) {
            if (e instanceof SyntaxError) {
              handleIncomingError("Error parsing incoming JSON: " + event.data);
            } else {
              handleIncomingError("Unknown error parsing response: " + event.data);
            }
            return;
          }

          // Incoming JSON signals the beginning of a call
          if (!peer_connection) {
            peer_connection = new RTCPeerConnection(rtc_configuration);
            send_channel = peer_connection.createDataChannel('label', null);
            send_channel.onopen = handleDataChannelOpen;
            send_channel.onmessage = handleDataChannelMessageReceived;
            send_channel.onerror = handleDataChannelError;
            send_channel.onclose = handleDataChannelClose;
            peer_connection.ondatachannel = onDataChannel;
            peer_connection.ontrack = onRemoteTrack;
            local_stream_promise = getLocalStream().then((stream) => {
              //debugger
              peer_connection?.addStream(stream);
              return stream;
            }).catch(setError)
          }

          // createCall(msg);

          if (msg.sdp != null) {
            onIncomingSDP(msg.sdp);
          } else if (msg.ice != null) {
            onIncomingICE(msg.ice);
          } else {
            handleIncomingError("Unknown incoming JSON: " + msg);
          }
        }
    }
  }

  const generateOffer = () => {
    //debugger
    peer_connection.createOffer().then(onLocalDescription).catch(setError);
  }

  const onIncomingSDP = (sdp) => {
    //debugger
    peer_connection.setRemoteDescription(sdp).then(() => {
      if (sdp.type != "offer")
        return;
      peer_connection.createAnswer();
      local_stream_promise.then((stream) => {
        peer_connection?.createAnswer()
          .then(onLocalDescription).catch(setError);
      }).catch(setError);
    }).catch(setError);
  }

  const onLocalDescription = (desc) => {
    //debugger
    peer_connection?.setLocalDescription(desc).then(function () {
      sdp = { 'sdp': peer_connection.localDescription }
      ws_conn.send(JSON.stringify(sdp));
    });
  }

  const onIncomingICE = (ice) => {
    ////debugger
    let candidate = new RTCIceCandidate(ice);
    peer_connection.addIceCandidate(candidate).catch(setError);
  }

  const onServerClose = (event) => {
    resetVideo();
    if (peer_connection) {
      peer_connection.close();
      peer_connection = null;
    }

    // Reset after a second
    // window.setTimeout(webSocketServerConnect, 1000);
  }

  const resetVideo = () => {
    if (local_stream_promise)
      local_stream_promise.then(stream => {
        if (stream) {
          stream.getTracks().forEach(function (track) { track.stop(); });
        }
      });

    // Reset the video element and stop showing the last received frame
    var videoElement = getVideoElement();
    if (videoElement != null) {
      videoElement.pause();
      videoElement.src = "";
      videoElement.load();
    }
  }

  const getVideoElement = () => {
    ////debugger
    return document.getElementById(videoId);
  }

  const handleIncomingError = (error) => {
    resetState();
  }

  const resetState = () => {
    ws_conn.close();
  }

  const playVideo = () => {
    // let vid = getVideoElement();
    // vid.play();
    webSocketState.send("Play")
    setIsPaused(false);
  }

  const pauseVideo = () => {
    webSocketState.send("Pause");
    setIsPaused(true);
  }

  const restartVideo = () => {
    if(isOver){
      let randomNum = Math.floor(Math.random() * 100);
      reConnectionTwo(videoId+""+randomNum);
      setIsOver(false);
    }else{
      if(isPaused){
        webSocketState.send("Play")
        setIsPaused(false)
      }
      webSocketState.send("Replay")
      let date = new Date(null);
      date.setSeconds(0);
      let hhmmssFormat = date.toISOString().substring(11, 19);
      setRunningTime(hhmmssFormat)
      setTimer(0)
    }
  }

  useEffect(() => {
  }, [isOver]);

  const createCall = (msg) => {
    connect_attempts = 0;

    peer_connection = new RTCPeerConnection(rtc_configuration);
    send_channel = peer_connection.createDataChannel('label', null);
    send_channel.onopen = handleDataChannelOpen;
    send_channel.onmessage = handleDataChannelMessageReceived;
    send_channel.onerror = handleDataChannelError;
    send_channel.onclose = handleDataChannelClose;
    peer_connection.ondatachannel = onDataChannel;
    peer_connection.ontrack = onRemoteTrack;
    /* Send our video/audio to the other peer */
    local_stream_promise = getLocalStream().then((stream) => {
      //debugger
      peer_connection.addStream(stream);
      return stream;
    }).catch(setError)

    if (msg != null && !msg.sdp) {
      console.log("WARNING: First message wasn't an SDP message!?");
    }

    peer_connection.onicecandidate = (event) => {
      // We have a candidate, send it to the remote party with the
      // same uuid
      //debugger
      if (event.candidate == null) {
        //console.log("ICE Candidate was null, done");
        //return;
      } else {

        ws_conn.send(JSON.stringify({ 'ice': event.candidate }));
      }
    };


    if (msg != null)
      console.log("Created peer connection for call, waiting for SDP");

    //generateOffer();
    return local_stream_promise;
  }

  const handleDataChannelOpen = (event) => {
    //debugger
  };

  const handleDataChannelMessageReceived = (event) => {
    //debugger

    if (typeof event.data === 'string' || event.data instanceof String) {
      textarea = document.getElementById("text")
      textarea.value = textarea.value + '\n' + event.data
    } else {
    }
    send_channel.send("Hi! (from browser)");
  };

  const handleDataChannelError = (error) => {
    //debugger
  };

  const handleDataChannelClose = (event) => {
    //debugger
  };

  function onDataChannel(event) {
    //debugger
    let receiveChannel = event.channel;
    receiveChannel.onopen = handleDataChannelOpen;
    receiveChannel.onmessage = handleDataChannelMessageReceived;
    receiveChannel.onerror = handleDataChannelError;
    receiveChannel.onclose = handleDataChannelClose;
  }

  function onRemoteTrack(event) {
    console.log("webSocketServerConnect", new Date());
    setLoading(false);
    setTempEvent(event);
    // if (getVideoElement().srcObject !== event.streams[0]) {
    //   ////debugger
    //   getVideoElement().srcObject = event.streams[0];
    // }
  }

  useEffect(() => {
    if (!loading) {
      if (getVideoElement()?.srcObject !== tempEvent?.streams[0]) {
      // if (getVideoElement()?.srcObject !== tempEvent.streams[0]) {
        // if (null.srcObject !== tempEvent.streams[0]) {
        setTimer(0)
        let date = new Date(null);
        date.setSeconds(0);
        let hhmmssFormat = date.toISOString().substring(11, 19);
        setRunningTime(hhmmssFormat)
        setIsOver(false);
        if(getVideoElement() != null){
          getVideoElement().srcObject = tempEvent?.streams[0];
        }
        // getVideoElement().srcObject = tempEvent?.streams[0];
      }
    }
  }, [loading]);

  function getLocalStream() {
    //debugger
    var constraints;
    try {
      constraints = default_constraints;
    } catch (e) {
      console.error(e);
      constraints = default_constraints;
    }

    //return null;
    // Add local stream
    if (navigator.mediaDevices.getUserMedia) {
      return navigator.mediaDevices.getUserMedia(constraints);
    } else {
      errorUserMediaHandler();
    }
  }

  function errorUserMediaHandler() {
    //debugger
  }

  const handleFullScreen = () => {
    screenfull.toggle(videoContainerParentRef.current)
    setIsFullscreenClick(!screenfull.isFullscreen);
  }

  useEffect(() => {
    document.addEventListener('fullscreenchange', exitHandler);
    function exitHandler() {
      if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
        setIsFullscreenClick(false);
      } else {
      }
    }
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
    }
  }, [isFullscreenClick]);


  const dateChange = (v, e) => {
    let d = ConvertDate(v);
    let temp = d.slice(0, 10).replace(/-/g, '_');
    setDateString(temp);
    setDateStringTwo(temp);
    setDate(v);
    setdateTwo(v);
  }

  const getDataFromChild = (data) => {
    let timeStringMain = "";

    if (data === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = data;
    }
    let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let currentTime = new Date();
    const difference = differenceInMinutes(currentTime, SD);
    if (difference < 15 && difference >= 0) {
      WarningToast("Selected Time is less than 15 min !")

      timeStringMain = "+" + difference + " min ";
      setDifferenceTime(parseInt(difference))
      let difSec = parseInt(difference) * 60;
      setDifferenceInSeconds(difSec);
      setTimeStringMain(timeStringMain);
    } else {
      setDifferenceTime(15);
      setDifferenceInSeconds(900);
      setTimeStringMain("+15 min");
    }

    setTimeString(data);
  }

  useEffect(() => {
    // let d = new Date();
    let d = subMinutes(new Date(), 15);
    let temp = ConvertDate(d);
    let temp_date = temp.slice(0, 10).replace(/-/g, '_');
    setDateString(temp_date);
  }, []);

  useEffect(() => {
    // let d = new Date();
    let d = new Date();
    let temp = ConvertDate(d);
    let temp_date = temp.slice(0, 10).replace(/-/g, '_');
    setDateStringTwo(temp_date);
  }, []);

  const getDataFromChildTwo = (data) => {
    let timeStringMainTwo = "";

    if (data === "") {
      let blankArr = blankDateTwo.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMainTwo = blankStr;
    } else {
      timeStringMainTwo = data;
    }
    let temp = dateStringTwo + '_' + timeStringMainTwo;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let currentTime = new Date();
    const difference = differenceInMinutes(currentTime, SD);
    if (difference < 15 && difference >= 0) {
      WarningToast("Selected Time is less than 15 min !")

      timeStringMainTwo = difference + " min ";
      setDifferenceTimeTwo(parseInt(difference))
      setTimeStringMainTwo(timeStringMain);
    } else {
      setDifferenceTimeTwo(15);
      setTimeStringMainTwo("15 min");
    }

    setTimeStringTwo(data);
  }

  const onSubmitBtn = () => {
    let timeStringMain = "";
    if (timeString === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = timeString;
    }
    let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);

    let ED = addMinutes(SD, differenceTime);
    let toDateTime = ConvertDate(ED);

    let currentTime = new Date();

    // let fifteenMinutesAgo = (currentTime, -15);
    // if (SD >= fifteenMinutesAgo) {
    //   // Show a toast message
    //   showErrorToast('The time should be selected 15 minutes backwards from the current time!');
    // }
    //currentTime =addMinutes(currentTime, 15)
    if (differenceTime == 0) {
      ErrorToast('The selected time is  greater from the current time!')
      return;
    }
    //const difference = differenceInMinutes(currentTime, SD);
    if (SD > currentTime && differenceTime > 0) {
      ErrorToast('The selected time is  greater from the current time!')
      return;
    } else {
      let tempState = {
        startTime: temp,
        endTime: toDateTime.replace(/[ :-]/g, '_'),
        deviceId: item.ParentCameraID,
        cameraName: item.CameraName || item.cameraName,
        siteName: item.SiteName,
        dateTimeOne: SD,
        dateTimeTwo: ED,
        streamName: item.StreamName,
        isTranscoded: item.isTranscoded
      }

      if (playback) {
        setWebRTCExportStartTime(temp);
        setWebRTCExportEndTime(toDateTime.replace(/[ :-]/g, '_'));
        let obj = {
          "action": "StartPlaybackUrl",
          "startTime": temp,
          "endTime": toDateTime.replace(/[ :-]/g, '_'),
          "deviceId": item.ParentCameraID || devId,
          "alertId": ""
        }

        getstreamingurl(obj)
          .then((res) => {
            if (res.status == "Ok") {
              setVideoId(id + "8");
              setVideoUrl(res.rtspURL);
              // setVideoUrl("rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=2%38subtype=1");
              setPopOpen(false);
              setPlaybackMode(true);
              setWebRTCExportCurrentVideoHide(false);
              setLiveMode(false);
            }
          })
          .catch(err => console.log(err))
      } else {
        // For Single Page Route
        console.log("isTranscoded", item.isTranscoded)
        history.push({
          pathname: CAMERA_SINGLE_PLAYBACK,
          state: {
            startTime: temp,
            endTime: toDateTime.replace(/[ :-]/g, '_'),
            deviceId: item.ParentCameraID,
            cameraName: item.CameraName,
            siteName: item.SiteName,
            dateTimeOne: SD,
            dateTimeTwo: ED,
            streamName: item.StreamName,
            isTranscoded: item.isTranscoded,
            item: item,
            secondsDiff: differenceInSeconds
          }
        });

      }
    }
  }

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  const onExportBtn = () => {
    // console.log("onExportBtn", timeString);
    // console.log("onExportBtn", dateString);
    // console.log("onExportBtn", timeStringTwo);
    // console.log("onExportBtn", dateStringTwo);
    // console.log("onExportBtn", blankDate)
    // console.log("onExportBtn", blankDateTwo)

    let timeStringMain = "";
    if (timeString === "") {
      let blankArr = blankDate.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMain = blankStr;
    } else {
      timeStringMain = timeString;
    }
    let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let startD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
    let SD = formatDate(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

    let timeStringMainTwo = "";
    if (timeStringTwo === "") {
      let blankArr = blankDateTwo.split("_");
      for (let i = 0; i < blankArr.length; i++) {
        if (blankArr[i].length == 1) {
          blankArr[i] = "0" + blankArr[i].toString();
        }
      }
      let blankStr = blankArr.join("_");
      timeStringMainTwo = blankStr;
    } else {
      timeStringMainTwo = timeStringTwo;
    }
    let tempTwo = dateStringTwo + '_' + timeStringMainTwo;
    let arrTwo = tempTwo.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let endD = new Date(arrTwo[0], arrTwo[1] - 1, arrTwo[2], arrTwo[3], arrTwo[4], arrTwo[5])
    let ED = formatDate(new Date(arrTwo[0], arrTwo[1] - 1, arrTwo[2], arrTwo[3], arrTwo[4], arrTwo[5]));
    let currentTime = new Date();

    let diff = differenceInMinutes(endD, startD);
    let current = new Date();

    if (startD > current) {
      ErrorToast('Invalid timings, start date time is greater than current time.')
      return;
    }

    if (endD > current) {
      ErrorToast('Invalid timings, end date time is greater than current time.')
      return;
    }


    if (diff > 15) {
      ErrorToast('Max limit for downloading of video is 15 mins')
      return;
    }else if (diff < 0) {
      ErrorToast('Invalid timings')
      return;
    } else if (diff == 15 && SD.slice(-2) != ED.slice(-2)) {
      ErrorToast('Max limit for downloading of video is 15 mins')
      return;
    }

    if (differenceTimeTwo == 0) {
      ErrorToast('The selected time is greater from the current time!')
      return;
    }

    //const difference = differenceInMinutes(currentTime, SD);
    if (SD > currentTime && differenceTimeTwo > 0) {
      ErrorToast('The selected time is greater from the current time!')
      return;
    } else {
      // Here will need to call the API to initiate download for the video with the parameters of its start time and end time.

      let user = JSON.parse(localStorage.getItem('user'))
      let currentDate = formatDate(new Date());
      console.log("ExportBtn", currentDate);
      let obj = {
        VideoExportLogID: "1",
        RequestedBy: user.UserUniqueID,
        RequestedByName: user.FullName,
        ExportRequestedOn: currentDate,
        DeviceID: item.ParentCameraID.toString(),
        DeviceName: item.CameraName,
        ExportStatus: "0",
        ExportStatusDateTime: "",
        ExportStatusLog: "",
        DownloadLink: "",
        DownloadStatus: "",
        DownloadDateTime: "",
        DeleteFlag: "0",
        ExportFromDateTime: SD,
        ExportToDateTime: ED
      }
      console.log("ExportBtn", obj)
      addDownload(obj)
        .then((res) => {
          if (res.Status == "success") {
            SuccessToast(`The download request has been raised successfully. `)
            setPopOpen(false);
          }
        }).catch(err => console.log(err));
    }
  }

  const downloadDirectPlayback = () => {
    let arr = webRTCexportStartTime.split("_");
    let startD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5])
    let SD = formatDate(new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]));

    let arrTwo = webRTCexportEndTime.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let endD = new Date(arrTwo[0], arrTwo[1] - 1, arrTwo[2], arrTwo[3], arrTwo[4], arrTwo[5])
    let ED = formatDate(new Date(arrTwo[0], arrTwo[1] - 1, arrTwo[2], arrTwo[3], arrTwo[4], arrTwo[5]));


    let user = JSON.parse(localStorage.getItem('user'))
    let currentDate = formatDate(new Date());
    let obj = {
      VideoExportLogID: "1",
      RequestedBy: user.UserUniqueID,
      RequestedByName: user.FullName,
      ExportRequestedOn: currentDate,
      DeviceID: item?.ParentCameraID?.toString() || id.toString(),
      DeviceName: item?.CameraName || item?.cameraName,
      ExportStatus: "0",
      ExportStatusDateTime: "",
      ExportStatusLog: "",
      DownloadLink: "",
      DownloadStatus: "",
      DownloadDateTime: "",
      DeleteFlag: "0",
      ExportFromDateTime: SD,
      ExportToDateTime: ED
    }
    console.log("ExportBtn", obj)
    addDownload(obj)
      .then((res) => {
        if (res.Status == "success") {
          SuccessToast(`The download request has been raised successfully. `)
          setPopOpen(false);
        }
      }).catch(err => console.log(err));
  }

  const videoExportOnClick = async (privateIPAddress, httpPort, user, password, BrandName, startDateTime, endDateTime) => {
    //Response Data:  https://localhost:6001//VideoTempFiles/video_2023_11_13 00_00_00_2023_11_13 00_05_00.mp4
    // https://localhost:6001//VideoTempFiles/video_2023_11_13 00_00_00_2023_11_13 00_05_00.mp4
    //debugger;
    try {
      if (privateIPAddress && httpPort && user && password) {
        const resp = await VideoExport(BrandName, privateIPAddress, httpPort, user, password, startDateTime, endDateTime);

        // Handle video download
        var videoUrl = resp.Data;
        downloadVideo(videoUrl);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const downloadVideo = (videoUrl) => {
    //debugger
    // var videoUrl = "data:video/mp4;base64," + videoUrl;
    fetch(videoUrl)
      .then(response => {
        return response.blob();
      })
      .then(blob => {
        var link = window.document.createElement("a");
        link.href = window.URL.createObjectURL(blob, { type: "video/mp4" });
        link.download = "downloaded_video.mp4";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(error => {
        console.error("Error downloading video:", error);
      });

  };

  function capture(video, scaleFactor) {
    if (scaleFactor == null) {
      scaleFactor = 1;
    }
    // let w = video.videoWidth * scaleFactor;
    // let h = video.videoHeight * scaleFactor;
    console.log("snap", video.videoWidth);
    console.log("snap", video.videoHeight);
    let h = video.videoHeight
    let w = video.videoWidth
    // let h = video.videoHeight > 700 ? 480 : video.videoHeight
    // let w = video.videoWidth > 750 ? 640 : video.videoWidth
    // let h = 500
    // let w = 540
    let canvas = document.createElement('canvas');
    canvas.setAttribute("id", "canvasId")
    canvas.width = w;
    canvas.height = h;
    let ctx = canvas.getContext('2d');
    let blobd = canvas.toBlob((blob) => {
      console.log("Canvas", blob)
    });
    let url = canvas.toDataURL();
    // canvas.toBlob() = (blob) => {
    //   const img = new Image();
    //   img.src = window.URL.createObjectURL(blob);
    //   console.log("Canvas", img.src);
    // }
    ctx.drawImage(video, 0, 0, w, h);
    return canvas;
  }

  const downloadImage = () => {
    let canvas = document.getElementById('canvasId');
    let image = canvas.toDataURL();
    let base64 = canvas.toDataURL("image/png").split(';base64,')[1];
    console.log("base64", base64)
    let aDownloadLink = document.createElement('a');
    aDownloadLink.download = `${item.CameraName || item.cameraName  || item.deviceName}_snapshot.png`;
    aDownloadLink.href = image;
    aDownloadLink.click();
  }

  const generateSnapInChat = () => {
    let canvas = document.getElementById('canvasId');
    let image = canvas.toDataURL();
    // let base64 = canvas.toDataURL("image/png").split(';base64,')[1];
    let fileName = `${item.CameraName || item.cameraName || item.deviceName}_${Date.now()}_snapshot.png`
    // imageToChat(image, `${item.CameraName || item.cameraName  || item.deviceName}_snapshot.png`);
    imageToChat(image, fileName);
  }

  /**
   * Invokes the <code>capture</code> function and attaches the canvas element to the DOM.
   */
  function shoot() {
    // setSnapModal(true);
    var video = document.getElementById(videoId);
    var output = document.getElementById('output');
    var canvas = capture(video, scaleFactor);

    output.innerHTML = '';
    output.append(canvas);
  }

  const HooterOnOffClickRequest = (pDeviceId, pMode) => {
    HooterOnOffClick(pDeviceId, pMode)
      .then((res) => {
        if (res.Status == "success") {
          if (pMode == 'on') {
            setIsAllinOneHooterOn(true)
          } else {
            setIsAllinOneHooterOn(false)
          }
        }
      }).catch(err => console.log(err));
  }

  useEffect(() => {
    if (snapModal) {
      if (isFullscreenClick) {
        handleFullScreen();
      }
      shoot();
    }
  }, [snapModal]);


  const prePostHandler = () => {
    let DeviceId = item.associatedCameraId || item.deviceId
    let DeviceBrandId = item.cameraBrandId

    let split1 = actualTime.split(' ')
    let split2 = split1[0].split('-')

    let newdateSplit = `${split2[2]}-${split2[1]}-${split2[0]} ${split1[1]}`
    let alerttime = new Date(newdateSplit)


    // let preposttime = 3600 //1800 sec 30min
    let pretime = 60;
    let posttime = 120 //1800 sec 30min
    alerttime.setSeconds(alerttime.getSeconds() - pretime)
    let styy = alerttime.getFullYear()
    let stmm = alerttime.getMonth() + 1
    let stdd = alerttime.getDate()
    let sth = alerttime.getHours()
    let stm = alerttime.getMinutes()
    let sts = alerttime.getSeconds()

    alerttime.setSeconds(alerttime.getSeconds() + pretime + posttime)
    let etyy = alerttime.getFullYear()
    let etmm = alerttime.getMonth() + 1
    let etdd = alerttime.getDate()
    let eth = alerttime.getHours()
    let etm = alerttime.getMinutes()
    let ets = alerttime.getSeconds()

    if (stmm.toString().length == 1) { stmm = "0" + stmm.toString() }
    if (stdd.toString().length == 1) { stdd = "0" + stdd.toString() }
    if (sth.toString().length == 1) { sth = "0" + sth.toString() }
    if (stm.toString().length == 1) { stm = "0" + stm.toString() }
    if (sts.toString().length == 1) { sts = "0" + sts.toString() }

    if (etmm.toString().length == 1) { etmm = "0" + etmm.toString() }
    if (etdd.toString().length == 1) { etdd = "0" + etdd.toString() }
    if (eth.toString().length == 1) { eth = "0" + eth.toString() }
    if (etm.toString().length == 1) { etm = "0" + etm.toString() }
    if (ets.toString().length == 1) { ets = "0" + ets.toString() }

    let startTime;
    let endTime;
    if (DeviceBrandId == 1) {
      startTime = styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + "00"
      endTime = etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + "00"
    } else if (DeviceBrandId == 2) {
      startTime = styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + "00"
      endTime = etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + "00"
    } else {
      startTime = styy + "_" + stmm + "_" + stdd + "_" + sth + "_" + stm + "_" + "00"
      endTime = etyy + "_" + etmm + "_" + etdd + "_" + eth + "_" + etm + "_" + "00"
    }
    setWebRTCExportStartTime(startTime);
    setWebRTCExportEndTime(endTime);
    let obj = {
      "action": "StartPlaybackUrl",
      "startTime": startTime,
      "endTime": endTime,
      // "endTime": "2021_12_13_12_22_00",
      // "startTime": "2021_12_13_12_18_00",
      "deviceId": DeviceId,
      "alertId": alertDetail.alertId
    }
    getstreamingurl(obj).then((res) => {
      if (res.status == "Ok") {
        setVideoId(id + "5");
        setVideoUrl(res.rtspURL);
        // setVideoUrl("rtsp://admin:2o2oImaging@192.168.0.64:554/cam/playback?channel=2%26starttime=2024_02_19_10_30_00%26endtime=2024_02_19_10_45_00")
        // setVideoUrl("rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=2%38subtype=1");
        setLiveMode(false)
        setWebRTCExportCurrentVideoHide(false);
      }
    })
  }

  function TwoWayAudioClick(audio, chat, isCloseBtn) {
    var resp = "";
    var resp1 = "";
    if (audio && audio == 'start') {

      setIsSoundOn(false)
      // 
      // TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
      resp1 = TwoWayCommunicationReq(0, 0, 'stop', 0, 0, 0)
      // 
      if(prePostDisplay){
        resp = TwoWayCommunicationReq(chat.privateIpAddress, chat.communicationPort
          , 'start', chat.camUserName, chat.camPassword, chat.brandName
         )
      }else{
        resp = TwoWayCommunicationReq(chat.privateIPAddress, chat.CommunicationPort
          , 'start', chat.camUserName, chat.camPassword, chat.BrandName
         )  
      }
    }
    if (audio && audio == 'stop') {
      setIsSoundOn(true)
      if(prePostDisplay){
        resp = TwoWayCommunicationReq(chat.privateIpAddress, chat.communicationPort
          , 'stop', chat.camUserName, chat.camPassword, chat.brandName
        )
      }else{
        resp = TwoWayCommunicationReq(chat.privateIPAddress, chat.CommunicationPort
          , 'stop', chat.camUserName, chat.camPassword, chat.BrandName
        )
      }
    }
  }

  const TwoWayCommunicationReq = async (privateIPAddress, CommunicationPort
    , action, user, password, BrandName) => {
    try {
      if (privateIPAddress && CommunicationPort
        && user && password) {        
        TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
          , action, user, password, BrandName).then((resp) => {
            return resp
          })
      }else{
        TwoWayCommunicationRequest(privateIPAddress, CommunicationPort
          , action, user, password, BrandName).then((resp) => {
            
            return resp
          })
      }
    } catch (error) {
      console.error(error)
    }
  }

    //For Getting No Stream View
  useEffect(() => {
    let timer = setTimeout(() => {
      if(loading){
        setStreamFound(false);
        setHelperState(true);
        setReconnectState(false);
        webSocketState?.close();
        resetVideo();
        if (peer_connection) {
          peer_connection.close();
          peer_connection = null;
        }
      }
    }, WAIT_TIME);

    return () => {
      clearTimeout(timer);
    }
  }, [loading, reconnectState]);

  

  //For Getting Loading Streaming View
  useEffect(() => {
    if(helperState && !liveMode){
      setStreamFound(true)
      setLoading(true);
      setReconnectState(true);
      setHelperState(false);
      WarningToast("Playback stream could not be found, going back to live stream.")
      goBackToLive();
      return;
    }

    if(helperState){
      let timerTwo = setTimeout(() => {
        setStreamFound(true);
        reConnection();
        setReconnectState(true);
        setHelperState(false);
      }, RETRY_TIME)

      return () => {
        clearTimeout(timerTwo);
      }

    }
  }, [helperState]);

  const reConnection = () => {
    webSocketServerConnect();
  }

  const reConnectionTwo = (id) => {
    console.log("restartPrePost", id);
    webSocketServerConnectTwo(id);
  }

  const progressHandler = (e) => {
    // let date = new Date(null);
    // date.setSeconds(e.playedSeconds);
    // let hhmmssFormat = date.toISOString().substring(11, 19);
    // setRunningTime(hhmmssFormat)
    if(e.playedSeconds != undefined){
      // setPlayed(e.playedSeconds);
    let date = new Date(null);
      date.setSeconds(e.playedSeconds);
      let hhmmssFormat = date.toISOString().substring(11, 19);
      setRunningTime(hhmmssFormat)
      setTimer(e.playedSeconds);
    }
  }

  const handleSeekMouseDown = (e) => {
    // setSeeking(true);
  }

  const handleSeekMouseUp = (e) => {
  }

  const handleSeekChange = e => {
    // console.log("seek", parseFloat(e.target.value));
    // let temp = parseFloat(e.target.value);
    // clearTimeout(timerInt)
    // const newTimer = setTimeout(() => {
    //   console.log("seek =>temp", temp)
    //   console.log("handleSeekChange =>played", parseFloat(e.target.value))
    //   setTimer(temp)
    //   // setTimer(parseFloat(e.target.value))
    //   // webSocketState.send("seek " + parseFloat(e.target.value))
    //   webSocketState.send("seek " + temp)
    //   console.log('seek' + new Date())
    // }, 500)
    // setTimerInt(newTimer)

    // setPlayed(parseFloat(e.target.value));
    // console.log("handleSeekChange =>played", parseFloat(e.target.value))
    let date = new Date(null);
    date.setSeconds(e.target.value);
    let hhmmssFormat = date.toISOString().substring(11, 19);
    setRunningTime(hhmmssFormat)
    setTimer(parseFloat(e.target.value))
    setPlayed(parseFloat(e.target.value))
    // webSocketState.send("seek " + parseFloat(e.target.value))
  }

  useEffect(() => {
    if (isSinglePlayback ) {
      if(webSocketState != null){
        clearTimeout(timerInt)
        const newTimer = setTimeout(() => {
          // setTimer(parseFloat(e.target.value))
          // webSocketState.send("seek " + parseFloat(e.target.value))
          if(isPaused){
            webSocketState.send("Play")
            setIsPaused(false)
          }
          webSocketState.send("seek " + played)
          // console.log('seek' + new Date())
        }, 500)
        setTimerInt(newTimer)
      }
    }
  }, [played]);

  useEffect(() => {
    if (isSinglePlayback ) {
      let interval = setInterval(() => {
        // let timePlayed = getVideoElement()?.currentTime
        let timePlayed
        if(secondsDiff <= timer){
          timePlayed = timer;
          setIsOver(true);
        }else{
          if(isPaused){
            timePlayed = timer;
          }else{  
            timePlayed = timer + 1;
          }  
        }
        // let timePlayed = Math.round((getVideoElement()?.currentTime / 900) * 100);

        let date = new Date(null);
        date.setSeconds(timePlayed);
        let hhmmssFormat = date.toISOString().substring(11, 19);
        setRunningTime(hhmmssFormat)
        // setPlayed(timePlayed);
        setTimer(timePlayed);
      }, 1000)  
  
      return () => {
        clearInterval(interval);
      }
    }
  }, [timer, isPaused]);
  
  return (
    <>

    {
      streamFound
      ?
  
    <div
      ref={videoContainerParentRef}
      className={upperDivCss}
      style={{ position: 'relative',  }}
    >
      {
        loading
          ?
          <div style={{ height: '100%', width: '100%', backgroundColor: "#000000", color: 'green', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            className={videoCss}>
            <p
            // style={{textAlign: 'center'}}
            >
              Loading Video...
            </p>
          </div>
          :
          <>
            <video id={videoId}
              style={{ height: '100%', width: '100%', backgroundColor: "#000000" }}
              className={isFullscreenClick ? fullVideoCss : videoCss}
              ref={videoContainerRef}
              autoPlay={true}
              onProgress={(e) => progressHandler(e)}
            ></video>

            <div className={buttonCss} style={{ width: "100%" }}>
              {
                playback
                  ?
                  <div className={` ${false == "parent5x5" ? "parent5x5CameraName" : 'MosaicCameraName'}`}><p>{item.CameraName || item.cameraName || item.deviceName}</p></div>
                  : null
              }
              <div className='mosaicbuttons'>

                {
                  (prePostDisplay || liveButton) && (!liveMode || playbackMode)
                    ?
                    <button data-title='GoBackToLive' className='MosaicButtonDiv'>
                      <img style={{ padding: "2px", height: "24px", }}
                        src={BackToLive}
                        onClick={goBackToLive}
                      />
                    </button>
                    : null
                }
                    {
                      isSinglePlayback
                      ?
                      <div style={{display: 'flex', flex: 1}}>

                      {
                        !isPaused
                        ?
                      <button data-title='Pause' className='MosaicButtonDiv' onClick={pauseVideo}>
                      <img style={{ padding: "2px" }}
                        src={Pause}
                      />
                    </button>
                        :
                      <button data-title='Play' className='MosaicButtonDiv' onClick={playVideo}
                      >
                      <img style={{ padding: "2px" }}
                        src={Play}
                      />
                    </button>
                      }
                      
                        {
                          item.BrandName != "Hikvision"
                          ?
                          <button data-title='Restart' className='MosaicButtonDiv' onClick={restartVideo}>
                          <img style={{ padding: "2px", marginLeft: "0px" }}
                            src={Rewind}
                          />
                          </button>
                          :
                          null
                        }  


                        <div
                          style={{
                            display: 'flex',
                            flex: 1,
                            flexDirection: 'column',
                            margin: "0px 10px" 
                          }}
                        >
                          {
                            item.BrandName != "Hikvision"
                            ?
                            <div>
                            <div className='controls_seconds'>
                              {
                                true
                                ?
                                <span>{runningTime} / {totalTime}</span>
                                :
                                <span></span>
                              }
                            </div>
                              <input
                                type='range'
                                className='controls_seekbar'
                                min={0}
                                max={secondsDiff}
                                value={timer}
                                onMouseDown={handleSeekMouseDown}
                                onChange={handleSeekChange}
                                onMouseUp={handleSeekMouseUp}
                              />
                              <span id="sliderTitle"></span>
                            </div> 
                            : null
                          }
 
                        </div>

                      </div>
                      : null
                    }

                {
                  prePostDisplay && (liveMode || playbackMode)
                    ?
                    <button data-title='Pre-Post Playback' className='MosaicButtonDiv'>
                      <img style={{ padding: "2px", height: "24px", }}
                        src={PrePost}
                        onClick={prePostHandler}
                      />
                    </button>

                    : null
                }
                      {
                        showPlaybackBtn
                    ?
                          <button
                            data-title='Playback'
                            // className="PlayBackBtnDiv"
                            className="MosaicButtonDiv"
                            onClick={e => {
                              setPopOpen(true);
                              let d = subMinutes(new Date(), 15);
                              let temp = ConvertDate(d);
                              let temp_date = temp.slice(0, 10).replace(/-/g, '_');
                              setDateString(temp_date);
                              setDate(subMinutes(new Date(), 15));
                              if (isFullscreenClick) {
                                handleFullScreen();
                              }
                              setMode("Playback");
                              // onSingleDevPlaybackHandleClick(item) 
                            }}>
                            <img src={PlayBack} className="camera-icon-height" alt='playback white icon' style={{ padding: "2px", height: "24px", }} />
                          </button>
                          : null
                      }

                {
                  showPrePostReplay && item?.brandName != "Hikvision"
                  ?
                  <button data-title='Restart' className='MosaicButtonDiv' onClick={restartPrePost}>
                  <img style={{ padding: "2px", marginLeft: "0px" }}
                    src={Rewind}
                  />
                  </button>
                  : null
                }  

                <button data-title='SnapShot' className='MosaicButtonDiv'>
                  <img style={{ padding: "2px", height: "24px", }}
                    src={MosaicSnapshot}
                    onClick={() => setSnapModal(true)}
                  />
                </button>
                {
                  item.ShowTwoWay && !hideTwoWay
                    ? (
                      isSoundOn ?
                        <button data-title='TwowayStart' className='MosaicButtonDiv'>
                          <img style={{ padding: "2px" }}
                            src={MosaicTwowayStart}
                          onClick={() => TwoWayAudioClick('start', item)}
                          />
                        </button>
                        :
                        <button data-title='TwowayStop' className='MosaicButtonDiv'>
                          <img style={{ padding: "2px" }}
                            src={MosaicTwowayStop}
                          onClick={() => TwoWayAudioClick('stop', item)}
                          />
                        </button>
                    )
                    : null
                }
                {
                  !HIDE_STUFF
                  ?
                  
                    item.ShowHooter ?
                      (!isAllinOneHooterOn ?
                        <button data-title='HooterOn' className='MosaicButtonDiv'>
                          <img style={{ padding: "2px" }}
                            src={MosaicHooterOn}
                            onClick={() => {
                              HooterOnOffClickRequest(item.ParentCameraID, 'on')
                            }}
                          />
                        </button>
                        :
                        <button data-title='HooterOff' className='MosaicButtonDiv'>
                          <img style={{ padding: "2px" }}
                            src={MosaicHooterOff}
                            onClick={() => {
                              HooterOnOffClickRequest(item.ParentCameraID, 'off')
                            }}
  
                          />
                        </button>) : null
                  
                  : null
                }
                
                <button
                  //data-title='Fullscreen' 
                  data-title={isFullscreenClick ? 'ExitFullscreen' : 'Fullscreen'}
                  className='MosaicButtonDiv'
                  onClick={handleFullScreen}
                >
                  {
                    !isFullscreenClick ?
                      <img style={{ padding: "2px" }}
                        src={MosaicFullscreen}
                      />
                      :
                      <img style={{ padding: "2px" }}
                        src={MosaicExitFullscreen}
                      />
                  }
                </button>

                 {
                  !HIDE_STUFF
                  ?
                <button data-title='Video Export' className='MosaicButtonDiv'>
                  <img style={{ padding: "2px", height: "24px", }}
                    src={Video_Export} alt="video Export Button"
                    onClick={
                      () => {
                        setPopOpen(true);

                        // let d = subMinutes(new Date(), 15);
                        // let temp = ConvertDate(d);
                        // let temp_date = temp.slice(0, 10).replace(/-/g, '_');
                        // setDateString(temp_date);
                        // setDate(subMinutes(new Date(), 15));
                        
                        // let f = new Date();
                        // let tempTwo = ConvertDate(f);
                        // let temp_date_two = tempTwo.slice(0, 10).replace(/-/g, '_');
                        // setDateStringTwo(temp_date_two);
                        // setdateTwo(new Date())
                        setDate(subMinutes(new Date(), 15))
                        setdateTwo(new Date());
                        setTimeString("");
                        setTimeStringTwo("");

                        if (isFullscreenClick) {
                          handleFullScreen();
                        }
                        setMode("Export");
                      }
                    }
                  />
                </button>
                  : null
                  } 
              </div>
              <div>
                <Modal
                  // size='lg'
                  show={popOpen}
                  onHide={() => setPopOpen(false)}
                  className={webRTCExportCurrentVideoHide || mode == "Playback" ? "singleplaybackexportModal" : "ExportModal"}
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    {
                      mode == "Playback" ?
                        <Modal.Title>
                          <p className="ModelHeaderName">{item.CameraName || item.cameraName} Playback</p>
                        </Modal.Title>
                        :
                        <Modal.Title>
                          <p className="ModelHeaderName">{item.CameraName || item.cameraName} Export</p>
                        </Modal.Title>
                    }
                  </Modal.Header>
                  <Modal.Body>
                    <PopupCard item={item} dateChange={dateChange} date={date} getDataFromChild={getDataFromChild} 
                    onSubmitBtn={mode == "Playback" ? onSubmitBtn : onExportBtn} setBlankDate={setBlankDate} 
                    timeStringMain={timeStringMain} mode={mode} setBlankDateTwo={setBlankDateTwo} 
                    getDataFromChildTwo={getDataFromChildTwo} timeStringMainTwo={timeStringMainTwo} 
                    dateTwo={dateTwo} exportCurrentVideoHide={webRTCExportCurrentVideoHide} 
                    videoExportDownload={downloadDirectPlayback} />
                  </Modal.Body>
                </Modal>

                <Modal
                  show={snapModal}
                  onHide={() => setSnapModal(false)}
                  size='lg'
                  style={{zIndex: "99999"}}
                // className='singleplaybackexportModal'
                  backdrop="static"
                >
                  <Modal.Header closeButton>
                    <p className='ModelHeaderName'>{item.cameraName} Snapshot</p>

                  </Modal.Header>

                  <Modal.Body>
                    <div id="output"></div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '0.5rem'
                      }}
                    >
                      <button className='downloadbuttonNewUI' onClick={downloadImage}>
                        Download Image
                      </button>

                      {
                        hideTwoWay
                        ?
                        <button className='downloadbuttonNewUI' onClick={generateSnapInChat}>
                        Add to Chat
                        </button>
                        : 
                        null
                      }

                    </div>
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </>

      }


    </div>

    :
    <div className={noStreamDivCss}>
              <img
                src={MosaicNoStream}
                alt="Video"
                className="w-100 image-bg mosaic-nostream-img"
              />
    </div>

    }

    </>
  )
}

export default WebRTCPlayer;