import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import '../../../app.css'
import { auth } from '../../../services/firebase'
import { IsEmailValid } from '../../../utils/validations'
// import { ReactComponent as ErrorIcon } from '../../../assets/images/error.svg'
import Tooltip from 'react-bootstrap/Tooltip'
import { OverlayTrigger } from 'react-bootstrap'
import { MonitoringHubLogo, LeftArrow, LoginImage } from '../../../assets/images'
import { BASE_CORE_URL } from '../../../config'


export default function Forgetpassword({ match }) {
    const location = useLocation();
    var t = location.pathname.split('/')
    var emailid = t[t.length - 1].replace('"', '').replace('"', '')
    let history = useHistory();
    const [error, setError] = useState(null)
    const [isShowSuccessPage, setIsshowSuccessPage] = useState(false)
    const [isShowResetpasswordcomponent, setIsShowResetpasswordcomponent] = useState(true)

    const Resetpasswordcomponent = ({ error }) => {
        console.log("rupesh");
        const [email, setEmail] = useState(emailid)
        const emailIdChanged = (e) => {
            setEmail(e.target.value)
        }

        const successpagerender = async () => {
            console.log("rupesh", email);
            if (email.trim() === '') {
                // setCommonError(null)
                setError('Please enter email')
                return
            }

            // if (IsEmailValid(emailid)) {
            //     // setCommonError(null)
            //     setError('Please enter valid Email Id.')
            //     return
            // }

            const requestOptions = {
                method: "GET",
                headers: { "Content-Type": "application/json" }
            };
            const url = BASE_CORE_URL + "Authenticate/ForgotPassword?email=" + encodeURIComponent(email);
            return await fetch(url, requestOptions)
                .then((resp) => resp.json())
                .then(function (data) {
                    if (data.Status == 'success') {
                        setIsshowSuccessPage(true)
                        setIsShowResetpasswordcomponent(false)
                    } else {
                        setError('email not verified.')
                    }
                })
                .catch(function (error) {
                    console.error(error)
                    setError('User does not exist!')
                });

            // {
            //     auth.sendPasswordResetEmail(email).then((resp) => {
            //         setIsshowSuccessPage(true)
            //         setIsShowResetpasswordcomponent(false)
            //         // ReactDOM.render(<Successpage />, document.getElementById("resetpassworddiv"))
            //     }).catch((error) => {
            //         console.error(error)
            //         setError('User does not exist!')
            //     });
            // }
        }

        return <>
            <p className="ForgotPasswordName">
                <OverlayTrigger
                    placement='bottom'
                    overlay={
                        <Tooltip>
                            Back to Login
                        </Tooltip>
                    }
                >
                    <img src={LeftArrow} onClick={() => history.replace('/login')} /></OverlayTrigger>
                <span className="ml-3"> Forgot your Password?</span>
            </p>
            <p className="SelectSpaceLine">
                Select which contact details should we use to reset your password:
            </p>

            <div className="inputMainContainer w25dot75rem">
                <div className="inputTypeLabelContainer">
                    <label for="email">Email ID</label>
                    <input
                        className="inputType"
                        id="email"
                        type="email"
                        placeholder="Ex. info@intellve.com"
                        //   value={emailid}
                        value={email}
                        onChange={(e) => emailIdChanged(e)}
                    />
                </div>
            </div>

            {/* <button className="ViaEmail">
                <div className="ViaSMS_Image">
                <img src={ViaEmail} style={{width: "25px"}} />
                <span>Via Email:</span>
                </div>
                <div className="ViaEmail_Text">{emailid}</div>
            </button> */}

            {/* <button className="ViaEmail">
                <div className="ViaSMS_Image">
                    <img src={ViaEmail} style={{width: "25px"}} />
                </div>
                <div className="ViaEmail_Text">
                    <p>Via Email:</p>
                    <span>{emailid}</span>
                </div>
            </button> */}
            <div style={{ height: "10px" }}>
                <p className="Error_P">
                    {
                        error &&
                        <>
                            {/* <ErrorMsgIcon /> */}
                            <span>{error}</span>
                        </>
                    }
                </p>
            </div>
            <button className="ResetPasswordButton" style={{ marginTop: "30px" }} onClick={() => successpagerender()}>
                <span className="Manage-Configuration Sign-In">Reset Password</span>
            </button>
        </>
    }



    const restpage = () => {
        setIsShowResetpasswordcomponent(true)
        setIsshowSuccessPage(false)
        // ReactDOM.render(<Resetpasswordcomponent error={error}/>, document.getElementById("resetpassworddiv"))
    }

    const Successpage = () => (
        <div className="SuccessfulPasswordDiv">
            <p className="ForgotPasswordName">
                <img src={LeftArrow} onClick={() => restpage()} />
                <span style={{ margin: "0px 0px 0px 5px" }} className="ml-3">Password reset link sent successful</span>
            </p>
            <p className="SelectSpaceLine mb-5">
                Password reset link has been sent to your registered email id. If you don't receive the link in the next 2 mins please go back & click on the reset password button again.
            </p>
            <button className="ResetPasswordButton" onClick={() => history.replace('/login')}>
                <span className="Manage-Configuration Sign-In">Sign In</span>
            </button>
        </div>
    )

    return (
        <div className="container-fluid forgot-pass-container" style={{ backgroundColor: "#fff" }}>
            <div className="row forgot-pass-row">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12" style={{ backgroundColor: "#F6F8FC" }}>
                    <div className="imageWhiteBg">
                        <MonitoringHubLogo height={"70px"} />
                        {/* <img src={leftimage} className="mt-4"/> */}
                    </div>
                    <div className="BigImage">
                        {/* <img src={ForgotPassword} /> */}
                        <img src={LoginImage} />
                    </div>
                </div>
                <div className="col-lg-6 ContentForm col-md-6 col-sm-12 col-xs-12 login-col-2">
                    <div className="WhiteFormSignIn">
                        <div className="Content" id="resetpassworddiv">
                            {isShowSuccessPage && <Successpage />}
                            {isShowResetpasswordcomponent &&
                                <Resetpasswordcomponent error={error} />
                            }
                            {/* <Resetpasswordcomponent error={error}/> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}