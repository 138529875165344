import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

function CameraTileSkeleton() {
    return (
        <><div className="CameraDiv1" >
            <>
             < Skeleton
                    style={{ borderRadius: "13px", width: "100%", height: "100%" }}
                    className="player-responsive1"
                />
            </>
            <Skeleton className="mt-3 pt-3">
            </Skeleton>
        </div></>
    )
}

export default CameraTileSkeleton