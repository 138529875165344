class ActionProvider {
    constructor(createChatBotMessage, setStateFunc, createClientMessage) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
      this.createClientMessage = createClientMessage;
    }

    testing() {
        console.log("action test")
    }

    ChatboCustomMessagehandler = (mess) =>{
        const message = this.createChatBotMessage(mess,{
            widget:"custombuttons",
        })
        this.setChatbotMessages(message)
    }
    setChatbotMessages = (message) => {
        this.setState(state => ({...state,messages:[...state.messages,message]}))
    }
    setChatbotlastmessage = (messagelist) => {
        this.setState(state => ({...state,messages:messagelist}))
    }


    // Chatusermessagehandler = (mess) =>{
    //     const message = this.createClientMessage(mess)
    //     this.setChatbotMessages(message)
    // }    
    // setChatbotResponse = (message) => {
    //     this.setState(state => ({...state,botresponse:message}))
    // }    
    // setChatbotlastreplymessage = (messagelist) => {
    //     this.setState(state => ({...state,messages:messagelist}))
    // }
  }
  
  export default ActionProvider;