import { useState, useEffect } from 'react';
import { INTERVAL_TIME, MAX_TRIES, WAIT_TIME } from '../config';

// const MAX_TRIES = 5;
// const WAIT_TIME = 30000;
// const INTERVAL_TIME = 30000;

export const useVideo = () => {
    const [count, setCount] = useState(0);
    const [buffering, setBuffering] = useState(false);
    const [oneMin, setOneMin] = useState(false);
    const [onError, setOnError] = useState(false);
    const [limitHit, setLimitHit] = useState(false);
    const [tempCount, setTempCount] = useState(0);


    const videoStartBuffering = (e, cameraName) => {
        console.log("cbpb buffer stop event, cameraName",e, cameraName)
        setBuffering(true);
    }

    const videoStopBuffering = (e, cameraName) => {
        console.log("cbpb buffer start event, cameraName stopBuffering",e, cameraName)

        setBuffering(false);

        // let c = count + 1;
        // if(count === MAX_TRIES || count > MAX_TRIES){
        //     setLimitHit(true);
        // }else{
        //     setCount(c);
        // }        
    }

    // useEffect(() => {
    //     let timerId = setTimeout(() => {
    //         console.log("Timerout after 30 secs");
    //         if(!buffering){
    //             setOneMin(true);
    //             let c = count + 1;
    //             setCount(c);
    //         }else{
    //             setOneMin(true);
    //         }
    //     }, 30000)

    //     return () => clearTimeout(timerId);
    // }, [])

    // useEffect(() => {
    //     if(count === 5){
    //         console.log("onError Timeout");
    //         let timerIdOne;
    //         clearTimeout(timerIdOne);
    //         timerIdOne = setTimeout(() => {
    //             console.log("onError Timeout")
    //             let c = 0;
    //             setCount(c);
    //         }, 2000);

    //         return () => clearTimeout(timerIdOne);
    //     }
    // }, [count]);

    useEffect(() => {
        if(onError){
            let timerId = setTimeout(() => {
                let c = count + 1;
                setCount(c);
                // setOnError(false);
            }, WAIT_TIME)
            return () => clearTimeout(timerId);
        }
    }, [onError])

    useEffect(() => {
        if(limitHit){
            let timerId = setTimeout(() => {
                let c = 0;
                setCount(c);

                // setOnError(false);
                setLimitHit(false);
            }, INTERVAL_TIME)
            return () => clearTimeout(timerId);
        }
    }, [limitHit]);

    // useEffect(() => {
    //     let timerId
    //     if(count === 5){
    //         console.log("Timer after 1 min before setting timeout", count);
    //         timerId = setTimeout(() => {
    //             console.log("Timer after 1 min", count);
    //             if(count === 5){
    //                 console.log("Timer after 1 min", count);
    //                 let temp = 0;
    //                 setCount(temp);
    //             }
    //         }, 30000);
    //     }
    //     return () => clearTimeout(timerId);
    // }, [count])

    
    const videoErrorHandler = (event, cameraName) => {
        console.log("cbpb error event, cameraName",event, cameraName)
        if(cameraName == "cp cam 2"){
            console.log("errorPlayerComponent", count, event, cameraName);
        }
        if(cameraName == "cp cam 3"){
            console.log("errorPlayerComponent", count, event, cameraName);
        }
        if(cameraName == "cp cam 1"){
            console.log("errorPlayerComponent", count, event, cameraName);
        }
        setOnError(true);
        if(buffering === false && onError){
            let c = count + 1;
            if(count === MAX_TRIES || count > MAX_TRIES){
                setLimitHit(true);
            }else{
                setCount(c);
            }
        }
        // window.location.reload(true)
    }

    const getHeaders = () => {
        return { 'Cache-Control': 'no-store' };
      };
    
    return { count, setCount, videoStartBuffering, videoStopBuffering, videoErrorHandler, getHeaders }
}