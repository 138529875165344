import React, { useEffect, useState } from 'react'
// import List from '../../assets/images/List.png'
import { List, Grid, Search, Cross, NewSearchIcon } from '../../assets/images';
// import 'bootstrap/dist/css/bootstrap.css'
import AlertsGridView from './AlertsGridView';
import AlertListView from './AlertsListView';
import { mixpanel } from '../../utils/MixPanelUtil'
import { useLocation} from "react-router-dom"
import InfiniteScroll from "react-infinite-scroll-component"
import { getAlertList,FilterAlert } from "../../services/alertAuthService";
import Swal from 'sweetalert2'

export default function AlertsContainer({
    extremeAlertLst,
    highAlertLst,
    moderateAlertLst,
    lowAlertsLst,
    allAlerts,
    onAddAlertChat,
    SetNewList,
    extremeAlertCount,
    highAlertCount,
    moderateAlertCount,
    lowAlertCount,
    AllAlertCount,
    uniqueId,
    alertSearch,
    setAlertSearch,
    activeSeverity,
    setActiveSeverity
}) {
    let lastselectedtab = "extreme"
    const location = useLocation()
    
    // For Toggling between list and grid views
    const [isListView, setIsListView] = useState(null)
    const [selectedSeverityTab, setSelectedSeverityTab] = useState('all')

    const [tempextremeAlertLst, settempextremeAlertLst] = useState([])
    const [temphighAlertLst, settemphighAlertLst] = useState([])
    const [tempmoderateAlertLst, settempmoderateAlertLst] = useState([])
    const [templowAlertsLst, settemplowAlertsLst] = useState([])
    const [tempAllAlert, setTempAllAlert] = useState([])
    const [resetInfiniteScroll, setResetInfiniteScroll] = useState(0);

    let LoggedInUser = JSON.parse(localStorage.getItem('user'))

    //Once user click on gridview or list view it should not change throughout the application until and unless user cannot change it again
    useEffect(() => {
        setAlertSearch(false);
        var alertView = localStorage.getItem("IsListView")
        if (alertView) {
            if (alertView == 'true') { setIsListView(true) }
            else if (alertView == 'false') { setIsListView(false) }
            else { setIsListView(false) }
        }
        else { setIsListView(true) }
    }, [])

    useEffect(() => {
        if(location.pathname === "/allalerts"){
            mixpanel.time_event("Session of Alert Docker Page")
            mixpanel.track("Clicks on Alert Docker")
        }else if(location.pathname === "/myalerts"){
            mixpanel.time_event("Session of Closed Alert Page")
            mixpanel.track("Clicks on Closed Alert")
        }

        return () => {
            var lasttab=localStorage.getItem("SelectedtAlertTab")
            if(location.pathname === "/allalerts"){
                EndMixpanelEventTimer(lasttab,"Session of Alert Docker Page")
            }else if(location.pathname === "/myalerts"){
                EndMixpanelEventTimer(lasttab,"Session of Closed Alert Page")
            }
            localStorage.removeItem("SelectedtAlertTab")
        }
    },[])

    useEffect(() => {
        settempextremeAlertLst(extremeAlertLst)
    }, [extremeAlertLst])
    useEffect(() => {
        settemphighAlertLst(highAlertLst)
    }, [highAlertLst])
    useEffect(() => {
        settempmoderateAlertLst(moderateAlertLst)
    }, [moderateAlertLst])
    useEffect(() => {
        settemplowAlertsLst(lowAlertsLst)
    }, [lowAlertsLst])
    useEffect(() => {
        setTempAllAlert(allAlerts)
    }, [allAlerts])

    useEffect(() => {
        setActiveSeverity(selectedSeverityTab);
    }, [selectedSeverityTab]);

    //#region Search impl s
    function onSearchChange(e) {
        // e.preventDefault()
        var searchText = e.target.value
        if (searchText) {

            if (selectedSeverityTab == "all") {
                if (allAlerts) {
                    var filteredLstBySearch = allAlerts.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        setTempAllAlert(filteredLstBySearch)
                    } else {
                        setTempAllAlert(allAlerts)
                    }
                }
            }
            if (selectedSeverityTab == "extreme") {
                if (extremeAlertLst) {
                    var filteredLstBySearch = extremeAlertLst.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        settempextremeAlertLst(filteredLstBySearch)
                    } else {
                        settempextremeAlertLst(extremeAlertLst)
                    }
                }
            }
            if (selectedSeverityTab == "high") {
                if (highAlertLst) {
                    var filteredLstBySearch = highAlertLst.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        settemphighAlertLst(filteredLstBySearch)
                    } else {
                        settemphighAlertLst(highAlertLst)
                    }
                }
            }
            if (selectedSeverityTab == "moderate") {
                if (moderateAlertLst) {
                    var filteredLstBySearch = moderateAlertLst.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        settempmoderateAlertLst(filteredLstBySearch)
                    } else {
                        settempmoderateAlertLst(moderateAlertLst)
                    }
                }
            }
            if (selectedSeverityTab == "low") {
                if (lowAlertsLst) {
                    var filteredLstBySearch = lowAlertsLst.filter(x => x.alertId.toString().includes(searchText))
                    if (filteredLstBySearch) {
                        settemplowAlertsLst(filteredLstBySearch)
                    } else {
                        settemplowAlertsLst(lowAlertsLst)
                    }
                }
            }

        }
        else {
            // setFilteredCamDeviceList(cameraDeviceList)
            settempextremeAlertLst(extremeAlertLst)
            settemphighAlertLst(highAlertLst)
            settempmoderateAlertLst(moderateAlertLst)
            settemplowAlertsLst(lowAlertsLst)
            setTempAllAlert(allAlerts)
        }
    }
    //#endregion Search impl e

    //#region Mixpanel events functions
    const AddMixpanelEvent = (severity) => {
        try{
            //mixpanel.time_event("Session of Alert Docker Page")
            if(location.pathname === "/allalerts"){
                EndMixpanelEventTimer(selectedSeverityTab,"Session of Alert Docker Page")
                mixpanel.time_event("Session of Alert Docker Page")

            }else if(location.pathname === "/myalerts"){
                EndMixpanelEventTimer(selectedSeverityTab,"Session of Closed Alert Page")
                mixpanel.time_event("Session of Closed Alert Page")

            }
        }catch(error){
            console.error(error)
        }
    }
    const EndMixpanelEventTimer = (selectedSeverityTab,eventname) => {
        try{
            switch (selectedSeverityTab) {
                case "extreme":
                    mixpanel.track(eventname,{ "Severity":"Extreme" })
                    break;
                case "high":
                    mixpanel.track(eventname,{ "Severity":"High" })
                    break;
                case "moderate":
                    mixpanel.track(eventname,{ "Severity":"Moderate" })
                    break;
                case "low":
                    mixpanel.track(eventname,{ "Severity":"Low" })
                    break;
                default:
                    break;
            }
        }catch(error){
            console.error(error)
        }
    }

    //#endregion

    //#region Infinite scroll

    const [selectedtablist,setselectedtablist]=useState([])

    useEffect(() => {
        (async () => {
            try{
                GettingAlertList(selectedSeverityTab)
                setalertsearchtxt("")
                document.getElementById("Alertid_txt").value=""
            }catch(error){
                console.error(error)
            }
         })();

         }, [uniqueId, selectedSeverityTab])

    const GettingAlertList = async (tab) => {
        try{
            var apiaction = ""
            if(location.pathname == "/myalerts") { apiaction = "getClosedAlerts" }
            else { apiaction = "getAlertList" }

            var obj={
                "action":apiaction,
                "severity":tab,
                "userUniqueId":LoggedInUser.UserUniqueID || LoggedInUser.userUniqueID,
                "startIndex":0,
                "numberOfElements":50,
                "authCode":localStorage.getItem('authCode')
            }
            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        SetNewList(resp.message,tab)
                        setselectedtablist(resp.message)
                    }
                }
            })
        }catch(error){
            console.error(error)
        }
    }

    const fetchMoreData = () => {
        try{
            var startindex = 50
            setAlertSearch(true);
            if(selectedSeverityTab == "all"){
                if(tempAllAlert.length == AllAlertCount){ return }
                SortingList(tempAllAlert,startindex,selectedSeverityTab)
            }else if(selectedSeverityTab == "extreme"){
                if(extremeAlertLst.length == extremeAlertCount){ return }
                SortingList(extremeAlertLst,startindex,selectedSeverityTab)
            }else if(selectedSeverityTab == "high"){
                if(highAlertLst.length == highAlertCount){ return }
                SortingList(highAlertLst,startindex,selectedSeverityTab)
            }else if(selectedSeverityTab == "moderate"){
                if(moderateAlertLst.length == moderateAlertCount){ return }
                SortingList(moderateAlertLst,startindex,selectedSeverityTab)
            }else if(selectedSeverityTab == "low"){
                if(lowAlertsLst.length == lowAlertCount){ return }
                SortingList(lowAlertsLst,startindex,selectedSeverityTab)
            }
        }catch(error){
            console.error(error)
        }
    }

    const SortingList = async (templist,index,tab) => {
        try{
            var currentindex = index
            if(templist.length < currentindex){
                currentindex = templist.length-1
            }else{
                currentindex=templist.length
            }

            var apiaction = ""
            if(location.pathname == "/myalerts") { apiaction = "getClosedAlerts" }
            else { apiaction = "getAlertList" }

            var obj={
                "action":apiaction,
                "severity":tab,
                "userUniqueId":LoggedInUser.UserUniqueID || LoggedInUser.userUniqueID,
                "startIndex":currentindex,
                "numberOfElements":index,
                "authCode":localStorage.getItem('authCode')
            }
            await getAlertList(obj).then((resp) => {
                if (resp.status == "Ok") {
                    if (resp.message) {
                        SetNewList(templist.concat(resp.message),tab)
                        setselectedtablist(templist.concat(resp.message))
                    }
                }
            })
        }catch(error){
            console.error(error)
        }
    }
    //#endregion

    const [alertsearchtxt,setalertsearchtxt]=useState("")
    // useEffect(() => {
    //     console.log("Alert id empty")
    //     if(alertsearchtxt === ""){
    //         console.log("Alert id empty")
    //         if(selectedSeverityTab == "all"){
    //             console.log("Alert id empty", allAlerts, tempAllAlert)
    //             setTempAllAlert([]);
    //             setTempAllAlert(allAlerts)
    //         }else if(selectedSeverityTab == "extreme"){
    //             console.log("Alert id empty")
    //             settempextremeAlertLst(extremeAlertLst)
    //         }else if(selectedSeverityTab == "high"){
    //             console.log("Alert id empty")
    //             settemphighAlertLst(highAlertLst)
    //         }else if(selectedSeverityTab == "moderate"){
    //             console.log("Alert id empty")
    //             settempmoderateAlertLst(moderateAlertLst)
    //         }else if(selectedSeverityTab == "low"){
    //             console.log("Alert id empty")
    //             settemplowAlertsLst(lowAlertsLst)
    //         }
    //     }
    // }, [alertsearchtxt]);

    useEffect(() => {
        if(alertsearchtxt.trim() === ""){
            GettingAlertList(selectedSeverityTab)
            setSelectedSeverityTab(selectedSeverityTab)
            setalertsearchtxt("")
            setAlertSearch(false);
            document.getElementById("Alertid_txt").value=""
        }
    }, [alertsearchtxt]);

    const setSearchedList = (lst,selectedSeverityTab) => {
        try{
            if(selectedSeverityTab == "all"){
                setTempAllAlert(lst)
            }else if(selectedSeverityTab == "extreme"){
                settempextremeAlertLst(lst)
            }else if(selectedSeverityTab == "high"){
                settemphighAlertLst(lst)
            }else if(selectedSeverityTab == "moderate"){
                settempmoderateAlertLst(lst)
            }else if(selectedSeverityTab == "low"){
                settemplowAlertsLst(lst)
            }
        }catch(error){
            console.error(error)
        }
    }



    async function SearchAlert(){
        var alertid=alertsearchtxt.trim()
        if(alertid == ""){
            Swal.fire({ text: 'Please enter alert id!' })
            // alert("Please enter alert id!")
            return
        }
        var obj={
            "userId": LoggedInUser.UserID,
            "searchInput": alertid
          }
        await FilterAlert(obj).then((resp) => {
            if (resp.status == "Ok") {
                if (resp.message) {
                    let lst = [];
                    if(location.pathname === '/myalerts' && resp.message?.status === "Closed"){
                        lst.push(resp.message)
                        SetNewList(lst,selectedSeverityTab)
                        // setSearchedList(lst,selectedSeverityTab)
                    }else if(location.pathname === '/allalerts' && resp.message?.status !== "Closed"){
                        setAlertSearch(true);
                        lst.push(resp.message)
                        SetNewList(lst,selectedSeverityTab)
                        // setSearchedList(lst,selectedSeverityTab)
                    }
                    else{
                        Swal.fire({
                            text: 'Alert id not present',
                            confirmButton: 'Ok',
                        }).then(result => result.isConfirmed && ResetSearchAlert());
                    } 
                }
            }
            else if (resp.status == "Failed" && resp.message == "Alert not found!") {
                SetNewList([],selectedSeverityTab)
                Swal.fire({
                    text:"Alert not found!",
                    // title: 'Do you want to save the changes?',
                    // showDenyButton: true,
                    // showCancelButton: true,
                    confirmButtonText: 'Ok',
                    // denyButtonText: `Don't save`,
                  }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        ResetSearchAlert()
                    //   Swal.fire('Saved!', '', 'success')
                    }
                    // else if (result.isDenied) {
                    //   Swal.fire('Changes are not saved', '', 'info')
                    // }
                  })
                // Swal.fire({text:'Alert not found!'})

            }
        })
    }
    function ResetSearchAlert(){
        var alertid=alertsearchtxt.trim()
        if(alertid == ""){ return }
        GettingAlertList(selectedSeverityTab)
        setSelectedSeverityTab(selectedSeverityTab)
        setalertsearchtxt("")
        setAlertSearch(false);
        document.getElementById("Alertid_txt").value=""
    }

    const filteredAlerts = (givenlist, searchedText) => {
        return givenlist.filter(x => x.alertId.toString().includes(searchedText));
    }

    const searchHandler = (e) => {
        let s = e.target.value.toString();
        setalertsearchtxt(e.target.value);
        if(selectedSeverityTab === 'all'){
            setTempAllAlert(filteredAlerts(allAlerts, s))
        }else if(selectedSeverityTab === 'extreme'){
            setTempAllAlert(filteredAlerts(extremeAlertLst, s))
        }else if(selectedSeverityTab === 'high'){
            setTempAllAlert(filteredAlerts(highAlertLst, s))
        }else if(selectedSeverityTab === 'moderate'){
            setTempAllAlert(filteredAlerts(moderateAlertLst, s))
        }else{
            setTempAllAlert(filteredAlerts(lowAlertsLst, s))
        }
    }

    const handleScroll = (e) => {
        if(e.currentTarget.scrollTop === 0) {
            // alert("on top")
            setAlertSearch(false);
            let c = resetInfiniteScroll + 1;
            setResetInfiniteScroll(c);
         }else{
            // setAlertSearch(true);
         }
    }

    return (
        <div className='MainContentMargin'>
            <div className="col-lg-12" style={{ margin: "0px auto", padding: "0px" }}>
                <div className="col-lg-12 SecondDivSearch dashboardHeadingInnerDiv">
                    <div className="cameramainheader"><h4>Alerts</h4></div>
                    <div >
                        <div className="AlertSearchTextDiv">
                            <img className="EyeIcon" alt= "search-icon"src={Search} onClick={() => SearchAlert()}/>
                            <input id="Alertid_txt" className="SearchViewInput" type="number" min="0" 
                            onChange={e => setalertsearchtxt(e.target.value)}
                            // onChange={searchHandler} 
                            placeholder="Alert ID" />
                            <img className="EyeIcon" alt="cross-icon" src={Cross} onClick={() => ResetSearchAlert()}/>
                        </div>
                        {/* <div className="cam-search mt-0">     
                                <>
                                    <span class="changeColor">
                                        <NewSearchIcon
                                            onClick={() => {
                                                SearchAlert()
                                                // if (!isSearch)
                                                //     ErrorToast('Please enter the name of the camera, location, or site.')
                                            }}
                                        />
                                    </span>
                                    <input className="SearchViewInput" type="search"
                                    id="Alertid_txt" 
                                    // ref={searchRef} 
                                    onChange={e => setalertsearchtxt(e.target.value)} 
                                    placeholder="Alert ID" 
                                    />
                                </>
                        </div> */}
                        {/* <div className="UpdateButtonDiv">
                                <button className="UpdateActivityButton" data-toggle="modal" data-target="#activityUpdateModal">
                                    <span className="ViewUpdate">Activity Update</span>
                                    <img src={Update} />
                                </button>
                            </div> */}

                    </div>
                </div>

                <div className="col-lg-12  SeverityBar" style={{ marginTop: "7px" ,marginBottom: "7px"}}>
                    <div className="RedBorderDiv">
                        <div className="SeverityTextDiv">
                            <p
                                className={selectedSeverityTab == "all" ? "active-all active-block active-text" : "inactive-text all"}
                                onClick={() => setSelectedSeverityTab('all')}>
                                <>All Alerts <span>({AllAlertCount})</span></>
                            </p>
                            <p
                                className={selectedSeverityTab == "extreme" ? "active-extreme active-block active-text" : "inactive-text extreme"}
                                onClick={()=> {
                                    AddMixpanelEvent("Extreme")
                                    localStorage.setItem("SelectedtAlertTab","extreme")
                                    setSelectedSeverityTab('extreme')
                                }}>
                                <>Extreme <span>({extremeAlertCount})</span></>
                            </p>
                            <p
                                className={selectedSeverityTab == "high" ? "active-high active-block active-text" : "inactive-text high"}
                                onClick={()=> {
                                    AddMixpanelEvent("High")
                                    localStorage.setItem("SelectedtAlertTab","high")
                                    setSelectedSeverityTab('high')
                                }}>
                                <>High <span>({highAlertCount})</span></>
                            </p>
                            <p
                                className={selectedSeverityTab == "moderate" ? "active-medium active-block active-text" : "inactive-text medium"}
                                onClick={()=> {
                                    AddMixpanelEvent("Medium")
                                    localStorage.setItem("SelectedtAlertTab","moderate")
                                    setSelectedSeverityTab('moderate')
                                }}>
                                <>Moderate <span>({moderateAlertCount})</span></>
                            </p>
                            <p
                                className={selectedSeverityTab == "low" ? "active-low active-block active-text" : "inactive-text low"}
                                onClick={()=> {
                                    AddMixpanelEvent("Low")
                                    localStorage.setItem("SelectedtAlertTab","low")
                                    setSelectedSeverityTab('low')
                                }}>
                                <>Low <span>({lowAlertCount})</span></>
                            </p>
                        </div>
                        <div className="DownloadTextDiv">
                            <a href="#" style={{ display: (isListView ? 'block' : 'none') }} onClick={() => {
                                setIsListView(false)
                                localStorage.setItem("IsListView", false)
                            }}>
                                <p className="DownloadText"><span className="pe-1"><img src={List} alt='list view icon'/></span>List View</p>
                            </a>

                            <a href="#" style={{ display: (isListView ? 'none' : 'block') }} onClick={() => {
                                setIsListView(true)
                                localStorage.setItem("IsListView", true)
                            }}>
                                <p className="DownloadText"><span className="pe-1"><img src={Grid} alt='grid view icon'/></span>Grid View</p>
                            </a>
                        </div>
                    </div>
                </div>
                {/* <div id="scrollableDiv" className="infinite-scroll-div" onScroll={handleScroll}> */}
                    {/* <InfiniteScroll
                        key={resetInfiniteScroll}
                        dataLength={selectedtablist.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv"
                    > */}
                        {/* alert grid view start */}
                        <div id="divGridView" style={{ display: (isListView ? 'block' : 'none') }}>
                            <AlertsGridView
                                severity={selectedSeverityTab}
                                extremeAlertLst={tempextremeAlertLst}
                                highAlertLst={temphighAlertLst}
                                moderateAlertLst={tempmoderateAlertLst}
                                lowAlertsLst={templowAlertsLst}
                                allAlerts={tempAllAlert}
                                onAddAlertChat={onAddAlertChat}
                                resetInfiniteScroll={resetInfiniteScroll}
                                selectedtablist={selectedtablist}
                                fetchMoreData={fetchMoreData}
                                handleScroll={handleScroll}
                            />
                        </div>
                        {/* alert grid view end */}

                        {/* alert list view start */}
                        <div id="divListView" style={{ display: (isListView ? 'none' : 'block') }}>
                            <AlertListView
                                severity={selectedSeverityTab}
                                extremeAlertLst={tempextremeAlertLst}
                                highAlertLst={temphighAlertLst}
                                moderateAlertLst={tempmoderateAlertLst}
                                lowAlertsLst={templowAlertsLst}
                                allAlerts={tempAllAlert}
                                onAddAlertChat={onAddAlertChat}
                                resetInfiniteScroll={resetInfiniteScroll}
                                selectedtablist={selectedtablist}
                                fetchMoreData={fetchMoreData}
                                handleScroll={handleScroll}
                            />
                        </div>
                        {/* alert list view end */}
                    {/* </InfiniteScroll> */}
                {/* </div> */}
            </div>
        </div>
    )
}