import { BASE_CORE_URL } from "../config"

export const getDataAccess = async () => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        }
    }
    let url = BASE_CORE_URL + "DataAccess/Get";

    return await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(data => {
            console.log(data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

export const postDataAccess = async (obj) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
        body: JSON.stringify(obj),
    }
    let url = BASE_CORE_URL + "DataAccess/Add";
    return await fetch(url, requestOptions)
        .then(resp => resp.json())
        .then(data => {
            console.log("DataAccess", data);
            return data;
        })
        .catch(err => {
            console.log(err);
    })

}

export const updateDataAccess = async (obj) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
        body: JSON.stringify(obj),
    }
    let url = BASE_CORE_URL + "DataAccess/Update";

    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("updateDataAccess", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

export const deleteDataAccess = async (obj) => {
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
        body: JSON.stringify(obj),
    }

    let url = BASE_CORE_URL + "DataAccess/Delete";

    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("deleteDataAccess", data);
            return data;
        }).catch(err => {
            console.log(err);
        })
}

export const getMasterData = async (obj) => {
    console.log("getMasterFunc");
    let {entity, fileName, parentId, childId, pageNumber, pageType, searchText } = obj;
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    }
    let url = BASE_CORE_URL + "DataAccess/GetMaster?type="+entity+"&fileName="+fileName+"&parentId="+parentId+"&childId="+childId+"&pageNumber="+pageNumber+"&pageType="+pageType+"&searchText="+searchText;
    console.log("CallAPI GetMasterData getMasterFunc",url);
    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then(data => {
            console.log("GetMasterData", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

export const addEntity = async (obj) => {
    // let {entity, fileName, ids } = obj;
    console.log("Add Entity ", obj);
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
        body: JSON.stringify(obj)
    }

    let url = BASE_CORE_URL + "DataAccess/AddEntity";

    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log("Add Entity", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

export const removeEntity = async (obj) => {
    let {entity, fileName, ids } = obj;

    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
        body: JSON.stringify(obj)
    }

    let url = BASE_CORE_URL + "DataAccess/RemoveEntity";

    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log("Add Entity", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

export const getDetails = async (id, parentId, fname, pageNo, searchText, searchType) => {
    const requestOptions = {
        method: "GET",
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
    }

    let url = BASE_CORE_URL + `DataAccess/GetDetails?childId=${id}&parentId=${parentId}&fileName=${fname}&pageNumber=${pageNo}&searchText=${searchText}&searchType=${searchType}`
    console.log("GetDetails CallAPI", url);
    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then((data) => {
            console.log("Get Details", data);
            return data;
        })
        .catch(err => console.log(err));
}

export const getReport = async (id, parentId, fname, searchText) => {
    const requestOptions = {
        method: 'GET',
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
    }

    let url = BASE_CORE_URL + `DataAccess/ExportData?childId=${id}&parentId=${parentId}&fileName=${fname}&searchText=${searchText}`
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("getReport", data);
            return data;
        })
        .catch((err) => console.log(err));
}

export const deleteFile = async (fileName) => {
    const requestOptions = {
        method: "GET",
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
    }

    let url = BASE_CORE_URL + `DataAccess/DeleteFile?fileName=${fileName}`
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("deleteFile", data)
            return data;
        })
        .catch((err) => console.log(err));
}