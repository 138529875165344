import React, { useEffect, useRef, useState } from 'react'
import {
    Accordion,
    Button,
    Card,
    Form,
    Pagination,
    Table,
    useAccordionToggle,
} from "react-bootstrap";
import { ArrowDown, ArrowUp, SearchIcon, SearchIconWhite } from "../../assets/images";
import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination';
import { getRegisteredUsers } from '../../services/notifService';
import RegisteredUsersList from './RegisteredUsersList';

const RegisteredUsers = ({ selectedUsersList, setSelectedUsersList, isNotifFormEnabled, userList, setUserList, siteListString, selectedAllRegUsers, setSelectedAllRegUsers, isDarkTheme }) => {
    const [userName, setUserName] = useState("");
    const [siteName, setSiteName] = useState("");
    const [totalString, setTotalString] = useState("");
    const [typingStart, setTypingStart] = useState(false);
    // const [userList, setUserList] = useState([]);
    const [totalDevices, setTotalDevices] = useState(0);
    const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);

    const [registerOpen, setRegisterOpen] = useState(false);

    useEffect(() => {
        setCurrentPageNo(1);
        setPageCounter(1)
        // getRegUsers("205", 1, totalString)
    }, []);

    useEffect(() => {
        if(userList.length === 0){
            setTotalDevices(0);
            setTotalPages(0);
        }
    }, [userList]);

    useEffect(() => {
        if (userName.length > 0) {
            setTypingStart(true);
        }
        if (userName === "" && typingStart) {
            //Call the API Here.
            searchIconClicked()
          }
    }, [userName])

    useEffect(() => {
        if (siteName.length > 0) {
            setTypingStart(true);
        }
        if (siteName === "" && typingStart) {
            //Call the API Here.
            searchIconClicked()
          }
    }, [siteName])

    useEffect(() => {
        if(siteListString != ""){
            getRegUsers(siteListString, currentPageNo, totalString)
        }else{
            setUserList([]);
            setSelectedUsersList([]);
        }
    }, [currentPageNo, siteListString])

    const getRegUsers = (siteIds, pageNumber, searchText) => {
        getRegisteredUsers(siteIds, pageNumber, searchText)
            .then((res) => {
                if (res.Status == "success") {
                    setTotalPages(parseInt(res.Data.Total));
                    setTotalDevices(parseInt(res.Data.Total))
                    let tempArr = res.Data.UserSiteModels.map((item) => {
                        let obj = {
                            ...item,
                            isItSelected: false
                        }
                        return obj;
                    })
                    let singleArray = selectedUsersList.map((i) => i.Item1);
                    let siteIdArray = selectedUsersList.map((i) => i.Item2);
                    let tempTwo = tempArr.map((item) => {
                        let obj;
                        let isPresent = false;
                        for(let i=0; i<selectedUsersList.length; i++){
                            if(item.UserUniqueId == selectedUsersList[i].Item1 && item.SiteId == selectedUsersList[i].Item2){
                                isPresent = true;
                                break;
                            }
                        }
                        // if(singleArray.includes(item.UserUniqueId) && siteIdArray.includes(item.SiteId)){
                        if(isPresent){
                            obj = {
                                ...item,
                                isItSelected: true
                            }
                        }else{
                            obj = {
                                ...item
                            }
                        }
                        return obj;
                    })
                    setUserList(tempTwo);
                    // setUserList(res.Data.UserSiteModels);

                }
            })
    }
    // const selectItemHandler = (id) => {
    //     const tempArr = userList.map((item, index) => {
    //         let obj;
    //         if(item.UserUniqueId == id){
    //             let selectedTemp = selectedUsersList;
    //             let tempObj = {
    //                 Item1 : item.UserUniqueId,
    //                 Item2 : item.SiteId,
    //             }
    //             if(!item.isItSelected){
    //                 selectedTemp.push(tempObj);
    //                 setSelectedUsersList(selectedTemp);
    //             }else{
    //                 let temp = selectedTemp.filter((i) => i.Item1 != item.UserUniqueId);
    //                 setSelectedUsersList(temp);
    //             }
    //             obj = {
    //                 ...item,
    //                 isItSelected: !item.isItSelected
    //             }           
    //         }
    //             else{
    //             obj = {
    //                 ...item
    //             }
    //         }
    //         return obj;
    //     })
    //     setUserList(tempArr);
    // }

    const selectItemHandler = (id, siteId) => {
        const tempArr = userList.map((item, index) => {
            let obj;
            if(item.UserUniqueId == id && item.SiteId == siteId){
                let selectedTemp = selectedUsersList;
                let tempObj = {
                    Item1 : item.UserUniqueId,
                    Item2 : item.SiteId,
                }
                if(!item.isItSelected){
                    selectedTemp.push(tempObj);
                    setSelectedUsersList(selectedTemp);
                }else{
                    let temp = selectedTemp.filter((i) => {
                        let bool = false;
                        if(i.Item1 == id && i.Item2 == siteId){
                            bool = false;
                        }else{
                            bool = true;
                        }
                        return bool;
                        // return i.Item1 != item.UserUniqueId && i.Item2 != item.SiteId
                    })
                    setSelectedUsersList(temp);
                }
                obj = {
                    ...item,
                    isItSelected: !item.isItSelected
                }           
            }
                else{
                obj = {
                    ...item
                }
            }
            return obj;
        })
        setUserList(tempArr);
    }

    const selectAllHandler = () => {
        let temp = selectedAllRegUsers;
        setSelectedAllRegUsers(!selectedAllRegUsers);
        let selectedTempArr = [];
        let tempOne = selectedUsersList;
        let tempTwo = [];
        const tempArr = userList.map((item, index) => {
            if(temp === true){
                tempOne = tempOne.filter((i) => i.Item1 != item.UserUniqueId);
            }else{  
                let tempObj = {
                    Item1 : item.UserUniqueId,
                    Item2 : item.SiteId,
                }
                selectedTempArr.push(tempObj);
            }
            let obj = {
                ...item,
                isItSelected: !selectedAllRegUsers
            }
            return obj;
        });
        if(temp){
            setSelectedUsersList(tempOne);            
        }else{
            setSelectedUsersList(selectedTempArr);
        }
        setUserList(tempArr);
    }

    const searchIconClicked = () => {
        let obj = {
            "userName": userName,
            "siteName": siteName
        }
        let stringText = JSON.stringify(obj);
        let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";")
        setTotalString(tempStringText);
        getRegUsers(siteListString, 1, tempStringText)
    }

    const PaginationComponent = (currentPageNo) => {
        let numbers = [];
        if (maxPageAllowed - 2 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 2}
                    active={maxPageAllowed - 2 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
                >
                    {maxPageAllowed - 2}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else if (maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else {
            for (let i = currentPageNo; i < currentPageNo + 3; i++) {
                if (i <= maxPageAllowed) {
                    numbers.push(
                        <Pagination.Item
                            key={i}
                            active={i == currentPageNo}
                            onClick={() => setCurrentPageNo(i)}
                        >
                            {i}
                        </Pagination.Item>
                    )
                }
            }
        }
        return numbers;
    }

    const uniqueKey = (data) => {
        return `${data.UserUniqueId}_${data.SiteId}`
    }

    return (
        // <Accordion defaultActiveKey={activeId}>
        //     <Card>
        //         <Card.Header
        //             style={{
        //                 display: "flex",
        //                 justifyContent: "space-between",
        //                 borderBottom: "0px",
        //                 backgroundColor: "#f2f6fe",
        //                 fontWeight: "500",
        //             }}
        //         >
        //             Registered Users
        //             <Accordion.Toggle
        //                 eventKey="0"
        //                 style={{ backgroundColor: "#f2f6fe" }}                        
        //             >
        //                 <img src={ArrowDown} className='AccordianArrow' alt="dropdown-arrow" />
        //             </Accordion.Toggle>
        //         </Card.Header>
        //         <Accordion.Collapse eventKey="0">
        //             <Card.Body style={{ padding: "10px" }}>
        //                 {/* <div className="SelectionListWithSelectAll">
        //     <input
        //       className="SearchViewInput1 searchNotification"
        //       type="search"
        //       placeholder="Search"
        //       // value={searchTextOne}
        //       // onChange={(e) => searchHandlerOne(e)}
        //     />
        //     <img src={SearchIcon} style={{ cursor: "pointer" }} />
        //   </div>
        //   <div className="HgtCheckBoxList">
        //     <ul className="submenu">
        //       <li>Alam</li>
        //       <li>Abdul</li>
        //       <li>Sourabh</li>
        //       <li>Jovin</li>
        //       <li>Rupesh</li>
        //       <li>Vyenktesh</li>
        //       <li>Devendra</li>
        //       <li>Sanket</li>
        //       <li>Akshata</li>
        //       <li>Rupali</li>
        //       <li>Pravin</li>
        //       <li>Alwin</li>
        //     </ul>
        //     <div className="NoRecordFound">
        //       <p>No User Record.</p>
        //     </div>
        //   </div> */}
        //                 <div className=" p-0">
        //                     <Table
        //                         className="table table-text-ext table-fixed report-table NotificationUserTable"
        //                         id="alert-activity-report"
        //                     >
        //                         <thead className="ReportTableHeader ReportTableHeadReport">
        //                             <tr>
        //                                 <th style={{ width: "5%" }}>
        //                                     {/* <Form.Check aria-label="option 1" disabled={!isNotifFormEnabled} checked={selectedAllRegUsers} onChange={(e) => selectAllHandler(e)} /> */}
        //                                 </th>
        //                                 <th>
        //                                     <p>Username</p>
        //                                     <div className="SearchNotification">
        //                                         <input
        //                                             className="SearchViewInput"
        //                                             type="text"
        //                                             placeholder="Search"
        //                                             value={userName}
        //                                             onChange={(e) => setUserName(e.target.value)}
        //                                         />
        //                                         <img
        //                                             className="EyeIcon"
        //                                             alt="Search-icon"
        //                                             src={SearchIcon}
        //                                             onClick={searchIconClicked}
        //                                         />
        //                                     </div>
        //                                 </th>
        //                                 <th>
        //                                     <p>Site Name</p>
        //                                     <div className="SearchNotification">
        //                                         <input
        //                                             className="SearchViewInput"
        //                                             type="text"
        //                                             placeholder="Search"
        //                                             value={siteName}
        //                                             onChange={(e) => setSiteName(e.target.value)}
        //                                         />
        //                                         <img
        //                                             className="EyeIcon"
        //                                             alt="Search-icon"
        //                                             src={SearchIcon}
        //                                             onClick={searchIconClicked}
        //                                         />
        //                                     </div>
        //                                 </th>
        //                             </tr>
        //                         </thead> 
        //                         <tbody>
        //                             {
        //                                 userList && (
        //                                 userList?.length > 0 ? 
        //                                 userList.map((item) => (
        //                                     <RegisteredUsersList key={uniqueKey(item)} item={item} isNotifFormEnabled={isNotifFormEnabled} fullName={item.FullName} siteName={item.SiteName} selectItemHandler={selectItemHandler} selectAllHandler={selectAllHandler} />
        //                                 )) : <div className='noRegisterFound'>
        //                                 <p>No Record Found.</p>
        //                             </div>
        //                                 )
        //                             }
        //                             {
        //                                 !userList && (
        //                                     <div className='noRegisterFound'>
        //                                 <p>No Record Available.</p>
        //                             </div>
        //                                 )
        //                             }
        //                         </tbody>
        //                         {
        //                             totalDevices == 0 || totalPages == 0 || totalPages <= limit
        //                                 ? null
        //                                 :
        //                                 <div className="PaginationDiv mb-2 mt-2">
        //                                     <h5 className="PageNumbers">
        //                                         {
        //                                             totalDevices == 0 ? 0
        //                                                 : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
        //                                         -
        //                                         {
        //                                             (currentPageNo * limit) > (totalDevices)
        //                                                 ? (totalDevices)
        //                                                 : currentPageNo * limit
        //                                         }
        //                                     </h5>
        //                                     <span>of</span>
        //                                     <h5 className='PageNumbers'>{totalDevices}</h5>

        //                                     <Pagination>
        //                                         <Pagination.Prev onClick={prevClick} disabled={currentPageNo == 1} />
        //                                         {
        //                                             PaginationComponent(currentPageNo)
        //                                         }
        //                                         <Pagination.Next onClick={nextClick} disabled={currentPageNo == maxPageAllowed} />                                    </Pagination>
        //                                 </div>
        //                         }
        //                     </Table>
        //                 </div>
        //             </Card.Body>
        //         </Accordion.Collapse>
        //     </Card>
        // </Accordion>

        <Accordion defaultActiveKey="0">
            <Card>
                    <Accordion.Toggle
                    as={Card.Header}
                        eventKey="1" className='NotificationCardAccordion'
                        onClick={() => setRegisterOpen(!registerOpen)}                        
                    >
                        Registered Users
                        {
                            !registerOpen ?  
                            <img src={ArrowDown} alt="dropdown-arrow" /> :
                            <img src={ArrowUp} alt='up arrow' />
                        }
                    </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Card.Body style={{ padding: "10px" }}>
                        {/* <div className="SelectionListWithSelectAll">
            <input
              className="SearchViewInput1 searchNotification"
              type="search"
              placeholder="Search"
              // value={searchTextOne}
              // onChange={(e) => searchHandlerOne(e)}
            />
            <img src={SearchIcon} style={{ cursor: "pointer" }} />
          </div>
          <div className="HgtCheckBoxList">
            <ul className="submenu">
              <li>Alam</li>
              <li>Abdul</li>
              <li>Sourabh</li>
              <li>Jovin</li>
              <li>Rupesh</li>
              <li>Vyenktesh</li>
              <li>Devendra</li>
              <li>Sanket</li>
              <li>Akshata</li>
              <li>Rupali</li>
              <li>Pravin</li>
              <li>Alwin</li>
            </ul>
            <div className="NoRecordFound">
              <p>No User Record.</p>
            </div>
          </div> */}
                        <div className=" p-0">
                            <Table
                                className="table table-text-ext table-fixed report-table NotificationUserTable"
                                id="alert-activity-report"
                            >
                                <thead className="ReportTableHeader ReportTableHeadReport">
                                    <tr>
                                        <th style={{ width: "5%" }}>
                                            {/* <Form.Check aria-label="option 1" disabled={!isNotifFormEnabled} checked={selectedAllRegUsers} onChange={(e) => selectAllHandler(e)} /> */}
                                        </th>
                                        <th>
                                            <p>Username</p>
                                            <div className="SearchNotification">
                                                <input
                                                    className="SearchViewInput"
                                                    type="text"   
                                                    placeholder="Search"
                                                    value={userName}
                                                    onChange={(e) => setUserName(e.target.value)}
                                                />
                                                {
                                                    isDarkTheme ?
                                                    <img
                                                        className="EyeIcon"
                                                        alt="Search-icon"
                                                        src={SearchIcon}
                                                        onClick={searchIconClicked}
                                                    /> :
                                                    <img
                                                        className="EyeIcon"
                                                        alt="Search-icon"
                                                        src={SearchIconWhite} height="15"
                                                        onClick={searchIconClicked}
                                                    />
                                                }
                                            </div>
                                        </th>
                                        <th>
                                            <p>Site Name</p>
                                            <div className="SearchNotification">
                                                <input
                                                    className="SearchViewInput"
                                                    type="text"
                                                    placeholder="Search"
                                                    value={siteName}
                                                    onChange={(e) => setSiteName(e.target.value)}
                                                />
                                                {
                                                    isDarkTheme ?
                                                    <img
                                                        className="EyeIcon"
                                                        alt="Search-icon"
                                                        src={SearchIcon}
                                                        onClick={searchIconClicked}
                                                    /> :
                                                    <img
                                                        className="EyeIcon"
                                                        alt="Search-icon"
                                                        src={SearchIconWhite} height="15"
                                                        onClick={searchIconClicked}
                                                    />
                                                }
                                            </div>
                                        </th>
                                    </tr>
                                </thead> 
                                <tbody>
                                    {
                                        userList && (
                                        userList?.length > 0 ? 
                                        userList.map((item) => (
                                            <RegisteredUsersList key={uniqueKey(item)} item={item} isNotifFormEnabled={isNotifFormEnabled} fullName={item.FullName} siteName={item.SiteName} selectItemHandler={selectItemHandler} selectAllHandler={selectAllHandler} />
                                        )) : <div className='noRegisterFound'>
                                        <p>No Record Found.</p>
                                    </div>
                                        )
                                    }
                                    {
                                        !userList && (
                                            <div className='noRegisterFound'>
                                        <p>No Record Available.</p>
                                    </div>
                                        )
                                    }
                                </tbody>
                                {
                                    totalDevices == 0 || totalPages == 0 || totalPages <= limit
                                        ? null
                                        :
                                        <div className="PaginationDiv mb-2 mt-2">
                                            <h5 className="PageNumbers">
                                                {
                                                    totalDevices == 0 ? 0
                                                        : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                                                -
                                                {
                                                    (currentPageNo * limit) > (totalDevices)
                                                        ? (totalDevices)
                                                        : currentPageNo * limit
                                                }
                                            </h5>
                                            <span>of</span>
                                            <h5 className='PageNumbers'>{totalDevices}</h5>

                                            <Pagination>
                                                <Pagination.Prev onClick={prevClick} disabled={currentPageNo == 1} />
                                                {
                                                    PaginationComponent(currentPageNo)
                                                }
                                                <Pagination.Next onClick={nextClick} disabled={currentPageNo == maxPageAllowed} />                                  
                                            </Pagination>
                                        </div>
                                }
                            </Table>
                        </div>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default RegisteredUsers