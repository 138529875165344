import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import {
  CameraBlock,
  CameraStatus,
  DVRStatus,
  DeviceGrayIcon,
  FaultyDevice,
  HDDstatus,
  InfoBlue,
  InfoBlueWhite,
  OpenPopupIconBlueWhite,
  OpenPopupIconBlue,
  RefreshBtnIcon,
  SystemHealth,
} from "../../assets/images";


import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import HealthCard from "./HealthCard";
import { AllWidgetData } from "../../services/healthMonitoringService";
import HealthTable from "./HealthTable";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import HealthOfflineDevices from "./HealthOfflineDevices";
import HealthEvents from "./HealthEvents";

//charts---
import { Chart as ChartJS, ArcElement,  Legend , LinearScale, CategoryScale, PointElement, TimeScale, BarElement} from 'chart.js';
import HealthPercent from "./HealthPercent";
// import { Button, Collapse } from "react-bootstrap";



ChartJS.register(ArcElement, Legend, TimeScale, LinearScale, CategoryScale, PointElement, BarElement);


const tooltip = (
  <Tooltip id="tooltip">
      Camera Tampering includes, Camera Block, Scene Change and Camera Tampering
  </Tooltip>
);




const dummyData = [

  {

    DeviceName: "Cam 01",

    SIteName: "Kurla",

    Count: "10:50:34"

  },

  {

    DeviceName: "Cam 02",

    SIteName: "Sion",

    Count: "06:32:34"

  },

  {

    DeviceName: "Cam 05",

    SIteName: "Matunga",

    Count: "15:50:34"

  }

]

const HealthMonitoring = ({  isDarkTheme }) => {
  let history = useHistory();

  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [refreshButton, setRefreshButton] = useState(false);
  const[allWidgetData,setAllWidgetData]=useState([]);
  const [cameraOnlineCount, setCameraOnlineCount] = useState(0);
  const [cameraOfflineCount, setCameraOfflineCount] = useState(0);

  const [nvrOnlineCount, setNVROnlineCount] = useState(0);
  const [nvrOfflineCount, setNVROfflineCount] = useState(0);

  const [hddOnlineCount, setHDDOnlineCount] = useState(0);
  const [hddOfflineCount, setHDDOfflineCount] = useState(0);
  
  const [totalCameraBlockCount,setTotalCameraBlockCount]=useState(0);
  const [systemHealthDevicesOfflineCount, setSystemHealthDevicesOfflineCount] = useState(0);
  const [systemHealthDevicesOnlineCount, setSystemHealthDevicesOnlineCount] = useState(0);
  const [onlinePercent, setOnlinePercent] = useState(0);

  const[top3OfflineDevices,setTop3OfflineDevices]=useState([]);
  // const [open, setOpen] = useState(false);

  const [tempPlugins, setTempPlugins] = useState(
    [{
      beforeDraw: function(chart) {
       var width = chart.width,
           height = chart.height,
           ctx = chart.ctx;
           ctx.restore();
           ctx.font ="bolder 28px sans-serif";
           ctx.textBaseline = "middle";
           var text = `${onlinePercent}%`,
           textX = Math.round((width - ctx.measureText(text).width) / 2),
           textY = height / 2;
           ctx.fillText(text, textX, textY);
           ctx.save();
      } 
    }]
  );
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getAllWidgetData();
  }, [])

  const refreshData = () => {
    setRefreshButton(true);
    getAllWidgetData();
  }

  const tooltip1 = (
    <Tooltip id="tooltip1">
      <div style={{textAlign: "left"}}>
        <p style={{maxWidth: "150px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}} className="mb-0">{top3OfflineDevices[0]?.Count.slice(0,-3)}</p>
        <div style={{height: "auto"}}>
        <p className="deviceNameTooltipText mb-0">Device Name - </p> 
        <p className="deviceNameTooltipName mb-0">{top3OfflineDevices[0]?.DeviceName}</p>
        </div>
        <div style={{height: "auto"}}>
        <p className="siteNameTooltipText mb-0">Site Name - </p> 
        <p className="siteNameTooltipName mb-0">{top3OfflineDevices[0]?.SIteName}</p>
        </div>
      </div>
    </Tooltip>
  );

  const tooltip2 = (
    <Tooltip id="tooltip2">
      <div style={{textAlign: "left"}}>
        <p style={{maxWidth: "150px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}} className="mb-0">{top3OfflineDevices[1]?.Count.slice(0,-3)}</p>
        <div style={{height: "auto"}}>
        <p className="deviceNameTooltipText mb-0">Device Name - </p> 
        <p className="deviceNameTooltipName mb-0">{top3OfflineDevices[1]?.DeviceName}</p>
        </div>
        <div style={{height: "auto"}}>
        <p className="siteNameTooltipText mb-0">Site Name - </p> 
        <p className="siteNameTooltipName mb-0">{top3OfflineDevices[1]?.SIteName}</p>
        </div>
      </div>
    </Tooltip>
  );

  const tooltip3 = (
    <Tooltip id="tooltip3">
      <div style={{textAlign: "left"}}>
        <p className="timeTooltipCount mb-0">{top3OfflineDevices[2]?.Count.slice(0,-3)}</p>
        <div style={{height: "auto"}}>
        <p className="deviceNameTooltipText mb-0">Device Name - </p> 
        <p className="deviceNameTooltipName mb-0">{top3OfflineDevices[2]?.DeviceName}</p>
        </div>
        <div style={{height: "auto"}}>
        <p className="siteNameTooltipText mb-0">Site Name - </p> 
        <p className="siteNameTooltipName mb-0">{top3OfflineDevices[2]?.SIteName}</p>
        </div>
      </div>
    </Tooltip>
  );

  // All Widget Data API Consumption
  const getAllWidgetData = () => {
    setLoading(true);
    let tempUser = JSON.parse(localStorage.getItem('user'));
    let userUniqueID = tempUser.UserUniqueID
    try {
      AllWidgetData(userUniqueID).then((resp) => {
        if (resp != undefined) {
          if (resp.Status == "success") {
            let onlineCount = 0;
            let offlineCount = 0;
            let total = 0
            let totalSystemHealthOffline = resp.Data.filter(item => item.Description == "System Health" && item.Status == "0")
            setSystemHealthDevicesOfflineCount(totalSystemHealthOffline[0]?.Count || 0)
            offlineCount = totalSystemHealthOffline[0]?.Count || 0
            let totalSystemHealthOnline = resp.Data.filter(item => item.Description == "System Health" && item.Status == "1")
            setSystemHealthDevicesOnlineCount(totalSystemHealthOnline[0]?.Count || 0)
            onlineCount = totalSystemHealthOnline[0]?.Count || 0
             
            let tempCount = 0
            let tempOfflineNVR = resp.Data.filter(item => (item.Description == "NVR" || item.Description == "DVR" || item.Description == "Hybrid") && item.Status == "0")
            if (tempOfflineNVR != undefined && tempOfflineNVR.length > 0) {
              tempOfflineNVR?.map(x => tempCount += parseInt(x.Count))
            }
            // setNVROfflineCount(tempOfflineNVR[0]?.Count || 0)
            setNVROfflineCount(tempCount)
            
            tempCount = 0
            let tempOnlineNVR = resp.Data.filter(item => (item.Description == "NVR" || item.Description == "DVR" || item.Description == "Hybrid") && item.Status == "1")
            if (tempOnlineNVR != undefined && tempOnlineNVR.length > 0) {
              tempOnlineNVR?.map(x => tempCount += parseInt(x.Count))
            }
            // setNVROnlineCount(tempOnlineNVR[0]?.Count || 0)
            setNVROnlineCount(tempCount)

            let tempOfflineCamera = resp.Data.filter(item => item.Description == "Camera" && item.Status == "0")
            setCameraOfflineCount(tempOfflineCamera[0]?.Count || 0)
            let tempOnlineCamera = resp.Data.filter(item => item.Description == "Camera" && item.Status == "1")
            setCameraOnlineCount(tempOnlineCamera[0]?.Count || 0)

            let tempOfflineHDD = resp.Data.filter(item => item.Description == "HDD" && item.Status == "0")
            setHDDOfflineCount(tempOfflineHDD[0]?.Count || 0)
            let tempOnlineHDD = resp.Data.filter(item => item.Description == "HDD" && item.Status == "1")
            setHDDOnlineCount(tempOnlineHDD[0]?.Count || 0)

            let totalCameraBlock = resp.Data.filter(item => item.Description == "CameraBlockAlert")
            setTotalCameraBlockCount(totalCameraBlock[0]?.Count || 0)

            let top3OfflineDevices = resp.Data.filter(item => item.Description == "OfflineDevice")
            setTop3OfflineDevices(top3OfflineDevices)

            setLoading(false);

            total = parseInt(onlineCount) + parseInt(offlineCount)
            // let percent = (onlineCount / total) * 100;
            let percent = (onlineCount / total) * 100;
            if(isNaN(percent)){
              setOnlinePercent(0);
            }else{
              setOnlinePercent(percent.toFixed(0));
            }
          } else {
            setAllWidgetData(null)
            setLoading(false);
          }
        }
      })
    } catch (error) {
      console.error(error)
    }
  }


  /* Chart data start here */
  const data = {
    labels: ['Online','Offline'],
    datasets: [
        {
            label: 'Values',
            data: [systemHealthDevicesOnlineCount,systemHealthDevicesOfflineCount],
            borderRadius: 0,
            backgroundColor: ['#1ab243', '#dddddd'],
            borderWidth: 0,
            //hoverOffset: 4
        }
        
    ],
    
}
const options = {
    plugins: {       
        legend: {
            display: false,
        },
        tooltip: {
          enabled: false
        }
    },
    cutout: "83%",  
}
let plugins = [{
    beforeDraw: function(chart) {
     var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         ctx.font ="bolder 28px sans-serif";
         ctx.textBaseline = "middle";
         var text = `${onlinePercent}%`,
         textX = Math.round((width - ctx.measureText(text).width) / 2),
         textY = height / 2;
         ctx.fillText(text, textX, textY);
         ctx.save();
    } 
  }]
/* Chart data end here */

useEffect(() => {
  let plugins = [{
    beforeDraw: function(chart) {
     var width = chart.width,
         height = chart.height,
         ctx = chart.ctx;
         ctx.restore();
         ctx.font ="bolder 28px sans-serif";
         ctx.textBaseline = "middle";
         var text = `${onlinePercent}%`,
         textX = Math.round((width - ctx.measureText(text).width) / 2),
         textY = height / 2;
         ctx.fillText(text, textX, textY);
         ctx.save();
    } 
  }]
    setTempPlugins(plugins)
}, [onlinePercent])

  return (
    <>
     <div className='MainContentMargin'>
      <div className="col-lg-12 HMHeader mt-3 mb-3">
        <div className="NameHeading1" style={{ width: "100%" }}>
          <p className="config-tool-head" style={{ float: "left" }}>
            Health Monitoring
          </p>
          <button
            className="DataAccessEdit mt-1"
            style={{ width: "125px", float: "right" }}
            onClick={refreshData}
          >
            <img src={RefreshBtnIcon} alt="Update Data" />
            <span className="ViewUpdate ml-1">Update Data</span>
          </button>

          <div className="ml-3" style={{ marginBottom: "3px" }}>
          </div>
        </div>
      </div>

      <div className="HeightScrollCameraGrid HeightScrollSitesGrid" style={{position: "relative"}}>
        <div>
          <div className="RowClass">
              <div className="grid-container grid-container--fit">
                  <div className="CardBoxWithBorder">
                      <div className='CardBoxContent'>
                          <div className="CardBoxImage" style={{backgroundColor:"#1e93c2"}}>
                              <img src={SystemHealth} alt="System health"/>
                          </div>
                          <div className='CardBoxText' style={{display: "flex", justifyContent:"space-between"}}>
                              <div>
                                  <h4>System Health</h4>
                                  <div className="StatusText">
                                      <div className="StatusValueDiv" style={{width:"auto"}}>
                                          {
                                            loading 
                                            ? (
                                              <>
                                              <Skeleton width={70} className="statusValueText" />
                                              <Skeleton width={50} className="statusValueP" />
                                              </>
                                            )
                                            : (<>
                                              <p className="statusValueP">{(parseInt(systemHealthDevicesOfflineCount)) + (parseInt(systemHealthDevicesOnlineCount))}</p>                            
                                              <p className="statusValueText">Total Devices</p>
                                            </>)
                                          }

                                      </div>
                                  </div>
                              </div>
                              
                              <div className="DonutChartWidthDiv">
                                  <div style={{width: "100%", height:"125px",display:"flex", justifyContent:"flex-end"}}>
                                      {
                                        loading && !onlinePercent
                                        ? null
                                        : <HealthPercent systemHealthDevicesOnlineCount={systemHealthDevicesOnlineCount} systemHealthDevicesOfflineCount={systemHealthDevicesOfflineCount} onlinePercent={onlinePercent} isDarkTheme={isDarkTheme} />
                                      }
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <HealthCard 
                  cardName="DVR/NVR Status"
                  imageName={DVRStatus}
                  loading={loading}
                  altImageName="DVR/NVR status"
                  bgColor="#da8012"
                  offlineCount={nvrOfflineCount}
                  onlineCount={nvrOnlineCount}
                  totalCount={(parseInt(nvrOfflineCount)) + (parseInt(nvrOnlineCount))}
                  />
                  <HealthCard 
                  cardName="Camera Status"
                  imageName={CameraStatus}
                  loading={loading}
                  altImageName="Camera status"
                  bgColor="#6459d6"
                  offlineCount={cameraOfflineCount}
                  onlineCount={cameraOnlineCount}
                  totalCount={(parseInt(cameraOfflineCount)) + (parseInt(cameraOnlineCount))}
                  />
          </div>
        </div>

          <div className="RowClass">
            <div className="grid-container grid-container--fit">
            <HealthCard
              cardName="Storage Device Status"
              imageName={HDDstatus}
              loading={loading}
              altImageName="HDD status"
              bgColor="#561400"
              offlineCount={hddOfflineCount}
              onlineCount={hddOnlineCount}
              totalCount={(parseInt(hddOfflineCount)) + (parseInt(hddOnlineCount))}
            />
            <div className="CardBoxWithBorder">
                  <div className='CardBoxContent'>
                      <div className="CardBoxImage" style={{backgroundColor:"#1d8cdb"}}>
                          <img src={CameraBlock} alt="Camera Block"/>
                      </div>
                      <div className='CardBoxText' style={{position:"relative", display:"block"}}>
                        <div className="CameraInfoDiv" >
                          <OverlayTrigger placement="left" overlay={tooltip}>
                              <button style={{background:"transparent"}}>
                              {
                                isDarkTheme ?
                                <img alt="InfoBlue" src={InfoBlue} />  :
                                <img alt="InfoWhite" src={ InfoBlueWhite }  height="17"/>
                              }
                             
                              </button>
                          </OverlayTrigger>
                          
                          <OverlayTrigger placement="right" overlay={<Tooltip>View details</Tooltip>}>
                          <button style={{background:"transparent"}} onClick={handleShow}>
                            {
                              isDarkTheme ? 
                              <img alt="OpenPopup" src={OpenPopupIconBlue}/> :
                              <img alt="OpenPopupWhite" src={OpenPopupIconBlueWhite} height="17p"/>

                            }
                          </button>
                          </OverlayTrigger>
                        </div>

                        <h4>Camera Tampering For Today</h4>
                     
                        <div className="OnlySingleValue">
                          {loading ? (
                            <Skeleton width={50} />
                          ) : (
                            <p className="statusValueP">{totalCameraBlockCount}</p>
                          )}
                        </div>
                      </div>
                  </div>
            </div>
              <div className="CardBoxWithBorder">
                  <div className='CardBoxContent'>
                      <div className="CardBoxImage"  style={{backgroundColor:"#27ad93"}}>
                          <img src={FaultyDevice} alt="Faulty Device"/>
                      </div>
                      <div className='CardBoxText'>
                         
                          <div className="offlineDevDiv">
                            <h4 style={{marginTop: "0px"}}>Top 3 Offline Camera Devices For Today</h4>

                            <div className="StatusText3">
                                <div className="StatusValueDiv">      
                                  <p className="statusValueP">
                                    <span>{top3OfflineDevices[0]?.Count.slice(0,-3)}</span>
                                    <OverlayTrigger placement="right" overlay={tooltip1}>
                                    <span className="InfoImg">
                                        <button style={{background:"transparent"}}>
                                          {
                                            isDarkTheme ? 
                                            <img alt="InfoBlue" src={InfoBlue}/> :
                                            <img alt="InfoWhite" src={ InfoBlueWhite }  height="17"/>
                                          }
                                        </button>
                                    </span>
                                    </OverlayTrigger>
                                  </p>
                                  <div className="mb-1">
                                    <div className="OfflineDeviceDiv">
                                      <img alt="Device" src={DeviceGrayIcon} />
                                      <p className="offlineDeviceText">{top3OfflineDevices[0]?.DeviceName}</p>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>

                          <div style={{textAlign: "end"}}>
                            <div className="StatusText1">
                                <div className="StatusValueDiv">      
                                  <p className="statusValueP">
                                    <span>{top3OfflineDevices[1]?.Count.slice(0,-3)}</span>
                                    <OverlayTrigger placement="bottom" overlay={tooltip2}>
                                    <span className="InfoImg">
                                      <button style={{background:"transparent"}}>
                                      {
                                            isDarkTheme ? 
                                            <img alt="InfoBlue" src={InfoBlue}/> :
                                            <img alt="InfoWhite" src={ InfoBlueWhite }  height="17"/>
                                          }
                                      </button>
                                    </span>
                                    </OverlayTrigger>
                                  </p>
                                  <div className="mb-1">
                                    <div className="OfflineDeviceDiv">
                                      <img alt="Device" src={DeviceGrayIcon} />
                                      <p className="offlineDeviceText">{top3OfflineDevices[1]?.DeviceName}</p>
                                    </div>
                                  </div>
                                </div>
                            </div>
                            <div className="StatusText2">
                                <div className="StatusValueDiv">      
                                  <p className="statusValueP">
                                    <span>{top3OfflineDevices[2]?.Count.slice(0,-3)}</span>
                                    <OverlayTrigger placement="bottom" overlay={tooltip3}>
                                      <span className="InfoImg">
                                        <button style={{background:"transparent"}}>
                                          {
                                            isDarkTheme ? 
                                            <img alt="InfoBlue" src={InfoBlue}/> :
                                            <img alt="InfoWhite" src={ InfoBlueWhite }  height="17"/>
                                          }
                                        </button>
                                      </span>
                                    </OverlayTrigger>
                                  </p>
                                  <div className="mb-1">
                                    <div className="OfflineDeviceDiv">
                                      <img alt="Device" src={DeviceGrayIcon} />
                                      <p className="offlineDeviceText">{top3OfflineDevices[2]?.DeviceName}</p>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>

                          {/* Old UI */}
                          <div style={{display: "none"}}>
                            {
                              loading
                              ?
                              <>
                                <div className="StatusValueDiv">
                                  <div className="mb-1">
                                    <Skeleton width={70} />
                                    <Skeleton width={70} />
                                  </div>
                                  <Skeleton width={70} className="statusValueP" />
                              
                                </div>
                                <div className="StatusValueDiv">
                                    <div className="mb-1">
                                        <Skeleton width={70} />
                                        <Skeleton width={70} />
                                      </div>
                                      <Skeleton width={70} className="statusValueP" />
                                </div>
                                <div className="StatusValueDiv">
                                <div className="mb-1">
                                        <Skeleton width={70} />
                                        <Skeleton width={70} />
                                      </div>
                                      <Skeleton width={70} className="statusValueP" />
                                </div>
                              </>
                              : <>
                              {
                                top3OfflineDevices.map((item) => (
                                   <HealthOfflineDevices key={item.DeviceName} item={item}/>
                               ))
                               }
                            </> 
                            }                                    
                          </div>
                      </div>
                  </div>
              </div>   
          </div>
        </div>
        
        {/* <div style={{position: "absolute", top: "370px", width: "100%"}}>
          <Collapse in={open}>
            <div id="example-collapse-text">
              <div className="RowClass">
                  <div className="grid-container grid-container--fit">
                  <HealthCard
                    cardName="Storage Device Status"
                    imageName={HDDstatus}
                    loading={loading}
                    altImageName="HDD status"
                    bgColor="#561400"
                    offlineCount={hddOfflineCount}
                    onlineCount={hddOnlineCount}
                    totalCount={(parseInt(hddOfflineCount)) + (parseInt(hddOnlineCount))}
                  />
                  <div className="CardBoxWithBorder">
                        <div className='CardBoxContent'>
                            <div className="CardBoxImage" style={{backgroundColor:"#1d8cdb"}}>
                                <img src={CameraBlock} alt="Camera Block"/>
                            </div>
                            <div className='CardBoxText' style={{position:"relative", display:"block"}}>
                              <div className="CameraInfoDiv" >
                                <OverlayTrigger placement="left" overlay={tooltip}>
                                    <button style={{background:"transparent"}}>
                                    {
                                      isDarkTheme ?
                                      <img alt="InfoBlue" src={InfoBlue} />  :
                                      <img alt="InfoWhite" src={ InfoBlueWhite }  height="17"/>
                                    }
                                  
                                    </button>
                                </OverlayTrigger>
                                
                                <OverlayTrigger placement="right" overlay={<Tooltip>View details</Tooltip>}>
                                <button style={{background:"transparent"}} onClick={handleShow}>
                                  {
                                    isDarkTheme ? 
                                    <img alt="OpenPopup" src={OpenPopupIconBlue}/> :
                                    <img alt="OpenPopupWhite" src={OpenPopupIconBlueWhite} height="17p"/>

                                  }
                                </button>
                                </OverlayTrigger>
                              </div>

                              <h4>Camera Tampering For Today</h4>
                          
                              <div className="OnlySingleValue">
                                {loading ? (
                                  <Skeleton width={50} />
                                ) : (
                                  <p className="statusValueP">{totalCameraBlockCount}</p>
                                )}
                              </div>
                            </div>
                        </div>
                  </div>
                    <div className="CardBoxWithBorder">
                        <div className='CardBoxContent'>
                            <div className="CardBoxImage"  style={{backgroundColor:"#27ad93"}}>
                                <img src={FaultyDevice} alt="Faulty Device"/>
                            </div>
                            <div className='CardBoxText'>
                              
                                <div className="offlineDevDiv">
                                  <h4 style={{marginTop: "0px"}}>Top 3 Offline Camera Devices For Today</h4>

                                  <div className="StatusText3">
                                      <div className="StatusValueDiv">      
                                        <p className="statusValueP">
                                          <span>{top3OfflineDevices[0]?.Count.slice(0,-3)}</span>
                                          <OverlayTrigger placement="right" overlay={tooltip1}>
                                          <span className="InfoImg">
                                              <button style={{background:"transparent"}}>
                                                {
                                                  isDarkTheme ? 
                                                  <img alt="InfoBlue" src={InfoBlue}/> :
                                                  <img alt="InfoWhite" src={ InfoBlueWhite }  height="17"/>
                                                }
                                              </button>
                                          </span>
                                          </OverlayTrigger>
                                        </p>
                                        <div className="mb-1">
                                          <div className="OfflineDeviceDiv">
                                            <img alt="Device" src={DeviceGrayIcon} />
                                            <p className="offlineDeviceText">{top3OfflineDevices[0]?.DeviceName}</p>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>

                                <div style={{textAlign: "end"}}>
                                  <div className="StatusText1">
                                      <div className="StatusValueDiv">      
                                        <p className="statusValueP">
                                          <span>{top3OfflineDevices[1]?.Count.slice(0,-3)}</span>
                                          <OverlayTrigger placement="bottom" overlay={tooltip2}>
                                          <span className="InfoImg">
                                            <button style={{background:"transparent"}}>
                                            {
                                                  isDarkTheme ? 
                                                  <img alt="InfoBlue" src={InfoBlue}/> :
                                                  <img alt="InfoWhite" src={ InfoBlueWhite }  height="17"/>
                                                }
                                            </button>
                                          </span>
                                          </OverlayTrigger>
                                        </p>
                                        <div className="mb-1">
                                          <div className="OfflineDeviceDiv">
                                            <img alt="Device" src={DeviceGrayIcon} />
                                            <p className="offlineDeviceText">{top3OfflineDevices[1]?.DeviceName}</p>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                  <div className="StatusText2">
                                      <div className="StatusValueDiv">      
                                        <p className="statusValueP">
                                          <span>{top3OfflineDevices[2]?.Count.slice(0,-3)}</span>
                                          <OverlayTrigger placement="bottom" overlay={tooltip3}>
                                            <span className="InfoImg">
                                              <button style={{background:"transparent"}}>
                                                {
                                                  isDarkTheme ? 
                                                  <img alt="InfoBlue" src={InfoBlue}/> :
                                                  <img alt="InfoWhite" src={ InfoBlueWhite }  height="17"/>
                                                }
                                              </button>
                                            </span>
                                          </OverlayTrigger>
                                        </p>
                                        <div className="mb-1">
                                          <div className="OfflineDeviceDiv">
                                            <img alt="Device" src={DeviceGrayIcon} />
                                            <p className="offlineDeviceText">{top3OfflineDevices[2]?.DeviceName}</p>
                                          </div>
                                        </div>
                                      </div>
                                  </div>
                                </div>

                                Old UI
                                <div style={{display: "none"}}>
                                  {
                                    loading
                                    ?
                                    <>
                                      <div className="StatusValueDiv">
                                        <div className="mb-1">
                                          <Skeleton width={70} />
                                          <Skeleton width={70} />
                                        </div>
                                        <Skeleton width={70} className="statusValueP" />
                                    
                                      </div>
                                      <div className="StatusValueDiv">
                                          <div className="mb-1">
                                              <Skeleton width={70} />
                                              <Skeleton width={70} />
                                            </div>
                                            <Skeleton width={70} className="statusValueP" />
                                      </div>
                                      <div className="StatusValueDiv">
                                      <div className="mb-1">
                                              <Skeleton width={70} />
                                              <Skeleton width={70} />
                                            </div>
                                            <Skeleton width={70} className="statusValueP" />
                                      </div>
                                    </>
                                    : <>
                                    {
                                      top3OfflineDevices.map((item) => (
                                        <HealthOfflineDevices key={item.DeviceName} item={item}/>
                                    ))
                                    }
                                  </> 
                                  }                                    
                                </div>
                            </div>
                        </div>
                    </div>   
                </div>
              </div>
            </div>
          </Collapse>
          <Button
            onClick={() => setOpen(!open)}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          >
            click
          </Button>
        </div> */}
        </div>
        <HealthTable tableLoading={tableLoading} setTableLoading={setTableLoading} refreshButton={refreshButton} setRefreshButton={setRefreshButton} isDarkTheme={isDarkTheme} />
      </div>
        {
          show
          ? <HealthEvents show={show} setShow={setShow} isDarkTheme={isDarkTheme} /> 
          : null
        }        
     </div>
    </>
  );
};

export default HealthMonitoring;
