export const initialElements = (templateName = "", severity) => [
    {
        id: '1',
        type: 'input',
        // data: {
        //     nodeId: 0,
        //     content: <>{'input'}</>,
        //     label: 'Alert Generated',
        // },
        "data": {
            "nodeId": 1,
            "content": {
                "key": null,
                "ref": null,
                "props": {
                    "children": "default"
                },
                "_owner": null,
                "_store": {}
            },
            "label": "Alert Generated",
            "otherProps": {
                "action": "Alert Generated",
                "nodeUserID": 1,
                "actionPerformedBy": "1",
            }
        },
        position: { x: 250, y: 5 }
    }, // you can also pass a React Node as a label
    {
        "id": "2",
        "type": "default",
        "data": {
            "nodeId": 1,
            "content": {
                "key": null,
                "ref": null,
                "props": {
                    "children": "default"
                },
                "_owner": null,
                "_store": {}
            },
            "label": "Alert is Displayed to an Operator",
            "otherProps": {
                "action": "Alert is Displayed to an Operator",
                "nodeUserID": 1,
                "actionPerformedBy": "1",
                "botMessage": templateName + " " + severity + " alert generated"
                // "intentName": `${templateName.toLowerCase().replaceAll(' ', '_')}_${severity.toLowerCase()}_alertgenerated`, //eg:  templateName+_+Severity+_+intent #intent is only for ert and operator
                // "intentText": `${templateName} ${severity}`
            }
        },
        "position": {
            "x": 300,
            "y": 100
        }
    },
    { id: 'e1-2', source: '1', target: '2', type: 'smoothstep', arrowHeadType: 'arrowclosed' },
];