import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import AlertsContainer from './AlertsContainer';
import { getAlertList } from '../../services/alertAuthService'

export default function MyAlerts(props) { //nothing but closed alerts
    // Alert list
    const [extremeAlertLst, setExtremeAlertLst] = useState(0)
    const [highAlertLst, setHighAlertLst] = useState(0)
    const [moderateAlertLst, setmoderateAlertLst] = useState(0)
    const [lowAlertsLst, setLowAlertsLst] = useState(0)
    const [allAlerts, setAllAlerts] = useState(0)

    let LoggedInUser = JSON.parse(localStorage.getItem('user'))


    useEffect(() => {
        (async () => {
            try{
                var obj={
                    "action":"getClosedAlerts",
                    "severity":"all",
                    "userUniqueId":LoggedInUser.UserUniqueID,
                    "startIndex":0,
                    "numberOfElements":50,
                    "authCode":localStorage.getItem('authCode')
                }
                await getAlertList(obj).then((resp) => {
                    if (resp.status == "Ok") {
                        if (resp.message) {
                            if (resp.message && resp.message.length > 0) {
                                FilterList(resp.message)
                            }
                        }
                    }
                })
            }catch(error){
                console.error(error)
            }
         })();
       
         }, [])

    function FilterList(lst){
        try{
            var closedAlertsList = lst//.filter(x => x.closedBy != 0)
            if (closedAlertsList) {              
                var extremeAlerts = filterAlertList(closedAlertsList, "Extreme")
                if (extremeAlerts) {
                    setExtremeAlertLst(extremeAlerts)
                }
                var highAlerts = filterAlertList(closedAlertsList, "High")
                if (highAlerts) {
                    setHighAlertLst(highAlerts)
                }
                var moderateAlerts = filterAlertList(closedAlertsList, "Moderate")
                if (moderateAlerts) {
                    setmoderateAlertLst(moderateAlerts)
                }
                var lowAlerts = filterAlertList(closedAlertsList, "Low")
                if (lowAlerts) {
                    setLowAlertsLst(lowAlerts)
                }
                setAllAlerts(closedAlertsList)
            }
        }catch(error){
            console.error(error)
        }
    }

    function filterAlertList(alertList, severity) {
        var newList = alertList.filter(x => x.severity == severity)
        return newList
    }

    function SetNewList(lst,selectedSeverityTab){
        try{
            if(selectedSeverityTab == "all"){
                setAllAlerts(lst)
            }else if(selectedSeverityTab == "extreme"){
                setExtremeAlertLst(lst)
            }else if(selectedSeverityTab == "high"){
                setHighAlertLst(lst)
            }else if(selectedSeverityTab == "moderate"){
                setmoderateAlertLst(lst)
            }else if(selectedSeverityTab == "low"){
                setLowAlertsLst(lst)
            }
        }catch(error){
            console.error(error)
        }
    }

    return (
        <div>
            <AlertsContainer
                extremeAlertLst={extremeAlertLst}
                highAlertLst={highAlertLst}
                moderateAlertLst={moderateAlertLst}
                lowAlertsLst={lowAlertsLst}
                allAlerts={allAlerts}
                alertSearch={props.alertSearch}
                activeSeverity={props.activeSeverity}
                setActiveSeverity={props.setActiveSeverity}
                setAlertSearch={props.setAlertSearch}
                extremeAlertCount={props.alertcounts.ExtremeClosed}
                highAlertCount={props.alertcounts.HighClosed}
                moderateAlertCount={props.alertcounts.ModerateClosed}
                lowAlertCount={props.alertcounts.LowClosed}
                AllAlertCount={props.alertcounts.TotalClosed}
                onAddAlertChat={props.onAddAlertChat} 
                SetNewList={SetNewList}/>            
        </div>
    )
}
