import React from 'react'

const HealthCounts = ({
    type, count
}) => {
  return (
    <div className="">
        <p className="statusValueP">{count}</p>
        <p className="statusValueText">{type}</p>
    </div>
  )
}

export default HealthCounts