import { getAlertDetailsById } from "../services"

export const GetAlertDetails = async (id) => {
    try {
        return await getAlertDetailsById(id).then((resp) => {
            if (resp.status == "Ok") {
                if (resp.message) {
                    return resp.message[0]
                }else{ return null}
            }else{ return null}
        })
    } catch (error) {
        console.error(error)
    }
}
