import { BASE_CORE_URL } from "../config"

export const getHealthMonitorData = async (pageNumber, uniqueId, searchText) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    }

    // Url Will Come here
    let url = BASE_CORE_URL + "HealthMonitoringDashboard/DeviceStatusDetails?userUniqueId="+uniqueId+"&pageNumber="+pageNumber+"&searchText="+searchText;
    
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then((data) => {
            console.log("getHealthMonitorData", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}

// let userUniqueID = "9dd508e1-16a5-40f4-ac10-b855b25289e0"
export const AllWidgetData = async (userUniqueID) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    }
    let url = BASE_CORE_URL + "HealthMonitoringDashboard/AllWidgetData?userUniqueId=" + userUniqueID;
    return await fetch(url, requestOptions)
        .then((resp) => {
            console.log("test",resp);
            return resp.json();
        })
        .then(function (data) {
            console.log("test",data);
            return data;
        })
        .catch(function (error) {
            console.error("AllWidgetData () Exception", error);
        });
}    

export const getSelectedDeviceHealthData = async (deviceId, deviceStatusType, fromDateTime, toDateTime, pageNumber, searchText) => {
    let fromDateTimeTemp = fromDateTime.replaceAll(":", "%3A");
    fromDateTimeTemp = fromDateTimeTemp.replace(" ", "%20");
    // console.log("getSelectedDeviceHealthData", fromDateTimeTemp);
    let toDateTimeTemp = toDateTime.replace(" ", "%20");
    toDateTimeTemp = toDateTimeTemp.replaceAll(":", "%3A");
    // console.log("getSelectedDeviceHealthData", toDateTimeTemp);

    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    }
    let url = BASE_CORE_URL + "HealthMonitoringDashboard/SingleDeviceStatusDetails?deviceId="+deviceId+"&deviceStatusType="+deviceStatusType+"&fromDateTime="+fromDateTime+"&toDateTime="+toDateTime+"&pageNumber="+pageNumber+"&searchText="+searchText;
    console.log("getSelectedDeviceHealthData", url);
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            // console.log("getSelectedDeviceHealthData", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}


export const getSingleDeviceStatusDetails = async (deviceId, deviceType, fromDateTime, toDateTime, pageNumber, searchText) => {
    let fromDateTimeTemp = fromDateTime.replaceAll(":", "%3A");
    fromDateTimeTemp = fromDateTimeTemp.replace(" ", "%20");
    console.log("getSingleDeviceStatusDetails", fromDateTimeTemp);
    let toDateTimeTemp = toDateTime.replace(" ", "%20");
    toDateTimeTemp = toDateTimeTemp.replaceAll(":", "%3A");
    console.log("getSingleDeviceStatusDetails", toDateTimeTemp);

    const requestOptions = {
        method: "GET",
        headers: { 
            "Content-Type": "application/json", 
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    };

    let url = BASE_CORE_URL + "HealthMonitoringDashboard/SingleDeviceStatusDetails?deviceId=" + deviceId + "&deviceStatusType=" + deviceType + "&fromDateTime=" + fromDateTime + "&toDateTime=" + toDateTime + "&pageNumber=" + pageNumber + "&searchText=" + searchText + "&isExport=true";
    console.log("getSingleDeviceStatusDetails", url);
    return await fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
            console.log("getSingleDeviceStatusDetails", data);
            return data;
        })
        .catch(err => {
            console.log(err);
        })
}
  
export const getCameraTampAlerts = async (uniqueId, pageNumber, searchText) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    }

    let url = BASE_CORE_URL + "HealthMonitoringDashboard/CameraBlockAlertDetails?userUniqueId="+uniqueId+"&pageNumber="+pageNumber+"&searchText="+searchText;

    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then(data => {
            console.log("getCameraTampAlerts", data);
            return data;
        })
        .catch(err => console.log(err));
}

export const getBarChartDataFromAPI = async (deviceId, deviceType, fromDateTime, toDateTime, deviceStatusType) => {
    const requestOptions = {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "authcode": localStorage.getItem('authCode'),
            "Authorization": "Bearer " + localStorage.getItem('authToken')
        },
    }
    console.log("getBarChartDataFromAPI", deviceId, deviceType, fromDateTime, toDateTime, deviceStatusType);
    let url = BASE_CORE_URL + "HealthMonitoringDashboard/GetBarChartData?deviceId=" + deviceId + "&deviceType=" + deviceType + "&fromDateTime=" + fromDateTime + "&toDateTime=" + toDateTime + "&deviceStatusType="+deviceStatusType;

    return await fetch(url, requestOptions)
        .then((res) => res.json())
        .then(data => {
            // console.log("getBarChartDataFromAPI", data);
            return data;
        })
        .catch(err => console.log(err));
}