import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
// import cognyfloLogo from '../../assets/images/cognyfloLogo.png'
import { AlertGenericImg, AlertGenericImg1 } from '../../assets/images'
import { Modal, Tooltip } from 'react-bootstrap'
import { frontDateConverter } from '../../utils/utils'
import { ZeroCountAlert } from './ZeroCountAlert'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import InfiniteScroll from 'react-infinite-scroll-component'
// import { Button, Tooltip } from 'react-bootstrap';

export default function AlertGridView({
    extremeAlertLst,
    highAlertLst,
    moderateAlertLst,
    lowAlertsLst,
    severity,
    allAlerts,
    onAddAlertChat,
    resetInfiniteScroll,
    selectedtablist,
    fetchMoreData,
    handleScroll
}) {
    const [isimage, setisimage] = useState(false)
    const [isOpenmodal, setisOpenmodal] = useState(false)
    const [snapshoturl, setsnapshoturl] = useState([])

    const AddMixpanelEvent = (id) => {
        try{
            // console.log("AddMixpanelEvent",id)
            //mixpanel.track("Clicks on Similar Alerts", { "AlertId": id })
        }catch(error){
            console.error(error)
        }
    }

    const ClosePreview = () => {
        setisOpenmodal(false)
    }
    const handleChange = (isimg, snapurl) => {
setisimage(isimg)
setisOpenmodal(true)
setsnapshoturl(snapurl)
    }

    return (
        <>
            <Modal
                show={isOpenmodal}
                dialogClassName={"activityUpdateModel"}
                onHide={ClosePreview}
                backdrop="static"
                keyboard={false}>
                <Modal.Header closeButton style={{ padding: "0 1rem", borderBottom: "0" }}></Modal.Header>
                <Modal.Body>
                    <div id="EvidenceViewContainer">
                        <div style={{ display: (isimage ? 'block' : 'none') }}>
                            <img
                                style={{ height: "500px", width: "465px" }}
                                src={snapshoturl}
                                alt='snapshot image'
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* alert grid view start */}
            <div id="scrollableDiv" className="infinite-scroll-div" onScroll={handleScroll}>
            <InfiniteScroll
                        key={resetInfiniteScroll}
                        dataLength={selectedtablist.length}
                        next={fetchMoreData}
                        hasMore={true}
                        // loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv"
                    >
            <div className="col-lg-12 pr-2 pt-1 MainAlertsFridDiv" id="extreme-severity">
                {
                    severity == 'extreme' &&
                    extremeAlertLst &&
                    extremeAlertLst.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>//method name
                    )
                }
                {
                    severity == 'high' &&
                    highAlertLst &&
                    highAlertLst.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>
                    )
                }
                {
                    severity == 'moderate' &&
                    moderateAlertLst &&
                    moderateAlertLst.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>
                    )
                }
                {
                    severity == 'low' &&
                    lowAlertsLst &&
                    lowAlertsLst.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>
                    )
                }
                {
                    severity == 'all' &&
                    allAlerts &&
                    allAlerts.map((item, index) =>
                        <AlertGrid
                            key={item.alertId}
                            id={item.alertId}
                            alert={item}
                            onAddAlertChat={onAddAlertChat} 
                            AddMixpanelEvent={AddMixpanelEvent}
                            handleChange={handleChange}/>
                    )
                }

                </div>
                <div className="col-lg-12 pr-2 pt-1">
                    {
                        severity == "extreme" && extremeAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "high" && highAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "moderate" && moderateAlertLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "low" && lowAlertsLst.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                    {
                        severity == "all" && allAlerts.length == 0 && <ZeroCountAlert  type={severity}/>
                    }
                </div>
                    </InfiniteScroll>
            </div>
            {/* alert grid view end */}
        </>
    )

}
export const AlertGrid = ({ id, alert, onAddAlertChat , handleChange}) => {
    let history = useHistory()
    const [isLoading, setIsLoading] = useState(false)
    return (
        <div className={`GridMain ${alert.severity?.toLowerCase()}-bb`}>
            {/* <img src={cognyfloLogo} /> */}
            
            <div className='GridBgImg'>
             <div className='GridAlertTypeImage'>
             {alert.snapshotURL ?  <img src={alert.snapshotURL} alt='snapshot image'
                //  onClick={()=>handleChange(true,alert.snapshotURL)} 
                onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src=AlertGenericImg1;
            }}
             />: <AlertGenericImg/>}
             </div>
             <div className='AlertTypeText'>
             <p className="OperateBy MB2" style={{color: "white"}}>Type:
                    <span className="OperateName ml-1" style={{color: "white"}}>{alert.alertType}</span>
                </p>
             </div>
            </div>
            <div className="TitlesOfGrid">
            
                <p className="TitleGrid">ID:  {id}</p>
                <p className="OperateBy MB2">Site:
                    <span className="OperateName ml-1">{alert.siteName}</span>
                </p>
                <p className="OperateBy MB2">Customer:
                    <span className="OperateName ml-1">{alert.customerName}</span>
                </p>
                
                <p className="OperateBy ReportTableFontBold MB2">Device:
                    <span className="OperateName ml-1">{alert.deviceName}</span>
                </p>
                <p className="OperateBy MB2">ACK By:
                    <span className="OperateName ml-1">{alert.ackbyName? alert.ackbyName : "--"}</span>
                </p>
                
                <p className="OperateBy ReportTableFontBold MB2">Date & Time:
                    <span className="OperateName ml-1">{alert.reqActualTime}</span>
                </p>
                <p className="OperateBy MB5">Status:
                    <span className="OperateName ml-1">{alert.status}</span>
                </p>
                <div className="ButtonsDivAlert">
                    <button className="AlertViewButton m-1" onClick={(e) => { 
                        // AddMixpanelEvent(id)
                        history.push(`/alertdetails/${id}`)
                        window.scrollTo({top: 0, left: 0, behavior: 'smooth'}); 
                    }}><p>View Alert</p></button>

                    {
                        (alert.status != "Open")
                            ?
                            <button className="SelectionAlertViewButton m-1" onClick={() => { 
                                // AddMixpanelEvent(id)
                                history.push(`/alertreport/${id}`) 
                            }}>
                                <p>Report</p>
                            </button>
                            :
                            <button
                                disabled={isLoading}
                                className="SelectionAlertViewButton m-1"
                                onClick={(e) => {
                                    // AddMixpanelEvent(id)
                                    setIsLoading(true)
                                    // e.target.setAttribute("disabled", true);
                                    onAddAlertChat(id).then((res) => {
                                        setIsLoading(false)

                                    })
                                }}>
                                <p>Chatflow</p>
                            </button>
                    }
                </div>
            </div>
        </div>
    )
}

