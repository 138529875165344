import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import ReactPlayer from "react-player";
import { useState } from 'react';
import { getstreamingurl } from '../../services/chatFlowService';
import { Live, LiveView, MosaicExitFullscreen, MosaicFullscreen, MosaicIcon, DownloadIcon, MosaicSnapshot, Save, Video_Export } from '../../assets/images';
import { CAMERAS } from '../../navigation/CONSTANTS';
import { addMinutes ,differenceInMinutes} from 'date-fns';
import { ConvertDate, ConvertDateTwo, frontDateConverter } from '../../utils/utils';
import SkelSinglePlayback from './SkelSinglePlayback';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import PopupCard from './PopupCard';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import screenfull from 'screenfull'
import calendar from "../../assets/images/calendar.svg"
import DateTimeArrow from "../../assets/images/DateTimeArrow.svg"
import { CLOUD_STREAMING, IS_WEBRTC } from '../../config'
import { useVideo } from '../../CustomHooks/useVideo';
import { ToastContainer, toast } from 'react-toastify'
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { WarningToast } from '../../CustomHooks/WarningToast';
import { stopStream } from '../../services/cameraService';
import captureVideoFrame from "capture-video-frame"
import WebRTCPlayer from './WebRTCPlayer';


const SinglePlayback = ({ setChildWindow }) => {
    const popupRef = useRef();
    const  { count, setCount, videoStartBuffering, videoStopBuffering, videoErrorHandler, getHeaders } = useVideo();
    const videoRef = useRef(null);
    const location = useLocation();
    let history = useHistory();
    const { startTime, endTime, deviceId, cameraName, dateTimeOne, dateTimeTwo, streamName, isTranscoded, item, secondsDiff } = location.state;
    const [isSinglePlaybackFullscreenClick, setIsSinglePlaybackFullscreenClick] = useState(false);
    const videoContainerRef = useRef(null);
    const videoContainerParentRef = useRef(null);

    /* ----------------------- Video Player Related State ----------------------- */
    const [played, setPlayed] = useState(0);
    const [videoPlaying, setVideoPlaying] = useState(true);
    const [videoMuted, setVideoMuted] = useState(false);
    const [runningTime, setRunningTime] = useState("00:00:00");
    const [seeking, setSeeking] = useState(false);

    /* ------------------------ Custom DatePicker Related ----------------------- */
    const [date, setDate] = useState(new Date());
    const [dateString, setDateString] = useState('');
    const [timeString, setTimeString] = useState("");
      const [blankDate, setBlankDate] = useState("");
    const [startDateString, setStartDateString] = useState("");
    const [endDateString, setEndDateString] = useState("");
    const [rtspAlias, setRtspAlias] = useState([]);
    const [alias, setAlias] = useState("");
    const [dateOne, setDateOne] = useState(new Date());
    const [dateTwo, setDateTwo] = useState(addMinutes(new Date(), 15));
    const[timeStringMain,setTimeStringMain]=useState("+15 min");
    const[differenceTime,setDifferenceTime]=useState(15);
    const[differenceTimeSec,setDifferenceTimeSec]=useState(secondsDiff);
    const [capturedImage, setCapturedImage] = useState(null);
    const [videoId, setVideoId] = useState(0);
    const [videoUrl, setVideoUrl] = useState("");

    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [exportStartTime, setExportStartTime] = useState("");
    const [exportEndTime, setExportEndTime] = useState("");

    console.log("isTranscoded", isTranscoded)

    let newStartTime = startTime.slice(0, -2) + "00";
    let newEndTime = endTime.slice(0, -2) + "00";
   
    useEffect(() => {

        if(IS_WEBRTC == 1){
          setLoading(true);
          setDateOne(dateTimeOne);
          setDate(dateTimeOne);
          setDateTwo(dateTimeTwo)
          let s = ConvertDate(dateTimeOne);
          s = s.slice(0, -2) + "00";
          setStartDateString(s);
          let d = ConvertDate(dateTimeTwo);
          d = d.slice(0, -2) + "00";
          setEndDateString(d);
          setExportStartTime(newStartTime);
          setExportEndTime(newEndTime);
          let obj = {
            "action": "StartPlaybackUrl",
            // "startTime": startTime,
            // "endTime": endTime,
            "startTime": newStartTime,
            "endTime": newEndTime,
            "deviceId": deviceId,
            "alertId": ""
        }
        getstreamingurl(obj).then((res) => {
          console.log("prePostHandler", res);
        if(res.status == "Ok"){
        console.log("prePostHandler", res);
        setVideoId(deviceId+"6");
        setVideoUrl(res.rtspURL);
        // setVideoUrl("rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=2%38subtype=1");
        setLoading(false);
      }
        })
        .catch(err => console.log(err));

        }else{
          let mainBaseUrl = "http://localhost:3000"
          let baseUrl = "http://localhost:3000"
          let camguid;
          setLoading(true);
          setDateOne(dateTimeOne);
          setDate(dateTimeOne);
          setDateTwo(dateTimeTwo)
          let s = ConvertDate(dateTimeOne);
          setStartDateString(s);
          let d = ConvertDate(dateTimeTwo);
          setEndDateString(d);
          let obj = {
              "action": "StartPlaybackUrl",
              // "startTime": startTime,
              // "endTime": endTime,
              "startTime": newStartTime,
              "endTime": newEndTime,
  
              "deviceId": deviceId,
              "alertId": ""
          }
          getstreamingurl(obj).then((res) => {
              if(res.status === 'Ok'){
                  if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
                  {
                  // let baseUrl = "http://localhost:8080"
                  let rtspurl = res.rtspURL
                  //get cam guid
                  let temp_date = ConvertDateTwo(new Date())
                  camguid = streamName + temp_date
                  setAlias(camguid);
                  // camguid = streamName
                  //do post req to base url
                  let actbaseUrl = baseUrl + "/start"
                  // console.log("check data : ", playUrl)
                  let IsTrancoding = false
                  if(isTranscoded==1)
                      IsTrancoding=true
                  fetch(actbaseUrl, {
                      method: 'POST',
                      body: JSON.stringify({
                      uri: rtspurl,
                      alias: camguid,
                      isencode:IsTrancoding
                      }),
                      headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                      },
                  }).then((res) => res.json())
                      .then((data) => {
                      baseUrl = baseUrl + data['uri']
                      // 
                      setUrl(baseUrl);
                      setLoading(false)
                      //setPlayUrl(baseUrl)
                      //item.
                      // console.log("check data playurl", baseUrl)
                      })
                      .catch((err) => {
                      });
                  //url as rtspurl and alis as camguid
                  }
                  else{
                  //
                  setUrl(res.message);
                  setLoading(false)
                  }
              }
          }).catch(err => {
              console.log(err);
              setLoading(false);
          })
          return () => {
              // const stopAll = async () => {
              //     await Promise.all(rtspAlias.map((item) => stopStream(item)));
              // }
  
              let stopURl = mainBaseUrl + "/stop";
              console.log("Exiting SinglePlayback", rtspAlias, camguid);
              fetch(stopURl, {
                  method: 'POST',
                  body: JSON.stringify({
                  alias: camguid,
                  }),
                  headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                  },
              }).then((res) => res.json())
                  .then((data) => {
                      console.log("Exiting SinglePlayback", data)
                  })
                  .catch((err) => {
                      console.log(err);
                  });
              // stopAll();
              for(let i=0; i<rtspAlias.length; i++){            
                  fetch(stopURl, {
                      method: 'POST',
                      body: JSON.stringify({
                      alias: rtspAlias[i],
                      }),
                      headers: {
                      'Content-type': 'application/json; charset=UTF-8',
                      },
                  }).then((res) => res.json())
                      .then((data) => {
                          console.log("Exiting SinglePlayback", data)
                      })
                      .catch((err) => {
                          console.log(err);
                      });
              }
          }
        }
    }, [])


    // useEffect(() => {
    //     console.log("useEffect Date", dateOne);
    //     let newDate = addMinutes(dateOne, 15);
    //     console.log("useEffect Date", newDate);
    //     setDateTwo(newDate);
    //     let d = ConvertDate(newDate);
    //     setEndDateString(d);
    // }, [dateOne]);

    const dateChange = (v, e) => {
        let d = ConvertDate(v);
        let temp = d.slice(0, 10).replace(/-/g, '_');
        setDateString(temp);
        setDate(v);
      }

    useEffect(() => {
        let d = new Date();
        let temp = ConvertDate(d);
        let temp_date = temp.slice(0, 10).replace(/-/g, '_');
        setDateString(temp_date);
    }, []);

    const onHandleDateTimeOkClick = (e) => {
        setLoading(true);
        let SD = e;
        setDateOne(e);
        let ED = addMinutes(e, 15);
        let fromDateTime = ConvertDate(SD);
        let toDateTime = ConvertDate(ED);
        let obj = {
            "action": "StartPlaybackUrl",
            "startTime": fromDateTime.replace(/[ :-]/g, '_'),
            "endTime": toDateTime.replace(/[ :-]/g, '_'),
            "deviceId": deviceId,
            "alertId": ""
        }
        getstreamingurl(obj).then((res) => {
            if(res.status === 'Ok'){
                setUrl(res.message);
                setLoading(false)
            }
        }).catch(err => {
            setLoading(false);
        })   
    }

    const getDataFromChild = (data) => {
        let timeStringMain = "";
        if(data === ""){
            let blankArr = blankDate.split("_");
            for(let i=0; i<blankArr.length; i++){
              if(blankArr[i].length == 1){
                blankArr[i] = "0" + blankArr[i].toString();
              }
            }
            let blankStr = blankArr.join("_");
            timeStringMain = blankStr;
          }else{
            timeStringMain = data;
          }
          let temp = dateString + '_' + timeStringMain;
    let arr = temp.split("_");
    // ['2023', '04', '14', '10', '47', '24']
    let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
    let currentTime = new Date();
    const difference = differenceInMinutes(currentTime, SD);
    if (difference < 15 && difference >= 0) {
        WarningToast("Selected Time is less than 15 min !")
        timeStringMain = "+" + difference + "min ago";
        setDifferenceTime(parseInt(difference))
        let timeDif = parseInt(difference) * 60;
        setDifferenceTimeSec(timeDif)
        setTimeStringMain(timeStringMain);
      } else {
        setDifferenceTime(15);
        setDifferenceTimeSec(900)
        setTimeStringMain("+15 min");
      }
        setTimeString(data);
      }
      

      const onSubmitBtn = () => {
        setLoading(true);
        popupRef.current.close()
        let timeStringMain = "";
          if (timeString === "") {
              console.log("Submit need to select the current time", blankDate);
              let blankArr = blankDate.split("_");
              for (let i = 0; i < blankArr.length; i++) {
                  if (blankArr[i].length == 1) {
                      blankArr[i] = "0" + blankArr[i].toString();
                  }
              }
              let blankStr = blankArr.join("_");
              console.log("Submit", blankStr);
              timeStringMain = blankStr;
          } else {
              timeStringMain = timeString
          }
          if(timeStringMain.split('_')[2].toString().length == 1){
            timeStringMain += "0";
        }
        let temp = dateString + '_' + timeStringMain;
        temp = temp.slice(0, -2) + "00";
        let arr = temp.split("_");
        // ['2023', '04', '14', '10', '47', '24']
        let SD = new Date(arr[0], arr[1] - 1, arr[2], arr[3], arr[4], arr[5]);
        setDate(SD);
        let a = ConvertDate(SD);
        let currentTime = new Date();
        console.log("rupesh checks00",currentTime)
        //currentTime = addMinutes(currentTime)
        console.log("rupesh checks1",SD)
        console.log("rupesh checks2",currentTime)
        //const difference = differenceInMinutes(currentTime,SD);
        if(differenceTime == 0){
            ErrorToast('The selected time is  greater from the current time!')
            return;    
          }
       // if (SD > currentTime && difference > 0 && currentTime > 0) 
        if (SD > currentTime && differenceTime > 0 ) {
          ErrorToast('The selected time is  greater from the current time!')
          setLoading(false);
        return;
        }
        setStartDateString(a);
        let ED = addMinutes(SD, differenceTime);
        console.log("checkTime",ED)
        let toDateTime = ConvertDate(ED);
        toDateTime = toDateTime.slice(0, -2) + "00";
        setEndDateString(toDateTime);

        // let currentTime = new Date();
        // console.log("rupesh checks1",SD)
        // console.log("rupesh checks2",currentTime)
        // if (SD > currentTime) {
        //     //console.log("rupesh checks The time should be selected 15 minutes backwards from the current time!")
        //     console.log("checkSD",SD)
        //   showErrorToast('The selected time is  greater from the current time!')
        // return;
        // }
        setExportStartTime(temp);
        setExportEndTime(toDateTime.replace(/[ :-]/g, '_'));
        let obj = {
            "action": "StartPlaybackUrl",
            "startTime": temp,
            "endTime": toDateTime.replace(/[ :-]/g, '_'),
            "deviceId": deviceId,
            "alertId": ""
        }
        getstreamingurl(obj).then((res) => {
            console.log(res);
            if(res.status === 'Ok'){

                if(IS_WEBRTC == 1){
                  console.log("prePostHandler", res);
                  setVideoId(deviceId + "8");
                  setVideoUrl(res.rtspURL);
                  // setVideoUrl("rtsp://admin:2o2oImaging@192.168.0.64:554/cam/realmonitor?channel=2%38subtype=1");
                  setLoading(false);
                }else{

                
                //
                console.log("RTSPURL Playback check",res.rtspURL);
                if (CLOUD_STREAMING == '0')//0 - D.S. and 1 - Cloud.S.
                {
                // let baseUrl = "http://localhost:8080"
                let baseUrl = "http://localhost:3000"
                let rtspurl = res.rtspURL
                console.log("RTSPURL : ", rtspurl)
                //get cam guid
                let temp_date = ConvertDateTwo(new Date())
                let camguid = streamName + temp_date;
                console.log("Exiting SinglePlayback", camguid);
                let tempArr = rtspAlias;
                tempArr.push(camguid);
                setRtspAlias(tempArr);
                console.log("RTSPURL checkplayback",camguid);
                //do post req to base url
                let actbaseUrl = baseUrl + "/start"
                // console.log("check data : ", playUrl)
                let IsTrancoding = false
                console.log("RTSPURL checkplayback",isTranscoded);
                if(isTranscoded==1)
                    IsTrancoding=true

                fetch(actbaseUrl, {
                    method: 'POST',
                    body: JSON.stringify({
                    uri: rtspurl,
                    alias: camguid,
                    isencode:IsTrancoding
                    }),
                    headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    },
                }).then((res) => res.json())
                    .then((data) => {
                    baseUrl = baseUrl + data['uri']
                    // 
                    console.log("check data playurl", baseUrl)
                    setUrl(baseUrl);
                    setLoading(false)
                    //setPlayUrl(baseUrl)
                    //item.
                    // console.log("check data playurl", baseUrl)
                    console.log("check data", data)
                    })
                    .catch((err) => {
                    console.log("check data error", err.message);
                    });
                //url as rtspurl and alis as camguid
                }
                else{
                //
                setUrl(res.message);
                setLoading(false)
                }
              }
            }
        }).catch(err => {
            console.log(err);
            setLoading(false);
        })
      }

      const progressHandler = (e) => {
        let date = new Date(null);
        date.setSeconds(e.playedSeconds);
        let hhmmssFormat = date.toISOString().substring(11, 19);
        setRunningTime(hhmmssFormat)
        setPlayed(e.playedSeconds);
      }
// SinglePlayback fullScreen 
      const onFullScreen = () => {
        if(screenfull.isEnabled){
            screenfull.request(videoRef.current.wrapper);
        }
    }

    // const handleProgress = changeState => {
    //     console.log('onProgress', state)
    //     // We only want to update time slider if we are not currently seeking
    //     if (!state.seeking) {
    //       setState({ ...state, ...changeState });
    //     }
    //   }
    
      const onSeekChange =(e)=>{
        console.log('onSeek', e)
      }
    
      const handlePlay = () => {
        console.log('onPlay')
        setVideoPlaying(true)
      }
    
    const handleSeekMouseDown = () => {
        setSeeking(true);
      }
    
      const handleSeekChange = e => {
        console.log("handleSeekChange =>played", parseFloat(e.target.value))
        setPlayed(parseFloat(e.target.value));
        // setState({ ...state, played: parseFloat(e.target.value) });
      }
    
      const handleSeekMouseUp = e => {
        setSeeking(false);
        // setState({ ...state, seeking: false });
        videoRef.current.seekTo(parseFloat(e.target.value))
        console.log("handleSeekMouseUp =>played", parseFloat(e.target.value))
      }

      /* captureImage */
const handleCaptureFrame = (params) => {
    debugger
    console.log("Rupesh Checks",videoRef)
    console.log("Rupesh checks", videoRef.current.getInternalPlayer());
    const video = videoRef.current.getInternalPlayer();
  
    if (typeof video === 'string') {
      video = console.log("rupesh checks",video);
  }
      const frame = captureVideoFrame(params)
      console.log('captured frame', frame)
            setCapturedImage(frame.dataUri)
            var fileUrl = frame.dataUri;
  
    const popupWindow = window.open('', '_blank', 'width=540,height=360');
    popupWindow.document.open();
    popupWindow.document.write(`
    <html>
    <head>
    <title>Snapshot</title>
    <style>
    body, html {
      margin: 0; padding: 0; background: transparent; font-family: Segoe UI;
    }
    .maincontainer {
      display: flex;
      flex-flow: column;
      height: 100%;
    }
    .snapshottitlediv {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #bdbcbc; 
      flex: 0 1 auto;
      padding: 0px 10px;
    }
    .DataAccessEdit {
      width: 32px;
      height: 32px;
      padding: 8px 7.6px 7.6px 8px;
      border-radius: 5px;
      background-color: #0b70d1;
      line-height: 10px;
      margin-left: 5px;
      border: 1px solid #0b70d1;
    }
    p {
      color: #ffffff;
      font-size: 14px;
      font-weight: 600;
      margin: 0px 0px 0px 5px;
    }
    .snapshotimagediv {
      flex: 1 1 auto;
      display: grid;
      align-items: center;
    }
    .snapshotimagediv img {
      width: 100%;
      object-fit: contain;
      box-sizing: border-box;
      display: flex;
    }
    </style>
    </head>        
    <body>
    <div class="maincontainer">
    <div class="snapshottitlediv">
    <p>${cameraName} Snapshot</p>
    <button class="DataAccessEdit" data-title="Download" onclick="capturedFrameDownloadClick('${fileUrl}')">
    <img src=${Save} alt="download icon" />
    </button>
    </div>
    <div class="snapshotimagediv">
    <img src="${fileUrl}" alt="Screenshot" />
    </div>
  </div>
    </body>
    <script>
    function capturedFrameDownloadClick(imageData){
      var fileUrl = imageData;
      fetch(fileUrl)
        .then(response => response.blob())
        .then(blob => {
          debugger
          var link = window.document.createElement("a");
          link.href = window.URL.createObjectURL(blob, { type: "image" });
          link.download = "test.png";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });              
    }
    </script>
    </html>`);    
    // popupWindow.document.write(`
    // <html>
    // <body>
    //   <img src="${fileUrl}" alt="Screenshot" width="300" height="300" />
    //   <br />
    //   <button class="DataAccessEdit" onclick="capturedFrameDownloadClick('${fileUrl}')">
    //   <img src=${DownloadIcon} alt="download icon" />
    // </button>
    // </body>
    // <script>
    // function capturedFrameDownloadClick(imageData){
    //   var fileUrl = imageData;
    //   fetch(fileUrl)
    //     .then(response => response.blob())
    //     .then(blob => {
    //       debugger
    //       var link = window.document.createElement("a");
    //       link.href = window.URL.createObjectURL(blob, { type: "image" });
    //       link.download = "test.png";
    //       document.body.appendChild(link);
    //       link.click();
    //       document.body.removeChild(link);
    //     });              
    // }
    // </script>
    // </html>`);
    popupWindow.document.close();
  };


  function openChildWindow() {
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    let windowFeatures = `menubar=no, fullscreen=yes, location=no, toolbar=no, scrollbars=yes, resizable=yes, status=no, width=${windowWidth}}, height=${windowHeight}`  
    const  newChildWindow = window.open("/mosaic","mosaicWindow", "_blank", windowFeatures);
    // const  newChildWindow = window.open("/mosaic","_blank", windowFeatures);
    setChildWindow(newChildWindow);
    sessionStorage.setItem("mosaicWindowKey", "mosaicWindow")

}
  
  /* captureImage */
    console.log("IS_WEBRTC", IS_WEBRTC);
    console.log("Startime-",startTime, endTime, deviceId, cameraName);
  return (
    <div className='MainContentMargin'>
        <div className='row col-lg-12 p-0' style={{margin:"0px auto"}}>
            <div className='col-lg-12'>
                <div 
                className='playbackcameradiv'
                // className="rightcameradiv" 
                // style={{width: '13%'}}
                >
                    <div className='playbackcameradiv_one'>
                        <p><Link className='playbackcameradiv_one-link' to="/cameras/true">Cameras</Link> &gt; {cameraName} playback</p>
                    </div>
                    <div className="playbackcameradiv_two">
                                <button className="playbackbuttonNewUI" onClick={() => history.push(`/cameras/true`)}>
                                    <img src={LiveView} className="playbackicon" alt="playback icon" />
                                    <p className="playbackbuttonButtonText">Live View</p>
                                </button>
                        </div>
                        <div className="playbackcameradiv_three">
                            <button
                                className="playbackbuttonNewUI"
                                onClick={() => {
                                    //history.push("/mosaic");
                                    openChildWindow()
                                }}>
                                    
                                <img className="playbackicon" src={MosaicIcon} alt="mosaic icon" />
                                <p className="playbackbuttonButtonText">
                                    Mosaic
                                </p>
                            </button>
                        </div>
                </div>


                <div className='playback__datepickers'>
                    {/* <DatePicker 
                        format="yyyy-MM-dd HH:mm:ss"
                        value={dateOne}
                        onOk={(e) => onHandleDateTimeOkClick(e)}
                        // disabledDate={combine(allowedMaxDays(180), afterToday())}
                    /> */}
                    <form className='singleInputDiv'>
                    <input value={frontDateConverter(startDateString)}
                        className='singleInput'  
                    />
                    <Popup
                        ref={popupRef}
                        onOpen={() => {
                          // console.log("singleInput")
                          // let d = new Date();
                          // let temp = ConvertDate(d);
                          // let temp_date = temp.slice(0, 10).replace(/-/g, '_');
                          // setDateString(temp_date); 
                          // setDate(new Date());                     
                        }}
                        trigger={
                            <img src={calendar} alt="calender" style={{cursor:"pointer"}} />
                      /*   <span className='singleInputIcon'>Icon</span> */
                        
                        }
                        >
                        <PopupCard dateChange={dateChange} date={date} getDataFromChild={getDataFromChild} onSubmitBtn={onSubmitBtn} setBlankDate={setBlankDate} timeStringMain={timeStringMain} mode="Playback" />
                    </Popup>
                    </form>
                    <img src={DateTimeArrow} alt='DateTimeArrow' />
                    
                   {/*  <div>  -&gt;  </div> */}
                    {/* <DatePicker 
                        format="yyyy-MM-dd HH:mm:ss"
                        readOnly={true}
                        value={dateTwo}
                        // disabled={true}
                    /> */}
                    <p id='endDateStringSinglePlayback'>{frontDateConverter(endDateString)}</p>
                </div>
                      {
                        IS_WEBRTC == 1
                        ?
                        <div className="" >
                          {
                            loading 
                            ?
                            <SkelSinglePlayback></SkelSinglePlayback>
                            :
                            <WebRTCPlayer 
                            key={deviceId}
                            id={deviceId}
                            url={videoUrl}
                            // isEncode={item.isTranscoded}
                            isEncode={isTranscoded}
                            //isEncode={0}
                            item={item}
                            // upperDivCss="HeightChildDiv"
                            upperDivCss="singlePlayebackHover"
                            // videoCss="single-cam-playback-player"
                            videoCss="mosaic"
                            fullVideoCss="player-responsive-fullScreen"
                            buttonCss="mosaicButtonsMainDiv"
                            noStreamDivCss="singleplaybackNoStreamdiv"
                            playback={false}
                            prePostDisplay={false}
                            liveButton={true}
                            isSinglePlayback={true}
                            videoExportDownload={onSubmitBtn}
                            exportStartTime={exportStartTime}
                            exportEndTime={exportEndTime}
                            secondsDiff={differenceTimeSec}
                            />
                          }
                        </div>
                        :
                        <div className="single-cam-playback-main">
                        <div className="single-cam-playback-sub" style={{height:"100%"}}>
                        {
                            loading
                            ? <SkelSinglePlayback></SkelSinglePlayback>
                            : <>
                            <ReactPlayer
                            ref={videoRef}
                            // key={deviceId}
                            key={count}
                            muted={videoMuted}
                            itemID={"video-" + deviceId}
                            url={url}
                            playing={videoPlaying}
                          /*   style={{ borderRadius: "13px", width: "100%", height: "100%" }} */
                            style={{height: "100%", width:"100%"}}
                            controls={false}
                            autoPlay
                            onProgress={(e) => progressHandler(e)}
                            onSeek={e => onSeekChange(e)}
                            // onPlay={handlePlay}
                            // onProgress={handleProgress}
                            onPlay={handlePlay}
                            className="single-cam-playback-player"
                            onError={(e) => videoErrorHandler(e, "Test")}
                            onBuffer={(e) => videoStartBuffering(e, "Test")}
                            onBufferEnd={(e) => videoStopBuffering(e, "Test")}
                                      config={{
                                          file: {
                                              attributes: {
                                                  crossorigin: 'anonymous',
                                              },
                                          },
                                      }}
                            />
                            <div className='single-cam-playback-controls'>
                                    <div className='single-cam-playback-controls__left'>
                                        <div className='controls_pause' onClick={() => setVideoPlaying(!videoPlaying)}>
                                            {
                                                videoPlaying ? "||" : ">"
                                            }
                                        </div>
                                    </div>
                                    <div className='single-cam-playback-controls__right' style={{width: "88%"}}>
                                        <div className='single-cam-playback-controls__right-upper'>
                                            <div className='controls_seconds'>{runningTime} / 00:15:00</div>
                                              <div className='controls_video'>
                                                  {/* <span>R</span>
                                                <span>D</span>
                                                <span>M</span>
                                                <span onClick={onFullScreen}>F</span> */}
                                              </div>
                                        </div>
                                        <div className='single-cam-playback-controls__right-lower'>
                                            <progress 
                                            className='controls_seekbar' 
                                            max={900} 
                                            value={played} 
                                            onMouseDown={handleSeekMouseDown}
                                            onChange={handleSeekChange}
                                            onMouseUp={handleSeekMouseUp}
                                            />

                                            {/* <input 
                                            type='range'
                                            className='controls_seekbar' 
                                            min={0}
                                            max={900} 
                                            value={played} 
                                            onMouseDown={handleSeekMouseDown}
                                            onChange={handleSeekChange}
                                            onMouseUp={handleSeekMouseUp}
                                            /> */}
                                        </div>
                                    </div>
                                    <div className='Singleplaybackbuttons'>
                                        <button data-title='SnapShot' className='SingleplaybackButtonDiv'>
                                            <img style={{ padding: "2px", height: "24px"}} 
                                            src={MosaicSnapshot} 
                                            // onClick={() => ScreenShotOkClick('start', item)} 
                                            onClick={()=>{handleCaptureFrame(videoRef.current.getInternalPlayer())}}
                                            />
                                        </button>
                                        
                                          {/* <button data-title='Fullscreen' className='SingleplaybackButtonDiv'>
                                        <img style={{ padding: "2px", height: "24px" }} 
                                        src={MosaicFullscreen} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                        </button>
                                        <button data-title='ExitFullscreen' className='SingleplaybackButtonDiv'>
                                        <img style={{ padding: "2px", height: "24px" }} 
                                        src={MosaicExitFullscreen} 
                                        // onClick={() => ScreenShotOkClick('start', item)} 
                                        />
                                        </button> */}

                                          <button data-title='Fullscreen'
                                              className='SingleplaybackButtonDiv'
                                              onClick={onFullScreen}
                                          >
                                              {
                                                  !isSinglePlaybackFullscreenClick ?
                                                      <img style={{ padding: "2px", height: "24px" }}
                                                          src={MosaicFullscreen}
                                                      />
                                                      :
                                                      <img style={{ padding: "2px", height: "24px" }}
                                                          src={MosaicExitFullscreen}
                                                      />
                                              }
                                          </button>

                                          <button data-title='Video Export' className='SingleplaybackButtonDiv'>
                                            <img style={{ padding: "2px", height: "24px"}} 
                                            src={Video_Export} 
                                            />
                                        </button>
                                      </div>
                                </div>
                            </>
                        }

                    </div>
                        </div>
                      }
            </div>
        </div>
          <ToastContainer
              position="bottom-left"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
          />
    </div>
  )
}

export default SinglePlayback