import React, { useState, useEffect } from "react";
import {
  MonitoringHubLogo,
  ForgotPassword,
  LeftArrow,
  LoginImage,
  Eye,
  EyeMask,
  CheckmarkSuccessCircle,
  ClosemarkCircle
} from "../../../assets/images";

import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { resetPassword, updateFirebaseUser } from "../../../services/userAuthServices";
import { FORGETPASSWORD, LOGIN } from "../../CONSTANTS";
import { useHistory } from "react-router-dom";
import { ErrorToast } from "../../../CustomHooks/ErrorToast";
import { ToastContainer } from "react-toastify";

export const ResetPassword = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [password, setPassword] = useState("");
  const [emailAddress, setEmailAddress] = useState("abc@gmail.com");
  const [resetpasswordQueryString, setResetpasswordQueryString] = useState("");
  const [dateAndTime, setDateAndTime] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [mode, setMode] = useState('reset');
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [isNotValidPassword, setIsNotValidPassword] = useState(false); 
  const history = useHistory();
  const togglePopover = () => {
    setPopoverOpen(!isPopoverOpen);
  };
  
  useEffect(() => {
    const url = window.location.href;
    const queryString = url.split('/').reverse()[0]

    const decodedURIComponentString = decodeURIComponent(queryString);
    var decodedString = atob(decodedURIComponentString);

    const separatedData = decodedString.split('##');
    // const resetpasswordQueryString = separatedData[0];
    // const emailAddress = separatedData[1];

    setResetpasswordQueryString(separatedData[0])
    setEmailAddress(separatedData[1])
    setDateAndTime(separatedData[2])

    const dateAndTime = separatedData[2];
    const currentDateTime = new Date();
    const data3DateTime = new Date(separatedData[2].split(' ')[0].split('-')[1] + '-' + separatedData[2].split(' ')[0].split('-')[0] + '-' + separatedData[2].split(' ')[0].split('-')[2] + ' ' + separatedData[2].split(' ')[1]);

    const isValid = data3DateTime > currentDateTime;
    console.log(isValid);
    if (!isValid) {
      // console.log("you can change your password");
      console.log("password reset time sesssion has passed");
      setIsButtonDisabled(true)
      setIsInputDisabled(true)
      setError('LINK EXPIRED')
      setMode('expire');
    } else {
      console.log("you can change your password");
      setMode('reset');
    }

     if (password === "" || password === null || password === undefined) {
      setIsButtonDisabled(true)
    }
  }, [])

  const handlePasswordChange = (event) => {
    let tempValue = event.target.value[event.target.value.length - 1];
    if (tempValue == " "){ 
         ErrorToast('Space is not Allow!'); 
         return;
    }
    const newPassword = event.target.value.replace(" ","");
    setPassword(newPassword);
    setIsButtonDisabled(
      !(
        newPassword.length > 0 &&
        isCapitalCase(newPassword) &&
        isSpecialCharacter(newPassword) &&
        isNumberInclude(newPassword) &&
        isMinCharacter(newPassword) &&
        isMaxCharacter(newPassword)
      )
    );
    setIsNotValidPassword(!(
      newPassword.length > 0 &&
      isCapitalCase(newPassword) &&
       isSpecialCharacter(newPassword) &&
       isNumberInclude(newPassword) &&
      isMinCharacter(newPassword) &&
      isMaxCharacter(newPassword)
    ))
  };

  const isCapitalCase = (pass) => {
    const pattern = /[A-Z]/;
    return pattern.test(pass)
  }
  const isSpecialCharacter = (pass) => {
    const pattern = /[\W_]/;
    return pattern.test(pass)
  };

  const isNumberInclude = (pass) => {
    const pattern = /\d/;
    return pattern.test(pass)
  }
  const isMinCharacter = (pass) => {
    return pass.length >= 8;
  };
  const isMaxCharacter = (pass) => {
    return pass.length <= 15 && pass.length >= 1;
  };



  const popover = (
    <Popover id="popover-basic" style={{ width: "200px" }}>
      <Popover.Content>
        <p className={isCapitalCase(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>One capital case
        </p>

        <p className={isSpecialCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>One special character
        </p>

        <p className={isNumberInclude(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>One number
        </p>

        <p className={isMinCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>Minimum 8 Character
        </p>

        <p className={isMaxCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
          <i className="fa fa-check-circle-o" aria-hidden="true"></i>Maximum 15 Character
        </p>
      </Popover.Content>
    </Popover>
  );

  const ResetPasswordFunction = () => {
    const resetString = resetpasswordQueryString + "##" + emailAddress + "##" + password;
    const encodedResetString = btoa(resetString);
    const encodeURIComponentResetString = encodeURIComponent(encodedResetString)
    console.log(encodeURIComponentResetString)
    console.log("Update Email Address", emailAddress, password);
    
    // updateFirebaseUser(emailAddress, password).then((res) => {
    //   console.log("Update Email Address", res);
    //   if (res.status == "success") {
    //     console.log("Update Email Address", res.message);
    //     resetPassword(encodeURIComponentResetString)
    //       .then(resp => {
    //         console.log("Update Email Address", resp);
    //         if (resp.Status == "success") {
    //           //console.log("Password reset successfully");
    //           setMode('success');
    //         } else {
    //           //console.log("Password reset failed");
    //           setMode('ok-fail');
    //         }
    //       })
    //       .catch(err => {
    //         console.log(err);
    //         setMode('error-fail');
    //       })
    //   } else {
    //     console.log(res.message);
    //     setMode('ok-fail');
    //   }
    // }).catch(function (error) {
    //   console.error(error)
    //   //setError('User does not exist!')
    //   setMode('error-fail');
    // });
    resetPassword(encodeURIComponentResetString)
          .then(resp => {
            console.log("Update Email Address", resp);
            if (resp.Status == "success") {
              //console.log("Password reset successfully");
              setMode('success');
            } else {
              //console.log("Password reset failed");
              setMode('ok-fail');
            }
          })
          .catch(err => {
            console.log(err);
            setMode('error-fail');
      })    
  }

  return (
    <div
      className="container-fluid forgot-pass-container"
      style={{ backgroundColor: "#fff" }}
    >
      <div className="row forgot-pass-row">
        <div
          className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
          style={{ backgroundColor: "#F6F8FC" }}
        >
          <div className="imageWhiteBg">
            <MonitoringHubLogo height={"70px"} />
          </div>
          <div className="BigImage">
            {/* <img src={ForgotPassword} /> */}
            <img src={LoginImage} />
          </div>
        </div>
        <div className="col-lg-6 ContentForm col-md-6 col-sm-12 col-xs-12 login-col-2">
          {
            mode == 'reset' &&
            <div className="WhiteFormSignIn">
              <div className="Content" id="resetpassworddiv">
                <p className="GM_msg">Reset Password</p>
                <p className="resetpassEmailText">for <span style={{ fontWeight: "bold" }} >{emailAddress}</span></p>

                <div className="inputMainContainer w25dot75rem">
                  <OverlayTrigger rootClose trigger="click" placement="right" overlay={popover}>
                    <div className="inputTypeLabelContainer">
                      <label for="password">New Password</label>
                      <input
                        className="inputTypePassword"
                        id="password"
                        type={passwordShown ? "text" : "password"}
                        placeholder="Ex. abc@123"
                        value={password}
                          //onChange={(e) => setPassword(e.target.value)}
                          onChange={(e) => handlePasswordChange(e)}
                        disabled={isInputDisabled}
                        onFocus={togglePopover}
                        onBlur={togglePopover}
                      />

                      <div className="EyeIconDiv">
                        {!passwordShown ? (
                          <img
                            className="EyeIcon"
                            src={Eye}
                            onClick={() => setPasswordShown(!passwordShown)}
                          />
                        ) : (
                          <EyeMask
                            height={18}
                            width={18}
                            onClick={() => setPasswordShown(!passwordShown)}
                          />
                        )}
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
                <div style={{ height: "10px" }}>
                  <p className="Error_P">
                    {
                      error &&
                      <>
                        {/* <ErrorMsgIcon /> */}
                        <span>{error}</span>
                      </>
                    }
                  </p>
                </div>
                <button className="ResetPasswordButton"
                  // onClick={() => history.replace('/login')}
                  onClick={() => ResetPasswordFunction()}
                  disabled={isButtonDisabled}
                  style={{ marginTop: "30px", fontWeight: "bold" }}
                >
                  <span className="Manage-Configuration Sign-In">Reset</span>
                </button>
              </div>
            </div>
          }

          {
            (mode == 'success' || mode == 'ok-fail' || mode == 'error-fail') &&
            <div className="WhiteFormSignIn">
              <div className="Content" id="resetpassworddiv">
                <p className="GM_msg">Reset Password</p>
                <p className="resetpassEmailText">for <span style={{ fontWeight: "bold" }} >{emailAddress}</span></p>

                <div className="inputMainContainer w25dot75rem" style={{ display: "flex", alignItems: "center" }}>
                  {
                    mode == 'success' &&
                    <>
                      <img src={CheckmarkSuccessCircle} />
                      <span className="closemarkErrorText">Your password has been successfully reset. You may now login in using your new password.</span>
                    </>
                  }
                  {
                    (mode == 'ok-fail' || mode == 'error-fail') &&
                    <>
                      <img src={ClosemarkCircle} />
                      <span className="closemarkErrorText">Password reset failed.</span>
                    </>
                  }
                </div>

                <button className="ResetPasswordButton"
                // onClick={() => history.replace('/login')}
                onClick={() => history.replace(LOGIN)}
                >
                  <span className="Manage-Configuration Sign-In" /* onClick={() => history.replace(LOGIN)} */>Go To Sign In</span>
                </button>
              </div>
            </div>
          }

          {
            mode == 'expire' &&
            <div className="WhiteFormSignIn">
              <div className="Content" id="resetpassworddiv">
                <p className="GM_msg">Reset Password</p>
                <p className="resetpassEmailText">for <span style={{ fontWeight: "bold" }} >{emailAddress}</span></p>

                <div className="inputMainContainer w25dot75rem" style={{ display: "flex", alignItems: "center" }}>
                  <img src={ClosemarkCircle} />
                  <span className="closemarkErrorText">Your link is no longer active. Create a new link and change the password.</span>
                </div>

                <button className="ResetPasswordButton"
                // onClick={() => history.replace('/login')}
                >
                  <span className="Manage-Configuration Sign-In" onClick={() => history.replace(`/forgetpassword/${emailAddress}`)}>Go To Reset Password</span>
                </button>
              </div>
            </div>
          }
        </div>
      </div>
      <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    />
    </div>
  );
};
