import React from 'react'
import { useState } from 'react'

const TimerWidget = ({ maxCount, minCount, type, defaultValue, handleCallback, 
    min,
    max,
    maxLength,
    handleTimerCB }) => {
    
    const [count, setCount] = useState(defaultValue);

    // const addCount = () => {
    //     let c = count + 1;
    //     if(count === maxCount){
    //         c = 0;   
    //     }
    //     setCount(c);
    //     handleTimerCB(c,type);
    // }

    // const removeCount = () => {
    //     let c = count - 1;
    //     if(count === 0 && type === "HOUR"){
    //         c = 23;
    //     }else if(count === 0 && (type === 'MIN' || type === 'SEC')){
    //         c = 59
    //     }
    //     setCount(c);
    //     handleTimerCB(c, type);
    // }

    const onChangeHandler = (e) => {
        setCount(e.target.value);
        handleTimerCB(e.target.value, type);
    }



    const timeValidation = (e) => {
        let val = e.target.value;
        if(val > parseInt(max)){
            setCount(max)
        }else if(val < parseInt(min)){
            setCount(0)
        }else{
            setCount(val);
        }
        handleTimerCB(val, type);
    }

    return (
    <div className='timerDiv'>
        <input className='timerDiv__input' type='number' value={count} 
            onChange={timeValidation} 
            min={min}
            max={max}
            maxLength={maxLength}
        />
        {/* <div className='timerDiv_Controls'>
            <button className='timerDiv__btn' 
            onClick={addCount}
            >
            +</button>
            <button className='timerDiv__btn' 
            onClick={removeCount}
            >
            -</button>    
        </div>  */}
    </div>
  )
}

export default TimerWidget