import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useForm } from 'react-hook-form';
import { EditShape,Cross, DeleteQc, SearchIcon, ToggleOff, ToggleOn, EditQc, PlusIcon } from '../../assets/images'
import Swal from 'sweetalert2'
import InlineEditItem from '../../components/InlineEditListItem/InlineEditItem';
import { ToastContainer } from 'react-toastify';
import Modal from "react-bootstrap/Modal";
import ReactFlow, { ReactFlowProvider, Controls } from "react-flow-renderer";
import DataAccessGroup from './DataAccessGroup';
import { WarningToast } from '../../CustomHooks/WarningToast';
import { getDataAccess } from '../../services/dataAccessService';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Button } from 'react-bootstrap';
import { ErrorToast } from '../../CustomHooks/ErrorToast';
import { verificationLink } from '../../services/userAuthServices';
import { SuccessToast } from '../../CustomHooks/SuccessToast';
import { toast } from 'react-toastify';

export default function UserTemplateStep({
    pageState,
    users,
    setSelectedDataAccessGroupId,
    userGroups,
    groups,
    onSaveUser,
    selectedUser,
    onSelectUser,
    onDeleteUser,
    setIsNotValidPassword,
    setChangePassword,
    setIsPassUpdated,
    isPassUpdated,
    // onSelectPrivilege,
    // setUserPrivilege,
    // selectedPrivilege,
    // updatePrivilegeofUser,
    onMoveNext,
    // userPrivilege,
    isUserEdit,
    setIsUserEdit,
    siteRef,
    // onUserGroupSelection,
    usersToBind,
    // selectedUserGroup,
    selectedUsers,
    setSelectedUsers,
    onAddSelectedUsers,
    // onDeleteUserGroup,
    onAddNewUserGroup,
    onUpdateUserGroup,
    filteredUsers,
    onSearchChange,
    selectedUserTypeList,
    setselectedUserTypeList,
    filteredUserGroups,
    // onMoveNextSubStep
    siteGroups,
    siteGroupOnClick,
    selectedSiteGroup,
    workflows,
    nodes, 
    setNodes,
    onLoad,
    editMode,
    setEditMode,
    isNodeSelected,
    setIsNodeSelected,
    setSelectedNode,
    selectedNode,
    disableAdd,
    setDisableAdd,
    onNavigate,
    setIsItemClick,
    setSelectedSubMenu,
    setIsUserVerified,
    isDarkTheme
}) {
    // const [selectedUserTypeList, setselectedUserTypeList] = useState('User List')

    const [issearch, setissearch] = useState(true)
    function ToggeleSearch() {
        try {
            setissearch(!issearch)
            document.getElementById("Search_txt").value = ""
            onSearchChange("", selectedUserTypeList)
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            <div className="col-lg-12 mt-2">
                {pageState == "Add User" &&
                    <AddUserForm
                        nodes={nodes}
                        setSelectedDataAccessGroupId={setSelectedDataAccessGroupId}
                        setNodes={setNodes}
                        onSubmitUser={onSaveUser}
                        user={selectedUser}
                        onSelectUser={onSelectUser}
                        isUserEdit={isUserEdit}
                        setIsUserEdit={setIsUserEdit}
                        ref={siteRef} 
                        workflows={workflows}
                        onNavigate={onNavigate}    
                        setIsItemClick={setIsItemClick}
                        setSelectedSubMenu={setSelectedSubMenu}
                        setIsNotValidPassword={setIsNotValidPassword}
                        setChangePassword={setChangePassword}
                        setIsPassUpdated={setIsPassUpdated}
                        isPassUpdated={isPassUpdated}
                        setIsUserVerified={setIsUserVerified}
                        />
                }
                {
                    pageState == "FR Assignment" &&
                    <AssignFRsToSiteGroup
                        // onUserGroupSelection={onUserGroupSelection}
                        userGroups={userGroups}
                        onFinish={onMoveNext}
                        usersToBind={usersToBind}
                        // selectedUserGroup={selectedUserGroup}
                        selectedUsers={selectedUsers}
                        setSelectedUsers={setSelectedUsers}
                        onAddSelectedUsers={onAddSelectedUsers}
                        // onDeleteUserGroup={onDeleteUserGroup}
                        onAddNewUserGroup={onAddNewUserGroup}
                        onUpdateUserGroup={onUpdateUserGroup}
                        // onMoveNextSubStep={onMoveNextSubStep}
                        siteGroups={siteGroups}
                        users={users}
                        siteGroupOnClick={siteGroupOnClick}
                        selectedSiteGroup={selectedSiteGroup}

                    />
                }

                {pageState == "Data Access Group" && (
                    <DataAccessGroup workflows={workflows} 
                    nodes={nodes}
                    setNodes={setNodes}
                    onLoad={onLoad}
                    isNodeSelected={isNodeSelected}
                    setIsNodeSelected={setIsNodeSelected}
                    setSelectedNode={setSelectedNode}
                    selectedNode={selectedNode}
                    disableAdd={disableAdd}
                    setDisableAdd={setDisableAdd}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    isDarkTheme={isDarkTheme}
                    />
                )}
                {/* {
                    pageState == "Manage Privileges" &&
                    <PrivilegeGroupAssignment
                        users={users}
                        userGroups={userGroups}
                        setUserPrivilege={setUserPrivilege}
                        groups={groups}
                        selectedPrivilege={selectedPrivilege}
                        onSelectPrivilege={onSelectPrivilege}
                        updatePrivilegeofUser={updatePrivilegeofUser}
                        onMoveNext={onMoveNext}
                        userPrivilege={userPrivilege}
                    />
                } */}
                {
                    pageState == "Data Access Group" ? null : (
                    <div className="RightListSide">
                        <div className="HeaderSiteList m-2">
                            {issearch && <p className={selectedUserTypeList == "User List" ? "active-all-light active-block active-text button-common-margin" : "inactive-text button-common-margin"}
                                onClick={() => setselectedUserTypeList('User List')}>User List</p>}

                            {!issearch &&
                                <input autoFocus id="Search_txt" className="SearchViewInput1" type="text"
                                    onChange={e => onSearchChange(e.target.value, selectedUserTypeList)} placeholder="Search User" />
                            }

                            <div className="SearchTextDivConfigTool">
                                {issearch && <img className="EyeIcon" alt='search icon' src={SearchIcon} onClick={() => ToggeleSearch()} />}
                                {!issearch && <img className="EyeIcon" src={Cross} alt='cancel icon' onClick={() => ToggeleSearch()} />}
                            </div>
                        </div>
                        {/* { !issearch &&
                        <input id="Search_txt" className="SearchViewInput" type="search" onChange={e => onSearchChange(e.target.value, selectedUserTypeList)}  placeholder="Search" />}
                        */}
                        <div className="SiteListMain">
                            {selectedUserTypeList && selectedUserTypeList == "User List" &&
                                filteredUsers && (
                                filteredUsers?.length > 0 ?
                                filteredUsers.map(item => {
                                    return <div className={selectedUser?.Email === item.Email ? "OneList SelectedList" : "OneList"} key={item.UserID} onClick={() => onSelectUser(item)}>
                                        <p className="ListofText">{item.FirstName || item.FullName}
                                            <span className="SiteNameText"> - {item.RoleName|| item.roleName}</span></p>

                                        <div>
                                            <img className="m-2" src={EditShape} alt="edit icon"/>
                                            {/* <img src={DeleteQc} alt='delete icon' onClick={(e) => onDeleteUser(e, item)} /> */}
                                        </div>
                                    </div>
                                }) : <div className="no-data-found"><p>No User Found.</p></div>
                                )
                            }

                            {selectedUserTypeList && selectedUserTypeList == "User List" && !filteredUsers &&
                                <div class="no-report-found"><p class="noRecordFoundText">No Users Available.</p></div>

                            }


                            {selectedUserTypeList && selectedUserTypeList == "User Group" &&
                                filteredUserGroups && filteredUserGroups.map(item => {
                                    return <div className="OneList" key={item.userGroupID} onClick={() => onSelectUser(item)}>
                                        <p className="ListofText">{item.groupTitle}
                                            {/* <span className="SiteNameText"> - {item.RoleName}</span> */}
                                        </p>
                                        <div >
                                            {/* <img className="m-2" src={EditShape} /> */}
                                            {/* <img src={DeleteShape} onClick={(e) => onDeleteUserGroup(e, item)} /> */}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {/* <button className="NextMoveButton float-lg-right m-2" onClick={() => onSelectUser({ UserID: 0 })}>
                            <span className="ViewUpdate">Add New User</span>
                        </button> */}
                    </div>
                    )
                }
                {/* <ToastContainer
                    position="bottom-left"
                    autoClose={7000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                /> */}
            </div>
        </>
    )
}


const AddUserForm = React.forwardRef((props, ref) => {

    let { onSubmitUser, user, onSelectUser, isUserEdit, setIsUserEdit, nodes, setNodes, setSelectedDataAccessGroupId, onNavigate, setIsItemClick, setSelectedSubMenu, setIsNotValidPassword, setChangePassword, setIsPassUpdated, isPassUpdated } = { ...props }
    const { register, handleSubmit, setValue, getValues, watch } = useForm({ ...user });
    const[webBoxDisabled, setWebBoxDisabled] = useState(false);
    const[mobileBoxDisabled, setMobileBoxDisabled] = useState(false);
    const[password,setPassword]=useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [adminRoleSelected, setAdminRoleSelected] = useState(false);
    // const passwordInput = useRef(getValues('password'));

    const handlePasswordChange = (event) => {
        if(user.UserID != 0){
            setIsPassUpdated(true);
        }
        let tempValue = event.target.value[event.target.value.length - 1];
   // console.log("Space is not Allow!", tempValue);
    if (tempValue == " "){ 

         ErrorToast('Space is not Allowed!'); 
         return;
    }

        const newPassword = event.target.value.replace(" ","");
        setPassword(newPassword);
        setValue('Password',newPassword)
         setIsButtonDisabled(
          !(
            newPassword.length > 0 &&
            isCapitalCase(newPassword) &&
             isSpecialCharacter(newPassword) &&
             isNumberInclude(newPassword) &&
            isMinCharacter(newPassword) &&
            isMaxCharacter(newPassword)
          )
        );
        setIsNotValidPassword(!(
            newPassword.length > 0 &&
            isCapitalCase(newPassword) &&
             isSpecialCharacter(newPassword) &&
             isNumberInclude(newPassword) &&
            isMinCharacter(newPassword) &&
            isMaxCharacter(newPassword)
          ))
      };

      const isCapitalCase = (pass) => {
        const pattern = /[A-Z]/;
        return pattern.test(pass)
      }
      const isSpecialCharacter = (pass) => {
        const pattern = /[\W_]/;
        return pattern.test(pass)
      };
    
      const isNumberInclude = (pass) => {
        const pattern = /\d/;
        return pattern.test(pass)
      }
      const isMinCharacter = (pass) => {
        return pass.length >= 8;
      };
      const isMaxCharacter = (pass) => {
        return pass.length <= 15 && pass.length >= 1;
      };
    
      const popover = (
        <Popover id="popover-basic" style={{ width: "200px" }}>
          <Popover.Content>
            <p className={isCapitalCase(password) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>One capital case
            </p>
    
            <p className={isSpecialCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>One special character
            </p>
    
            <p className={isNumberInclude(password) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>One number
            </p>
    
            <p className={isMinCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>Minimum 8 Character
            </p>
    
            <p className={isMaxCharacter(password) ? "popoverP Valid" : "popoverP Invalid"}>
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>Maximum 15 Character
            </p>
          </Popover.Content>
        </Popover>
      );

      const tempComponent = (
        <Popover>

        </Popover>
      )

    useEffect(() => {
        if (user != null) {
            setValue('FirstName', user.FirstName)
            setValue('Email', user.Email)
            setValue('Phone', user.Phone)
            setValue('UserRoleID', user.UserRoleID)
            setValue('isWebLoginAllowed', user.isWebLoginAllowed);
            setValue('isMobileLoginAllowed', user.isMobileLoginAllowed)
            setValue('dataAccessGroupId', user.dataAccessGroupId);
            setValue('isEnabled', user.isEnabled);
            if(user.UserID != 0){
                setValue('Password', user.Password || "********")
                setPassword(user.Password || "********")
            }else{
                setValue('Password', user.Password)
                setPassword(user.Password);
            }
            let selectedUserRoleId = user.UserRoleID
            if(selectedUserRoleId == 4){
                setMobileBoxDisabled(true);
                setWebBoxDisabled(true);
                setAdminRoleSelected(false);
            }else if (selectedUserRoleId == 3){
                setMobileBoxDisabled(false);
                setWebBoxDisabled(true);
                setAdminRoleSelected(false);
            }else if(selectedUserRoleId == 2){
                setMobileBoxDisabled(false);
                setWebBoxDisabled(true);
                setAdminRoleSelected(true);
            }else{
                setMobileBoxDisabled(false);
                setWebBoxDisabled(false);
                setAdminRoleSelected(false);
            }   
        }
        setIsPassUpdated(false);
        setChangePassword(false);
    }, [user])

    useEffect(() => {
         if(user.UserID == 0 || isUserEdit){
            let selectedUserRoleId = getValues('UserRoleID');
            if(selectedUserRoleId == 4){
                setValue('isMobileLoginAllowed', true)
                setValue('isWebLoginAllowed', false);
                setMobileBoxDisabled(true);
                setWebBoxDisabled(true);
                // setSelectedDataAccessGroupId(null);
                // setValue('dataAccessGroupId', null);
                setAdminRoleSelected(false);
            }else if(selectedUserRoleId == 3){
                setValue('isWebLoginAllowed', true);
                setValue('isMobileLoginAllowed', false) 
                setMobileBoxDisabled(false);
                setWebBoxDisabled(true);
                // setSelectedDataAccessGroupId(null);
                // setValue('dataAccessGroupId', null);
                setAdminRoleSelected(false);
            }else if(selectedUserRoleId == 2){
                setValue('isWebLoginAllowed', true);
                setValue('isMobileLoginAllowed', false)
                setMobileBoxDisabled(false);
                setWebBoxDisabled(true);
                setSelectedDataAccessGroupId("1");
                setValue('dataAccessGroupId', "Super Admin");
                setAdminRoleSelected(true);
            }else{
                setMobileBoxDisabled(false);
                setWebBoxDisabled(false);
                setAdminRoleSelected(false);
            }
        }
    }, [watch('UserRoleID')])

    useEffect(() => {
        if(user.UserID != 0 && isUserEdit){
            if(getValues('isEnabled') == false){
                setValue('Password', "");
                setPassword("");
                setChangePassword(true);
                WarningToast("Please enter a new password, for enabling the user");
                // if (passwordInput.current) {
                //     passwordInput.current.focus();
                // }
            }
        }
    }, [watch('isEnabled')])

    //#region Edit and cancel

    useImperativeHandle(ref, () => ({
        isDataChanged() {
            return getValues('FirstName') != user.FirstName ||
                getValues('Email') != user.Email ||
                getValues('Phone') != user.Phone ||
                getValues('UserRoleID') != user.UserRoleID ||
                getValues('Password') != user.Password ||
                getValues('UserRoleId') != user.UserRoleID ||
                getValues('isWebLoginAllowed') != user.isWebLoginAllowed ||
                getValues('isMobileLoginAllowed') != user.isMobileLoginAllowed ||
                getValues('isEnabled') != user.isEnabled ||
                getValues('dataAccessGroupId') != user.dataAccessGroupId
        }
    }))

    function isDataChanged() {
        return getValues('FirstName') != user.FirstName ||
            getValues('Email') != user.Email ||
            getValues('Phone') != user.Phone ||
            getValues('UserRoleID') != user.UserRoleID ||
            getValues('Password') != user.Password ||
            getValues('UserRoleId') != user.UserRoleID ||
            getValues('isWebLoginAllowed') != user.isWebLoginAllowed ||
            getValues('isMobileLoginAllowed') != user.isMobileLoginAllowed ||
            getValues('isEnabled') != user.isEnabled ||
            getValues('dataAccessGroupId') != user.dataAccessGroupId
    }

    const onCancel = (e) => {
        e.stopPropagation()
        if (isDataChanged()) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to cancel the changes made?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#027aaa',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Leave!'
            }).then((result) => {
                if (result.isConfirmed) {
                    if (user != null) {
                        setValue('FirstName', user.FirstName)
                        setValue('Email', user.Email)
                        setValue('Phone', user.Phone)
                        setValue('UserRoleID', user.UserRoleID)
                        setValue('Password', user.Password || "********")
                        setValue('isWebLoginAllowed', user.isWebLoginAllowed);
                        setValue('isMobileLoginAllowed', user.isMobileLoginAllowed)
                        setValue('isEnabled', user.isEnabled)
                        setValue('dataAccessGroupId', user.dataAccessGroupId)
                    }
                    setIsUserEdit(false)
                }
            })
        } else {
            setIsUserEdit(false)
        }
    }
   //#endregion
   const showSuccessToast = (msg) => {
    toast.success(msg, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
}; 
    const [show, setShow] = useState(false);

    const onSelectElement = (event, element) => {
        setValue('dataAccessGroupId', element.data.label);
        setShow(false);
    }

    const addName = (e) => {
        // let tempValue = e.target.value.replace(/[^\w]/gi, "");
        let tempValue = e.target.value.replace(/[-’/`~!#*$@_%+=.,^&\d(){}[\]|;:”<>?\\]/gi, "");
        if(!/[0-9a-zA-Z]/.test(e.key)){
            e.preventDefault();
        }else{
            setValue("FirstName",tempValue);
        }

        // let key = e.key;
        // let regex = new RegExp("^[a-zA-Z0-9]+$");
        // if(regex.test(key)){
        //     // Restrict the special characters
        //     e.preventDefault();  
        //     return false;
        // }else{
        //     setValue("FirstName", e.target.value);
        // }
    }

    const [isToggleActive, setIsToggleActive] = useState(false);
    const ToggleAlertFullTime = (toggleStatus) => {

        if (toggleStatus == "on"){
            setIsToggleActive(false)
        }
        else {
            setIsToggleActive(true)
        }
    }

    const [mouseDown, setMouseDown] = useState(false);

    const handleMouseDown = () => {
      setMouseDown(true);
    };
  
    const handleMouseUp = () => {
      setMouseDown(false);
    };
    const onUserVerifiedClick = () => {
    verificationLink(user.Email).then((res) => {
             if(res.Status === "success"){
            showSuccessToast(res.Message)
          }
            else{
                ErrorToast(res.message)
            }
        }).catch((error) => {
          console.error(error) 
        })
    }
    useEffect(() => {
      window.addEventListener('mousedown', handleMouseDown);
      window.addEventListener('mouseup', handleMouseUp);
      return () => {
        window.removeEventListener('mousedown', handleMouseDown);
        window.removeEventListener('mouseup', handleMouseUp);
      };
    }, []);
  
  

    useEffect(() => {
        getNodes();
        setSelectedDataAccessGroupId(null);
    }, []);
    
    const getNodes = () => {
        getDataAccess()
          .then((resp) => {
            buildWorkflowTree(resp.Data);
          }).catch(err => {
            console.log(err);
          })
      }

      const buildWorkflowTree = (arr) => {
            let tempArr = [];
            // This is for plotting the nodes.
            for (let i = 0; i < arr.length; i++) {
              // let { xPos, yPos} = getPosition(arr[i].id);
              
              let tempxpos = arr[i].Position_X === "" ? 250 : parseInt(arr[i].Position_X);
              let tempypos = arr[i].Position_Y === "" ? 5 : parseInt(arr[i].Position_Y);
              
              let obj = {
                id: arr[i].Id.toString(),
                type: i === 0 ? 'input' : 'default',
                position: { x: tempxpos, y: tempypos },
                data: {
                  label: (
                    <>
                      <div className='NodeTitleDescr'>
                        <p className='NodeHeading'>{arr[i].Name}</p>
                        <p className='NodeDescreption'>{arr[i].Description}</p>
                      </div>
                      <div className='nodeDeleteIcon'>
                            {/* <img style={{ width: "15px" }} src={DeleteQc} onClick={() => {}} /> */}
                      </div>
                    </>
                  )
                },
                databaseId: arr[i].Id,
                parentId: arr[i].ParentId
              }
              tempArr.push(obj);
            }
            //This is for plotting the edges.
            for (let x = 0; x < arr.length; x++) {
              let parentId = 0;
              let source = "";
              let target = "";
              if (arr[x].ParentId !== null) {
                parentId = arr[x].ParentId;
                target = arr[x].Id.toString();
                source = arr[x].ParentId.toString();
                let edgeObj = {
                  id: `e${source}-${target}`,
                  source: source,
                  target: target,
                  type: 'edge',
                  arrowHeadType: 'arrowclosed',
                  style: { strokeWidth: 2, stroke: 'black',  }
                }
                tempArr.push(edgeObj);
              }
            }
            setNodes(tempArr);
            // setTempNode(tempArr);
            // let c = count + 1;
            // setCount(c);
          }

          const nodeSelected = (event, element) => {
            setSelectedDataAccessGroupId(element.id);
            setValue('dataAccessGroupId',element.data.label.props.children[0].props.children[0].props.children)
            // setValue('dataAccessGroupId', element.data.label.props.children[0].props.children[0].props.children);
            setShow(false);
          }

          useEffect(() => {
            setTimeout(() => {
                if(show){
                    document.querySelector('.react-flow__controls-fitview').click();
                }
            }, 100)
          }, [show])
console.log("check",user)
    return <div className="LeftAddSide">
          <div className='LeftAddSideHeight'>

          <div className="AddSite userTemplate-addUser-conatiner" style={{ padding: "0rem 1rem" }}>
            {user.UserID == 0 && <h1 className="MainHeaderName">1. Add User</h1>}
            {user.UserID != 0 && <h1 className="MainHeaderName">1. Update User</h1>}
            <form onSubmit={handleSubmit((e) => onSubmitUser(e, user))} >
                <div className='user-formdt'>
                    <div className="SiteTextBox">
                        {/* <p className="SiteNameText">Name <span style={{ color: "red" }}> *</span></p> */}
                        <input
                            className="SiteNameTextBox"
                            placeholder="Name* e.g. Rupesh"
                            {...register("FirstName")}
                            defaultValue={user.FirstName}
                            disabled={!isUserEdit}
                            onChange={addName}
                             />
                    </div>
                    <div className="SiteTextBox">
                        {/* <p className="SiteNameText">Email <span style={{ color: "red" }}> *</span></p> */}
                        <input
                            className="SiteNameTextBox"
                            placeholder="Email* e.g. rupesh@xyz.com"
                            {...register("Email")}
                            defaultValue={user.Email}
                            disabled={!isUserEdit || user.UserID != 0} />
                    </div>
                    <div className="SiteTextBox">
                        {/* <p className="SiteNameText">Contact Number <span style={{ color: "red" }}> *</span></p> */}
                        <div className="MainDropdownStyle ContactDropdown">
                        {/* <div className="dropdown MainDropdownStyle ContactDropdown"> */}
                            <span className="btn TextRoot contactNumUser">+91</span>
                            {/* <span className="btn TextRoot" type="button" data-toggle="dropdown" aria-expanded="false">+91
                             <span className="caret"></span>
                        </span> */}
                            {/* <ul className="dropdown-menu">
                            <li><a href="#">+92</a></li>
                            <li><a href="#">+93</a></li>
                        </ul> */}
                        </div>
                        <input
                            className="SiteNameTextBox phoneNumberTextBox"
                            placeholder="Contact* e.g. 9876543210"
                            {...register("Phone")}
                            defaultValue={user.Phone}
                            disabled={!isUserEdit} />
                    </div>
                    <div className="SiteTextBox">
                        {/* <p className="SiteNameText">Select Role <span style={{ color: "red" }}> *</span></p> */}
                        <select
                            className="SiteNameTextBox ArrowSelect"
                            placeholder="Select User Role*"
                            {...register("UserRoleID")}
                            defaultValue={user.UserRoleID}
                            onChange={e => setValue('UserRoleID', e.target.value)}
                            disabled={!isUserEdit}>
                            <option key="0" disabled={false} value="0">Select Role*</option>
                            <option value={2}>Admin</option>
                            <option value={3}>Operator</option>
                            <option value={4}>Field Responder</option>
                        </select>
                    </div>
                    <div className="SiteTextBox">
                        {/* <p className="SiteNameText">Password <span style={{ color: "red" }}> *</span></p> */}
                        <OverlayTrigger rootClose trigger="click" placement="right" overlay={password === "********" ? tempComponent : popover}>
                        <input
                            // type="password"
                            autoComplete="off"
                            name="Password"
                            className="SiteNameTextBox"
                            placeholder="Password*"
                            {...register("Password")}
                            value={password}
                            // ref={passwordInput}
                            // defaultValue={password}
                            defaultValue={user.Password}
                            //onChange={handlePasswordChange}
                             onChange={(e) => handlePasswordChange(e)}
                            disabled={!isUserEdit} />
                            </OverlayTrigger>
                    </div>
                    <>
                        <div className="SiteTextBox">
                            <div className="DataAccessDiv">
                                <input
                                type="text"
                                name="Data Access"
                                className="SiteNameTextBox"
                                placeholder="Select Data Access*"
                                // {...register("DataAccess")}
                                {...register("dataAccessGroupId")}
                                disabled
                                defaultValue={user.dataAccessGroupId === null ? "" : user.dataAccessGroupId}
                                />
                                <div style={{ float: "right", marginTop: "6px" }}>
                                    <img src={PlusIcon} alt='plus icon' style={!isUserEdit || adminRoleSelected ? { display: "none" } : {}} onClick={() => setShow(!show)} />
                                    <img
                                    className="m-2"
                                    src={EditQc}
                                    alt='edit icon'
                                    style={{ display: "none" }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="SiteTextBox">
                            <div className="form-check">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                                {...register("isWebLoginAllowed")}
                                defaultChecked={user?.isWebLoginAllowed}
                                disabled={!isUserEdit || webBoxDisabled}
                                />
                                <label className="form-check-label" for="exampleCheck1">
                                Web Application Login
                                </label>
                            </div>
                        </div>
                        <div className="SiteTextBox">
                            <div className="form-check">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck2"
                                {...register("isMobileLoginAllowed")}
                                defaultChecked={user?.isMobileLoginAllowed}
                                disabled={!isUserEdit || mobileBoxDisabled}
                                />
                                <label className="form-check-label" for="exampleCheck2">
                                Mobile Application Login
                                </label>
                            </div>
                        </div>
                        {
                            user.UserID != 0 ?
                            <div className="SiteTextBox">
                            <div className="form-check">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck3"
                                {...register("isEnabled")}
                                defaultChecked={user?.isEnabled}
                                disabled={!isUserEdit}
                                />
                                <label className="form-check-label" for="exampleCheck3">
                                User Disabled
                                </label>
                            </div>
                            </div>
                            : null
                        }
                        </>
                </div>
                
                {/* <a className="add-user-btn" onClick={() => onSelectUser({ UserID: 0 })}>
                    <span className="UserAddPlus">Add User +</span>
                </a> */}
                <div className="BulkText userTemplate-next-btn userTemplate-next-btn-div">
                    {/*  <div className="SentenceText">
                        <p>Bulk Import Sites?</p>
                        <p className="SampleTextDocument">Sample Document
                            <span>
                                <img src={SampleDownload} />
                            </span>
                        </p>
                    </div> */}

                    <div className="ButtonText w-100 mt-3">
                        <button className="AlertViewButton" hidden>
                            <p>Upload CSV</p>
                        </button> 
                        {
                            (user.UserID !==0 && user.EmailConfirmed == "False") &&
                        <button type='button' className="NextMoveButton mr-1" onClick={()=>onUserVerifiedClick()}>
                            <span className="ViewUpdate">Resend Verification Link </span>
                        </button>

                        }
                        
                        {
                            user.UserID != 0 &&
                            !isUserEdit &&
                            <button type="submit" className="NextMoveButton float-end updateUser-edit-btn" style={{ width: "6.8rem" }} onClick={() => setIsUserEdit(true)}>
                                <span className="ViewUpdate">Edit</span>
                            </button>
                            
                        }
                        {
                            isUserEdit &&
                            <>
                                {
                                    user.UserID == 0 && 
                                    <>
                                    {/* <div className='userEnableDisable'>
                                        <div>Enable/disabled</div>
                                        <div style={{marginLeft: "7px"}}>
                                        <span className="alert-template-fullDay-toggle">
                                            {isToggleActive ? <img src={ToggleOn} alt="Toggle icon on" onClick={() => {
                                                    ToggleAlertFullTime('on')
                                            }} />
                                                : <img src={ToggleOff} alt="Toggle icon off" onClick={() => {
                                                        ToggleAlertFullTime('off')
                                                }} />}
                                        </span>
                                        </div>
                                    </div> */}
                                    <button className="EnableNextStep updateUser-next-btn" type="submit" >
                                        <p>Next</p>
                                    </button>
                                    </>
                                }
                                {
                                    user.UserID != 0 &&
                                    <>
                                        <button className="EnableNextStep updateUser-update-btn" type="submit" >
                                            <p>Update</p>
                                        </button>
                                        <a className="btn btn-link ml-2 updateUser-cancel-btn" onClick={(e) => onCancel(e)}>Cancel</a>
                                    </>
                                }
                            </>
                        }
                    </div>
                </div>
            </form>

            {/* Data Access modal onClick on PlusIcon */}
            <Modal
                    size="xl"
                    show={show}
                    onHide={() => setShow(false)}
                    backdrop="static"
                    dialogClassName=""
                    //   aria-labelledby="example-custom-modal-styling-title"
                    >
                    <>
                        <Modal.Header closeButton>
                        <Modal.Title id="example-custom-modal-styling-title">
                            Data Access Workflow
                        </Modal.Title>
                        </Modal.Header>
                        
                        <Modal.Body>
                        <ReactFlowProvider>
              <ReactFlow
                className="workflowPreview UserDataAccessWorkflow"
                elements={nodes}
                onElementClick={nodeSelected}
                style={{ cursor: mouseDown ? "grabbing" : "all-scroll",  }}
                onMoveStart={handleMouseDown} 
                onMoveEnd={handleMouseUp}                // onNodeDoubleClick={nodeClicked}
                // // onNodeDoubleClick={sessionStart ? nodeClickedTemp : nodeClicked}
                // nodesDraggable={!editMode ? false : true}
                // elementsSelectable={!editMode ? false : true}
                // // onDragOver={onDragOver}
                // key={count}
              >
              </ReactFlow>
              <Controls />

            </ReactFlowProvider>
            
                        </Modal.Body>
                    </>
                        <div style={{textAlign:"end", margin:"10px"}}>
                            <Button onClick={() => {
                                onNavigate('Data Access Group');
                                setIsItemClick(false);
                                setSelectedSubMenu('Data Access Group');
                            }
                            }>
                                Go to data access</Button>
                        </div>
                    </Modal>
                    {/* ----------modal end--------- */}

        </div>
          </div>
    </div>
});

const AssignFRsToSiteGroup = ({
    usersToBind,
    selectedUsers,
    setSelectedUsers,
    onAddSelectedUsers,
    siteGroups,
    users,
    siteGroupOnClick,
    selectedSiteGroup
}) => {

    const levels = []

    const handleChange = index => event => {

        // handle FR level start
        const selUser = usersToBind.find(x => x.userID == event.target.id)
        if (selUser) {
            if (event.target.checked == true) {
                selUser.isSelected = true
                selUser.contactLevel = Object.entries(selectedUsers).filter(x => x[1] == true).length + 1
            }
            else {

                const maxIndex = usersToBind.length
                if (selUser.contactLevel != maxIndex) {
                    for (let i = 0; i < usersToBind.length; i++) {
                        const fruser = usersToBind[i];
                        if (fruser && fruser.userID != selUser.userID && fruser.contactLevel != null) {
                            if (fruser.contactLevel > selUser.contactLevel) {
                                var currentLength = fruser.contactLevel - 1
                                fruser.contactLevel = currentLength
                            }

                        }
                    }
                }
                selUser.isSelected = false
                selUser.contactLevel = null

            }
        }
        // handle FR level end

        setSelectedUsers({ ...selectedUsers, [event.target.id]: event.target.checked })
    }
    var frUsers = []
    if (users && users.length > 0) { frUsers = users.filter(x => x.UserRoleID == 4) }

    return <>
        <div className="LeftAddSide">
            <div className='LeftAddSideHeight'>
                <div className="ManageSite userTemplate-FRAssignment-container">
                    <h1 className="MainHeaderName mb-0">2. Assign Field Responder to Site Group</h1>
                    <div className="ManageSiteDiv">
                        <div className="DevicesHeader">
                            <p className="mb-0" style={{ width: "40%" }}>Site Group<br /></p>
                            <p className="mb-0">Field Responder List<br /></p>
                        </div>

                        {/* --- */}

                        <div className="site-group">
                            <div className="site-group-list">
                                {
                                    siteGroups &&
                                    siteGroups.map(item => {
                                        return <div
                                            id={item.siteGroupID}
                                            key={item.siteGroupID}
                                            onClick={() => siteGroupOnClick(item)}
                                            className={`cameraList user-list-item ${selectedSiteGroup && item.siteGroupID == selectedSiteGroup?.siteGroupID && "selected-user"}`}
                                        >
                                            <p className="mb-0">{item.groupTitle}</p>
                                            <div style={{ float: "right" }}>
                                            </div>

                                        </div>
                                    })
                                }
                            </div>
                        </div>

                        <div className="SiteList" style={{ borderBottom: "1px solid #e6e6e6" }}>
                            <div className='sitegrp-sitelist'>
                                <form >
                                    {
                                        usersToBind ? usersToBind.map((value, index, array) => {

                                            return <div className="selection-list-group cameraList"
                                                key={value.userID}
                                            >
                                                <input
                                                    id={value.userID}
                                                    className="checkbox_custom"
                                                    type="checkbox" 
                                                    key={value.userID}
                                                    onChange={handleChange(index)}
                                                    name={value.firstName}
                                                    defaultChecked={value.isSelected == true ? true : false} 
                                                    />
                                                <p className="ListofText">{value.firstName}
                                                    <span className="SiteNameText">{value.isSelected && ` - Level-${value.contactLevel}`}</span>
                                                </p>
                                            </div>
                                        }) : <div>
                                            No FRs added to this site group.
                                        </div>
                                    }


                                </form>
                            </div>
                        </div>

                        <div className="m-2 text-right" style={{ textAlign: "end" }}>
                            {/* <button className="NextMoveButton mr-3 mt-3" onClick={() => onFinish('Review')}>
                                <span className="ViewUpdate">Next</span>
                            </button> */}
                            <button className="NextMoveButton assignFieldResponder-AddSelected-btn" onClick={() => onAddSelectedUsers()}>
                                <span className="ViewUpdate">Add Selected</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <ToastContainer
                      position="bottom-left"
                      autoClose={5000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    /> */}
        </div>

    </>
}
