import React, { useState, useEffect } from 'react'
import { InfoBlue, SearchIcon, SearchIconWhite } from "../../assets/images";
import { DatePicker } from "rsuite";
import Pagination from "react-bootstrap/Pagination";
import { useDataAccessPagination } from '../../CustomHooks/useDataAccessPagination';
import DownloadItem from './DownloadItem';
import { getDownloadList, getDownloadDetails} from '../../services/downloadService';


const statusArr = [
    { id: 1, value: "Available", label: 'Available' },
    { id: 2, value: "In Progress", label: 'In Progress' },
    { id: 3, value: "Expired", label: 'Expired' }
]

export default function DownloadHistory() {
    const [idSearch, setIdSearch] = useState("");
    const [requestDateTime, setRequestDateTime] = useState(null);
    const [statusSearch, setStatusSearch] = useState("");
    const [siteSearch, setSiteSearch] = useState("");
    const [deviceSearch, setDeviceSearch] = useState("");
    const [fromDateTime, setFromDateTime] = useState(null);
    const [toDateTime, setToDateTime] = useState(null);

    const [totalDevices, setTotalDevices] = useState(0);
    const [downloadList, setDownloadList] = useState([]);
    const [tempDownloadList, setTempDownloadList] = useState([]);
    const [expiryDateTime, setExpiryDateTime] = useState(null);
    const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);
    const [timer, setTimer] = useState(null)

    const PaginationComponent = (currentPageNo) => {
        let numbers = [];
        if (maxPageAllowed - 2 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 2}
                    active={maxPageAllowed - 2 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
                >
                    {maxPageAllowed - 2}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else if (maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))) {
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed - 1}
                    active={maxPageAllowed - 1 == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
                >
                    {maxPageAllowed - 1}
                </Pagination.Item>)
            numbers.push(
                <Pagination.Item
                    key={maxPageAllowed}
                    active={maxPageAllowed == currentPageNo}
                    onClick={() => setCurrentPageNo(maxPageAllowed)}
                >
                    {maxPageAllowed}
                </Pagination.Item>)
        }
        else {
            for (let i = currentPageNo; i < currentPageNo + 3; i++) {
                if (i <= maxPageAllowed) {
                    numbers.push(
                        <Pagination.Item
                            key={i}
                            active={i == currentPageNo}
                            onClick={() => setCurrentPageNo(i)}
                        >
                            {i}
                        </Pagination.Item>
                    )
                }
            }
        }
        return numbers;
    }

    const requestDateHandler = (e) => {
        setRequestDateTime(e);
    }

    const expiryDateHandler = (e) => {
        setExpiryDateTime(e);
    }

    const statusChanged = (e) => {
        setStatusSearch(e.target.value);
    }

    const getList = () => {
        getDownloadList(currentPageNo)
            .then((res) => {
                if (res.Status == "success") {
                    setDownloadList(res.Data.LstDownloadRequest);
                    setTempDownloadList(res.Data.LstDownloadRequest);
                    setTotalDevices(res.Data.TotalLength);
                    setTotalPages(res.Data.TotalLength);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }
    useEffect(() => {
        getList()
    }, []);

    useEffect(() => {
        getList()
    }, [currentPageNo]);

    const getDownloadHistoryDetails = (userId, currentPageNo, tempStringText) => {
      // debugger
      getDownloadDetails(userId, currentPageNo, tempStringText)
        .then(res => {
          if (res && res.Status && res.Status === "success" && res.Data && res.Data.LstDownloadRequest) {
            try { 
              // debugger
              console.log("rupesh checks",res.Data.LstDownloadRequest)
              // let temp = JSON.parse(res.Data.LstDownloadRequest);
              let temp = res.Data.LstDownloadRequest;
              let tempArr = temp.map((item) => {
                let obj = {
                  ...item,
                  // isItSelected: true
                }
                return obj;
              });
              console.log("rupesh checks",tempArr)
              console.log("rupesh checks",tempArr.length)
              setTempDownloadList(tempArr);
            } catch (error) {
              console.error("Error parsing JSON data:", error);
              // Handle parsing error here, maybe setTempDownloadList([]) or other appropriate action
            }
          } else {
            console.error("Invalid response format or status:", res);
            // Handle invalid response format or status here
          }
        })
        .catch(error => {
          console.error("Error fetching download details:", error);
          // Handle error fetching download details here
        });
    };
    
    const searchAccessDetails = () => {
      let obj = {
      "request_id":idSearch,
      "site_name":siteSearch,
      "device_name":deviceSearch,
      "status":statusSearch
      }

      let stringText = JSON.stringify(obj);
      console.log("rupesh checks stringText",stringText)
      let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";")//
      setCurrentPageNo(1);
      let userId = localStorage.getItem("UserID");
      getDownloadHistoryDetails(userId,currentPageNo,tempStringText)
    }

  useEffect(() => {
    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      searchAccessDetails();
    }, 500)

    setTimer(newTimer)
  }, [idSearch, siteSearch, deviceSearch,statusSearch])

    return (
      <>
        <div className="MainContentMargin">
          <div
            className="row col-lg-12"
            style={{ margin: "0 auto", padding: "0px" }}
          >
            <div className="col-lg-12 dashboardHeadingInnerDiv">
              <div className="NameHeading1">
                <p>Download Request & History</p>
              </div>
            </div>
          </div>
          <div className="col-lg-12 report-container-height AlertActivityReportTable">
            <div className="ReportAccordionMainContainer">
              <div className="card-body">
                <table
                  className="table table-text-ext table-fixed report-table mt-0 downloadTable"
                  id="alert-activity-report"
                >
                  <thead className="ReportTableHeader ReportTableHeadReport downloadDetailTable">
                    <tr>
                      <th scope="col">
                        <p>Request ID</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <input
                            className="SearchViewInput"
                            type="search"
                            placeholder="Search"
                            value={idSearch}
                            onChange={(e) => setIdSearch(e.target.value)} 
                          />
                          <img
                            className="EyeIcon"
                            alt="Search-icon"
                            src={SearchIcon}
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <p>Request Date Time</p>
                        <div className="mt-1">
                          <DatePicker
                            format="dd-MM-yyyy HH:mm:ss"
                            placement="autoVerticalEnd"
                            value={requestDateTime}
                            onChange={(e) => requestDateHandler(e)}
                            disabledDate={(currentDate) =>
                              currentDate &&
                              currentDate < new Date().setHours(0, 0, 0, 0)
                            }
                            disabled
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <p>Status</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <select
                            required
                            className="ArrowSelect HMTableSelect"
                            value={statusSearch}
                            onChange={(e) => statusChanged(e)}
                          >
                            <option key="0" value="0">
                              - Select Status -
                            </option>
                            {statusArr.map((item) => (
                              <option key={item.id} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </th>

                      <th scope="col">
                        <p>Site</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <input
                            className="SearchViewInput"
                            type="search"
                            placeholder="Search"
                            value={siteSearch}
                            onChange={(e) => setSiteSearch(e.target.value)} 
                          />
                          <img
                            className="EyeIcon"
                            alt="Search-icon"
                            src={SearchIcon}
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <p>Device</p>
                        <div className="SearchTextDiv StatusSearchText">
                          <input
                            className="SearchViewInput"
                            type="search"
                            placeholder="Search"
                            value={deviceSearch}
                            onChange={(e) => setDeviceSearch(e.target.value)}  
                          />
                          <img
                            className="EyeIcon"
                            alt="Search-icon"
                            src={SearchIcon}
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <p>From Date</p>
                        <div className="mt-1">
                          <DatePicker
                            format="dd-MM-yyyy HH:mm:ss"
                            placement="autoVerticalEnd"
                            value={fromDateTime}
                            onChange={(e) => requestDateHandler(e)}
                            disabledDate={(currentDate) =>
                              currentDate &&
                              currentDate < new Date().setHours(0, 0, 0, 0)
                            }
                            disabled
                          />
                        </div>
                      </th>
                      <th scope="col">
                        <p>To Date</p>
                        <div className="mt-1">
                          <DatePicker
                            format="dd-MM-yyyy HH:mm:ss"
                            placement="autoVerticalEnd"
                            value={toDateTime}
                            onChange={(e) => requestDateHandler(e)}
                            disabledDate={(currentDate) =>
                              currentDate &&
                              currentDate < new Date().setHours(0, 0, 0, 0)
                            }
                            disabled
                          />
                        </div>
                      </th>

                      <th scope="col">
                        <p>Expiring In Time</p>
                        <div style={{ height: "35px", marginTop: "5px" }}></div>
                      </th>
                      <th style={{ width: "10%" }}>
                        <p>Actions</p>
                        <div style={{ height: "35px", marginTop: "5px" }}></div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tempDownloadList &&
                        tempDownloadList.map((item) => (
                          <DownloadItem
                            key={item.VideoExportLogID}
                            requestId={item.VideoExportLogID}
                            requestDate={item.ExportRequestedOn}
                            status={item.ExportStatus}
                            expiryDate={item.ExportRequestedOn}
                            site={item.SiteName}
                            device={item.DeviceName}
                            fromTime={item.ExportFromDateTime}
                            toTime={item.ExportToDateTime}
                          />
                        ))
                    }
                    {(!tempDownloadList || tempDownloadList.length == 0) && (
                      <div className="noDataAccessMsg">
                        <div>
                          <img
                            className="informationIcon"
                            src={InfoBlue}
                            alt="Info"
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <h3 className="No-Activitylog-Present">
                            No Downlod History present.
                          </h3>
                        </div>
                      </div>
                    )}
                  </tbody>
                  {totalDevices == 0 ||
                  totalPages == 0 ||
                  totalPages <= limit ? (
                    <div className="PaginationDiv mb-2 mt-2">
                      <h5 className="PageNumbers">
                        {totalDevices == 0 ? 0 : 1} - {totalDevices}
                      </h5>
                      <span>of</span>
                      <h5 className="PageNumbers">{totalDevices}</h5>
                      <Pagination>
                        <Pagination.Prev disabled />
                        <Pagination.Item active disabled>
                          {1}
                        </Pagination.Item>
                        <Pagination.Next disabled />
                      </Pagination>
                    </div>
                  ) : (
                    <div className="PaginationDiv mb-2 mt-2 mr-2">
                      <h5 className="PageNumbers">
                        {totalDevices == 0
                          ? 0
                          : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                        -
                        {currentPageNo * limit > totalDevices
                          ? totalDevices
                          : currentPageNo * limit}
                      </h5>
                      <span>of</span>
                      <h5 className="PageNumbers">{totalDevices}</h5>
                      <Pagination>
                        <Pagination.Prev
                          onClick={prevClick}
                          disabled={currentPageNo == 1}
                        />
                        {
                          // PaginationComponentTwo(pageCounter)
                          PaginationComponent(currentPageNo)
                        }
                        {/* <Pagination.Item>1</Pagination.Item>
                                            <Pagination.Item>2</Pagination.Item>
                                            <Pagination.Item>3</Pagination.Item> */}
                        <Pagination.Next
                          onClick={nextClick}
                          disabled={currentPageNo == maxPageAllowed}
                        />
                      </Pagination>
                    </div>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}


