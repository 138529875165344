import { BASE_URL } from './../config/index'

export const getWFMasterList = async () => {

    let url = `${BASE_URL}getworkflowmasterdata`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "action": "getMasterList"
        })
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            ////sessionStorage.setItem('workflow-master', JSON.stringify(data.message))
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const loadWorkflows = async (userId) => {
    let url = `${BASE_URL}workflowstepscrud`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            "action": "getList",
            "userID": userId
        })
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}

export const workFlowOperations = async (obj) => {

    let url = `${BASE_URL}workflowstepscrud`

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(obj),
    };

    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
            return data;
        })
        .catch(function (error) {
            console.error(error);
        });
}
