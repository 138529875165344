import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SkelSinglePlayback = () => {
  return (
    // <div className="single-cam-playback-sub">
    <>
    <Skeleton 
    // className='single-cam-playback-player' 
    style={{ borderRadius: "13px", width: "100%", height: "100%" }} />
    {/* <Skeleton className='single-cam-playback-controls'></Skeleton> */}
    </>
    // </div>
  )
}

export default SkelSinglePlayback