export function CheckForPrivilege(privilegename) {
    try{
    let userRole  = JSON.parse(localStorage.getItem('user'))
        let res;
        if (userRole?.UserRoleID === 3) {
            
            res = userRole.Privileges.find(privilege => privilege.EnumTitle ===privilegename)
            if(res){
                return res.IsSelected=== "True" ? true : false
            }else{ return false}
        }
        else 
        return true;
    }catch(error){
        console.error(error)
    }
}