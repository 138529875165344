import React from 'react'

const HealthListView = ({setShow, show, data, selectedItem}) => {
  return (
      <tr onClick={() => {
        selectedItem(data)  
      }}>
          <td>{data.DeviceName}</td>
          <td>{data.InterfaceName}</td>
          <td>{data.DeviceType}</td>
          <td>{data.SiteName}</td>
          <td 
          className={data.DeviceStatus == "Online" || data.DeviceStatus == "Normal" ? "online-color" : "offline-color"}>{data.DeviceStatus}</td>
          <td>{data.StatusDateTime}</td>
      </tr>
  )
}

export default HealthListView