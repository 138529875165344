import React, { useState, useEffect } from 'react'
import HealthListView from './HealthListView';
import { SearchIcon ,SearchIconWhite } from "../../assets/images";
import Skeleton from "react-loading-skeleton";
import { DatePicker } from "rsuite";
import { useDataAccessPagination } from "../../CustomHooks/useDataAccessPagination";
import Pagination from "react-bootstrap/Pagination";
import { getHealthMonitorData } from '../../services/healthMonitoringService';
import HealthModal from './HealthModal';
import { ConvertDate } from '../../utils/utils'; 

const HealthTable = (props) => {

  let { tableLoading, setTableLoading, refreshButton, setRefreshButton, isDarkTheme } = props;
  const [totalDevices, setTotalDevices] = useState(0);
  const [healthData, setHealthData] = useState(null);
  const [mainHealthData, setMainHealthData] = useState([]);
  const [deviceSearch, setDeviceSearch] = useState("");
  const [show, setShow] = useState(false);
  const [interfaceSearch, setInterfaceSearch] = useState("");
  const [siteSearch, setSiteSearch] = useState("");
  const [interfaceTypeSearch, setInterfaceTypeSearch] = useState("");
  const [currentStatusSearch, setCurrentStatusSearch] = useState("");
  const [datetimeSearch, setDateTimeSearch] = useState("");
  const [typingStart, setTypingStart] = useState(false);
  const [totalString, setTotalString] = useState("");
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [nextClick, prevClick, pageCounter, currentPageNo, limit, totalPages, setCurrentPageNo, setTotalPages, maxPageAllowed, setPageCounter] = useDataAccessPagination(10, 1, 10, 1);

  useEffect(() => {
    if (deviceSearch.length > 0) {
      setTypingStart(true);
    }
    if (deviceSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [deviceSearch])

  useEffect(() => {
    if (siteSearch.length > 0) {
      setTypingStart(true);
    }
    if (siteSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [siteSearch])

  useEffect(() => {
    if (interfaceSearch.length > 0) {
      setTypingStart(true);
    }
    if (interfaceSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [interfaceSearch])

  useEffect(() => {
    if (interfaceTypeSearch.length > 0) {
      setTypingStart(true);
    }
    if (interfaceTypeSearch === "" && typingStart) {
      searchListDetails()
    }
  }, [interfaceTypeSearch])

  useEffect(() => {
    // if (currentPageNo != 1) {
      setTableLoading(true);
      let loggedInUser = JSON.parse(localStorage.getItem('user'))
      getHealthMonitorData(currentPageNo, loggedInUser.UserUniqueID, totalString)
        .then((res) => {
          if (res.Status == "success") {
            setHealthData(res.Data.DeviceStatusModels);
            setMainHealthData(res.Data.DeviceStatusModels);
            setTableLoading(false);
            setTotalPages(parseInt(res.Data.Total));
            setTotalDevices(parseInt(res.Data.Total));
          }
        })
        .catch(err => {
          console.log(err);
          setTableLoading(false);
        })
    // }
  }, [currentPageNo])

  useEffect(() => {
    if(refreshButton){
      searchListDetails();
      setRefreshButton(false);
    }
  }, [refreshButton]);

  const dateTimeHandler = (e) => {
    let selectedDate = ConvertDate(new Date(e)).replaceAll(":","-");
    setDateTimeSearch(selectedDate);
    searchListDetails(selectedDate, "DATETIME");
  }

  const PaginationComponent = (currentPageNo) => {
    let numbers = [];
    if (maxPageAllowed - 2 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1) || currentPageNo === (maxPageAllowed - 2))) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 2}
          active={maxPageAllowed - 2 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 2)}
        >
          {maxPageAllowed - 2}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed}
          active={maxPageAllowed == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed)}
        >
          {maxPageAllowed}
        </Pagination.Item>)
    }
    else if (maxPageAllowed - 1 != 0 && (currentPageNo === maxPageAllowed || currentPageNo === (maxPageAllowed - 1))) {
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed - 1}
          active={maxPageAllowed - 1 == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed - 1)}
        >
          {maxPageAllowed - 1}
        </Pagination.Item>)
      numbers.push(
        <Pagination.Item
          key={maxPageAllowed}
          active={maxPageAllowed == currentPageNo}
          onClick={() => setCurrentPageNo(maxPageAllowed)}
        >
          {maxPageAllowed}
        </Pagination.Item>)
    }
    else {
      for (let i = currentPageNo; i < currentPageNo + 3; i++) {
        if (i <= maxPageAllowed) {
          numbers.push(
            <Pagination.Item
              key={i}
              active={i == currentPageNo}
              onClick={() => setCurrentPageNo(i)}
            >
              {i}
            </Pagination.Item>
          )
        }
      }
    }
    return numbers;
  }

  const searchListDetails = (data, type) => {
    let tempInterfaceType = interfaceTypeSearch.replace("0", "");
    let tempCurrentStatus = currentStatusSearch.replace("0", "");
    let tempDateTime = datetimeSearch;
    if(type == "InterfaceType"){
      tempInterfaceType = data.replace("0", "");
    }else if(type == "CurrentStatus"){
      tempCurrentStatus = data.replace("0", "");
    }else if(type == "DATETIME") {
      tempDateTime = data;
    }

    let obj = {
      "devicename": deviceSearch,
      "interfacename": interfaceSearch,
      "sitename": siteSearch,
      "devicetype": tempInterfaceType,
      "devicestatus": tempCurrentStatus,
      // "devicestatustime": tempDateTime, 
    }
    let stringText = JSON.stringify(obj);
    let tempStringText = stringText.replace(/["'{}]/g, "").replace(/[,]/g, ";")
    setTotalString(tempStringText);
    getHMData(tempStringText)
    // Call the API Function Here.
  }

  const getHMData = (tempStringText) => {
    setTableLoading(true);
    let loggedInUser = JSON.parse(localStorage.getItem('user'))
    getHealthMonitorData(currentPageNo, loggedInUser.UserUniqueID, tempStringText)
      .then((res) => {
        if (res.Status == "success") {
          setHealthData(res.Data.DeviceStatusModels);
          setMainHealthData(res.Data.DeviceStatusModels);
          setTableLoading(false);
          setTotalPages(parseInt(res.Data.Total));
          setTotalDevices(parseInt(res.Data.Total));
          setCurrentPageNo(1)
          setPageCounter(1)
        }
      })
      .catch(err => {
        console.log(err);
        setTableLoading(false);
      })
  }

  const selectedItem = (data) => {
    setSelectedDevice(data);
    setShow(!show);
  }

  const interfaceTypeChange = (e) => {
    setInterfaceTypeSearch(e.target.value)
    searchListDetails(e.target.value, "InterfaceType");
  }

  const currentStatusChange = (e) => {
    setCurrentStatusSearch(e.target.value);
    searchListDetails(e.target.value, "CurrentStatus");
  }

  // const sorting = (col) => {
  //   if (order === "ASC") {
  //     const sorted = [...healthData].sort((a, b) => 
  //       a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
  //     );
  //     setHealthData(sorted);
  //     setOrder("DEC");
  //   }
  //   if (order === "DEC") {
  //     const sorted = [...healthData].sort((a, b) => 
  //       a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
  //     );
  //     setHealthData(sorted);
  //     setOrder("ASC");
  //   }
  // }

  return (
    <>
      <div className="DeviceCurrentStatusDiv">
        <div className="DeviceTableHeader">
          <p>Device(s) Current Status</p>
        </div>
       
    <div className="card-body p-0">
      <table
        className="table table-text-ext table-fixed report-table healthmonitoringTable mb-2"
        id="alert-activity-report"
      >
        <thead>
          <tr>
            <th>
              <p>Device Name(s)</p>
              <div className="SearchTextDiv StatusSearchText">
                <input
                  className="SearchViewInput"
                  type="search"
                  placeholder="Search"
                  value={deviceSearch}
                  onChange={(e) => setDeviceSearch(e.target.value)}
                />
                {
                  props.isDarkTheme ? 
                  <img
                    className="EyeIcon"
                    alt="Search-icon"
                    src={SearchIcon}
                    onClick={() => searchListDetails()}
                  /> :
                  <img
                    className="EyeIcon"
                    alt="Search-icon"
                    src={SearchIconWhite} height="15"
                    onClick={() => searchListDetails()}
                  /> 
                }
              </div>
            </th>
            <th>
              <p>Interface Name(s)</p>
              <div className="SearchTextDiv StatusSearchText">
                <input
                  className="SearchViewInput"
                  type="search"
                  placeholder="Search"
                  value={interfaceSearch}
                  onChange={(e) => setInterfaceSearch(e.target.value)}
                />
                {
                  props.isDarkTheme ?
                  <img
                    className="EyeIcon"
                    alt="Search-icon"
                    src={SearchIcon}
                    onClick={() => searchListDetails()}
                  /> :
                  <img
                    className="EyeIcon"
                    alt="Search-icon"
                    src={SearchIconWhite} height="15"
                    onClick={() => searchListDetails()}
                  />

                }
              </div>
            </th>
            <th>
              <p>Device Type(s)</p>
              <div className="SearchTextDiv StatusSearchText">
                <select
                  required
                  className="ArrowSelect HMTableSelect"
                  value={interfaceTypeSearch}
                  onChange={(e) => interfaceTypeChange(e)}
                >
                  <option key="0" disabled={false} value="0">
                    - Select Data -
                  </option>
                  <option key="1" value="Camera">
                    Camera
                  </option>
                  <option key="2" value="NVR">
                    NVR
                  </option>
                  <option key="3" value="DVR">
                    DVR
                  </option>
                  <option key="4" value=" Storage Device">
                  Storage Device
                  </option>
                  <option key="5" value="Hybrid">
                    Hybrid
                  </option>
                </select>
              </div>
            </th>

                <th>
                  <p>Site(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <input
                      className="SearchViewInput"
                      type="search"
                      placeholder="Search"
                      value={siteSearch}
                      onChange={(e) => setSiteSearch(e.target.value)}
                    />
                    {
                      props.isDarkTheme ?
                      <img
                        className="EyeIcon"
                        alt="Search-icon"
                        src={SearchIcon}
                        onClick={() => searchListDetails()}
                      /> :
                       <img
                        className="EyeIcon"
                        alt="Search-icon"
                        src={SearchIconWhite} height="15"
                        onClick={() => searchListDetails()}
                      />
                    }
                  </div>
                </th>

                <th>
                  <p>Current Status(s)</p>
                  <div className="SearchTextDiv StatusSearchText">
                    <select
                      required
                      className="ArrowSelect HMTableSelect"
                      value={currentStatusSearch}
                      onChange={(e) => currentStatusChange(e)}
                    >
                      <option
                        // hidden
                        key="0"value="0">
                        - Select Data -
                      </option>
                      <option key="1" value="Online">
                        Online
                      </option>
                      <option key="2" value="Offline">
                        Offline
                      </option>
                      <option key="3" value="Normal">
                        Normal
                      </option>
                      <option key="4" value="Faulty">
                        Faulty
                      </option>
                    </select>
                  </div>
                </th>
                <th>
                  <p>Last Update Date Time</p>
                  <div className="mt-1">
                    <DatePicker
                      disabled={true}
                      format="dd-MM-yyyy HH:mm:ss"
                      placement="autoVerticalEnd"
                      onOk={(e) => dateTimeHandler(e)}
                    />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className='OnHoverMouseTableRow'>
              {
                tableLoading
                  ?
                  (
                    <tr onClick={() => setShow(!show)}>
                      {
                        <>
                          <td>
                            <Skeleton className="width70pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                          <td>
                            <Skeleton className="width60pct" />
                          </td>
                          <td>
                            <Skeleton className="width70pct" />
                          </td>
                          <td className="online-color">
                            <Skeleton className="width60pct" />
                          </td>
                          <td>
                            <Skeleton className="width80pct" />
                          </td>
                        </>
                      }
                    </tr>
                  )
                  :
                  (
                    <>
                      {
                        healthData && (
                          healthData?.length > 0 ?
                          healthData.map((item) => (
                            <HealthListView key={item.DeviceId} setShow={setShow} show={show} data={item} selectedItem={selectedItem} />
                          )) : <div className='noRecordFound'><p className='noRecordFoundText'>No Record Found.</p></div>
                        )
                      }
                      {
                        !healthData && (
                          <div class="no-report-found"><p className='noRecordFoundText'>No Record Available.</p></div>
                        )
                      }
                    </>
                  )
              }
            </tbody>
            {
              totalPages == 0 || totalPages <= limit
                ? <div className="PaginationDiv mb-2 mt-2">
                <h5 className='PageNumbers'>{totalDevices == 0 ? 0 : 1} - {totalDevices}</h5>
                <span>of</span>
                <h5 className='PageNumbers'>{totalDevices}</h5>
                <Pagination>
                    <Pagination.Prev disabled/>
                    <Pagination.Item active disabled>{1}</Pagination.Item>
                    <Pagination.Next disabled/>
                </Pagination>
                </div>
                :
                <div className="PaginationDiv mb-2 mt-2">
                  <h5 className="PageNumbers">
                    {
                      totalDevices == 0 ? 0
                        : (currentPageNo - 1) * parseInt(limit) + parseInt(1)}
                    -
                    {
                      (currentPageNo * limit) > (totalDevices)
                        ? (totalDevices)
                        : currentPageNo * limit
                    }
                  </h5>
                  <span>of</span>
                  <h5 className="PageNumbers">{totalDevices}</h5>

                  <Pagination>
                    <Pagination.Prev onClick={prevClick} disabled={currentPageNo == 1} />
                    {
                      PaginationComponent(currentPageNo)
                    }
                    <Pagination.Next onClick={nextClick} disabled={currentPageNo == maxPageAllowed} />
                  </Pagination>
                </div>
            }
          </table>
        </div>
      </div>
      {
        show 
        ? <HealthModal show={show} setShow={setShow} selectedDevice={selectedDevice} isDarkTheme={isDarkTheme} />
        : null
      }
    </>
  )
}

export default HealthTable