import { BASE_CORE_URL, BASE_URL, AUTH_CODE, AUTH_TOKEN } from './../config/index'

export const Getuserslist = async (obj) => {
    console.log(obj);
    let url;
    let requestOptions;
      url = BASE_CORE_URL + "User/Get"
      requestOptions = {
        method: "GET",
        headers: { 
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken'),
        "Content-Type": "application/json"
        },
      }
    // else{
    //   url = BASE_URL + "userconfigcrud";
    //   requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json", },
    //     body: JSON.stringify(obj),
    // };
    // }
    console.log("Get User List New API", requestOptions, url);
    return await fetch(url, requestOptions)
        .then((resp) => {
          console.log("Get User List New API", resp);
          return resp.json()
        })
        .then(function (data) {
        console.log("Get User List New API", data);
        console.log("userconfigcrud() Data: ", data);
        return data;
        })
        .catch(function (error) {
        console.error("userconfigcrud() Exception: ", error);
        });
}

export const createNewUser = async (obj) => {
    let url;
    let requestOptions = {
      method: "POST",
      headers: { 
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')  
      },
      body: JSON.stringify(obj),
    }
    url = BASE_CORE_URL + "User/Add";    
    // else{
    //   url = BASE_URL + "registeruserwithprivileges";
    //   requestOptions.headers = { 
    //     "Content-Type": "application/json",
    //   }
    // }
    
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        console.log("registeruserwithprivileges () Data: ", data);
        return data;
      })
      .catch(function (error) {
        console.error("registeruserwithprivileges () Exception: ", error);
      });
  }

  export const registerEmailUser = async (obj) => {
    const requestOptions = {
      method: "POST",
      headers:{ "Content-Type": "application/json" },
      body: JSON.stringify(obj),
    };
    let url = BASE_URL + "registeremailuser ";
    console.log("api url", url)
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        console.log("registeremailuser () Data: ", data);
        return data;
      })
      .catch(function (error) {
        console.error("registeremailuser () Exception: ", error);
      });
  }

  export const updateUser = async (obj) => {
    console.log("Update User API", obj)
    const requestOptions = {
      method: "POST",
      body: JSON.stringify(obj),
    };
    let url;   
      url = BASE_CORE_URL + "User/Update";
      requestOptions.headers = {
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken'),
      "Content-Type": "application/json"
      }

    // else{
    //   url = BASE_URL + "updateprivilegeduser";
    //   requestOptions.headers = { "Content-Type": "application/json",}
    // }
    console.log("RequestOptions", url);
    console.log("RequestOptions", requestOptions);
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        console.log("updateprivilegeduser() Data: ", data);
        return data;
      })
      .catch(function (error) {
        console.error("updateprivilegeduser() Exception: ", error);
      });
  }

  export const GetPrivilegeGroupList = async (userId) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      //body: JSON.stringify({ "UserID": userId })
    };
  
    let url = BASE_URL + "getprivilegegrouplist?UserID=" + userId;
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        console.log("getprivilegegrouplist () Data: ", data);
        return data;
      })
      .catch(function (error) {
        console.error("getprivilegegrouplist () Exception: ", error);
      });
  }

  export const deleteUser = async (userId) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ UserID: userId }),
    };
  
    let url = BASE_URL + "deleteprivilegeduser ";
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        console.log("deleteprivilegeduser () Data: ", data);
        return data;
      })
      .catch(function (error) {
        console.error("deleteprivilegeduser () Exception: ", error);
      });
  }

  export const GetUserProfileInfoDotNet = async() => {
    const requestOptions = {
      method: 'GET',
      headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
      }
    }
    let url = BASE_CORE_URL + "User/GetUserByAuthCode?" + new URLSearchParams({
      authCode: localStorage.getItem('authCode')
    });
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(data => {
        console.log("Profile Dot Net: ", data);
        return data;
      }).catch(function (error) {
        console.error("GetUserProfileInfo () Exception: ", error);
      });
  }

  export const GetUserProfileInfo = async (useruniqueid) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
  
    let url = BASE_URL + "getuserdetails/?useruniqueid=" + useruniqueid;
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        console.log("GetUserProfileInfo () Data: ", data);
        return data;
      })
      .catch(function (error) {
        console.error("GetUserProfileInfo () Exception: ", error);
      });
  }

  export const userGroupAndSiteGroupCRUD = async (obj) => {
    console.log(obj);
    const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(obj),
    };

    let url = BASE_URL + "usergroupandsitegroupcrud";
    return await fetch(url, requestOptions)
        .then((resp) => resp.json())
        .then(function (data) {
        console.log("usergroupandsitegroupcrud() Data: ", data);
        return data;
        })
        .catch(function (error) {
        console.error("usergroupandsitegroupcrud() Exception: ", error);
        });
}

// export const Getsitelist = async () => {
//     let userid = localStorage.getItem('UserID');
//     const requestOptions = {
//       method: "GET",
//       headers: { "Content-Type": "application/json" },
//     };
  
//     let url = BASE_URL + "getsitelist?UserID=" + userid;
//     return await fetch(url, requestOptions)
//       .then((resp) => {
//         // console.log("site details resp", { resp });
//         return resp.json();
//       })
//       .then(function (data) {
//         // console.log("getsitelist () Data: ", data);
//         return data;
//       })
//       .catch(function (error) {
//         console.error("getsitelist () Exception: ", error);
//       });
// }