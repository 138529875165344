import React from "react";
import Calendar from "react-calendar";
import TimerCard from "./TimerCard";
import { subDays } from "date-fns";
import { PlayBackWhite, Video_Export } from "../../assets/images";
import DateTimeArrow from "../../assets/images/DateTimeArrow.svg";

const PopupCard = ({
  onSubmitBtn,
  dateChange,
  date,
  getDataFromChild,
  dateTwo,
  timeStringMain,
  mode,
  getDataFromChildTwo,
  setBlankDateTwo,
  setBlankDate,
  onExportBtn,
  exportCurrentVideoHide,
  videoExportDownload
}) => {
  return (
    <>
      {
        mode == "Playback" ? null
          :
          <>
            {
              !exportCurrentVideoHide
              ?
              <>
                  <div className="popup_link mt-0"
                  onClick={videoExportDownload}
                  >
                    {/* <img src={PlayBackWhite} className="playbackicon mr-2" alt="playback icon" /> */}
                    <span>Download Current Video</span>
                  </div>
                  <div className="ORLine"></div>
              </>
              : 
              null
            }
          </>
      }
      <div className="popupDiv">
        <div className="popup_card">
          <p className="popup_header">Date and Time</p>
          <Calendar
            onChange={dateChange}
            value={date}
            maxDate={new Date()}
            minDate={subDays(new Date(), 180)}
          />
        </div>
        {mode == "Playback" ? (
          <div className="popup_cardTimer">
            <div className="popup_cardTimerRight">
              <TimerCard
                dateFromParent={date}
                getDataFromChild={getDataFromChild}
                setBlankDate={setBlankDate}
              />
            </div>
            <div className="popup_cardTimerRightArrow">
              {/* -&gt; */}
              <img src={DateTimeArrow} alt="DateTimeArrow" />
            </div>
            <div className="popup_cardTimerRightTwo">
              <p>{timeStringMain}</p>
            </div>
          </div>
        ) : (
          <div className="popup_cardTimer">
            <div
              className="popup_cardTimerRight"
              style={{ marginRight: "0px" }}
            >
              <TimerCard
                dateFromParent={date}
                getDataFromChild={getDataFromChild}
                setBlankDate={setBlankDate}
              />
            </div>
            <div
              className="popup_cardTimerRightArrow"
              style={{ margin: "0.5rem" }}
            >
              <img src={DateTimeArrow} alt="DateTimeArrow" />
            </div>
            <div
              className="popup_cardTimerRight"
              style={{ marginRight: "0px" }}
            >
              <TimerCard
                dateFromParent={dateTwo}
                getDataFromChild={getDataFromChildTwo}
                setBlankDate={setBlankDateTwo}
              />
            </div>
          </div>
        )}
        <>
          {mode == "Playback" ? (
            <div className="popup_link" onClick={onSubmitBtn}>
              <img
                src={PlayBackWhite}
                className="playbackicon mr-2"
                alt="playback icon"
              />
              {/* <span onClick={onSubmitBtn}>Playback</span> */}
              <span>Playback</span>
            </div>
          ) : (
            <div className="popup_link" onClick={onSubmitBtn}>
              <img
                src={Video_Export}
                className="playbackicon mr-2"
                alt="playback icon"
              />
              <span>Export</span>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default PopupCard;
