
//#region lib
import "rsuite/dist/rsuite.min.css";
import {useEffect, useState, useRef} from 'react'
//#endregion lib

import CameraTileSkeleton from "./CameraTileSkeleton";

//#region custom hooks 
import { WarningToast } from '../../CustomHooks/WarningToast'
import { THIRTY_MINUTES } from "../../navigation/CONSTANTS";
import CameraCard from "./CameraCard";
//#endregion

const CameraTileComponent = ({
  cameraUI,
  isCheckBoxVisible,
  setIsDatePickerAccessAllowed,
  isMultiPlay,
  cameraMasterList,
  isLoading,
  isChecked,
  setIsChecked,
  setIsSingleDevPlaybackBtnClick,
  singleDevPlaybackClick,
  setIsDatePickerVisible,
  exportStartTime,
  exportEndTime,
}) => {

  // const [uniqueKey, setUniqueKey] = useState(Date.now());
  const [time, setTime] = useState(Date.now());
  const [selectedCams, setSelectedCams] = useState(0);
  const playerRef = useRef(null);
  const onCheckBoxOnClick = (e) => {
    const chkStatus = e.target.checked
    var selDevLst = []
    if (cameraUI && cameraUI.length > 0) {
      const selectedCamera = cameraUI.find(x => x.ParentCameraID == e.target.id)
      const selMasterDataCam = cameraMasterList.find(x => x.ParentCameraID == e.target.id)
      selDevLst = cameraMasterList.filter(x => x.isChecked === true)
      if (selDevLst.length >= 4 && chkStatus == true) {
        WarningToast('Only four devices are allowed to select at a time!')
        e.preventDefault();
        return
      }
      else {
        setIsChecked(true)

      }
      setSelectedCams(selDevLst.length)
      if (selectedCamera && selectedCamera != undefined) {
        selectedCamera.isChecked = chkStatus
        selMasterDataCam.isChecked = chkStatus

      }
      const currentSelDevLst = cameraMasterList.filter(x => x.isChecked === true)
      if (currentSelDevLst.length > 1) {
        setIsDatePickerAccessAllowed(true)
      }
      else {
        setIsDatePickerAccessAllowed(false)
      }
    }

  }

  const onSingleDevPlaybackHandleClick = (dev) => {
    setIsSingleDevPlaybackBtnClick(true)
    setIsDatePickerVisible(true)
    singleDevPlaybackClick(dev)
  }

  // useEffect(() => {
  //   // generate the unique key only when the component mounts
  //   setUniqueKey(Date.now());
  // }, []);

  // useEffect(() => {
  //   const intervalTimer = setInterval(() => {

  //   }, 60000);

  //   return () => clearInterval(intervalTimer);
  // }, []);

  //tej refresh camera stream after every 60 sec
  useEffect(() => {
      const intervalId = setInterval(() => {
        if (isMultiPlay == false) {
        setTime(Date.now())
      }
      }, THIRTY_MINUTES); // set the interval to 30 mins
  
      return () => clearInterval(intervalId); // clear the interval on unmount
 
  }, []);

  return (
    <>
      {isLoading ?
        <> 
          { 
            Array.from(Array(selectedCams+1)).map(() => (
              <CameraTileSkeleton></CameraTileSkeleton>
            ))
          }
          {/* <CameraTileSkeleton></CameraTileSkeleton>
          <CameraTileSkeleton></CameraTileSkeleton>
          <CameraTileSkeleton></CameraTileSkeleton>
          <CameraTileSkeleton></CameraTileSkeleton> */}
          </>
        :
        
        cameraUI && cameraUI.length > 0 &&
        cameraUI.map((item) => (
          <CameraCard item={item} time={time} key={item.ParentCameraID} isMultiPlay={isMultiPlay} isCheckBoxVisible={isCheckBoxVisible} onSingleDevPlaybackHandleClick={onSingleDevPlaybackHandleClick} isChecked={isChecked} onCheckBoxOnClick={onCheckBoxOnClick} exportStartTime={exportStartTime} exportEndTime={exportEndTime} />
          // <div className="CameraDiv1" key={item.StreamName}>
          //   <>
          //     {item &&
          //       item.StreamingURL ?
          //       <> 
          //         <ReactPlayer
          //           ref={playerRef}
          //           key={time}
          //           muted
          //           itemID={"video-" + item.StreamName}
          //           url={ isMultiPlay && isMultiPlay == true ?  item.playbackURL  : item.StreamingURL}
          //           playing={true}
          //           style={{ borderRadius: "13px", width: "100%", height: "100%" }}
          //           controls
          //           autoPlay
          //           pip={true} // for picture in picture
          //           stopOnUnmount={false} // for picture in picture
          //           className="player-responsive1"
          //           onError={(e)=>ReactPlayerHandleOnError(e, item.CameraName)}
                    
          //           config={{
          //             file: {
          //               attributes: {
          //                 controlsList: 'nodownload',
          //                 onContextMenu: (e) => e.preventDefault(),
          //               },
          //               tracks: [],
          //               forceVideo: true,
          //               forceAudio: true,
          //               http: {
          //                 headers: getHeaders(),
          //               },
          //             },
          //           }}
          //         />
          //       </>
          //       :
          //       <>
          //         <div className="no-stream-found">
          //           <img
          //             src={MosaicNoStream}
          //             alt="Video"
          //             className="w-100 image-bg mosaic-nostream-img"
          //           />
          //         </div>

          //       </>
          //     }

          //   </>
          //   <Row className="m-0 pt-3">
          //     <Col lg={9}>
          //       <p className="CameraNameText1 camera-name cam-name">{item.CameraName}</p>
          //       <p className="LocationNameText camera-name location-name">
          //         Location: <span>{item.SiteName}</span>{" "}
          //       </p>
          //     </Col>
          //     {isCheckBoxVisible && isCheckBoxVisible == true &&
          //       <Col className="col-lg-3">
          //         <input
          //           className="camera-checkbox"
          //           type="checkbox"
          //           id={item.ParentCameraID}
          //           defaultChecked={isChecked == true ? item.isChecked == true ? true : false : false}
          //           onClick={e => { onCheckBoxOnClick(e) }}
          //         />
          //       </Col>
          //     }
          //     {isCheckBoxVisible === false &&
          //       <>
          //         <Col className="col-lg-3">
          //           <button className="playbackbtn" onClick={e => { onSingleDevPlaybackHandleClick(item) }}>
          //             <img src={Playbackimage} className="camera-icon-height" /></button>
          //         </Col>
          //       </>
          //     }

          //   </Row>
          // </div>
        ))


      }


    </>
  )
}

export default CameraTileComponent;