import React, { useState, useEffect, useContext } from "react"
import AlertNotificationIcon from '../../assets/images/alert-notification-2.svg'
import { messaging } from '../../services/firebase'
//import { useToasts } from 'react-toast-notifications';
import { useLocation, useHistory } from 'react-router-dom';
import OptionsIcon from '../../assets/images/options.svg'
import MarkunreadIcon from '../../assets/images/mark-unread.svg'
import { getAll, getertmessage, removeAll, removeOne, updateStatus } from "../../utils/NotificationUtil";
import { ConversationStateContext } from '../../Context/ConversationStateContext'
import { to12HrFormat } from "../../utils/utils";
import { mixpanel } from '../../utils/MixPanelUtil'
import AlertIcon from '../../assets/images/alert-icon.svg'
import CameraIcon from '../../assets/images/cameraIcon.svg'

export default function Allnotifications() {
    const history = useHistory()
    //const { addToast } = useToasts();
    const [notifications, setNotifications] = useState([])
    const [unReadCount, setUnReadCount] = useState(0)
    const location = useLocation();
    //const [state, setState ] = useContext(Context) // to refresh alert list
    const {conversationstate, setconversationState} = useContext(ConversationStateContext)

    const [tabopen, settabopen] = useState(false)

    const mapNots = (snapshot) => {
        let temp = snapshot.val();
        let newNots = [];
        for (let notification in temp) {
            newNots.push({
                key: notification,
                value: temp[notification]
            });
        }
        setNotifications(prev => prev = newNots);

    }

    useEffect(() => {
        try {
            console.log("DB subcribe")
            getAll().on('value', mapNots);
            getertmessage().on('value', ertmess)

            mixpanel.track("Clicks on Notification Page")
        } catch (error) {
            console.error(error)
        }
            return () => {
                try {
                    console.log("DB unsubcribe")
                    getAll().off('value', mapNots);
                    getertmessage().off('value', ertmess);
                } catch (error) {
                    console.error(error)
                }
            }
    }, [])


    const ertmess = (snapshot) => {
        let temp = snapshot.val();
        //if(temp != "Notification"){
        setconversationState([temp])
        //}
    }

    const sortedNots = notifications.sort((a, b) => {
        return Date.parse(b.value.time) - Date.parse(a.value.time)
    })

    const count = sortedNots.reduce((counter, obj) => obj.value.status === 'unread' ? counter += 1 : counter, 0); // 6 

    messaging.onMessage((payload) => {
        if (payload != undefined && payload != null) {
            if (payload.data.body != undefined) {
                var IsAlertNotification = payload.data.body.toLowerCase().includes("alert")
                if (IsAlertNotification == true) {
                    //refreshAlertList()
                }
            }

        }

        // addToast(payload.data.body,
        //     {
        //         appearance: 'success',
        //         autoDismiss: 5000
        //     })
    })

    function removeSingle(key) {
        removeOne(key);
    }

    function removeAllNotifications() {
        removeAll()
    }

    function updateNotification(key, data) {
        document.getElementById(key).style.display = "none"
        updateStatus(key, data)
    }

    function markAllRead() {
        notifications.forEach(notification => {
            if (notification.value.status === 'unread') {
                updateStatus(notification.key, 'read')
            }
        });
    }


    const Optionsimgclick = (key) => {
        try {
            if (document.getElementById(key).style.display == 'none')
                document.getElementById(key).style.display = "block"
            else if (document.getElementById(key).style.display == 'block')
                document.getElementById(key).style.display = "none"

        } catch (error) {
            console.error(error)
        }
    }

    const NavigatetoAlertDetailsPage = (mess,key,status) => {
        try {
            if(status == "unread"){ updateNotification(key, 'read') }
            var alertid = mess.split("AlertID: ")[1]
            history.push(`/alertdetails/${alertid}`)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <>
            <div className="row" style={{ margin: "0 auto", padding: "0px" }}>
                <div className="col-lg-12 pr-0 mt-3 SecondDivSearch">
                    <div className="NameHeading1">
                        <p>
                            All Notifications
                    </p>
                    </div>
                    {/* <div>
                    <div className="CalenderDiv">
                        <input className="SearchViewInput" type="datetime-local" />
                    </div>
                    <div className="SearchTextDiv">
                        <img className="EyeIcon" src={SearchIcon}/>
                        <input className="SearchViewInput" type="search" placeholder="Search" />
                    </div>
                </div> */}
                </div>
            </div>
            <div className="row" style={{ margin: "0 auto", padding: "0px" }}>
                <div className="col-lg-3 notification-category-mask">
                    <div className="row header-notification-type align-items-center">
                        <div className="col-lg-1 icon-header">
                            <img src={AlertNotificationIcon} alt="" />
                        </div>
                        <span className="col-lg-8 header-notification">Alerts</span>
                        <span className="col-lg-1 badge bg-light text-dark badge-notification align-self-center">{count}</span>
                    </div>
                    {/* <div className="row filter-ext">
                     <div className="col">
                        <span>Oldest- Newest</span>
                        <img src={DropArrowIcon} alt=""/>
                    </div> 
                     <div className="col">
                        <img src={FilterIcon} alt=""/>
                        <span>Alert Severity</span>
                        <img src={DropArrowIcon} alt=""/>
                    </div> 
                </div> */}
                    <hr className="hr-ext" />
                    <ul className="notification-list">
                        {
                            notifications && notifications.map((notification, i) => (
                                <li className="notification-list-item">
                                    <div className="row">
                                        <div className="col-lg-1 pr-0">
                                            {(notification.value.status === 'unread')
                                                ?
                                                <div className="notification-read"></div>
                                                : <></>
                                            }
                                        </div>
                                        <div className="col-lg-1 pl-0 align-self-center">
                                            {
                                                notification.value.type == "alert" &&
                                                <div className="alert-icon">
                                                    <div className={`alert-icon-bg ${notification.value.severity.toLowerCase()}-bg`} >
                                                        <img src={AlertIcon} alt="alert icon" />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                notification.value.type == "camera" &&
                                                <div className="alert-icon">
                                                    <div className={`alert-icon-bg low-bg`} >
                                                        <img src={CameraIcon} alt="camera icon" />
                                                    </div>
                                                </div>
                                            }
                                            {
                                                (!'type' in notification.value ||
                                                    notification.value.type == undefined) &&
                                                <img src={AlertNotificationIcon} alt="alert icon" />
                                            }
                                            {/* <img src={AlertNotificationIcon} alt="" /> */}
                                        </div>
                                        <div className="col-lg-8">
                                            {
                                                (notification.value.type === "alert")
                                                ?
                                                <>
                                                {
                                                    <span className="notification-desc">
                                                        {notification.value.message.split("-")[0]}{" - "}
                                                        <span style={{cursor:"pointer"}}
                                                        onClick={() => NavigatetoAlertDetailsPage(notification.value.message,notification.key,notification.value.status)}
                                                        >{notification.value.message.split("-")[1]}</span>
                                                    </span>
                                                }
                                                </>
                                                :
                                                <span className="notification-desc">{notification.value.message}</span>
                                            }
                                            
                                            <div className="notification-time-main">
                                                <span className="notification-time">{to12HrFormat(new Date(notification.value.time))}</span>
                                            </div>
                                        </div>
                                        <div className="col-lg-1 pr-0 ml-3 dropleft">
                                            {(notification.value.status === 'unread')
                                                ?
                                                <>
                                                    <button className="btn p-0 btn-link" onClick={() => Optionsimgclick(notification.key)} >
                                                        <img src={OptionsIcon} alt="onption 3 dot" className="dropdown-toggle options" data-toggle="dropdown" aria-expanded="false" data-offset="10,20" />
                                                    </button>
                                                    <ul id={notification.key} className="dropdown-menu" style={{ display: (tabopen ? 'block' : 'none') }}>
                                                        <li>
                                                            <div className="dropdown-item notification-options" onClick={(e) => updateNotification(notification.key, 'read')}>
                                                                <img src={MarkunreadIcon} alt="Mark Unread" />
                                                                <span className="mark-unread-text">Mark as read</span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </>
    )
}