import React from 'react'
import { DeviceGrayIcon } from '../../assets/images'

const HealthOfflineDevices=({item}) =>{
    
return(
    <div className="StatusValueDiv">
    <p className="statusValueP">{item.Count.slice(0, -3)}</p>
    <div className="mb-1">
      <div className="OfflineDeviceDiv">
        <img alt="Device" src={DeviceGrayIcon} />
         <p className="offlineDeviceText">{item.DeviceName}</p>
      </div>
    </div>
  </div>
)
}
export default HealthOfflineDevices