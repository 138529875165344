import { BASE_URL, BASE_CORE_URL } from './../config/index'

//Site 
export const Getsitelist = async () => {
    let userid = localStorage.getItem('UserID');
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
  
    let url = BASE_URL + "getsitelist?UserID=" + userid;
    return await fetch(url, requestOptions)
      .then((resp) => {
        return resp.json();
      })
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        console.error("getsitelist () Exception: ", error);
      });
}

export const Getsitetypelist = async () =>{
  //debugger
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };
  let url = BASE_CORE_URL + "Site/GetSiteTypeList";
  console.log("Checksite", url);
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetSiteTypeList () Exception: ", error);
    });

}

export const Addsitedetails = async (obj) => {
  
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "addsitedetails";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      console.log("checkdata",data)
      return data;
    })
    .catch(function (error) {
      console.error("addsitedetails () Exception: ", error);
    });
}

export const Updatesitedetails = async (obj) => {
  
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "updatesitedetails";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("updatesitedetails () Exception: ", error);
    });
}

export const addCustomerName = async(obj)=>{
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "addclient";
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        console.error("Add Customer Name () Exception: ", error);
      });
}

export const getCountryDetails = async () => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  let url = BASE_URL + "countrydetails";

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("countryDetails () Exception: ", error);
    });
}

export const getStateDetails = async (CountryMasterId) => {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  };

  let url = BASE_URL + "statedetails?CountryMasterId=" + CountryMasterId;

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("state details () Exception: ", error);
    });
}

export const deletesite = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "deletesite";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("deletesite () Exception: ", error);
    });
}

export const getSiteList = async () => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };

  let url = BASE_CORE_URL + "Site/GetSiteList";

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getSiteList () Exception: ", error);
    });
}

export const SiteList =async (id,deviceTypeId,alertType, showAll) =>{
  //let user = JSON.parse(localStorage.getItem('user'));
  // let userUniqueId = user.UserUniqueID
  // console.log("checkId",userUniqueId)
  const requestOptions = {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
}
let url = BASE_CORE_URL + "Site/SiteList?clientId="+id+"&deviceTypeId="+deviceTypeId+"&alertType="+alertType+"&showAll="+showAll;
return await fetch(url, requestOptions)
        .then((resp) => {
            console.log("test",resp);
            return resp.json();
        })
        .then(function (data) {
            console.log("test",data);
            return data;
        })
        .catch(function (error) {
            console.error("SiteList () Exception", error);
        });
}

//MasterTableList 
export const Getmastertablelist = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };
  const url = BASE_URL + "getmastertablelist";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data
    })
    .catch(function (error) {
      console.error(error);
    });
}
//Event Template 
export const AddNewEventTemplate = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };
  const url = BASE_URL + "addeventtemplate";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("AddNewEventTemplate() Exception: " + error);
    });
}

export const DeleteEventTemplate = async (eventTemplateID) => {
  if (eventTemplateID != null) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        alertTemplateID: eventTemplateID,
      }),
    };

    const url = BASE_URL + "deleteeventtemplate";
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        console.error(error);
      });
  } else {
    return undefined;
  }
}

export const GetEventTemplateList = async () => {
  let userid = localStorage.getItem('UserID');
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      UserID: userid,
    }),
  };

  const url = BASE_URL + "geteventtemplatelist";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data.message;
    })
    .catch(function (error) {
      console.error(error);
    });
}

//Template (Alert)
export const addTemplate = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "addeventtemplate";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("addTemplate () Exception: ", error);
    });
}

export const updateTemplate = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "updateeventtemplate";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("updateTemplate () Exception: ", error);
    });
}
export const Alerttemplatecrud = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  const url = BASE_URL + "alerttemplatecrud";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error(error);
    });
}
export const GetSeverityTypeList = async () => {
  let url = BASE_URL + "getseveritytypelist";
  return await fetch(url)
    .then((resp) => resp.json())
    .then(function (res) {
      return res;     
    })
    .catch(function (error) {
      console.error(error);
    });
};

export const GetAlertTemplate = async (pType,pageNumber,searchText,filterText) =>{
  //debugger
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };
  let url = BASE_CORE_URL + "AlertTemplate/GetAlertTemplate?pType="+pType+"&pageNumber="+pageNumber+"&searchText="+searchText+"&filterText="+filterText;
  console.log("CheckAlert",url);
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetAlertTemplate () Exception: ", error);
    });
};

export const GetAlertTemplateAudit = async (id) =>{
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };
  let url = BASE_CORE_URL + "AlertTemplate/GetScheduleLog?id="+id;
  console.log("CheckAlert",url);
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetAlertTemplateAudit () Exception: ", error);
    });
};

export const GetDataToFilter = async (pType,pSelectedIds) =>{
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };
  let url = BASE_CORE_URL + "AlertTemplate/GetDataToFilter?pType="+pType+"&pSelectedIds="+pSelectedIds;
  console.log("CheckAlert",url);
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("GetDataToFilter () Exception: ", error);
    });
};
export const AddAlertTemplateApI = async(obj) =>{
  //debugger
  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
    body: JSON.stringify(obj),
  };
  let url = BASE_CORE_URL + "AlertTemplate/AddAlertTemplate";
  console.log("CheckTemplate",url);
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      console.log(data);
      return data;
    })
    .catch(function (error) {
      console.error("AddAlertTemplateApI () Exception: ", error);
    });
}

export const UpdateAlertTemplateApI = async(obj)=>{
  //debugger
  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
   body: JSON.stringify(obj),
  };
  let url = BASE_CORE_URL + "AlertTemplate/UpdateAlertTemplate";
  console.log("CheckUpdateTemplate",url);
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      console.log(data);
      return data;
    })
    .catch(function (error) {
      console.error("UpdateAlertTemplateApI () Exception: ", error);
    });
}

export const seperateAlertTemplateByDevice = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
   body: JSON.stringify(obj),
  };
  let url = BASE_CORE_URL + "AlertTemplate/SeperateALertTemplateByDevice";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("UpdateAlertTemplateApI () Exception: ", error);
    });
}

export const splitAlertTemplateByDevice = async (alertTemplateId, deviceId, userId) => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + `AlertTemplate/SplitAlertTemplateByDevice?alertTemplateId=${alertTemplateId}&deviceId=${deviceId}&userId=${userId}`;

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getDeviceTypeList () Exception: ", error);
    });
}

//Device 
export const deviceconfigcrud = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "deviceconfigcrud";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      console.log("Vyenkatesh Check : ",data)
      return data;
    })
    .catch(function (error) {
      console.error("deviceconfigcrud () Exception: ", error);
    });
}

export const bulkUploadCamera = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "Device/DeviceBulkUpload";
  console.log("New Bulk Upload", url);
  return await fetch(url, requestOptions)
    .then(res => res.json())
    .then(data => {
      console.log("New Bulk Upload", data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}

export const bulkUploadPanel = async (obj) => {
  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
    body: JSON.stringify(obj),
  };

  /* ---------------------- Endpoint needs to be changed for panel --------------------- */
  let url = BASE_CORE_URL + "Device/PanelBulkUpload";
  console.log("New Bulk Upload", url);
  return await fetch(url, requestOptions)
    .then(res => res.json())
    .then(data => {
      console.log("New Bulk Upload", data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}

export const getDeviceTypeList = async () => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };

  let url = BASE_CORE_URL + "Device/DeviceTypeList";
  
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getDeviceTypeList () Exception: ", error);
    });
}

export const Getdeviceslistdata = async () => {
  let user = JSON.parse(localStorage.getItem('user'));
  let useruniqueid = user.UserUniqueID
  const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        UserUniqueID: useruniqueid,
      }),
    };
  
    const url = BASE_URL + "getstreamingurllist";
    return fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data.message;
      })
      .catch(function (error) {
        console.error({ error });
      });
}

export const SaveDeviceDetails = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "addcameraconfigdetails";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("SaveDeviceDetails() Exception: ", error);
    });
}

export const UpdateDeviceDetails = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(obj),
  };

  let url = BASE_URL + "updatecameraconfigdet";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("UpdateDeviceDetails() Exception: ", error);
    });
}

export const GetDeviceList = async (siteIds,deviceTypeId,IDSPanelZoneTypeID, alertType, showAll)=>{
  // let user = JSON.parse(localStorage.getItem('user'));
  // let userUniqueId = user.UserUniqueID
  // console.log("checkId",userUniqueId)
  const requestOptions = {
    method: "GET",
    headers: {
        "Content-Type": "application/json",
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
}

let url = BASE_CORE_URL + `Device/GetDeviceList?siteIds=${siteIds}&deviceTypeId=${deviceTypeId}&IDSPanelZoneTypeID=${IDSPanelZoneTypeID}&alertType=${alertType}&showAll=${showAll}`
return await fetch(url, requestOptions)
        .then((resp) => {
            console.log("test1",resp);
            return resp.json();
        })
        .then(function (data) {
            console.log("test1",data);
            return data;
        })
        .catch(function (error) {
            console.error("GetDeviceList () Exception", error);
        });
}
export const getPanelZoneType = async ()=>{
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };
  let url = BASE_CORE_URL + "Device/GetPanelZoneType";
  return await fetch(url, requestOptions)
  .then((resp) => resp.json())
  .then(function (data) {
    return data;
  })
  .catch(function (error) {
    console.error("getPanelZoneType () Exception: ", error);
  });
}
// Interfaces 
export const getInterfaceTypeData = async () => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };

  let url = BASE_CORE_URL + "InterfaceType/Get";

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getInterfaceTypeData () Exception: ", error);
    });
}

export const getInterfaceSubTypeData = async (interfaceType) => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json", 
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
    },
  };

  let url = BASE_CORE_URL + "InterfaceSubType/Get?id=" + interfaceType;

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getInterfaceSubTypeData () Exception: ", error);
    });
}

export const getInterfaceList = async (siteid) => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };

  let url = BASE_CORE_URL + "Interface/Get?siteId=" + siteid;
  return await fetch(url, requestOptions)
    .then((resp) => {
      return resp.json();
    })
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("getInterfaceList () Exception: ", error);
    });
}

export const addInterfaceDetails = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "Interface/Add";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("addInterfaceDetails () Exception: ", error);
    });
}

export const updateInterfaceDetails = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "Interface/Update";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("updateInterfaceDetails () Exception: ", error);
    });
}

export const deleteInterfaceDetails = async (obj) => {

  const requestOptions = {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
    body: JSON.stringify(obj),
  };

  let url = BASE_CORE_URL + "Interface/Delete";
  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      console.log(data);
      return data;
    })
    .catch(function (error) {
      console.error("deleteInterfaceDetails () Exception: ", error);
    });
}

//entity subtype
export const getCCTVType = async () => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  };

  let url = BASE_CORE_URL + "CCTVType/Get";

  return await fetch(url, requestOptions)
    .then((resp) => resp.json())
    .then(function (data) {
      return data;
    })
    .catch(function (error) {
      console.error("CCTVType () Exception: ", error);
    });
}

export const getAlertTemplateByIdAPI = async (templateId) => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  }

  let url = BASE_CORE_URL + "AlertTemplate/GetAlertTemplateById?id=" + templateId;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })

}

export const getAlertTemplateByDeviceIdAPI = async (deviceId) => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  }

  let url = BASE_CORE_URL + "AlertTemplate/GetAlertTemplateByDeviceId?deviceId=" + deviceId;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })

}

export const getCustomerList = async (deviceTypeId, alertType, showAll) => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  }

  let url = BASE_CORE_URL + `Customer/CustomerList?deviceTypeId=${deviceTypeId}&alertType=${alertType}&showAll=${showAll}`;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}

export const removeDeviceFromTemp = async(alertTemplateId, deviceId) => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken')
   },
  }

  let url = BASE_CORE_URL + `AlertTemplate/RemoveDevice?alertTemplateId=${alertTemplateId}&deviceId=${deviceId}`;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}

export const getZoneTypeAlertTemp = async (deviceIds, showAll, alertCodeId) => {
  const requestOptions = {
    method: "GET",
    headers: { 
      "Content-Type": "application/json",
      "authcode": localStorage.getItem('authCode'),
      "Authorization": "Bearer " + localStorage.getItem('authToken'),
   },
  }

  // let url = BASE_URL + `getZonesAlertTemp?SiteId=${siteIds}`;
  let url = BASE_CORE_URL + `Site/GetZoneAlertTemp?deviceIds=${deviceIds}&showAll=${showAll}&alertCodeId=${alertCodeId}`;

  return await fetch(url, requestOptions)
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      return data;
    })
    .catch(err => {
      console.log(err);
    })
}

//DeleteAlertTemplate
export const DeleteAlertTemplate2 = async (eventTemplateID) => {
  console.log("rupesh checks Templateid",eventTemplateID)
  if (eventTemplateID != null) {
    const requestOptions = {
      method: "GET",
      headers: { 
        "Content-Type": "application/json", 
        "authcode": localStorage.getItem('authCode'),
        "Authorization": "Bearer " + localStorage.getItem('authToken'),  
      },
      
      // body: JSON.stringify({
      //   alertTemplateID: eventTemplateID,
      // }),
    };

    const url = BASE_CORE_URL + "AlertTemplate/DeleteAlertTemplate?id=" + eventTemplateID;
    return await fetch(url, requestOptions)
      .then((resp) => resp.json())
      .then(function (data) {
        return data;
      })
      .catch(function (error) {
        console.error(error);
      });
  } else {
    return undefined;
  }
}