import firebase from "firebase/app";
import 'firebase/auth'
import 'firebase/messaging'
import 'firebase/database'

let config = {
  apiKey: "AIzaSyB-bzYSLCechfx2jzCC3mhq_QhtZOlr_RM",
  authDomain: "stream-engine-development.firebaseapp.com",
  databaseURL: "https://stream-engine-development.firebaseio.com",
  projectId: "stream-engine-development",
  storageBucket: "stream-engine-development.appspot.com",
  messagingSenderId: "346588511364",
  appId: "1:346588511364:web:eaf8b0d0b7d51afe8016d1",
  measurementId: "G-49ZK6YV610"
}

const app = firebase.initializeApp(config);

export const auth = app.auth()
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const fbProvider = new firebase.auth.FacebookAuthProvider();
export let messaging = firebase.messaging();
export const PhoneAuthProvider = firebase.auth.PhoneAuthProvider;
export const applicationVerifier = firebase.auth.ApplicationVerifier;
export const RecaptchaVerifier = firebase.auth.RecaptchaVerifier;

export const signInWithGoogle = () => {

    firebase.auth().getRedirectResult().then(function (result) {
        if (result.credential) {
            // This gives you a Google Access Token.
            // var token = result.credential.accessToken;
        }
        // var user = result.user;
    });

    // Start a sign in process for an unauthenticated user.
    googleProvider.addScope('profile');
    googleProvider.addScope('email');
    auth.signInWithRedirect(googleProvider);
}

export const signInWithFacebook = () => {
    auth.signInWithPopup(fbProvider).then(function (result) {
        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        // var token = result.credential.accessToken;
        // The signed-in user info.
        // var user = result.user;
        // ...
    }).catch(function (error) {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
    });
}

export const db = firebase.database()
export default app