import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Eye from '../../../assets/images/Eye.png'
import Swal from 'sweetalert2'

export default function Step2Form({ initialValues, OnSubmit }) {

    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmpasswordShown, setconfirmpasswordShown] = useState(false);
    let { register, handleSubmit } = useForm({
        defaultValues: initialValues
    });

    const onSubmit = () => {
        var pwd = document.getElementById("passwordtxt").value
        var cpwd = document.getElementById("confirmpasswordtxt").value

        if(pwd.trim()==''){
            Swal.fire({text:'Please enter password.'})
            return
        }

        if(cpwd.trim()==''){
            Swal.fire({text:'Please enter confirm password.'})            
            return
        }

        if (pwd === cpwd) {
            var data = { "UPassword": pwd }
            OnSubmit(data)
        } else {
            Swal.fire({text:'Password and confirm password must be same!'})            
            return
        }
    };

    return (
        <>
            <p className="LabelText"> New password </p>
            <div className="BgInputPassword">
                <input id="passwordtxt" type={passwordShown ? "text" : "password"} className="PasswordBg" />
                <img className="EyeIcon" src={Eye} onClick={() => setPasswordShown(!passwordShown)} />
            </div>

            <p className="LabelText"> Confirm new password </p>
            <div className="BgInputPassword">
                <input id="confirmpasswordtxt" type={confirmpasswordShown ? "text" : "password"} className="PasswordBg" />
                <img className="EyeIcon" src={Eye} onClick={() => setconfirmpasswordShown(!confirmpasswordShown)} />
            </div>

            <p className="SelectSpaceLine mb-0 mt-3" hidden>
                Password Strength:
                <b>
                    Moderate
                </b>
            </p>
            <div hidden>
                <div className="Line"></div>
                <div className="Line"></div>
                <div className="Line"></div>
                <div className="Line1"></div>
                <div className="Line1"></div>
            </div>

            <div className="mt-3 mb-5">
                {/* <p className="SelectSpaceLine">
                    Password Must:
                </p>
                <p className="mb-0">
                    <span className="CheckBox">
                        <img src={CheckTick} />
                    </span>
                    <span>Be a minimum of 8 characters</span>
                </p>
                <p className="mb-0">
                    <span className="CheckBox">
                        <img src={CheckTick} />
                    </span>
                    <span>Include at least one lowercase letter (a-z)</span>
                </p>
                <p className="mb-0">
                    <span className="CheckBox ">
                        <img className="untick" src={UncheckTick} />
                    </span>
                    <span>Include at least one uppercase letter (A-Z)</span>
                </p>
                <p className="mb-0">
                    <span className="CheckBox">
                        <img src={CheckTick} />
                    </span>
                    <span>Include at least one number (0-9)</span>
                </p> */}
            </div>

            <button className="signUpBtn" onClick={() => onSubmit()}>
                Set Password and continue
            </button>
        </>
    )
}