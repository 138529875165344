import React, { useEffect, useState } from 'react'
import { AddNode, ArrowDown, ArrowUp, SearchIcon, SearchIconWhite } from "../../assets/images";
import {
    Accordion,
    Card,
    Form,
    Pagination,
    Table,
    useAccordionToggle,
} from "react-bootstrap";
import CustomContactForm from './CustomContactForm';

const CustomContact = ({
    customContact,
    onAddClick,
    isNotifFormEnabled,
    count,
    mainCustomerList,
    onDeleteClick,
    setEnableAdd,
    setMainCustomerList,
    setCustomContact,
    addNewClicked,
    isDarkTheme
}) => {
    const [searchCustCont, setSearchCustCont] = useState("");
    const [customOpen, setCustomeOpen] = useState(false)
    
    const searchHandler = (e) => {
      let s = e.target.value;
      let filteredCont = mainCustomerList.filter((x) => x.Name.toLowerCase().includes(s.toLowerCase()));
      setSearchCustCont(s);
      setCustomContact(filteredCont);
    }

    useEffect(() => {
      if(addNewClicked && searchCustCont != ""){
        setSearchCustCont("");
      }
    }, [addNewClicked]);

    useEffect(() => {
      if(customContact.length == 0){
        setEnableAdd(false);
      }
    }, [customContact]);

    const uniqueKey = (data) => {
      return `${data.Id}_${data.Name}`;
    }

    return (
        <Accordion defaultActiveKey="0" style={{ marginTop: "20px" }}>
            <Card>
                    <Accordion.Toggle
                    as={Card.Header}
                        eventKey="1" className='NotificationCardAccordion'
                        onClick={() => setCustomeOpen(!customOpen)}
                    >
                      <p>Custom Contact</p>
                      {
                            !customOpen ?  
                            <img src={ArrowDown} alt="dropdown-arrow" /> :
                            <img src={ArrowUp} alt='up arrow' />
                        }
                    </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                    <Card.Body style={{ padding: "10px" }}>
                      <div style={{display: "flex", justifyContent: "space-between"}}>
                        <div className="SelectionListWithSelectAll customContactSearch">
                            <input
                                className="SearchViewInput1 searchNotification"
                                type="search"
                                placeholder="Search"
                                value={searchCustCont}
                                onChange={(e) => searchHandler(e)}
                            // value={searchTextOne}
                            // onChange={(e) => searchHandlerOne(e)}
                            />
                            {
                              isDarkTheme ?
                              <img src={SearchIcon} style={{ cursor: "pointer" }} alt='search icon' /> :
                              <img src={SearchIconWhite} height="15" style={{ cursor: "pointer" }} alt='search icon' />
                            }
                        </div>
                        <button
                            type="button"
                            className="addCustomer"
                            disabled={!isNotifFormEnabled}
                            onClick={(e) => {
                                onAddClick(e);
                            }}
                            style={{ marginBottom: "10px" }}
                        >
                            <span className="ViewUpdate">
                                <img src={AddNode} alt='plus icon add node' />
                            </span>
                        </button>
                      </div>
                        <div className="HgtCheckBoxList" style={{paddingRight: "5px"}}>
                            {/* {
                        customContact
                      } */}
                            {
                                customContact.map((item) => (
                                    <CustomContactForm 
                                      key={item.Id} 
                                      // key={uniqueKey(item)}
                                      item={item} isNotifFormEnabled={isNotifFormEnabled} onDeleteClick={onDeleteClick} setEnableAdd={setEnableAdd} mainCustomerList={mainCustomerList} setMainCustomerList={setMainCustomerList} customContact={customContact} setCustomContact={setCustomContact} />
                                ))
                            }
                            {/* <div className="grid-container Notificationgrid-container grid-container--fit">
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">Contact Name</p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter Name here"
                          />
                        </div>
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">
                            Position/Designation <sup>(Optional)</sup>
                          </p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter contact number"
                          />
                        </div>
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">Contact Number</p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter contact number"
                          />
                        </div>
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">Contact Email</p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter contact email"
                          />
                        </div>
                      </div>
                      <div className="grid-container Notificationgrid-container grid-container--fit">
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">Contact Name</p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter Name here"
                          />
                        </div>
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">
                            Position/Designation <sup>(Optional)</sup>
                          </p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter contact number"
                          />
                        </div>
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">Contact Number</p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter contact number"
                          />
                        </div>
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">Contact Email</p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter contact email"
                          />
                        </div>
                      </div>
                      <div className="grid-container Notificationgrid-container grid-container--fit">
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">Contact Name</p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter Name here"
                          />
                        </div>
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">
                            Position/Designation <sup>(Optional)</sup>
                          </p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter contact number"
                          />
                        </div>
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">Contact Number</p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter contact number"
                          />
                        </div>
                        <div className="NotificationTextBox">
                          <p className="SiteNameText">Contact Email</p>
                          <input
                            className="MessageTextBox1"
                            placeholder="Enter contact email"
                          />
                        </div>
                      </div> */}
                        </div>

                        {/* <button
                            className="addCustomer"
                            disabled={!isNotifFormEnabled}
                            onClick={(e) => {
                                onAddClick(e);
                            }}
                            style={{ marginBottom: "10px" }}
                        >
                            <span className="ViewUpdate">
                                <img src={AddNode} />
                            </span>
                        </button> */}
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    )
}

export default CustomContact